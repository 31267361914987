import { FC, useCallback, useState } from "react";
import Cropper from "react-easy-crop";
import { Point } from "react-easy-crop/types";
import {
  addAdiPicture,
  uploadAdiDocument,
} from "../../../../core/services/adi-service";
import getCroppedImg from "../../../helpers/Cropper";
import Modal from "../../shared/overlays/Modal";
import Slider from "@mui/material/Slider";
import toast from "react-hot-toast";
import Notification from "../../shared/overlays/Notification";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";

interface Props {
  show: boolean;
  hide: any;
  imageSrc: any;
}

const UploadProfileModal: FC<Props> = ({ show, hide, imageSrc }) => {
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback(
    (croppedArea: any, croppedAreaPixels: any) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  const getCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels, 0);
      if (croppedImage) {
        const file = await urlToFile(croppedImage);
        let loadingId = toast.custom(
          () => (
            <Notification
              colour="gray"
              title={"Uploading..."}
              description={""}
              Icon={ExclamationCircleIcon}
            />
          ),
          {
            duration: 3000,
            position: "top-center",
          }
        );
        uploadAdiDocument(file)
          .then((data) => {
            addAdiPicture({ picture_url: data.data.results })
              .then(() => {
                toast.custom(
                  () => (
                    <Notification
                      colour="green"
                      title={"Saved successfully"}
                      description={""}
                      Icon={CheckCircleIcon}
                    />
                  ),
                  {
                    duration: 3000,
                    position: "top-center",
                    id: loadingId,
                  }
                );
                hide();
              })
              .catch(() => {
                toast.custom(
                  () => (
                    <Notification
                      colour="red"
                      title={"Failed to upload document"}
                      description={""}
                      Icon={ExclamationTriangleIcon}
                    />
                  ),
                  {
                    duration: 3000,
                    position: "top-center",
                    id: loadingId,
                  }
                );
              });
          })
          .catch(() => {
            toast.custom(
              () => (
                <Notification
                  colour="red"
                  title={"Failed to upload document"}
                  description={""}
                  Icon={ExclamationTriangleIcon}
                />
              ),
              {
                duration: 3000,
                position: "top-center",
                id: loadingId,
              }
            );
          });
      }
    } catch {
      toast.custom(
        () => (
          <Notification
            colour="red"
            title={"Sorry, something went wrong!"}
            description={""}
            Icon={ExclamationTriangleIcon}
          />
        ),
        {
          duration: 3000,
          position: "top-center",
        }
      );
    }
  }, [croppedAreaPixels, hide, imageSrc]);

  const urlToFile = async (url: any) => {
    const res = await fetch(url);
    const blob = await res.blob();
    // Gets URL data and read to blob
    const mime = blob.type;
    const ext = mime.slice(mime.lastIndexOf("/") + 1, mime.length);
    // Gets blob MIME type (e.g. image/png) and extracts extension
    const file = new File([blob], `filename.${ext}`, {
      type: mime,
    });
    // Creates new File object using blob data, extension and MIME type
    return file;
  };

  const saveImg = () => {
    try {
      getCroppedImage();
    } catch (e) {
      hide();
    }
  };

  return (
    <Modal
      open={show}
      title="Crop Photo"
      submitText="Done"
      onClose={hide}
      onSubmit={saveImg}
    >
      <div className="relative h-96 w-full">
        <Cropper
          image={imageSrc}
          crop={crop}
          zoom={zoom}
          aspect={4 / 4}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          initialCroppedAreaPercentages={undefined}
        />
      </div>
      <Slider
        value={zoom}
        min={1}
        max={3}
        step={0.1}
        aria-labelledby="Zoom"
        className="text-primary py-lg-3 py-0"
        onChange={(e: any, zoom: any) => setZoom(Number(zoom))}
      />

      {/* </div> */}
    </Modal>
  );
};

export { UploadProfileModal };
