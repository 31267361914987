import { FC, useEffect, useState } from "react";

import ReactGA from "react-ga4";
import { Job } from "../../../core/models/job";
import { useHistory } from "react-router-dom";
import { FilterObject } from "../../../core/models/filter-object";
import {
  availableJobList,
  getADIJobDefaultViewOptions,
  getMinAndMaxHourRates,
} from "../../../core/services/job-service";
import * as JobsDefaultViewEnum from "../../../core/models/enums/jobs-default-view-enum";
import {
  checkAdiHasRequiredDocs,
  getCanAcceptJobs,
} from "../../../core/services/adi-service";
import InfiniteScroll from "react-infinite-scroll-component";
import { AvailableJobCard } from "./AvailableJobCard";
import { SortJobsDialog } from "./SortJobsDialog";
import { FilterJobsDialog } from "./FilterJobsDialog";
import EmptyState from "../../components/shared/elements/EmptyState";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import Button from "../../components/shared/elements/Button";
import DisclosureItem from "../../components/shared/elements/DisclosureItem";
import { NewJobModalParent } from "../../components/jobs/modals/NewJobModalParent";

interface IProps {
  unsavedSteps: Array<number>;
  completeSteps: any;
  setUploadedReqDocs: any;
  showSortDialog: boolean;
  hideSortDialog: () => void;
  setIsSorted: any;
  isSmartSorted?: boolean;
  setIsSmartSort: any;
  showFilterDialog: boolean;
  hideFilterDialog: () => void;
  setFilterCount: any;
  isShowFilter: boolean;
  showFilters: () => void;
  hideFilters: () => void;
}
const AvailableJobList: FC<IProps> = (props) => {
  const history = useHistory();
  const urlSearchParams = new URLSearchParams(window.location.search);

  const [availableJobs, setAvailableJobs] = useState<Array<Job>>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [errors, setErrors] = useState<Array<any>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [minHourRate, setMinHourRate] = useState<number>(1);
  const [maxHourRate, setMaxHourRate] = useState<number>(100);
  const [show, setShow] = useState(false);
  const [showRegisterInterest, setShowRegisterInterest] =
    useState<boolean>(false);
  const [showRegisterLocationInterest, setShowRegisterLocationInterest] =
    useState<boolean>(false);
  const [showRegisterAvailabilityInterest, setShowRegisterAvailabilityInterest] = useState<boolean>(false)


  const jobsBenefits = [
    {
      title: "1000s of jobs nationwide",
      desc: "Find work in your area for free. With courses ranging from 10- to 48-hours long and a variety of learner availability, you can quickly fill your diary with ADI Network.",
      icon: "pmf-icon-wheel",
    },
    {
      title: "Get paid upfront ",
      desc: "All work taken from ADI Network is paid in advance of lessons going ahead and sent directly into your bank account.",
      icon: "pmf-icon-faster-payouts",
    },
    {
      title: "Leave the admin to us",
      desc: "Course providers handle all the test bookings so you can focus on the teaching and maximise your income. Your learners will be automatically informed when you schedule lessons or log their learning progress.",
      icon: "pmf-icon-security",
    },
    {
      title: "No minimum commitment",
      desc: "Take as few courses as you like, or as many as you can — ADI Network’s job board is the ultimate diary filler. Get the security of a franchise model without the fees or commitments.",
      icon: "pmf-icon-stay-loop",
    },
  ];

  let jobId = urlSearchParams.get("job-id");
  let recruiter = urlSearchParams.get("recruiter");
  let urlGenerationDateTime = urlSearchParams.get("datetime");
  const [showWithdrawDialog, setShowWithdrawDialog] = useState<boolean>(false);
  let searchDataFromURL = Object.fromEntries(urlSearchParams.entries());
  const [jobData, setJobData] = useState<any>({});
  const [canAcceptJobs, setCanAcceptJobs] = useState<boolean>(false);
  const defaultDistance = 15;
  const defaultRate = 20;
  const [jobsDefaultView, setJobsDefaultView] = useState<
    number | undefined | null
  >();
  const [filterObject, setFilterObject] = useState<FilterObject>({
    SearchObject: {
      is_new_only: searchDataFromURL.is_new_only ?? null,
      from_hour: searchDataFromURL.from_hour,
      to_hour: searchDataFromURL.to_hour,
      from_rate: searchDataFromURL.from_rate ?? defaultRate,
      from_distance: searchDataFromURL.from_distance ?? 1,
      to_distance: searchDataFromURL.to_distance ?? defaultDistance,
      include_distance: searchDataFromURL.include_distance ?? null,
      exam_from_date: searchDataFromURL.exam_from_date,
      exam_to_date: searchDataFromURL.exam_to_date,
      my_test_centers: searchDataFromURL.my_test_centers ?? null,
      include_hidden_jobs: searchDataFromURL.include_hidden_jobs ?? null,
      is_active_bid: searchDataFromURL.is_active_bid ?? null,
      course_intensity: searchDataFromURL?.course_intensity?.split(",") ?? [],
    },
    PageNumber: 1,
    PageSize: 9,
    SortDirection: searchDataFromURL.SortDirection ?? "",
    SortBy: searchDataFromURL.SortBy ?? "",
  });

  const isJobFromNewJobsSMS = searchDataFromURL.new_jobs_sms ? true : false;

  const [defaultSearch, setDefaultSearch] = useState<any>({
    is_new_only: 0,
    from_hour: null,
    to_hour: null,
    from_rate: defaultRate,
    from_distance: 1,
    to_distance: defaultDistance,
    include_distance: null,
    exam_from_date: null,
    exam_to_date: null,
    my_test_centers: null,
    include_hidden_jobs: 0,
    is_active_bid: 0,
    course_intensity: [],
  });

  useEffect(() => {
    ReactGA.event("open_new_jobs");
  }, []);

  const fetchData = () => {
    if (loading) {
      return;
    }
    const oldPageNumber = filterObject.PageNumber;
    setFilterObject({
      ...filterObject,
      PageNumber: oldPageNumber + 1,
    });
  };

  const handleOnJobsUpdated = () => {
    refresh();
  };

  const refresh = () => {
    setAvailableJobs([]);
    if (filterObject.PageNumber == 1) {
      getFilteredList();
    } else {
      setFilterObject({
        ...filterObject,
        PageNumber: 1,
      });
    }
  };

  const handleOnJobFilters = (searchData: any) => {
    updateUrlQueryParams(searchData);
    setAvailableJobs([]);
    setFilterObject({
      ...filterObject,
      PageNumber: 1,
      SearchObject: searchData,
    });
    updateFilterCount(searchData);
  };
  const onHide = (isUpdated: boolean) => {
    setShowRegisterInterest(false);
    setShowRegisterLocationInterest(false);
    setShowWithdrawDialog(false);
    setShowRegisterAvailabilityInterest(false);
    if (isUpdated) {
      getFilteredList();
    }
  };

  const updateFilterCount = (searchData: any) => {
    props.setFilterCount(
      Object.keys(searchData).filter(
        (i) =>
          searchData[i] != undefined &&
          searchData[i] != 0 &&
          searchData[i] != "0" &&
          searchData[i] != defaultSearch[i]
      ).length
    );
  };

  const handleSortChange = (sortby: any, sortDirection: any) => {
    if (sortby && sortDirection) {
      props.setIsSorted(true);
      props.setIsSmartSort(sortby === "most_relevant");
      setFilterObject({
        ...filterObject,
        PageNumber: 1,
        SortBy: sortby,
        SortDirection: sortDirection,
      });
    } else {
      props.setIsSorted(false);
    }
  };
  const updateUrlQueryParams = (searchData: any) => {
    var query = "?";
    for (const [key, value] of Object.entries(searchData)) {
      if (value != undefined) {
        if (query != "?") query += "&";
        query += `${key}=${value}`;
      }
    }
    sessionStorage.setItem("new_jobs_query", query);
    history.push({
      pathname: "/ADI/new-jobs",
      search: query,
    });
  };

  useEffect(() => {
    if (!jobsDefaultView) {
      getADIJobDefaultViewOptions().then((res) => {
        setJobsDefaultView(
          res?.data?.results?.setting_value ??
          JobsDefaultViewEnum.Jobs_matching_preferred_test_centres
        );
        if (filterObject.PageNumber == 1) {
          if (!filterObject.SearchObject) {
            filterObject.SearchObject = {};
          }

          if (
            res?.data?.results?.setting_value ==
            JobsDefaultViewEnum.Jobs_match_preferred_test_centers_and_miles
          ) {
            filterObject.SearchObject.include_distance =
              filterObject.SearchObject.include_distance ?? 1;
            filterObject.SearchObject.my_test_centers =
              filterObject.SearchObject.my_test_centers ?? 1;

            setDefaultSearch({
              ...defaultSearch,
              include_distance: 1,
              my_test_centers: 1,
            });
          } else if (
            res?.data?.results?.setting_value ==
            JobsDefaultViewEnum.Jobs_within_miles_of_your_address
          ) {
            filterObject.SearchObject.include_distance =
              filterObject.SearchObject.include_distance ?? 1;
            filterObject.SearchObject.my_test_centers =
              filterObject.SearchObject.my_test_centers ?? 0;

            setDefaultSearch({
              ...defaultSearch,
              include_distance: 1,
              my_test_centers: 0,
            });
          } else {
            filterObject.SearchObject.include_distance =
              filterObject.SearchObject.include_distance ?? 0;
            filterObject.SearchObject.my_test_centers =
              filterObject.SearchObject.my_test_centers ?? 1;
            setDefaultSearch({
              ...defaultSearch,
              include_distance: 0,
              my_test_centers: 1,
            });
          }
        }
        getFilteredList();
      });
    } else {
      getFilteredList();
    }
  }, [filterObject]);
  const getModalTitle = () => {
    if (showRegisterInterest) return "Register Interest";
    else return "Withdraw Job Bid";
  };

  useEffect(() => {
    if (loading) updateFilterCount(filterObject.SearchObject);
  }, [filterObject, defaultSearch]);

  function getErrorText(errorMessage: any) {
    if (
      errorMessage ==
      "You should add car with transmission rate to show available jobs"
    ) {
      return [
        "You should add car with transmission rate to show available jobs",
        "Go to Car Details",
      ];
    }
    if (
      errorMessage ==
      "You should add your driving tuition details to show available jobs"
    ) {
      return [
        "You should add your driving tuition details to show available jobs",
        "Go to Driving Tuition Details",
      ];
    }
    if (
      errorMessage ==
      "You should add badge photo and insurance document to show available jobs"
    ) {
      // Please upload your badge and enter your insurance details to see available jobs
      return [
        "Please upload your badge and enter your insurance details to see available jobs",
        "Go to Documents",
      ];
    }
    if (
      errorMessage ==
      "You should add transmission rate for the added car to show available jobs"
    ) {
      return [
        "You should add transmission rate for the added car to show available jobs",
        "Go to Rates & Cancellation policy",
      ];
    }
    if (errorMessage == "Please upload your badge to see available jobs") {
      return [
        "Please upload your badge to see available jobs",
        "Go to Documents",
      ];
    }
    if (errorMessage == "Please upload your insurance to see available jobs") {
      return [
        "Please enter your insurance details to see available jobs",
        "Go to Documents",
      ];
    }
    if (
      errorMessage ==
      "Your insurance document has expired. Please update to continue taking work from PassMeFast."
    ) {
      return [
        "Your insurance document has expired. Please update to continue taking work from ADI Network.",
        "Go to Documents",
      ];
    }

    if (
      errorMessage ==
      "Your ADI badge has expired. Please update to continue taking work from PassMeFast."
    ) {
      return [
        "Your ADI badge has expired. Please update to continue taking work from ADI Network.",
        "Go to Documents",
      ];
    }

    if (
      errorMessage ==
      "Please upload a picture of the front of your badge (the side with your face on) and insurance documents to see available jobs"
    ) {
      return [
        "Please upload your badge and enter your insurance details to see available jobs",
        "Go to Documents",
      ];
    }

    if (
      errorMessage == "You should add your post code to show available jobs"
    ) {
      return [
        "You should add your post code to see available jobs",
        "Go to About You",
      ];
    }

    if (
      errorMessage ==
      "Your badge document is rejected. Please update to continue taking work from PassMeFast."
    ) {
      return [
        "Something isn’t quite right with your badge. Please upload a new picture.",
        "Go to Documents",
      ];
    }

    if (
      errorMessage == "You need at least 1 test centre to see available jobs"
    ) {
      return [
        "You need at least 1 test centre to see available jobs",
        "Go to Job Preferences",
      ];
    }

    if (
      errorMessage ==
      "Your cancellation policy document is rejected. Please update to continue taking work from PassMeFast."
    ) {
      return ["Please update your terms and conditions.", "Go to Rates"];
    }

    return [errorMessage];
  }

  function editBids(jobRef: any, isEdit: boolean) {
    let job = availableJobs.find((a) => a.job_ref == jobRef);
    setJobData({ ...job, actual_hour_rate: job?.rate_per_hour });
    if (isEdit) {
      if (job?.pickup_location_long) {
        setShowRegisterLocationInterest(true);
      } else {
        setShowRegisterInterest(true);
      }
      setShowWithdrawDialog(false);
    } else {
      setShowRegisterInterest(false);
      setShowRegisterLocationInterest(false);
      setShowWithdrawDialog(true);
    }
  }
  function getFilteredList() {
    setLoading(true);
    setErrors([]);
    if (filterObject.SearchObject.include_distance == 0) {
      filterObject.SearchObject.to_distance = null;
      filterObject.SearchObject.from_distance = null;
    }
    availableJobList(filterObject)
      .then((res) => {
        props.showFilters();
        if (filterObject.PageNumber == 1) {
          setAvailableJobs(res.data.results.Results);
        } else {
          const oldJobs = [...availableJobs];
          const newJobs = oldJobs.concat(
            res.data.results.Results.filter(
              (a) => availableJobs.findIndex((b) => b.id == a.id) == -1
            )
          );
          setAvailableJobs(newJobs);
        }
        setTotalRecords(res.data.results.TotalRecords);

        if (res.data.results.TotalRecords == 0) {
          let errors = [];
          errors.push({
            errorImageUrl: "/media/svg/no-docs.svg",
            errorMessage: getErrorText(
              "There are no jobs in your area. Please check later as the list is updated frequently."
            ),
          });
          setErrors(errors);
        }
        setLoading(false);
      })
      .catch((error) => {
        if (error && error.errors && error.errors.length > 0) {
          props.hideFilters();
          let errors = [];
          errors.push({
            errorMessage: getErrorText(error.errors[0].errorMessage),
          });
          setErrors(errors);
        }
        setLoading(false);
      });
  }

  function getMinAndMaxHourRatesFn() {
    setErrors([]);
    if (filterObject.SearchObject.include_distance == 0) {
      filterObject.SearchObject.to_distance = null;
      filterObject.SearchObject.from_distance = null;
    }
    getMinAndMaxHourRates(filterObject)
      .then((res) => {
        if (
          res.data.results?.min_rate === res.data.results?.max_rate &&
          res.data.results?.min_rate != null
        ) {
          res.data.results.max_rate = res.data.results.min_rate + 1;
        }
        setMinHourRate(res.data.results.min_rate);
        setMaxHourRate(res.data.results.max_rate);
      })
      .catch((error) => { });
  }
  useEffect(() => {
    getCanAcceptJobs().then((res) => {
      setCanAcceptJobs(res.data.results?.can_accept_jobs === 1);
    });
    getMinAndMaxHourRatesFn();

    checkAdiHasRequiredDocs().then((res) => {
      props.setUploadedReqDocs(res.data.results?.hasRequiredDocuments === 1);
    });
  }, []);

  useEffect(() => {
    if (jobId) {
      setShow(true);
    }
  }, [jobId]);

  const redirct = (errorMessage: any) => {
    if (errorMessage == "Go to Car Details") {
      history.push("/ADI/profile/car-details");
    }
    if (errorMessage == "Go to Driving Tuition Details") {
      history.push("/ADI/profile/driving-tuition");
    }

    if (errorMessage == "Go to Rates & Cancellation policy") {
      history.push("/ADI/profile/rates-cancellation-policy");
    }
    if (errorMessage == "Go to Documents") {
      history.push("/ADI/profile/documents");
    }

    if (errorMessage == "Go to About You") {
      history.push("/ADI/profile/about-u");
    }

    if (errorMessage == "Go to Job Preferences") {
      history.push("/ADI/profile/job-preferences");
    }
    if (errorMessage == "Go to Rates") {
      history.push("/ADI/profile/rates-cancellation-policy");
    }
  };

  var errorsView = errors.map(function (error: any, key) {
    return (
      <div key={key}>
        {availableJobs?.length === 0 && (
          <EmptyState
            title={
              <p className="text-sm font-bold mb-2 flex flex-col items-center">
                {error.errorMessage[0]} <br></br>
                {error.errorMessage[1] && (
                  <Button
                    colour="yellow"
                    size="large"
                    className="mt-3"
                    halfWidth
                    onClick={() => redirct(error.errorMessage[1])}
                  >
                    {error.errorMessage[1]}
                  </Button>
                )}
              </p>
            }
            Icon={ExclamationCircleIcon}
            description=""
          />
        )}

        {error.errorMessage[1] === "Go to Documents" && (
          <div className="-mt-6">
            {jobsBenefits.map((item, index) => (
              <div key={index}>
                <DisclosureItem
                  disclosureCard
                  title={item.title}
                  disclosureIcon={item.icon}
                  disclosureCardSize="md"
                >
                  <p className="px-4 pt-0 pb-4">{item.desc}</p>
                </DisclosureItem>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  });
  var availableJobsRows =
    availableJobs?.length == 0 && loading ? (
      <>
        <AvailableJobCard loading={loading} />
      </>
    ) : (
      availableJobs &&
      availableJobs?.length > 0 &&
      availableJobs?.map(function (job: Job, key) {
        return (
          <div key={"divmain" + key}>
            <AvailableJobCard
              canAcceptJobs={canAcceptJobs}
              completeSteps={props.completeSteps}
              unsavedSteps={props.unsavedSteps}
              key={"job" + key}
              jobData={job}
              handleOnJobsUpdated={handleOnJobsUpdated}
              isFromSMS={false}
              isFromNewJobsSMS={isJobFromNewJobsSMS}
              editBids={editBids}
              isSmartSorted={props.isSmartSorted}
            />
          </div>
        );
      })
    );

  return (
    <>
      {/* <h3 className='mb-0 fw-black fs-2 text-uppercase'> {!errors || errors?.length == 0 ? `Jobs (${availableJobs?.length})` : ''} </h3> */}
      {props.isShowFilter && (
        <>
          <FilterJobsDialog
            searchObject={filterObject.SearchObject}
            show={props.showFilterDialog}
            handleSearch={(SearchObject) => handleOnJobFilters(SearchObject)}
            onHide={props.hideFilterDialog}
            defaultSearchResetValues={defaultSearch}
            minHourRate={minHourRate ?? 1}
            maxHourRate={maxHourRate ?? 100}
            getMinAndMaxHourRates={getMinAndMaxHourRatesFn}
          />

          <SortJobsDialog
            show={props.showSortDialog}
            onHide={() => {
              props.hideSortDialog();
            }}
            isBank={false}
            completeSteps={undefined}
            onAcceptAnyway={undefined}
            setSort={(sortBy: any, sortDirection: any) =>
              handleSortChange(sortBy, sortDirection)
            }
          />
        </>
      )}

      {errorsView}

      <InfiniteScroll
        key="infinite"
        dataLength={availableJobs.length} //This is important field to render the next data
        next={fetchData}
        hasMore={totalRecords > availableJobs.length}
        loader={<></>}
        className="overflow-visible"
      >
        <div
          key={"jobRow"}
          className="grid grid-cols-1 xl:grid-cols-3 sm:grid-cols-2 gap-4"
        >
          {availableJobsRows}
          {availableJobs && availableJobs?.length > 0 && loading && (
            <AvailableJobCard loading={loading} />
          )}
        </div>
      </InfiniteScroll>
      <NewJobModalParent
        show={
          showRegisterInterest ||
          showWithdrawDialog ||
          showRegisterLocationInterest
        }
        onClose={() => onHide(false)}
        onUpdate={() => onHide(true)}
        showRegisterInterest={showRegisterInterest}
        showRegisterLocationInterest={showRegisterLocationInterest}
        showRegisterAvailabilityInterest={showRegisterAvailabilityInterest}
        showWithdrawDialog={showWithdrawDialog}
        showClashDialog={false}
        title={getModalTitle()}
        clashedData={jobData}
        acceptJobData={jobData}
        cancelClash={() => { }}
        isSubmitting={false}
        acceptTheJob={() => { }}
      />
      {/* 
      {jobId != null && <AcceptJobDialog
        show={show}
        onHide={() => {
          setShow(false)
          handleOnJobsUpdated()
        }}
        jobId={jobId}
        recruiter={recruiter}
        urlGenerationDateTime={urlGenerationDateTime}
        onSubmit={() => {
          setShow(false)
          handleOnJobsUpdated()
        }}
        isFromSMS={true}
        isFromNewJobsSMS={false}
      />} */}
    </>
  );
};

export { AvailableJobList };
