import { FC, useEffect, useState } from "react";
import {
  getCustomJobReminder,
  getCutomJobAlerts,
  getNewJobReminder,
  updateCutomJobReminder,
  updateNewJobReminder,
} from "../../../../core/services/adi-service";
import PageHeader from "../../shared/elements/PageHeader";
import Button from "../../shared/elements/Button";
import Toggler from "../../shared/forms/Toggler";
import Skeleton from "@mui/material/Skeleton";
import Notification from "../../shared/overlays/Notification";
import toast from "react-hot-toast";
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  PencilSquareIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { CustomJobReminderDialog } from "./CustomJobReminderDialog";
import { CustomJobReminderModel } from "../../../../core/models/custom-job-reminder-model";
import { DeleteJobReminderDialog } from "./DeleteJobReminderDialog";
import { ActionMenu } from "../../shared/forms/ActionMenu";

const Communication: FC = () => {
  const [loading, setLoading] = useState(false);
  const [newJobReminder, setNewJobReminder] = useState(0);
  const [customJobReminder, setCustomJobReminder] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const [showReminderModal, setShowReminderModal] = useState(false);
  const [showDeleteReminderModal, setShowDeleteReminderModal] = useState(false);
  const [selectedAlert, setSelectedAlert] =
    useState<CustomJobReminderModel | null>();
  const [remindersList, setRemindersList] =
    useState<Array<CustomJobReminderModel>>();

  useEffect(() => {
    getInitialValues();
  }, []);

  const getInitialValues = async () => {
    setLoading(true);
    const newJobReminder = await getNewJobReminder();
    const customJobReminder = await getCustomJobReminder();
    const customJobRemindersList = await getCutomJobAlerts();

    Promise.all([newJobReminder, customJobReminder])
      .then(() => {
        setLoading(false);
        setNewJobReminder(
          newJobReminder.data.results.is_new_job_reminder_enabled
        );
        setCustomJobReminder(
          customJobReminder.results.is_customized_job_reminder_enabled
        );
        setRemindersList(customJobRemindersList.results);
      })
      .catch(() => setLoading(false));
  };

  const submitNewJobReminder = async (newValue: number) => {
    setSubmitting(true);
    const update = await updateNewJobReminder({
      is_new_job_reminder_enabled: newValue,
    });
    if (update.data.results) {
      showSuccessToast();
      getInitialValues();
    } else {
      showErrorToast();
    }
  };

  const submitCustomJobReminder = async (newValue: number) => {
    setSubmitting(true);
    const update = await updateCutomJobReminder({
      is_customized_job_reminder_enabled: newValue,
    });
    if (update.results) {
      showSuccessToast();
      getInitialValues();
    } else {
      showErrorToast();
    }
  };

  const showSuccessToast = () => {
    setSubmitting(false);
    toast.custom(
      () => (
        <Notification
          colour="green"
          title={"Saved successfully"}
          description={""}
          Icon={CheckCircleIcon}
        />
      ),
      {
        duration: 5000,
        position: "top-center",
      }
    );
  };

  const showErrorToast = () => {
    setSubmitting(false);
    toast.custom(
      () => (
        <Notification
          colour="red"
          title={"Failed to update personal data!"}
          description={""}
          Icon={ExclamationTriangleIcon}
        />
      ),
      {
        duration: 5000,
        position: "top-center",
      }
    );
  };

  return (
    <>
      <div className="mb-5">
        <PageHeader title="Communication" backToURL="/ADI/settings/overview" />
      </div>
      <div className="bg-white py-4 px-5">
        <div className="mb-5">
          {
            <Toggler
              label={`New Job SMS Reminders`}
              checked={!!newJobReminder}
              onChange={(newValue: boolean) => {
                setNewJobReminder(+newValue);
                submitNewJobReminder(+newValue);
              }}
              disabled={submitting}
              loading={loading}
            />
          }
        </div>

        <div className="mb-8">
          {
            <Toggler
              label={`Personalised SMS Job Alerts`}
              checked={!!customJobReminder}
              onChange={(newValue: boolean) => {
                setCustomJobReminder(+newValue);
                submitCustomJobReminder(+newValue);
              }}
              disabled={submitting}
              loading={loading}
            />
          }
        </div>

        {loading ? (
          <Skeleton
            className="!rounded-full"
            height={35}
            animation="wave"
            variant="rounded"
          ></Skeleton>
        ) : (
          <div className="w-fit me-0 ms-auto my-2">
            <Button
              type="button"
              disabled={submitting}
              onClick={() => {
                setSelectedAlert(null);
                setShowReminderModal(true);
              }}
              colour="darkBlue"
              loading={loading}
            >
              <div className="flex space-x-1 items-center py-1">
                <PlusIcon className="w-4 h-4" />
                <span className="text-xs">Add new Alert</span>
              </div>
            </Button>
          </div>
        )}
        {loading ? (
          <div className="flex items-center justify-between bg-gray-100 rounded p-3 my-2">
            <Skeleton width={100} animation="wave" />
            <Skeleton width={14} height={24} className="me-2" />
          </div>
        ) : (
          <>
            {remindersList?.map((reminder, key) => (
              <div
                key={reminder + " " + key}
                className="flex items-center justify-between bg-gray-100 rounded p-3 mt-3"
              >
                <p className="text-sm">{"Alert #" + (key + 1)}</p>
                <ActionMenu
                  options={[
                    {
                      Icon: PencilSquareIcon,
                      title: "Edit reminder",
                      show: true,
                      onClick: () => {
                        setSelectedAlert(reminder);
                        setShowReminderModal(true);
                      },
                    },
                    {
                      Icon: TrashIcon,
                      title: "Delete reminder",
                      show: true,
                      onClick: () => {
                        setSelectedAlert(reminder);
                        setShowDeleteReminderModal(true);
                      },
                    },
                  ]}
                ></ActionMenu>
              </div>
            ))}
          </>
        )}

        <CustomJobReminderDialog
          show={showReminderModal}
          alertInfo={selectedAlert ?? null}
          onHide={() => setShowReminderModal(false)}
          refreshParent={getInitialValues}
        />

        <DeleteJobReminderDialog
          show={showDeleteReminderModal}
          alertInfo={selectedAlert!}
          onHide={() => setShowDeleteReminderModal(false)}
          refreshParent={getInitialValues}
        />
      </div>
    </>
  );
};

export { Communication };
