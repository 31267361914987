import { useFormik } from "formik";
import { FC, Fragment, useEffect, useState } from "react";
import * as Yup from "yup";
import { yearYup } from "../../shared/validations";
import { pdiNumberExpression } from "../../../../core/models/enums/patterns-enum";
import { DrivingTuitionModel } from "../../../../core/models/driving-tuition-model";
import { formatFromDate } from "../../../helpers/DateHelper";
import {
  getDrivingTuition,
  updateDrivingTuition,
} from "../../../../core/services/adi-service";
import ReactGA from "react-ga4";
import { scrollToFirstError, scrollToTop } from "../../../helpers/ScrollHelper";
import RadioInput from "../../shared/forms/RadioInput";
import Input from "../../shared/forms/Input";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "../../shared/elements/Button";
import { toAbsoluteUrl } from "../../../helpers";
import Skeleton from "@mui/material/Skeleton";
import {
  CheckBadgeIcon,
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import Alert from "../../shared/overlays/Alert";
import toast from "react-hot-toast";
import Notification from "../../shared/overlays/Notification";

interface IProps {
  parentWrapper: string;
  goToNextTab: any;
  goToPrevTab: any;
  allowSkip: any;
  handleShow: any;
  hasUploadedBadge: any;
  badge: any;
}

const AdiPdiSettingsTemplate: FC<IProps> = (props) => {
  const requiredMsg = "This field is required";
  const pdiMsg = "ADI/PDI number must be 5 - 6 digits long";
  const tomorrow: Date = new Date();
  const [isPDI, setIsPDI] = useState(false);

  tomorrow.setDate(tomorrow.getDate() + 1);
  const validationSchema = Yup.object().shape({
    is_adi_trainer: Yup.number().required(requiredMsg).nullable(),
    is_pdi_trainer: Yup.number().required(requiredMsg).nullable(),
    adi_number: Yup.string()
      .required(requiredMsg)
      .matches(new RegExp(pdiNumberExpression + "$"), pdiMsg),
    adi_grade: Yup.string().nullable(),
    is_with_franchise:
      props.parentWrapper === "questionnaire" ||
      props.parentWrapper === "document"
        ? Yup.number().nullable()
        : Yup.number().when("is_adi_trainer", {
            is: (is_adi_trainer: number) => is_adi_trainer == 1,
            then: () => Yup.number().required(requiredMsg).nullable(),
            otherwise: () => Yup.number().nullable(),
          }),
    reserved_hours_count: Yup.number().nullable(),
    franchise_name: Yup.string().when("is_with_franchise", {
      is: (is_adi_trainer: number) => is_adi_trainer == 1,
      then: () => Yup.string().required(requiredMsg).nullable(),
      otherwise: () => Yup.string().nullable(),
    }),
  });
  const formik = useFormik({
    initialValues: new DrivingTuitionModel(),
    enableReinitialize: true,
    validationSchema,
    onSubmit,
  });
  const [loading, setLoading] = useState(false);
  function onSubmit(
    values: DrivingTuitionModel,
    { setStatus, setSubmitting }: any
  ) {
    if (values.is_with_franchise !== 1) {
      values.franchise_name = undefined;
    }

    let loadingId = toast.custom(
      () => (
        <Notification
          colour="gray"
          title={"Updating driving tuition..."}
          description={""}
          Icon={ExclamationCircleIcon}
        />
      ),
      {
        duration: 3000,
        position: "top-center",
      }
    );

    let newItem = JSON.parse(JSON.stringify(values));

    updateDrivingTuition(newItem)
      .then((data) => {
        ReactGA.event("update_driving_tuition");
        setSubmitting(false);
        setStatus(null);
        toast.custom(
          () => (
            <Notification
              colour="green"
              title={"Saved successfully"}
              description={""}
              Icon={CheckCircleIcon}
            />
          ),
          {
            duration: 5000,
            position: "top-center",
            id: loadingId,
          }
        );

        props.goToNextTab();
        scrollToTop();
      })
      .catch((res) => {
        setSubmitting(false);
        toast.custom(
          () => (
            <Notification
              colour="red"
              title={"Failed to update driving tuition!"}
              description={""}
              Icon={ExclamationTriangleIcon}
            />
          ),
          {
            duration: 5000,
            position: "top-center",
            id: loadingId,
          }
        );
        scrollToTop();
        if (res?.errors?.length) {
          setStatus(res.errors[0].errorMessage);
        } else {
          setStatus("Sorry, an error has occurred");
        }
      });
  }

  useEffect(() => {
    setLoading(true);
    //getLatestBadge()
    getDrivingTuition()
      .then((data) => {
        const drivingTuition = data?.data?.results;
        drivingTuition.is_with_franchise = drivingTuition?.adi_number
          ? drivingTuition?.franchise_name
            ? 1
            : 2
          : 0;
        drivingTuition.adi_grade = drivingTuition?.adi_grade
          ? drivingTuition.adi_grade
          : "";
        drivingTuition.franchise_name = drivingTuition?.franchise_name
          ? drivingTuition.franchise_name
          : "";

        // setHasUploadedBadge(drivingTuition.has_required_badge_documents == 1)
        if (drivingTuition.is_adi_trainer == null) {
          drivingTuition.is_adi_trainer = 1;
        }
        if (drivingTuition) {
          formik.setValues(drivingTuition);
        }
      })
      .finally(() => setLoading(false));
  }, []);

  // function getLatestBadge() {
  //     getAdiLatestBadge()
  //         .then((res) => {
  //             setBadge(res.data.results)
  //         }).catch((res) => {
  //         })
  // }

  useEffect(() => {
    scrollToFirstError(formik);
  }, [formik.errors, formik.isSubmitting, formik.isValidating]);

  useEffect(() => {
    setIsPDI(formik.values.is_adi_trainer != 1);
  }, [formik.values.is_adi_trainer]);

  return (
    <>
      <form onSubmit={formik.handleSubmit} noValidate>
        {formik.status ? (
          <Alert colour="red" icon="pmf-icon-info" title={formik.status} />
        ) : (
          <></>
        )}

        <h2 className="block text-base font-bold leading-5 text-darkBlue">
          {loading ? (
            <Skeleton width={150} height={50}></Skeleton>
          ) : (
            <>
              {props.parentWrapper !== "document"
                ? ""
                : "Are you now an ADI or still a PDI?"}
            </>
          )}
        </h2>
        {/* start:: ADI/PDI  */}
        {loading ? (
          <>
            <Skeleton width={200} height={50}></Skeleton>
            <Skeleton width={100} height={50}></Skeleton>
            <Skeleton width={100} height={50}></Skeleton>
          </>
        ) : (
          <RadioInput
            label="Are you an ADI or PDI?"
            name="is_adi_trainer"
            options={[
              { value: 1, label: "ADI" },
              { value: 0, label: "PDI" },
            ]}
            error={
              formik.touched.is_adi_trainer && formik.errors.is_adi_trainer
                ? true
                : false
            }
            errorMsg={formik.errors.is_adi_trainer}
            onClick={(value) => formik.setFieldValue("is_adi_trainer", value)}
            value={formik.values.is_adi_trainer}
            disabled={formik.isSubmitting}
            required={true}
            optionsNoWrap={true}
          />
        )}
        {/* end:: ADI/PDI  */}

        {props.parentWrapper != "questionnaire" && (
          <>
            {" "}
            {loading ? (
              <>
                <Skeleton className="w-full" height={50}></Skeleton>
                <Skeleton className="w-full" height={50}></Skeleton>
              </>
            ) : (
              <>
                <p className="block text-base font-bold leading-5 text-darkBlue mt-4 -mb-2">
                  How many hours do you reserve for the test date?
                </p>
                <Input
                  {...formik.getFieldProps("reserved_hours_count")}
                  type="number"
                  label="Reserved hours"
                  placeholder="Reserved hours"
                  name="reserved_hours_count"
                  className="form-control"
                  id="reserved_hours_count"
                  error={
                    formik.touched.reserved_hours_count &&
                    formik.errors.reserved_hours_count
                      ? true
                      : false
                  }
                  errorMsg={formik.errors.reserved_hours_count}
                  onChange={formik.handleChange}
                  disabled={formik.isSubmitting}
                />
              </>
            )}
          </>
        )}

        {loading ? (
          <Skeleton className="w-full" height={50}></Skeleton>
        ) : (
          <div className="-my-1">
            <Input
              {...formik.getFieldProps("adi_number")}
              type="number"
              label={
                formik.values.is_adi_trainer === 1 ? "ADI number" : "PDI number"
              }
              placeholder="ADI/PDI number"
              error={
                formik.touched.adi_number && formik.errors.adi_number
                  ? true
                  : false
              }
              errorMsg={formik.errors.adi_number}
              name="adi_number"
              id="adi_number"
              onChange={formik.handleChange}
              disabled={formik.isSubmitting}
              required={true}
              hasInfo={true}
              alertMsg="This is the 6-digit number on the front of your badge"
            />
          </div>
        )}

        {formik.values.is_adi_trainer == 1 && (
          <>
            {" "}
            {loading ? (
              <>
                <Skeleton width={200} height={50}></Skeleton>
                <Skeleton width={100} height={50}></Skeleton>
                <Skeleton width={100} height={50}></Skeleton>
                <Skeleton width={100} height={50}></Skeleton>
              </>
            ) : (
              <>
                <RadioInput
                  label="ADI Grade"
                  required={false}
                  error={
                    formik.touched.adi_grade && formik.errors.adi_grade
                      ? true
                      : false
                  }
                  errorMsg={formik.errors.adi_grade}
                  onClick={(value) => formik.setFieldValue("adi_grade", value)}
                  value={formik.values.adi_grade}
                  name="adi_grade"
                  options={[
                    { value: "A", label: "A" },
                    { value: "B", label: "B" },
                  ]}
                  disabled={formik.isSubmitting}
                  optionsNoWrap={true}
                />
                <RadioInput
                  label="Are you a PDI Trainer?"
                  name="is_pdi_trainer"
                  options={[
                    { value: 1, label: "Yes" },
                    { value: 0, label: "No" },
                  ]}
                  error={
                    formik.touched.is_pdi_trainer &&
                    formik.errors.is_pdi_trainer
                      ? true
                      : false
                  }
                  errorMsg={formik.errors.is_pdi_trainer}
                  onClick={(value) =>
                    formik.setFieldValue("is_pdi_trainer", value)
                  }
                  value={formik.values.is_pdi_trainer}
                  disabled={formik.isSubmitting}
                  required={true}
                  optionsNoWrap={true}
                />
              </>
            )}
          </>
        )}

        {props.parentWrapper !== "questionnaire" &&
        props.parentWrapper !== "document" ? (
          <>
            {" "}
            {loading ? (
              <>
                <Skeleton width={200} height={50}></Skeleton>
                <Skeleton width={100} height={50}></Skeleton>
                <Skeleton width={100} height={50}></Skeleton>
              </>
            ) : (
              <RadioInput
                label="Are you currently part of a franchise?"
                required={true}
                error={
                  formik.touched.is_with_franchise &&
                  formik.errors.is_with_franchise
                    ? true
                    : false
                }
                errorMsg={formik.errors.is_with_franchise}
                onClick={(value) =>
                  formik.setFieldValue("is_with_franchise", value)
                }
                value={formik.values.is_with_franchise}
                name="is_with_franchise"
                options={[
                  { value: 1, label: "Yes" },
                  { value: 2, label: "No" },
                ]}
                disabled={formik.isSubmitting}
                optionsNoWrap={true}
              />
            )}
          </>
        ) : (
          <></>
        )}

        {props.parentWrapper !== "document" &&
          formik.values.is_with_franchise === 1 && (
            <>
              {" "}
              {loading ? (
                <Skeleton className="w-full" height={50}></Skeleton>
              ) : (
                <Fragment>
                  <Input
                    label=" Franchise/Parent Company"
                    required={true}
                    error={
                      formik.touched.franchise_name &&
                      formik.errors.franchise_name
                        ? true
                        : false
                    }
                    errorMsg={formik.errors.franchise_name}
                    type="text"
                    name="franchise_name"
                    className="form-control"
                    id="franchise_name"
                    value={formik.values.franchise_name}
                    onChange={formik.handleChange}
                    disabled={formik.isSubmitting}
                  />
                </Fragment>
              )}
            </>
          )}

        <div
          className={`${
            props.parentWrapper === "questionnaire"
              ? "flex-row-reverse"
              : "flex-row"
          } my-3 flex justify-between gap-2`}
        >
          {loading ? (
            <>
              <Skeleton
                className="!rounded-full"
                height={35}
                variant="rounded"
              ></Skeleton>
              <Skeleton
                className="!rounded-full"
                height={35}
                variant="rounded"
              ></Skeleton>
            </>
          ) : (
            <>
              <Button
                disabled={formik.isSubmitting}
                colour="yellow"
                size="large"
                type="submit"
              >
                {!loading && (
                  <span className="indicator-label">
                    {props.parentWrapper === "questionnaire"
                      ? "Save & Continue"
                      : "Save"}
                  </span>
                )}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    Please wait...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </Button>
              {props.parentWrapper != "questionnaire" ? (
                <Button
                  disabled={formik.isSubmitting}
                  onClick={() => props.goToPrevTab()}
                  colour="outline"
                  size="large"
                >
                  Discard
                </Button>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      </form>
    </>
  );
};

export { AdiPdiSettingsTemplate };
