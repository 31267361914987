import { PaymentMethods } from "./enums/payment-methods-enum";

export class MoneyIn {
    public student_name: string|undefined;
    public lesson_date: string|undefined;
    public transaction_date: string|undefined;
    public product_type: any|undefined;
    public product_id: number|undefined;
    public amount: number|undefined;
    public payment_method_id: number|undefined = PaymentMethods.Stripe;
    public transaction_date_formatted: string|undefined;
    public job_id: string|undefined;
    public student_id: string|undefined;
}