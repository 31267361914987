import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { saveAdiClickedPage } from "./app/core/services/adi-service";

const PageViewTracker = () => {
  const history = useHistory();

  useEffect(() => {
    const adiClickedPage = async (e: any) => {
      await saveAdiClickedPage(e).catch(() => {});
    };

    const unlisten = history.listen((location) => {
      // Skip sending page view for the initial mount
      if (history.action !== "POP") {
        adiClickedPage({
          page_url: location.pathname,
        });
      }
    });

    // Send initial page view on component mount
    adiClickedPage({
      page_url: history.location.pathname,
    });

    return () => {
      unlisten();
    };
  }, [history]);

  return null; // PageViewTracker doesn't render anything to the DOM
};

export default PageViewTracker;
