import { useState } from "react";
import Modal from "../shared/overlays/Modal";
import InfoList from '../shared/data/InfoList';
import { deletePrivateStdTest } from "../../../core/services/job-service";
import toast from "react-hot-toast";
import Notification from "../shared/overlays/Notification";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { deletePrivateTest } from "../../../core/services/test-service";

export type DeletePrivateStudentTestModalProps = {
  jobId: string;
  testLocation: string;
  testDate: string;
  onClose: () => void;
  open: boolean;
  testId?: string
};

const DeletePrivateStudentTestModal = ({ jobId, testLocation, testDate, open, onClose, testId }: DeletePrivateStudentTestModalProps) => {
    const [loading, setLoading] = useState<boolean>(false)

    const onHide = () => {
        if (!loading) {
            setLoading(false)
            onClose()
        }
    }

    const deleteTest = async () => {
        setLoading(true)
        const deletedTest = await deletePrivateTest(testId).catch(() => {
          setLoading(false);
        });
        const isDeleted = await deletePrivateStdTest(jobId)
            .catch(() => { setLoading(false) });

        if (deletedTest) {
          setLoading(false);
          onClose();
          toast.custom(
            () => (
              <Notification
                colour="green"
                title={"Test is deleted successfully!"}
                description={""}
                Icon={ExclamationTriangleIcon}
              />
            ),
            {
              duration: 5000,
              position: "top-center",
            }
          );
        }
    }

    return (
        <Modal
            open={open}
            onClose={onHide}
            onSubmit={deleteTest}
            submitText='Remove'
            closeText='Cancel'
            title='Remove test'
            description={'Are you sure you want to remove this test?'}
            disabled={loading}
        >
            <InfoList
                data={[
                    {
                        icon: 'pmf-icon-test',
                        value: testLocation ?? '-',
                    },
                    {
                        icon: 'pmf-icon-clock',
                        value: testDate ?? '-',
                    }
                ]}
            />
        </Modal>
    )
}


export { DeletePrivateStudentTestModal }
