import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import Badge from "./Badge";
import Skeleton from "@mui/material/Skeleton";
import LinkItem from "./LinkItem";
import { scrollToTagBySelector } from "../../../helpers/ScrollHelper";

export interface DisclosureItemProps {
  fullWidth?: boolean;
  count?: number;
  id?: string;
  title?: string | React.ReactNode;
  subTitle?: string;
  disclosureIcon?: string;
  disclosureImg?: string;
  customDescription?: React.ReactNode;
  children?: React.ReactNode;
  disclosureCard?: boolean;
  disclosureCardSize?: "xs" | "sm" | "md" | "lg";
  background?: string;
  loading?: boolean;
  progress?: boolean;
  expanded?: boolean;
  linkName?: string;
  linkURL?: string;
  hidden?: boolean;
  storageKey?: string
  border?: string;
  disableTitleClicked?: boolean;
  onTitleClicked?: () => void;
}

export default function DisclosureItem({
  id,
  title = '',
  subTitle,
  disclosureIcon,
  disclosureImg,
  fullWidth,
  count = 0,
  customDescription,
  children,
  disclosureCard = false,
  disclosureCardSize = 'lg',
  background = "bg-white",
  loading,
  progress,
  expanded = false,
  linkName,
  linkURL,
  hidden,
  storageKey,
  border,
  disableTitleClicked = false,
  onTitleClicked,
}: DisclosureItemProps) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen)
      scrollToTagBySelector('#' + id)
  }, [isOpen])

  useEffect(() => {
    if (expanded)
      setIsOpen(expanded)
  }, [expanded])

  return (
    <div key={expanded === true || loading === true ? 1 : 0}>
      <Disclosure
        as="div"
        hidden={hidden}
        defaultOpen={expanded}
        className={`
        ${{
            xs: "mb-0",
            sm: "mb-0",
            md: "mb-3",
            lg: " mb-5"
          }[disclosureCardSize]
          }
        ${disclosureCard ? `${background} rounded-[10px]` : " mb-8"
          }
          ${border ? border : ''} `}
      >
        {({ open }) => (
          <>
            <Disclosure.Button
              disabled={count == 0 && linkURL != null}
              onClick={() => setIsOpen(!isOpen)}
              className={`
              ${disclosureCard && (
                  {
                    xs: "py-1.5",
                    sm: "p-3",
                    md: "py-3 px-4",
                    lg: "p-4"
                  }[disclosureCardSize])
                }
              flex w-full justify-between items-center text-darkBlue font-bold`}
            >
              {disclosureCard ? (
                <>
                  <div
                    className="flex items-center justify-center min-w-0 gap-x-4 flex-grow"
                    onClick={(event) => {
                      if (disableTitleClicked) event.preventDefault();                        
                      if (onTitleClicked) onTitleClicked();
                    }}
                  >
                    {disclosureIcon && <div className="shrink-0">
                      {loading ? <Skeleton width={30} height={50} />
                        : <i className={`text-3xl ${disclosureIcon}`}></i>}
                    </div>}
                    {disclosureImg && <div className="shrink-0">
                      {loading ? <Skeleton width={30} height={50} />
                        : <>
                          <img
                            className='w-100'
                            src={disclosureImg}
                            alt=""
                          />
                        </>
                      }
                    </div>}
                    <div className="min-w-0 flex-auto flex flex-col justify-start items-start">
                      <div className="flex items-center w-full">
                        <div className="w-full">
                        <h3 className={`
                        ${{
                            xs: "text-small",
                            sm: "text-base",
                            md: "text-lg",
                            lg: "text-xl mb-1"
                          }[disclosureCardSize]
                          }
                        font-titlef text-left`}>  {loading ? <>
                            <Skeleton width={150} className="me-2" />
                          </> :
                            <>
                              {title}
                            </>} </h3>
                        
                          {customDescription ? <>{customDescription}</> : <></>}
                        </div>
                        {(loading && progress) ? <>
                          <Skeleton variant="circular" width={20} height={20} />
                        </> :
                          <>
                            {(progress || count > 0) && (
                              <Badge notificationBadge={true} title={count ? count : 0} size="mdBadge" />
                            )}
                          </>}
                      </div>
                    </div>
                  </div>
                  {loading ? (
                    <>
                      <Skeleton variant="circular" width={20} height={20} />
                    </>
                  ) : (
                    <div className="flex md:ps-2 ps-4">
                    <ChevronDownIcon
                      className={`${
                        open ? "rotate-180 transform" : ""
                      } h-4 w-4 text-darkBlue`}
                    />
                    </div>)}

                </>
              ) : (
                <>
                  <h3 className="flex items-center">
                    <span
                      className={`${disclosureCard
                        ? "text-base font-normal font-normalf"
                        : "text-2xl leading-none font-titlef"
                        }`}
                    >
                      {loading ? <>
                        <Skeleton width={150} className="me-2" />
                      </> :
                        <>
                          {linkURL != null && count == 0 ?
                            <LinkItem url={storageKey ? sessionStorage.getItem(storageKey) != null ? linkURL + sessionStorage.getItem(storageKey) : linkURL : linkURL}  >
                              <span className="text-2xl leading-none font-titlef">{title}</span>
                            </LinkItem> :
                            <> {title} </>
                          }
                        </>}


                    </span>
                    {loading ? <>
                      <Skeleton variant="circular" width={20} height={20} />
                    </> :
                      <>
                        {count > 0 && (
                          <Badge notificationBadge={true} title={count} size="mdBadge" />
                        )}
                      </>}

                  </h3>

                  {/* {loading ? <>
                      <Skeleton variant="circular" width={20} height={20} />
                      </> :
                      <>
                      {count > 0 && (
                        <Badge notificationBadge={true} title={count} />
                      )}
                    </>} */}

                  {count > 0 && (
                    <ChevronDownIcon
                      className={`${open ? "rotate-180 transform" : ""
                        } h-4 w-4 text-darkBlue`}
                    />
                  )}
                  {loading ? <>
                    <Skeleton variant="circular" width={20} height={20} />
                  </> :
                    <>
                      {count == 0 && linkURL &&
                        <div className="font-thin text-xs">
                          <LinkItem url={storageKey ? sessionStorage.getItem(storageKey) != null ? linkURL + sessionStorage.getItem(storageKey) : linkURL : linkURL} seeAllIcon={true} >
                            <span className="text-sm">See all {linkName}</span>
                          </LinkItem>
                        </div>
                      }
                    </>}
                </>
              )}
            </Disclosure.Button>
            {(isOpen || open) && <Disclosure.Panel
              id={id}
              className={`${disclosureCard ? "" : "pt-4"
                } text-sm`}
            >
              {children}
            </Disclosure.Panel>}
          </>
        )}
      </Disclosure>
    </div>
  );
}
