/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react";
import PageHeader from "../../components/shared/elements/PageHeader";
import { StudentsListComponent } from "../../components/students/StudentsListComponent";
import { getStudentListFilter } from "../../../core/services/job-service";
import ReactGA from "react-ga4";
import { AddPrivateStudentDialog } from "../../components/students/AddPrivateStudentDialog";
import { LearnerJobStatusEnum } from "../../../core/models/enums/learner-job-status-enum";
import { StudentList } from "../../../core/models/student-list";

const StudentsWrapper: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [students, setStudents] = useState<StudentList>();
  const [show, setShow] = useState(false);
  const [filterObject, setFilterObject] = useState<any>({
    searchObject: { job_learner_status_id: LearnerJobStatusEnum.Active },
  });
  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
    getStudentsList();
  };

  useEffect(() => {
    ReactGA.event("open_students_tab");
  }, []);

  useEffect(() => {
    getStudentsList();
  }, [filterObject]);

  const getStudentsList = () => {
    setLoading(true);
    getStudentListFilter(filterObject.searchObject)
      .then((res) => {
        setStudents(res.data.results);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  useEffect(() => {
    document.title = `ADI Network App | Learners | ADI Network`;
  }, []);

  return (
    <>
      <div className="px-5">
        <div className="mb-5">
          <PageHeader title="Learners" />
        </div>

        <StudentsListComponent
          students={students}
          loading={loading}
          handleShow={handleShow}
          setFilterObject={setFilterObject}
        />
        <AddPrivateStudentDialog
          show={show}
          onHide={handleClose}
          type={"add"}
          jobDetails={null}
          refresh={handleClose}
        />
      </div>
    </>
  );
};

export { StudentsWrapper };
