import * as prismic from '@prismicio/client'

export const repositoryName = 'https://adi-network.cdn.prismic.io/api/v2'

export const createClient = prismic.createClient(repositoryName, {
  accessToken: 'MC5aU3ozMVJBQUFDSUF6S2ZL.77-977-9Km7vv71C77-9JO-_ve-_ve-_ve-_vQAF77-9RhDvv70a77-977-9Ue-_ve-_vTbvv706dO-_ve-_vSUH',
  routes: [
    {
      type: 'videos',
      path: '/ADI/CPD',
    },
    {
      type: 'cpd_article',
      path: '/ADI/CPD',
    },
  ],
})




const apiEndpoint = 'https://adi-network.cdn.prismic.io/api/v2';
const accessToken = 'MC5aU3ozMVJBQUFDSUF6S2ZL.77-977-9Km7vv71C77-9JO-_ve-_ve-_ve-_vQAF77-9RhDvv70a77-977-9Ue-_ve-_vTbvv706dO-_ve-_vSUH';

const client = prismic.createClient(apiEndpoint, { accessToken });

export const fetchPrismicData = async (contentType: any) => {
  try {
    const response = await client.getByType(contentType);
    return response.results;
  } catch (error) {
    console.error('Error fetching data from Prismic: ', error);
    return [];
  }
};