/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/20/solid";
import { FC } from "react";
import { Car } from "../../../../core/models/car";
import Badge from "../elements/Badge";
import { ActionMenu } from "../forms/ActionMenu";

interface IJobBidCardProp {
  title: string
  value: string
  showActionMenu: boolean
  options: Array<any>
}

const JobBidCard: FC<IJobBidCardProp> = ({ title, value, showActionMenu, options }) => {

  return (
    <>
      <div className="flex flex-1 justify-between rounded-[10px] bg-gray-100 py-4 px-6 mb-4">
        <div>
          <h5 className="mb-2 block text-xs leading-snug tracking-normal text-darkBlue antialiased">
            {title}
          </h5>
          <p className="block text-xs font-bold leading-relaxed text-inherit antialiased">
            {value}
          </p>
        </div>
        {showActionMenu ? <><ActionMenu options={options}></ActionMenu></> : <></>}
        
      </div>
    </>
  );
};

export { JobBidCard };
