import { useState } from "react";
import { Test } from "../../../core/models/tests/test";
import { cancelTestOverlap, checkTestOverlap, confirmJobTest } from "../../../core/services/job-service";
import InfoList from "../shared/data/InfoList";
import Alert from "../shared/overlays/Alert";
import Modal from "../shared/overlays/Modal";
import ReactGA from 'react-ga4'
import { format } from 'date-fns';
import { ClashDetailsDialog } from "../jobs/modals/ClashDetailsDialog";

export type ConfirmTestModalProps = {
    test: Test
    refreshTests: () => void,
    closeModal: () => void
    open: boolean
};

const ConfirmTestModal = ({ test, refreshTests, open, closeModal }: ConfirmTestModalProps) => {

    const [submitting, setSubmitting]: any = useState()
    const [showClashDialog, setShowClashDialog] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const [clashedData, setClashedData] = useState<any>()

    const confirmJobTestRequest = () => {
        (async () => {
            setErrorMessage(null)
            const confirmResponse = await confirmJobTest({
                test_id: test.id,
                job_ref: test.deal_id
            }).catch(() => { })
            setSubmitting(false)

            if (confirmResponse?.status === 200) {
                ReactGA.event('accept_test')
                closeModal()
                refreshTests()
            } else {
                if (confirmResponse?.data?.errors && confirmResponse?.data?.errors?.length > 0) {
                    setErrorMessage(confirmResponse.data.errors[0])
                } else {
                    setErrorMessage('Sorry, an error has occurred')
                }
            }

        })()
    }

    const checkClash = () => {
        (async () => {
            setErrorMessage(null)
            const overlapResponse = await checkTestOverlap({
                job_ref: test.deal_id
                , test_date: format(new Date(test.booked_for), 'yyyy-MM-dd ')
            }).catch(() => { })
            if (overlapResponse?.status === 200) {
                if (overlapResponse?.data?.results.has_clash) {
                    setClashedData(overlapResponse?.data?.results);
                    setShowClashDialog(true);
                } else {
                    confirmJobTestRequest();
                }
            } else {
                setErrorMessage('Sorry, an error has occurred')
            }
            setSubmitting(false);
        })()
    }

    const cancelClash = () => {
        (async () => {
            setErrorMessage(null)
            setSubmitting(true);
            const cancelOverlapResponse = await cancelTestOverlap({
                job_ref: test.deal_id
                , test_date: format(new Date(test.booked_for), 'yyyy-MM-dd ')
            }).catch(() => { setSubmitting(false) })
            if (cancelOverlapResponse?.status === 200) {
                closeModal();
                setShowClashDialog(false);
            } else {
                setErrorMessage('Sorry, an error has occurred')
            }
            setSubmitting(false);
        })()
    }

    return (
        <>
            <Modal
                title="You are about to confirm the following test"
                onSubmit={checkClash}
                onClose={closeModal}
                submitText="Confirm"
                closeText="Cancel"
                disabled={submitting}
                open={open}
                hideClose={showClashDialog}
                hideSubmit={showClashDialog}>
                {errorMessage && <Alert description={errorMessage} colour="red"></Alert>}

                {showClashDialog ?
                    <ClashDetailsDialog
                        show={showClashDialog}
                        onClose={cancelClash}
                        accept={confirmJobTestRequest}
                        data={clashedData}
                        isTest={true}
                        isSubmitting={submitting}
                    ></ClashDetailsDialog> :
                    <>
                        <InfoList data={[
                            {
                                icon: 'pmf-icon-user',
                                value: test?.student?.full_name ?? '',
                            },
                            {
                                icon: 'pmf-icon-test',
                                value: test?.location?.name ?? '',
                            },
                            {
                                icon: 'pmf-icon-clock',
                                value: test?.booked_for_human,
                            },
                            {
                                icon: 'pmf-icon-suitcase',
                                value: `#${test?.deal_id?.toString()}`,
                                badge: true
                            }
                        ]}>
                        </InfoList>

                        <p className="text-sm mt-5">
                            Are you sure you want to accept this test?
                        </p>
                    </>
                }
            </Modal>
        </>)
}


export { ConfirmTestModal }
