import { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  ResponsiveContainer,
  AreaChart,
  CartesianGrid,
  XAxis,
  YAxis,
  ReferenceLine,
  Tooltip,
  Area,
  Label,
} from "recharts";

interface IProps {
  chartGraphData: any;
  hitNumber: any;
  loading: boolean;
}
const DetailsAreaChart: FC<IProps> = (props) => {
  const [minValue, setMinValue] = useState(0);
  const [average, setAverage] = useState(0);
  const [maxValue, setMaxValue] = useState(0);

  useEffect(() => {
    if (props.chartGraphData.length > 0) {
      // Find the minimum value
      const values = props.chartGraphData.map((item: any) => item.value);
      const min = Math.min(...values);
      setMinValue(min);

      // Calculate the average
      const sum = values.reduce((acc: number, val: number) => acc + val, 0);
      const avg = sum / values.length;
      setAverage(avg);

      // Calculate the maximum value with a buffer higher than hitNumber
      const maxValueWithBuffer = 100;
      const maxValueFromData = Math.max(...values);
      setMaxValue(
        maxValueWithBuffer > maxValueFromData
          ? maxValueWithBuffer
          : maxValueFromData
      );
    }
  }, [props.chartGraphData, props.hitNumber]);

  return (
    <>
      <div className="relative [&>*]:!font-normalf [&>*]:text-sm">
        {/* <div className="absolute">
          <span className="bg-darkBlue rounded-full py-1 px-2 text-white">
            {props.hitNumber}
          </span>
        </div> */}
        
        {/* */}
        <ResponsiveContainer width="100%" height={320}>
          <AreaChart
            data={props.chartGraphData}
            margin={{
              top: 10,
              right: 0,
              left: 20,
              bottom: 0,
            }}
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#A3B0BF" stopOpacity={0.9} />
                <stop offset="95%" stopColor="#F6F7F9" stopOpacity={0.9} />
              </linearGradient>
            </defs>

            <CartesianGrid stroke="#EFEFEF" />
            <XAxis dataKey="month" axisLine={false} tickLine={false} />
            <YAxis
              type="number"
              axisLine={false}
              tickLine={false}
              tick={true} // Hide the tick marks
              domain={[minValue, maxValue]} // Set the calculated domain
              width={20} // Adjust the width as needed
            />
            {/* Add reference line for the average */}
            <ReferenceLine
              y={average >= 0 ? average : 0}
              stroke="#2BCD5A"
              strokeWidth={1.5}
              strokeDasharray="4 4"
            />
            {/* Add reference line for hitNumber */}
            <defs>
              <filter id="rounded-corners" x="-5%" width="110%" y="0%" height="100%">
              <feFlood flood-color="#163A5E"/>
              <feGaussianBlur stdDeviation="2"/>
              <feComponentTransfer>
                <feFuncA type="table"tableValues="0 0 0 1"/>
              </feComponentTransfer>
              <feComponentTransfer>
                <feFuncA type="table"tableValues="0 1 1 1 1 1 1 1"/>
              </feComponentTransfer>
                <feComposite operator="over" in="SourceGraphic"/>
              </filter>
            </defs>
            <ReferenceLine y={props.hitNumber} stroke="#163A5E" strokeWidth={1.5} strokeDasharray="4 4"
             label={{
              value: props.hitNumber,
              position: "insideBottomLeft",
              fill: "#fff",
              filter: "url(#rounded-corners)",
              dx: -30, // Adjust as needed to move the label outside the CartesianGrid
              dy: 10, // Adjust as needed to position the label
            }}
            >

            </ReferenceLine>
            {/* <ReferenceLine
              y={props.hitNumber}
              stroke="#163A5E"
              strokeWidth={1.5}
              strokeDasharray="4 4"
            /> */}
            <Tooltip />
            <Area
              type="monotone"
              dataKey="value"
              stroke="#163A5E"
              strokeWidth={3}
              fillOpacity={1}
              fill="url(#colorUv)"
            />
          </AreaChart>
        </ResponsiveContainer>
        {/* */}
      </div>
    </>
  );
};

export { DetailsAreaChart };
