import React from "react";
import { toAbsoluteUrl } from "../../../helpers";
import Badge from "../elements/Badge";
import { ActionMenu } from "../forms/ActionMenu";
import Skeleton from '@mui/material/Skeleton';

export interface StackedCardWithActionsMenuProps
  extends React.HTMLAttributes<HTMLDivElement> {
  // data: {
  icon?: string;
  title?: string;
  description?: string;
  pmfIcon?: boolean;
  status?: {
    title: string;
    color: string;
  };
  pillTitle?: string | null;
  actions?: any,
  showAction?: boolean,
  imageUrl?: string
  children?: React.ReactNode;
  showButtonAction?: boolean
  loading?: boolean
  isCanlenderView?: boolean
}

export default function StackedCardWithActionsMenu({
  icon,
  title,
  description,
  pmfIcon = false,
  status,
  pillTitle,
  actions,
  showAction = true,
  children,
  imageUrl,
  showButtonAction,
  loading,
  isCanlenderView = false,
  ...props
}: StackedCardWithActionsMenuProps) {
  return (
    <>
      <div className="bg-white rounded-lg p-3">
        <div className="flex justify-between align-items-center gap-x-6">
          <div className="flex min-w-0 gap-x-4">
            {loading ? <Skeleton width={60} height={60} animation="wave"></Skeleton> :
              <>
                {icon &&
                  <div className={`h-9 w-9 flex items-center justify-center rounded-full + ${isCanlenderView ? "bg-darkBlue text-white" : "bg-gray-50"}`}>
                    <i className={`${icon} text-base`}></i>
                  </div>
                }
                {imageUrl &&
                  <img
                    className="h-12 w-12 flex-none bg-gray-50"
                    src={toAbsoluteUrl(imageUrl)}
                    alt=""
                  />
                }
              </>
            }
            <div className="min-w-0 flex-auto">
              <p className="text-sm">
                {loading ? <Skeleton width={130} height={30} animation="wave"></Skeleton> :
                  <> {title} </>
                }
              </p>
              <p className="text-sm font-bold">
                {loading ? <Skeleton width={200} height={30} animation="wave"></Skeleton> :
                  <> {description} </>}
              </p>
            </div>
          </div>
          <div className="shrink-0 flex flex-col items-end">
            { actions?.length > 0 && showAction ?
              (<div className="ml-4 flex flex-shrink-0">
                {showButtonAction ? <>{actions}</> : <ActionMenu options={actions} ></ActionMenu>}
              </div>) : <></>
            }
          </div>
        </div>

        {children}
        <div className="flex justify-between align-items-center gap-x-6">
          {loading ?  <div className="flex item-center pt-3">
              <Skeleton width={14} height={14}  className="me-2" />
                           <Skeleton  width={100} animation="wave" />
                        </div> :
          status && <div className="flex min-w-0 gap-x-4 pt-3">
            <div className="min-w-0 flex-auto">
              <div className="flex items-center gap-x-1.5">
                <div className="flex-none rounded-full">
                  <div
                    className={`
                        ${{
                        darkGreen: "bg-green",
                        yellow: "bg-yellow",
                        darkRed: "bg-danger",
                      }[status?.color]
                      } 
                        h-1.5 w-1.5 rounded-full`}
                    />
                  </div> 
                  <p className="text-sm">{status?.title}</p>
          
                  {pmfIcon ? (
                    <img
                      src={toAbsoluteUrl("/assets/media/logos/pmf.svg")}
                      className="h-4"
                      alt="pmf"
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          }
          <div className="shrink-0 flex flex-col items-end">
            {loading ? <div className="pt-3"><Skeleton width={150}  animation="wave"></Skeleton></div> : pillTitle && <div className="pt-3"><Badge roundedPill={false} title={pillTitle} colour="gray"></Badge></div>}
          </div>
        </div>
      </div>
    </>
  );
}
