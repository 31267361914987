export const scrollToFirstError = (formik: any) => {
        let keys = Object.keys(formik.errors);
        if (keys.length > 0) {
            const selector = `[name=${keys[0]}]`;
            const errorElement = document.querySelector(selector) as HTMLElement;
            if (errorElement) {
                errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                // errorElement.focus({ preventScroll: true });
            }
        }
}

export const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    });
};
export const scrollToTagBySelector = (selector: any) => {
    const targetElement = document.querySelector(selector) as HTMLElement;
    if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
}

export const scrollInstantToTagBySelector = (selector: any) => {
  const targetElement = document.querySelector(selector) as HTMLElement;
  if (targetElement) {
    targetElement.scrollIntoView({ behavior: "instant", block: "center" });
  }
};