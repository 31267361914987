import { FC, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Alert from "../../../../shared/overlays/Alert";
import Modal from "../../../../shared/overlays/Modal";
import toast from "react-hot-toast";
import Notification from "../../../../shared/overlays/Notification";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { AvailabilityModel } from "../../../../../../core/models/availability-modal";
import { Availability } from "../../../../account-settings/job-preferences/Availability";
import { DayModel } from "../../../../../../core/models/day-model";
import { scrollToTop } from "../../../../../helpers/ScrollHelper";
import { addPrivateStudentavAilability, getStudentWeekDays } from "../../../../../../core/services/student-service";
import Skeleton from "@mui/material/Skeleton";

type Props = {
  jobDetails: any;
  show: any;
  onHide: any;
  availability: Array<AvailabilityModel>;
  loading: boolean;
  refresh: any;
  setPrivateStudentAvailibility: any;
};

const AddPrivateStudentAvailabilityDialog: FC<Props> = ({
  jobDetails,
  show,
  onHide,
  availability,
  loading,
  refresh,
  setPrivateStudentAvailibility,
}) => {
  const [overLap, setOverLap] = useState<Array<boolean>>([]);
  const [weekDays, setWeekDays] = useState<Array<DayModel>>([])

  useEffect(() => {
    async function fetchWeekDays() {
      const weekDaysPromise = await getStudentWeekDays()
      setWeekDays(weekDaysPromise.results)
    }
    fetchWeekDays();
    
    setPrivateStudentAvailibility(parseAvilabilityDates(availability));
  }, [availability]);
  const updatePersonalSchema = Yup.object().shape({
    availability: Yup.array().min(1, "Availability is required"),
  });

  const parseAvilabilityDates = (job: AvailabilityModel[]) => {
    job.forEach((day_availability_item) => {
      let from = day_availability_item.from_time.split(":");
      let to = day_availability_item.to_time.split(":");
      if (from.length == 3) {
        day_availability_item.from_time = `${from[0]}:${from[1]}`;
      }
      if (to.length == 3) {
        day_availability_item.to_time = `${to[0]}:${to[1]}`;
      }
    });
    return job;
  };

  const overlapping = (a: any, b: any) => {
    const getMinutes = (s: any) => {
      const p = s.split(":").map(Number);
      return p[0] * 60 + p[1];
    };
    return (
      getMinutes(a.to_time) > getMinutes(b.from_time) &&
      getMinutes(b.to_time) > getMinutes(a.from_time)
    );
  };

  const isOverlapping = (arr: any) => {
    let i, j;
    for (i = 0; i < arr.length - 1; i++) {
      for (j = i + 1; j < arr.length; j++) {
        if (overlapping(arr[i], arr[j])) {
          return true;
        }
      }
    }
    return false;
  };

  const checkAvailabilityError = (): boolean => {
    if (formik.values.availability.length == 0) {
      return true;
    }
    if (
      formik.values.availability.findIndex(
        (a) => a.from_time == "" || a.to_time == "" || a.day_id == null
      ) > -1
    ) {
      return true;
    }

    let x = formik.values.availability.filter((slot) => {
      if (slot.to_time && slot.from_time && slot.to_time <= slot.from_time) {
        return slot;
      }
    });
    if (x.length > 0) {
      return true;
    }

    return false;
  };

  const checkOverLap = () => {
    setOverLap([]);
    let overLapExists: Array<boolean> = [];
    weekDays.forEach((day) => {
      let daySlots = formik.values.availability.filter((slot) => {
        if (slot.day_id == day.id) {
          return slot;
        }
      });
      overLapExists.push(isOverlapping(daySlots));
    });
    setOverLap(overLapExists);

    if (overLapExists.indexOf(true) > -1) {
      return true;
    }
  };
  const formik = useFormik({
    initialValues: {
      availability: availability,
    },
    enableReinitialize: true,
    validationSchema: updatePersonalSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setStatus("");
      let hasAvailabilityError = checkAvailabilityError();
      let hasOverLap = checkOverLap();
      if (hasAvailabilityError || hasOverLap) {
        setSubmitting(false);
        if (hasOverLap || hasAvailabilityError) {
          scrollToTop();
        }
        return;
      }
      await submitJobPreferenceRequest().then(() => {
        toast.custom(
          () => (
            <Notification
              colour="gray"
              title={"Student Availability Updated Successfully"}
              description={""}
              Icon={CheckCircleIcon}
            />
          ),
          {
            duration: 5000,
            position: "top-center",
          }
        );
        onHide();
        refresh();
      });
    },
  });

  async function submitJobPreferenceRequest() {
    await addPrivateStudentavAilability(
      jobDetails?.student_id,
      formik.values
    ).catch((error) => {
      formik.setSubmitting(false);
      if (error && error.errors && error.errors.length > 0) {
        formik.setSubmitting(false);
        formik.setStatus(error.errors[0].errorMessage);
      } else {
        formik.setSubmitting(false);
        formik.setStatus("Sorry, an error has occured");
      }
    });
    formik.setSubmitting(true);
  }

  const close = () => {
    formik.resetForm();
    onHide();
  };

  return (
    <>
      <form noValidate id="kt_update_form">
        <Modal
          open={show}
          onClose={close}
          onSubmit={formik.handleSubmit}
          submitText="Save"
          closeText="Cancel"
          title={"Availability"}
          disabled={formik.isSubmitting}
          disableSubmit={formik.isSubmitting}
        >
          {formik.status ? <Alert title={formik.status}> </Alert> : <></>}
          {loading ? (
            <Skeleton width={50} className="me-2" />
          ) : (
            <Availability
              formik={formik}
              submitted={formik.isSubmitting}
              weekDays={weekDays}
              overLap={overLap}
              availabilityList={formik.values.availability}
              loading={loading}
            />
          )}
        </Modal>
      </form>
    </>
  );
};

export { AddPrivateStudentAvailabilityDialog };
