/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react'
const CurrentJobsWrapper: FC = () => {
  useEffect(() => {
    document.title = `ADI Network App | Jobs | ADI Network`;
  }, []);
  
  return (
    <>
      <div className='d-flex flex-wrap px-5 py-6 p-lg-0'>
      CurrentJobsWrapper works
      </div>
    </>
  )
}

export { CurrentJobsWrapper }
