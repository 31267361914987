import React from "react";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  colour?: "yellow" | "darkBlue" | "outline" | "gray" | "link" | "dangerLink" | "lightGray" | "transparent" | "darkGray";
  size?: "icon" | "medium" | "large" | "fit" | string;
  rounded?: "full" | "sm"|"none";
  className?: string;
  Icon?: any;
  fullWidth?: boolean;
  halfWidth?: boolean;
  fitWidth?: boolean;
  onClick?: (e?:any) => void;
  loading?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
  type?: "button" | "submit";
}

export default function Button({
  colour = "yellow",
  className = "",
  size = "medium",
  rounded="full",
  Icon,
  children,
  fullWidth,
  halfWidth = false,
  fitWidth = false,
  onClick,
  loading,
  disabled,
  type = "button",
  ...props
}: ButtonProps) {
  // types: primary, secondary, warning, danger
  return (
<>    
{/* <button className="text-darkBlue bg-yellow h-[34px] rounded-full w-full text-xs border-none">
            Collect Payment
          </button> */}
    <button
      type={type}
      className={`${className} ${fullWidth ? "w-full" : (halfWidth ? "w-1/2" : (fitWidth? "w-fit" : "flex-1"))} ${
        {
          
          yellow: "bg-yellow  border-[0.8px] border-yellow text-darkBlue hover:brightness-75",
          darkBlue: "bg-darkBlue border-[0.8px] border-darkBlue text-white hover:brightness-75",
          outline: "bg-transparent border-[0.8px] border-darkBlue text-darkBlue hover:brightness-75",
          gray: "bg-gray-300 border-[0.8px] border-gray-300  hover:brightness-75",
          lightGray: "bg-[#F4F5F7] border-[0.8px] border-[#F4F5F7] text-darkBlue hover:brightness-75",
          darkGray: "bg-[#eceff1] border-[0.8px] border-[#eceff1] text-darkBlue hover:brightness-75",
          link: "bg-transparent border-0 text-darkBlue hover:brightness-100",
          dangerLink: "bg-transparent border-0 text-danger hover:brightness-100",
          transparent: "bg-transparent border-transparent hover:brightness-75",
        }[colour]
      } ${
        {
          icon: "p-0 ms-2",
          medium: "py-1 px-4",
          large: "py-2 px-2",
          fit:"p-0 h-fit"
        }[size]
      } ${
        {
          full: "rounded-full",
          sm: "rounded",
          none:"rounded-none"
        }[rounded]
      } flex relative items-center justify-center ${
        disabled ? "opacity-50" : "opacity-100"
      } relative font-semibold text-xs outline-none transition-all`}
      onClick={onClick}
      disabled={disabled ? disabled : loading ? true : undefined}
      {...props}
    >
        <div
          className={`flex items-center ${loading ? "opacity-0" : ""
            } transition-all ${(!children && Icon) ? 'inline-flex items-center justify-center rounded-full bg-[#F4F5F7] w-[30px] h-[30px] shadow-sm hover:bg-gray-50' : ''}`}
        >
        {Icon && <Icon className={`h-4 w-4 ${!children? 'mx-auto h-4 w-4 text-darkBlue' : 'mr-3'}`} />}
        <div className={(colour === "link" || colour === "dangerLink") ? 'mt-2 underline' : ''}>{children}</div>
      </div>
      {loading && (
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
          <svg
            className="animate-spin h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>
      )}
    </button>
    </>

  );
}
