import { FC, useEffect, useState } from "react";
import AddNewEmptyState from "../../shared/elements/AddNewEmptyState";
import { LessonCard } from "../../shared/cards/LessonCard";
import { getDashboardLessons } from "../../../../core/services/job-service";
import Slider from "react-slick";
import DisclosureItem from "../../shared/elements/DisclosureItem";
import LinkItem from "../../shared/elements/LinkItem";
import { AddEditLessonModel } from "../../lessons/_modals/AddEditLessonModel";

const LessonsSummary: FC<any> = ({ }) => {
  const [isLoading, setIsloading] = useState<boolean>(true);
  const [lessons, setLessons] = useState<Array<any>>([]);
  const [selectedLesson, setSelectedLesson] = useState<any>();
  const [lessonsCount, setLessonsCount] = useState<any>();
  const [showAddLessonCard, setShowAddLessonCard] = useState<boolean>(false)
  useEffect(() => {
    setIsloading(false);
 
  }, []);
  var settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3.25,
    slidesToScroll: 1.5,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 2.5,
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1.65,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1.35,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1.15,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 350,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  };
  const getDashboardLessonsList = () => {
    setIsloading(true)
    getDashboardLessons()
      .then((res) => {
       setLessons(res?.data?.results?.Results)
       setLessonsCount(res?.data?.results?.TotalRecords)
        setIsloading(false)
      })
      .catch(() => {
        setIsloading(false)
      })
  };
  useEffect(() => {
    getDashboardLessonsList();
  }, []); 
  
  return (
    <>
     <AddEditLessonModel
          onClose={() => {
            setShowAddLessonCard(false)
          }}
          duration={1}
          firstDate={null}
          jobId={selectedLesson?.job_id}
          id={undefined}
          lessonsUpdated={() => {getDashboardLessonsList()}}
          show={showAddLessonCard}
          isPrivateJob={selectedLesson?.is_private_student}
          lesson_type={selectedLesson?.lesson_type}
       />
      <DisclosureItem title="Lessons" count={lessonsCount} loading={isLoading} linkName="lessons" linkURL="/ADI/lessons">
        <Slider {...settings}>
        {lessons.map((job, index) => {
          return (  
            <div key={index} className="pr-3">
              <LessonCard 
              title={job.hours} 
              data={job}
              buttons={[
                {
                text: (
                    <span>{job.is_ready_first_lesson ? 'Add First Lesson' : 'Schedule Next Lesson'}</span>
                ),
                colour: job.is_ready_first_lesson ? 'outline': 'yellow',
                onClick: () => {
                  setSelectedLesson(job) 
                  setShowAddLessonCard(true) 
                  }
                },
              ]}
              />
            </div>
            )
          })
          }
          <div className="pr-3">
            <AddNewEmptyState onClick={() => {
                  setSelectedLesson(null) 
                  setShowAddLessonCard(true) 
                  }}></AddNewEmptyState>
          </div>
        </Slider>
        <div className="flex justify-end w-full mt-[24px]">
          <LinkItem url="/ADI/lessons" seeAllIcon={true}>
            <span className="text-sm">See all lessons</span>
          </LinkItem>
        </div>
      </DisclosureItem>
    </>
  );
};

export { LessonsSummary };
