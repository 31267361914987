import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import * as auth from "../../../pages/auth/redux/AuthRedux";
import { SubmitPersonalDataModel } from "../../../../core/models/submit-personal-data-model";
import { PersonalDataModel } from "../../../../core/models/personal-data-model";
import {
  deleteMyAccount,
  getPersonalData,
  updatePersonalData,
} from "../../../../core/services/adi-service";
import { IQuationnaireTabProps } from "../../../../core/models/quationnaire-tab-props";
import { scrollToFirstError } from "../../../helpers/ScrollHelper";
import { phoneExpression } from "../../../../core/models/enums/patterns-enum";
import ReactGA from "react-ga4";
import { RootState } from "../../../../../setup";
import { DeleteAdiAccountModal } from "./DeleteAdiAccountModal";
import Alert from "../../shared/overlays/Alert";
import PageHeader from "../../shared/elements/PageHeader";
import Input from "../../shared/forms/Input";
import Button from "../../shared/elements/Button";
import Skeleton from "@mui/material/Skeleton";
import toast from "react-hot-toast";
import Notification from "../../shared/overlays/Notification";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import { checkAdiHasActivePmfJobs } from "../../../../core/services/job-service";
import { getPendingStudentPayment } from "../../../../core/services/connect-service";

const PersonalDetails: React.FC<IQuationnaireTabProps> = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
  const [hasActivePmfCourses, setHasActivePmfCourses] = useState(false);
  const [hasActiveStripePayment, setHasActiveStripePayment] = useState(false);
  const [personalData, setPersonalData] = useState<PersonalDataModel>({
    name: "",
    sur_name: "",
    email: "",
    email_id: "",
    phone: "",
    phone_id: "",
    phone_verification_date: null,
    email_verification_date: null,
    has_expired_doc: 0,
    has_expired_insurance: 0,
    has_expired_badge: 0,
    badge_remaining_days: 0,
    insurance_remaining_days: 0,
    hide_new_jobs_tab: 0,
    hide_new_jobs_tab_for_impersonate: 0,
    is_impersonate: 0,
    request_delete_account_at: null,
    is_stripe_connect_view_enabled: 0,
  });

  const validPhone = new RegExp(phoneExpression + "$");
  const updatePersonalSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Email is required"),
    name: Yup.string()
      .min(2, "Minimum 2 symbols")
      .max(50, "Maximum 50 symbols")
      .required("First Name is required"),
    sur_name: Yup.string()
      .min(2, "Minimum 2 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Surname is required"),
    phone: Yup.string()
      .matches(validPhone, "Invalid phone number")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Mobile Number is required"),
  });

  React.useEffect(() => {
    setLoading(true);
    getData();
    getCheckAdiHasActivePmfJobs();
    getHasActiveStripePayment();
  }, []);

  function getData() {
    getPersonalData().then((data) => {
      setPersonalData(data?.data?.results);
      setLoading(false);
    });
  }

  async function getCheckAdiHasActivePmfJobs() {
    const res = await checkAdiHasActivePmfJobs();
    setHasActivePmfCourses(res?.results);
  }

  const getHasActiveStripePayment = () => {
    getPendingStudentPayment()
      .then((res) => {
        setHasActiveStripePayment(res?.data?.total_records > 0);
      })
      .catch(() => {
        setHasActiveStripePayment(false);
      });
  };

  const handleAccountDelete = () => {
    setShowDeleteAccountModal(true);
    if (hasActivePmfCourses) {
      sendDeleteRequest();
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const formik = useFormik({
    initialValues: {
      name: personalData.name,
      sur_name: personalData.sur_name,
      email: personalData.email,
      phone: personalData.phone?.includes("+44") ? personalData.phone?.slice(3,) : personalData.phone,
    },
    enableReinitialize: true,
    validationSchema: updatePersonalSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setStatus("");
      let submitData: SubmitPersonalDataModel = prepareSubmitData(values);
      setLoading(true);

      let loadingId = toast.custom(
        () => (
          <Notification
            colour="gray"
            title={"Updating your contact details..."}
            description={""}
            Icon={ExclamationCircleIcon}
          />
        ),
        {
          duration: 5000,
          position: "top-center",
        }
      );

      updatePersonalData(submitData)
        .then((data) => {
          toast.custom(
            () => (
              <Notification
                colour="green"
                title={"Saved successfully"}
                description={""}
                Icon={CheckCircleIcon}
              />
            ),
            {
              duration: 5000,
              position: "top-center",
              id: loadingId,
            }
          );

          ReactGA.event("update_personal_details");
          dispatch(auth.actions.setPersonalData(data?.data?.results));
          setLoading(false);
          props.goToNextTab();
          setSubmitting(false);
          scrollToTop();
        })
        .catch((error) => {
          toast.custom(
            () => (
              <Notification
                colour="red"
                title={"Failed to update contact details!"}
                description={""}
                Icon={ExclamationTriangleIcon}
              />
            ),
            {
              duration: 5000,
              position: "top-center",
              id: loadingId,
            }
          );
          setLoading(false);
          setSubmitting(false);
          scrollToTop();
          if (error && error.errors && error.errors.length > 0) {
            setSubmitting(false);
            setStatus(error.errors[0].errorMessage);
          } else {
            setSubmitting(false);
            setStatus("Sorry, an error has occurred");
          }
        });
    },
  });

  useEffect(() => {
    scrollToFirstError(formik);
  }, [formik.errors, formik.isSubmitting, formik.isValidating]);

  function prepareSubmitData(
    data: SubmitPersonalDataModel
  ): SubmitPersonalDataModel {
    const submittedData: SubmitPersonalDataModel = {
      name: data.name,
      sur_name: data.sur_name,
      phone: !personalData.phone_id
        ? { phone_number: Number(data.phone) }
        : { id: personalData.phone_id, phone_number: Number(data.phone) },
      email: !personalData.email_id
        ? { email: data.email }
        : { id: personalData.email_id, email: data.email },
    };
    return submittedData;
  }

  function sendDeleteRequest() {
    deleteMyAccount({ hasActiveJobs: hasActivePmfCourses })
      .then(() => {
        getData();
      })
      .catch(() => {});
  }

  const setSubmit = () => {
    if (submitted === false) {
      setSubmitted(true);
    }
  };

  return (
    <div className="">
      {props.parentWrapper === "questionnaire" ? (
        <h4 className="pt-4 -mb-4 px-5 text-base font-bold">Contact Details</h4>
      ) : (
        <div className="mb-5">
          <PageHeader
            title="Contact Details"
            backToURL={
              props.parentWrapper !== "questionnaire"
                ? "/ADI/settings/overview"
                : ""
            }
          />
        </div>
      )}
      <div className="bg-white py-4 px-5">
        <div
          className={
            `col-12  m-auto` +
            (props.parentWrapper === "questionnaire"
              ? "col-lg-10"
              : "col-lg-12")
          }
        >
          {props.parentWrapper === "questionnaire" &&
            !props.selfRegisterDate && (
              <Alert
                colour="orange"
                icon="pmf-icon-info"
                title={
                  "Welcome to our new ADI Network app. \nAs we contacted you before, we already hold some of your data in our systems. Please review and update these records in order to access the app."
                }
              />
            )}

          <form onSubmit={formik.handleSubmit} noValidate>
            <div className="card card--form-container ">
              {formik.status && (
                <Alert description={formik.status} colour="red"></Alert>
              )}

              {/* begin::Form group */}
              {loading ? (
                <Skeleton
                  className="w-full"
                  height={50}
                  animation="wave"
                ></Skeleton>
              ) : (
                <Input
                  {...formik.getFieldProps("name")}
                  type="text"
                  label="First Name"
                  placeholder=""
                  error={
                    formik.touched.name && formik.errors.name ? true : false
                  }
                  errorMsg={formik.errors.name}
                  required={true}
                  name="name"
                  disabled={formik.isSubmitting}
                  id="name"
                />
              )}
              {/* end::Form group */}

              {/* begin::Form group */}
              {loading ? (
                <Skeleton
                  className="w-full"
                  height={50}
                  animation="wave"
                ></Skeleton>
              ) : (
                <Input
                  {...formik.getFieldProps("sur_name")}
                  type="text"
                  label="Surname"
                  placeholder=""
                  error={
                    formik.touched.sur_name && formik.errors.sur_name
                      ? true
                      : false
                  }
                  errorMsg={formik.errors.sur_name}
                  required={true}
                  name="sur_name"
                  disabled={formik.isSubmitting}
                  id="sur_name"
                />
              )}
              {/* end::Form group */}

              {/* begin::Form group */}
              {loading ? (
                <Skeleton
                  className="w-full"
                  height={50}
                  animation="wave"
                ></Skeleton>
              ) : (
                <Input
                  {...formik.getFieldProps("phone")}
                  type="tel"
                  label="Mobile Number"
                  placeholder=""
                  inputGroup={true}
                  inputGroupValue="+44"
                  error={
                    formik.touched.phone && formik.errors.phone ? true : false
                  }
                  errorMsg={formik.errors.phone}
                  required={true}
                  name="phone"
                  onChange={(e) => {
                    formik.setFieldValue(
                      "phone",
                      e.target.value.length > 4
                        ? e.target.value.replace("+44", "")
                        : e.target.value
                    );
                  }}
                  value={formik.values.phone}
                  disabled={
                    formik.isSubmitting ||
                    personalData.phone_verification_date != null
                  }
                  id="phone"
                />
              )}
              {/* end::Form group */}

              {/* begin::Form group */}
              {loading ? (
                <Skeleton
                  className="w-full"
                  height={50}
                  animation="wave"
                ></Skeleton>
              ) : (
                <Input
                  {...formik.getFieldProps("email")}
                  type="text"
                  label="Email Address"
                  placeholder=""
                  error={
                    formik.touched.email && formik.errors.email ? true : false
                  }
                  errorMsg={formik.errors.email}
                  required={true}
                  name="email"
                  disabled={true}
                  id="email"
                />
              )}
              {/* end::Form group */}

              <div className="d-flex w-100">
                <div className="clearfix input-group mt-3">
                  {props.parentWrapper !== "questionnaire" &&
                  personalData.request_delete_account_at == null ? (
                    <div className="w-fit">
                      {loading ? (
                        <Skeleton
                          width={150}
                          height={30}
                          animation="wave"
                        ></Skeleton>
                      ) : (
                        <Button
                          colour="link"
                          id="delete_account"
                          onClick={() => handleAccountDelete()}
                          size="fit"
                        >
                          <span className="text-danger">Delete my account</span>
                        </Button>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <DeleteAdiAccountModal
                  submitForDelete={() => {
                    sendDeleteRequest();
                    setShowDeleteAccountModal(false);
                  }}
                  onHide={() => setShowDeleteAccountModal(false)}
                  show={showDeleteAccountModal}
                  hasActivePmfCourses={hasActivePmfCourses}
                  hasActiveStripePayment={hasActiveStripePayment}
                />
              </div>
            </div>

            <div className="my-3 flex space-x-2 justify-between items-center">
              <>
                {loading ? (
                  <>
                    <Skeleton
                      className={
                        props.parentWrapper !== "questionnaire"
                          ? "w-1/2 !rounded-full"
                          : "w-full !rounded-full"
                      }
                      height={35}
                      animation="wave"
                      variant="rounded"
                    ></Skeleton>
                    {props.parentWrapper !== "questionnaire" && (
                      <Skeleton
                        className="w-1/2 !rounded-full"
                        height={35}
                        animation="wave"
                        variant="rounded"
                      ></Skeleton>
                    )}
                  </>
                ) : (
                  <>
                    <Button
                      disabled={formik.isSubmitting}
                      onClick={() => {
                        setSubmit();
                      }}
                      colour="yellow"
                      size="large"
                      halfWidth={props.parentWrapper !== "questionnaire"}
                      type="submit"
                    >
                      {!loading && (
                        <span className="indicator-label">
                          {props.parentWrapper === "questionnaire"
                            ? "Save & Continue"
                            : "Save"}
                        </span>
                      )}
                      {loading && (
                        <span
                          className="indicator-progress"
                          style={{ display: "block" }}
                        >
                          Please wait...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                    </Button>
                    {props.parentWrapper !== "questionnaire" ? (
                      <Button
                        disabled={formik.isSubmitting}
                        onClick={() => props.goToPrevTab()}
                        colour="outline"
                        size="large"
                        halfWidth={true}
                      >
                        Discard
                      </Button>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export { PersonalDetails };
