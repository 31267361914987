import { FC } from "react";
import { toAbsoluteUrl } from "../../../helpers";

const CPDHeader: FC = () => {
  return (
    <>
      <div className="rounded-br-[10px] rounded-bl-[10px] px-5">
        <div className="text-center ">
          <div className="grid grid-cols-6 mb-0 w-full sm:max-w-md mx-auto m pt-4 content-end px-4">
            <div className="col-span-4 col-start-2">
              <img
                className="w-full mb-2"
                src={toAbsoluteUrl(
                  `/assets/media/logos/ADI-network-partnership.svg`
                )}
                alt=""
              />
            </div>
            <div className="col-span-6">
              <img
                className="w-full"
                src={toAbsoluteUrl(
                  `/assets/media/logos/intelligent-instructor.svg`
                )}
                alt=""
              />
            </div>
          </div>
          <p className="text-sm px-4 pt-5 pb-8">
            ADI Network is partnered <b>exclusively</b> with Intelligent
            Instructor+ to provide you with premium professional development and
            industry content <b>completely free of charge.</b> 
          </p>
        </div>
      </div>
    </>
  );
};

export default CPDHeader;
