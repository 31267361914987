import { FC, useState } from "react";

import DescriptionList from "../../components/shared/data/DescriptionList";
import { Job } from "../../../core/models/job";
import { setJobLimitExceededViewed } from "../../../core/services/job-service";
import Modal from "../../components/shared/overlays/Modal";
import { DeclineJobDialog } from "./DeclineJobDialog";
import { useHistory } from "react-router-dom";
import { JobLimitWarningDialog } from "./JobLimitWarningDialog";
import {
  JobBIDStatusEnum,
  JobBIDStatusList,
} from "../../../core/models/enums/job-bids-status-enum";

interface IProps {
  jobData?: Job | any;
  handleOnJobsUpdated?: any;
  unsavedSteps?: Array<number> | any;
  completeSteps?: any;
  canAcceptJobs?: boolean;
  isFromSMS?: boolean;
  isFromNewJobsSMS?: boolean;
  loading?: boolean;
  editBids?: any;
  isSmartSorted?: boolean;
}

const AvailableJobCard: FC<IProps> = (props) => {
  const history = useHistory();
  const [showMaxWarning, setShowMaxWarning] = useState(false);
  let pills: Array<{
    title: string;
    colour?:
      | "blue"
      | "green"
      | "red"
      | "darkGreen"
      | "yellow"
      | "gray"
      | "outlineRed";
  }> = [];
  if (props.jobData?.is_new_job == 1 && props.jobData?.is_updated_job != 1) {
    pills.push({
      colour: "outlineRed",
      title: "New",
    });
  }
  if (
    props.jobData?.is_parallel_tbc == 1 ||
    props.jobData?.is_test_date_confirmed == 0 ||
    (props.jobData?.is_private_student == 1 &&
      props.jobData?.test_date == null &&
      props.jobData?.test_center_name == null)
  ) {
    pills.push({
      colour: "blue",
      title: `TBC`,
    });
  }
  if (props.jobData?.is_updated_job == 1) {
    pills.push({
      colour: "red",
      title: "Updated",
    });
  }
  pills.push({
    colour: "green",
    title: props.jobData?.transmission_type_name,
  });

  /**
   * pill for Lesson only
   */
  if (
    (!props.jobData?.test_center_name || !props.jobData?.test_date) &&
    props.jobData?.pt_required_at == null
  ) {
    pills.push({
      colour: "yellow",
      title: `Lessons only`,
    });
  }

  let card: Array<{
    icon?:
      | "pmf-icon-map-pin"
      | "pmf-icon-currency-pound"
      | "pmf-icon-calendar"
      | "pmf-icon-intensity";
    value: string;
    type: string;
    info?: string;
    img?: string;
    isIntensity?: boolean;
    isAvailability?: boolean;
    showRateLabel?: boolean;
  }> = [
    {
      icon: "pmf-icon-map-pin",
      type: "Postcode",
      value: props.jobData?.student_post_code,
      info: props.jobData?.distance
        ? props.jobData?.distance?.toFixed(1) + "mi away"
        : undefined,
    },
    {
      icon: "pmf-icon-currency-pound",
      type: "Hourly rate",
      showRateLabel: props.jobData?.show_app_rate,
      value: Number.isInteger(props.jobData?.rate_per_hour)
        ? "£" + props.jobData?.rate_per_hour?.toLocaleString()
        : Number(props.jobData?.rate_per_hour)
        ? "£" + props.jobData?.rate_per_hour?.toFixed(2)
        : props.jobData?.rate_per_hour,
      info: Number.isInteger(props.jobData?.total_rate)
        ? "£" + props.jobData?.total_rate?.toLocaleString() + " total"
        : props.jobData?.total_rate != null
        ? "£" + props.jobData?.total_rate?.toFixed(2) + " total"
        : "",
    },
  ];
  if (props.jobData?.course_intensity != null) {
    card.push({
      icon: "pmf-icon-intensity",
      type: "Intensity",
      value: props.jobData?.course_intensity,
      isIntensity: true,
    });
  }
  if (
    props.jobData?.availability_match &&
    props.jobData?.availability_match >= 20
  ) {
    let match = "Great";
    if (
      props.jobData?.availability_match >= 20 &&
      props.jobData?.availability_match <= 34
    ) {
      match = "Fair";
    }
    if (
      props.jobData?.availability_match >= 35 &&
      props.jobData?.availability_match < 50
    ) {
      match = "Good";
    }
    card.push({
      icon: "pmf-icon-calendar",
      type: "Availability match",
      img: "availability/availability-" + match + ".svg",
      value: match,
      isAvailability: true,
    });
  }

  const handleShow = () => {
    if (props.jobData.has_exceeded_max_jobs == 1) {
      setJobLimitExceededViewed({ jobId: props.jobData.id })
        .then(() => {})
        .catch(() => {});
      setShowMaxWarning(true);
    } else {
      history.push("/ADI/new-jobs/details/" + props.jobData?.id);
    }
  };

  const handleOnJobsUpdated = () => {
    props.handleOnJobsUpdated();
  };

  const [showDeclineDialog, setShowDeclineDialog] = useState(false);
  const handleShowDeclineDialog = () => {
    setShowDeclineDialog(true);
  };
  const handleCloseDeclineDialog = () => {
    props.handleOnJobsUpdated();
    setShowDeclineDialog(false);
  };

  const practicalTestData = () => {
    let testData: {
      icon:
        | "pmf-icon-map-pin"
        | "pmf-icon-suitcase"
        | "pmf-icon-user"
        | "pmf-icon-clock"
        | "pmf-icon-test"
        | "pmf-icon-info"
        | undefined;
      value: string | undefined;
    }[] = [];

    if (
      (!props.jobData?.test_center_name || !props.jobData?.test_date) &&
      props.jobData?.pt_required_at == null
    ) {
      /**
       * Lessons Only. Mark location and time as N/A
       */
      testData.push({
        icon: "pmf-icon-test",
        value: "N/A",
      });
      testData.push({
        icon: "pmf-icon-clock",
        value: "N/A",
      });
    } else {
      if (
        props.jobData?.test_center_name ||
        props.jobData?.test_preferred_test_centers
      ) {
        testData.push({
          icon: "pmf-icon-test",
          value:
            props.jobData?.test_center_name ??
            props.jobData?.test_preferred_test_centers,
        });
      }

      if (
        props.jobData?.test_date_formatted ||
        props.jobData?.test_preferred_dates_str
      ) {
        testData.push({
          icon: "pmf-icon-clock",
          value:
            props.jobData?.test_date_formatted ??
            props.jobData?.test_preferred_dates_str,
        });
      }
    }

    return testData;
  };

  return (
    <>
      <DescriptionList
        bidStatus={
          props.jobData?.has_bid && props.jobData?.job_bid_status_name
            ? props.jobData?.job_bid_status_name
            : undefined
        }
        bidStatusObject={{
          ...JobBIDStatusList.find(
            (a) => a.id == props.jobData?.job_bid_status_id
          ),
          onClick: props.editBids,
          jobRef: props.jobData?.job_ref,
          canEdit: props.jobData?.can_edit_bid,
          canDelete: props.jobData?.can_delete_bid,
        }}
        title={props.jobData?.displayed_hours + " Hours"}
        pmfIcon={true}
        pills={pills}
        data={practicalTestData()}
        cardData={card}
        buttons={[
          {
            text: (
              <span>
                {props.jobData?.job_bid_status_id ==
                  JobBIDStatusEnum.AcceptedConnected ||
                props.jobData?.job_bid_status_id ==
                  JobBIDStatusEnum.AcceptedPendingADIConfirmation
                  ? "Accept Job"
                  : "View Details"}
              </span>
            ),
            size: "large",
            onClick: () => handleShow(),
          },
        ]}
        hideButton={{
          disabled:
            props.jobData?.is_updated_job != 1 &&
            props.jobData?.is_hidden_job == 1,
          text: <span>Hide </span>,
          onClick: () => handleShowDeclineDialog(),
        }}
        loading={props.loading}
        isRelevantJob={props.jobData?.isRelevantJob}
        isSmartSorted={props.isSmartSorted}
      />

      <JobLimitWarningDialog
        show={showMaxWarning}
        onHide={() => {
          setShowMaxWarning(false);
        }}
      />

      <DeclineJobDialog
        jobRef={props.jobData?.job_ref}
        jobId={props.jobData?.id}
        show={showDeclineDialog}
        jobRate={props.jobData?.rate_per_hour}
        hasPrevBid={props.jobData?.has_bid}
        adiHasBadge={props.jobData?.has_required_badge}
        adiRateBelowHourlyRate={props.jobData?.adi_rate_below_hourly_rate}
        onHide={handleCloseDeclineDialog}
        onSubmit={handleOnJobsUpdated}
      />
    </>
  );
};

export { AvailableJobCard };
