import { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { StudentCard } from "../shared/cards/StudentCard";
import {
  ExclamationCircleIcon,
  PlusSmallIcon,
} from "@heroicons/react/24/outline";
import EmptyState from "../shared/elements/EmptyState";
import Button from "../shared/elements/Button";
import Input from "../shared/forms/Input";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Tab } from "@headlessui/react";
import { Skeleton } from "@mui/material";
import { LearnerJobStatusEnum } from "../../../core/models/enums/learner-job-status-enum";
import { StudentList } from "../../../core/models/student-list";
export type StudentsListProps = {
  students: StudentList | undefined;
  loading: boolean;
  handleShow: () => void;
  setFilterObject: any;
};
function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}
export const StudentsListComponent: FC<StudentsListProps> = ({
  students,
  loading,
  handleShow,
  setFilterObject,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const history = useHistory();
  const filterSchema = Yup.object().shape({
    student_name: Yup.string().nullable(),
  });

  const formik = useFormik({
    initialValues: {
      student_name: searchQuery,
    },
    enableReinitialize: true,
    validationSchema: filterSchema,
    onSubmit: () => {},
  });

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchQuery(event.target.value);
    formik.setFieldValue("student_name", event.target.value);
  };

  const filteredStudents = students?.allStudents?.filter((student) => {
    const fullName = `${student?.student_first_name ?? ""} ${
      student?.student_surname ?? ""
    }`;
    return fullName.toLowerCase().includes(searchQuery.toLowerCase());
  });

  const tabs = [
    {
      key: LearnerJobStatusEnum.Active,
      title: "Active",
      count: students?.activeCount,
    },
    {
      key: LearnerJobStatusEnum.WaitList,
      title: "Waitlist",
      count: students?.waitCount,
    },
    {
      key: LearnerJobStatusEnum.OnHold,
      title: "On Hold",
      count: students?.holdCount,
    },
    {
      key: LearnerJobStatusEnum.Complete,
      title: "Complete",
      count: students?.completeCount,
    },
  ];

  const hasAnyCounter = tabs.some((tab) => tab.count !== undefined);

  const updateFilterObject = (index: number) => {
    setSearchQuery("");
    setFilterObject({
      searchObject: {
        job_learner_status_id: tabs[index].key,
      },
    });
  };

  const getStudentStatus = (status: number) => {
    if (status === LearnerJobStatusEnum.Complete) {
      return {
        title: "Complete",
        color: "darkGreen",
      };
    } else if (status === LearnerJobStatusEnum.OnHold) {
      return {
        title: "On Hold",
        color: "darkBlue",
      };
    } else if (status === LearnerJobStatusEnum.WaitList) {
      return {
        title: "Waitlist",
        color: "darkGray",
      };
    } else {
      return {
        title: "Active",
        color: "yellow",
      };
    }
  };

  return (
    <>
      <Tab.Group
        selectedIndex={selectedIndex}
        onChange={(index) => {
          setSelectedIndex(index);
          updateFilterObject(index);
        }}
      >
        <Tab.List className="flex space-x-0 mb-5 lg:mx-0 -ml-5">
          {tabs.map((tab) => (
            <Tab
              key={tab.key}
              className={({ selected }) =>
                classNames(
                  "w-full py-2.5 text-base font-medium leading-5",
                  "ring-white ring-opacity-60 ring-offset-2 ring-offset-transparent focus:outline-none focus:ring-none",
                  selected
                    ? " border-b-4 border-darkBlue"
                    : " text-[#A8A8A8] hover:brightness-75"
                )
              }
            >
              {loading ? (
                <div className="flex justify-center">
                  <Skeleton width={80} />
                </div>
              ) : (
                <span className="lg:text-base text-sm">
                  {tab.title}{" "}
                  {tab?.count && tab?.count > 0 ? (
                    <>
                      <span className="block lg:hidden ml-2">
                        ({tab?.count})
                      </span>
                      <span className="hidden lg:inline ml-2">
                        | {tab?.count}
                      </span>
                    </>
                  ) : (
                    hasAnyCounter && (
                      // Only render placeholders if at least one tab has a counter
                      <>
                        <span className="block lg:hidden ml-2 invisible">
                          (0)
                        </span>
                        <span className="hidden lg:inline ml-2 invisible">
                          | 0
                        </span>
                      </>
                    )
                  )}
                </span>
              )}
            </Tab>
          ))}
        </Tab.List>
      </Tab.Group>
      {loading ? (
        <div className="grid grid-cols-1 xl:grid-cols-3 sm:grid-cols-2 gap-4">
          <StudentCard loading={loading} />
        </div>
      ) : (
        <>
          {students?.allStudents?.length !== 0 && (
            <div className="flex flex-col -mb-2 -mt-7 col-span-full">
              <Input
                {...formik.getFieldProps("student_name")}
                type={"text"}
                value={searchQuery}
                placeholder="Search by learner name"
                onChange={handleSearchInputChange}
                searchIcon={true}
              />
            </div>
          )}
          {filteredStudents?.length && filteredStudents?.length > 0 ? (
            <div className="grid grid-cols-1 xl:grid-cols-3 sm:grid-cols-2 gap-4">
              {filteredStudents?.map((student) => (
                <div
                  className="h-full"
                  onClick={() =>
                    history.push(
                      `/ADI/job-details/${student?.job_id}?adi_job_id=${student?.adi_job_id}`,
                      "student"
                    )
                  }
                  key={student?.job_id}
                >
                  <StudentCard
                    loading={loading}
                    title={
                      student?.awaiting_payment ||
                      (student?.adi_has_approved_badge != 1 &&
                        student.is_private_student == 0)
                        ? student?.student_first_name
                        : `${student?.student_first_name ?? ""} ${
                            student?.student_surname ?? ""
                          }`
                    }
                    location={student?.student_post_code}
                    email={
                      student?.is_private_student == 1
                        ? student?.student_email
                        : student?.awaiting_payment
                        ? null
                        : student?.adi_has_approved_badge == 1
                        ? student?.student_email
                        : null
                    }
                    phone={
                      student?.is_private_student == 1
                        ? student?.student_phone
                        : student?.awaiting_payment
                        ? null
                        : student?.adi_has_approved_badge == 1
                        ? student?.student_phone
                        : null
                    }
                    agreedLocation={
                      student?.is_private_student == 1
                        ? {
                            lat: Number(student?.pickup_location_lat),
                            lng: Number(student?.pickup_location_long),
                          }
                        : student?.awaiting_payment
                        ? null
                        : student?.adi_has_approved_badge == 1
                        ? {
                            lat: Number(student?.pickup_location_lat),
                            lng: Number(student?.pickup_location_long),
                          }
                        : null
                    }
                    description={
                      student?.awaiting_payment ||
                      (student?.adi_has_approved_badge != 1 &&
                        student.is_private_student == 0)
                        ? student?.student_street_address
                        : `${
                            student?.student_street_address
                              ? student?.student_street_address + ", "
                              : ""
                          } ${student?.student_city_name ?? ""}`
                    }
                    pmfIcon={!student?.is_private_student}
                    status={getStudentStatus(student?.learner_job_status_id)}
                    completeReason={student?.complete_learner_reasons_name}
                    testResult={student?.test_result}
                  />
                </div>
              ))}
            </div>
          ) : (
            <>
              <div>
                <EmptyState Icon={ExclamationCircleIcon} title="No learners" />
                <Button
                  id="btnAdd"
                  onClick={() => handleShow()}
                  size="large"
                  fullWidth
                >
                  <span className="flex items-center py-1">
                    <PlusSmallIcon className="w-5 h-5" /> New Learner
                  </span>
                </Button>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};
