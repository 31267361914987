import { FC, useEffect, useRef, useState } from "react";
import PageHeader from "../../shared/elements/PageHeader";
import {
  getAdiRates,
  getCancellationPolicies,
  getRateTransmissionTypes,
  updateAdiCancellation,
  uploadAdiDocument,
} from "../../../../core/services/adi-service";
import { TransmissionTypeModel } from "../../../../core/models/transmission-type-model";
import { AdiRates } from "../../../../core/models/rates/adi-rates";
import Button from "../../shared/elements/Button";
import Alert from "../../shared/overlays/Alert";
import Input from "../../shared/forms/Input";
import Dropdown from "../../shared/forms/Dropdown";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { CancellationPolicyEnum } from "../../../../core/models/enums/cancellation-policy-enum";
import ReactGA from "react-ga4";
import * as Yup from "yup";
import { Skeleton } from "@mui/material";
import { toAbsoluteUrl } from "../../../helpers";
import toast from "react-hot-toast";
import Notification from "../../shared/overlays/Notification";
import {
  ExclamationCircleIcon,
  CheckCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import Badge from "../../shared/elements/Badge";
import { AdiCancellationRequest } from "../../../../core/models/rates/adi-cancellation-request";

export type CancellationPolicyProps = {
  parentWrapper: string;
  goToPrevTab: () => void;
  goToNextTab: () => void;
  allowSkip: boolean;
};

const CancellationPolicy: FC<any> = ({
  parentWrapper,
  goToPrevTab,
  goToNextTab,
  allowSkip,
}: CancellationPolicyProps) => {
  const [adiRatesObject, setAdiRatesObject] = useState<AdiRates>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingFile, setLoadingFile] = useState<boolean>(false);
  const [hasUploadedFile, setHasUploadedFile] = useState<boolean>(false);
  const isQuestionnaire = parentWrapper === "questionnaire";
  const history = useHistory();
  const [cancellationPeriods, setCancellationPeriods] = useState<any>([
    { label: "as", value: 1 },
  ]);
  const [maxFileSizeError, setMaxFileSizeError] = useState<string>();
  const maxFileSize = 10 * 1024 * 1024;

  const inputFile = useRef<HTMLInputElement>(null);

  const [showCancellationPolicyWarning, setShowCancellationPolicyWarning] =
    useState<boolean>(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const policiesResponse = await getCancellationPolicies().catch(() => {});
      if (
        policiesResponse?.data?.results &&
        policiesResponse.data.results.length > 0
      ) {
        let temp = policiesResponse.data.results?.map((elem) => {
          return {
            value: elem.id,
            label: elem.name,
          };
        });
        setCancellationPeriods(temp);
      }
      const transmissionTypeResponse = await getRateTransmissionTypes().catch(
        () => {}
      );
      const transmissionTypes = transmissionTypeResponse?.data?.results ?? [];
      transmissionTypes.forEach((a) => (a.is_used = false));

      getRates(transmissionTypes);

      setLoading(false);
    })();
  }, []);

  const getRates = async (transmissionTypes: Array<TransmissionTypeModel>) => {
    const adiRateResponse = await getAdiRates().catch(() => {});
    const response = adiRateResponse?.data?.results;
    setAdiRatesObject(response);
  };

  const viewFile = (url: any) => {
    if (formik.values.file) url = URL.createObjectURL(formik.values.file);

    Object.assign(document.createElement("a"), {
      target: "_blank",
      href: url,
    }).click();
  };

  const validationSchema = Yup.object().shape({
    cancellation_policy_id: Yup.number().required("This field is required"),
    url: Yup.string()
      .nullable()
      .when("cancellation_policy_id", ([cancellation_policy_id], schema) => {
        return cancellation_policy_id === CancellationPolicyEnum.other
          ? schema.required("This field is required")
          : schema;
      }),
  });

  const formik = useFormik({
    initialValues: {
      cancellation_policy_id: adiRatesObject?.cancellation_policy_id,
      url: adiRatesObject?.url,
      file: undefined,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit,
  });

  function onSubmit(values: any, { setStatus, setSubmitting }: any) {
    (async () => {
      setStatus(null);
      setSubmitting(false);
      const request: AdiCancellationRequest = {
        cancellation_policy_id: formik.values.cancellation_policy_id,
        url: formik.values.url,
      };
      const updateResponse = await updateAdiCancellation(request).catch(
        (error) => {
          if (error?.errors?.length) {
            setStatus(error.errors[0].errorMessage);
          } else {
            setStatus("Sorry, an error has occured");
          }
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }
      );
      if (updateResponse?.status === 200) {
        ReactGA.event("update_rate");
        if (isQuestionnaire) {
          goToNextTab();
        } else {
          toast.custom(
            () => (
              <Notification
                colour="green"
                title={"Saved successfully"}
                description={""}
                Icon={CheckCircleIcon}
              />
            ),
            {
              duration: 5000,
              position: "top-center",
            }
          );
          history.push("/ADI/settings/overview");
        }
      }
      setSubmitting(false);
    })();
  }

  const redirectToDashboard = () => {
    history.push("/ADI/dashboard");
  };

  const onCancellationFileChange = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const documentFile = e.target.files[0];
      if (e.target.files[0].size < maxFileSize) {
        setLoadingFile(true);
        formik.setFieldValue("file", documentFile);
        let loadingId = toast.custom(
          () => (
            <Notification
              colour="gray"
              title={"Uploading Document..."}
              description={""}
              Icon={ExclamationCircleIcon}
            />
          ),
          {
            duration: 5000,
            position: "top-center",
          }
        );

        uploadAdiDocument(documentFile)
          .then((data) => {
            formik.setFieldValue("url", data.data.results);
            setLoadingFile(false);
            setHasUploadedFile(true);
            setMaxFileSizeError(undefined);
            toast.custom(
              () => (
                <Notification
                  colour="green"
                  title={"Saved successfully"}
                  description={""}
                  Icon={CheckCircleIcon}
                />
              ),
              {
                duration: 5000,
                position: "top-center",
                id: loadingId,
              }
            );
          })
          .catch(() => {
            setLoadingFile(false);
            toast.custom(
              () => (
                <Notification
                  colour="red"
                  title={"Failed to upload cancellation policy!"}
                  description={""}
                  Icon={ExclamationTriangleIcon}
                />
              ),
              {
                duration: 5000,
                position: "top-center",
                id: loadingId,
              }
            );
          });
      } else {
        setMaxFileSizeError("Max file size is 10 MB");
      }
    }
  };

  const onReuploadClick = () => {
    // `current` points to the mounted file input element
    inputFile.current && inputFile.current.click();
  };

  const cancel = () => {
    if (isQuestionnaire) {
      goToPrevTab();
    } else {
      redirectToDashboard();
    }
  };

  return (
    <>
      <div className={isQuestionnaire ? "hidden" : "mb-5"}>
        <PageHeader
          title="Cancellation Policy"
          backToURL={
            parentWrapper !== "questionnaire" ? "/ADI/settings/overview" : ""
          }
        />
      </div>

      <div className={`${isQuestionnaire ? "pb-2" : "px-5 py-4"} bg-white`}>
        <div className="flex flex-col items-center justify-between w-full">
          <div className="flex flex-col w-full">
            {loading ? (
              <Skeleton className="w-full" height={50}></Skeleton>
            ) : (
              <Dropdown
                {...formik.getFieldProps("cancellation_policy_id")}
                options={cancellationPeriods}
                label="Cancellation period"
                placeholder="Cancellation period"
                id="cancellation_period"
                error={
                  formik.touched.cancellation_policy_id &&
                  formik.errors.cancellation_policy_id !== undefined
                }
                errorMsg={formik.errors.cancellation_policy_id}
                value={cancellationPeriods.find(
                  (t: any) => t.value === formik.values.cancellation_policy_id
                )}
                required={true}
                disabled={formik.isSubmitting}
                onChange={(e) => {
                  if (
                    !isQuestionnaire &&
                    formik.values.cancellation_policy_id !== e?.value
                  ) {
                    setShowCancellationPolicyWarning(true);
                  }
                  formik.setFieldValue("cancellation_policy_id", e?.value);
                }}
                onBlur={() => {
                  formik.setFieldTouched("cancellation_policy_id");
                }}
              ></Dropdown>
            )}

            {showCancellationPolicyWarning === true ? (
              <Alert
                description="This change only applies to students you take on from now on"
                colour="orange"
              ></Alert>
            ) : (
              ""
            )}

            {loading ? (
              <Skeleton width={160}></Skeleton>
            ) : (
              <h3 className="text-sm font-titlef font-bold leading-6 text-darkBlue">
                Terms & Conditions
              </h3>
            )}
            {loading ? (
              <Skeleton width={250}></Skeleton>
            ) : (
              <span className="text-[10px]">
                You can upload a copy of your cancellation terms here. They will
                typically be approved by a member of the ADI Network team within
                one working day. Please note, the course supplier will set the
                payment terms for each course.
              </span>
            )}
            {formik.values.url &&
            formik.values.cancellation_policy_id ===
              CancellationPolicyEnum.other ? (
              <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow border mb-4 mt-3">
                <div className="p-3">
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      <img
                        src={toAbsoluteUrl("/assets/media/svg/insurance.svg")}
                        className="h-12 w-12"
                        alt="doc icon"
                      />
                    </div>
                    <div className="ml-3 w-0 flex-1 -mt-0.5">
                      <p className="text-xs font-bold mb-2 flex justify-between">
                        Cancellation policy document
                        {(adiRatesObject?.cancellation_policy_id ===
                          CancellationPolicyEnum.other ||
                          formik.values.cancellation_policy_id ===
                            CancellationPolicyEnum.other) && (
                          <Badge
                            fullWidth={false}
                            size={"xs"}
                            colour={"gray"}
                            title={
                              hasUploadedFile
                                ? "Pending"
                                : adiRatesObject?.cancellation_policy_status ??
                                  ""
                            }
                          />
                        )}
                      </p>
                      <div className="mt-1 flex space-x-2">
                        <Button
                          fitWidth
                          colour="darkBlue"
                          onClick={() => viewFile(formik.values.url)}
                        >
                          <span className="px-4">View</span>
                        </Button>

                        <Button
                          colour="outline"
                          onClick={onReuploadClick}
                          fitWidth
                        >
                          <span className="">Reupload</span>

                          <input
                            {...formik.getFieldProps("url")}
                            type="file"
                            id="file"
                            ref={inputFile}
                            value=""
                            className="hidden"
                            accept="application/pdf"
                            onChange={(e) => onCancellationFileChange(e)}
                            required={
                              formik.values.cancellation_policy_id ===
                              CancellationPolicyEnum.other
                            }
                          />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : loading ? (
              <Skeleton className="w-full" height={50}></Skeleton>
            ) : (
              <Input
                type="file"
                accept="application/pdf"
                onChange={(e) => onCancellationFileChange(e)}
                error={
                  maxFileSizeError !== undefined ||
                  (formik.touched.url && formik.errors.url !== undefined)
                }
                errorMsg={maxFileSizeError || formik.errors.url}
                label="Upload Document"
                required={
                  formik.values.cancellation_policy_id ===
                  CancellationPolicyEnum.other
                }
                disabled={
                  loadingFile ||
                  formik.values.cancellation_policy_id !==
                    CancellationPolicyEnum.other
                }
              ></Input>
            )}
          </div>

          {loading ? (
            <Skeleton className="w-full" height={120}></Skeleton>
          ) : (
            <Alert
              description="ADI Network will share your terms and conditions with your learners, however it is your responsibility to clarify your policy with learners to ensure ADI Network can support you with upholding this, should it be needed. We will send lesson reminders to your learners 24 hours before your stated cancellation policy. If you upload a document, reminders will be sent 72 hours before the lesson begins."
              colour="blue"
            ></Alert>
          )}
        </div>

        {/*start:: Actions buttons area */}

        <div
          className={
            parentWrapper === "questionnaire" ? "md:mt-0 mt-[65px]" : ""
          }
        ></div>
        <div
          className={
            parentWrapper === "questionnaire"
              ? "fixed md:sticky w-full bottom-0 left-0 px-5 md:px-0 py-4 bg-white z-[11]"
              : ""
          }
        >
          <div
            className={`${
              parentWrapper === "questionnaire"
                ? "flex-row-reverse"
                : "flex-row mt-6 mb-3"
            } flex gap-x-2 justify-between`}
          >
            {loading ? (
              <>
                <Skeleton width={180} height={50} className="me-2" />
                <Skeleton width={180} height={50} className="me-2" />
              </>
            ) : (
              <>
                <Button
                  colour="yellow"
                  type="submit"
                  onClick={formik.handleSubmit}
                  size="large"
                  disabled={formik.isSubmitting || loadingFile}
                  halfWidth
                >
                  Save
                </Button>
                <Button
                  onClick={cancel}
                  disabled={formik.isSubmitting || loadingFile}
                  colour="outline"
                  size="large"
                  halfWidth={true}
                >
                  {isQuestionnaire ? "Back" : "Discard"}
                </Button>
              </>
            )}
          </div>
        </div>

        {/*end:: Actions buttons area */}
      </div>
    </>
  );
};

export { CancellationPolicy };
