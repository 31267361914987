import { FC, useEffect, useState } from "react";
import ListGroup from "../shared/data/ListGroup";
import { SignOutButton } from "./signOut/SignOutButton";
import DisclosureItem from "../shared/elements/DisclosureItem";
import { getAdiPicture } from "../../../core/services/adi-service";
import { PersonalDataModel } from "../../../core/models/personal-data-model";
import { useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import { ProfessionalDevelopment } from "./ProfessionalDevelopment";

type Props = {
  personalData: PersonalDataModel;
};

const AccountSettings: React.FC<Props> = ({ personalData }) => {
  const [adiPicture, setAdiPicture] = useState<any>(null);
  const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  useEffect(() => {
    getAdiPicture().then((data) => {
      setAdiPicture(data.data.results);
    });
  }, []);

  const fullName = personalData?.name;
  let initials: any;
  if (fullName) initials = fullName.charAt(0);
  // return initials.toUpperCase();
  const isMobile = useSelector<RootState>(({ auth }) => auth.isMobile);
  const showPayment = useSelector<RootState>(
    ({ auth }) => auth.personalData?.is_stripe_connect_view_enabled
  );
  return (
    <>
      {/* start:: user yellow header */}

      <div className="flex flex-col px-5 pt-8 pb-6 bg-yellow sm:mt-0 -mt-6">
        <div className="flex w-full items-center justify-between space-x-1">
          {adiPicture != null ? (
            <>
              <img
                alt="Logo"
                className=" h-16 w-16 flex-shrink-0 rounded-full bg-gray-200 border-[4px] border-[#F4F5F7]"
                src={adiPicture}
              />
            </>
          ) : (
            <>
              <div className="h-[38px] w-[38px] flex-shrink-0 rounded-full flex items-center justify-center bg-gray-200 border-[4px] border-[#F4F5F7]">
                <span className="initials">{initials}</span>
              </div>
            </>
          )}
          <div className="flex-1 truncate">
            <h3 className="truncate text-[22px] font-titlef font-semibold text-darkBlue">
              {personalData?.name} {personalData?.sur_name}
            </h3>
          </div>
        </div>
      </div>
      {/* end:: user yellow header */}

      <div className="d-flex flex-wrap px-5 pt-6 pb-4 p-lg-0">
        <DisclosureItem
          disclosureCard
          title="Your Account"
          subTitle="Lorem ipsum dolor sit amet"
          disclosureIcon="pmf-icon-user-circle"
        >
          <div className=" px-4">
            <ListGroup
              data={[
                {
                  name: "About You",
                  linkURL: "/ADI/profile/about-u",
                },
                {
                  name: "Contact Details",
                  linkURL: "/ADI/profile/overview",
                },
                {
                  name: "Car",
                  linkURL: "/ADI/profile/car-details",
                },
                {
                  name: "Documents",
                  linkURL: "/ADI/profile/documents",
                },
                {
                  name: "Banking Details",
                  linkURL: "/ADI/profile/banking-details",
                },
                {
                  name: "Calendar Integration",
                  linkURL: "/ADI/settings/integration",
                },
              ]}
            />
          </div>
        </DisclosureItem>

        <DisclosureItem
          disclosureCard
          title="Job Preferences"
          subTitle="Lorem ipsum dolor sit amet"
          disclosureIcon="pmf-icon-wheel"
        >
          <div className=" px-4">
            <ListGroup
              data={[
                {
                  name: "Rates",
                  linkURL: "/ADI/profile/rates-cancellation-policy",
                  hide: false,
                },
                {
                  name: "Cancellation Policy",
                  linkURL: "/ADI/profile/cancellation-policy",
                  hide: false,
                },
                {
                  name: "Driving Tuition",
                  linkURL: "/ADI/profile/driving-tuition",
                  hide: false,
                },
                {
                  name: "Digital Payment Account",
                  linkURL: "/ADI/digital-payments",
                  hide: !showPayment,
                },
                {
                  name: "Job Preferences",
                  linkURL: "/ADI/profile/job-preferences",
                  hide: false,
                },
                {
                  name: "Default Job Settings",
                  linkURL: "/ADI/settings",
                  hide: false,
                },
                {
                  name: "Communication",
                  linkURL: "/ADI/settings/communication",
                  hide: false,
                },
                {
                  name: "Notifications",
                  linkURL: "/ADI/settings/notification",
                  hide: !isMobile,
                },
              ].filter((a) => !a.hide)}
            />
          </div>
        </DisclosureItem>

        {/* start:: CPD Link */}
        {/* <CPDLink /> */}

        {/* end:: CPD Link */}
        {/* <DisclosureItem
          disclosureCard
          title="Professional Development"
          subTitle="Lorem ipsum dolor sit amet"
          disclosureImg="/assets/media/svg/help.svg"
        >
          <div className=" px-4">
            <ListGroup
              data={[
                {
                  name: "All Videos and Articles",
                  linkURL: "/ADI/cpd",
                },
                {
                  name: "Ask the Expert",
                  linkURL: "/ADI/professional-development/ask-the-expert",
                },
                {
                  name: "Competitions",
                  linkURL: "/ADI/professional-development/competitions",
                },
              ]}
            />
          </div>
        </DisclosureItem> */}
      </div>

      {/* <div className="d-flex flex-wrap px-5 py-6 p-lg-0">
        <NetworkPerks />
      </div> */}

      {/* begin::Sign out */}
      <SignOutButton />
      {/* end::Sign out */}
    </>
  );
};

export { AccountSettings };
