import axios from 'axios'
import { UserModel } from '../models/UserModel'
import { Auth } from "aws-amplify";
import { getPersonalData } from '../../../../core/services/adi-service';
import { PersonalDataModel } from '../../../../core/models/personal-data-model';
import { BaseResponse } from '../../../../core/models/base-response';
import ReactGA from "react-ga4";
const API_URL = process.env.REACT_APP_BASE_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/adis`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
export const VERIFY_EMAIL_URL = `${API_URL}/verify-email`
export const EMAIL_EXISTS_URL = `${API_URL}/email-exists`
export const NEXT_AUTH_ACTION = `${API_URL}/next-auth-action`
export const VERIFY_RECAPTCH = `${API_URL}/adi-verify-recaptch`
export const IMPERSONATE_ADI = `${API_URL}/impersonate-adi`
export const IMPERSONATOR_TOKEN = `impersonator-token`

export function impersonateUser(email: string, password: string) {
  return axios.post(IMPERSONATE_ADI, {
    username: email,
    password
  })
    .then((result) => {
      localStorage.setItem(IMPERSONATOR_TOKEN, result.data.results.token);
      sessionStorage.setItem("isSessionOpened", "true");
      return login(result.data.results.impersonator_email, password);
    })
    .catch((result) => {
      if (result?.errors?.length) {
        throw {
          message: result.errors[0].errorMessage
        };
      }
      throw result;
    });
}

// Server should return AuthModel
export function login(email: string, password: string): any {
  return Auth.signIn({
    username: email,
    password
  })
  .catch((err) => handleLoginError(err, email, password));
}

export function signOut() {
  Auth.signOut()
  localStorage.removeItem(IMPERSONATOR_TOKEN)
}

// Server should return AuthModel
export function register(email: string, password: string) {
  return Auth.signUp({
    username: email,
    password,
    attributes: {
      email
    }
  }).then(() => addAdi(email, email));
}

// Server should return AuthModel
export function addAdi(username: string, email: any = undefined) {
  return axios.post<BaseResponse<UserModel>>(REGISTER_URL, {
    username: username,
    email: email
  });
}

export function federatedSignIn(provider: any) {
  return Auth.federatedSignIn({ provider: provider });
}

export function requestPassword(email: string) {
  return Auth.forgotPassword(email);
}
export function getNextAuthAction(email: string) {
  return axios.post<any>(NEXT_AUTH_ACTION, {email});
}
export function emailExists(email: string) {
  return axios.post<any>(EMAIL_EXISTS_URL, {email})
    .then((res) => {
      if (!res.data.results.email_exists) {
        throw { message: "There is no account registered with this email" }
      } else if (!res.data.results.matching_ids) {
        throw { message: "Sorry, we can't log you in right now — please contact us"
        }
      }
    });
}
export function resetPassword(email: string, code: string, password: string) {
  return Auth.forgotPasswordSubmit(email, code, password).then(res => {
    axios.put<any>(VERIFY_EMAIL_URL, { email })
      .catch(err => console.log(err));
    return res;
  }).catch((err) => handleLoginError(err, email, password));
}

export function confirmEmail(email: string, code: string) {
  return Auth.confirmSignUp(email, code).then(res => {
    axios.put<any>(VERIFY_EMAIL_URL, { email })
      .catch(err => console.log(err));
    return res;
  });
}

export function resendOTP(username: string) {
  return Auth.resendSignUp(username).catch(err => console.log(err));
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  return Auth.currentAuthenticatedUser().then((cognitoUser) => {
    const user: any = {
      username: cognitoUser?.username
    };
    return user;
  });
}

export function getUserPersonalData() {
  return getPersonalData().then((data) =>
   {
    ReactGA.set({userId: data.data.results.zendesk_contact_id})
    let personalData = data.data.results
    return {
      email: personalData.email == null ? "" : personalData.email,
      name: personalData.name == null ? "" : personalData.name,
      sur_name: personalData.sur_name == null ? "" : personalData.sur_name,
      phone: personalData.phone == null ? "" : personalData.phone,
      has_expired_doc: personalData.has_expired_doc == 0 ? 0 : personalData.has_expired_doc,
      is_impersonate: personalData.is_impersonate == 0 ? 0 : personalData.is_impersonate,
      hide_new_jobs_tab_for_impersonate: personalData.hide_new_jobs_tab_for_impersonate == 0 ? 0 : personalData.hide_new_jobs_tab_for_impersonate,
      hide_new_jobs_tab: personalData.hide_new_jobs_tab == 0 ? 0 : personalData.hide_new_jobs_tab,
      is_stripe_connect_view_enabled : personalData.is_stripe_connect_view_enabled
    } as PersonalDataModel;
  })
}

export function verifyRecaptch(captchaToken: any) {
  return axios.post(VERIFY_RECAPTCH, {
    captchaToken
  });
}

function handleLoginError(err: any, email: string, password: string) {
  if (err.message == 'The quota has been exceeded.') {
    window.localStorage.clear()
    return login(email, password);
  } else {
    throw err;
  }
}

export function CockpitImpersonate(userToken: string | null) {
  return axios.get(`${API_URL}/get-impersonate-password`, {
    headers: {
      "Authorization": `Bearer ${userToken}`
    }
  })
    // .then((result) => {
        // Decode the String
    //   const pwd = atob(result.data.results.pwd);
    //   return login(result.data.results.impersonator_email, pwd);
    // })
    // .catch(err => console.log(err));
}

