import Button from "../elements/Button";
import { Skeleton } from "@mui/material";
import { SparkLineChart } from "@mui/x-charts/SparkLineChart";
import { ArrowUpRightIcon } from "@heroicons/react/24/outline";
import { Link, useHistory } from "react-router-dom";

export type IProps = {
  title?: string;
  type?: string;
  icon?: string;
  indicatorNote?: React.ReactNode;
  loading?: boolean;
  linkURL?: any;
  chartType?: "bar" | "line";
  chartData: any;
  avgNo?: {
    val: any;
    label: string;
    color: "green" | "yellow" | "red";
  };
};

export default function StandardsCheckCardWithChart({
  title,
  type,
  icon,
  indicatorNote,
  linkURL,
  loading = false,
  chartData,
  chartType,
  avgNo,
}: IProps) {
  const history = useHistory();

  // Function to format the number
  const formatNumber = (num: number): string => {
      if (num % 1 === 0 || num % 1 < 0.1) {
        return Math.round(num).toString(); // Return number as string without decimal places
    } else {
        return num.toFixed(2); // Return number with 2 decimal places
    }
  };

  return (
    <div className="h-full">
      <div className="bg-white rounded-[10px] border-2 border-[#CECECE] mb-3 h-full flex flex-col justify-between">
        <div>
          <div className="flex pt-3 ps-4 pe-3 pb-4 flex-col">
            <div className="flex justify-between items-center">
              {/* {loading ? ( */}
              <div className="min-w-0 flex-1 flex items-center mb-0 min-h-[24px] justify-between">
                <h3 className="text-base leading-6 text-darkBlue grow items-center flex">
                  {loading ? (
                    <>
                      <Skeleton width={150} animation="wave" />
                    </>
                  ) : (
                    <>
                      <i
                        className={`${icon} text-xl text-darkBlue me-2 -mt-0.5 -ms-1 leading-none`}
                      ></i>{" "}
                      {title}{" "}
                    </>
                  )}
                </h3>
                {/* {linkURL && ( */}
                  <div className="ml-4 flex flex-shrink-0">
                    {loading ? (
                      <div className="flex item-center">
                        <Skeleton
                          width={20}
                          height={20}
                          className="me-2"
                          variant="circular"
                        />
                       
                      </div>
                    ) : (
                      <>
                        <Link 
                        className="flex items-center  transition-all justify-center rounded-full bg-[#E3EEF9] w-[30px] h-[30px] shadow-sm hover:bg-gray-50"
                        to={{ pathname: `/ADI/standards-check-indicators/${type}`, state: { title: title, uid: type } }}
                        >
                          <ArrowUpRightIcon className="mx-auto h-4 w-4 text-darkBlue" />
                        </Link>
                      </>
                    )}
                  </div>
                {/* )} */}
              </div>
            </div>

            <div className="grid grid-cols-2 gap-3 items-center">
              {avgNo ? (
                <div
                  className={`flex w-full items-baseline justify-start ${
                    {
                      red: "text-danger",
                      yellow: "text-yellow",
                      green: "text-green",
                    }[avgNo.color]
                  }`}
                >
                  <span className=" text-5xl ">
                    {chartType === "line" ?
                      <>{formatNumber(avgNo.val)}</>
                      :
                      <>{avgNo.val}</>
                    }
                    
                  </span>
                  <span className={` text-[22px] ms-[3px]`}>{avgNo.label}</span>
                </div>
              ) : null}
              <SparkLineChart
                plotType={chartType}
                colors={["#163A5E"]}
                data={chartData}
                height={72}
                margin={{
                  left: 0,
                  right: 0,
                  top: 10,
                  bottom: 20,
                }}
                curve="natural"
                sx={{
                  ".MuiBarElement-root": {
                    width: "3px!important"
                  },
                }}
              />
            </div>

            <p className="text-xs"> {indicatorNote}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
