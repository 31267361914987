/* eslint-disable jsx-a11y/anchor-is-valid */
import Button from "../elements/Button";
import Badge from "../elements/Badge";
import Alert from "../overlays/Alert";
import TextArea from "../forms/TextArea";
import { useState } from "react";
import Moment from "react-moment";
import moment from "moment";

export type NotesProps = {
  title?: string;
  onCreateNote: (value: any) => void;
  onRemoveNote: (note: any, index: number) => void;

  disableCreate?: boolean;
  notes?: {
    value: string | undefined;
    date?: any;
  }[];

  alertTxt: React.ReactNode;
};

export default function Notes({
  title,
  onRemoveNote,
  disableCreate,
  onCreateNote,
  notes,
  alertTxt,
}: NotesProps) {
  const [newNote, setNewNote] = useState<string>("");

  const updateNote = (value: string) => {
    setNewNote(value);
  };

  return (
    <>
      <h3 className="font-bold text-base font-normalf mb-2 leading-none">
        {title}
      </h3>
      <Alert colour="white" icon="pmf-icon-info">
        {alertTxt}
      </Alert>

      <div className="mt-2 bg-[#F4F5F7] p-4 rounded">
        {notes?.map(function (note, index) {
          return (
            <div className="mb-6" key={"notes" + index}>
              <span className="text-[#848484] text-[10px]">#{index+1}</span>
              <p className="text-xs">{note.value}</p>
              <div className="flex justify-between">
                {note.date && (
                  <Moment fromNow className="text-[#848484] text-[10px]">{moment.utc(note.date).valueOf()}</Moment>
                )}
                <Button
                  fitWidth
                  colour="link"
                  type="button"
                  size="fit"
                  onClick={() => onRemoveNote(note, index)}
                >
                  <span className="text-danger">Remove</span>
                </Button>
              </div>
            </div>
          );
        })}

        <div className="relative border border-gray-200 rounded p-1 bg-white">
          <TextArea
            name="newNotes"
            maxLength={255}
            className="form-control"
            onChange={(e) => updateNote(e.target.value)}
            value={newNote}
          />
          <Button
            disabled={disableCreate}
            type="button"
            onClick={() => {
              onCreateNote(newNote);
              setNewNote("");
            }}
            colour="darkBlue"
            fullWidth
            rounded="none"
          >
            <span>Add Note</span>
          </Button>
        </div>
      </div>
    </>
  );
}
