import { FC, useEffect, useState } from "react";
import { IQuationnaireTabProps } from "../../../../core/models/quationnaire-tab-props";
import { CarsList } from "../cars/CarsList";
import Button from "../../shared/elements/Button";
import { CheckBadgeIcon } from "@heroicons/react/24/outline";
import { AddDocumentDialog } from "../documents/AddDocumentDialog";
import DisclosureItem from "../../shared/elements/DisclosureItem";
import { QuestionnaireStepStatusEnum } from "../../../../core/models/enums/questionnaire-step-status-enum";
import { toAbsoluteUrl } from "../../../helpers";
import { getAdiLatestInsurance } from "../../../../core/services/adi-service";
import { DocumentDataModel } from "../../../../core/models/document-data-model";

const CarAndInsurance: FC<IQuationnaireTabProps> = (props) => {
  const [showAddInsuranceModal, setShowAddInsuranceModal] = useState(false);
  const [insuranceDoc, setInsuranceDoc] = useState<DocumentDataModel | null>(
    null
  );
  const [hasUploadedDoc, setHasUploadedDoc] = useState(false);

  useEffect(() => {
    getLatestInsurance();
  }, []);

  function getLatestInsurance() {
    getAdiLatestInsurance()
      .then((res) => {
        setInsuranceDoc(res.data.results);
        if (res.data.results != null) {
          setHasUploadedDoc(true);
        }
      })
      .catch((res) => {});
  }

  const onCreateInsurance = () => {
    setHasUploadedDoc(true);
    getLatestInsurance();
  };

  return (
    <div>
      <div className="-mx-4">
        <DisclosureItem disclosureCard disclosureCardSize="md" title="Car(s)">
          <div className="px-4">
            <CarsList
              hasUnVerifiedProfile={props.hasUnVerifiedProfile}
              refresh={props.refresh}
              selfRegisterDate={props.selfRegisterDate}
              goToPrevTab={props.goToPrevTab}
              goToNextTab={props.goToNextTab}
              onUpdate={props.onUpdate}
              parentWrapper="questionnaire"
            />
          </div>
        </DisclosureItem>
      </div>

      <div className="-mx-4">
        <DisclosureItem
          disclosureCard
          disclosureCardSize="md"
          title="Insurance"
          expanded={props?.currentStep.completed_status ===
            QuestionnaireStepStatusEnum.PartiallyComplete}
        >
          {hasUploadedDoc && insuranceDoc != null ? (
            <div className="pb-1 px-4">
              <div className="flex items-start mb-4 bg-gray-50 border px-3 py-4">
                <div className="flex-shrink mx-3">
                  <img
                    className="max-w-150px w-30 h-auto"
                    src={toAbsoluteUrl("/assets/media/svg/insurance.svg")}
                    alt=""
                  />
                </div>
                <div>
                  <div>
                    <h3 className="text-sm font-semibold mb-0">
                      {insuranceDoc?.insurance_provider}
                    </h3>
                    <p className="text-sm">
                      {insuranceDoc?.expiry_date_formatted}
                    </p>
                  </div>
                  <Button
                    fitWidth
                    className="mt-2"
                    onClick={() => setShowAddInsuranceModal(true)}
                  >
                    Reupload insurance
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div className="px-4 pb-3">
              <Button
                colour="darkBlue"
                type="button"
                size="medium"
                disabled={
                  props?.currentStep.completed_status ===
                  QuestionnaireStepStatusEnum.Incomplete
                }
                onClick={() => setShowAddInsuranceModal(true)}
              >
                <span className="flex items-center py-1">
                  <CheckBadgeIcon className="w-4 h-4 me-1" /> Upload insurance
                </span>
              </Button>
            </div>
          )}
        </DisclosureItem>
      </div>

      <AddDocumentDialog
        show={showAddInsuranceModal}
        onHide={() => setShowAddInsuranceModal(false)}
        isUploadBadge={false}
        isUploadInsurance={true}
        onCreatedNewDocument={() => {
          setShowAddInsuranceModal(false);
          onCreateInsurance();
        }}
        parentWrapper="questionnaire"
      />

      <div className="md:mt-0 mt-[65px]"></div>
      <div className="fixed md:sticky w-full bottom-0 left-0 px-5 md:px-0 py-4 bg-white z-[11]">
        <Button
          onClick={props.goToNextTab}
          colour="outline"
          size="large"
          fullWidth
        >
          Done
        </Button>
      </div>
    </div>
  );
};

export { CarAndInsurance };
