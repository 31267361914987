import { Autocomplete, TextField } from "@mui/material";
import React from "react";

export interface DropdownProps {
  label?: string;
  id?: string;
  name?: string;
  className?: string;
  value?: any;
  options: Array<any>;
  onChange: (newValue: any) => void;
  onBlur?: () => void;
  error?: boolean;
  errorMsg?: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  isSearchable?: boolean;
  multiple?: boolean;
  dropdownPosition?: string;
  placement?:'auto-end'
    | 'auto-start'
    | 'auto'
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
}

function areEqual(prevState: any, nextState: any) {
  return (
    prevState.value === nextState.value &&
    nextState.value !== undefined &&
    prevState.error === nextState.error &&
    prevState.disabled === nextState.disabled &&
    prevState.options === nextState.options
  );
}

export default React.memo(function Dropdown({
  label,
  className = "",
  options = [],
  name,
  id = "",
  value,
  onChange,
  onBlur,
  required = false,
  disabled = false,
  isSearchable = false,
  error = false,
  errorMsg = "This field is required",
  placeholder,
  dropdownPosition = 'absolute',
  placement = 'bottom',
  multiple = false,
  ...props
}: DropdownProps) {
  const selectedValue = React.useMemo(() => {
    if (Array.isArray(value)) {
      return options?.length > 0 && value
        ? options?.filter(
            (v) => Array.isArray(value) && value?.includes(v?.value)
          )
        : [];
    } else {
      return options?.length > 0 && value
        ? options?.filter((v) => v?.value === value?.value)[0]
        : '';
    }
  }, [options, value]);

  return (
    <div className="flex flex-col items-start my-4">
      <div className="w-full static md:relative">
        <div className="relative">
          <label
            htmlFor={name}
            className="absolute -top-2 left-2 inline-block bg-white px-1 text-sm font-medium text-darkBlue z-[1]"
          >
            {label}
            {required && <span className='text-sm text-red-500'>*</span>}
          </label>
        </div>
        <Autocomplete
          {...props}
          id={id}
          disablePortal
          value={selectedValue}
          getOptionLabel={(option) => option.label ?? ''}
          options={options}
          onChange={(e, value) => onChange(value)}
          onBlur={onBlur}
          // inputValue={inputValue}
          // onInputChange={(event, newInputValue) => onChange(newInputValue)}
          disabled={disabled}
          disableClearable
          renderInput={({ inputProps, ...rest }) => (
            <TextField
              {...rest}
              placeholder={placeholder}
              name={name}
              required={required}
              inputProps={{ ...inputProps, readOnly: !isSearchable }}
            />
          )}
          slotProps={{
            paper: {
              sx: {
                marginTop: '4px',
                border: '1px solid #d1d5db',
                borderRadius: '2px',
                ".MuiAutocomplete-noOptions": {
                  fontSize: '14px',
                  fontWeight: 400,
                  fontFamily: 'Poppins, sans-serif',
                  color: '#9fa6b2',
                  padding: '12px 8px',
                },
              },
            },
            popper: {
              placement: placement,
              sx:
                dropdownPosition === 'static'
                  ? {
                      position: 'static !important',
                      transform: 'none !important',
                    }
                  : {
                      position: 'absolute !important',
                    },
            },
          }}
          ListboxProps={{
            sx: {
              maxHeight: '160px',
              boxShadow: 'none',
              '& .MuiAutocomplete-option': {
                fontSize: '14px',
                fontWeight: 400,
                fontFamily: 'Poppins, sans-serif',
                color: '#163A5E',
                minHeight: '36px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                paddingTop: 0,
                paddingBottom: 0,
              },
            },
          }}
          className={`block w-full text-darkBlue placeholder:text-gray-400 mt-1 ${
            !multiple && 'h-[44px]'
          } ${className}`}
          sx={{
            ".Mui-disabled": {
              backgroundColor: '#fafafa',
              WebkitTextFillColor: '#163A5E !important',
            },
            ".MuiOutlinedInput-input": {
              fontSize: '14px',
              fontWeight: 400,
              fontFamily: 'Poppins, sans-serif',
              color: '#163A5E',
              padding: '3px 0px !important',
            },
            ".Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: '#4f46e5 !important',
            },
            ".MuiOutlinedInput-notchedOutline": {
              borderWidth: '1px !important',
              borderRadius: '2px',
              borderColor: error ? "#ef4444 !important" : "#dadde2 !important",
            },
            // Conditionally add style based on the 'multiple' prop
            ...(multiple
              ? {}
              : { '.MuiOutlinedInput-root': { height: '44px' } }),
          }}
          multiple={multiple}
        />
        {error && (
          <p className="text-sm text-red-600" id="error">
            {errorMsg}
          </p>
        )}
      </div>
    </div>
  );
},
areEqual);
