import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";

interface PickupLocationMapProps {
  center: { lat: number; lng: number };
  zoom: number;
  radius: number;
  setPickup?: (location: { lat: number; lng: number }) => void;
  canEdit?: boolean;
}

const PickupLocationMap: React.FC<PickupLocationMapProps> = ({
  center,
  zoom,
  radius,
  setPickup,
  canEdit = false,
}) => {
  const [map, setMap] = useState<any>(null);
  const [pickupLocation, setPickupLocation] = useState<any>(null);
  const [mapsInstance, setMapsInstance] = useState<any>(null);

  const infoWindowContent = (location: any, title: string) => {
    return `<div>
          <h4>${title}</h4>
          Latitude: ${location.lat.toFixed(5)}<br />
          Longitude: ${location.lng.toFixed(5)}
        </div>`;
  };

  const updateMap = (location: any, isPickup = false) => {
    const marker = new mapsInstance.Marker({
      position: location,
      map,
      icon: isPickup
        ? "/assets/media/pins/car.svg"
        : "/assets/media/pins/default.svg",
    });
    const infoWindow = new mapsInstance.InfoWindow({
      content: infoWindowContent(location, isPickup ? "Instructor" : "Student"),
    });
    marker.addListener("click", () => {
      infoWindow.open(map, marker);
    });
    if (isPickup) {
      setPickupLocation(marker);
    }
  };

  useEffect(() => {
    if (map && mapsInstance) {
      updateMap(center);
      if (canEdit) {
        new mapsInstance.Circle({
          strokeColor: "#FF0000",
          strokeOpacity: 0.5,
          strokeWeight: 1,
          fillColor: "#FF0000",
          fillOpacity: 0.3,
          map,
          center,
          radius,
          clickable: false,
        });
      }
    }
  }, [map, mapsInstance]);

  const handleMapClick = ({ lat, lng }: { lat: number; lng: number }) => {
    const clickedLocation = { lat, lng };
    const distance = mapsInstance.geometry.spherical.computeDistanceBetween(
      new mapsInstance.LatLng(clickedLocation.lat, clickedLocation.lng),
      new mapsInstance.LatLng(center.lat, center.lng)
    );
    if (distance <= radius) {
      if (pickupLocation) {
        pickupLocation.setPosition(clickedLocation);
      } else {
        updateMap(clickedLocation, true);
      }
      if (setPickup) setPickup(clickedLocation);
    }
  };

  return (
    <div className="h-[40vh] w-full">
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_MAP_API_KEY ?? "",
        }}
        center={center}
        zoom={zoom}
        yesIWantToUseGoogleMapApiInternals
        onClick={canEdit ? handleMapClick : undefined}
        onGoogleApiLoaded={({ map, maps }) => {
          setMap(map);
          setMapsInstance(maps);
        }}
        options={{
          fullscreenControl: false,
        }}
      />
    </div>
  );
};

export default PickupLocationMap;
