import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";

import * as Yup from "yup";

import ReactGA from "react-ga4";
import { IQuationnaireTabProps } from "../../../../core/models/quationnaire-tab-props";
import {
  bankAccountExpression,
  sortCodeExpression,
} from "../../../../core/models/enums/patterns-enum";
import {
  getBankDetails,
  updateBankDetails,
} from "../../../../core/services/adi-service";
import { BankDetailsModel } from "../../../../core/models/bank-details-model";
import { scrollToTop, scrollToFirstError } from "../../../helpers/ScrollHelper";
import Input from "../../shared/forms/Input";
import Button from "../../shared/elements/Button";
import PageHeader from "../../shared/elements/PageHeader";
import Skeleton from "@mui/material/Skeleton";
import toast from "react-hot-toast";
import Notification from "../../shared/overlays/Notification";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";

const BankingDetails: FC<IQuationnaireTabProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);

  const validationSchema = Yup.object().shape({
    sort_code: Yup.string()
      .matches(
        new RegExp(sortCodeExpression + "$"),
        "Sort Code should be a number of 6 digits long"
      )
      .required("This field is required")
      .nullable(),
    bank_account_number: Yup.string()
      .matches(
        new RegExp(bankAccountExpression + "$"),
        "Account Number should be 6 to 8 digits long"
      )
      .required("This field is required")
      .nullable(),
    bank_account_holder_name: Yup.string()
      .required("This field is required")
      .nullable(),
  });

  useEffect(() => {
    setLoading(true);
    getBankDetails().then((data) => {
      setLoading(false);
      const bankDetails = data?.data?.results;
      if (bankDetails) {
        bankDetails.sort_code = bankDetails.sort_code ?? "";
        bankDetails.bank_account_number = bankDetails.bank_account_number ?? "";
        bankDetails.bank_account_holder_name =
          bankDetails.bank_account_holder_name ?? "";
        formik.setValues(bankDetails).catch((err) => console.log(err));
      }
    });
  }, []);

  const formik = useFormik({
    initialValues: new BankDetailsModel(),
    enableReinitialize: true,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    scrollToFirstError(formik);
  }, [formik.errors, formik.isSubmitting, formik.isValidating]);

  function onSubmit(
    values: BankDetailsModel,
    { setStatus, setSubmitting }: any
  ) {
    updateBankDetailsFn(formik.values, formik.setStatus, formik.setSubmitting);
  }
  function updateBankDetailsFn(
    values: BankDetailsModel,
    setStatus: any,
    setSubmitting: any
  ) {
    setLoading(true);

    let loadingId = toast.custom(
      () => (
        <Notification
          colour="gray"
          title={"Updating bank details..."}
          description={""}
          Icon={ExclamationCircleIcon}
        />
      ),
      {
        duration: 5000,
        position: "top-center",
      }
    );
    updateBankDetails(values)
      .then((data) => {
        ReactGA.event("update_bank_details");
        setStatus(null);
        toast.custom(
          () => (
            <Notification
              colour="green"
              title={"Saved successfully"}
              description={""}
              Icon={CheckCircleIcon}
            />
          ),
          {
            duration: 5000,
            position: "top-center",
            id: loadingId,
          }
        );
        props.goToNextTab();
        scrollToTop();
        setLoading(false);
      })
      .catch((response) => {
        setLoading(false);
        setSubmitting(false);

        toast.custom(
          () => (
            <Notification
              colour="red"
              title={"Failed to update bank details!"}
              description={""}
              Icon={ExclamationTriangleIcon}
            />
          ),
          {
            duration: 5000,
            position: "top-center",
            id: loadingId,
          }
        );
        scrollToTop();
        if (response?.errors?.length) {
          setStatus(response.errors[0].errorMessage);
        } else {
          setStatus("Sorry, an error has occured");
        }
      });
  }
  return (
    <>
      <div
        className={props.parentWrapper === "questionnaire" ? "hidden" : "mb-5"}
      >
        <PageHeader
          title="Banking Details"
          backToURL={
            props.parentWrapper !== "questionnaire"
              ? "/ADI/settings/overview"
              : ""
          }
        />
      </div>
      <div
        className={`${
          props.parentWrapper === "questionnaire" ? "pb-2" : "px-5 py-4"
        } bg-white`}
      >
        <div
          className={
            `col-12  m-auto` +
            (props.parentWrapper === "questionnaire"
              ? "col-lg-10"
              : "col-lg-12")
          }
        >
          <form onSubmit={formik.handleSubmit} noValidate>
            <div
              className={props.parentWrapper === "questionnaire" ? "-mt-5" : ""}
            >
              <fieldset>
                {/* start:: Bank Sort Code */}
                <div className="clearfix">
                  {loading ? (
                    <Skeleton
                      className="w-full"
                      height={50}
                      animation="wave"
                    ></Skeleton>
                  ) : (
                    <Input
                      label="Sort Code"
                      {...formik.getFieldProps("sort_code")}
                      type="text"
                      inputmode="numeric"
                      placeholder="Sort Code"
                      name="sort_code"
                      className="form-control"
                      id="bank_sort_code"
                      disabled={formik.isSubmitting}
                      required={true}
                      errorMsg={formik.errors.sort_code}
                      error={
                        formik.touched.sort_code && formik.errors.sort_code
                          ? true
                          : false
                      }
                    />
                  )}
                </div>
                {/* end:: Bank Sort Code */}

                {/* start:: Bank account number */}
                <div className="clearfix">
                  {loading ? (
                    <Skeleton
                      className="w-full"
                      height={50}
                      animation="wave"
                    ></Skeleton>
                  ) : (
                    <Input
                      label="Account Number"
                      type="text"
                      inputmode="numeric"
                      {...formik.getFieldProps("bank_account_number")}
                      placeholder="Account Number"
                      name="bank_account_number"
                      className="form-control"
                      id="bank_account_number"
                      disabled={formik.isSubmitting}
                      required={true}
                      errorMsg={formik.errors.bank_account_number}
                      error={
                        formik.touched.bank_account_number &&
                        formik.errors.bank_account_number
                          ? true
                          : false
                      }
                    />
                  )}
                  <span className="errors"></span>
                </div>
                {/* end:: Bank account number */}

                {/* start:: Bank account holder name */}
                <div className="clearfix">
                  {loading ? (
                    <Skeleton
                      className="w-full"
                      height={50}
                      animation="wave"
                    ></Skeleton>
                  ) : (
                    <Input
                      label="Holder Name "
                      type="text"
                      {...formik.getFieldProps("bank_account_holder_name")}
                      placeholder="Holder Name"
                      name="bank_account_holder_name"
                      className="form-control"
                      id="bank_account_holder_name"
                      disabled={formik.isSubmitting}
                      required={true}
                      errorMsg={formik.errors.bank_account_holder_name}
                      error={
                        formik.touched.bank_account_holder_name &&
                        formik.errors.bank_account_holder_name
                          ? true
                          : false
                      }
                    />
                  )}
                </div>
                {/* end:: Bank account holder name */}
              </fieldset>
              <div
                className={`${
                  props.parentWrapper === "questionnaire"
                    ? "flex-row-reverse"
                    : "flex-row"
                } my-3 flex justify-between gap-2`}
              >
                {loading ? (
                  <>
                    <Skeleton
                      className="!rounded-full"
                      height={35}
                      animation="wave"
                      variant="rounded"
                    ></Skeleton>
                    <Skeleton
                      className="!rounded-full"
                      height={35}
                      animation="wave"
                      variant="rounded"
                    ></Skeleton>
                  </>
                ) : (
                  <>
                    <Button
                      disabled={formik.isSubmitting}
                      colour="yellow"
                      size="large"
                      type="submit"
                    >
                      Save
                    </Button>
                    <Button
                      disabled={formik.isSubmitting}
                      onClick={() => props.goToPrevTab()}
                      colour="outline"
                      size="large"
                    >
                      {props.parentWrapper === "questionnaire"
                        ? "Cancel"
                        : "Discard"}
                    </Button>
                  </>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export { BankingDetails };
