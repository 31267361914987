import { format } from "date-fns";
import { getMonths } from "../../core/services/date-service";
// import { format } from 'date-fns';

export const formatDate = (year: string, month: string, day: string) => {
  if (!year || !month || !day) return null;
  return (
    year +
    "-" +
    (getMonths().findIndex((a) => a.value == month) + 1) +
    "-" +
    day
  );
};

export const getMonthLastDay = (year: string, monthStr: string) => {
  if (!year || !monthStr) return null;
  var month = getMonths().findIndex((a) => a.value == monthStr) + 1;
  var day = new Date(Number(year), month, 0).getDate();
  if (!day) return null;
  return year + "-" + month + "-" + day;
};
export const formatFromDate = (dt: any) => {
  if (dt == null) {
    return "";
  }
  //get timezone offset in hours

  var tzoffset = new Date().getTimezoneOffset() / 60;
  const date = new Date(dt);
  // add timezone offset before ISO string
  date.setHours(date.getHours() - tzoffset);
  const dateFormat: RegExp = /(\d{4}\-\d{2}\-\d{2})T(\d{2}:\d{2}:\d{2})/;
  const iso: RegExpMatchArray | null = date!.toISOString().match(dateFormat);
  if (!iso) return "";
  return iso![1] + " " + iso![2];
};
export const concatDateAndTime = (date: Date, time: string) => {
  const dateString = format(date, "yyyy-MM-dd").toString();
  return dateString + " " + time + ":00";
};
export const convertToTimeFormat = (totalMinutes: any) => {
  var hours = Math.floor(totalMinutes / 60);
  var minutes = totalMinutes % 60;

  return (
    (Number(hours) == 0
      ? ""
      : hours +
        (Number(hours) == 1
          ? Number(minutes) == 0
            ? " Hour "
            : " hr "
          : Number(minutes) == 0
          ? " Hours "
          : " hrs ")) +
    (Number(minutes) == 0 ? "" : String(minutes).padStart(2, "0") + " mins")
  );
};
