import React, { useState } from "react";
import { Test } from "../../../core/models/tests/test";
import TestCard from "../shared/cards/TestCard";
import { TestStatusEnum } from "../../../core/models/enums/test-status-enum";
import { mapStatus, mapSubTitle } from "../../mappers/testMapper";
import { ConfirmTestModal } from "./ConfirmTestModal";
import { RejectTestModal } from "./RejectTestModal";
import EmptyState from "../shared/elements/EmptyState";
import { ExclamationCircleIcon, PlusIcon } from "@heroicons/react/24/outline";
import { AddTestResult } from "../jobs/job-details/tabs/_modals/AddTestResult";
import { LogTestStatus } from "../jobs/job-details/tabs/_modals/LogTestStatus";

export type TestListProps = {
  tests: Array<Test>;
  loading: boolean;
  refreshTests: () => void;
};

const TestList = ({ tests, loading, refreshTests }: TestListProps) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [test, setTest] = useState<any>();
  const [jobId, setJobId] = useState<string>();
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [openRejectDialog, setOpenRejectDialog] = useState<boolean>(false);
  const [showLogTestResultModal, setShowLogTestResultModal] = useState(false);
  const [showAddTestResultModal, setShowAddTestResultModal] = useState(false);

  const actionButtons = (test: Test) => {
    let buttons: {
      text: React.ReactNode;
      onClick: () => void;
      color: "yellow" | "darkBlue" | "outline";
      disabled: boolean;
      size: string;
    }[] = [];
    if (
      test.status === TestStatusEnum.PendingResult &&
      test.abilities?.can_fail &&
      test.abilities?.can_pass
    ) {
      buttons.push({
        text: (
          <div className="flex space-x-1 items-center pt-1 pb-0.5">
            <PlusIcon className="w-4 h-4" />
            <span className="text-xs">Log test result</span>
          </div>
        ),
        color: "outline",
        onClick: () => {
          openLogTestStatus(test);
        },
        disabled: submitting,
        size: "large",
      });
    }

    if (test.status === TestStatusEnum.PendingAdiApproval) {
      if (test.abilities.can_accept) {
        buttons.push({
          text: <span>Accept</span>,
          color: "yellow",
          onClick: () => {
            openConfirmModal(test);
          },
          disabled: submitting,
          size: "large",
        });
      }

      if (test.abilities.can_reject) {
        buttons.push({
          text: <span>Reject</span>,
          color: "outline",
          onClick: () => {
            openRejectModal(test);
          },
          disabled: submitting,
          size: "large",
        });
      }
    }
    return buttons;
  };

  const openLogTestStatus = (test: Test) => {
    setTest(test);
    setJobId(test.job_id);
    setShowLogTestResultModal(true);
  };

  const openConfirmModal = (test: Test) => {
    setTest(test);
    setOpenConfirmDialog(true);
  };

  const openRejectModal = (test: Test) => {
    setTest(test);
    setOpenRejectDialog(true);
  };

  const mapData = (test: Test) => {
    const data: any = [
      {
        icon: "pmf-icon-test",
        value: test?.location?.name ?? test.test_location ?? "",
      },
      {
        icon: "pmf-icon-clock",
        value: test?.booked_for_human,
      },
    ];

    if (test.deal_id) {
      data.push({
        icon: "pmf-icon-suitcase",
        value: `#${test.deal_id?.toString()}`,
        badge: true,
      });
    }

    return data;
  };

  return tests.length === 0 && !loading ? (
    <>
      <EmptyState
        title="No tests scheduled yet"
        Icon={ExclamationCircleIcon}
      ></EmptyState>
    </>
  ) : (
    <>
      <ConfirmTestModal
        test={test}
        refreshTests={refreshTests}
        open={openConfirmDialog}
        closeModal={() => setOpenConfirmDialog(false)}
      ></ConfirmTestModal>
      <RejectTestModal
        test={test}
        refreshTests={refreshTests}
        open={openRejectDialog}
        closeModal={() => setOpenRejectDialog(false)}
      ></RejectTestModal>
      <LogTestStatus
        show={showLogTestResultModal}
        onHide={() => {
          refreshTests();
          setShowLogTestResultModal(false);
        }}
        onDismiss={() => {
          setShowLogTestResultModal(false);
        }}
        openAddTestResult={() => setShowAddTestResultModal(true)}
        jobId={jobId}
        jobRef={test?.is_private_student ? null : test?.deal_id}
        testId={test?.id}
        is_private_student={test?.is_private_student}
      />
      <AddTestResult
        show={showAddTestResultModal}
        onHide={() => {
          setShowAddTestResultModal(false);
          refreshTests();
        }}
        jobDetails={{
          is_private_student: test?.is_private_student,
          job_ref: test?.is_private_student ? null : test?.deal_id,
          job: { id: jobId },
        }}
        setJobDetails={() => {}}
      />
      {loading ? (
        <TestCard loading={loading}></TestCard>
      ) : (
        <div className="grid grid-cols-1 xl:grid-cols-3 sm:grid-cols-2 gap-4">
          {tests?.map((test) => (
            <div key={test.id}>
              <TestCard
                loading={loading}
                name={test.student?.full_name ?? test.student_name}
                status={
                  test.created_by_type !== "passmefast" &&
                  test?.status === TestStatusEnum.Confirmed
                    ? undefined
                    : mapStatus(test?.status)
                }
                data={mapData(test)}
                buttons={actionButtons(test)}
                pmfIcon={test.created_by_type === "passmefast"}
                subTitle={mapSubTitle(test.status)}
              ></TestCard>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export { TestList };
