/* eslint-disable jsx-a11y/anchor-is-valid */
import Button from "../elements/Button";
import Badge from "../elements/Badge";
import Skeleton from "@mui/material/Skeleton";
import { ActionMenu } from "../forms/ActionMenu";
import { toAbsoluteUrl } from "../../../helpers";
import { StarIcon } from "@heroicons/react/20/solid";
export type DescriptionListHeaderProps = {
  title?: string;
  pmfIcon?: boolean;
  jobRef?: string | null;
  loading?: boolean | undefined;
  subTitle?: number | null | undefined;
  hideButton?: {
    disabled?: boolean;
    text: React.ReactNode;
    onClick: () => void;
  };
  editButtons?: {
    title: string;
    Icon: any;
    show: boolean;
    onClick: () => void;
  }[];
  isRelevantJob?: boolean;
  isSmartSorted?: boolean;
};

export default function DescriptionListHeader({
  title = "",
  pmfIcon = false,
  jobRef,
  hideButton,
  editButtons,
  subTitle,
  loading = false,
  isRelevantJob = false,
  isSmartSorted = false,
}: DescriptionListHeaderProps) {
  return (
    <>
      <div className="flex justify-between items-center mb-2">
        <h2 className="text-2xl font-extrabold text-darkBlue flex items-center">
          {loading ? (
            <>
              <Skeleton width={60} className="me-2" />
            </>
          ) : (
            <>
              {pmfIcon && (
                <img
                  src={toAbsoluteUrl("/assets/media/logos/pmf.svg")}
                  className="h-6 me-2"
                  alt="pmfIcon"
                />
              )}
              {isRelevantJob && isSmartSorted && (
                <div className="text-yellow me-1">
                  <StarIcon className="h-4 w-4" />
                </div>
              )}
              {title}
            </>
          )}
          {subTitle && (
            <span className="text-base font-medium line-through ml-[6px]">
              {loading ? (
                <Skeleton width={60} className="me-2" />
              ) : (
                <>{subTitle} Hours</>
              )}
            </span>
          )}
        </h2>

        {hideButton &&
          (loading ? (
            <Skeleton width={50} height={50} className="me-2" />
          ) : (
            <Button
              colour="lightGray"
              disabled={hideButton?.disabled}
              onClick={() => hideButton.onClick()}
              fitWidth
              className="px-[10px] !py-0"
            >
              <span className="flex items-center h-fit">
                <i className="pmf-icon-eye-slash text-base me-1"></i>
                <span className="text-[10px]">{hideButton.text}</span>
              </span>
            </Button>
          ))}

        {jobRef ? (
          loading ? (
            <Skeleton width={50} height={20} className="me-2" />
          ) : (
            <>
              <Badge
                colour="gray"
                roundedPill={false}
                size="xs"
                title={jobRef}
              />
            </>
          )
        ) : (
          <></>
        )}
        {editButtons &&
          (loading ? (
            <Skeleton width={30} height={30} className="me-2" />
          ) : (
            <div>
              <ActionMenu options={editButtons}></ActionMenu>
            </div>

            // <Button
            //   colour="outline"
            //   type="button"
            //   fitWidth
            //   onClick={editButton.onClick}
            // >
            //   <span className="">
            //     <div className="flex items-center">
            //       <PencilIcon className="w-4 h-4 me-2" />
            //       {editButton.text}
            //     </div>
            //   </span>
            // </Button>
          ))}
      </div>
    </>
  );
}
