import { Fragment, Suspense, useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { LayoutSplashScreen } from "./app/content/layout/SplashScreen";
import { Routes } from "./app/routing/Routes";
import ReactGTM from "react-gtm-module";
import initializeAmplify from "./setup/amplify/SetupAmplify";
import AuthInit from "./app/content/pages/auth/redux/AuthInit";
import PageViewTracker from "./PageViewTracker";

ReactGTM.initialize({
  gtmId: `${process.env.REACT_APP_GTM_CONTAINER_ID}`,
});

type Props = {
  basename: string;
};

initializeAmplify();

const App: React.FC<Props> = ({ basename }) => {
  useEffect(() => {
    const isSessionOpened = sessionStorage.getItem("isSessionOpened");
    if (!isSessionOpened && localStorage.getItem("impersonator-token")) {
      sessionStorage.setItem("isSessionOpened", "true");
      localStorage.clear();
    }
  }, []);

  return (
    <>
      <Suspense fallback={<LayoutSplashScreen />}>
        <BrowserRouter basename={basename}>
          <PageViewTracker />
          <AuthInit>
            <Routes />
          </AuthInit>
        </BrowserRouter>
      </Suspense>
    </>
  );
};

export default App;
