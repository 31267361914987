import { FC } from "react";
import Modal from "../../components/shared/overlays/Modal";

interface IProps {
  show: any;
  onHide: any;
}

const JobLimitWarningDialog: FC<IProps> = ({show, onHide}) => {

  return (
    <Modal
      open={show}
      hideSubmit={true}
      title="Job limit"
      description={
        "We are unable to allocate you this as you have the maximum number of active jobs"
      }
      closeText={"Close"}
      onClose={onHide}
    />
  );
};

export { JobLimitWarningDialog };
