import { FC, useEffect, useRef, useState } from "react";
import { RateList } from "./RateList";
import PageHeader from "../../shared/elements/PageHeader";
import { AdiRateModel } from "../../../../core/models/rates/adi-rate-model";
import {
  getAdiRates,
  getCancellationPolicies,
  getRateTransmissionTypes,
  updateAdiRates,
} from "../../../../core/services/adi-service";
import { TransmissionTypeModel } from "../../../../core/models/transmission-type-model";
import { AdiRates } from "../../../../core/models/rates/adi-rates";
import { AdiRateRequest } from "../../../../core/models/rates/adi-rate-request";
import Alert from "../../shared/overlays/Alert";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { CancellationPolicyEnum } from "../../../../core/models/enums/cancellation-policy-enum";
import ReactGA from "react-ga4";
import * as Yup from "yup";

export type RatesWrapperProps = {
  parentWrapper: string;
  goToPrevTab: () => void;
  goToNextTab: () => void;
  allowSkip: boolean;
};

const RatesWrapper: FC<any> = ({
  parentWrapper,
  goToPrevTab,
  goToNextTab,
  allowSkip,
}: RatesWrapperProps) => {
  const [adiRatesObject, setAdiRatesObject] = useState<AdiRates>();
  const [transmissionTypes, setTransmissionTypes] = useState<
    Array<TransmissionTypeModel>
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const isQuestionnaire = parentWrapper === "questionnaire";
  const history = useHistory();

  useEffect(() => {
    (async () => {
      setLoading(true);
      const policiesResponse = await getCancellationPolicies().catch(() => {});
      if (
        policiesResponse?.data?.results &&
        policiesResponse.data.results.length > 0
      ) {;
      }
      const transmissionTypeResponse = await getRateTransmissionTypes().catch(
        () => {}
      );
      const transmissionTypes = transmissionTypeResponse?.data?.results ?? [];
      transmissionTypes.forEach((a) => (a.is_used = false));
      setTransmissionTypes(transmissionTypes);

      getRates(transmissionTypes);

    })();
  }, []);

  const getRates = async (transmissionTypes: Array<TransmissionTypeModel>) => {
    const adiRateResponse = await getAdiRates().catch(() => {});
    const response = adiRateResponse?.data?.results;

    response?.adiRates?.forEach((a: AdiRateModel) => {
      let index = transmissionTypes.findIndex(
        (b) => b.id === a.transmission_type_id
      );
      if (index !== -1) {
        transmissionTypes[index].is_used = true;
      }
      a.second_range_hour_price =
        Math.round(Number(a.second_range_hour_price) * 100) / 100 ?? "";
      a.first_range_hour_price =
        Math.round(Number(a.first_range_hour_price) * 100) / 100 ?? "";
      a.third_range_hour_price =
        Math.round(Number(a.third_range_hour_price) * 100) / 100 ?? "";
      a.seven_days_cancel_refund_percentage_text = getPercentageText(
        a.seven_days_cancel_refund_percentage
      );
      a.two_days_cancel_refund_percentage_text = getPercentageText(
        a.two_days_cancel_refund_percentage
      );
    });
    setAdiRatesObject(response);
    setLoading(false);
  };

  function getPercentageText(value: any): string {
    if (value === 0) {
      return "Non refundable";
    }

    if (value === 100) {
      return "Fully refundable";
    }
    let text: string = parseInt(value, 10).toString() + "%";
    return text;
  }

  const refreshRates = async () => {
    setLoading(true);
    getRates(transmissionTypes);
    setLoading(false);
  };

  const setRates = (adiRates: Array<AdiRateModel>) => {
    formik.setFieldValue("adiRates", adiRates);
  };

  const validationSchema = Yup.object().shape({
    cancellation_policy_id: Yup.number().required("This field is required"),
    url: Yup.string()
      .nullable()
      .when("cancellation_policy_id", ([cancellation_policy_id], schema) => {
        return cancellation_policy_id === CancellationPolicyEnum.other
          ? schema.required("This field is required")
          : schema;
      }),
    adiRates: Yup.array().min(
      1,
      "You should add at least 1 rate to save this step"
    ),
  });

  const formik = useFormik({
    initialValues: {
      cancellation_policy_id: adiRatesObject?.cancellation_policy_id,
      url: adiRatesObject?.url,
      adiRates: adiRatesObject?.adiRates,
      file: undefined,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit,
  });

  function onSubmit(values: any, { setStatus, setSubmitting }: any) {
    (async () => {
      setStatus(null);
      setSubmitting(false);
      const request: AdiRateRequest = {
        adi_rates: formik.values.adiRates ?? []
      };
      const updateResponse = await updateAdiRates(request).catch((error) => {
        if (error?.errors?.length) {
          setStatus(error.errors[0].errorMessage);
        } else {
          setStatus("Sorry, an error has occured");
        }
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      });
      if (updateResponse?.status === 200) {
        ReactGA.event("update_rate");
        if (isQuestionnaire) {
          goToNextTab();
        } else {
          history.push("/ADI/settings/overview");
        }
      }
      setSubmitting(false);
    })();
  }

  return (
    <>
      <div className={parentWrapper === "questionnaire" ? 'hidden' : 'mb-5'}>
      <PageHeader
            title="Rates"
            backToURL={
              parentWrapper !== "questionnaire" ? "/ADI/settings/overview" : ""
        }
      />
      </div>
      <div className={parentWrapper === "questionnaire" ?  "" : "px-5"}>
        {formik.status ? (
          <Alert description={formik.status} colour="red"></Alert>
        ) : null}
        {formik.errors.adiRates ? (
          <Alert description={formik.errors.adiRates} colour="red"></Alert>
        ) : null}
        <RateList
          loading={loading}
          parentWrapper={parentWrapper}
          adiRates={adiRatesObject}
          transmissionTypes={transmissionTypes}
          submitting={false}
          refreshRates={refreshRates}
          setRates={setRates}
          questionnaire={parentWrapper === "questionnaire"}
          closeParentModal={goToNextTab}
        ></RateList>
      </div>
    </>
  );
};

export { RatesWrapper };
