import { useEffect } from 'react'
import { completePayment } from '../../../../core/services/connect-service'
import { toAbsoluteUrl } from '../../../helpers';


export function ConfirmPayment() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  let sessionId = urlSearchParams.get('session_id');


  useEffect(() => {
    if (sessionId) {
      completePayment({"status": "complete","session_id":sessionId,"payment_status":"paid"})
      .then((res) => {
      

      })
      .catch((response) => {
      })
    }
  }, [sessionId])



  return (
    <>
      <div className='flex flex-col items-center'>
        <img src={toAbsoluteUrl('/assets/media/svg/success.svg')} alt='payment-successful' className=' w-20 h-20' />
        <h3 className="text-lg font-titlef font-bold text-center mt-6">
          Thank you!
        </h3>
        <p className='text-xs mb-3 text-center'>Payment is done successfully</p>
      </div>
    </>
  )

}