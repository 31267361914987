import React, { BaseSyntheticEvent, useState } from "react";
import { PrismicImage, PrismicText } from "@prismicio/react";
import Skeleton from "@mui/material/Skeleton";
import { ArrowRightIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { toAbsoluteUrl } from "../../../helpers";
export type AlertProps = {
  title?: string;
  colour?: "blue" | "green" | "orange" | "red" | "gray" | "white" | "darkRed";
  description?: string;
  buttons?: {
    title: string;
    onClick: () => void;
  }[];
  list?: string[];
  icon?: string;
  customDesc?: React.ReactNode;
  children?: React.ReactNode;
  loading?: boolean;
  imgSrc?: string;
  hasClose?: boolean;
  basicAlert?: boolean;
  onClick?: () => void;
  size?: "md" | "sm" | "xs" | "lg";
  onCloseClick?: () => void;
  prismicImage?: any;
  prismicTitle?: any;
};
export default function Alert({
  title,
  list,
  colour,
  description,
  buttons,
  icon,
  customDesc,
  children,
  loading = false,
  imgSrc,
  hasClose = false,
  basicAlert = false,
  onClick = () => { },
  size = "sm",
  onCloseClick = () => { },
  prismicImage,
  prismicTitle
}: AlertProps) {
  const [show, setShow] = useState(true);
  return (
    <>
      {show && (
        <div
          onClick={onClick}
          id="alert-hide-show"
          className={`${basicAlert ? "px-3 py-4" : "rounded-md p-3 mb-5"} ${colour
            ? {
              blue: "bg-blue-100 text-blue-800",
              green: "bg-green-100 text-green-800",
              orange: "bg-orange-100 text-orange-800",
              red: "bg-red-100 text-red-800",
              gray: "bg-[#E8E8E8] ",
              white: "bg-white text-darkBlue !p-0",
              darkRed: "bg-danger text-white border-transparent !p-3",
            }[colour]
            : "bg-gray-300 "
            }`}
        >
          <div
            className={`relative flex ${!description && !list
              ? buttons && buttons.length <= 1
                ? "items-center"
                : "items-start sm:items-center"
              : ""
              }`}
          >
            {loading ? (
              <Skeleton
                width={15}
                height={15}
                variant="circular"
                className="me-2"
              />
            ) : imgSrc ? (
              <img
                src={toAbsoluteUrl(`/assets/media/${imgSrc}`)}
                alt=""
                className="w-[30px] h-[30px]"
              />
            ) : (
              prismicImage ? (
                <PrismicImage
                  field={prismicImage}
                  className="self-center"
                  height={48}
                  width={65}
                />
              ) :
                (<i className={`${icon ?? "pmf-icon-info"} text-lg leading-none`}
                ></i>)

            )}
            <div
              className={`ml-2 mr-3 flex flex-grow ${buttons && buttons.length == 1
                ? !list && !description
                  ? "flex-row justify-between items-center"
                  : "flex-col"
                : "flex-col"
                }`}
            >
              {title && <h3 className="leading-3">
                {loading ? (
                  <Skeleton width={40} className="me-2" />
                ) : (
                  <span
                    className={` 
                    ${{
                        xs: "text-xs",
                        sm: "text-sm",
                        md: "text-base",
                        lg: "text-lg",
                      }[size]
                      } `}
                  >
                    {" "}
                    {title}
                  </span>
                )}
              </h3>}

              {prismicTitle &&
                <strong>
                  <PrismicText field={prismicTitle} />
                </strong>
              }
              {customDesc && (
                <>
                  {loading ? (
                    <Skeleton width={100} className="me-2" />
                  ) : (
                    <>{customDesc}</>
                  )}
                </>
              )}
              {children && (
                <>
                  {loading ? (
                    <Skeleton width={70} className="me-2" />
                  ) : (
                    <>{children}</>
                  )}
                </>
              )}
              {description && (
                <div className=" text-[10px]">
                  {loading ? (
                    <Skeleton width={100} className="me-2" />
                  ) : (
                    <>{description}</>
                  )}
                </div>
              )}
              {list && (
                <div className="mt-2 text-sm">
                  <ul role="list" className="list-disc space-y-1 pl-5">
                    {list.map((item, i) => (
                      <li key={i}>{item}</li>
                    ))}
                  </ul>
                </div>
              )}
              {buttons && (
                <div
                  className={`${!description && !list
                    ? buttons && buttons.length == 1
                      ? "mt-0"
                      : "mt-3"
                    : "mt-4"
                    }`}
                >
                  <div className="-mx-2 -my-1.5 flex">
                    {buttons.map((button, i) => (
                      <button
                        type="button"
                        key={i}
                        onClick={() => button.onClick()}
                        className={`${basicAlert
                          ? "rounded-sm py-1 sm:mt-0 mt-2 me-1"
                          : "rounded-md py-1.5"
                          } flex items-center space-x-2 px-2 text-sm font-medium focus:outline-none focus:ring-2 transition-all focus:ring-offset-2
                      ${colour
                            ? {
                              blue: "focus:ring-blue-600 focus:ring-offset-blue-50 bg-blue-100 text-blue-800 hover:bg-blue-200",
                              green:
                                "focus:ring-green-600 focus:ring-offset-green-50 bg-green-100 text-green-800 hover:bg-green-200",
                              orange:
                                "focus:ring-orange-600 focus:ring-offset-orange-50 bg-orange-100 text-orange-800 hover:bg-orange-200",
                              red: "focus:ring-red-600 focus:ring-offset-red-50 text-red-100 bg-red-700",
                              gray: "focus:ring-gray-600 focus:ring-offset-gray-50 bg-gray-100  hover:bg-gray-200",
                              white:
                                "focus:ring-gray-600 focus:ring-offset-gray-50 bg-gray-100  hover:bg-gray-200",
                              darkRed:
                                "focus:ring-red-600 focus:ring-offset-red-50 bg-danger hover:bg-red-700",
                            }[colour]
                            : "focus:ring-gray-600 focus:ring-offset-gray-50 bg-gray-100  hover:bg-gray-200"
                          }
                    `}
                      >
                        {button.title && <span className="me-2">{button.title}</span>}
                        {buttons.length == 1 && (
                          <ArrowRightIcon className="h-4 w-4" />
                        )}
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>
            {hasClose && (
              <div className="block w-4 h-4">
                <XMarkIcon
                  className="w-4 h-4 right-[0px] top-[0px] cursor-pointer absolute"
                  onClick={(e: BaseSyntheticEvent) => {
                    e.stopPropagation();
                    setShow(false);
                    onCloseClick()
                  }}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
