import { FC, useEffect, useState } from "react";
import { Assessment } from "../../../../../core/models/assessments/assessment";
import { AdiJob } from "../../../../../core/models/adi-job";
import { PersonalDataModel } from "../../../../../core/models/personal-data-model";
import { ProgressHistory } from "./_modals/ProgressHistory";
import { AddAssessmentDialog } from "./_modals/AddAssessmentDialog";
import Button from "../../../shared/elements/Button";
import { DocumentCheckIcon, PlusSmallIcon } from "@heroicons/react/24/outline";
import EmptyState from "../../../shared/elements/EmptyState";
import {
  getDealAssessments,
  shareAssessment,
} from "../../../../../core/services/assessment-service";
import toast from "react-hot-toast";
import Notification from "../../../shared/overlays/Notification";
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import TabItem from "../../../shared/data/TabItem";
import { ProgressTabEnum } from "../../../../../core/models/enums/progress-tabs";
import { toAbsoluteUrl } from "../../../../helpers";
import { AddReflectionDialog } from "./_modals/AddReflectionDialog";
import { AddReflectionFormModel } from "./_modals/AddReflectionFormModel";
import { getJobLessonsDataWithReflection } from "../../../../../core/services/job-service";
import HorizontalCheckboxGroup from "../../../shared/forms/HorizontalCheckboxGroup";
import Skeleton from "@mui/material/Skeleton";
import { CircularProgress } from "@mui/joy";
interface IProps {
  jobLoading: boolean;
  jobDetails: AdiJob | null;
  adiData: PersonalDataModel | null;
}

export const ProgressTab: FC<IProps> = ({
  jobLoading,
  jobDetails,
  adiData,
}) => {
  const [jobAssessments, setJobAssessments] = useState<Array<Assessment>>([]);
  const [jobReflection, setJobReflection] = useState<Array<any>>([]);
  const [editedAssessment, setEditedAssessment] = useState<null | Assessment>(
    null
  );
  const [showAddAssessment, setShowAddAssessment] = useState(false);
  const [showAddReflection, setShowAddReflection] = useState(false);
  const [lessons, setLessons] = useState<Array<any>>([]);
  const [selectedLesson, setSelectedLesson] = useState<any>();
  const [edit, setEdit] = useState<boolean>(false);
  const [lessonsWithReflection, setLessonsWithReflection] = useState<
    Array<any>
  >([]);
  const [loading, setLoading] = useState(false);
  const [loadingOfReflection, setLoadingOfReflection] = useState(false);
  const [activeTab, setActiveTab] = useState<string>(
    ProgressTabEnum.ProgressTracker
  );
  let rates = Array.from([1, 2, 3, 4, 5]).map((rate) => {
    return {
      value: rate,
      label: rate + "",
    };
  });
  const handleTabChange = (tabName: string) => {
    setActiveTab(tabName);
  };

  const getAssessments = () => {
    if (jobDetails?.job_id) {
      setLoading(true);
      getDealAssessments(jobDetails?.job_id).then((res) => {
        setJobAssessments(res.data?.results?.assessments ?? []);
        setJobReflection(res.data?.results?.reflections ?? []);
        setLoading(false);
      });
      loadLessonsWithReflection();
    }
  };

  const handleShowAddAssessment = () => {
    setShowAddAssessment(true);
  };

  const handleSetEditedAssessment = (editedAssessment: any) => {
    setEditedAssessment(editedAssessment);
  };

  const handleCloseAddAssessment = () => {
    setShowAddAssessment(false);
    getAssessments();
  };
  const loadLessonsWithReflection = async () => {
    try {
      setLoadingOfReflection(true);
      const res = await getJobLessonsDataWithReflection(
        jobDetails?.job_id ?? "",
        jobDetails?.id ?? ""
      );

      setLessonsWithReflection(
        res.data.results.filter((a) => {
          return a?.reflection.length > 0;
        }) ?? []
      );

      setLessons(res.data.results ?? []);
      setLoadingOfReflection(false);
    } catch {
      setLoadingOfReflection(false);
    }
  };
  useEffect(() => {
    if (editedAssessment != null) {
      setShowAddAssessment(true);
    }
  }, [editedAssessment]);

  useEffect(() => {
    getAssessments();
  }, [jobDetails]);

  const share = async () => {
    const shareResponse = await shareAssessment(jobDetails?.job_id, {
      student_name:
        jobDetails?.student_first_name ?? jobDetails?.student_surname,
      student_email: jobDetails?.student_email,
    }).catch(() => {
      toast.custom(
        () => (
          <Notification
            colour="green"
            title={"Failed to send email!"}
            description={""}
            Icon={ExclamationTriangleIcon}
          />
        ),
        {
          duration: 5000,
          position: "top-center",
        }
      );
    });
    if (shareResponse?.status === 200) {
      toast.custom(
        () => (
          <Notification
            colour="green"
            title={"Email sent successfully!"}
            description={""}
            Icon={CheckCircleIcon}
          />
        ),
        {
          duration: 5000,
          position: "top-center",
        }
      );
    }
  };
  return (
    <div className="w-full px-5 py-4 block">
      <ul className=" text-sm font-medium text-center text-gray-500 rounded-full shadow flex bg-gray-200">
        <TabItem
          label={ProgressTabEnum.ProgressTracker}
          activeTab={activeTab}
          handleTabChange={handleTabChange}
        />
        <TabItem
          label={ProgressTabEnum.ReflectiveLog}
          activeTab={activeTab}
          handleTabChange={handleTabChange}
        />
        {/* Add more TabItems for additional tabs */}
      </ul>
      {activeTab == ProgressTabEnum.ProgressTracker ? (
        <>
          {/* START:: Empty progress placeholder */}
          {(!jobAssessments || jobAssessments.length === 0) &&
            !jobLoading &&
            !loading && (
              <>
                <EmptyState title="No assessments" Icon={DocumentCheckIcon} />
                <Button
                  type="button"
                  className=" mt-4"
                  onClick={() => handleShowAddAssessment()}
                  disabled={jobDetails?.removed_at != null}
                  size="large"
                  fullWidth
                >
                  <span className="flex items-center py-1">
                    <PlusSmallIcon className="w-5 h-5 -mt-[2px]" />{" "}
                    <span className="ms-1 leading-5">New Assessment</span>{" "}
                  </span>
                </Button>
              </>
            )}
          {/* END:: Empty progress placeholder */}

          {/* START:: progress section */}
          <>
            <div className="">
              <ProgressHistory
                assessments={jobAssessments}
                setEditedAssessment={handleSetEditedAssessment}
                loading={loading || jobLoading}
                allowShare={
                  jobDetails?.is_private_student === 1 &&
                  jobDetails?.student_email != null &&
                  jobDetails?.student_email !== undefined
                }
                shareAssessment={share}
              />
              <AddAssessmentDialog
                dealId={jobDetails?.job_ref}
                job_id={jobDetails?.job_id}
                student_contact_id={jobDetails?.job.student_contact_id}
                student_id={jobDetails?.job.student_id}
                student_name={
                  jobDetails?.job?.student_first_name ??
                  jobDetails?.job?.student_surname
                }
                adi_contact_id={adiData?.zendesk_contact_id}
                show={showAddAssessment}
                assessment={editedAssessment}
                is_private_student={
                  jobDetails?.is_private_student === 1 ? true : false
                }
                onHide={handleCloseAddAssessment}
                student_email={jobDetails?.student_email}
              />
            </div>
          </>
          {/* END:: progress section */}
        </>
      ) : (
        <>
          {/* START:: Empty progress placeholder */}
          {(!lessonsWithReflection || lessonsWithReflection.length == 0) &&
            !jobLoading &&
            !loading &&
            !loadingOfReflection && (
              <>
                <EmptyState
                  title="No Reflection"
                  imgUrl={toAbsoluteUrl("/assets/media/svg/Reflection.svg")}
                />
                <Button
                  type="button"
                  className=" mt-4"
                  onClick={() => {setSelectedLesson({});setEdit(false);setShowAddReflection(true)} }
                  disabled={
                    jobDetails?.removed_at != null || lessons.length == 0
                  }
                  size="large"
                  fullWidth
                >
                  <span className="flex items-center py-1">
                    <PlusSmallIcon className="w-5 h-5 -mt-[2px]" />{" "}
                    <span className="ms-1 leading-5">New Reflection</span>{" "}
                  </span>
                </Button>
              </>
            )}
          {
            <>
              {loading || jobLoading || loadingOfReflection ? (
                <div
                  className="w-full mt-2 pt-2
                "
                >
                  <div className="p-2">
                  <div className="flex items-center justify-between bg-darkBlue rounded-[20px] text-white p-5">
                    <Skeleton width="40%" animation="wave"></Skeleton>
                    <Skeleton width={100} height={100} className="me-2" variant="circular"/>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {lessonsWithReflection.length > 0 && (
                    <>
                      <Button
                        type="button"
                        className=" mt-4"
                        onClick={() => {setSelectedLesson({});setEdit(false);setShowAddReflection(true)}}
                        disabled={
                          jobDetails?.removed_at != null || lessons.length == 0
                        }
                        size="large"
                        fullWidth
                      >
                        <span className="flex items-center py-1">
                          <PlusSmallIcon className="w-5 h-5 -mt-[2px]" />{" "}
                          <span className="ms-1 leading-5">New Reflection</span>{" "}
                        </span>
                      </Button>
                      <div className="mt-2 py-1">
                        {lessonsWithReflection?.map(
                          (item: any, index: number) => (
                            <div
                              key={item?.id + "-" + index}
                              className=" w-full my-2 "
                              onClick={()=>{setEdit(true); setSelectedLesson({
                                value: item.id,
                                label: item.lesson_date_str,
                                item: item,
                              }); setShowAddReflection(true)}}
                            >
                              <div className="flex items-center justify-between bg-darkBlue rounded-[20px] text-white p-5">

                                  <h4 className="p-2 text-lg font-bold">
                                    {item?.lesson_date_str}
                                  </h4>
                                  <CircularProgress
                                    determinate
                                    sx={{
                                      "--CircularProgress-size": "90px",
                                      color: "#fff",
                                      "--CircularProgress-linecap": "square",
                                      "--CircularProgress-progressColor":
                                        "#FFD04F",
                                      "--CircularProgress-trackColor":
                                        "#4F779E",
                                    }}
                                    value={
                                      item?.reflection_percentage == undefined
                                        ? 0
                                        : item?.reflection_percentage
                                    }
                                    thickness={10}
                                  >
                                    <span className=" font-bold text-2xl">
                                      {`${
                                        item?.reflection_percentage == undefined
                                          ? 0
                                          : item?.reflection_percentage
                                      }%`}
                                    </span>
                                  </CircularProgress>
                                
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </>
                  )}
                </>
              )}

              <AddReflectionFormModel
                lessons={lessons?.map((elem) => {
                  return {
                    value: elem.id,
                    label: elem.lesson_date_str,
                    item: elem,
                  };
                })}
                selected={selectedLesson}
                edit={edit}
                setShowAddReflectionFormModel={setShowAddReflection}
                jobId={jobDetails?.job_id}
                adiJobId={jobDetails?.id}
                show={showAddReflection}
                studentId={jobDetails?.student_id}
                onClose={(reload: any) => {
                  if (reload) {
                    loadLessonsWithReflection();
                  }
                  setShowAddReflection(false);
                }}
              />
            </>
          }
          {/* END:: Empty progress placeholder */}
        </>
      )}
    </div>
  );
};
