import React from "react";

export interface TextAreaProps extends React.HTMLAttributes<HTMLTextAreaElement> {
    label?: string;
    id?: string;
    name?: string;
    className?: string;
    value?: string;
    onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    error?: boolean;
    errorMsg?: string;
    placeholder?: string;
    disabled?: boolean;
    required?: boolean;
    inputGroup?: boolean;
    inputGroupValue?: string;
    maxLength?: any;
}

export default function TextArea({
    label,
    className = "",
    name,
    id = "",
    value,
    onChange,
    required = false,
    disabled = false,
    inputGroup = false,
    inputGroupValue = "",
    error = false,
    errorMsg = "This field is required",
    placeholder,
    maxLength,
    ...props
}: TextAreaProps) {

    return (
        <>

            <div className="flex flex-col items-start">
                <div className="relative w-full">
                    <label
                        htmlFor={name}
                        className="absolute -top-2 z-10 left-2 inline-block bg-white px-1 text-sm font-medium text-darkBlue"
                    >
                        {label}{required && <span className='text-sm text-red-500'>*</span>}
                    </label>
                    <div className="relative -mt-0.5 rounded-md shadow-sm">
                        {inputGroup &&
                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                <span className="text-gray-500 sm:text-sm">{inputGroupValue}</span>
                            </div>
                        }
                        <textarea
                            {...props}
                            name={name}
                            id={id}
                            value={value}
                            className={`${error ? "ring-red-500" : "ring-gray-300"}
                            ${inputGroup ? "pl-7 pr-20" : "px-2"} 
                            block w-full rounded-sm mt-1 border-0 py-2.5  ring-1 ring-inset  placeholder:text-gray-400 outline-none focus:ring-1 focus:ring-inset focus:ring-indigo-600 text-sm leading-6 ${className}`}
                            placeholder={placeholder}
                            onChange={onChange}
                            disabled={disabled}
                            required={required}
                            maxLength={maxLength}
                        />
                    </div>

                    {error && (
                        <p className="text-sm text-red-600" id="error">
                            {errorMsg}
                        </p>
                    )}
                </div>
            </div>
        </>
       
    );
}
