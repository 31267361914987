import React from "react";
import {
  AdjustmentsHorizontalIcon,
  ChevronLeftIcon,
  ChevronUpDownIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import Button from "./Button";

export interface PageHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
  backToURL?: string;
  description?: string;
  isCenter?: boolean;
  buttons?: {
    text: React.ReactNode;
    notification?: React.ReactNode;
    Icon?: "sort" | "filter";
    onClick: () => void;
  }[];
  storageKey?: string;
}

export default function PageHeader({
  title,
  backToURL,
  buttons,
  isCenter = false,
  storageKey,
}: // navigation,
PageHeaderProps) {
  return (
    <>
      <div className="lg:flex lg:items-center lg:justify-between mb-0 rounded-md transition-all flex items-center pt-6">
        <div
          className={
            isCenter
              ? "min-w-0 flex-1 flex justify-center mb-3"
              : "min-w-0 flex-1 flex mb-0"
          }
        >
          {backToURL ? (
            <>
              <Link
                to={
                  storageKey
                    ? sessionStorage.getItem(storageKey) != null
                      ? backToURL + sessionStorage.getItem(storageKey)
                      : backToURL
                    : backToURL
                }
                className="flex items-center px-5"
              >
                <ChevronLeftIcon className="w-4 h-4 me-[10px] -ms-2" />
                <h1 className="text-2xl font-titlef font-bold text-darkBlue">
                  {title}
                </h1>
              </Link>
            </>
          ) : (
            <h1 className="text-2xl font-titlef font-bold text-darkBlue">
              {title}
            </h1>
          )}
        </div>
        {buttons && buttons.length > 0 ? (
          <div className="flex space-x-4 items-center">
            {buttons.map((button, i) => (
              <Button
                onClick={() => button.onClick()}
                key={i}
                colour="transparent"
                size="fit"
              >
                <span className="flex text-sm text-darkBlue items-center">
                  {button.Icon === "sort" && (
                    <ChevronUpDownIcon className="h-4 w-4 mr-1 text-darkBlue" />
                  )}
                  {button.Icon === "filter" && (
                    <AdjustmentsHorizontalIcon className="h-4 w-4 mr-1 text-darkBlue" />
                  )}
                  {button.text}{" "}
                  {button.notification && <>{button.notification}</>}{" "}
                </span>
              </Button>
            ))}
          </div>
        ) : null}
      </div>
    </>
  );
}
