import { FC, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { createClient } from "../../../../../prismic";
import { PrismicImage, PrismicRichText, PrismicProvider } from "@prismicio/react";
import { useQuery } from "../../../helpers/QueryParamsHelper";
import PageHeader from "../../../components/shared/elements/PageHeader";
import { toAbsoluteUrl } from "../../../helpers";
import { Tab } from "@headlessui/react";
import AuthorVideosList from "./AuthorVideosList";
import AuthorArticlesList from "./AuthorArticlesList";

const AuthorPage: FC = () => {
  const history = useHistory();
  const { authorUid }: any = useParams();
  const [authorId, setAuthorId] = useState<any>();
  const [author, setAuthor] = useState<any>();
  const query = useQuery();
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    if (query.get("authorTab")?.toLocaleLowerCase() === "article")
      setSelectedIndex(1);
  }, [query]);

  useEffect(() => {
    query.set("authorTab", selectedIndex === 0 ? "videos" : "article");
    history.replace({
      search: query.toString(),
    });
  }, [selectedIndex]);

  const tabs = [
    {
      key: "videos",
      title: "Videos",
      component: <AuthorVideosList authorId={authorId} />,
    },
    {
      key: "articles",
      title: "Articles",
      component: <AuthorArticlesList authorId={authorId} authorUid={author?.uid} />,
    },
  ];
  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
  }

  useEffect(() => {
    const fetchAuthor = async () => {
      try {

        const authorResponse = await createClient.getByUID("author", authorUid);
        setAuthor(authorResponse);
        setAuthorId(authorResponse.id);

      } catch (error) {
        console.error("Error while fetching author:", error);
      }
    };

    fetchAuthor();
  }, [createClient]);

  return (
    <>
      <PageHeader
        title=""
        backToURL={
          query.get("category") != null
            ? `/ADI/cpd?tab=article&uid=${query.get("articleId")}&page=${query.get("pageCounter")}&mainTab=${query.get("mainTab")}&category=${query.get("category")}`
            : `/ADI/cpd?tab=article&uid=${query.get("articleId")}&page=${query.get("pageCounter")}&mainTab=${query.get("mainTab")}`
        }
      />

      <>

        <div className="flex flex-col gap-1 items-center md:flex-row md:gap-36 mx-auto w-max">
          <div className="flex flex-col items-center">
            {author?.data?.author_image ? (
              <PrismicImage
                field={author?.data?.author_image}
                className="w-24 h-24 mb-4 rounded-full bg-gray-100 object-cover"

              />
            ) :
              (<img
                src={toAbsoluteUrl(`/assets/media/CPD/Default_user.png`)}
                className="w-24 h-24 mb-4 rounded-full bg-gray-100 object-cover"
              />
              )}
            <h2 className="text-2xl font-bold">{author?.data?.author_name}</h2>
          </div>
          <div className="md:text-left text-md md:max-w-[490px]  max-w-[370px] p-3">
            <PrismicRichText field={author?.data?.write_up} />
          </div>
        </div>
        {/* <ArticleTile loading={true}/> */}
        <div className="px-5 pb-5">
          <PrismicProvider client={createClient}>
            <Tab.Group
              selectedIndex={selectedIndex}
              onChange={(index) => {
                setSelectedIndex(index);
              }}
            >
              <Tab.List className="flex space-x-1 lg:w-1/4 sm:w-1/2">
                {tabs.map((tab) => (
                  <Tab
                    key={tab.key}
                    className={({ selected }) =>
                      classNames(
                        "w-full py-2.5 text-lg font-medium leading-5",
                        "ring-white ring-opacity-60 ring-offset-2 ring-offset-transparent focus:outline-none focus:ring-none",
                        selected
                          ? " border-b-4 border-darkBlue"
                          : " text-[#A8A8A8] hover:brightness-75"
                      )
                    }
                  >
                    <>{tab.title}</>
                  </Tab>
                ))}
              </Tab.List>
              <Tab.Panels className="">
                {tabs.map((tab) => (
                  <Tab.Panel
                    key={tab.key}
                    className={`grid grid-cols-1 pt-6 gap-0 ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none`}
                  >
                    {tab.component}
                  </Tab.Panel>
                ))}
              </Tab.Panels>
            </Tab.Group>
          </PrismicProvider>
        </div>
      </>
    </>
  );
};

export default AuthorPage;
