import { FC, useEffect, useState } from "react";
import PageHeader from "../../components/shared/elements/PageHeader";
import { useHistory, useParams } from "react-router-dom";
import { createClient } from "../../../../prismic";
import { filter } from "@prismicio/client";
import VideoTile from "./_shared/VideoTile";
import { VideoModal } from "./videos/VideoModal";
import ArticleTile from "./_shared/ArticleTile";
import { saveAdiClickedPage } from "../../../core/services/adi-service";

const CPDSearchResult: FC = () => {
  const history = useHistory();
  const { searchVal }: any = useParams();

  const [searchResults, setSearchResults] = useState<Array<any>>([]);
  const [searchArticlesResults, setSearchArticlesResults] = useState<
    Array<any>
  >([]);

  const [selectedVideo, setSelectedVideo] = useState<any>();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const openVideoModal = (video?: any) => {
    setSelectedVideo(video);
    setShow(true);
  };

  useEffect(() => {
    // Define your Prismic query
    const prismicQuery = createClient.get({
      filters: [
        filter.at("document.type", "videos"),
        filter.fulltext("my.videos.title", searchVal),
      ],
    });
    const articlesQuery = createClient.get({
      filters: [
        filter.at("document.type", "cpd_article"),
        filter.fulltext("my.cpd_article.title", searchVal),
      ],
    });

    articlesQuery
      .then((response) => {
        // Update the search results with filtered data
        setSearchArticlesResults(response.results);
      })
      .catch((error) => {
        console.error("Error fetching Prismic data:", error);
      });

    prismicQuery
      .then((response) => {
        console.log("prismicQuery", response.results);
        // Update the search results with filtered data
        setSearchResults(response.results);
      })
      .catch((error) => {
        console.error("Error fetching Prismic data:", error);
      });
  }, [searchVal, createClient]);

  const adiClickedPage = async (e: any) => {
    await saveAdiClickedPage(e).catch(() => { });
  };

  return (
    <>
      <VideoModal
        title={selectedVideo?.title}
        show={show}
        onHide={handleClose}
        url={selectedVideo?.vidurl?.url}
      ></VideoModal>

      <div className="mb-2">
        <PageHeader title={`Result for "${searchVal}"`} backToURL="/ADI/cpd" />
      </div>

      <div className="px-5 pb-5">
        <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 xl:grid-cols-3 lg:gap-x-8">
          {searchResults?.map((item, index) => (
            <VideoTile
              key={index}
              imgURL={item.data.thumb_img}
              vidDuration={item.data.duration}
              author={item?.data.author}
              title={item.data.title}
              uid={item.uid}
              authorData={item.data.author_data}
              onShowVideoClick={() => {
                adiClickedPage({
                  page_url: window.location.pathname + "/video",
                  page_name: item.videoTitle,
                });
                openVideoModal(item?.data);
              }}
            />
          ))}
          {searchArticlesResults?.map((item, index) => (
            <ArticleTile
              key={index}
              imgURL={item.data.thumb_img}
              author={item?.data.author}
              title={item.data.title}
              loading={false}
              uid={item.uid}
              content={item.data.content}
              authorData={item.data.author_data}
              onShowArticleClick={() => {
                history.push(
                  `/ADI/cpd/article/${item?.uid}?searchVal=${searchVal}`
                );
              }}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default CPDSearchResult;
