import StackedCardWithActionsMenu from "../data/StackedCardWithActionsMenu";
import { LessonRecurringOptionEnum } from "../../../../core/models/enums/lesson-recurring_option-enum";
import moment from "moment";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { OtherEventModal } from "../../lessons/_modals/OtherEventModal";
import { RemoveOtherEventModal } from "../../lessons/_modals/RemoveOtherEventModal";

interface IOtherEventCardProp {
  event: any;
  refreshParent?: () => void;
  isMonthView?: boolean;
}

export default function OtherEventCard({
  event,
  refreshParent,
  isMonthView = false
}: IOtherEventCardProp) {
  const [showDeleteOtherEventCard, setShowDeleteOtherEventCard] =
    useState<boolean>(false);
  const [showUpdateOtherEventCard, setShowUpdateOtherEventCard] =
    useState<boolean>(false);

  const getRecurringOption = () => {
    switch (event?.recurring_option) {
      case LessonRecurringOptionEnum.Daily:
        return "Daily";
      case LessonRecurringOptionEnum.Weekly:
        return "Weekly";
      case LessonRecurringOptionEnum.Fortnightly:
        return "Fortnightly";
      default:
        return "Never";
    }
  };

  return (
    <>
      <div className={`flex flex-col w-full rounded bg-white ${!isMonthView ? 'shadow p-1 mb-4' : ''}`}>
        <StackedCardWithActionsMenu
          title={isMonthView ? event?.date_str : event?.event_name}
          icon="pmf-icon-calendar"
          description={
            event?.is_all_day
              ? isMonthView ? '' : moment(event?.date).format("DD MMM, yyyy")
              : event?.recurring_option === LessonRecurringOptionEnum.Never
              ? (isMonthView ? moment(event?.date).format("h:mm a") : event?.date) + " to " + event?.to_time_str
              : (isMonthView ? moment(event?.date).format("h:mm a") : event?.date) + " to " + event?.series_end_date
          }
          pillTitle={
            event?.is_all_day
              ? "All day"
              : event?.recurring_option !== LessonRecurringOptionEnum.Never
              ? getRecurringOption()
              : null
          }
          loading={false}
          showAction={true}
          actions={[
            {
              Icon: PencilSquareIcon,
              title: "Edit Other Event",
              show: true,
              onClick: () => {
                setShowUpdateOtherEventCard(true);
              },
            },
            {
              Icon: TrashIcon,
              title: "Delete Other Event",
              show: true,
              onClick: () => {
                setShowDeleteOtherEventCard(true);
              },
            },
          ]}
          isCanlenderView={isMonthView}
        >
          {!isMonthView ? <p className="text-sm text-darkBlue mt-1 leading-5">
            {event?.event_location}
          </p> :
          <div className="pt-3 pb-1">
            {event?.event_name && (
              <div>
                <label className="text-sm">
                  <strong>Event: </strong>{" "}
                  {event?.event_name}
                </label>
              </div>
            )}
          </div>
          }
        </StackedCardWithActionsMenu>
      </div>
      
      <OtherEventModal
        show={showUpdateOtherEventCard}
        onHide={() => setShowUpdateOtherEventCard(false)}
        refreshParent={refreshParent}
        event={event}
        isEditMode={true}
      />

      <RemoveOtherEventModal
        show={showDeleteOtherEventCard}
        onHide={() => setShowDeleteOtherEventCard(false)}
        refreshParent={refreshParent}
        event={event}
      />
    </>
  );
}

export { OtherEventCard };
