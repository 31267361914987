import { BaseResponse } from "../models/base-response";
import { PrivateStudentDataModel } from "../models/private-student-data";
import modifiedFetch from "../../../setup/axios/ModifiedFetch";
import { AvailabilityModel } from "../models/availability-modal";
import { DayModel } from "../models/day-model";

const STUDENT_URL = process.env.REACT_APP_STUDENTS_BASE_URL;

export function addPrivateStudent(
  data: PrivateStudentDataModel
): Promise<BaseResponse<any>> {
  return modifiedFetch({
    url: `${STUDENT_URL}/students/private/create`,
    method: "POST",
    data: data,
  });
}

export function getPrivateStudentavAilability(
  student_id: string
): Promise<BaseResponse<Array<AvailabilityModel>>> {
  return modifiedFetch({
    url: `${STUDENT_URL}/students/private/${student_id}/availability`,
    method: "GET",
  });
}

export function addPrivateStudentavAilability(
  studentId: string,
  data: any
): Promise<BaseResponse<any>> {
  return modifiedFetch({
    url: `${STUDENT_URL}/students/private/${studentId}/availability`,
    method: "POST",
    data: data,
  });
}

export function updatePrivateStdEmail(
  email: string,
  student_id: string
): Promise<BaseResponse<any>> {
  return modifiedFetch({
    url: `${STUDENT_URL}/students/private/update-email`,
    method: "PUT",
    data: {
      email: email,
      student_id: student_id,
    },
  });
}
export function updatePrivateStudent(data: any, student_id: string): Promise<BaseResponse<any>> {
  return modifiedFetch({
    url: `${STUDENT_URL}/students/private/${student_id}/update`,
    method: "PUT",
    data: data,
  });
}
export function getStudentWeekDays(): Promise<
  BaseResponse<Array<DayModel>>
> {
  return modifiedFetch({
    url: `${STUDENT_URL}/days`,
    method: "GET",
  });
}

export function getStudentAvailability(studentId: string): Promise<
  BaseResponse<Array<DayModel>>
> {
  return modifiedFetch({
    url: `${STUDENT_URL}/students/${studentId}/availability-for-adi`,
    method: "GET",
  });
}

