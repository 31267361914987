/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import Badge from "../elements/Badge";
import { ActionMenu } from "../forms/ActionMenu";
import { toAbsoluteUrl } from "../../../helpers";

interface IDetailsCardProp {
  icon?: any
  title?: string,
  titleIcon?: string,
  subTitle?: any,
  badge?: string | null,
  loading?: boolean,
  actions?: any,
  pmfIcon?: boolean,
  children?: React.ReactNode;
  showAction?: any,
  status?: {
    title: string;
    color: string;
  };

}

const DetailsCard: FC<IDetailsCardProp> = ({ titleIcon, title, subTitle, badge, loading, actions, pmfIcon, children, showAction = true, status }) => {
  return (
    <>
      <div className="flex flex-col w-full rounded shadow bg-white p-3.5 mb-4">
        <div className="flex justify-between items-start">
          <div className="flex items-center">
            {titleIcon && <span>
              <i className={`${titleIcon} text-lg text-darkBlue bg-[#E8E8E8] me-2 p-[10px] rounded-full`}></i>
            </span>}
            <div>
              <h3 className="text-sm leading-6 text-darkBlue">
                {title}
              </h3>
              <h4 className="text-sm font-bold leading-6 text-darkBlue">
                {subTitle}
              </h4>
            </div>
          </div>

          {(actions?.length > 0 && showAction) &&
            <div className="ml-4 flex flex-shrink-0">
              <ActionMenu options={actions} ></ActionMenu>
            </div>}
        </div>

        {children && <div className="mt-3">
          {children}
        </div>}

        {(status || badge) && <div className="mt-4 flex justify-between">
          {status && <><div className="flex items-center gap-x-1.5">

            <div
              className={`
                        ${{
                  darkGreen: "bg-green",
                  yellow: "bg-yellow",
                  darkRed: "bg-danger",
                }[status?.color]} 
                        h-1.5 w-1.5 rounded-full`} />
          <p className="text-xs">{status?.title}</p>
            {pmfIcon && <img src={toAbsoluteUrl('/assets/media/logos/pmf.svg')} alt="pmf" className='w-[14px]' />}
          </div></>}

          {(badge && badge != null) &&
            <Badge title={badge} colour="gray" roundedPill={false} size="xs"></Badge>
          }
        </div>}
      </div>
    </>
  );
};

export { DetailsCard };
