import { PlusIcon } from "@heroicons/react/24/outline";
import React from "react";

export interface AddNewEmptyStateProps extends React.HTMLAttributes<HTMLDivElement> {
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  title?: string;
  description?: string;
  onClick :() => void
}

export default function AddNewEmptyState({
  title = "Add new",
  Icon,
  onClick,
  ...props
}: AddNewEmptyStateProps) {
  return (
    <div
      className={`flex flex-col items-center justify-center sm:min-w-[360px] sm:max-w-fit min-w-[210px]`}
      {...props}
    >

<button
      type="button"
      className="relative w-full rounded-lg px-12 py-14 text-center flex flex-col items-center focus:outline-none bg-gray-200"
      onClick={()=> onClick()}
    >
     <PlusIcon className="w-10 h-10 "></PlusIcon>
      {/* {Icon && <Icon className="h-10 w-10" />} */}
      <span className="mt-2 block text-lg font-semibold ">{title}</span>
    </button>

      
    </div>
  );
}
