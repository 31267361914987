import { FC, useEffect, useState } from "react";
import update from "immutability-helper";
import ReactGA from "react-ga4";
import { AssessmentNotes } from "./AssessmentNotes";
import { Assessment } from "../../../../../../core/models/assessments/assessment";
import {
  addAssessment,
  getDealAssessments,
  getSections,
} from "../../../../../../core/services/assessment-service";
import { Section } from "../../../../../../core/models/assessments/section";
import Modal from "../../../../shared/overlays/Modal";
import DisclosureItem from "../../../../shared/elements/DisclosureItem";
import HorizontalCheckboxGroup from "../../../../shared/forms/HorizontalCheckboxGroup";
import Alert from "../../../../shared/overlays/Alert";
import { getadiPaidJobs } from "../../../../../../core/services/job-service";
import { getPersonalData } from "../../../../../../core/services/adi-service";
import Dropdown from "../../../../shared/forms/Dropdown";
import toast, { Toaster } from 'react-hot-toast'
import Notification from "../../../../shared/overlays/Notification";
import { CheckCircleIcon, ExclamationCircleIcon, ExclamationTriangleIcon } from "@heroicons/react/24/outline";

interface IProps {
  dealId?: any;
  student_id?: any;
  student_name?: any;
  job_id?: any;
  student_contact_id?: any;
  adi_contact_id?: any;
  show: any;
  onHide: any;
  assessment?: Assessment | null;
  is_private_student?: boolean,
  student_email?: string
  refreshParent?: any
}

const AddAssessmentDialog: FC<IProps> = (props) => {
  const [sections, setSections] = useState<Array<Section>>([]);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showJobs, setShowJobs] = useState<boolean>(false);
  const [notes, setNotes] = useState<Array<{note: string}>>([]);
  const [currentJobs, setCurrentJobs] = useState<Array<any>>([])
  const [selectedJob, setSelectedJob] = useState<any>(null)
  const [currentAssessment, setCurrentAssessment] = useState<Assessment | null>(null)

  const loadJobs = async () => {
    try {
      setShowJobs(true)
      setLoading(true)
      var personalDataResponse = await getPersonalData()
      var studentsResponse = await getadiPaidJobs()
      setSelectedJob({
        ...selectedJob,
        adi_contact_id: personalDataResponse.data.results.zendesk_contact_id
      })
      const jobs = studentsResponse?.data?.results
      if (jobs) {
        const mappedJobs = Array.from(jobs).map((job) => {
          return {
            value: job.job_id,
            label: `${job?.student_first_name ?? ''} ${job?.student_surname ?? ''}`,
            deal_id: job.job_ref,
            student_id: job?.student_id,
            student_name: `${job?.student_first_name ?? ''} ${job?.student_surname ?? ''}`,
            job_id: job?.job_id,
            student_contact_id: job?.student_contact_id,
            is_private_student: job?.is_private_student,
            student_email: job?.student_email
          }
        })
        setCurrentJobs(mappedJobs)
        if (mappedJobs.length === 1) {
          setSelectedJob(mappedJobs[0]?.value)
        }
      }
      setLoading(false)
    }
    catch {
      setLoading(false)
      toast.custom(
        () => (
          <Notification
            colour="red"
            title={"Error loading your jobs"}
            description={""}
            Icon={ExclamationTriangleIcon}
          />
        ),
        {
          duration: 5000,
          position: "top-center",
        }
      );
    }
  }

  const onSelectedJobChanges = async (selected: any) => {
    try {
      setSelectedJob({
        ...selectedJob,
        deal_id: selected?.deal_id,
        student_id: selected?.student_id,
        student_name: selected?.student_name,
        job_id: selected?.job_id,
        student_contact_id: selected?.student_contact_id,
        is_private_student: selected?.is_private_student,
        student_email: selected?.student_email
      })
      setLoading(true);

      let jobAssessments = (await getDealAssessments(selected?.job_id))?.data?.results?.assessments ?? []
      if (jobAssessments?.length > 0) {
        setCurrentAssessment(jobAssessments[0])
      } else {
        let sectionsResponse = await getSections(selected.job_id);
        setSections(sectionsResponse.data.results);
      }
      setLoading(false);
    } catch {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (props.show) {
      if (!props?.job_id) {
        loadJobs()
      } else {
        setSelectedJob({
          deal_id: props?.dealId,
          student_id: props?.student_id,
          student_name: props?.student_name,
          job_id: props?.job_id,
          student_contact_id: props?.student_contact_id,
          adi_contact_id: props?.adi_contact_id,
          is_private_student: props?.is_private_student,
          student_email: props.student_email
        })

        if (props.assessment == null) {
          setLoading(true);
          getSections(props.job_id).then((res) => {
            setSections(res.data.results);
            setLoading(false);
          }).catch(() => {
            setLoading(false);
            toast.custom(
              () => (
                <Notification
                  colour="red"
                  title={"Error getting sections!"}
                  description={""}
                  Icon={ExclamationTriangleIcon}
                />
              ),
              {
                duration: 5000,
                position: "top-center",
              }
            );
          });
        } else {
          setCurrentAssessment(props?.assessment)
        }
      }
    }
  }, [props.show]);

  useEffect(() => {
    if (currentAssessment != null) {
      setLoading(true);
      let assessmentSection = currentAssessment.sections;
      assessmentSection.forEach((a) => {
        a.section_id = a.id;
      });
      setSections(assessmentSection);

      let notesSection = currentAssessment.notes.reverse().map((note) => {
        return { note: note.note };
      });
      if (notesSection.length === 0) setNotes([]);
      else setNotes(notesSection);
      setLoading(false);
    }
  }, [currentAssessment]);

  let rates = Array.from([1, 2, 3, 4, 5]).map((rate) => {
    return {
      value: rate,
      label: rate + "",
    };
  });

  const sectionItemRateChanged = (
    checked: boolean,
    section_id: number,
    section_item_id: number,
    rate: any,
    completed: boolean = false
  ) => {
    let section = sections[section_id],
      item = sections[section_id].section_items[section_item_id],
      oldRate = item.rate;
    if (section.score === undefined || section.score === null) {
      section.score = 0;
    }
    if (checked === true) {
      item.rate = rate;
      item.completed = completed;
      section.score = section.score + rate - oldRate;
    } else {
      item.rate = 0;
      item.completed = false;
      section.score = section.score - oldRate;
    }
    update(section.section_items, { $splice: [[section_item_id, 1, item]] });
    update(sections, { $splice: [[section_id, 1, section]] });
    setSections([...sections]);
  };

  const handleClose = () => {
    setSections([]);
    setNotes([]);
    setSubmitting(false);
    setLoading(false);
    setShowJobs(false);
    setCurrentJobs([])
    setSelectedJob(null)
    setCurrentAssessment(null)
    props.onHide();
  };

  const addNewAssessment = () => {
    setSubmitting(true);
    let assessment = new Assessment();
    assessment.sections = sections;
    assessment.adi_contact_id = selectedJob.adi_contact_id;
    assessment.job_id = selectedJob.job_id;
    assessment.student_contact_id = selectedJob.student_contact_id;
    assessment.student_id = selectedJob.student_id;
    assessment.zendesk_deal_id = selectedJob.deal_id;
    assessment.is_private_student = selectedJob.is_private_student;
    assessment.notes = notes.filter((note) => note.note !== "");
    assessment.student_email = selectedJob.student_email;
    assessment.student_name = selectedJob.student_name;

    let loadingId = toast.custom(
      () => (
        <Notification
          colour="gray"
          title={"Adding Assessment..."}
          description={""}
          Icon={ExclamationCircleIcon}
        />
      ),
      {
        duration: 5000,
        position: "top-center",
      }
    );
    addAssessment(assessment).then(() => {
      ReactGA.event("add_progress");
      handleClose();
      if(props.refreshParent !== undefined){
        props.refreshParent()
      }
    
      toast.custom(
        () => (
          <Notification
            colour="green"
            title={`Progress successfully logged. The learner will be notified ${selectedJob.is_private_student ? " by email." : " in their PassMeFast Portal."}`}
            description={""}
            Icon={CheckCircleIcon}
          />
        ),
        {
          duration: 5000,
          position: "top-center",
          id: loadingId
        }
      );
    }).catch(() => {
      setSubmitting(false);
      toast.custom(
        () => (
          <Notification
            colour="red"
            title={"Couldn't add assessment!"}
            description={""}
            Icon={ExclamationTriangleIcon}
          />
        ),
        {
          duration: 5000,
          position: "top-center",
          id: loadingId
        }
      );
    });
  };

  return (
    <>
      <Toaster />
      <form noValidate id="kt_update_rate_form">
        <Modal
          open={props.show}
          title="New Assessment"
          submitText="Share"
          onClose={handleClose}
          onSubmit={addNewAssessment}
          disabled={submitting || loading}
          disableSubmit={selectedJob?.job_id == null}
          setMinHeight={true}
        >
          {showJobs ? (
            <Dropdown
              options={currentJobs}
              label='Learner'
              placeholder='Select Learner'
              required={true}
              name='job_id'
              disabled={loading || submitting}
              id='job_id'
              dropdownPosition={selectedJob?.job_id ? "absolute" : "static"}
              isSearchable
              value={{
                value: selectedJob?.job_id ?? "",
                label: !selectedJob?.job_id
                  ? currentJobs.length === 1
                    ? currentJobs[0]?.label
                    : 'Select job'
                  : currentJobs.find((c) => c.value === selectedJob?.job_id)
                    ?.label,
              }}
              onChange={onSelectedJobChanges}
            />
          ) : <></>}
          {sections.length > 0 ?
            <Alert colour="blue" icon="pmf-icon-info">
              <span className="text-sm">
                Select from <b>1-5</b> where <b>1</b> is Introduced, <b>2</b> is
                Helped, <b>3</b> is Prompted, <b>4</b> is Independent, <b>5</b> is
                Reflection.
              </span>
            </Alert>
            : <></>
          }

          {sections.map((section, key) => {
            return (
              <div key={section.section_id} className="border rounded border-[#E6E6E6] mb-4">
                <DisclosureItem
                  disclosureCard disclosureCardSize="sm"
                  title={section.section_name}
                  count={section.score ?? 0}
                  progress={true}
                >
                  {section.section_items.map((item, index) => {
                    return (
                      <div
                        className="mb-5 last:mb-0 px-3 last:pb-4"
                        key={item.section_item_id + "-" + index}
                      >
                        <p className="text-xs font-semibold mb-2">
                          {item.item_name}
                        </p>
                        <div
                          className="grid grid-cols-4 gap-2"
                          aria-disabled={item.completed_at !== null}
                        >
                          <div className="col-span-3">
                            <HorizontalCheckboxGroup
                              values={[item.rate]}
                              disabled={item.completed_at != null}
                              onChange={(e: any, value: number) => {
                                sectionItemRateChanged(
                                  e?.target?.checked,
                                  key,
                                  index,
                                  value
                                );
                              }}
                              name={item.section_item_id + ""}
                              options={rates}
                            />
                          </div>
                          <div className="grid w-full grid-cols-1 relative">
                            <input
                              className="checked:accent-[#163a5e] peer absolute opacity-0 top-0 bottom-0 left-0 right-0"
                              type="checkbox"
                              name={item.section_item_id + "c"}
                              id={item.section_item_id + "c"}
                              value={"completed"}
                              checked={
                                item.completed_at != null ||
                                item.completed === true
                              }
                              onChange={(e) =>
                                sectionItemRateChanged(
                                  e.target.checked,
                                  key,
                                  index,
                                  5,
                                  true
                                )
                              }
                              data-parsley-multiple={"completed"}
                              disabled={item.completed_at != null}
                            />
                            <label
                              htmlFor={item.section_item_id + "c"}
                              className="inline-flex items-center justify-between w-full py-1
                          bg-white border-2 border-gray-200 rounded-lg cursor-pointer 
                          peer-checked:border-darkBlue peer-checked:bg-darkBlue peer-checked:text-white
                           hover: hover:bg-gray-50"
                            >
                              <div className="w-full text-[10px] font-semibold text-center">
                                {" "}
                                Completed{" "}
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </DisclosureItem>
              </div>
            );
          })}

          {sections.length > 0 ?
            <div className="pt-2">
              <AssessmentNotes
                notes={notes}
                setNotes={setNotes}
                student_name={selectedJob?.student_name}
                disabled={loading || submitting}
              ></AssessmentNotes>
            </div>
            : <></>
          }
        </Modal>
      </form>
    </>
  );
};

export { AddAssessmentDialog };
