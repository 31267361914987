import { FC } from 'react'
import RadioInput from '../../shared/forms/RadioInput';

type Props = {
  editAll: boolean
  setEditAll: any
  isPrivateJob: boolean
  type: string
}

const EditInSeries: FC<Props> = ({editAll, setEditAll, isPrivateJob, type}) => {

  return (
    <>
      <RadioInput
        label={`This ${type} is in a series. What do you want to edit?`}
        name="lesson_in_series"
        options={[
          { value: false, label: "Just this one" },
          { value: true, label: isPrivateJob ? "The entire series" : "This & all upcoming" }
        ]}
        onClick={(value: any) => setEditAll(value)}
        value={editAll}
        disabled={false}
        required={false}
      />
    </>
  )
}

export { EditInSeries };