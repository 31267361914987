import { useFormik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import Modal from "../../shared/overlays/Modal";
import Alert from "../../shared/overlays/Alert";
import { AdiRateModel } from "../../../../core/models/rates/adi-rate-model";
import RadioInputGroup from "../../shared/forms/RadioInputGroup";
import Input from "../../shared/forms/Input";
import { TransmissionTypeModel } from "../../../../core/models/transmission-type-model";
import { updateAdiRates } from "../../../../core/services/adi-service";
import ReactGA from "react-ga4";
import { AdiRates } from "../../../../core/models/rates/adi-rates";
import { AdiRateRequest } from "../../../../core/models/rates/adi-rate-request";
import toast from "react-hot-toast";
import Notification from "../../shared/overlays/Notification";
import {
  ExclamationCircleIcon,
  CheckCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";

export type RateModalProps = {
  closeModal: (adiRates?: Array<AdiRateModel>) => void;
  refreshRates: () => void;
  open: boolean;
  rate?: AdiRateModel;
  transmissionTypes: Array<TransmissionTypeModel>;
  adiRates?: AdiRates;
  parentWrapper: string;
};

const RateModal = ({
  open,
  closeModal,
  rate,
  transmissionTypes,
  adiRates,
  parentWrapper,
  refreshRates,
}: RateModalProps) => {
  const [range2GreaterThan1, setRange2GreaterThan1] = useState<boolean>(false);
  const range1Start = 1;
  const range1End = 9;
  const range2Start = 10;
  const range2End = 29;
  const range3Start = 30;

  const validationSchema = Yup.object().shape({
    transmission_type_id: Yup.number()
      .required("This field is required")
      .nullable(),
    first_range_hour_price: Yup.number()
      .min(1, "Minimum value is 1")
      .required("This field is required"),
    second_range_hour_price: Yup.number().when(
      "first_range_hour_price",
      ([first_range_hour_price]) => {
        return first_range_hour_price && first_range_hour_price !== 0
          ? Yup.number()
              .min(1, "Minimum value is 1")
              .max(
                first_range_hour_price,
                "This rate must be equal to or lower than the rates above"
              )
              .required("This field is required")
          : Yup.number()
              .min(1, "Minimum value is 1")
              .required("This field is required");
      }
    ),
    third_range_hour_price: Yup.number().when(
      "second_range_hour_price",
      ([second_range_hour_price]) => {
        return second_range_hour_price && second_range_hour_price !== 0
          ? Yup.number()
              .min(1, "Minimum value is 1")
              .max(
                second_range_hour_price,
                "This rate must be equal to or lower than the rates above"
              )
              .required("This field is required")
          : Yup.number()
              .min(1, "Minimum value is 1")
              .required("This field is required");
      }
    ),
  });

  const formik = useFormik({
    initialValues: {
      first_range_hour_price: rate?.first_range_hour_price,
      second_range_hour_price: rate?.second_range_hour_price,
      third_range_hour_price: rate?.third_range_hour_price,
      transmission_type_id:
        rate?.transmission_type_id ??
        (transmissionTypes.length === 1 ? transmissionTypes[0].id : undefined),
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    const rate1 = Number(formik.values.first_range_hour_price);
    const rate2 = Number(formik.values.second_range_hour_price);
    setRange2GreaterThan1(
      rate1 > 0 && rate2 > 0 && Number(rate1) < Number(rate2)
    );
  }, [
    formik.values.first_range_hour_price,
    formik.values.second_range_hour_price,
  ]);

  useEffect(() => {
    formik.resetForm();
  }, [open]);

  function onSubmit(values: any, { setStatus, setSubmitting }: any) {
    let request: AdiRateRequest = {
      adi_rates: [],
    };
    if (adiRates?.adiRates) {
      if (rate?.id != null || rate?.frontId != null) {
        // case edit
        const updatedRate = adiRates.adiRates.find((a) => a.id == rate.id);
        if (updatedRate) {
          updatedRate.first_range_hour_price = values.first_range_hour_price;
          updatedRate.second_range_hour_price = values.second_range_hour_price;
          updatedRate.third_range_hour_price = values.third_range_hour_price;
        }
      } else {
        // case add
        const adiRate = adiRates.adiRates.find(
          (a) => a.transmission_type_id === values.transmission_type_id
        );
        if (adiRate) {
          adiRate.first_range_hour_price = values.first_range_hour_price;
          adiRate.second_range_hour_price = values.second_range_hour_price;
          adiRate.third_range_hour_price = values.third_range_hour_price;
        } else {
          const addedRate: any = {
            first_range_hour_price: values.first_range_hour_price,
            second_range_hour_price: values.second_range_hour_price,
            third_range_hour_price: values.third_range_hour_price,
            transmission_type_id: values.transmission_type_id,
            trasmission_type_name: transmissionTypes.find(
              (x) => x.id === values.transmission_type_id
            )?.trasmission_type_name,
          };
          adiRates?.adiRates.push(addedRate);
        }
      }
      request.adi_rates = adiRates.adiRates;
      let loadingId = toast.custom(
        () => (
          <Notification
            colour="gray"
            title={"Updating your rates..."}
            description={""}
            Icon={ExclamationCircleIcon}
          />
        ),
        {
          duration: 5000,
          position: "top-center",
        }
      );

      updateAdiRates(request)
        .then(() => {
          ReactGA.event("update_rate");
          refreshRates();
          closeModal();
          toast.custom(
            () => (
              <Notification
                colour="green"
                title={"Saved successfully"}
                description={""}
                Icon={CheckCircleIcon}
              />
            ),
            {
              duration: 5000,
              position: "top-center",
              id: loadingId,
            }
          );
        })
        .catch((error) => {
          if (error?.errors?.length) {
            setStatus(error.errors[0].errorMessage);
          } else {
            setStatus("Sorry, an error has occurred");
          }
          toast.custom(
            () => (
              <Notification
                colour="red"
                title={"Failed to update your rates!"}
                description={""}
                Icon={ExclamationTriangleIcon}
              />
            ),
            {
              duration: 5000,
              position: "top-center",
              id: loadingId,
            }
          );
        })
        .finally(() => {
          setSubmitting(false);
          formik.resetForm();
        });
    }
  }

  const close = function () {
    closeModal();
    formik.resetForm();
  };

  return (
    <>
      <Modal
        title="Rates"
        submitText="Save"
        reverseButtons={parentWrapper === "questionnaire"}
        onSubmit={formik.submitForm}
        onClose={close}
        open={open}
        disabled={formik.isSubmitting}
      >
        {
          <>
            <form onSubmit={formik.handleSubmit}>
              <div className="">
                {/* ---- */}
                {formik.status ? (
                  <Alert description={formik.status} colour="red"></Alert>
                ) : null}

                <RadioInputGroup
                  name="transmission_type_id"
                  label="Transmission"
                  options={transmissionTypes.map((x) => ({
                    label: x.trasmission_type_name ?? "",
                    value: x.id,
                  }))}
                  onChange={(value) => {
                    formik.setFieldValue("transmission_type_id", value);
                  }}
                  value={formik.values.transmission_type_id}
                  disabled={formik.isSubmitting || rate?.id !== undefined}
                  error={
                    formik.touched.transmission_type_id &&
                    formik.errors.transmission_type_id
                      ? true
                      : false
                  }
                  errorMsg={formik.errors.transmission_type_id}
                />

                <Input
                  {...formik.getFieldProps("first_range_hour_price")}
                  type="number"
                  inputGroup={true}
                  inputGroupValue="£"
                  label={`${range1Start}–${range1End} hour block (per hour)`}
                  error={
                    formik.touched.first_range_hour_price &&
                    formik.errors.first_range_hour_price
                      ? true
                      : false
                  }
                  errorMsg={formik.errors.first_range_hour_price}
                  required={true}
                  name="first_range_hour_price"
                  disabled={formik.isSubmitting}
                  id="first_range_hour_price"
                  key={
                    open
                      ? "open_first_range_hour_price"
                      : "closed_first_range_hour_price"
                  }
                />

                <Input
                  {...formik.getFieldProps("second_range_hour_price")}
                  type="number"
                  inputGroup={true}
                  inputGroupValue="£"
                  label={`${range2Start}–${range2End} hour block (per hour)`}
                  error={
                    formik.touched.second_range_hour_price &&
                    formik.errors.second_range_hour_price
                      ? true
                      : false
                  }
                  errorMsg={formik.errors.second_range_hour_price}
                  required={true}
                  name="second_range_hour_price"
                  disabled={
                    formik.isSubmitting || !formik.values.first_range_hour_price
                  }
                  id="second_range_hour_price"
                  key={
                    open
                      ? "open_second_range_hour_price"
                      : "closed_second_range_hour_price"
                  }
                />

                <Input
                  {...formik.getFieldProps("third_range_hour_price")}
                  type="number"
                  inputGroup={true}
                  inputGroupValue="£"
                  label={`${range3Start}+ hour block (per hour)`}
                  error={
                    formik.touched.third_range_hour_price &&
                    formik.errors.third_range_hour_price
                      ? true
                      : false
                  }
                  errorMsg={formik.errors.third_range_hour_price}
                  required={true}
                  name="third_range_hour_price"
                  disabled={
                    formik.isSubmitting ||
                    !formik.values.first_range_hour_price ||
                    !formik.values.second_range_hour_price ||
                    range2GreaterThan1
                  }
                  id="third_range_hour_price"
                  key={
                    open
                      ? "open_third_range_hour_price"
                      : "closed_third_range_hour_price"
                  }
                />
              </div>
            </form>
          </>
        }
      </Modal>
    </>
  );
};

export { RateModal };
