import { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Alert from "../../shared/overlays/Alert";
import Button from "../../shared/elements/Button";
import { fetchPrismicData } from "../../../../../prismic";

const PrismicNotification: FC<any> = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [notification, setNotification] = useState<any>();
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const history = useHistory();
    useEffect(() => {
        fetchData();

    }, []);


    const fetchData = async () => {
        const contentType = "notification-banner"; // Replace with your content type
        const results = await fetchPrismicData(contentType);
        let enabledNotification = results.filter(function (a: any) {
            return a.data?.enable === true;
        });
        setNotification(enabledNotification[0] ?? []);
        const userHideNotification = localStorage.getItem(`hide-prismic-notification`);
        if (userHideNotification !== results[0].last_publication_date) {
            setShowAlert(true);
        }
    };

    return (
        <>
            {notification?.data?.enable ? (
                <>
                    {(showAlert && (
                        <Alert
                            customDesc={
                                <p className="text-xs sm:text-sm">
                                    <span className="w-full">
                                        <span className="w-fit inline-block">
                                            {" "}
                                            <Button
                                                onClick={() => {
                                                    localStorage.setItem('hide-prismic-notification', notification.last_publication_date)
                                                    history.push(
                                                        notification?.data?.link.url
                                                    )
                                                }}
                                                colour="link"
                                                size="fit"
                                            >
                                                <span className="text-xs sm:text-sm ms-1">
                                                    {notification?.data?.link_title[0]?.text}
                                                </span>
                                            </Button>
                                        </span>
                                    </span>
                                </p>
                            }
                            colour="gray"
                            prismicImage={notification?.data?.image}
                            prismicTitle={notification?.data?.title}
                            hasClose={true}
                            onCloseClick={() => localStorage.setItem('hide-prismic-notification', notification.last_publication_date)}
                        />
                    )
                    )}
                </>
            ) : (
                <></>
            )}
        </>
    );
};

export { PrismicNotification };
