import { Auth } from "aws-amplify"
import { signOut } from '../../app/content/pages/auth/redux/AuthCRUD';
import * as auth from '../../app/content/pages/auth/redux/AuthRedux'

export default function setupAxios(axios: any, store: any) {
  

  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    async (config: any) => {
      
      config =  await Auth.currentSession().then(session => {
        config.headers.Authorization = `Bearer ${session.getIdToken().getJwtToken()}`
        return config
      }).catch(() => config);

      const impersonatorToken = localStorage.getItem(`impersonator-token`);
      if (impersonatorToken) {
        config.headers["Impersonator-Token"] = impersonatorToken;
      }
      config.headers["Is-Adi-Network"] = "true";
      return config
    
    },
    (err: any) =>{
      Promise.reject(err)
    } 
  )
  axios.interceptors.response.use(
    (response :any) => response, 
    (error : any) => { 
      const accessDenied = "Can't access portal";
      const tokenNotProvided ="Token not provided";
      const unautherizedErrors = ["Adi doesn't exist", accessDenied,tokenNotProvided];
      
      let errorsList = error?.response?.data?.errors;
      const unauth = errorsList?.length > 0 && 
        unautherizedErrors.find(e => e == errorsList[0].errorMessage);
      if (unauth) {   
          signOut()
          store.dispatch(auth.actions.logout())  
          sessionStorage.clear()
          localStorage.clear()
          if (accessDenied == errorsList[0].errorMessage) {
            localStorage.setItem("permission-denied", "true");
          } 
          window.location.href="/"
      }
      return Promise.reject(error?.response?.data);
    }
  );
}

