import { FC, useState } from "react";

import { moveOnHoldPrivateLearner } from "../../../../core/services/job-service";
import Modal from "../../shared/overlays/Modal";
import Button from "../../shared/elements/Button";

interface IJobOnHoldDialogProps {
  show: any;
  onClose: any;
  jobId: string;
}

const JobOnHoldDialog: FC<IJobOnHoldDialogProps> = (props) => {
  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  function closeModal() {
    props.onClose();
  }

  function onSubmit() {
    setSubmitting(true);
    moveOnHoldPrivateLearner({
      job_id: props.jobId,
    }).catch((response: any) => {
      setSubmitting(false);
    });
    setSubmitting(false);
    closeModal();
  }

  return (
    <>
      <Modal
        open={props.show}
        onClose={() => closeModal()}
        onSubmit={() => onSubmit()}
        submitText="Yes"
        closeText="No"
        title="Move to on hold"
        disabled={isSubmitting}
      >
        <p className="mb-2">
          Are you sure you wish to move this learner to on hold?
        </p>
      </Modal>
    </>
  );
};

export default JobOnHoldDialog;
