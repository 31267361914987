import { FC } from "react";
import Modal from "../../shared/overlays/Modal";
import { RegisterInterestDialog } from "./RegisterInterestDialog";
import { WithdrawBidDialog } from "./WithdrawBidDialog";
import { ClashDetailsDialog } from "./ClashDetailsDialog";
import { RegisterLocationInterestDialog } from "./RegisterLocationInterestDialog";
import { RegisterAvailabilityInterestDialog } from "./RegisterAvailabilityInterestDialog";

interface IProps {
  show: any;
  onClose: any;
  onUpdate: any;
  title: string;
  isSubmitting: boolean;
  showRegisterInterest: boolean;
  showWithdrawDialog: boolean;
  showClashDialog: boolean;
  showRegisterLocationInterest: boolean;
  showRegisterAvailabilityInterest: boolean;
  acceptJobData: any;
  clashedData: any;
  acceptTheJob: () => void;
  cancelClash: () => void;
}

const NewJobModalParent: FC<IProps> = ({
  show,
  onClose,
  onUpdate,
  clashedData,
  title,
  acceptJobData,
  isSubmitting,
  showRegisterInterest,
  showWithdrawDialog,
  showClashDialog,
  showRegisterLocationInterest,
  showRegisterAvailabilityInterest,
  acceptTheJob,
  cancelClash,
}) => {
  return (
    <form noValidate>
      <Modal
        open={show}
        onClose={onClose}
        submitText="Save"
        title={title}
        hideSubmit={true}
        hideClose={true}
      >
        {showRegisterInterest && (
          <RegisterInterestDialog
            onClose={onClose}
            onUpdate={onUpdate}
            jobRate={acceptJobData?.actual_hour_rate}
            adiRateBelowHourlyRate={acceptJobData?.adi_rate_below_hourly_rate}
            jobBidReasons={acceptJobData?.job_bid_reasons?.map(
              (item: any) => item.bid_reason_id
            )}
            requestedRate={
              acceptJobData?.requested_rate
                ? parseFloat(acceptJobData?.requested_rate)
                : null
            }
            jobId={acceptJobData?.id}
            jobUpdated={onClose}
          />
        )}

        {showRegisterLocationInterest && (
          <RegisterLocationInterestDialog
            onClose={onClose}
            onUpdate={onUpdate}
            jobId={acceptJobData?.id}
            jobUpdated={onClose}
            studentLocation={{
              lat: Number(acceptJobData.student_lat),
              lng: Number(acceptJobData.student_long),
            }}
          />
        )}
        {showRegisterAvailabilityInterest && (
          <RegisterAvailabilityInterestDialog
            onClose={onClose}
            onUpdate={onUpdate}
            jobId={acceptJobData?.id}
            jobUpdated={onClose}
            studentId={acceptJobData?.student_id}
          />
        )}

        {showWithdrawDialog && (
          <WithdrawBidDialog
            onClose={onClose}
            jobUpdated={onUpdate}
            jobId={acceptJobData?.id}
          />
        )}

        {showClashDialog && (
          <ClashDetailsDialog
            show={showClashDialog}
            onClose={cancelClash}
            accept={acceptTheJob}
            data={clashedData}
            isTest={false}
            isSubmitting={isSubmitting}
          />
        )}
      </Modal>
    </form>
  );
};

export { NewJobModalParent };
