import { Skeleton } from "@mui/material";
import { Tooltip } from "@mui/material";
import { useState } from "react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

interface IProps {
  name: string;
  label?: string;
  options: Array<{
    value: any;
    label: string;
    description?: string;
    tooltip?: string;
  }>;
  onClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string | number | boolean | undefined | Array<any>;
  required: boolean;
  disabled: boolean;
  error?: boolean;
  errorMsg?: string;
  loading?: boolean;
  fullWidth?: boolean;
  optionsNoWrap?: boolean;
}

export default function RadioInput({
  name,
  label,
  value,
  options = [],
  onClick,
  required = false,
  disabled = false,
  error = false,
  errorMsg = "This field is required",
  loading = false,
  fullWidth = false,
  optionsNoWrap = false,
}: IProps) {
  const [openDesc, setOpenDesc] = useState<number | null>(null);

  return (
    <>
      <div className="flex flex-col mt-1">
        {loading ? (
          <div className="flex items-center">
            <Skeleton variant="circular" width={24} height={20} />
            <Skeleton height={30} className="w-full ms-2" />
          </div>
        ) : (
          <>
            <label className="block text-sm font-medium leading-6 text-darkBlue mb-2">
              {label}
              {required && <span className="text-sm text-red-500">*</span>}
            </label>
            <div
              className={
                (options?.length > 3 && !fullWidth) || optionsNoWrap
                  ? "grid grid-cols-2 gap-x-3"
                  : ""
              }
            >
              {options?.map((option, index) => {
                return (
                  <div key={index} className="mb-3">
                    <label
                      htmlFor={name + index}
                      className="flex flex-col items-start text-sm font-medium text-darkBlue"
                    >
                      <span
                        className={`${
                          option?.description ? "font-semibold" : ""
                        } flex items-start`}
                      >
                        <input
                          className="checked:accent-[#163a5e] me-1 w-4 h-4 min-w-[16px] mt-[2px]"
                          type="radio"
                          name={name}
                          id={name + index}
                          value={option.value}
                          checked={value === option.value}
                          onChange={() => {}}
                          onClick={() => onClick(option.value)}
                          data-parsley-multiple={name}
                          disabled={disabled}
                          required={required}
                        />
                        {option.label}
                        {option?.tooltip && (
                          <Tooltip
                            key={index}
                            onMouseOver={() => {
                              setOpenDesc(index);
                            }}
                            onMouseOut={() => {
                              setOpenDesc(null);
                            }}
                            title={option?.tooltip}
                            open={openDesc == index}
                            arrow
                            placement="top"
                            componentsProps={{
                              tooltip: {
                                style: {
                                  fontSize: "10px",
                                  lineHeight: "18px",
                                  fontFamily: "Poppins, sans-serif",
                                  maxWidth: "250px",
                                  marginRight: "6px",
                                },
                              },
                              popper: {
                                style: {
                                  zIndex: 9999,
                                },
                              },
                            }}
                          >
                            <InformationCircleIcon className="w-5 h-5 mx-2 flex-shrink-0 text-darkBlue" />
                          </Tooltip>
                        )}
                      </span>
                      {option?.description && (
                        <span className="ml-[20px] block">
                          {option?.description}
                        </span>
                      )}
                    </label>
                  </div>
                );
              })}
            </div>
            {error && (
              <p className="text-sm text-red-600" id="error">
                {errorMsg}
              </p>
            )}
          </>
        )}
      </div>
    </>
  );
}
