import { setHeader } from "../tokenSetup";
import { signOut } from "../../app/content/pages/auth/redux/AuthCRUD";
import * as auth from "../../app/content/pages/auth/redux/AuthRedux";
import store from "../redux/Store";
export default async function modifiedFetch({
  url,
  method,
  xApiKey,
  data,
}: {
  url: string;
  method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
  xApiKey?: string;
  data?: any;
}) {
  const headers = await setHeader(xApiKey);
  return fetch(url, {
    method: method,
    headers: headers,
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw response;
    })
    .catch((error: any) => {
      let jsonError = error.clone().json();
      return jsonError.then((error: any) => {
        const accessDenied = "Can't access portal";
        const tokenNotProvided = "Token not provided";
        const unautherizedErrors = [
          "Adi doesn't exist",
          accessDenied,
          tokenNotProvided,
        ];
        let errorsList = error?.errors;
        const unauth =
          errorsList?.length > 0 &&
          unautherizedErrors.find((e) => e == errorsList[0].errorMessage);
        if (unauth) {
          window.location.href = "/";
          signOut();
          store.dispatch(auth.actions.logout());
          sessionStorage.clear();
          if (accessDenied == errorsList[0].errorMessage) {
            localStorage.setItem("permission-denied", "true");
          }
        }
        throw error;
      });
    });
}
