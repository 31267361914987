import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { DayModel } from "../../../../core/models/day-model";
import { getStudentAvailability, getStudentWeekDays } from "../../../../core/services/student-service";
import * as Yup from "yup";
import Button from "../../shared/elements/Button";
import { addAvailabilityBid } from "../../../../core/services/job-service";
import { PlusSmallIcon, TrashIcon } from "@heroicons/react/24/outline";
import Dropdown from "../../shared/forms/Dropdown";
import CheckboxInput from "../../shared/forms/CheckboxInput";
import { AvailabilityModel } from "../../../../core/models/availability-modal";
import { getTimes } from "./../../../../core/services/date-service";

interface IProps {
    jobId: string | undefined;
    onClose: any;
    onUpdate: any;
    studentId: string;
    jobUpdated: () => void;
}
const RegisterAvailabilityInterestDialog: FC<IProps> = (props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [weekDays, setWeekDays] = useState<Array<DayModel>>([]);
    const [overLap, setOverLap] = useState<Array<boolean>>([]);
    const [availability, setAvailability] = useState<Array<any>>([]);
    const [studentAvailability, setStudentAvailability] = useState<Array<any>>([]);
    const [submitted, setSubmitted] = useState(false);

    async function fetchWeekDays() {
        const weekDaysPromise = await getStudentWeekDays()
        setWeekDays(weekDaysPromise.results)
    }

    async function fetchStudentAvailability() {
        const studentAvailability = await getStudentAvailability(props.studentId);
        let mappedAvailability = studentAvailability?.results.map(
            (item: any) => {
                return {
                    ...item,
                    from_time: item.from_time.slice(0, 5),
                    to_time: item.to_time.slice(0, 5),
                };
            }
        );
        setStudentAvailability(mappedAvailability);
    }

    useEffect(() => {
        setLoading(true);
        fetchWeekDays();
        fetchStudentAvailability()
        setLoading(false);

    }, []);

    const validationSchema = Yup.object().shape({
        availability: Yup.array().min(1, "Availability is required")
    });
    const formik = useFormik({
        initialValues: {
            availability: availability,
        },
        enableReinitialize: true,
        validationSchema,
        onSubmit,
    });

    async function onSubmit() {

        let hasAvailabilityError = checkAvailabilityError();
        let hasOverLap = checkOverLap();
        if (hasAvailabilityError || hasOverLap) {
            return;

        } else {
            await addAvailabilityBid({ "availability": formik.values.availability, "jobId": props.jobId });
            props.onUpdate();
        }
    }

    const checkAvailabilityError = (): boolean => {
        if (formik.values.availability.length == 0) {
            return true;
        }
        if (
            formik.values.availability.findIndex(
                (a: any) => a.from_time == "" || a.to_time == "" || a.day_id == null
            ) > -1
        ) {
            return true;
        }

        let x = formik.values.availability.filter((slot: any) => {
            if (slot.to_time && slot.from_time && slot.to_time <= slot.from_time) {
                return slot;
            }
        });
        if (x.length > 0) {
            return true;
        }

        return false;
    };
    const onFromTimeChanged = (e: any, slot: AvailabilityModel) => {
        if (formik.isSubmitting) {
            return;
        }
        if (e.value != null) {
            let availabilityData = formik.values.availability;
            let updatedSlotIndex = availabilityData.indexOf(slot);
            availabilityData[updatedSlotIndex].from_time = e.value;
            formik.setFieldValue("availability", availabilityData);
        }
    };

    function onDayChanged(
        e: React.ChangeEvent<HTMLInputElement>,
        dayId: number
    ): void {
        let list = formik.values.availability;
        if (!e.target.checked) {
            let dataWithoutRemoved = list.filter(function (a: AvailabilityModel) {
                return a.day_id != dayId;
            });
            formik.setFieldValue("availability", dataWithoutRemoved);
        } else {
            addAvailabilitySlot(dayId);
        }
    }
    const removeAvailabilitySlot = (slot: AvailabilityModel) => {
        if (formik.isSubmitting) {
            return;
        }
        const temp = formik.values.availability;
        let deletedIndex = temp.indexOf(slot);

        temp.splice(deletedIndex, 1);
        formik.setFieldValue("availability", temp);
        checkOverLap();
    };

    const addAvailabilitySlot = (dayId: number) => {
        let tempSlot = formik.values.availability;
        let newSlot = new AvailabilityModel();
        newSlot.day_id = dayId;
        tempSlot.push(newSlot);
        formik.setFieldValue("availability", tempSlot);
    };

    const overlapping = (a: any, b: any) => {
        const getMinutes = (s: any) => {
            const p = s.split(":").map(Number);
            return p[0] * 60 + p[1];
        };
        return (
            getMinutes(a.to_time) > getMinutes(b.from_time) &&
            getMinutes(b.to_time) > getMinutes(a.from_time)
        );
    };
    const onToTimeChanged = (e: any, slot: AvailabilityModel) => {
        if (formik.isSubmitting) {
            return;
        }
        if (e.value != null) {
            let availabilityData = formik.values.availability;
            let updatedSlotIndex = availabilityData.indexOf(slot);
            availabilityData[updatedSlotIndex].to_time = e.value;
            formik.setFieldValue("availability", availabilityData);
        }
    };
    const isOverlapping = (arr: any) => {
        let i, j;
        for (i = 0; i < arr.length - 1; i++) {
            for (j = i + 1; j < arr.length; j++) {
                if (overlapping(arr[i], arr[j])) {
                    return true;
                }
            }
        }
        return false;
    };

    const checkOverLap = () => {
        setOverLap([]);
        let overLapExists: Array<boolean> = [];
        const x = studentAvailability.concat(formik.values.availability)
        weekDays.forEach((day) => {
            let daySlots = x.filter((slot: any) => {
                if (slot.day_id == day.id) {
                    return slot;
                }
            });
            overLapExists.push(isOverlapping(daySlots));
        });
        setOverLap(overLapExists);

        if (overLapExists.indexOf(true) > -1) {
            return true;
        }
    };

    function closeModal() {
        setLoading(false);
        props.onClose();
    }

    return (
        <>
            We’ll send this request to the learner and update you when they respond.<br /><br />

            <b>Note:</b> Submitting this request does not guarantee you’ll be allocated the course.<br /><br />

            If the learner accepts the change, we ask that you accept the course<b> within 72 hours</b>. <br /><br />

            Please enter the dates/times you’d like to change:<br />

            <form
                noValidate
                onSubmit={formik.submitForm}
            >
                {weekDays?.map(function (day, key) {
                    return (
                        <div key={key}>
                            <div className="grid grid-cols-2  items-center">
                                <CheckboxInput
                                    loading={loading}
                                    name={day.day_name ?? ""}
                                    required={false}
                                    label={day.day_name ?? ""}
                                    isSingleCheckbox={true}
                                    checked={formik.values.availability.findIndex((b) => b.day_id == day.id) > -1}
                                    onChange={(e: any) => onDayChanged(e, day.id)}
                                    disabled={formik.isSubmitting}

                                // value={day.id}
                                />

                                {studentAvailability?.filter(function (a) {
                                    return a.day_id == day.id;
                                }).length > 0 ? (
                                    <div key={key + "btnCopy"} className="w-full">
                                        {
                                            <div className="flex flex-row w-full">
                                                <span className="w-full">
                                                    {studentAvailability.filter(function (a) {
                                                        return a.day_id == day.id && a.id != ''
                                                    }).map((val: any, j: number) => {
                                                        return (
                                                            <div key={j} className="bg-gray-200 rounded-3xl p-2 m-2 flex items-center justify-center">
                                                                <div >
                                                                    {val.from_time.slice(0, 5)} - {val.to_time.slice(0, 5)}
                                                                </div>
                                                            </div>

                                                        )
                                                    })}
                                                </span>
                                                <span className={(formik.values.availability.findIndex((b) => b.day_id == day.id) > -1) ? '' : 'hidden'}>
                                                    <Button
                                                        type="button"
                                                        onClick={() => addAvailabilitySlot(day.id)}
                                                        size="fit"
                                                        fitWidth={true}
                                                        colour="transparent"
                                                        Icon={PlusSmallIcon}
                                                    ></Button>
                                                </span>
                                            </div>
                                        }
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>

                            {formik.values.availability.filter(function (a) {
                                return a.day_id == day.id;
                            }).length > 0 ? (
                                <div className="mt-3 mb-2">
                                    {formik.values.availability
                                        .filter(function (a) {
                                            return a.day_id === day.id;
                                        })
                                        .map(function (slot, i) {
                                            return (
                                                <div key={key + i + "slot"} className={slot.id != '' ? 'hidden' : ''}>
                                                    <div className="flex items-start justify-between mb-4">
                                                        {<div>
                                                            <div className="grid grid-cols-11 space-x-2 items-start">
                                                                <div className="-my-5 col-span-5">
                                                                    <Dropdown
                                                                        options={getTimes(6, 22.5, 30)}
                                                                        onChange={(newValue) =>
                                                                            onFromTimeChanged(newValue, slot)
                                                                        }
                                                                        value={
                                                                            getTimes(6, 22.5, 30).filter(
                                                                                (time) => time.value === slot.from_time
                                                                            )[0]
                                                                        }
                                                                        error={!slot.from_time && submitted}
                                                                        errorMsg="From time is required"
                                                                        placeholder="From"
                                                                        disabled={slot.id != ''}
                                                                    />
                                                                </div>
                                                                <div className="-my-5 col-span-5">
                                                                    <Dropdown
                                                                        options={getTimes(6, 22.5, 30)}
                                                                        onChange={(newValue) =>
                                                                            onToTimeChanged(newValue, slot)
                                                                        }
                                                                        value={
                                                                            getTimes(6, 22.5, 30).filter(
                                                                                (time) => time.value === slot.to_time
                                                                            )[0]
                                                                        }
                                                                        error={!slot.to_time && submitted}
                                                                        errorMsg="Until time is required"
                                                                        placeholder="To"
                                                                        disabled={slot.id != ''}
                                                                    />
                                                                </div>

                                                                <div className="mt-1">
                                                                    <Button
                                                                        type="button"
                                                                        size="fit"
                                                                        fitWidth={true}
                                                                        colour="transparent"
                                                                        Icon={TrashIcon}
                                                                        onClick={() => removeAvailabilitySlot(slot)}
                                                                        disabled={slot.id != ''}
                                                                    ></Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        }
                                                    </div>

                                                    {slot.to_time &&
                                                        slot.from_time &&
                                                        slot.to_time <= slot.from_time && (
                                                            <p
                                                                className="text-sm text-red-600 -mt-2 mb-3"
                                                                id="error"
                                                            >
                                                                From time must be less than until time
                                                            </p>
                                                        )}
                                                </div>
                                            );
                                        })}
                                    {overLap[key] == true && (
                                        <p className="text-sm text-red-600 -mt-1 mb-4" id="error">
                                            Overlap exists
                                        </p>
                                    )}
                                </div>
                            ) : (
                                <></>
                            )}


                        </div>
                    );
                })}

                <div className="sticky w-full bottom-0 left-0 px-0 py-4 bg-white z-[11]">
                    <div className='w-full flex items-center justify-between space-x-2'>
                        <Button
                            colour="yellow"
                            type="button"
                            className="w-1/2"
                            size="large"
                            disabled={formik.isSubmitting || loading || formik.status}
                            onClick={() => onSubmit()}
                        >
                            Confirm
                        </Button>
                        <Button
                            colour="outline"
                            type="button"
                            className="w-1/2"
                            size="large"
                            onClick={() => closeModal()}
                            disabled={formik.isSubmitting || loading || formik.status}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            </form>
        </>
    );
};

export { RegisterAvailabilityInterestDialog };
