/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react";
import { ActivityList } from "../../components/payments/ActivityList";
import { FinanceSummaryHeader } from "../../components/payments/FinanceSummaryHeader";
import { adiTransactions } from "../../../core/services/payment-service";
import { FilterObject } from '../../../core/models/filter-object'
import { PaymentFilterModal } from "../../components/payments/_modals/PaymentFilterModal";
import PageHeader from "../../components/shared/elements/PageHeader";
import { TransactionTypes } from "../../../core/models/enums/transaction-type-enum";
import { PaymentStatusFilter } from "../../../core/models/enums/payment-status-filter-enum";
import { PaymentMethods } from "../../../core/models/enums/payment-methods-enum";
import moment from "moment";

const PaymentsWrapper: FC = () => {

  const [transactions, setTransactions] = useState<Array<any>>([])
  const [moneySummery, setSummery] = useState<Array<any>>([])
  const [openFilterModal, setOpenFilterModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const paymentMethodsEnum: Array<any> = Object.values(PaymentMethods).filter((v) => !isNaN(Number(v)));
  const paymentStatusEnum: Array<any> = Object.values(PaymentStatusFilter).filter((v) => !isNaN(Number(v)));
  const transactionTypesEnum: Array<any> = Object.values(TransactionTypes).filter((v) => !isNaN(Number(v)));

  moment.updateLocale('en', {
    week: {
      dow: 1
    }
  });

  const [filterObject, setFilterObject] = useState<FilterObject>({
    SearchObject: {
      transaction_type_id: [0].concat(transactionTypesEnum),
      from_date: moment().startOf('week').format('YYYY-MM-DD'),
      to_date: moment().endOf('week').format('YYYY-MM-DD'),
      status: [0].concat(paymentStatusEnum),
      payment_method_id: [0].concat(paymentMethodsEnum),
      custom_date: 2,
      filterName: 'This week'
    },
    PageNumber: 1,
    PageSize: 10000000,
    SortDirection: '',
    SortBy: '',
  })

  useEffect(() => {
    getAdiTransactions()
  }, [filterObject])


  const handleSearch = (filterObj: any) => {
    setFilterObject({
      ...filterObject,
      SearchObject: filterObj
    })
  }
  const getAdiTransactions = () => {
    (async () => {
      setLoading(true)
      var res = await adiTransactions(filterObject).catch()
      setLoading(false)
      setTransactions(res.data.results.finance)
      setSummery(res.data.results.totalAmount)
    })().catch(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    document.title = `ADI Network App | Finance | ADI Network`;
  }, []);
  
  return (
    <>
      <div className='px-5'>
        <PaymentFilterModal filterObject={filterObject} open={openFilterModal} applyFilter={handleSearch} onHide={() => { setOpenFilterModal(false) }}></PaymentFilterModal>
        <PageHeader title="Finance" buttons={[
          {
            text: (<span>{filterObject.SearchObject.filterName}</span>),
            onClick: () => { setOpenFilterModal(true); },
            Icon: 'filter'
          }
        ]} />
      </div>
      <FinanceSummaryHeader moneyAmount={moneySummery} refreshList={getAdiTransactions} loading={loading} />
      <ActivityList transactionList={transactions} loading={loading} refreshList={getAdiTransactions} title="Activity" hidePageHeader={false}/>

    </>
  );
};

export { PaymentsWrapper };
