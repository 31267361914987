import { FC, useEffect, useState } from "react";
import { VideoModal } from "./VideoModal";
import { PlusIcon } from "@heroicons/react/24/outline";
import { filter } from "@prismicio/client";
import { createClient, fetchPrismicData } from "../../../../../prismic";
import Button from "../../../components/shared/elements/Button";
import CPDFilter from "../_shared/CPDFilter";
import VideoTile from "../_shared/VideoTile";
import CPDBreakCard from "../_shared/CPDBreakCard";
import { useHistory } from "react-router-dom";
import { saveAdiClickedPage } from "../../../../core/services/adi-service";
import { useQuery } from "../../../helpers/QueryParamsHelper";
import { scrollInstantToTagBySelector } from "../../../helpers/ScrollHelper";

const VideosList: FC = () => {
  const history = useHistory();
  const query = useQuery();

  const [selectedVideo, setSelectedVideo] = useState<any>();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const openVideoModal = (video?: any) => {
    setSelectedVideo(video);
    query.append("video", video?.uid);
    history.replace({
      search: query.toString(),
    });
    setShow(true);
  };

  const [videos, setVideos] = useState<Array<any>>([]);
  const [mostPopularVideos, setMostPopularVideos] = useState<Array<any>>([]);
  const [totalResults, setTotalResults] = useState<number>(0);
  const [resultsSize, setResultsSize] = useState<number>(0);

  const [pageCounter, setPageCounter] = useState(20);
  const [orderingBy, setOrderingBy] = useState(
    "my.cpd_article.last_publication_date"
  );
  const [orderDir, setOrderDir] = useState<any>("asc");
  const [selectedCategoryID, setSelectedCategoryID] = useState<Array<any>>([]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (videos.length > 0)
      scrollInstantToTagBySelector(`[id='${query.get("uid")}']`);
  }, [videos]);

  const fetchData = async () => {
    const contentType = "category"; // Replace with your content type
    const results = await fetchPrismicData(contentType);
    const idValues = results.map((item: any) => item.id); // Adjust this according to your API response structure
    const categoryUid = query.get("category");
    setSelectedCategoryID(
      categoryUid ? [results?.find((e) => e.uid == categoryUid)?.id] : idValues
    );
  };

  const loadMore = async () => {
    if (resultsSize < totalResults) {
      setPageCounter(pageCounter + 20);
    } else {
      setPageCounter(totalResults);
    }
    // setIsLoading(true);
    // setIsLoading(false);
  };

  // Initial load of videos
  useEffect(() => {
    //console.log("*** Initial load of videos ***");
    const fetchVideos = async () => {
      try {
        const response = await createClient.get({
          fetchLinks: "category.name",
          filters: [
            filter.at("document.type", "videos"),
            filter.any("my.videos.categories_link", selectedCategoryID),
          ],
          orderings: {
            field: orderingBy,
            direction: orderDir,
          },
          pageSize: pageCounter,
        });

        //console.log(response.results); // Log the response object
        const mappedVideos = response?.results?.map((result) => ({
          id: result.id,
          // Map additional properties here
          thumbImage: result.data.thumb_img,
          categoryName: result.data.categories_link.data.name,
          videoTitle: result.data.title,
          videoShortDescription:
            result.data.blurb.length > 0
              ? result.data.blurb
                .map((paragraph: any) => paragraph.text)
                .join(" ")
              : "",
          authorName: result.data.author,
          author: result.data.author_data,
          releaseDate: result.data.date,
          videoURL: result.data.vidurl.url,
          duration: result.data.duration,
          uid: result.uid,
          mostPopular: result.data.set_in_most_popular,
        }));
        const popularVideos = mappedVideos.filter((video) => {
          if (video.mostPopular === true) {
            return video;
          }
        });
        const notPopularVideos = mappedVideos.filter((video) => {
          if (!video.mostPopular || video.mostPopular === false) {
            return video;
          }
        });
        //console.log(mappedArticles); // Log the response object
        setVideos(notPopularVideos);
        setMostPopularVideos(popularVideos);
        setTotalResults(response.total_results_size);
        setResultsSize(response.results_size);
      } catch (error) {
        console.error("Error while fetching videos:", error);
      }
    };

    fetchVideos();
  }, [pageCounter, selectedCategoryID, orderingBy, createClient]);

  // start:: Initial sort
  const handleSortChange = (newOrder: any) => {
    if (newOrder) {
      const values = getSort(newOrder.target.value);
      getSort(newOrder.target.value);
      setOrderDir(values.dir);
      setOrderingBy(values.orderField);
    }
  };
  const getSort = (value: string): { orderField: string; dir: string } => {
    switch (value) {
      case "title":
        return { orderField: "my.videos.title", dir: "asc" };
      case "author":
        return { orderField: "my.videos.author", dir: "asc" };
      case "newest":
        return { orderField: "my.videos.first_publication_date", dir: "asc" };
      case "oldest":
        return { orderField: "my.videos.first_publication_date", dir: "desc" };
      default:
        return { orderField: "my.videos.date", dir: "asc" };
    }
  };

  // Handle filter by category
  const handleCategoryChange = (selectedCategory: any) => {
    if (selectedCategory) {
      //console.log(selectedCategory)
      setSelectedCategoryID([selectedCategory.target.value]);
    }
  };

  const adiClickedPage = async (e: any) => {
    await saveAdiClickedPage(e).catch(() => { });
  };

  return (
    <>
      {/* start:: Category & Sort filter */}
      <CPDFilter
        setSort={(sortBy: any) => handleSortChange(sortBy)}
        setCategory={(selectedCategory: any) =>
          handleCategoryChange(selectedCategory)
        }
        handleClearCategoryClick={() => {
          query.delete("category");
          history.replace({
            search: query.toString(),
          });
          fetchData();
        }}
        handleClearSortClick={() => handleSortChange(null)}
      />
      {/* end:: Category & Sort filter */}

      <VideoModal
        title={selectedVideo?.videoTitle}
        show={show}
        onHide={handleClose}
        url={selectedVideo?.videoURL}
      ></VideoModal>

      {mostPopularVideos.length > 0 && (
        <div className="bg-white rounded-[20px] px-5 pt-2 pb-9 mt-5 mb-10 ">
          <p className="py-4 text-2xl">Most popular</p>
          <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 xl:grid-cols-3 lg:gap-x-8">
            {mostPopularVideos?.map((item, index) => (
              <VideoTile
                key={index}
                imgURL={item.thumbImage}
                vidDuration={item.duration}
                author={item.authorName}
                authorData={item.author}
                title={item.videoTitle}
                uid={item.uid}
                category={item.categoryName}
                pageCounter={pageCounter}
                onShowVideoClick={() => {
                  adiClickedPage({
                    page_url: window.location.pathname + "/video",
                    page_name: item.videoTitle,
                  });
                  openVideoModal(item);
                }}
              />
            ))}
          </div>
        </div>
      )}
      <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 xl:grid-cols-3 lg:gap-x-8">
        {/* {state === "loading" ? (
          <p>Loading…</p>
        ) : ( */}
        <>
          {videos?.slice(0, 3).map((item, index) => (
            <VideoTile
              key={index}
              imgURL={item.thumbImage}
              vidDuration={item.duration}
              author={item.authorName}
              title={item.videoTitle}
              shortDesc={item.videoShortDescription}
              category={item.categoryName}
              authorData={item.author}
              uid={item.uid}
              pageCounter={pageCounter}
              onShowVideoClick={() => {
                adiClickedPage({
                  page_url: window.location.pathname + "/video",
                  page_name: item.videoTitle,
                });
                openVideoModal(item);
              }}
            />
          ))}
          {/*  */}
        </>
        {/* )} */}
      </div>

      {/* start:: Break Card 1 */}
      <CPDBreakCard
        imgURL={"/assets/media/CPD/getting-paid.svg"}
        title={
          <>
            Getting paid has never been{" "}
            <span className="bg-free-circle bg-bottom bg-no-repeat bg-contain">
              easier
            </span>
          </>
        }
        desc="Take digital payments by credit card, debit card, Apple Pay & Google Pay"
        button={{
          text: "Find out more",
          onClick: () => history.push(`/ADI/digital-payments`),
          color: "darkBlue",
          disabled: false,
        }}
      />
      {/* end:: Break Card 1 */}

      <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 xl:grid-cols-3 lg:gap-x-8">
        {/* {state === "loading" ? (
          <p>Loading…</p>
        ) : ( */}
        <>
          {videos?.slice(3, pageCounter).map((item, index) => (
            <VideoTile
              key={index}
              imgURL={item.thumbImage}
              vidDuration={item.duration}
              author={item.authorName}
              title={item.videoTitle}
              authorData={item.author}
              shortDesc={item.videoShortDescription}
              uid={item.uid}
              pageCounter={pageCounter}
              onShowVideoClick={() => {
                adiClickedPage({
                  page_url: window.location.pathname + "/video",
                  page_name: item.videoTitle,
                });
                openVideoModal(item);
              }}
            />
          ))}
          {/*  */}
        </>
        {/* )} */}
      </div>

      <div className="grid grid-cols-6 gap-4 mx-auto w-full mt-6">
        {resultsSize < totalResults && (
          //Load more button
          <Button
            className="col-span-4 col-start-2"
            colour="outline"
            onClick={loadMore}
            size="large"
          >
            <div className="flex items-center text-lg font-semibold">
              LOAD MORE <PlusIcon className="w-5 h-5 ms-2" />
            </div>
          </Button>
        )}

        <p className="col-span-6 text-[#616161] text-sm text-center mb-5">
          Showing {resultsSize} of {totalResults}
        </p>
      </div>
      {/* start:: Break Card 2 */}
      <CPDBreakCard
        imgURL={"/assets/media/CPD/course-around.svg"}
        title={<>Find available courses local to you</>}
        desc=" Lessons paid upfront & tests booked in advance"
        button={{
          text: "Get work for free",
          onClick: () => history.push(`/ADI/new-jobs`),
          color: "yellow",
          disabled: false,
        }}
      />
      {/* start:: Break Card 2 */}
    </>
  );
};

export default VideosList;
