import { FC, useEffect, useState } from 'react'
import { formatDisplayedDate, formatDisplayedTimeWithDuration } from '../../../../core/services/date-service'
import { getLessonsRecurringOptions, removeLesson } from '../../../../core/services/job-service'
import ReactGA from "react-ga4";
import Modal from '../../shared/overlays/Modal'
import InfoList from '../../shared/data/InfoList'
import Alert from '../../shared/overlays/Alert'
import Button from '../../shared/elements/Button';

interface IProps {
  show: any
  onHide: any
  onSubmit: any
  lessonId: any
  date: any
  duration: any
  dateStr: any
  isPrivateJob: any
  recurringOption?: number;
  canUpdateSeries?: boolean;
}

const RemoveLessonDialog: FC<IProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [isSubmited, setIsSubmited] = useState<boolean>(false)
  const [submiting, setSubmiting] = useState<boolean>(false)
  const [msgCondition, setMsgCondition] = useState<any>()
  const [recurringOptions, setRecurringOptions] = useState<any>()

  const onHide = () => {
    if (!submiting) {
      if (isSubmited) {
        props.onSubmit()
      } else {
        props.onHide()
      }
    }
  }
  const onShow = () => {
    setLoading(false)
    setError(null)
    setIsSubmited(false)
    setSubmiting(false)
    deleteLesson(true, false)
  }
  const deleteLesson = (checkOnly: any, deleteEntireSeries: boolean) => {
    setSubmiting(true)
    setLoading(true)
    removeLesson({ lessonId: props.lessonId, check_only: checkOnly, delete_entire_series: deleteEntireSeries})
      .then((res) => {
        ReactGA.event('remove_lesson')
        if (checkOnly) {
          setSubmiting(false)
          setIsSubmited(false)
          setLoading(false)
          setMsgCondition(res.data.results)
        } else {
          props.onSubmit()
        }
      })
      .catch((error) => {
        setSubmiting(false)
        if (error && error.errors && error.errors.length > 0) {
          setSubmiting(false)
          setError(error.errors[0].errorMessage)
        } else {
          setSubmiting(false)
          setError('Sorry, an error has occured')
        }
      })
  }
 useEffect(()=>{
  if(props.show)
    {
      onShow();
      getLessonsRecurringOptions().then((data)=>{
          setRecurringOptions(data?.data?.results);
      })
    }
 
 },[props.show])

  return (
    <Modal
      open={props.show}
      onClose={() => onHide()}
      onSubmit={() => deleteLesson(false, false)}
      submitText='Remove'
      closeText='Cancel'
      title='Remove lesson'
      description={msgCondition?.isRemoved != 1 ? (props?.canUpdateSeries ? 'Are you sure you want to remove this repeating lesson? This will remove payments' : 'Are you sure you want to remove this lesson?') : ''}      disabled={false}
      disableSubmit={submiting || loading}
      hideSubmit={props?.canUpdateSeries ? true : false}
      hideClose={props?.canUpdateSeries ? true : false}
    >
      {error ? (
        <p className="text-sm text-red-600 -mt-1" id="error">{error}</p>
      ) : (
        <>
          {loading == true && <h2>Loading...</h2>}
          {loading != true && !isSubmited && (
            <>
              <InfoList
                data={[
                  {
                    icon: "pmf-icon-calendar",
                    value: formatDisplayedDate(props.date) ?? '-',
                  },
                  {
                    icon: "pmf-icon-clock",
                    value: formatDisplayedTimeWithDuration(new Date(props.dateStr), props.duration * 60) ?? "-",
                  },
                  {
                    icon: "pmf-icon-reschedule",
                    value:  recurringOptions?.find((key:any) => key.id === props?.recurringOption)?.name,
                  },
                ]}
              />
            </>
          )}
          { }
        </>
      )}

      {props?.canUpdateSeries ?
        <div className="sticky w-full bottom-0 left-0 px-0 py-4 bg-white z-[11]">
          <div className='w-full'>
            <div className='w-full flex items-center justify-between space-x-2'>
              <Button
                colour="yellow"
                type="submit"
                className="w-1/2"
                onClick={()=> deleteLesson(false, false)}
                disabled={loading}
              >
                This lesson only
              </Button>
              <Button
                colour="outline"
                type="button"
                className="w-1/2"
                onClick={()=>{
                  deleteLesson(false, true)
                }}
                disabled={loading}
              >
                {props.isPrivateJob ? 'The entire series' : 'This & All Upcoming'}
              </Button>
            </div>
            <Button
              onClick={onHide}
              disabled={loading}
              colour="link"
              size="fit"
              fullWidth
            >
              Cancel
            </Button>
          </div>
        </div>
        : <></>}
    </Modal>
  )
}

export { RemoveLessonDialog }