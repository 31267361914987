import { FC, useEffect, useState } from "react";
import {
  getNotificationSettings,
  updateNotificationSettings,
} from "../../../../core/services/adi-service";
import Toggler from "../../shared/forms/Toggler";
import PageHeader from "../../shared/elements/PageHeader";
import { NotificationModel } from "../../../../core/models/notification-model";

const AppNotification: FC = () => {
  const [loading, setLoading] = useState(false);
  const [notificationValues, setNotificationValues] = useState<Array<NotificationModel>>([]);

  useEffect(() => {
    getNotificationValue();
  }, []);

  const getNotificationValue = async () => {
    setLoading(true);

    const notificationSettings = await getNotificationSettings()
      .catch(() => { setLoading(false) });

    if (notificationSettings?.results)
      setNotificationValues(notificationSettings.results);

    setLoading(false);
  };

  const updateNotificationValue = async (id: string, value: number) => {
    await updateNotificationSettings(id, {
      is_enabled: value
    })
  };

  const updateSwitcher = (index: number) => {
    updateNotificationValue(notificationValues[index]?.user_notification_action_settings_id!, !notificationValues[index]?.is_enabled ? 1 : 0)

    setNotificationValues([...notificationValues.slice(0, index), {
      user_notification_action_settings_id: notificationValues[index]?.user_notification_action_settings_id,
      is_enabled: !notificationValues[index]?.is_enabled ? 1 : 0,
      action_name_human: notificationValues[index]?.action_name_human,
    }, ...notificationValues.slice(index + 1,)]);
  };

  return (
    <>
      <div className="mb-5">
        <PageHeader title="Notification" backToURL="/ADI/settings/overview" />
      </div>
      <div className="bg-white py-4 px-5">
        <div className="grid grid-cols-1 gap-4">
          {notificationValues?.map((notificationValue, index: number) =>
            <div>
              {
                <Toggler
                  label={notificationValue.action_name_human}
                  checked={!!notificationValue.is_enabled}
                  onChange={() => updateSwitcher(index)}
                  loading={loading}
                  size="full"
                />
              }
            </div>)}
        </div>
      </div>
    </>
  );
};

export { AppNotification };
