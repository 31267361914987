import * as React from 'react';
import { createContext, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getArticlesUnReadCount } from '../../../core/services/adi-service';

export type SharedDataContextType = {
    data : any ,
    updateSharedData: (key: any, value: any) => void;
    getUnread: () => void;
    setUnreadCountFunction: any;
    unReadCount: any
}


export const defaultData: { selectedDate : Date } = {
selectedDate : new Date()
};
interface Props {
    children: React.ReactNode;
  }

export const SharedDataContext = createContext<SharedDataContextType | null>(null);

export const SharedDataProvider:  React.FC<Props>  = ({ children }) => { 
    const [sharedDataState, updatesharedDataState] = useState<any>(defaultData);
    const [unReadCount, setUnreadCount] = useState<any>(0);
    const dispatch = useDispatch()
    const updateSharedData = (key: any, value: any = undefined) => {
        if (value == undefined) {
            updatesharedDataState((prev: any) => {
                return {
                    ...prev,
                    ...key,
                };
            });
            return false;
        }
        updatesharedDataState((prevState: any) => {
            return {
                ...prevState,
                [key]: value,
            };
        });
    };
    const getUnread = async () => {
        const res = await getArticlesUnReadCount()
        setUnreadCount(res?.results?.un_read_count ?? 0)
        }
       const setUnreadCountFunction = ()=>{
        setUnreadCount(0)
        }
    return <SharedDataContext.Provider value={{updateSharedData , data: sharedDataState, getUnread , unReadCount , setUnreadCountFunction}}>{children}</SharedDataContext.Provider>;
};

export const useSharedData = () => useContext(SharedDataContext) as SharedDataContextType;
