import { Tab } from "@headlessui/react";
import { FC, useState, useEffect } from "react";
import { GeneralTab } from "./tabs/GeneralTab";
import { LessonsTab } from "./tabs/LessonsTab";
import { PaymentsTab } from "./tabs/PaymentsTab";
import { ProgressTab } from "./tabs/ProgressTab";
import { useHistory, useParams } from "react-router-dom";
import { AdiJob } from "../../../../core/models/adi-job";
import { Lesson } from "../../../../core/models/lesson";
import { Assessment } from "../../../../core/models/assessments/assessment";
import { PersonalDataModel } from "../../../../core/models/personal-data-model";
import { getPersonalData } from "../../../../core/services/adi-service";
import {
  currentJobDetails,
  getJobLessonsData,
} from "../../../../core/services/job-service";
import ReactGA from "react-ga4";
import { getDealAssessments } from "../../../../core/services/assessment-service";
import { formatFromDate } from "../../../helpers/DateHelper";
import Skeleton from "@mui/material/Skeleton";

export const JobDetailsComponent: FC = () => {
  const { jobId }: any = useParams();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [jobDetails, setJobDetails] = useState<AdiJob | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const [adiData, setAdiData] = useState<PersonalDataModel | null>(null);
  const [lessons, setLessons] = useState<Array<Lesson>>([]);
  const [firstUpcomingLesson, setFirstUpcomingLesson] = useState<Lesson>();
  const history = useHistory();
  const adiJobId = Object.fromEntries(urlSearchParams.entries()).adi_job_id;
  const recruiterName = Object.fromEntries(urlSearchParams.entries()).recruiter;
  const recruiterId = Object.fromEntries(urlSearchParams.entries()).recruiterId;

  const urlGenerationDateTime = Object.fromEntries(
    urlSearchParams.entries()
  ).datetime;
  const [warning, setWarning] = useState<any>();
  const getJobLessons = () => {
    getJobLessonsData(jobId, adiJobId)
      .then((res) => {
        setLessons(res.data.results);
        setFirstUpcomingLessonDate(res.data.results);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    getCurrentJobDetails();
  };

  const tabs = [
    {
      key: "general",
      title: "General",
      component: <GeneralTab refreshLessonsTab={getJobLessons} />,
      hide: false,
    },
    {
      key: "lessons",
      title: "Lessons",
      component: (
        <LessonsTab
          lessons={lessons}
          getLessons={getJobLessons}
          jobDetails={jobDetails}
          loading={loading}
          warning={warning}
          setWarning={setWarning}
        />
      ),
      hide: false,
    },
    {
      key: "progress",
      title: "Progress",
      component: (
        <ProgressTab
          jobDetails={jobDetails}
          adiData={adiData}
          jobLoading={loading}
        />
      ),
    },
    {
      key: "payments",
      title: "Payments",
      component: <PaymentsTab jobData={jobDetails} />,
    },
  ];

  useEffect(() => {
    if (jobId) {
      (async () => {
        try {
          setLoading(true);
          await getCurrentJobDetails();

          var lessonsResponse = await getJobLessonsData(jobId, adiJobId);
          setLessons(lessonsResponse.data.results);
          setFirstUpcomingLessonDate(lessonsResponse.data.results);
          var personalDataResponse = await getPersonalData();
          setAdiData(personalDataResponse.data.results);
        } catch {
          history.push("/ADI/students");
          setLoading(false);
        }
      })()
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [jobId, adiJobId]);

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
  }
  const getCurrentJobDetails = async () => {
    await currentJobDetails(jobId, adiJobId)
      .then((res) => {
        if (res.data.results.redirect_to_new_jobs === true) {
          var url = `/ADI/new-jobs/details/${jobId}?`;
          url += `isFromSMS=true&isFromNewJobsSMS=false`;

          if (recruiterName && urlGenerationDateTime) {
            url += `&recruiter=${recruiterName}&recruiterId=${recruiterId}&datetime=${urlGenerationDateTime}`;
          }

          history.push(url);
        } else if (res.data.results.redirect_to_my_jobs === true) {
          history.push("/ADI/students");
        } else {
          ReactGA.event("view_my_job_details", {
            user_id: adiData?.zendesk_contact_id,
          });
          setJobDetails(res.data.results.job);
          if (res.data.results.job?.awaiting_payment != 0) {
            setAwaitingPaymentWarning();
          }
        }
      })
      .catch((error) => {
        history.push("/ADI/students");
        setLoading(false);
      });
  };

  const setAwaitingPaymentWarning = () => {
    setWarning("Awaiting payment before lessons can be scheduled");
  };

  function setFirstUpcomingLessonDate(jobLessons: Array<Lesson>) {
    let today = new Date();
    let firstUpcoming = jobLessons.find(
      (a) => a.lesson_date && a.lesson_date.toString() > formatFromDate(today)
    );
    setFirstUpcomingLesson(firstUpcoming);
  }

  return (
    <>
      {/* // loading ? <h5> loading... </h5> : */}
      <Tab.Group
        selectedIndex={selectedIndex}
        onChange={(index) => {
          setSelectedIndex(index);
        }}
      >
        <Tab.List className="flex space-x-1">
          {tabs
            .filter((a) => !a.hide)
            .map((tab) => (
              <Tab
                key={tab.key}
                className={({ selected }) =>
                  classNames(
                    "w-full py-2.5 text-base font-medium leading-5",
                    "ring-white ring-opacity-60 ring-offset-2 ring-offset-transparent focus:outline-none focus:ring-none",
                    selected
                      ? " border-b-4 border-darkBlue"
                      : " text-[#A8A8A8] hover:brightness-75"
                  )
                }
              >
                {loading ? (
                  <div className="flex justify-center">
                    <Skeleton width={80} />
                  </div>
                ) : (
                  <>{tab.title}</>
                )}
              </Tab>
            ))}
        </Tab.List>
        <Tab.Panels className="">
          {tabs
            .filter((a) => !a.hide)
            .map((tab) => (
              <Tab.Panel key={tab.key}>{tab.component}</Tab.Panel>
            ))}
        </Tab.Panels>
      </Tab.Group>
    </>
  );
};
