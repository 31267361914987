import { FC, useEffect } from "react";
import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { useParams } from "react-router-dom";
import {
  phoneExpression,
  postalCodeExpression,
} from "../../../core/models/enums/patterns-enum";
import {
  getAllDays,
  getMonths,
  getYears,
} from "../../../core/services/date-service";
import {
  checkAdiHasCancellationPolicy,
  getCities,
  getHasPenddingDocument,
  getTestCenters,
} from "../../../core/services/adi-service";
import { formatDate } from "../../helpers/DateHelper";
import { PrivateStudentDataModel } from "../../../core/models/private-student-data";
import { updatePrivateStudent } from "../../../core/services/student-service";
import Modal from "../shared/overlays/Modal";
import Input from "../shared/forms/Input";
import Dropdown from "../shared/forms/Dropdown";
import Alert from "../shared/overlays/Alert";
import toast from "react-hot-toast";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import Notification from "../shared/overlays/Notification";
import InputHint from "../shared/forms/InputHint";
import DisclosureItem from "../shared/elements/DisclosureItem";
import { addPrivateStudent } from "../../../core/services/student-service";
import { LearnerJobStatusEnum } from "../../../core/models/enums/learner-job-status-enum";
import Toggler from "../shared/forms/Toggler";

type Props = {
  jobDetails: any;
  type: any;
  show: any;
  onHide: any;
  refreshParent?: any;
  pickupAddress?: any;
  refresh: any;
};

const AddPrivateStudentDialog: FC<Props> = ({
  jobDetails,
  type,
  show,
  onHide,
  refreshParent,
  pickupAddress,
  refresh,
}) => {
  const validPhone = new RegExp(phoneExpression + "$");
  const validPostalCode = new RegExp(postalCodeExpression + "$", "i");

  const [showTermsToaster, setShowTermsToaster] = useState<boolean>(false);
  const [testCenters, setTestCenters] = useState<Array<any>>([]);
  const [selectedTestCentre, setSelectedTestCentre] = useState<any>();
  const years = getYears(1, 0, true);
  const months = getMonths();
  const days = getAllDays();
  const [year, setYear] = useState<any>("");
  const [month, setMonth] = useState<any>("");
  const [day, setDay] = useState<any>("");
  const [time, setTime] = useState<any>("");
  const { jobId }: any = useParams();

  const [cities, setCities] = useState<Array<any>>([]);
  const [hasCancellationPolicy, setHasCancellationPolicy] =
    useState<boolean>(false);
  const citiesBinded = cities.map((city) => city.city_name);

  const updatePersonalSchema = Yup.object().shape(
    {
      student_first_name: Yup.string()
        .min(2, "Minimum 2 symbols")
        .max(50, "Maximum 50 symbols")
        .required("First name is required")
        .nullable(),
      student_surname: Yup.string()
        .min(2, "Minimum 2 symbols")
        .max(50, "Maximum 50 symbols")
        .nullable(),
      student_phone: Yup.string()
        .matches(validPhone, "Invalid mobile number")
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Mobile number is required")
        .nullable(),
      student_email: Yup.string()
        .email("Wrong email format")
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .nullable(),
      student_post_code: Yup.string()
        .matches(validPostalCode, "Invalid postal code")
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .nullable(),
      student_city_name: Yup.string().nullable(),
      student_street_address: Yup.string()
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .nullable(),
      practical_location: Yup.string().nullable(),
      month: Yup.string()
        .when(["time", "day", "year"], {
          is: (time: any, day: any, year: any) => time || day || year,
          then: () => Yup.string().required(),
        })
        .nullable(),
      day: Yup.string()
        .when(["time", "month", "year"], {
          is: (time: any, month: any, year: any) =>
            (time !== null && time) || month || year,
          then: () => Yup.string().required(),
        })
        .nullable(),
      year: Yup.string()
        .when(["time", "day", "month"], {
          is: (time: any, day: any, month: any, node: any) =>
            (time !== null && time) || day || month,
          then: () => Yup.string().required(),
        })
        .nullable(),
      time: Yup.string().nullable(),
    },
    [
      ["day", "year"],
      ["time", "year"],
      ["month", "year"],
      ["day", "month"],
      ["time", "day"],
      ["time", "month"],
    ]
  );

  const checkHasCancellationPolicy = async () => {
    const res = await checkAdiHasCancellationPolicy();
    setHasCancellationPolicy(res.results.hasCancellationPolicy);
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await getCities();
      setCities(res.data.results);
      await checkHasCancellationPolicy();
      getTestCenters().then((res) => {
        let testCentersBinded = res.data.results.map((testCenter) => {
          return { label: testCenter.test_center_name, value: testCenter.id };
        });
        setTestCenters(testCentersBinded);
      });
      checkADITerms();
    };
    if(show)
    fetchData();
    
  }, [show]);

 

  const formatDateTime = (values: any) => {
    if (formatDate(values.year, values.month, values.day) === null) return null;
    else if (!values.time)
      return formatDate(values.year, values.month, values.day);
    else
      return (
        formatDate(values.year, values.month, values.day) + " " + values.time
      );
  };

  function prepareSubmitData(values: any): PrivateStudentDataModel {
    const submittedData: PrivateStudentDataModel = {
      job_id: jobId,
      student_first_name: values.student_first_name
        ? values.student_first_name
        : null,
      student_surname: values.student_surname ? values.student_surname : null,
      student_phone: values.student_phone ? values.student_phone : null,
      student_email: values.student_email ? values.student_email : null,
      student_post_code: values.student_post_code
        ? values.student_post_code
        : null,
      student_city_name: values.student_city_name
        ? values.student_city_name
        : null,
      student_street_address: values.student_street_address
        ? values.student_street_address
        : null,
      test_center_id: values.practical_location
        ? values.practical_location
        : null,
      test_date: formatDateTime(values),
      learner_job_status: values.learner_job_status
        ? values.learner_job_status
        : null,
    };
    return submittedData;
  }
  function sendTermsAndConditionToast() {
    toast.custom(
      () => (
        <Notification
          colour="green"
          title={
            "Your terms & conditions have been sent to your learner’s email address"
          }
          description={""}
          Icon={ExclamationTriangleIcon}
        />
      ),
      {
        duration: 5000,
        position: "top-center",
      }
    );
  }

  const formik = useFormik({
    initialValues: {
      jobId: jobId,
      student_first_name: jobDetails?.student_first_name
        ? jobDetails?.student_first_name
        : "",
      student_surname: jobDetails?.student_surname
        ? jobDetails?.student_surname
        : "",
      student_phone: jobDetails?.student_phone ? (jobDetails?.student_phone?.includes("+44") ? jobDetails?.student_phone?.slice(3,) : jobDetails?.student_phone) : "",
      student_email: jobDetails?.student_email ? jobDetails?.student_email : "",
      student_post_code: pickupAddress?.postal_code
        ? pickupAddress?.postal_code
        : "",
      practical_location: "",
      month: month,
      day: day,
      year: year,
      time: time,
      student_city_name: pickupAddress?.city_name
        ? pickupAddress?.city_name
        : "",
      student_street_address: pickupAddress?.street_address
        ? pickupAddress?.street_address
        : "",
      learner_job_status: jobDetails?.job?.learner_job_status_id
        ? jobDetails?.job?.learner_job_status_id
        : LearnerJobStatusEnum.Active,
    },
    enableReinitialize: true,
    validationSchema: updatePersonalSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setStatus("");

      if (type === "add") {
        try {
          const data = await addPrivateStudent(prepareSubmitData(values));
          setSubmitting(false);

          if (refreshParent) {
            refreshParent();
          }

          close();

          if (values.student_email !== "" && hasCancellationPolicy == true && showTermsToaster) {
            sendTermsAndConditionToast();
          }
        } catch (error) {
          setStatus("Sorry, an error has occurred");

          setSubmitting(false);
        }
      }
      if (type === "edit") {
        await updatePrivateStudent(values, jobDetails?.student_id).catch(
          (error) => {
            setSubmitting(false);
            if (error && error.errors && error.errors.length > 0) {
              setSubmitting(false);
              setStatus(error.errors[0].errorMessage);
            } else {
              setSubmitting(false);
              setStatus("Sorry, an error has occurred");
            }
          }
        );
        if (refreshParent) refreshParent();
        setSubmitting(false);
        close();
        refresh();
      }
    },
  });

  const close = () => {
    formik.resetForm();
    onHide();
  };

  function checkADITerms() {
    getHasPenddingDocument()
      .then((res) => {
        if (res.data.results) {
          setShowTermsToaster(!res.data.results.has_pending_terms && !res.data.results.has_rejected_terms);
        }
      })
      .catch((error) => {});
  }

  return (
    <>
      <Modal
        open={show}
        title={type === "add" ? "Add private learner" : "About the learner"}
        submitText={type === "add" ? "Add" : "Save"}
        onClose={close}
        closeText="Cancel"
        onSubmit={formik.handleSubmit}
        disableSubmit={formik.isSubmitting}
      >
        <form onSubmit={formik.handleSubmit} noValidate>
          <div>
            {formik.status && (
              <Alert colour="red" icon="pmf-icon-info" title={formik.status} />
            )}
            {/* begin::Form group */}
            <Input
              {...formik.getFieldProps("student_first_name")}
              type="text"
              label="First name"
              placeholder=""
              error={
                formik.touched.student_first_name &&
                formik.errors.student_first_name
                  ? true
                  : false
              }
              errorMsg={formik.errors.student_first_name?.toString()}
              required={true}
              name="student_first_name"
              disabled={formik.isSubmitting}
              id="student_first_name"
              smallSpace={true}
            />
            {/* end::Form group */}

            {/* begin::Form group */}
            <Input
              {...formik.getFieldProps("student_surname")}
              type="text"
              label="Surname"
              placeholder=""
              error={
                formik.touched.student_surname && formik.errors.student_surname
                  ? true
                  : false
              }
              errorMsg={formik.errors.student_surname?.toString()}
              required={false}
              name="student_surname"
              disabled={formik.isSubmitting}
              id="student_surname"
              smallSpace={true}
            />
            {/* end::Form group */}

            {/* begin::Form group */}
            <Input
              {...formik.getFieldProps("student_phone")}
              type="tel"
              label="Mobile number"
              placeholder=""
              error={
                formik.touched.student_phone && formik.errors.student_phone
                  ? true
                  : false
              }
              errorMsg={formik.errors.student_phone?.toString()}
              required={true}
              inputGroup={true}
              inputGroupValue="+44"
              onChange={(e) => {
                /^[+\d]{0,12}$/.test(e.target.value) &&
                  formik.setFieldValue(
                    "student_phone",
                    e.target.value.length > 4
                      ? e.target.value.replace("+44", "")
                      : e.target.value
                  );
              }}
              value={formik.values.student_phone}
              name="student_phone"
              disabled={formik.isSubmitting}
              id="student_phone"
              smallSpace={true}
            />
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className="-mb-2">
              <Input
                {...formik.getFieldProps("student_email")}
                type="text"
                label="Email Address"
                placeholder=""
                error={
                  formik.touched.student_email && formik.errors.student_email
                    ? true
                    : false
                }
                errorMsg={formik.errors.student_email?.toString()}
                required={false}
                name="student_email"
                disabled={formik.isSubmitting}
                id="student_email"
                smallSpace={true}
              />
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <DisclosureItem
              title="Pick up address"
              disclosureCard
              disclosureCardSize="xs"
              id="pickup-content"
              expanded={pickupAddress?.postal_code != null ? true : false}
            >
              {/* begin::Form group */}
              <Input
                {...formik.getFieldProps("student_street_address")}
                type="text"
                label="Address"
                placeholder=""
                error={
                  formik.touched.student_street_address &&
                  formik.errors.student_street_address
                    ? true
                    : false
                }
                errorMsg={formik.errors.student_street_address?.toString()}
                name="student_street_address"
                disabled={formik.isSubmitting}
                id="student_street_address"
              />
              {/* end::Form group */}

              {/* begin::Form group */}
              <InputHint
                {...formik.getFieldProps("student_city_name")}
                type="text"
                label="Town/City"
                error={
                  formik.touched.student_city_name &&
                  formik.errors.student_city_name !== undefined
                }
                value={
                  formik.values.student_city_name != ""
                    ? citiesBinded.find(
                        (t: any) =>
                          t.toLowerCase() ===
                          formik.values?.student_city_name?.toLowerCase()
                      )
                    : formik.values.student_city_name
                }
                errorMsg={formik.errors.student_city_name?.toString()}
                name="student_city_name"
                disabled={formik.isSubmitting}
                id="student_city_name"
                onChange={(e) => {
                  formik.setFieldValue("student_city_name", e?.target.value);
                }}
                options={citiesBinded}
              />
              {/* end::Form group */}
              <Input
                {...formik.getFieldProps("student_post_code")}
                type="text"
                label="Postcode"
                placeholder=""
                error={
                  formik.touched.student_post_code &&
                  formik.errors.student_post_code
                    ? true
                    : false
                }
                errorMsg={formik.errors.student_post_code?.toString()}
                required={false}
                name="student_post_code"
                disabled={formik.isSubmitting}
                id="student_post_code"
                smallSpace={true}
              />
              {/* end::Form group */}
            </DisclosureItem>
            {type === "add" && (
              <DisclosureItem
                title="Practical test information"
                disclosureCard
                disclosureCardSize="xs"
                id="practical-content"
              >
                {/* begin::Form group */}
                <div className="grid grid-cols-3 gap-2 -mb-4">
                  <Dropdown
                    options={days}
                    label="Day"
                    placeholder="Day"
                    error={
                      formik.touched.day && formik.errors.day ? true : false
                    }
                    errorMsg={formik.errors.day?.toString()}
                    required={false}
                    name="days"
                    disabled={formik.isSubmitting}
                    id="days"
                    value={{
                      value: formik.values.day,
                      label:
                        formik.values.day === "" ? "Day" : formik.values.day,
                    }}
                    onChange={(e) => {
                      formik.setFieldValue("day", e?.value);
                    }}
                  />

                  <Dropdown
                    options={months}
                    label="Month"
                    placeholder="Month"
                    error={
                      formik.touched.month && formik.errors.month ? true : false
                    }
                    errorMsg={formik.errors.month?.toString()}
                    required={false}
                    name="months"
                    disabled={formik.isSubmitting}
                    id="months"
                    value={{
                      value: formik.values.month,
                      label:
                        formik.values.month === ""
                          ? "Month"
                          : formik.values.month,
                    }}
                    onChange={(e) => {
                      formik.setFieldValue("month", e?.value);
                    }}
                  />

                  <Dropdown
                    options={years}
                    label="Year"
                    placeholder="Year"
                    error={
                      formik.touched.year && formik.errors.year ? true : false
                    }
                    errorMsg={formik.errors.year?.toString()}
                    required={false}
                    name="years"
                    disabled={formik.isSubmitting}
                    id="years"
                    value={{
                      value: formik.values.year,
                      label:
                        formik.values.year === "" ? "Year" : formik.values.year,
                    }}
                    onChange={(e) => {
                      formik.setFieldValue("year", e?.value);
                    }}
                  />
                </div>
                {/* end:: From Group */}

                {/* begin::Form group */}
                <Input
                  {...formik.getFieldProps("time")}
                  type="time"
                  label="Time"
                  placeholder=""
                  error={
                    formik.touched.time && formik.errors.time ? true : false
                  }
                  errorMsg={formik.errors.time?.toString()}
                  required={false}
                  name="time"
                  disabled={formik.isSubmitting}
                  id="time"
                  onChange={(e) => {
                    if (e.target.valueAsDate === null)
                      formik.setFieldValue("time", "");
                    else
                      formik.setFieldValue(
                        "time",
                        moment(e.target.valueAsDate).utc().format("HH:mm:ss")
                      );
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Backspace" || e.key === "Delete")
                      formik.setFieldValue("time", "");
                  }}
                  className="w-full h-11 bg-white text-left"
                  smallSpace={true}
                />
                {/* end::Form group */}

                {/* start::Form group */}
                <Dropdown
                  options={testCenters}
                  label="Test centre"
                  placeholder=""
                  error={
                    formik.touched.practical_location &&
                    formik.errors.practical_location
                      ? true
                      : false
                  }
                  errorMsg={formik.errors.practical_location?.toString()}
                  required={false}
                  isSearchable={true}
                  name="practical_location"
                  disabled={formik.isSubmitting}
                  id="practical_location"
                  value={selectedTestCentre}
                  onChange={(e: any) => {
                    formik.setFieldValue("practical_location", e?.value);
                    setSelectedTestCentre({ label: e?.label, value: e?.value });
                  }}
                  placement="top"
                />
                {/* end::Form group */}
              </DisclosureItem>
            )}
            {jobDetails?.job?.learner_job_status_id ===
              LearnerJobStatusEnum.Complete ||
            jobDetails?.job?.learner_job_status_id ===
              LearnerJobStatusEnum.OnHold ? (
              <div></div>
            ) : (
              <div className="my-4">
                <Toggler
                  label="Add to waitlist"
                  checked={
                    formik.values.learner_job_status ===
                    LearnerJobStatusEnum.WaitList
                  }
                  onChange={() => {
                    formik.values.learner_job_status ===
                    LearnerJobStatusEnum.Active
                      ? formik.setFieldValue(
                          "learner_job_status",
                          LearnerJobStatusEnum.WaitList
                        )
                      : formik.setFieldValue(
                          "learner_job_status",
                          LearnerJobStatusEnum.Active
                        );
                  }}
                  loading={formik.isSubmitting}
                  size="full"
                />
              </div>
            )}
          </div>
        </form>
      </Modal>
    </>
  );
};

export { AddPrivateStudentDialog };
