import { FC, useEffect, useState } from "react";
import Modal from "../../shared/overlays/Modal";
import RadioInputGroup from "../../shared/forms/RadioInputGroup";
interface IProps {
  show: boolean;
  hide: any;
  setShowRegisterInterest: any;
  setShowRegisterLocationInterest: any;
  setShowRegisterAvailabilityInterest: any;
  setShowCantAccept: any;
  registerInterest: any;
  canAcceptJob: boolean;
  acceptJobData: any;
}

const BidTypesModal: FC<IProps> = ({
  show,
  hide,
  setShowRegisterInterest,
  setShowRegisterLocationInterest,
  setShowRegisterAvailabilityInterest,
  setShowCantAccept,
  registerInterest,
  canAcceptJob,
  acceptJobData,
}: IProps) => {
  const [bidType, setBidType] = useState<any>();
  useEffect(() => {}, []);

  return (
    <Modal
      open={show}
      title="Register Interest"
      submitText="Confirm"
      closeText="Back"
      onClose={hide}
      hideSubmit={true}
    >
      <div>
        If you&apos;re able to take the course but the hourly rate, availability
        or pickup location doesn&apos;t work for you, you can request a change
        below:
      </div>

      <div className="my-3">
        <RadioInputGroup
          colour="gray"
          name="register-interest"
          label=""
          options={[
            {
              label: "Hourly rate",
              value: 1,
              img: "/assets/media/svg/bids/rate-icon.svg",
              show: true,
            },
            {
              label: "Availability",
              value: 3,
              img: "/assets/media/svg/bids/availability-icon.svg",
              show: acceptJobData?.can_add_availability_bid === true,
            },
            {
              label: "Pick-up location",
              value: 2,
              img: "/assets/media/svg/bids/location-icon.svg",
              show: acceptJobData?.can_add_location_bid === true,
            },
          ]}
          centraliseItems={true}
          onChange={(e) => {
            setBidType(e);
            if (canAcceptJob) {
              registerInterest();
              if (e === 2) {
                setShowRegisterLocationInterest(true);
              } else if (e === 3) {
                setShowRegisterAvailabilityInterest(true);
              } else {
                setShowRegisterInterest(true);
              }
            } else {
              setShowCantAccept(true);
            }
            hide();
            setBidType(null);
          }}
          value={bidType}
          disabled={false}
        />
      </div>
    </Modal>
  );
};

export { BidTypesModal };
