import { FC } from 'react'
import Modal from '../../shared/overlays/Modal'

interface IProps {
    show: any
    onClose: any
}

export const CantAcceptJobDialog: FC<IProps> = ({ show, onClose }) => {    
    return (
        <Modal
            open={show}
            onClose={onClose}
            hideSubmit={true}
            title=""
            closeText='Ok'
        >
            <p>It isn’t currently possible to accept PassMeFast jobs. Other ADI Network app features are available as normal.</p>
        </Modal>
    )
}
