import React, { ReactNode, useEffect, useState } from "react";
import Navigation from "./Navigation";
import { Sidebar } from "./Sidebar";
import { QuickActions } from "./QuickActions";
import { signOut } from "../pages/auth/redux/AuthCRUD";
import * as auth from "../pages/auth/redux/AuthRedux";
import { PersonalDataModel } from "../../core/models/personal-data-model";
import { RootState } from "../../../setup";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Alert from "../components/shared/overlays/Alert";

import { useLocation } from "react-router-dom";
import QuickAddButton from "./QuickAddButton";
import { getMobileVersion } from "../../core/services/adi-service";
import { NewRelease } from "../pages/new-release/NewRelease";

type LayoutProps = {
  children: ReactNode;
};

const MasterLayout = ({ children }: LayoutProps) => {
  const envMobileVersion = useSelector<RootState>(
    ({ auth }) => auth.mobileVersion
  );
  const personalData: PersonalDataModel = useSelector<RootState>(
    ({ auth }) => auth.personalData,
    shallowEqual
  ) as PersonalDataModel;
  const dispatch = useDispatch();
  const logout = () => {
    signOut();
    dispatch(auth.actions.logout());
    sessionStorage.clear();
  };
  const location = useLocation();

  const [modalParam, setModalParam] = useState(0);

  const refreshParent = () => {
    setModalParam(modalParam === 1 ? 0 : 1);
  };

  const [mobVersion, setMobVersion] = useState<any>();

  useEffect(() => {
    const fetchMobileVersion = async () => {
      await getMobileVersion().then((data) => {
        setMobVersion(data.data.results);
      });
    };
    fetchMobileVersion();
  }, [mobVersion]);

  const [showEventsSubMenu, setShowEventsSubMenu] = useState(false);

  return (
    <>
      {mobVersion !== envMobileVersion ? (
        <>
          <NewRelease />
        </>
      ) : (
        <></>
      )}
      <div>
        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-60 lg:flex-col">
          <Sidebar />
        </div>

        <div className="lg:pl-60">
          {personalData?.is_impersonate === 1 && (
            <Alert
              basicAlert
              colour="red"
              buttons={[{ title: "Exit", onClick: logout }]}
              customDesc={
                <p className="text-sm sm:w-full w-[80%]">
                  You are viewing this page as an admin, Logged in as{" "}
                  <b>{personalData?.name + " " + personalData?.sur_name}</b>
                </p>
              }
            ></Alert>
          )}
          <main className="pb-10 overflow-x-hidden">
            <div className="pb-[100px] sm:pt-0 pt-6" key={modalParam}>
              {children}
            </div>
            <div className="block w-full lg:hidden">
              <Navigation />
            </div>
          </main>
        </div>
      </div>

      {location.pathname.includes("students") ? (
        <QuickAddButton show="Learner" refreshParent={refreshParent} />
      ) : location.pathname.includes("lessons") ? (
        <>
          <QuickAddButton
            show="Events"
            setShowEventsSubMenu={setShowEventsSubMenu}
            refreshParent={refreshParent}
          />
          <QuickActions
            showEventsSubMenu={showEventsSubMenu}
            setShowEventsSubMenu={setShowEventsSubMenu}
            customMenu
            refreshParent={refreshParent}
          />
        </>
      ) : !location.pathname.includes("new-jobs/details") ? (
        <QuickActions refreshParent={refreshParent} />
      ) : null}
    </>
  );
};

export { MasterLayout };
