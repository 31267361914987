import { FC, useEffect, useState } from "react";
import { Car } from "../../../../core/models/car";
import {
  getCars,
  getFuelTypes,
  getTransmissionTypes,
  updateAdiCars,
} from "../../../../core/services/adi-service";
import { scrollToTop } from "../../../helpers/ScrollHelper";
import ReactGA from "react-ga4";
import { CarCard } from "../../shared/cards/CarCard";
import Modal from "../../shared/overlays/Modal";
import { PlusSmallIcon } from "@heroicons/react/20/solid";
import Button from "../../shared/elements/Button";
import { CarModal } from "./CarModal";
import PageHeader from "../../shared/elements/PageHeader";
import Alert from "../../shared/overlays/Alert";
import { IQuationnaireTabProps } from "../../../../core/models/quationnaire-tab-props";
import Skeleton from "@mui/material/Skeleton";
import EmptyState from "../../shared/elements/EmptyState";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import toast from "react-hot-toast";
import Notification from "../../shared/overlays/Notification";

const CarsList: FC<IQuationnaireTabProps> = (props) => {
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);
  const [modalCurrentCar, setModalCurrentCar] = useState(new Car());
  const [cars, setCars] = useState<Array<Car>>([]);
  const [preDeleteCars, setPreDeleteCars] = useState<Array<Car>>([]);
  const [fuelTypes, setFuelTypes] = useState<any>([]);
  const [transmissionTypes, setTransmissionTypes] = useState<Array<any>>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const isQuestionnaire = props.parentWrapper === "questionnaire";

  const handleClose = () => {
    setModalCurrentCar(new Car());
    setShow(false);
  };
  const handleShow = (car: Car) => {
    setModalCurrentCar(car);
    setShow(true);
  };
  const deleteCar = (carId: any) => {
    const filteredCars = cars.filter((c) => c.frontendId !== carId);
    if (filteredCars.length === 0) {
      setErrorMsg("Please add the details of your car");
    } else {
      setPreDeleteCars(filteredCars);
      setShowDeleteDialog(true);
    }
  };

  const submitDeleteDialog = () => {
    return saveCars(preDeleteCars, () => setCars(preDeleteCars));
  };

  useEffect(() => {
    setLoading(true);
    const fuelsPromise = getFuelTypes().then((response) => {
      setFuelTypes(response?.data?.results);
    });
    const transmissionsPromise = getTransmissionTypes().then((response) => {
      setTransmissionTypes(response?.data?.results);
    });

    Promise.all([fuelsPromise, transmissionsPromise])
      .then(() => getCars())
      .then((response) => {
        const cars = response?.data?.results;
        if (cars) {
          cars.forEach((car) => setFrontEndID(car));
          setCars(Array.from(cars));
        }
        setLoading(false);
      })
      .catch((error) => {
        setErrorMsg(error.message);
        setLoading(false);
      });
  }, []);

  const setFrontEndID = (car: Car) => {
    car.frontendId = (Math.random() * 1000000).toString(16);
  };

  const handleSubmittingCar = (car: Car) => {
    if (!car.frontendId) setFrontEndID(car);
    const filteredCars = cars.filter((c) => c.frontendId !== car.frontendId);
    filteredCars.push(car);
    return saveCars(filteredCars, () => setCars(filteredCars));
  };

  const saveCars = (adiCars: Array<Car>, onSuccess: () => void) => {
    setIsSubmitting(true);
    let loadingId = toast.custom(
      () => (
        <Notification
          colour="gray"
          title={"Updating your cars..."}
          description={""}
          Icon={ExclamationCircleIcon}
        />
      ),
      {
        duration: 5000,
        position: "top-center",
      }
    );
    return updateAdiCars({ cars: adiCars })
      .then(() => {
        ReactGA.event("update_car");
        setErrorMsg(undefined);
        toast.custom(
          () => (
            <Notification
              colour="green"
              title={"Saved successfully"}
              description={""}
              Icon={CheckCircleIcon}
            />
          ),
          {
            duration: 5000,
            position: "top-center",
            id: loadingId,
          }
        );
        onSuccess();
        setShowDeleteDialog(false);
        if (isQuestionnaire && props.onUpdate) {
          props.onUpdate();
        }
      })
      .catch((res) => {
        setShowDeleteDialog(false);
        toast.custom(
          () => (
            <Notification
              colour="red"
              title={"Failed to update your cars!"}
              description={""}
              Icon={ExclamationTriangleIcon}
            />
          ),
          {
            duration: 5000,
            position: "top-center",
            id: loadingId,
          }
        );
        if (res?.errors?.length) {
          setErrorMsg(res.errors[0].errorMessage);
        } else {
          setErrorMsg("Sorry, an error has occurred");
        }
      })
      .finally(() => {
        setIsSubmitting(false);
        scrollToTop();
      });
  };

  return (
    <>
      <div
        className={props.parentWrapper === "questionnaire" ? "hidden" : "mb-5"}
      >
        <PageHeader
          title="Car"
          backToURL={
            props.parentWrapper !== "questionnaire"
              ? "/ADI/settings/overview"
              : ""
          }
        />
      </div>
      <div
        className={`${
          props.parentWrapper === "questionnaire" ? "bg-white" : "px-5 pb-5"
        }`}
      >
        {errorMsg && (
          <div className="mt-3">
            {" "}
            <Alert
              colour="red"
              icon="pmf-icon-info"
              title={errorMsg}
              loading={loading}
            />
          </div>
        )}

        {/* {isQuestionnaire && props.selfRegisterDate ? (
            <AlertSkip />
          ) : (<></>)}
          {isQuestionnaire && !props.selfRegisterDate ? (
            <AlertVerifyData />
          ) : (<></>)} */}

        {loading ? (
          <Skeleton
            height={40}
            variant="rounded"
            className="w-full mb-5 !rounded-full"
          />
        ) : (
          <>
            <div
              className={
                cars?.length === 0
                  ? "rounded-lg bg-white shadow border mb-2 mt-3 p-3"
                  : ""
              }
            >
              {cars?.length === 0 && (
                <div className="-my-8">
                  <EmptyState
                    title="No cars yet"
                    description="Please add at least one car"
                    Icon={ExclamationCircleIcon}
                  />
                </div>
              )}
              <Button
                className={isQuestionnaire ? "mb-2" : "my-2"}
                colour="yellow"
                type="button"
                fullWidth
                size={isQuestionnaire ? "medium" : "large"}
                onClick={() => handleShow(new Car())}
              >
                <span className="flex items-center py-1">
                  <PlusSmallIcon className="w-5 h-5" /> Add car
                </span>
              </Button>
            </div>
          </>
        )}

        {/* ----- start:: Cars List ----- */}
        {loading ? (
          <div
            className={`${
              isQuestionnaire ? "" : "xl:grid-cols-3 sm:grid-cols-2"
            } grid grid-cols-1 gap-4`}
          >
            <CarCard loading={loading} />
          </div>
        ) : (
          <div
            className={`${
              isQuestionnaire ? "" : "xl:grid-cols-3 sm:grid-cols-2"
            } grid grid-cols-1 gap-4 my-5`}
          >
            {cars.map(function (car, index) {
              return (
                <div key={index}>
                  <>
                    <CarCard
                      car={car}
                      transmissionTypes={transmissionTypes}
                      isSubmitting={isSubmitting}
                      handleDeleteCar={() => deleteCar(car.frontendId)}
                      handleShow={handleShow}
                      loading={loading}
                    />
                  </>
                </div>
              );
            })}
          </div>
        )}

        {/* ----- end:: Car List ----- */}

        {/*  {props.allowSkip ? (
                <div className='col-12 d-flex order-3 justify-content-center'>
                  <a className='link--basic btn btn-link' onClick={() => props.goToNextTab()}>Skip this step</a>
                </div>
                ) : (<></>)} */}

        {/* ----- start:: Add/Edit Car Modal ----- */}
        <CarModal
          show={show}
          onClose={handleClose}
          car={modalCurrentCar}
          fuelTypes={fuelTypes}
          transmissionTypes={transmissionTypes}
          handleSubmittingCar={handleSubmittingCar}
          parentWrapper={props.parentWrapper}
        ></CarModal>
        {/* ----- end:: Add/Edit Car Modal ----- */}

        {/* ----- start:: Delete Car Modal ----- */}
        <Modal
          open={showDeleteDialog}
          title="Delete car"
          description="Are you sure you want to delete this car?"
          submitText="Delete"
          onClose={() => setShowDeleteDialog(false)}
          onSubmit={submitDeleteDialog}
          disabled={isSubmitting}
        ></Modal>
        {/* ----- end:: Delete Car Modal ----- */}
      </div>
    </>
  );
};
export { CarsList };
