import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { resetPassword, requestPassword, login, emailExists } from '../redux/AuthCRUD'
import { useDispatch } from 'react-redux'
import * as auth from '../redux/AuthRedux'
import { passwordYup } from './PasswordRequirements'
import { getSavedSteps } from '../../../../core/services/adi-service'
import { CountDownTimer } from './CountDownTimer'
import ContactInfo from '../../help/ContactInfo'
import { scrollToTop } from '../../../helpers/ScrollHelper'
import Input from '../../../components/shared/forms/Input'
import Button from '../../../components/shared/elements/Button' 
import PageHeader from '../../../components/shared/elements/PageHeader'
import Alert from '../../../components/shared/overlays/Alert'


const resetPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  code: Yup.string()
    .required('Code is required'),
  password: passwordYup,
  confirmpassword: Yup.string()
  .oneOf([Yup.ref('password')], "Passwords do not match"),
})

export function ResetPassword() {
  
    // Initialize a boolean state
    const [passwordShown, setPasswordShown] = useState(false)
    const [resendCodeTimerResetting, resetResendCodeTimer] = useState(false)
    const [resendCodeTimerOn, setResendCodeTimerOn] = useState(false)
    const [permissionDenied, setPermissionDenied] = useState(false)
    const [showPasswordFields, setShowPasswordFields] = useState(false)

    const history = useHistory();
    // Password toggle handler
    const togglePassword = () => {
      // When the handler is invoked
      // inverse the boolean state of passwordShown
      setPasswordShown(!passwordShown)
    }

    // Initialize a boolean state
    const [confirmPassShown, setConfirmPassShown] = useState(false)

    // Password toggle handler
    const toggleconfirmPass = () => {
      // When the handler is invoked
      // inverse the boolean state of passwordShown
      setConfirmPassShown(!confirmPassShown)
    }

  const [loading, setLoading] = useState(false)
  const [adiNotFound, setAdiNotFound] = useState<boolean>(false)
  const dispatch = useDispatch()
  const { email }: any = useParams();
  const initialValues = {
    email: email,
    code: '',
    password: '',
    confirmpassword:''
  }
  const formik = useFormik({
    initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setStatus(null)
      emailExists(email)
          .then(() => resetPassword(values.email, values.code, values.password))
          .then(() => login(values.email, values.password))
          .then(() => checkHasUnverifiedData())
          .catch((err) => {
            setLoading(false)
            handleError(err);
            setShowPasswordFields(false);
            setPasswordShown(false);
            setConfirmPassShown(false);
          }).finally(() => setSubmitting(false))
    },
  })
  const checkHasUnverifiedData = () => {
    return getSavedSteps().then(res => {
      if(res.data.results.has_un_verified_imported_data)
      {
        dispatch(auth.actions.setHasUnVerifiedData(true))
      }
      dispatch(auth.actions.setIsLogin(true))
      setLoading(false)
      dispatch(auth.actions.resetPassword())
    });
  }

  const resendCode = () => {
    const email = formik.getFieldProps('email').value;
    requestPassword(email)
      .then(() => emailExists(email))
      .catch(handleError);
    setResendCodeTimerOn(true)
    resetResendCodeTimer(true)
  }

  const handleError = (err: any) => {
    const cannotAccessPortalMsg = "Can't access portal";
    const errorMsg = err.errors?.length ? err.errors[0].errorMessage : err.message;
    if (errorMsg === cannotAccessPortalMsg) {
      setPermissionDenied(true);
      localStorage.removeItem("permission-denied");
    } else if (errorMsg == "Invalid verification code provided, please try again.") {
      formik.setStatus("The verification code you entered does not match the code we sent.");
    } else {
      formik.setStatus(errorMsg);
    }
    const notFoundMsgs = [
      "There is no account registered with this email", 
      "Adi doesn't exist",
      cannotAccessPortalMsg
    ];
    const notFound = notFoundMsgs.find(e => e === errorMsg) != undefined;
    setAdiNotFound(notFound);
    scrollToTop()
  }

  return (
    <>
      <form
        className="space-y-6"
        noValidate
        onSubmit={formik.handleSubmit}
      >

        {/* begin::Heading */}
        <div className="flex flex-col text-center items-center justify-center mb-0">
          {/* begin::Title */}
          <PageHeader title="Set password" />
          {/* end::Title */}
          <div className='text-gray-400 fw-bold text-sm mt-2'>Please enter the verification code we have just emailed you</div>
        </div>
        {/* end::Heading */}
        
        
        {permissionDenied && (<div className='mb-lg-6 alert alert-warning not-allowed-alert p-6 pb-4'>
          <ContactInfo isNotAllowed={true}></ContactInfo>
        </div>)}


        {formik.status && (
                      <Alert description={formik.status} colour="red"></Alert>

   
        )}


        {/* begin::Form group */}
        <Input
          {...formik.getFieldProps('email')}
          type="email"
          label="Email address"
          placeholder="you@example.com"
          error={formik.touched.email && formik.errors.email ? true : false}
          errorMsg=''
          required={true}
          name="email"
          disabled
          id="email"
        />
        {/* end::Form group */}

        {/* begin:: Code Form group */}
        <Input
          {...formik.getFieldProps('code')}
          type="text"
          label="Code"
          placeholder="000000"
          error={formik.touched.code && formik.errors.code ? true : false}
          errorMsg={formik.errors.code}
          required={true}
          name="code"
          disabled={formik.isSubmitting || showPasswordFields}
          id="code"
        />
        {/* end:: Code Form group */}

        <div className="flex items-center justify-between d-none">
          <div className="text-sm leading-6">
          {!showPasswordFields &&
            <div className='fv-help-block d-flex flex-wrap justify-content-between'>
           
              <button type="button"
                disabled={resendCodeTimerOn || adiNotFound}
                onClick={(e) => {
                  e.preventDefault();
                  resendCode();
                }}
                className='btn btn-link link-primary fs-3 fw-bolder pt-1 pb-0'
              >
                Resend Code?
              </button>

              <CountDownTimer
                timeInSeconds={30}
                isResetting={resendCodeTimerResetting}
                resetTimer={resetResendCodeTimer}
                onFinish={() => setResendCodeTimerOn(false)}>
              </CountDownTimer>
            </div>}
           
          </div>
        </div>

        {showPasswordFields && 
        <>
          {/* begin:: Password form group */}
          <Input
            {...formik.getFieldProps("password")}
            type={passwordShown ? "text" : "password"}
            label="Password"
            placeholder="********"
            error={
              formik.touched.password && formik.errors.password ? true : false
            }
            errorMsg={formik.errors.password}
            required={true}
            name="password"
            disabled={formik.isSubmitting}
            id="password"
          />
          {/* end:: Password form group */}

          {/* begin:: Password form group */}
          <Input
           {...formik.getFieldProps('confirmpassword')}
            type={confirmPassShown ? 'text' : 'password'}
            label="Confirm password"
            placeholder="********"
            error={
              formik.touched.confirmpassword && formik.errors.confirmpassword ? true : false
            }
            errorMsg={formik.errors.confirmpassword}
            required={true}
            name="confirmpassword"
            disabled={formik.isSubmitting}
            id="confirmpassword"
          />

              <span
                className={clsx(
                  'input-group-text rounded-0',
                  {
                    'border-danger': formik.touched.password && formik.errors.password,
                  }
                )}
                role='button' onClick={toggleconfirmPass}>
                <i className={`fs-3 far ${confirmPassShown ? 'fa-eye-slash' : 'fa-eye'}`}></i>
              </span>
          {/* end:: Password form group */}
        </>
        }


        {/* begin::Action */}
        <div className='flex justify-between space-x-2'>
        {showPasswordFields && (
          <Button
          colour="yellow"
          type="submit"
          className="flex-1"
          size='large'
          disabled={formik.isSubmitting || adiNotFound}
        >
          {!loading && <span className="indicator-label">Submit</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </Button>
        )}
        {!showPasswordFields && (
          <Button
          colour="yellow"
          type="submit"
          size='large'
          className="flex-1"
          onClick={() => {
            if (!showPasswordFields && formik.values.code) {
              setShowPasswordFields(true);
            } else {
              formik.handleSubmit();
            }
          }}
          >
          Save
          </Button>
        )}
          
        <Button  className='flex-1' colour="gray" disabled={formik.isSubmitting} 
          onClick={() => history.push("/auth/login" + email)}>
          Cancel
        </Button>
        </div>
        {/* end::Action */}
      </form>
    </>
  )
}
