import React, { useState } from "react";
import Alert from "../overlays/Alert";
import { EyeIcon, EyeSlashIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline'

export interface InputProps extends React.HTMLAttributes<HTMLInputElement> {
  label?: string;
  id?: string;
  name?: string;
  className?: string;
  value?: string;
  key?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error?: boolean;
  errorMsg?: string;
  smallSpace?: boolean;
  placeholder?: string;
  inputmode?: "numeric";
  min?: string;
  disabled?: boolean;
  required?: boolean;
  inputGroup?: boolean;
  inputGroupValue?: string;
  accept?: string;
  hasInfo?:boolean;
  alertMsg?:string;
  eyeIcon?:boolean;
  readonly?:boolean;
  searchIcon?:boolean;
  clickableSearchIcon?:boolean;
  onSearchClick?: () => void;
  roundedFull?:boolean;
  type:
  | "text"
  | "email"
  | "password"
  | "number"
  | "tel"
  | "url"
  | "search"
  | "date"
  | "time"
  | "datetime-local"
  | "month"
  | "week"
  | "color"
  | "file";
}
export default function Input({
  label,
  className = "",
  type = "text",
  smallSpace = false,
  inputmode,
  min,
  name,
  key,
  id = "",
  value,
  onChange,
  required = false,
  hasInfo = false,
  alertMsg = "",
  disabled = false,
  inputGroup = false,
  inputGroupValue = "",
  error = false,
  eyeIcon=false,
  searchIcon=false,
  clickableSearchIcon=false,
  onSearchClick,
  roundedFull=false,
  readonly=false,
  errorMsg = "This field is required",
  placeholder,
  accept,
  ...props
}: InputProps) {
  // Initialize a boolean state
  const [passwordShown, setPasswordShown] = useState(false);
  const [openAdiNumberNote, setOpenAdiNumberNote] = useState(false);

  // Password toggle handler
  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };

  const toggleInfo = () => {
    setOpenAdiNumberNote(!openAdiNumberNote);
  };
  return (
    <div className="flex flex-col items-start my-6">
      <div className="relative w-full">
        <label
          htmlFor={name}
          className="absolute -top-2 z-[3] left-2 inline-block bg-white px-1 text-sm font-medium text-darkBlue"
        >
          {label}{required && <span className='text-sm text-red-500'>*</span>}{hasInfo && <i onClick={toggleInfo} className="pmf-icon-info pl-1 text-sm"></i>}
        </label>

        <div className="relative -mt-0.5 rounded-md">
          {inputGroup &&
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <span className="text-gray-500 text-sm">{inputGroupValue}</span>
            </div>
          }
          {
            eyeIcon &&
            <div className="absolute inset-y-0 right-2 flex items-center pl-3" onClick={togglePassword}>
              {passwordShown ? <EyeSlashIcon className='w-6 h-6 text-[#A8A8A8]' /> : <EyeIcon className='w-6 h-6 text-[#A8A8A8]' />}
            </div>
          }
          { clickableSearchIcon &&
            <div className="absolute inset-y-0 right-4 flex items-center pl-3" onClick={onSearchClick}>
              {<MagnifyingGlassIcon className='w-5 h-5 text-darkBlue' />}
            </div>
          }
          {
            searchIcon &&
            <div className="absolute inset-y-0 left-0 flex items-center pl-3" >
              {<MagnifyingGlassIcon className='w-6 h-6 text-[#A8A8A8]' />}
            </div>
          }
          <input
            {...props}
            type={eyeIcon ? (passwordShown ? 'text' : 'password') : type}
            // type={type}
            key={key ?? name}
            name={name}
            id={id}
            inputMode={inputmode}
            readOnly={readonly}
            value={value}
            min={min}
            className={`${error ? "border-red-500" : "border-gray-300"}
            ${inputGroup ? (type === "tel" ? "pl-12" : "pl-8") : "px-2"}
            ${searchIcon ? "pl-11 pr-20" : "px-2"}
            ${smallSpace ? "py-1.5" : "py-2.5"}
            ${roundedFull ? "rounded-3xl pl-4" : "rounded-sm"}
             block w-full mt-1 text-darkBlue border placeholder:text-gray-400 focus:border focus:border-inset focus:border-indigo-600 text-sm leading-6 ${className}`}
            placeholder={placeholder}
            onChange={onChange}
            disabled={disabled}
            required={required}
          />
         
          {openAdiNumberNote && (
            <div className="mt-2 -mb-5">
              <Alert colour='blue' icon="pmf-icon-info" title={alertMsg} />
            </div>
          )}
        </div>
        {error && (
          <p className="text-sm text-red-600" id="error">
            {errorMsg}
          </p>
        )}
      </div>
    </div>
  );
}