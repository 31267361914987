import { FC, useEffect, useState } from "react";
import { IQuationnaireTabProps } from "../../../../core/models/quationnaire-tab-props";
import Button from "../../shared/elements/Button";
import Alert from "../../shared/overlays/Alert";
import DisclosureItem from "../../shared/elements/DisclosureItem";
import { getQuestionnareProgress } from "../../../../core/services/adi-service";
import { Skeleton } from "@mui/material";
import { QuestionnaireStepsEnum } from "../../../../core/models/enums/questionnaire-steps-enum";
import { QuestionnaireStepStatusEnum } from "../../../../core/models/enums/questionnaire-step-status-enum";
import { QuestionnaireStepStatusTextEnum } from "../../../../core/models/enums/questionnaire-step-status-text-enum";
import { QuestionnaireStepModal } from "./QuestionnaireStepModal";
import { scrollToTop } from "../../../helpers/ScrollHelper";
import { VerifyAccountModal } from "../bank-details/_modals/VerifyAccountModal";
import { QuestionnaireStepModel } from "../../../../core/models/questionnaire-step-model";
import { useHistory } from "react-router-dom";
import { useQuery } from "../../../helpers/QueryParamsHelper";

const GeneralQuestionnaireData: FC<IQuationnaireTabProps> = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedShownModal, setSelectedShownModal] = useState<number>(0);
  const [showAlertError, setShowAlertError] = useState<boolean>(false);
  const [showVerifyPopup, setShowVerifyPopup] = useState(false);
  const history = useHistory();
  const query = useQuery();

  const steps: Array<QuestionnaireStepModel> = [
    {
      step_number: QuestionnaireStepsEnum.badge,
      name: "Badge",
      icon: "badge",
      title: "Please complete to view rates and accept jobs",
      description:
        "You’ll need to complete this step to unlock course rates your area. Once you’ve uploaded your badge, it will be sent to a member of the ADI Network team to approve. ",
      completed_status: 1,
      disabled: false,
      openModal: () => {
        setShowModal(true);
        setSelectedShownModal(QuestionnaireStepsEnum.badge);
      },
    },
    {
      step_number: QuestionnaireStepsEnum.bankDetails,
      name: "Bank Details",
      icon: "bank-details",
      title: "Please complete to accept jobs",
      description:
        "We ask for your bank details so that we can send you payments for courses you accept via ADI Network and any other rewards/bonus initiatives that you qualify for. Please complete this step to accept jobs from ADI Network. ",
      completed_status: 1,
      disabled: false,
      openModal: () => {
        setShowModal(true);
        setSelectedShownModal(QuestionnaireStepsEnum.bankDetails);
      },
    },
    {
      step_number: QuestionnaireStepsEnum.carAndInsurance,
      name: "Car + Insurance",
      icon: "car-insurance",
      title: "Please complete to accept jobs",
      description:
        "Please provide your car and insurance policy details. You’ll need to complete this section to accept courses on the app. ",
      completed_status: 1,
      disabled: false,
      openModal: () => {
        setShowModal(true);
        setSelectedShownModal(QuestionnaireStepsEnum.carAndInsurance);
        setShowAlertError(false);
      },
    },
    {
      step_number: QuestionnaireStepsEnum.cancellationPolicy,
      name: "Cancellation policy",
      icon: "cancellation-policy",
      title: "Please complete to accept jobs",
      description:
        "Tell us your cancellation policy or upload your terms and conditions and we’ll forward it to your learners. We’ll also trigger lesson reminders to them based on your policy. You’ll need to complete this section to accept courses on the app. ",
      completed_status: 1,
      disabled: false,
      openModal: () => {
        setShowModal(true);
        setSelectedShownModal(QuestionnaireStepsEnum.cancellationPolicy);
      },
    },
    {
      step_number: QuestionnaireStepsEnum.availability,
      name: "Availability",
      icon: "availability",
      title: "Please complete to view tailored job opportunities",
      description:
        "Please provide your working hours and any test centres that you cover so that we can show you tailored job opportunities. ",
      completed_status: 1,
      disabled: false,
      openModal: () => {
        setShowModal(true);
        setSelectedShownModal(QuestionnaireStepsEnum.availability);
      },
    },
    {
      step_number: QuestionnaireStepsEnum.rates,
      name: "Rates",
      icon: "rates",
      title: "Please complete to view tailored job opportunities",
      description:
        "Please provide your hourly rates so that we can show you tailored job opportunities. ",
      completed_status: 1,
      disabled: false,
      openModal: () => {
        setShowModal(true);
        setSelectedShownModal(QuestionnaireStepsEnum.rates);
      },
    },
  ];
  const [loading, setLoading] = useState(true);
  const [currentSteps, setCurrentSteps] = useState(steps);

  useEffect(() => {
    getQuestionnareProgressData();
  }, []);

  const getQuestionnareProgressData = async () => {
    const res = await getQuestionnareProgress();
    let updatedSteps = res.results.map(
      (currentStep: QuestionnaireStepModel) => {
        let tempStep = currentSteps.filter(
          (step: {
            completed_status: number;
            disabled: boolean;
            step_number: number;
          }) => step.step_number === currentStep.step_number
        )[0];
        return { ...tempStep, ...currentStep };
      }
    );

    const isCompletedQuestionnaire =
      updatedSteps.filter(
        (step: QuestionnaireStepModel) =>
          step.completed_status !== QuestionnaireStepStatusEnum.Complete
      ).length === 0;

    if (isCompletedQuestionnaire) {
      setShowVerifyPopup(true);
    }

    setCurrentSteps(updatedSteps);
    setLoading(false);
  };

  const getStatusName = (statusNumber: number) => {
    switch (statusNumber) {
      case QuestionnaireStepStatusEnum.Incomplete:
        return QuestionnaireStepStatusTextEnum.Incomplete;
      case QuestionnaireStepStatusEnum.PartiallyComplete:
        return QuestionnaireStepStatusTextEnum.PartiallyComplete;
      case QuestionnaireStepStatusEnum.Complete:
        return QuestionnaireStepStatusTextEnum.Complete;
      default:
        return null;
    }
  };

  return (
    <div
      className={
        props.parentWrapper === "questionnaire" ? "px-5 pb-4" : ""
      }
    >
   
      <div className="mb-4">
        <Alert
          colour="blue"
          loading={loading}
          icon="pmf-icon-info"
          title="Unlock more opportunities — please complete the optional sections of your profile to see and accept tailored job opportunities in your area. It will only take a couple of minutes"
        />
      </div>
      {showAlertError ? (
        <div className="mb-4">
          <Alert
            colour="red"
            icon="pmf-icon-info"
            title="Please add at least one car"
          />
        </div>
      ) : (
        <></>
      )}
      <div
        className={`${
          props.parentWrapper === "questionnaire"
            ? "md:border-0 md:grid md:grid-cols-2 md:gap-4"
            : ""
        } rounded-md border`}
      >
        {currentSteps.map((step, index) => (
          <div
            key={index}
            className={`${
              props.parentWrapper === "questionnaire"
                ? "md:rounded-md md:border-b-0"
                : ""
            } first:rounded-t-md last:rounded-b-md border-b py-[6px] bg-white`}
          >
            <DisclosureItem
              title={step.name}
              disclosureImg={`/assets/media/svg/general-questionnaire/${step.icon}.svg`}
              disclosureCard
              disclosureCardSize="sm"
              border={""}
              loading={loading}
              disableTitleClicked
              onTitleClicked={() => {
                if (!step.disabled) step.openModal();
                else {
                  scrollToTop();
                  setShowAlertError(true);
                }
              }}
              customDescription={
                <>
                  {loading ? (
                    <Skeleton width={150} />
                  ) : (
                    <p className="text-xs py-[6px] font-normal text-start">
                      {step.title}
                    </p>
                  )}
                  {loading ? (
                    <>
                      <Skeleton width={50} />
                      <Skeleton width={150} />
                    </>
                  ) : (
                    <div aria-hidden="true">
                      <div className="mb-1 text-darkBlue font-bold text-sm text-start">
                        {getStatusName(step.completed_status)}
                      </div>
                      <div className="overflow-hidden rounded-full bg-gray-200">
                        <div
                          className="h-2 rounded-full bg-yellow"
                          style={{
                            width: `${
                              step.completed_status ===
                              QuestionnaireStepStatusEnum.Complete
                                ? 100
                                : step.completed_status ===
                                  QuestionnaireStepStatusEnum.Incomplete
                                ? 0
                                : 50
                            }%`,
                          }}
                        />
                      </div>
                    </div>
                  )}
                </>
              }
            >
              <p className="text-sm px-3 pb-3 pt-2">{step.description}</p>
            </DisclosureItem>
          </div>
        ))}
      </div>

      <QuestionnaireStepModal
        show={showModal}
        getQuestionnareProgressData={getQuestionnareProgressData}
        onClose={() => setShowModal(false)}
        onSubmit={() => {
          setShowModal(false);
          setLoading(true);
          getQuestionnareProgressData();
          if (props.parentWrapper === "questionnaire" && props.onUpdate)
            props.onUpdate();
        }}
        selectedShownModal={selectedShownModal}
        jobId={props.jobId}
        steps={currentSteps}
      />

      <VerifyAccountModal
        continueSelected={() => {
          setShowVerifyPopup(false);
          if (props?.onCloseParentModal) props?.onCloseParentModal();
          if (props?.goToDashboard) {
            let url = query.get("returnUrl");
            const category = query.get("category");
            const video = query.get("video");            
            if (category || video) {
              const tab = query.get("tab");
              url = `/ADI/cpd?tab=${tab ?? "videos"}`;
              if (category) url += `&category=${category}`;
              if (video) url += `&video=${video}`;
            } else {
              if (!url) {
                url = "/ADI/dashboard";
              }
            }
            history.push(url);
          } else {
            history.push("/ADI/dashboard");
          }
        }}
        show={showVerifyPopup}
        onHide={() => {
          setShowVerifyPopup(false);
        }}
      />
    </div>
  );
};

export { GeneralQuestionnaireData };
