import { useEffect } from "react";

interface IProps {
    name: string
    label?: string
    options: Array<{ value: any, label: string }>
    onChange: (e: any, data: any) => void;
    values: Array<any>
    required?: boolean
    disabled: boolean
    error?: boolean;
    errorMsg?: string;
    isRounded?:boolean ;
    isFullWidth?: boolean
    border?:string

}

export default function HorizontalCheckboxGroup({
    name,
    label,
    values,
    options = [],
    onChange,
    required = false,
    disabled = false,
    isRounded = false,
    isFullWidth = true,
    
}: IProps) {

    const toggleSelected = (e: any, data: any) => {
        if (e.target.checked) {
            values = [...values, data];
        } else {
            values = values.filter((elem: any) => elem !== data);
        }

        onChange(e, data);
    };
    useEffect(()=>{
        console.log(values)
    },[values])

    return (
        <ul className={  isFullWidth ? " grid gap-2 grid-cols-5 w-full" : "flex px-2"}key={name}>
            {options?.map((option, index) => {
                const uniqueId = `${name}-${index}`;
                return (
                    <li key={uniqueId} className={ "relative" + (isFullWidth ?"":" px-2")}>
                        <input
                            className='checked:accent-[#163a5e] peer absolute opacity-0 top-0 left-0 right-0 bottom-0'
                            type='checkbox'
                            name={uniqueId}
                            id={uniqueId}
                            checked={values.indexOf(option.value) !== -1}
                            onChange={(e) => {
                                toggleSelected(e, option.value);
                            }}
                            data-parsley-multiple={name}
                            disabled={disabled}
                            required={required}
                        />
                        <label htmlFor={uniqueId} className={"inline-flex items-center justify-center  bg-white cursor-pointer peer-checked:border-darkBlue peer-checked:bg-darkBlue peer-checked:text-white hover:bg-gray-50" + (isRounded ? " border-2 border-darkBlue rounded-full w-10 h-10 " : " border-2 border-gray-200  w-full rounded-lg py-1")} >
                            <div className="w-full text-[10px] font-semibold text-center">{option.label}</div>
                        </label>
                    </li>
                );
            })}

        </ul>
    )
}