/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from "react";
import React from "react";
import { toAbsoluteUrl } from "../../../helpers";
import Skeleton from "@mui/material/Skeleton";
import { ClickAwayListener, Tooltip } from "@mui/material";
import Badge from "../elements/Badge";

interface IGrayCardProp {
  title?: React.ReactNode;
  value?: string | null | undefined | React.ReactNode;
  topIcon?:
    | "pmf-icon-map-pin"
    | "pmf-icon-currency-pound"
    | "pmf-icon-calendar"
    | "pmf-icon-intensity";
  icon?:
    | "pmf-icon-map-pin"
    | "pmf-icon-currency-pound"
    | "pmf-icon-calendar"
    | "pmf-icon-intensity"
    | null;
  img?: string | undefined;
  smallCard?: boolean;
  info?: string;
  loading?: boolean;
  desc?: string;
  valueNoWrap?: boolean;
  addSpace?: boolean;
  showRateLabel?: boolean;
}

const GrayCard: FC<IGrayCardProp> = ({
  topIcon,
  icon,
  title,
  value,
  img,
  smallCard,
  info,
  loading = false,
  desc,
  valueNoWrap = false,
  addSpace = false,
  showRateLabel = false,
}) => {
  const [openDesc, setOpenDesc] = useState(false);

  return (
    <>
      <div
        className={`${
          smallCard ? "py-3 px-3 mb-0" : "py-4 px-6 mb-4"
        } relative flex flex-col flex-1 items-center justify-start text-center rounded-[10px] bg-gray-100 h-full`}
      >
        {showRateLabel == true && (
          <div className="absolute -top-5 left-1/2 transform -translate-x-1/2 px-0 py-1 rounded-full w-20">
            <Badge colour={"brown"} title={"App Rate"} />
          </div>
        )}
        {topIcon && loading ? (
          <Skeleton width={20} className="me-2" />
        ) : (
          <i className={`text-xl ${topIcon}`}></i>
        )}
        <div
          className={`${
            addSpace ? "justify-between" : ""
          } h-full flex flex-col items-center`}
        >
          <h5
            className={`${
              smallCard ? "mb-[2px]" : "mb-2"
            } text-xs text-center block leading-snug tracking-normal text-darkBlue antialiased`}
          >
            <>
              {loading ? (
                <Skeleton width={50} className="me-2" />
              ) : (
                <> {title}</>
              )}
            </>
          </h5>

          <div className={`${addSpace ? "items-center h-[15px]" : ""} flex`}>
            {icon != null ? (
              <>
                {loading ? (
                  <Skeleton
                    width={15}
                    height={15}
                    className="me-2"
                    variant="circular"
                  />
                ) : (
                  <i className={`text-base me-1 ${icon}`}></i>
                )}
              </>
            ) : (
              <></>
            )}

            {img != undefined ? (
              <>
                {loading ? (
                  <Skeleton width={60} height={60} className="me-2" />
                ) : (
                  <img
                    src={toAbsoluteUrl("/assets/media/svg/" + img)}
                    className={`w-[14px] block mr-1`}
                    alt=""
                  />
                )}
              </>
            ) : (
              <></>
            )}

            <p
              className={`text-base block font-bold leading-relaxed text-inherit antialiased`}
              onClick={(e) => {
                e.stopPropagation();
                if (topIcon === "pmf-icon-map-pin")
                  window.open(
                    process.env.REACT_APP_GOOGLE_MAP_URL
                      ? process.env.REACT_APP_GOOGLE_MAP_URL + value
                      : "#",
                    "_blank"
                  );
              }}
            >
              {loading ? (
                <Skeleton width={30} className="me-2" />
              ) : (
                <span
                  className={`${
                    valueNoWrap
                      ? "whitespace-wrap leading-[20px]"
                      : "whitespace-nowrap"
                  } flex items-center justify-center `}
                >
                  {value}
                  {desc && (
                    <ClickAwayListener onClickAway={() => setOpenDesc(false)}>
                      <Tooltip
                        title={desc}
                        arrow
                        placement="top"
                        open={openDesc}
                        componentsProps={{
                          tooltip: {
                            style: {
                              fontSize: "10px",
                              lineHeight: "18px",
                              fontFamily: "Poppins, sans-serif",
                              maxWidth: "250px",
                              marginRight: "6px",
                            },
                          },
                        }}
                      >
                        <i
                          className="text-sm ms-1 pmf-icon-info cursor-pointer"
                          onClick={() => setOpenDesc(!openDesc)}
                        ></i>
                      </Tooltip>
                    </ClickAwayListener>
                  )}
                </span>
              )}
            </p>
          </div>
        </div>
        {info && (
          <div className="text-center text-xs leading-[12px] mt-2 whitespace-nowrap">
            {loading ? <Skeleton width={40} className="me-2" /> : <>{info}</>}
          </div>
        )}
      </div>
    </>
  );
};

export { GrayCard };
