import { FC, useState } from 'react'
import { deleteTransaction } from '../../../../core/services/payment-service'
import Modal from '../../shared/overlays/Modal';

interface IProps {
    show: boolean
    onClose: () => void,
    onSubmit: any,
    transactionId: any,
}

const DeleteMoneyModal: FC<IProps> = ({ show, onClose, onSubmit, transactionId }) => {

    const [loading, setLoading] = useState(false);

    const deleteTransactionItem = () => {
        setLoading(true);
        deleteTransaction(transactionId).then(() => {
            onSubmit();
            setLoading(false);
        }).catch(() => setLoading(false))
    }


    return (
        <>
            <form noValidate>
                <Modal
                    open={show}
                    onClose={onClose}
                    onSubmit={deleteTransactionItem}
                    submitText='Delete'
                    title="Delete activity"
                    description='Are you sure you want to delete this activity?'
                    disabled={loading}
                >
                </Modal >
            </form >
        </ >
    )
}

export { DeleteMoneyModal }
