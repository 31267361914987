import { FC } from "react";
import Modal from "../../../../shared/overlays/Modal";
import { toAbsoluteUrl } from "../../../../../helpers";

interface IMedicalConditionsDialogProps {
  show: any;
  onClose: any;
  medicalConditions: Array<any> | null | undefined;
}

const MedicalConditionsDialog: FC<IMedicalConditionsDialogProps> = (props) => {
  function closeModal() {
    props.onClose();
  }

  return (
    <>
      <Modal
        open={props.show}
        onClose={() => closeModal()}
        hideSubmit
        closeText="Close"
        title="Medical Conditions"
        titleIcon={toAbsoluteUrl("/assets/media/svg/medical-icon.svg")}
        disabled={false}
      >
        <div className="d-flex flex-column align-items-start justify-content-start">
          <ul className="">
            {props.medicalConditions?.map((item, i) => (
              <li key={i} className="flex flex-col border-b-2 last:border-b-0 border-[#E6E6E6]">
                <div className="flex justify-between align-items-center gap-x-6 py-3">
                  <div className="flex min-w-0 gap-x-4">
                    <div className="min-w-0 flex-auto">
                      <div className="min-w-0 flex-1 flex items-center">
                        <p className="text-base font-bold leading-6">
                          {item.medical_condition_name}
                        </p>
                      </div>
                      {item?.is_reportable == 1 && (
                        <div className="flex items-center gap-x-1.5">
                          <div className="flex items-center">
                            <span className="bg-yellow h-1.5 w-1.5 rounded-full flex me-1"></span>
                            <p className="text-xs leading-4">DVSA Reportable</p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </Modal>
    </>
  );
};

export default MedicalConditionsDialog;
