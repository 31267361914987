import { useFormik } from 'formik'
import { FC, useEffect, useState } from 'react'
import { Car } from '../../../../core/models/car'
import * as Yup from 'yup'
import { yearYup } from '../../shared/validations'
import { scrollToFirstError } from '../../../helpers/ScrollHelper';
import Modal from '../../shared/overlays/Modal'
import Input from '../../shared/forms/Input'
import Dropdown from '../../shared/forms/Dropdown'
import RadioInput from '../../shared/forms/RadioInput'
import RadioInputGroup from '../../shared/forms/RadioInputGroup'

interface IProps {
  show: any
  onClose: any
  car: Car
  fuelTypes: any
  transmissionTypes: any
  handleSubmittingCar: any
  parentWrapper?: string
}

const CarModal: FC<IProps> = (props) => {
  const [fuelTypeOptions, setFuelTypeOptions] = useState(props.fuelTypes);
  const [transmissionTypeOptions, setTransmissionTypeOptions] = useState(props.transmissionTypes);
  const requiredMsg = "This field is required";
  const validationSchema = Yup.object().shape({
    transmission_type_id: Yup.number().required(requiredMsg).nullable(),
    reg_number: Yup.string().required(requiredMsg).nullable(),
    car_year: yearYup.required(requiredMsg),
    make: Yup.string().required(requiredMsg).nullable(),
    model: Yup.string().required(requiredMsg).nullable(),
    fuel_type_id: Yup.number().required(requiredMsg).nullable(),
    color: Yup.string().required(requiredMsg).nullable(),
    has_brand_advertisement: Yup.number().required(requiredMsg).nullable(),
  })

  const formik = useFormik({
    initialValues: props.car,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      props.handleSubmittingCar(values).finally(() => {
        setSubmitting(false);
        formik.resetForm();
        props.onClose();
      });
    },
  })

  useEffect(() => {
    setFuelTypeOptions(
      Array.from(props.fuelTypes).map((type: any) => {
        return {
          value: type.id,
          label: type.fuel_type_name
        }
      }))
  }, [props.fuelTypes])

  useEffect(() => {
    setTransmissionTypeOptions(
      Array.from(props.transmissionTypes).map((type: any) => {
        return {
          value: type.id,
          label: type.trasmission_type_name
        }
      }))
  }, [props.transmissionTypes])

  useEffect(() => {
    formik.resetForm();
    formik.setValues(props.car);
  }, [props.show])


  useEffect(() => {
    scrollToFirstError(formik);
  }, [formik.errors, formik.isSubmitting, formik.isValidating]);

  return (
    <div>
      <form noValidate>
        <Modal
          open={props.show}
          onClose={props.onClose}
          onSubmit={formik.submitForm}
          submitText='Save'
          title={props.car.frontendId ? "Edit Car" : "Add Car"}
          disabled={formik.isSubmitting}
          reverseButtons={props.parentWrapper === "questionnaire"}
        >

          <RadioInputGroup
            {...formik.getFieldProps('transmission_type_id')}
            name="transmission_type_id"
            label="Transmission"
            options={transmissionTypeOptions}
            onChange={(e) => formik.setFieldValue('transmission_type_id', e)}
            value={formik.values.transmission_type_id}
            disabled={formik.isSubmitting}
            error={(formik.touched.transmission_type_id && formik.errors.transmission_type_id) ? true : false}
            errorMsg={formik.errors.reg_number} />

          <Input
            {...formik.getFieldProps('reg_number')}
            type='text'
            label='Car Registration'
            placeholder='Car Registration'
            error={(formik.touched.reg_number && formik.errors.reg_number) ? true : false}
            errorMsg={formik.errors.reg_number}
            required={true}
            name='reg_number'
            disabled={formik.isSubmitting}
            id='reg_number' 
            key={props.show ? 'open_reg_number' : 'closed_reg_number'}/>

          <Input
            {...formik.getFieldProps('car_year')}
            type='number'
            label='Year'
            placeholder='Year'
            error={(formik.touched.car_year && formik.errors.car_year) ? true : false}
            errorMsg={formik.errors.car_year}
            required={true}
            name='car_year'
            disabled={formik.isSubmitting}
            id='car_year' 
            key={props.show ? 'open_car_year' : 'closed_car_year'}/>

          <Input
            {...formik.getFieldProps('make')}
            type='text'
            label='Make'
            placeholder='Make'
            error={(formik.touched.make && formik.errors.make) ? true : false}
            errorMsg={formik.errors.make}
            required={true}
            name='make'
            disabled={formik.isSubmitting}
            id='make' 
            key={props.show ? 'open_make' : 'closed_make'}/>

          <Input
            {...formik.getFieldProps('model')}
            type='text'
            label='Model'
            placeholder='Model'
            error={(formik.touched.model && formik.errors.model) ? true : false}
            errorMsg={formik.errors.model}
            required={true}
            name='model'
            disabled={formik.isSubmitting}
            id='model'
            key={props.show ? 'open_model' : 'closed_model'} />

          <Dropdown
            {...formik.getFieldProps('fuel_type_id')}
            options={fuelTypeOptions}
            label='Fuel Type'
            placeholder='Fuel type'
            error={(formik.touched.fuel_type_id && formik.errors.fuel_type_id) ? true : false}
            errorMsg={formik.errors.fuel_type_id}
            required={true}
            name='fuel_type_id'
            disabled={formik.isSubmitting}
            id='fuel_type'
            value={fuelTypeOptions.find((t: any) => t.value === formik.values.fuel_type_id)}
            onChange={(e) => {
              formik.setFieldValue("fuel_type_id", e?.value)
            }}
            onBlur={() => {
              formik.setFieldTouched("fuel_type_id")
            }} />

          <Input
            {...formik.getFieldProps('color')}
            type='text'
            label='Colour'
            placeholder='Colour'
            error={(formik.touched.color && formik.errors.color) ? true : false}
            errorMsg={formik.errors.color}
            required={true}
            name='color'
            disabled={formik.isSubmitting}
            id='color' 
            key={props.show ? 'open_color' : 'closed_color'} />

          <hr />

          <RadioInput
            label='Does your car have any branding that advertises any driving tuition company other than ADI Network?'
            name="has_brand_advertisement"
            options={[
              { value: 1, label: "Yes" },
              { value: 2, label: "No" }
            ]}
            error={(formik.touched.has_brand_advertisement && formik.errors.has_brand_advertisement) ? true : false}
            errorMsg={formik.errors.has_brand_advertisement}
            onClick={(value) => formik.setFieldValue('has_brand_advertisement', value)}
            value={formik.values.has_brand_advertisement}
            disabled={formik.isSubmitting}
            required={true}
          />
        </Modal>
      </form>
    </div>
  )
}

export { CarModal }
