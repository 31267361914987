import { FC, useEffect, useState } from "react";
import { useFormik } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactGA from "react-ga4";
import { Course } from "../../../core/models/enums/course-intensity-enum";
import Modal from "../../components/shared/overlays/Modal";
import Dropdown from "../../components/shared/forms/Dropdown";
import Slider from "../../components/shared/forms/Slider";
import CheckboxInput from "../../components/shared/forms/CheckboxInput";
import Input from "../../components/shared/forms/Input";
import DisclosureItem from "../../components/shared/elements/DisclosureItem";
import Alert from "../../components/shared/overlays/Alert";
import { CompleteQuestionnaireDataModal } from "../../components/account-settings/general-questionnaire-data/CompleteQuestionnaireDataModal";

interface IProps {
  show: any;
  onHide: any;
  searchObject: any;
  defaultSearchResetValues: any;
  handleSearch: (filterParams: any) => void;
  minHourRate: any;
  maxHourRate: any;
  getMinAndMaxHourRates: () => void
}

const FilterJobsDialog: FC<IProps> = (props) => {
  const [showQuestionnaire, setShowQuestionnaire] = useState<boolean>(false);

  const defaultDistance = 15;
  const maxDistance = 40;
  const step = 2;
  const minDistance = 1;
  const minRate = props.minHourRate;
  const maxRate = props.maxHourRate;

  const rateStep = 1;

  useEffect(() => {
    setRate([
      props.searchObject.from_rate &&
        props.searchObject.from_rate >= props.minHourRate
        ? props.searchObject.from_rate
        : props.minHourRate,
    ])
  }, [props.minHourRate, props.maxHourRate])
  

  const formik = useFormik({
    initialValues: {
      from_hour:
        props.searchObject.from_hour ??
        props.defaultSearchResetValues.from_hour,
      to_hour:
        props.searchObject.to_hour ?? props.defaultSearchResetValues.to_hour,
      from_rate:
        props.searchObject.from_rate !== undefined
          ? props.searchObject.from_rate
          : props.defaultSearchResetValues.from_rate,
      from_distance:
        props.searchObject.from_distance ??
        props.defaultSearchResetValues.from_distance,
      to_distance:
        props.searchObject.to_distance ??
        props.defaultSearchResetValues.to_distance,
      exam_from_date:
        props.searchObject.exam_from_date ??
        props.defaultSearchResetValues.exam_from_date,
      exam_to_date:
        props.searchObject.exam_to_date ??
        props.defaultSearchResetValues.exam_to_date,
      my_test_centers:
        props.searchObject.my_test_centers ??
        props.defaultSearchResetValues.my_test_centers,
      include_hidden_jobs:
        props.searchObject.include_hidden_jobs ??
        props.defaultSearchResetValues.include_hidden_jobs,
      include_distance:
        props.searchObject.include_distance ??
        props.defaultSearchResetValues.include_distance,
      is_new_only:
        props.searchObject.is_new_only ??
        props.defaultSearchResetValues.is_new_only,
      is_active_bid:
        props.searchObject.is_active_bid ??
        props.defaultSearchResetValues.is_active_bid,
      course_intensity:
        props.searchObject.course_intensity ??
        props.defaultSearchResetValues.course_intensity,
    },
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      props.handleSearch(values);
      setSubmitting(false)
    },
  });

  useEffect(() => {
    formik.setValues(props.searchObject);
    ReactGA.event("filter_new_jobs");
  }, []);

  const hourSearch = [
    { value: 5, label: "5" },
    { value: 10, label: "10" },
    { value: 15, label: "15" },
    { value: 20, label: "20" },
    { value: 30, label: "30" },
    { value: 40, label: "40+" },
  ];

  const [startDate, setStartDate] = useState(
    props.searchObject.exam_from_date
      ? new Date(props.searchObject.exam_from_date)
      : null
  );
  const [endDate, setEndDate] = useState(
    props.searchObject.exam_to_date
      ? new Date(props.searchObject.exam_to_date)
      : null
  );

  const setFromDateValue = (e: any) => {
    if (e != null) {
      formik.setFieldValue(
        "exam_from_date",
        e.getFullYear() + "-" + (e.getMonth() + 1) + "-" + e.getDate()
      );
    } else {
      formik.setFieldValue("exam_from_date", null);
    }
    setStartDate(e);
  };

  const setToDateValue = (e: any) => {
    if (e != null) {
      formik.setFieldValue(
        "exam_to_date",
        e.getFullYear() + "-" + (e.getMonth() + 1) + "-" + e.getDate()
      );
    } else {
      formik.setFieldValue("exam_to_date", null);
    }
    setEndDate(e);
  };

  const [values, setDistanceValues] = useState([
    props.searchObject.to_distance
      ? props.searchObject.to_distance
      : defaultDistance,
  ]);

  const [rate, setRate] = useState([
    props.searchObject.from_rate &&
      props.searchObject.from_rate >= props.minHourRate
      ? props.searchObject.from_rate
      : props.minHourRate,
  ]);

  const reset = () => {
    let resetObj = props.defaultSearchResetValues;
    formik.resetForm({ values: props.defaultSearchResetValues });
    setFromDateValue(null);
    setToDateValue(null);
    let rateVal =
      props.defaultSearchResetValues.from_rate &&
        props.defaultSearchResetValues.from_rate >= props.minHourRate
        ? props.defaultSearchResetValues.from_rate
        : props.minHourRate;
    setRate([rateVal]);
    setDistanceValues([props.defaultSearchResetValues.to_distance]);
    formik.setFieldValue(
      "is_new_only",
      props.defaultSearchResetValues.is_new_only
    );
    formik.setFieldValue(
      "my_test_centers",
      props.defaultSearchResetValues.my_test_centers
    );
    formik.setFieldValue(
      "include_distance",
      props.defaultSearchResetValues.include_distance
    );
    formik.setFieldValue(
      "is_active_bid",
      props.defaultSearchResetValues.is_active_bid
    );
    formik.setFieldValue(
      "course_intensity",
      props.defaultSearchResetValues.course_intensity
    );
    props.handleSearch(resetObj);
  };

  const onCourseIntensityChanged = (e: any) => {
    formik.setFieldValue("course_intensity", e);
  };
  const onTestCentresChanged = (e: any) => {
    if (e.target.checked) {
      formik.setFieldValue("my_test_centers", 1);
    } else {
      formik.setFieldValue("my_test_centers", 0);
    }
  };
  const onNewJobChanged = (e: any) => {
    if (e.target.checked) {
      formik.setFieldValue("is_new_only", 1);
    } else {
      formik.setFieldValue("is_new_only", 0);
    }
  };

  const onActiveJobBidChanged = (e: any) => {
    if (e.target.checked) {
      formik.setFieldValue("is_active_bid", 1);
    } else {
      formik.setFieldValue("is_active_bid", 0);
    }
  };

  const onIncludeDistanceChanged = (e: any) => {
    if (e.target.checked) {
      formik.setFieldValue("include_distance", 1);
      formik.setFieldValue("to_distance", defaultDistance);
      formik.setFieldValue("from_distance", 1);
    } else {
      formik.setFieldValue("include_distance", 0);
      formik.setFieldValue("to_distance", null);
      formik.setFieldValue("from_distance", null);
    }
  };

  const onDistanceChanged = (e: any) => {
    setDistanceValues(e);
    formik.setFieldValue("to_distance", e[0]);
  };

  const onRateSliderChange = (e: any) => {
    setRate(e);
    formik.setFieldValue("from_rate", e[0]);
  };

  const onHiddenJobsChanged = (e: any) => {
    if (e.target.checked) {
      formik.setFieldValue("include_hidden_jobs", 1);
    } else {
      formik.setFieldValue("include_hidden_jobs", 0);
    }
  };

  return (
    <>
      <form noValidate>
        <Modal
          open={props.show}
          onClose={() => {
            reset();
            props.onHide();
          }}
          onDismiss={props.onHide}
          onSubmit={() => {
            formik.submitForm();
            props.onHide();
          }}
          closeText={"Clear filter"}
          submitText={"Show Results"}
          title={"Filter by"}
        >
          {/* ---- */}
          <div
            className="flex flex-col"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex flex-col">
            <DisclosureItem title="Number of Hours" disclosureCard disclosureCardSize="xs">
              <div className="grid grid-cols-2 gap-4">
                <Dropdown
                  {...formik.getFieldProps("from_hour")}
                  options={hourSearch}
                  label="From"
                  placeholder=""
                  required={false}
                  name="from_hour"
                  disabled={formik.isSubmitting}
                  id="from_hour"
                  value={hourSearch.find((t: any) => t.value == formik.values.from_hour)}

                  onChange={(e) => {

                    formik.setFieldValue("from_hour", e?.value);
                  }}
                  onBlur={() => {
                    formik.setFieldTouched("from_hour");
                  }}
                />
                <Dropdown
                  {...formik.getFieldProps("to_hour")}
                  options={hourSearch}
                  label="To"
                  placeholder=""
                  required={false}
                  name="to_hour"
                  disabled={formik.isSubmitting}
                  id="to_hour"
                  value={
                    hourSearch.find(
                      (t) => t.value == formik.values.to_hour
                    )
                  }
                  onChange={(e) => {
                    formik.setFieldValue("to_hour", e?.value);
                  }}
                  onBlur={() => {
                    formik.setFieldTouched("to_hour");
                  }}
                />
              </div>
              </DisclosureItem>
            </div>

            <div className="flex flex-col">
            <DisclosureItem title="Minimum Hourly Rate" disclosureCard disclosureCardSize="xs">
              {typeof props.minHourRate === "string" || typeof props.maxHourRate === "string" ?
              <div className="mt-2">
                <Alert
                    customDesc={<span className="h-fit me-2">Please upload your badge to unlock course rates</span>}
                    colour="gray"
                    imgSrc="gif/doc-icon.gif"
                    buttons={[
                      {
                        onClick: () => setShowQuestionnaire(true),
                        title: "",
                      },
                    ]}
                  />
              </div> :
              <Slider
                decreaseFunction={() => { }}
                increaseFunction={() => { }}
                step={rateStep}
                minValue={props.minHourRate}
                maxValue={props.maxHourRate}
                rangeValues={
                  rate < props.minHourRate
                    ? props.minHourRate
                    : rate > props.maxHourRate
                      ? props.maxHourRate
                      : rate
                }
                onChange={onRateSliderChange}
                showInput={false}
                hideButtons={true}
                formik={formik}
                formatFun={(index: number) => Number.isInteger(+rate[index]) ?  rate[index] : (+rate[index])?.toFixed(2)}
                minValueFormatted={`£${Number.isInteger(+minRate) ?  minRate : (+minRate)?.toFixed(2)}`}
                maxValueFormatted={`£${Number.isInteger(+maxRate) ?  maxRate : (+maxRate)?.toFixed(2)}`}
              />}
              </DisclosureItem>
            </div>

            <div className="">
            <DisclosureItem title="Course Intensity" disclosureCard disclosureCardSize="xs">
              <CheckboxInput
                label=""
                name="course_intensity"
                onChange={(e) => onCourseIntensityChanged(e)}
                disabled={formik.isSubmitting}
                required={false}
                options={[
                  { label: "Intensive", value: Course.Intensive },
                  { label: "Semi-Intensive", value: Course.SemiIntensive },
                  { label: "Relaxed", value: Course.Relaxed },
                ]}
                values={formik.values.course_intensity}
                classNameOpt='flex justify-between'
              />
              </DisclosureItem>
            </div>
          </div>
          <div className="flex flex-col">
          <DisclosureItem title="Test Date" disclosureCard disclosureCardSize="xs">
            <div className="grid grid-cols-2 gap-x-4">
              <DatePicker
                selected={startDate}
                dateFormat={"dd/MM/yyyy"}
                isClearable={true}
                name="exam_from_date"
                id="exam_from_date"
                onChange={(update) => {
                  setFromDateValue(update);
                }}
                customInput={
                  <Input
                    type='text'
                    label='From'
                    placeholder='From'
                    readonly={true}
                    name='from' />
                }
                calendarClassName="w-full pt-5"
                clearButtonClassName="!h-fit !top-[42%] after:!bg-white after:border after:border-darkBlue after:!text-darkBlue"
                popperClassName="!static !pt-0 w-full !translate-x-0"
                wrapperClassName="-mb-3"
              />
              <DatePicker
                selected={endDate}
                dateFormat={"dd/MM/yyyy"}
                isClearable={true}
                name="exam_to_date"
                id="exam_to_date"
                onChange={(update) => {
                  setToDateValue(update);
                }}
                customInput={
                  <Input
                    type='text'
                    label='To'
                    placeholder='To'
                    readonly={true}
                    name='to' />
                }
                calendarClassName="w-full pt-5"
                clearButtonClassName="!h-fit !top-[42%] after:!bg-white after:border after:border-darkBlue after:!text-darkBlue"
                popperClassName="!static !pt-0 w-full !translate-x-0"
                wrapperClassName="-mb-3"
              />
            </div>
            </DisclosureItem>
          </div>

          <CheckboxInput
            name="include_distance"
            required={false}
            label="Filter by distance"
            isSingleCheckbox={true}
            checked={formik.values.include_distance == 1}
            onChange={(e) => onIncludeDistanceChanged(e)}
          />

          {formik.values.include_distance == 1 && (
            <div className="mb-3">
              <Slider
                decreaseFunction={() => { }}
                increaseFunction={() => { }}
                step={rateStep}
                minValue={minDistance}
                maxValue={maxDistance}
                rangeValues={values}
                onChange={onDistanceChanged}
                showInput={false}
                hideButtons={true}
                formik={formik}
                formatFun={(index: number) => values[index]}
                minValueFormatted={`${minDistance} miles`}
                maxValueFormatted={`${maxDistance} miles`}
              />
            </div>
          )}

          <CheckboxInput
            name="my_test_centers"
            required={false}
            label="Only show tests booked at my preferred test centres"
            isSingleCheckbox={true}
            checked={formik.values.my_test_centers == 1}
            onChange={(e) => onTestCentresChanged(e)}
          />

          <CheckboxInput
            required={false}
            name="is_new_only"
            label="Show new jobs only"
            isSingleCheckbox={true}
            disabled={formik.values.include_hidden_jobs == 1}
            checked={formik.values.is_new_only == 1}
            onChange={(e) => onNewJobChanged(e)}
          />

          <CheckboxInput
            required={false}
            name="include_hidden_jobs"
            label="Show hidden jobs"
            isSingleCheckbox={true}
            disabled={formik.values.is_new_only == 1}
            checked={formik.values.include_hidden_jobs == 1}
            onChange={(e) => onHiddenJobsChanged(e)}
          />

          <CheckboxInput
            required={false}
            name="is_active_bid"
            label="Show active bids"
            isSingleCheckbox={true}
            checked={formik.values.is_active_bid == 1}
            onChange={(e) => onActiveJobBidChanged(e)}
          />

          {/* ---- */}
        </Modal>
      </form>

      <CompleteQuestionnaireDataModal
        show={showQuestionnaire}
        onClose={() => {
          setShowQuestionnaire(false);
          props.getMinAndMaxHourRates();
        }}
        goToDashboard={false}
      />
    </>
  );
};

export { FilterJobsDialog };
