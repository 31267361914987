import { FC, useEffect, useState } from "react";
import {
  authorizeGoogleCalendar,
  authorizeOutlookCalendar,
  getGoogleCalendarSettings,
  getOutlookCalendarSettings,
  syncGoogleCalendar,
  syncOutlookCalendar,
} from "../../../../core/services/adi-service";
import { toAbsoluteUrl } from "../../../helpers";
import Button from "../../shared/elements/Button";
import PageHeader from "../../shared/elements/PageHeader";
import {
  CheckCircleIcon,
  CheckIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  LinkIcon,
} from "@heroicons/react/20/solid";
import { Skeleton } from "@mui/material";
import toast from "react-hot-toast";
import Notification from "../../shared/overlays/Notification";

const CalendarIntegration: FC = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const [loading, setLoading] = useState(false);
  const [googleConnecting, setGoogleConnecting] = useState(false);
  const [outlookConnecting, setOutlookConnecting] = useState(false);
  const [integrationOptions, setIntegrationOptions] = useState([
    {
      img: "google-calendar.png",
      title: "Google Calendar",
      desc: "Link your Google Calendar to ADI Network Calendar",
      isConnected: false,
    },
    {
      img: "outlook.png",
      title: "Outlook Calendar",
      desc: "Link your Outlook Calendar to ADI Network Calendar",
      isConnected: false,
    },
  ]);
  let code = urlSearchParams.get("x");
  let scope = urlSearchParams.get("scope");

  useEffect(() => {
    setLoading(true);
    getSettings();
    if (code && scope) {
      connect(0);
    } else if (code && !scope) {
      connect(1);
    }
  }, []);

  const connect = (type: number) => {
    let loadingId = toast.custom(
      () => (
        <Notification
          colour="gray"
          title={"Connecting with your calendar..."}
          description={""}
          Icon={ExclamationCircleIcon}
        />
      ),
      {
        duration: 5000,
        position: "top-center",
      }
    );

    if (type === 0) {
      setGoogleConnecting(true);
      authorizeGoogleCalendar(code && scope ? { code: code } : null)
        .then((res) => {
          setGoogleConnecting(false);
          if (res.data.results.redirectUrl) {
            window.location.href = res.data.results.redirectUrl;
          } else if (code) {
            getSettings();
            syncGoogleEvents();
          }
          toast.custom(
            () => (
              <Notification
                colour="green"
                title={"Saved successfully"}
                description={""}
                Icon={CheckCircleIcon}
              />
            ),
            {
              duration: 5000,
              position: "top-center",
              id: loadingId,
            }
          );
        })
        .catch(() => {
          setGoogleConnecting(false);
          toast.custom(
            () => (
              <Notification
                colour="red"
                title={"Failed to Connect your google calendar!"}
                description={""}
                Icon={ExclamationTriangleIcon}
              />
            ),
            {
              duration: 5000,
              position: "top-center",
              id: loadingId,
            }
          );
        });
    } else {
      setOutlookConnecting(true);
      authorizeOutlookCalendar(code && !scope ? { code: code } : null)
        .then((res) => {
          setOutlookConnecting(false);
          if (res.data.results.redirectUrl) {
            window.location.href = res.data.results.redirectUrl;
          } else if (code) {
            getSettings();
            syncOutlookEvents();
          }
          toast.custom(
            () => (
              <Notification
                colour="green"
                title={"Saved successfully"}
                description={""}
                Icon={CheckCircleIcon}
              />
            ),
            {
              duration: 5000,
              position: "top-center",
              id: loadingId,
            }
          );
        })
        .catch(() => {
          setOutlookConnecting(false);
          toast.custom(
            () => (
              <Notification
                colour="red"
                title={"Failed to Connect your outlook calendar!"}
                description={""}
                Icon={ExclamationTriangleIcon}
              />
            ),
            {
              duration: 5000,
              position: "top-center",
              id: loadingId,
            }
          );
        });
    }
  };

  const getSettings = async () => {
    let temp = [...integrationOptions];
    const googlePromise = getGoogleCalendarSettings().then((res) => {
      temp[0].isConnected = res.data.results.has_google_calendar;
    });
    const outlookPromise = getOutlookCalendarSettings().then((res) => {
      temp[1].isConnected = res.data.results.has_outlook_calendar;
    });

    Promise.all([googlePromise, outlookPromise])
      .then(() => {
        setLoading(false);
        setIntegrationOptions(temp);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const syncGoogleEvents = () => {
    syncGoogleCalendar().then((res) => {
      console.log(res.data.results);
    });
  };

  const syncOutlookEvents = () => {
    syncOutlookCalendar().then((res) => {
      console.log(res.data.results);
    });
  };

  return (
    <>
      <div className="mb-5">
        <PageHeader
          title="Calendar Integration"
          backToURL="/ADI/settings/overview"
        />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 px-5">
        {loading ? (
          <>
            {integrationOptions.map((option, index) => (
              <div className="bg-white rounded-lg p-3" key={index}>
                <div className="flex items-center mb-3">
                  <Skeleton width={60} height={60} animation="wave"></Skeleton>
                  <span className="ms-3 w-full">
                    <Skeleton
                      width={130}
                      height={30}
                      animation="wave"
                    ></Skeleton>
                    <Skeleton
                      className="w-full"
                      height={30}
                      animation="wave"
                    ></Skeleton>
                  </span>
                </div>
                <Skeleton
                  height={25}
                  variant="rounded"
                  className="w-full mb-1 !rounded-full"
                  animation="wave"
                ></Skeleton>
              </div>
            ))}
          </>
        ) : (
          <>
            {integrationOptions.map((option, index) => (
              <div key={index} className="bg-white rounded-lg p-3">
                <div className="flex items-center mb-3">
                  <span className="h-12 w-12 flex-none bg-gray-50 me-3">
                    <img
                      className=""
                      src={toAbsoluteUrl(
                        "/assets/media/svg/integration/" + option.img
                      )}
                      alt=""
                    />
                  </span>
                  <span>
                    <p className="text-sm font-bold mb-[2px]">{option.title}</p>
                    <p className="text-sm">{option.desc}</p>
                  </span>
                </div>
                {option.isConnected ? (
                  <Button
                    disabled={true}
                    colour="darkGray"
                    fullWidth
                    size="large"
                  >
                    <span className="flex items-center">
                      <CheckIcon className="w-4 h-4" />{" "}
                      <span className="ms-1">Connected</span>
                    </span>
                  </Button>
                ) : (
                  <Button
                    colour="yellow"
                    fullWidth
                    size="large"
                    disabled={
                      index === 0 ? googleConnecting : outlookConnecting
                    }
                    onClick={() => connect(index)}
                  >
                    {(index === 0 ? googleConnecting : outlookConnecting) ? (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    ) : (
                      <span className="flex items-center">
                        {" "}
                        <LinkIcon className="w-4 h-4" />
                        <span className="ms-1">Connect</span>
                      </span>
                    )}
                  </Button>
                )}
                {/* <StackedCardWithActionsMenu
                                pillTitle={null}
                                title={option.title}
                                imageUrl={'/assets/media/svg/integration/' + option.img}
                                description={option.desc}
                                showButtonAction={true}
                                actions={[
                                    option.isConnected ?
                                        <Button disabled={true} colour="darkGray">
                                            <span className="flex items-center">
                                                <CheckIcon className="w-4 h-4" /> <span className="ms-1">Connected</span>
                                            </span>
                                        </Button>
                                        :
                                        <Button
                                            colour="yellow"
                                            disabled={index === 0 ? googleConnecting : outlookConnecting}
                                            onClick={() => connect(index)}
                                        >
                                            {(index === 0 ? googleConnecting : outlookConnecting) ? (
                                                <span className="indicator-progress" style={{ display: "block" }}>
                                                    Please wait...
                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </span>)
                                                : <span className="flex items-center"> <LinkIcon className="w-4 h-4" /><span className="ms-1">Connect</span></span>}
                                        </Button>
                                ]}
                            ></StackedCardWithActionsMenu> */}
              </div>
            ))}
          </>
        )}
      </div>
    </>
  );
};

export { CalendarIntegration };
