import axios, { AxiosResponse } from "axios";

import { AboutDataModel } from "../models/about-data-model";
import { BankDetailsModel } from "../models/bank-details-model";
import { BaseResponse } from "../models/base-response";
import { Car } from "../models/car";
import { DayModel } from "../models/day-model";
import { DocumentDataModel } from "../models/document-data-model";
import { DrivingTuitionModel } from "../models/driving-tuition-model";
import { FuelType } from "../models/fuel-type";
import { HolidayModel } from "../models/holiday-model";
import { JobPreferenceDataModel } from "../models/job-preference-data-model";
import { PersonalDataModel } from "../models/personal-data-model";
import { SavedStepsModel } from "../models/saved-steps-model";
import { SubmitPersonalDataModel } from "../models/submit-personal-data-model";
import { TestCenterDataModel } from "../models/test-center-data-model";
import { TransmissionTypeModel } from "../models/transmission-type-model";
import { CancellationPolicyModel } from "../models/cancellation-policy-model";
import { AdiRateRequest } from "../models/rates/adi-rate-request";
import modifiedFetch from "../../../setup/axios/ModifiedFetch";
import { AdiCancellationRequest } from "../models/rates/adi-cancellation-request";
const API_URL = process.env.REACT_APP_BASE_URL;
const TEST_CENTERS_URL = process.env.REACT_APP_TEST_CENTERS_BASE_URL;
const DOCUMENTS_URL = process.env.REACT_APP_DOCUMENTS_BASE_URL;
const HOLIDAY_URL = process.env.REACT_APP_HOLIDAY_BASE_URL;
const CALENDAR_URL = process.env.REACT_APP_CALENDARS_BASE_URL;
const NOTIFICATION_URL = process.env.REACT_APP_NOTIFICATION_BASE_URL;
const TRACKING_URL = process.env.REACT_APP_TRACKING_BASE_URL;

export const GET_PERSONAL_DATA_URL = `${API_URL}/adis/get-personal-data`;
export const UPDATE_PERSONAL_DATA_URL = `${API_URL}/adis/update-personal-data`;
export const GET_CITIES_URL = `${API_URL}/cities`;
export const GET_GENDERS_URL = `${API_URL}/genders`;
export const GET_LANGUAGES_URL = `${API_URL}/languages`;
export const GET_ABOUT_DATA_URL = `${API_URL}/adis/get-about-data`;
export const GET_CONTACT_DATA_URL = `${API_URL}/adis/get-contact-id`;
export const UPDATE_ABOUT_DATA_URL = `${API_URL}/adis/update-about-data`;
export const GET_DRIVING_TUITION_URL = `${API_URL}/adis/get-adi-driving-tuition`;
export const GET_BADGE_STATUS_URL = `${API_URL}/adis/check-expired-badge`;
export const GET_HAS_BADGE_URL = `${API_URL}/adis/get-adi-has-approved-badge`;
export const UPDATE_DRIVING_TUITION_URL = `${API_URL}/adis/update-adi-driving-tuition`;
export const GET_BANK_DETAILS_URL = `${API_URL}/adis/get-bank-details`;
export const UPDATE_BANK_DETAILS_URL = `${API_URL}/adis/update-bank-details`;
export const GET_ADI_CARS_URL = `${API_URL}/adi-cars`;
export const GET_FUEL_TYPES_URL = `${API_URL}/fuel-types`;
export const GET_TRANSMISSION_TYPES_URL = `${API_URL}/transmission-types`;
export const UPDATE_ADI_CARS_URL = `${API_URL}/adi-cars`;
export const GET_JOB_PREFRENCES_URL = `${API_URL}/adis/get-job-preferences`;
export const GET_TEST_CENTERS_URL = `${TEST_CENTERS_URL}/practical-test-centers`;
export const UPDATE_JOB_PREFRENCES_URL = `${API_URL}/adis/update-job-preferences`;
export const UPDATE_ADI_RATES_URL = `${API_URL}/adis/update-adi-rates`;
export const UPDATE_ADI_CANCELLATION_URL = `${API_URL}/adis/update-adi-cancellation`;
export const GET_ADI_RATES_URL = `${API_URL}/adis/adi-rates`;
export const GET_SAVED_STEPS_URL = `${API_URL}/adis/get-saved-steps`;
export const GET_RATE_TRANSMISSION_TYPES_URL = `${API_URL}/rates-transmission-types`;
export const GET_DOCUMENTS_URL = `${DOCUMENTS_URL}/documents`;
export const GET_CAN_ACCEPT_JOBS_URL = `${API_URL}/adi-can-accept-jobs`;
export const ADD_DOCUMENT_URL = `${DOCUMENTS_URL}/documents`;
export const ADD_INSURANCE_URL = `${DOCUMENTS_URL}/insurances`;
export const ADD_BAGDE_URL = `${DOCUMENTS_URL}/badges`;
export const UPLOAD_DOCUMENT_URL = `${DOCUMENTS_URL}/upload`;
export const GET_HOLIDAYS_URL = `${HOLIDAY_URL}/cognito`;
export const GET_Days_URL = `${API_URL}/days`;
export const GET_ADI_HAS_PENDDING_DOCUMENT = `${API_URL}/adis/has-pendding-document`;
export const GET_LATEST_BADGE_URL = `${DOCUMENTS_URL}/latest-badge`;
export const GET_LATEST_INSURANCE_URL = `${DOCUMENTS_URL}/latest-insurance`;
export const ADI_PICTURE_URL = `${DOCUMENTS_URL}/pictures`;
export const DELETE_MY_ACCOUNT_URL = `${API_URL}/delete-my-account`;
export const GET_NEW_JOB_REMINDER_URL = `${API_URL}/get-new-job-reminder`;
export const UPDATE_NEW_JOB_REMINDER_URL = `${API_URL}/update-new-job-reminder`;
export const GET_CUSTOM_JOB_REMINDER_URL = `${API_URL}/get-customized-job-reminder`;
export const UPDATE_CUSTOM_JOB_REMINDER_URL = `${API_URL}/update-customized-job-reminder`;
export const CUSTOM_JOB_ALERT_URL = `${API_URL}/adi-alert`;
export const ADI_TEST_CENTER_AND_TRANSMISSION_URL = `${API_URL}/get-adi-test-centre-with-transmission`;

export const GET_GOOGLE_SETTINGS_URL = `${CALENDAR_URL}/get-google-adi-settings`;
export const GET_GOOGLE_EVENTS_URL = `${CALENDAR_URL}/get-google-events`;
export const AUTHORIZE_GOOGLE_URL = `${CALENDAR_URL}/authorize-google-calendar`;
export const SYNC_GOOGLE_CALENDAR_URL = `${CALENDAR_URL}/sync-google-events`;

export const GET_OUTLOOK_SETTINGS_URL = `${CALENDAR_URL}/get-outlook-adi-settings`;
export const GET_OUTLOOK_EVENTS_URL = `${CALENDAR_URL}/get-outlook-events`;
export const AUTHORIZE_OUTLOOK_URL = `${CALENDAR_URL}/authorize-outlook-calendar`;
export const SYNC_OUTLOOK_CALENDAR_URL = `${CALENDAR_URL}/sync-outlook-events`;

export const GET_CANCELLATION_POLICIES = `${API_URL}/cancellation-policies`;

export const GET_HAS_BANK_DETAILS = `${API_URL}/check-has-bank-details`;

export const GET_HAS_REQUIRED_DOCS = `${API_URL}/check-has-required-documents`;

export const ADD_PROMOTION_CODE_URL = `${API_URL}/promotion-code/create`;
export const GET_LATEST_PROMOTION_CODE = `${API_URL}/promotion-code/latest`;
export const GET_REFERRER = `${API_URL}/promotion-code/load-referrer`;

export const GET_NOTIFICATION_SETTINGS_URL = `${NOTIFICATION_URL}/get-adi-notification-action-setting`;
export const UPDATE_NOTIFICATION_SETTINGS_URL = `${NOTIFICATION_URL}/update-adi-notification-setting`;
export const CHECK_IN_NEW_YEAR_URL = `${API_URL}/check-in-new-year`;
export const SKIP_STRIPE_URL = `${API_URL}/adis/set-stripe-skipped`;
export const SKIP_JOB_PREFERENCES_URL = `${API_URL}/adis/set-job-preferences-skipped`;
export const CHECK_IS_FIRST_LOGIN_URL = `${API_URL}/adis/check-first-login`;
export const CHANGE_IS_FIRST_LOGIN_URL = `${API_URL}/adis/change-first-login`;
export const QUESTIONNARE_PROGRESS_URL = `${API_URL}/adis/questionnare-progress`;
export const CHECK_COMPLETED_QUESTIONNARE_URL = `${API_URL}/adis/questionnare-status`;
export const CHECK_HAS_CANCELLATION_POLICY_URL = `${API_URL}/adis/check-has-cancellation-policy`;

export const TRACK_PAGES_URL = `${TRACKING_URL}/track/adi`;

export const GET_MOBILE_APP_VERSION = `${API_URL}/latest-mobile-version`;
export const ADD_TO_WISH_LIST_URL = `${API_URL}/add-wishlist`;
export const MARK_ARTICLE_AS_READ = `${API_URL}/articles/mark-as-read`;
export const GET_UNREAD_ARTICLE_NUMBER = `${API_URL}/articles/un-read-count`;
const API_KEY = process.env.REACT_APP_X_API_KEY;

export function getPersonalData(): Promise<
  AxiosResponse<BaseResponse<PersonalDataModel>>
> {
  return axios.get<BaseResponse<PersonalDataModel>>(GET_PERSONAL_DATA_URL);
}
export function getAboutData(): Promise<
  AxiosResponse<BaseResponse<AboutDataModel>>
> {
  return axios.get<BaseResponse<any>>(GET_ABOUT_DATA_URL);
}
export function getCities(): Promise<AxiosResponse<BaseResponse<Array<any>>>> {
  return axios.get<BaseResponse<Array<any>>>(GET_CITIES_URL);
}
export function getGenders(): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.get<BaseResponse<any>>(GET_GENDERS_URL);
}
export function getLanguages(): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.get<BaseResponse<any>>(GET_LANGUAGES_URL);
}

export function getMobileVersion(): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.get<BaseResponse<any>>(GET_MOBILE_APP_VERSION);
}

export function updatePersonalData(
  data: SubmitPersonalDataModel
): Promise<AxiosResponse<BaseResponse<PersonalDataModel>>> {
  return axios.post<BaseResponse<any>>(UPDATE_PERSONAL_DATA_URL, data);
}
export function updateAboutData(
  data: any
): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.post<BaseResponse<any>>(UPDATE_ABOUT_DATA_URL, data);
}

export function getDrivingTuition(): Promise<
  AxiosResponse<BaseResponse<DrivingTuitionModel>>
> {
  return axios.get<BaseResponse<DrivingTuitionModel>>(GET_DRIVING_TUITION_URL);
}
export function checkCanAddLesson(): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.get<BaseResponse<any>>(GET_BADGE_STATUS_URL);
}
export function updateDrivingTuition(
  data: any
): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.post<BaseResponse<any>>(UPDATE_DRIVING_TUITION_URL, data);
}
export function getBankDetails(): Promise<
  AxiosResponse<BaseResponse<BankDetailsModel>>
> {
  return axios.get<BaseResponse<BankDetailsModel>>(GET_BANK_DETAILS_URL);
}
export function updateBankDetails(
  data: any
): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.post<BaseResponse<any>>(UPDATE_BANK_DETAILS_URL, data);
}
export function updateAdiCars(
  data: any
): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.post<BaseResponse<any>>(UPDATE_ADI_CARS_URL, data);
}

export function getCars(): Promise<AxiosResponse<BaseResponse<Array<Car>>>> {
  return axios.get<BaseResponse<Array<Car>>>(GET_ADI_CARS_URL);
}
export function getFuelTypes(): Promise<
  AxiosResponse<BaseResponse<Array<FuelType>>>
> {
  return axios.get<BaseResponse<Array<FuelType>>>(GET_FUEL_TYPES_URL);
}
export function getTransmissionTypes(): Promise<
  AxiosResponse<BaseResponse<Array<TransmissionTypeModel>>>
> {
  return axios.get<BaseResponse<Array<TransmissionTypeModel>>>(
    GET_TRANSMISSION_TYPES_URL
  );
}

export function getTestCenters(): Promise<
  AxiosResponse<BaseResponse<Array<TestCenterDataModel>>>
> {
  return axios.get<BaseResponse<Array<TestCenterDataModel>>>(
    GET_TEST_CENTERS_URL,

    {
      headers: {
        "x-api-key": API_KEY!,
      },
    }
  );
}
export function getJobPrefrences(): Promise<
  AxiosResponse<BaseResponse<JobPreferenceDataModel>>
> {
  return axios.get<BaseResponse<JobPreferenceDataModel>>(
    GET_JOB_PREFRENCES_URL
  );
}
export function updateJobPrefrences(
  data: JobPreferenceDataModel
): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.post<BaseResponse<any>>(UPDATE_JOB_PREFRENCES_URL, data);
}

export function updateAdiRates(
  data: AdiRateRequest
): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.post<BaseResponse<any>>(UPDATE_ADI_RATES_URL, data);
}
export function updateAdiCancellation(
  data: AdiCancellationRequest
): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.post<BaseResponse<any>>(UPDATE_ADI_CANCELLATION_URL, data);
}
export function getAdiRates(): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.get<BaseResponse<any>>(GET_ADI_RATES_URL);
}
export function getSavedSteps(): Promise<
  AxiosResponse<BaseResponse<SavedStepsModel>>
> {
  return axios.get<BaseResponse<SavedStepsModel>>(GET_SAVED_STEPS_URL);
}
export function getRateTransmissionTypes(): Promise<
  AxiosResponse<BaseResponse<Array<TransmissionTypeModel>>>
> {
  return axios.get<BaseResponse<Array<TransmissionTypeModel>>>(
    GET_RATE_TRANSMISSION_TYPES_URL
  );
}
export function getAdiDocuments(): Promise<
  AxiosResponse<BaseResponse<Array<DocumentDataModel>>>
> {
  return axios.get<BaseResponse<Array<DocumentDataModel>>>(GET_DOCUMENTS_URL);
}
export function getAdiLatestBadge(): Promise<
  AxiosResponse<BaseResponse<DocumentDataModel>>
> {
  return axios.get<BaseResponse<DocumentDataModel>>(GET_LATEST_BADGE_URL);
}
export function getAdiLatestInsurance(): Promise<
  AxiosResponse<BaseResponse<DocumentDataModel>>
> {
  return axios.get<BaseResponse<DocumentDataModel>>(GET_LATEST_INSURANCE_URL);
}
export function getCanAcceptJobs(): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.get<BaseResponse<any>>(GET_CAN_ACCEPT_JOBS_URL);
}
export function addDocument(
  data: DocumentDataModel
): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.post<BaseResponse<any>>(ADD_DOCUMENT_URL, data);
}
export function addInsurance(
  data: DocumentDataModel
): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.post<BaseResponse<any>>(ADD_INSURANCE_URL, data);
}
export function addBadge(
  data: DocumentDataModel
): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.post<BaseResponse<any>>(ADD_BAGDE_URL, data);
}
export function uploadAdiDocument(
  document: any
): Promise<AxiosResponse<BaseResponse<string>>> {
  var formData = new FormData();
  formData.append("file", document);
  return axios.post(UPLOAD_DOCUMENT_URL, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function addAdiPicture(
  data: any
): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.post<BaseResponse<any>>(ADI_PICTURE_URL, data);
}

export function getAdiPicture(): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.get<BaseResponse<any>>(ADI_PICTURE_URL + "/my-profile-picture");
}

export function getHolidays(): Promise<
  AxiosResponse<BaseResponse<Array<HolidayModel>>>
> {
  return axios.get<BaseResponse<Array<HolidayModel>>>(GET_HOLIDAYS_URL);
}

export function getWeekDays(): Promise<
  AxiosResponse<BaseResponse<Array<DayModel>>>
> {
  return axios.get<BaseResponse<Array<DayModel>>>(GET_Days_URL);
}
export function getAdiContactId(): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.get<BaseResponse<any>>(GET_CONTACT_DATA_URL);
}

export function getHasPenddingDocument(): Promise<
  AxiosResponse<BaseResponse<any>>
> {
  return axios.get<BaseResponse<any>>(GET_ADI_HAS_PENDDING_DOCUMENT);
}

export function deleteMyAccount(
  data: any
): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.post<BaseResponse<any>>(DELETE_MY_ACCOUNT_URL, data);
}

export function getNewJobReminder(): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.get<BaseResponse<any>>(GET_NEW_JOB_REMINDER_URL);
}

export function updateNewJobReminder(
  data: any
): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.post<BaseResponse<any>>(UPDATE_NEW_JOB_REMINDER_URL, data);
}

export function getCustomJobReminder(): Promise<BaseResponse<any>> {
  return modifiedFetch({
    url: GET_CUSTOM_JOB_REMINDER_URL,
    method: "GET",
  });
}

export function updateCutomJobReminder(data: {
  is_customized_job_reminder_enabled: number;
}): Promise<BaseResponse<any>> {
  return modifiedFetch({
    url: UPDATE_CUSTOM_JOB_REMINDER_URL,
    method: "PUT",
    data: data,
  });
}

export function getGoogleCalendarSettings(): Promise<
  AxiosResponse<BaseResponse<any>>
> {
  return axios.get<BaseResponse<any>>(GET_GOOGLE_SETTINGS_URL, {
    headers: {
      "x-api-key": API_KEY!,
    },
  });
}

export function getGoogleCalendarEvents(): Promise<
  AxiosResponse<BaseResponse<any>>
> {
  return axios.get<BaseResponse<any>>(GET_GOOGLE_EVENTS_URL, {
    headers: {
      "x-api-key": API_KEY!,
    },
  });
}

export function getGoogleCalendarEventsOnSelectedDay(
  date: string
): Promise<any> {
  return modifiedFetch({
    url: GET_GOOGLE_EVENTS_URL + "?date=" + date,
    method: "GET",
    xApiKey: API_KEY,
  });
}

export function authorizeGoogleCalendar(
  data: any
): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.post<BaseResponse<any>>(AUTHORIZE_GOOGLE_URL, data, {
    headers: {
      "x-api-key": API_KEY!,
    },
  });
}

export function syncGoogleCalendar(): Promise<
  AxiosResponse<BaseResponse<any>>
> {
  return axios.post<BaseResponse<any>>(SYNC_GOOGLE_CALENDAR_URL, null, {
    headers: {
      "x-api-key": API_KEY!,
    },
  });
}

export function getOutlookCalendarSettings(): Promise<
  AxiosResponse<BaseResponse<any>>
> {
  return axios.get<BaseResponse<any>>(GET_OUTLOOK_SETTINGS_URL, {
    headers: {
      "x-api-key": API_KEY!,
    },
  });
}

export function getOutlookCalendarEvents(): Promise<
  AxiosResponse<BaseResponse<any>>
> {
  return axios.get<BaseResponse<any>>(GET_OUTLOOK_EVENTS_URL, {
    headers: {
      "x-api-key": API_KEY!,
    },
  });
}

export function authorizeOutlookCalendar(
  data: any
): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.post<BaseResponse<any>>(AUTHORIZE_OUTLOOK_URL, data, {
    headers: {
      "x-api-key": API_KEY!,
    },
  });
}

export function syncOutlookCalendar(): Promise<
  AxiosResponse<BaseResponse<any>>
> {
  return axios.post<BaseResponse<any>>(SYNC_OUTLOOK_CALENDAR_URL, null, {
    headers: {
      "x-api-key": API_KEY!,
    },
  });
}

export function getCancellationPolicies(): Promise<
  AxiosResponse<BaseResponse<CancellationPolicyModel[]>>
> {
  return axios.get<BaseResponse<CancellationPolicyModel[]>>(
    GET_CANCELLATION_POLICIES
  );
}

export function checkAdiHasBankDetails(): Promise<
  AxiosResponse<BaseResponse<any>>
> {
  return axios.get<BaseResponse<Array<any>>>(GET_HAS_BANK_DETAILS);
}

export function checkAdiHasRequiredDocs(): Promise<
  AxiosResponse<BaseResponse<any>>
> {
  return axios.get<BaseResponse<Array<any>>>(GET_HAS_REQUIRED_DOCS);
}

export function addPromotionCode(
  data: any
): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.post<BaseResponse<any>>(ADD_PROMOTION_CODE_URL, data);
}

export function getLatestPromotionCode(): Promise<
  AxiosResponse<BaseResponse<any>>
> {
  return axios.get<BaseResponse<Array<any>>>(GET_LATEST_PROMOTION_CODE);
}

export function getReferrer(
  data: any
): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.post<BaseResponse<any>>(GET_REFERRER, data);
}

// export function updateNotificationSettings(
//   data: any
// ): Promise<AxiosResponse<BaseResponse<any>>> {
//   return axios.post<BaseResponse<any>>(UPDATE_NOTIFICATION_SETTINGS_URL, data);
// }

export function updateNotificationSettings(
  id: string,
  data: any
): Promise<BaseResponse<any>> {
  return modifiedFetch({
    url: UPDATE_NOTIFICATION_SETTINGS_URL + "/" + id,
    method: "PUT",
    data: data,
    xApiKey: API_KEY,
  });
}

export function getNotificationSettings(): Promise<BaseResponse<any>> {
  return modifiedFetch({
    url: GET_NOTIFICATION_SETTINGS_URL,
    method: "GET",
    xApiKey: API_KEY,
  });
}

export function checkInNewYear(): Promise<BaseResponse<any>> {
  return modifiedFetch({
    url: CHECK_IN_NEW_YEAR_URL,
    method: "GET",
  });
}

export function skipStripe(): Promise<BaseResponse<any>> {
  return modifiedFetch({
    url: SKIP_STRIPE_URL,
    method: "POST",
  });
}

export function skipJobPreferencesStep(): Promise<BaseResponse<any>> {
  return modifiedFetch({
    url: SKIP_JOB_PREFERENCES_URL,
    method: "POST",
  });
}

export function checkIsFirstLogin(): Promise<BaseResponse<any>> {
  return modifiedFetch({
    url: CHECK_IS_FIRST_LOGIN_URL,
    method: "GET",
  });
}

export function changeIsFirstLogin(data: {
  isFirstLogin: boolean;
}): Promise<BaseResponse<any>> {
  return modifiedFetch({
    url: CHANGE_IS_FIRST_LOGIN_URL,
    method: "POST",
    data: data,
  });
}

export function getQuestionnareProgress(): Promise<BaseResponse<any>> {
  return modifiedFetch({
    url: QUESTIONNARE_PROGRESS_URL,
    method: "GET",
  });
}

export function checkIsQuestionnareCompleted(): Promise<BaseResponse<any>> {
  return modifiedFetch({
    url: CHECK_COMPLETED_QUESTIONNARE_URL,
    method: "GET",
  });
}

export function checkAdiHasCancellationPolicy(): Promise<BaseResponse<any>> {
  return modifiedFetch({
    url: CHECK_HAS_CANCELLATION_POLICY_URL,
    method: "GET",
  });
}

export function saveAdiClickedPage(
  data: any
): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.post<BaseResponse<any>>(TRACK_PAGES_URL, data, {
    headers: {
      "x-api-key": API_KEY!,
    },
  });
}

export function checkHasBadge(): Promise<BaseResponse<any>> {
  return modifiedFetch({
    url: GET_HAS_BADGE_URL,
    method: "GET",
  });
}

export function getCutomJobAlerts(): Promise<BaseResponse<any>> {
  return modifiedFetch({
    url: CUSTOM_JOB_ALERT_URL,
    method: "GET",
  });
}

export function addCutomJobAlert(data: any): Promise<BaseResponse<any>> {
  return modifiedFetch({
    url: CUSTOM_JOB_ALERT_URL,
    method: "POST",
    data: data,
  });
}

export function updateCutomJobAlert(
  data: any,
  id: string
): Promise<BaseResponse<any>> {
  return modifiedFetch({
    url: CUSTOM_JOB_ALERT_URL + "/update/" + id,
    method: "PUT",
    data: data,
  });
}

export function deleteCutomJobAlert(id: string): Promise<BaseResponse<any>> {
  return modifiedFetch({
    url: CUSTOM_JOB_ALERT_URL + "/delete/" + id,
    method: "PUT",
  });
}

export function getADITestCenterAndTransmissions(): Promise<any> {
  return modifiedFetch({
    url: ADI_TEST_CENTER_AND_TRANSMISSION_URL,
    method: "GET",
  });
}

export function addToWishList(data: any): Promise<BaseResponse<any>> {
  return modifiedFetch({
    url: ADD_TO_WISH_LIST_URL,
    method: "POST",
    data: data,
  });
}

export function getOutlookCalendarEventsOnSelectedDay(
  date: string
): Promise<any> {
  return modifiedFetch({
    url: GET_OUTLOOK_EVENTS_URL + "?date=" + date,
    method: "GET",
    xApiKey: API_KEY,
  });
}
export function markArticlesAsRead(): Promise<BaseResponse<any>> {
  return modifiedFetch({
    url: MARK_ARTICLE_AS_READ,
    method: "GET",
  });
}
export function getArticlesUnReadCount(): Promise<BaseResponse<any>> {
  return modifiedFetch({
    url: GET_UNREAD_ARTICLE_NUMBER,
    method: "GET",
  });
}
