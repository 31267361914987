import { useFormik } from "formik";
import { FC, useState } from "react";
import * as Yup from "yup";
import ReactGA from "react-ga4";
import { updateJobTestResult } from "../../../../../../core/services/job-service";
import { TestResultStatusEnum } from "../../../../../../core/models/enums/test-result-status-enum";
import Modal from "../../../../shared/overlays/Modal";
import Alert from "../../../../shared/overlays/Alert";
import TextArea from "../../../../shared/forms/TextArea";
import RadioInputGroup from "../../../../shared/forms/RadioInputGroup";
import toast from "react-hot-toast";
import Notification from "../../../../shared/overlays/Notification";
import {
  ExclamationCircleIcon,
  CheckCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import Input from "../../../../shared/forms/Input";
import { Tooltip } from "@mui/material";

type Props = {
  show: any;
  onHide: any;
  jobDetails: any;
  setJobDetails: any;
  testResultStatus?: any;
};

const AddTestResult: FC<Props> = (props) => {
  const [showInfo, setShowInfo] = useState(false);
  const [testStatusOptions, setTestStatusOptions] = useState([
    {
      label: "Passed",
      value: TestResultStatusEnum.YES,
      img: "/assets/media/svg/test-result/pass-icon.svg",
    },
    {
      label: "Failed",
      value: TestResultStatusEnum.NO,
      img: "/assets/media/svg/test-result/fail-icon.svg",
    },
  ]);
  const validationSchema = Yup.object().shape({
    test_result_status_id: Yup.number().required("Result is required"),
    is_continue: Yup.boolean()
      .when("test_result_status_id", ([test_result_status_id], schema) => {
        return test_result_status_id == 2
          ? schema.required("This field is required")
          : schema;
      })
      .nullable(),
    attended_independently: Yup.boolean().required("This field is required"),
    number_of_minors_faults: Yup.number().min(0, 'Value should be more than or equal 0').nullable(),
    number_of_serious_faults: Yup.number().min(0 , 'Value should be more than or equal 0').nullable(),
    examiner_intervene: Yup.boolean().nullable(),
  });
  const formik = useFormik({
    initialValues: {
      test_result_status_id: props.jobDetails?.job?.test_result_status_id,
      failing_test_reasons: props.jobDetails?.job?.failing_test_reasons ?? "",
      is_continue: props.jobDetails?.job?.is_continue,
      attended_independently: props.jobDetails?.job?.attended_independently,
      job_id: props.jobDetails?.job?.id,
      number_of_minors_faults: null,
      examiner_intervene: null,
      number_of_serious_faults: null,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      let loadingId = toast.custom(
        () => (
          <Notification
            colour="gray"
            title={"Updating test result..."}
            description={""}
            Icon={ExclamationCircleIcon}
          />
        ),
        {
          duration: 5000,
          position: "top-center",
        }
      );

      updateJobTestResult(props.jobDetails?.job_ref, values)
        .then(() => {
          ReactGA.event("set_test_result");
          var jobDetails = props.jobDetails;
          jobDetails.test_result_status_id = values.test_result_status_id;
          jobDetails.job.failing_test_reasons = values.failing_test_reasons;
          jobDetails.job.is_continue = values.is_continue;
          jobDetails.job.attended_independently = values.attended_independently;
          props.setJobDetails(jobDetails);
          props.onHide();
          toast.custom(
            () => (
              <Notification
                colour="green"
                title={"Saved successfully"}
                description={""}
                Icon={CheckCircleIcon}
              />
            ),
            {
              duration: 5000,
              position: "top-center",
              id: loadingId,
            }
          );
        })
        .catch((response) => {
          if (response?.errors?.length) {
            setStatus(response.errors[0].errorMessage);
          } else {
            setStatus("Sorry, an error has occurred");
          }
          toast.custom(
            () => (
              <Notification
                colour="red"
                title={"Failed to update test result!"}
                description={""}
                Icon={ExclamationTriangleIcon}
              />
            ),
            {
              duration: 5000,
              position: "top-center",
              id: loadingId,
            }
          );
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const handleClose = () => {
    formik.resetForm();
    props.onHide();
  };

  const updateTestStatusOptions = (attended_independently: boolean) => {
    let options = [
      {
        label: "Passed",
        value: TestResultStatusEnum.YES,
        img: "/assets/media/svg/test-result/pass-icon.svg",
      },
      {
        label: "Failed",
        value: TestResultStatusEnum.NO,
        img: "/assets/media/svg/test-result/fail-icon.svg",
      },
    ];

    if (attended_independently) {
      options.push({
        label: "Unsure",
        value: TestResultStatusEnum.UNSURE,
        img: "/assets/media/svg/test-result/unsure-icon.svg",
      });
    }

    setTestStatusOptions(options);
  };

  return (
    <>
      <form
        onSubmit={formik.handleSubmit}
        className="flex flex-col justify-content-between h-100"
      >
        <Modal
          open={props.show}
          onSubmit={() => {
            formik.handleSubmit();
          }}
          submitText="Confirm"
          closeText="Cancel"
          title={"Add Test Result"}
          onClose={handleClose}
          disabled={formik.isSubmitting}
        >
          {formik.status ? (
            <Alert colour="orange" icon="pmf-icon-info" title={formik.status} />
          ) : (
            <></>
          )}
          <div className="flex flex-col mb-3">
            <RadioInputGroup
              name="attended_independently"
              label={"Did you attend the test with the learner?"}
              options={[
                {
                  label: "Yes",
                  value: false,
                },
                {
                  label: "No",
                  value: true,
                },
              ]}
              onChange={(attended_independently) => {
                formik.setFieldValue(
                  "attended_independently",
                  attended_independently
                );
                if(attended_independently)
                {
                  formik.setFieldValue('number_of_serious_faults', null)
                  formik.setFieldValue('number_of_minors_faults', null)
                }
                updateTestStatusOptions(attended_independently);
                formik.setFieldValue("test_result_status_id", null);
                if (attended_independently) {
                  if (!props.jobDetails?.job?.is_private_student) {
                    formik.setFieldValue("failing_test_reasons", "");
                    formik.setFieldValue("is_continue", false);
                  }
                }
              }}
              value={formik.values.attended_independently}
              disabled={formik.isSubmitting}
              error={
                formik.touched.attended_independently &&
                formik.errors.attended_independently != undefined
              }
              errorMsg={formik.errors.attended_independently?.toString()}
            />
          </div>

          <div className="flex flex-col mb-3">
            <RadioInputGroup
              name="test_result_status_id"
              label={"Enter learner's test result:"}
              options={testStatusOptions}
              onChange={(reason) => {
                formik.setFieldValue("test_result_status_id", reason);
                if (reason == TestResultStatusEnum.YES) {
                  formik.setFieldValue("failing_test_reasons", "");
                } else {
                  formik.setFieldValue("is_continue", false);
                }
              }}
              value={formik.values.test_result_status_id}
              disabled={formik.isSubmitting}
              error={
                formik.touched.test_result_status_id &&
                formik.errors.test_result_status_id != undefined
              }
              errorMsg={formik.errors.test_result_status_id?.toString()}
            />
          </div>
          {!formik?.values?.attended_independently &&
            (formik.values.test_result_status_id == TestResultStatusEnum.YES ||
              formik.values.test_result_status_id ==
                TestResultStatusEnum.NO) && (
              <div className="flex flex-col mb-3">
                <div className="flex flex-col justify-start">
                  <h4 className="flex justify-start text-sm">
                    Enter additional test result information:
                    <Tooltip
                      title={
                        "To track your standards checks indicators, please provide some additional information about the outcome of your learner's test"
                      }
                      arrow
                      placement="top"
                      open={showInfo}
                      componentsProps={{
                        tooltip: {
                          style: {
                            fontSize: "10px",
                            lineHeight: "18px",
                            fontFamily: "Poppins, sans-serif",
                            maxWidth: "250px",
                            marginRight: "6px",
                          },
                        },
                        popper: {
                          style: {
                            zIndex: 9999,
                          },
                        },
                      }}
                    >
                      <i
                        className="text-sm ms-1 pmf-icon-info cursor-pointer"
                        onClick={() => setShowInfo(!showInfo)}
                      ></i>
                    </Tooltip>
                  </h4>
                </div>
                <div className="-mb-5">
                <Input
                  {...formik.getFieldProps("number_of_minors_faults")}
                  type="number"
                  label="Number of minor faults"
                  placeholder=""
                  error={
                    formik.touched.number_of_minors_faults &&
                    formik.errors.number_of_minors_faults
                      ? true
                      : false
                  }
                  errorMsg={formik.errors.number_of_minors_faults}
                  name="number_of_minors_faults"
                  disabled={formik.isSubmitting}
                  id="number_of_minors_faults"
                  min="0"
                />
                </div>
                {formik.values.test_result_status_id ==
                TestResultStatusEnum.NO ? (
                  <>
                  <div className="-mb-2">
                    <Input
                      {...formik.getFieldProps("number_of_serious_faults")}
                      type="number"
                      label="Number of serious faults"
                      placeholder=""
                      error={
                        formik.touched.number_of_serious_faults &&
                        formik.errors.number_of_serious_faults
                          ? true
                          : false
                      }
                      errorMsg={formik.errors.number_of_serious_faults}
                      name="number_of_serious_faults"
                      disabled={formik.isSubmitting}
                      id="number_of_serious_faults"
                      min="0"
                    />
                    </div>
                    <RadioInputGroup
                      name="examiner_intervene"
                      label={"Examiner intervention"}
                      options={[
                        { label: "Yes", value: true },
                        { label: "No", value: false },
                      ]}
                      onChange={(val) => {
                        formik.setFieldValue("examiner_intervene", val);
                      }}
                      value={formik.values.examiner_intervene}
                      disabled={formik.isSubmitting}
                      error={
                        formik.touched.examiner_intervene &&
                        formik.errors.examiner_intervene != undefined
                      }
                      errorMsg={formik.errors.examiner_intervene?.toString()}
                    />
                  </>
                ) : (
                  <></>
                )}
              </div>
            )}

          {(formik.values.test_result_status_id == TestResultStatusEnum.NO ||
            formik.values.test_result_status_id ==
              TestResultStatusEnum.UNSURE) && (
            <div className="flex flex-col">
              <div className="my-2">
                <TextArea
                  name="failing_test_reasons"
                  disabled={formik.isSubmitting}
                  maxLength={255}
                  label=" Comments:"
                  className="form-control"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.failing_test_reasons}
                  placeholder="Add a Comment"
                />
              </div>
              {props.jobDetails?.is_private_student === 1 && (
                <>
                  <RadioInputGroup
                    name="is_continue"
                    label={"Are you continuing with this learner?"}
                    options={[
                      { label: "Yes", value: true },
                      { label: "No", value: false },
                    ]}
                    onChange={(reason) => {
                      formik.setFieldValue("is_continue", reason);
                      formik.setFieldValue("failing_test_reasons", "");
                    }}
                    value={formik.values.is_continue}
                    disabled={formik.isSubmitting}
                    error={
                      formik.touched.is_continue &&
                      formik.errors.is_continue != undefined
                    }
                    errorMsg={formik.errors.is_continue?.toString()}
                  />
                </>
              )}
            </div>
          )}
        </Modal>
      </form>
    </>
  );
};

export { AddTestResult };
