import React, { useState } from "react";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopTimePicker } from '@mui/x-date-pickers';
import { ClickAwayListener } from "@mui/material";

export interface TimeInputProps {
  label?: string;
  className?: string;
  value?: any;
  onChange: (newValue: any) => void;
  onOpen: () => void;
  minutesStep?: number;
  disabled?: boolean;
  error?: boolean;
  errorMsg?: string;
}

function areEqual(prevState: any, nextState: any) {
  return (prevState.value === nextState.value && nextState.value !== undefined && prevState.error === nextState.error);
}

export default React.memo(function TimeInput({
  label,
  className = "",
  value,
  onChange,
  onOpen,
  minutesStep,
  disabled = false,
  error = false,
  errorMsg = "This field is required",
  ...props
}: TimeInputProps) {
  const [open, setOpen] = useState(false);

  return (
    <div className="flex flex-col my-4">
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['TimePicker', 'TimePicker']}>
            <DesktopTimePicker
              label={label}
              value={value}
              // closeOnSelect

              onOpen={() => {
                setOpen(true);
                onOpen();
              }}
              open={open}
              onClose={() => setOpen(false)}
              disabled={disabled}
              minutesStep={minutesStep}
              ampm={false}
              onChange={onChange}
              sx={{
                width: '100%',
                marginBottom: '4px !important',
                ".Mui-disabled": {
                  backgroundColor: '#fafafa',
                  WebkitTextFillColor: '#163A5E !important'
                },
                ".MuiFormControl-root": {
                  "&:focus-visible": {
                    borderColor: '#d1d5db'
                  }
                },
                ".MuiFormLabel-root": {
                  fontSize: '18px',
                  fontWeight: 500,
                  fontFamily: 'Poppins, sans-serif',
                  color: '#163A5E !important',
                  letterSpacing: 0,
                  lineHeight: "1.25rem",
                  height: 'fit-content !important',
                  backgroundColor: '#fff',
                  padding: '2px 6px',
                  left: '-5px'
                },
                ".MuiOutlinedInput-root": {
                  borderRadius: '2px',
                  fontSize: '19px'
                },
                ".MuiInputBase-input": {
                  maxHeight: '11px',
                  fontSize: '14px',
                  fontWeight: 400,
                  fontFamily: 'Poppins, sans-serif',
                  color: '#163A5E',
                  letterSpacing: 0,
                  lineHeight: "1.25rem"
                },
                ".Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: '#4f46e5 !important',
                },
                ".MuiOutlinedInput-notchedOutline": {
                  borderWidth: '1px !important',
                  borderRadius: '2px',
                  borderColor: error ? "#ef4444 !important" : "#dadde2 !important",
                },
              }}
              slotProps={{
                popper: {
                  disablePortal: window.innerWidth <= 768 ? true : false,
                  placement: window.innerWidth <= 768 ? 'top-start' : 'bottom-start'
                },
                desktopPaper: {
                  sx: {
                    ".MuiMultiSectionDigitalClock-root": {
                      width: 'fit-content',
                      overflowY: 'auto',
                      "&:after": {
                        display: 'none'
                      }
                    },
                    ".MuiMenuItem-gutters": {
                      marginTop: 0,
                      marginBottom: 0,
                      width: '50px !important',
                      borderRadius: '4px',
                      fontFamily: 'Poppins, sans-serif',
                      color: '#163A5E',
                      "&.Mui-selected": {
                        backgroundColor: '#bad9f1',
                        fontWeight: 600
                      }
                    }
                  }
                },
                actionBar: {
                  sx: {
                    ".MuiButton-text": {
                      fontSize: '14px',
                      fontWeight: 600,
                      fontFamily: 'Poppins, sans-serif',
                      color: '#163A5E',
                      border: '1px solid #163A5E',
                      borderRadius: '50px',
                      padding: '2px',
                      width: '100%'
                    }
                  }
                }
              }}
            />
          </DemoContainer>
        </LocalizationProvider>
      </ClickAwayListener>
      {error && (
        <p className="text-sm text-red-600" id="error">
          {errorMsg}
        </p>
      )}
    </div>
  );
}, areEqual)