import { PrismicImage, PrismicRichText } from "@prismicio/react";
import { FC } from "react";
import { toAbsoluteUrl } from "../../../helpers";
import Skeleton from "@mui/material/Skeleton";
import { calculateReadingTime } from "../../../helpers/readingTimeHelper";
import { useHistory } from "react-router-dom";
import { useQuery } from "../../../helpers/QueryParamsHelper";

type Props = {
  loading: boolean;
  imgURL?: any;
  category?: any;
  author?: any;
  title?: React.ReactNode;
  shortDesc?: any;
  onShowArticleClick?: () => void;
  uid: string;
  content: any;
  authorData?: any;
  pageCounter?: any
};

const ArticleTile: FC<Props> = ({
  loading,
  imgURL,
  category,
  title,
  shortDesc,
  author,
  onShowArticleClick,
  uid,
  content,
  authorData,
  pageCounter
}) => {

  const history = useHistory();
  const query = useQuery();
  return (
    <>
      {/* <!------- */}
      <div
        className={`group relative flex flex-col overflow-hidden rounded-[20px] bg-white`}
        id={uid}
      >
        <div
          onClick={onShowArticleClick}
          className="VideoTip d-flex flex-column"
        >
          {loading ? (
            <>
              <Skeleton width={60} height={60}></Skeleton>
            </>
          ) : (
            <div className="aspect-h-4 aspect-w-3 bg-gray-200 sm:aspect-none group-hover:opacity-75 h-56">
              {Object.keys(imgURL).length > 0 ? (
                <>
                  <PrismicImage
                    field={imgURL}
                    className="h-full w-full object-cover object-center sm:h-full sm:w-full"
                  />
                </>
              ) : (
                <>
                  <img
                    src={toAbsoluteUrl(`/assets/media/CPD/article-thumb.svg`)}
                    className="h-full w-full object-cover object-center sm:h-full sm:w-full"
                  />
                </>
              )}
            </div>
          )}

          <div className="flex flex-1 flex-col space-y-2 px-5 pb-7 pt-4">
            <div className="text-sm text-[#616161]">{category}</div>
            <h3 className="leading-none text-2xl font-titlef font-bold">
              {/* <span aria-hidden="true" className="absolute inset-0"></span> */}
              {loading ? (
                <>
                  <Skeleton width={150} />
                </>
              ) : (
                <>{title}</>
              )}
            </h3>
            <p className="text-sm text-[#616161]">
              {loading ? (
                <>
                  <Skeleton width={150} />
                </>
              ) : (
                <>{shortDesc}</>
              )}
            </p>
            {Object.keys(author).length > 0 ? (
              <div className="text-sm flex" >
                <p className="me-1">by</p>
                {""}
                <div onClick={(e) => {
                  e.stopPropagation();
                  history.push(
                    query.get("category") != null ?
                      `/ADI/cpd/authors/${authorData?.uid}?mainTab=article&pageCounter=${pageCounter}&articleId=${uid}&category=${query.get("category")}` :
                      `/ADI/cpd/authors/${authorData?.uid}?mainTab=article&pageCounter=${pageCounter}&articleId=${uid}`
                  );

                }}>
                  <PrismicRichText field={author} />
                </div>
                <span className="mx-1">•</span>
                <p className="mx-1">{calculateReadingTime(content)} min read</p>
              </div>
            ) : (
              <>
                <div className="text-sm">
                  <p>{calculateReadingTime(content)} min read</p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {/* <!------- */}
    </>
  );
};

export default ArticleTile;
