import { useFormik } from 'formik'
import { useEffect, useState } from "react";
import * as Yup from 'yup'
import { Test } from "../../../core/models/tests/test";
import Alert from "../shared/overlays/Alert";
import Modal from "../shared/overlays/Modal";
import ReactGA from 'react-ga4'
import { getDealTags, getRejectionReasons, rejectJobTest } from "../../../core/services/job-service";
import { OTHER_ID, OUTSIDE_WORKING_HOURS, TOO_SOON, VACATION, WRONG_TEST_CENTRE } from '../../../core/models/enums/rejection-reasons-enum'
import { TestCenterDataModel } from "../../../core/models/test-center-data-model";
import RadioInputGroup from '../shared/forms/RadioInputGroup';
import { toAbsoluteUrl } from '../../helpers';
import TextArea from '../shared/forms/TextArea';
import { TestCentres } from '../account-settings/job-preferences/TestCentres';
import { getTestCenters } from '../../../core/services/adi-service';


export type RejectModalProps = {
    test: Test
    refreshTests: () => void,
    closeModal: () => void
    open: boolean
};

const requiredMsg = "Required";
const rejectionReasonRequired = "A rejection Reason is required";
const validationSchema = Yup.object().shape({
    rejection_reason_id: Yup.number().required(rejectionReasonRequired),
    other_rejection_reason: Yup.string().max(255).when('rejection_reason_id', ([rejection_reason_id], schema) => {
        return checkNoteShown(rejection_reason_id) ? schema.required(requiredMsg) : schema;
    }),
    selected_test_centres: Yup.array().when('rejection_reason_id', ([rejection_reason_id], schema) => {
        return rejection_reason_id == WRONG_TEST_CENTRE ? schema.min(1, requiredMsg).of(
            Yup.object().shape({
                test_center_name: Yup.string().required(requiredMsg),
                test_center_id: Yup.string().required(requiredMsg),
            })
        ) : schema;
    })
})

const checkNoteShown = (value: number | undefined) => {
    return (value === TOO_SOON || value === OUTSIDE_WORKING_HOURS || value === VACATION || value === OTHER_ID)
}

const RejectTestModal = ({ test, refreshTests, open, closeModal }: RejectModalProps) => {

    const [rejReasons, setRejReasons]: any = useState([])
    const [selectedReason, setSelectedReason] = useState<string | null>()
    const [selectedTestCentres, setSelectedTestCentres] = useState<Array<TestCenterDataModel>>([])
    const [testCentres, setTestCentres] = useState<Array<TestCenterDataModel>>([])
    const [tags, setTags] = useState<Array<string>>()

    useEffect(() => {
        if (open && tags) {
            setSelectedTestCentres(testCentres.filter(x => tags.includes(x.test_center_name ?? '')).map(x => ({
                test_center_id: x.id,
                test_center_name: x.test_center_name,
                id: x.id
            })))
        }
    }, [open])

    useEffect(() => {
        (async () => {
            const reasonsResponse = await getRejectionReasons().catch(() => { })
            setRejReasons(reasonsResponse?.data)
        })()
    }, []);

    useEffect(() => {
        if (test?.deal_id) {
            (async () => {
                const testCenterResponse = await getTestCenters().catch(() => { })
                const testCentres = testCenterResponse?.data?.results ?? []
                setTestCentres(testCentres)

                const tagResponse = await getDealTags(test.deal_id)
                if (tagResponse?.data?.results?.tags.length > 0) {
                    setTags(tagResponse?.data?.results?.tags)
                    setSelectedTestCentres(testCentres.filter(x => tagResponse?.data?.results?.tags.includes(x.test_center_name)).map(x => ({
                        test_center_id: x.id,
                        test_center_name: x.test_center_name,
                        id: x.id
                    })))
                }
            })()
        }
    }, [test?.deal_id])

    let radioOption = rejReasons?.map((reason: any) => {
        return {
            label: reason.name,
            value: reason.id,
            nonselectImg: toAbsoluteUrl('/assets/media/svg/test-reject-reasons/' + reason.name.concat('-grey.svg')),
            img: toAbsoluteUrl('/assets/media/svg/test-reject-reasons/' + reason.name.concat('.svg'))
        }
    })

    const formik = useFormik({
        initialValues: {
            other_rejection_reason: undefined,
            rejection_reason_id: undefined,
            selected_test_centres: selectedTestCentres
        },
        enableReinitialize: true,
        validationSchema,
        onSubmit
    });
    useEffect(() => {
        formik.resetForm();
    }, [open])

    function onSubmit(values: { other_rejection_reason: string | undefined, rejection_reason_id: any, selected_test_centres: Array<TestCenterDataModel> }, { setStatus, setSubmitting }: any) {
        if (!checkNoteShown(values.rejection_reason_id)) { // CHECK not other reason
            values.other_rejection_reason = undefined;
        }
        (async () => {
            setStatus(null)
            const rejectTestResponse = await rejectJobTest({
                rejection_reason_id: values.rejection_reason_id,
                other_rejection_reason: values.other_rejection_reason,
                tags: values.rejection_reason_id == WRONG_TEST_CENTRE ? selectedTestCentres.map(x => x.test_center_name) : [],
                test_id: test.id,
                job_ref: test.deal_id,
                testCentres: values.rejection_reason_id == WRONG_TEST_CENTRE ? selectedTestCentres : []
            }).catch((response: any) => {
                if (response?.errors?.length) {
                    setStatus(response.errors[0])
                } else {
                    setStatus('Sorry, an error has occured')
                }
            });

            setSubmitting(false)
            if (rejectTestResponse?.status === 200) {
                ReactGA.event('reject_test')
                closeModal()
                refreshTests()
            }
        })()
    }


    const onReasonChanged = (reason: any) => {
        setSelectedReason(reason)
        formik.setValues({ ...formik.values, rejection_reason_id: reason, other_rejection_reason: formik.values.other_rejection_reason })
    }

    const close = function () {
        setSelectedReason(null)
        closeModal()
    }

    const deleteTestCentre = (index: number) => {
        const temp = formik.values.selected_test_centres
        temp.splice(index, 1)
        formik.setFieldValue('selected_test_centres', temp)
        setSelectedTestCentres(temp)
    }

    const addTestCentre = () => {
        if (testCentres.length <= formik.values.selected_test_centres.length) {
            return
        }
        let tempTestCentres = formik.values.selected_test_centres
        tempTestCentres.push({ id: null, test_center_id: null, test_center_name: null })
        formik.setFieldValue('selected_test_centres', tempTestCentres)
        setSelectedTestCentres(tempTestCentres)

    }

    const onTestCentresChanged = (e: any, key: number) => {
        if (formik.isSubmitting) {
            return
        }
        const updatedTestCentres: TestCenterDataModel[] = formik.values.selected_test_centres
        updatedTestCentres[key].test_center_id = e.value
        updatedTestCentres[key].test_center_name = e.label
        formik.setFieldValue('selected_test_centres', updatedTestCentres)
        setSelectedTestCentres(updatedTestCentres)
    }

    return (
        <>
            <Modal
                title={`You are about to reject a test for ${test?.student?.full_name}`}
                description='Why are you rejecting this test?'
                closeText="Cancel"
                submitText="Reject"
                onSubmit={formik.submitForm}
                onClose={close}
                open={open}
                disabled={formik.isSubmitting}
            >
                {formik.status && <Alert description={formik.status} colour="red"></Alert>}

                <form onSubmit={formik.handleSubmit}>
                    <div className=''>
                        {/* ---- */}
                        {formik.status ? (
                            <Alert description={formik.status} colour="red"></Alert>

                        ) : null}
                        <div className='py-3'>
                            <RadioInputGroup name='reasons' label='' options={radioOption} value={selectedReason} disabled={formik.isSubmitting}
                                onChange={(reason) => onReasonChanged(reason)} errorMsg={formik.errors.rejection_reason_id}
                                error={formik.touched.rejection_reason_id && formik.errors.rejection_reason_id !== undefined} />
                        </div>

                        {checkNoteShown(formik.values.rejection_reason_id) && (
                            <div className="mt-3">
                                <TextArea name='other_rejection_reason'
                                    label='Please describe why this is unsuitable'
                                    id='other_rejection_reason'
                                    onChange={formik.handleChange}
                                    disabled={formik.isSubmitting}
                                    errorMsg={formik.errors.other_rejection_reason}
                                    error={formik.touched.other_rejection_reason && formik.errors.other_rejection_reason !== undefined}
                                >
                                </TextArea>
                            </div>
                        )}

                        {formik.values.rejection_reason_id === 8 && (
                            <div className="">
                                <TestCentres values={formik.values.selected_test_centres} label="Please select the test centre(s) that would be appropriate"
                                    isDisabled={formik.isSubmitting}
                                    isSubmitted={formik.touched.selected_test_centres && formik.errors.selected_test_centres !== undefined}
                                    options={testCentres.map((testCenter: any) => (({ label: testCenter.test_center_name, value: testCenter.id })))}
                                    onChange={(e: any, key: number) => { onTestCentresChanged(e, key) }}
                                    deleteTestCentre={deleteTestCentre}
                                    addTestCentre={addTestCentre}
                                    errorMsg={"Test centre is required"}></TestCentres>
                            </div>
                        )}

                        <div className='mt-3'>
                            <Alert colour='white' icon="pmf-icon-info" customDesc={<span className='text-sm'> We will work to find a more suitable test for this learner </span>} />
                        </div>
                    </div>
                </form>

            </Modal>
        </>)
}


export { RejectTestModal }
