import { FC, useState } from "react";
import Button from "../shared/elements/Button";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline";
import { toAbsoluteUrl } from "../../helpers";
import moment from 'moment'
import { MoneyInModal } from "./_modals/MoneyInModal";
import { MoneyOutModal } from "./_modals/MoneyOutModal";
import Skeleton from "@mui/material/Skeleton";


interface IProps {
  moneyAmount: any,
  refreshList: () => void,
  loading: boolean,
}

const FinanceSummaryHeader: FC<IProps> = (props) => {

  const [showAddMoneyInModal, setShowAddMoneyInModal] = useState(false)
  const [showAddMoneyOutModal, setShowAddMoneyOutModal] = useState(false)

  const openAddMoneyInModal = () => {
    setShowAddMoneyInModal(true)
  }

  const closeAddMoneyInModal = () => {
    setShowAddMoneyInModal(false)
  }

  const openAddMoneyOutModal = () => {
    setShowAddMoneyOutModal(true)
  }

  const closeAddMoneyOutModal = () => {
    setShowAddMoneyOutModal(false)
  }
  return (
    <>
      <div className="d-flex flex-wrap px-5 pt-4 p-lg-0">
        <div className="grid grid-cols-2 gap-3 mt-1">
          <div className="relative rounded-[10px] bg-yellow ">
            <div className="py-4 px-3 flex flex-col text-center justify-center items-center">
              <i className={`text-base iouu`}></i>
              <p className="block text-base font-light leading-relaxed text-inherit antialiased">
                Balance
              </p>
              <h5 className="mb-2 mt-1 font-titlef text-3xl font-semibold text-blue-gray-900 antialiased">
                {props.loading ? <>
                  <Skeleton width={125} />
                </> :
                  <>
                  {Math.sign(props.moneyAmount.total ) === -1 ? '-£' : '£'}{Math.abs(props.moneyAmount.total) ?? 0}
                   </>
                }
              </h5>
              {true && (
                <div className="flex items-center text-xs">
                  {moment(new Date()).format('DD MMMM YYYY')}
                </div>
              )}
            </div>
          </div>

          <div className="grid grid-cols-1 gap-x-2">
            <div className="bg-white rounded-lg p-[10px] mb-3">
              <div className="flex justify-between items-center min-w-0 gap-x-3">
                <img
                  className="flex-none rounded-full h-9 w-9"
                  src={toAbsoluteUrl("/assets/media/svg/money-in.svg")}
                  alt=""
                />
                <div className="min-w-0 flex-auto">
                  <p className="text-[10px] leading-5">
                    Money in
                  </p>
                  <p className="text-base font-semibold font-titlef ">
                    {props.loading ? <>
                      <Skeleton width={80} />
                    </> :
                      <>£{(Number.isInteger(+props.moneyAmount.moneyIn) ?  props.moneyAmount.moneyIn : (+props.moneyAmount.moneyIn)?.toFixed(2)) ?? 0} </>
                    }
                  </p>
                </div>
              </div>
            </div>

            <div className="bg-white rounded-lg p-[10px]">
              <div className="flex justify-between items-center min-w-0 gap-x-3">
                <img
                  className="flex-none rounded-full h-9 w-9"
                  src={toAbsoluteUrl("/assets/media/svg/money-out.svg")}
                  alt=""
                />
                <div className="min-w-0 flex-auto">
                  <p className="text-[10px] leading-5">
                    Money out
                  </p>
                  <p className="text-base font-semibold font-titlef ">
                    {props.loading ? <>
                      <Skeleton width={80} />
                    </> :
                      <>£{(Number.isInteger(+props.moneyAmount.moneyOut) ?  props.moneyAmount.moneyOut : (+props.moneyAmount.moneyOut)?.toFixed(2)) ?? 0} </>
                    }
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* start:: Actions */}
        <div className="px-5 my-6 grid grid-cols-2 gap-3">
          <Button colour="darkBlue" size="large" onClick={openAddMoneyInModal} fullWidth>
            <div className="flex space-x-2 items-center">
              <PlusIcon className="w-4 h-4" />
              <span className="leading-none">Money in</span>
            </div>
          </Button>
          <Button colour="outline" size="large" onClick={openAddMoneyOutModal} fullWidth>
            <div className="flex space-x-2 items-center">
              <MinusIcon className="w-4 h-4" />
              <span className="leading-none">Money out</span>
            </div>
          </Button>
          <MoneyInModal
            show={showAddMoneyInModal}
            onClose={closeAddMoneyInModal}
            onSubmit={() => {
              props.refreshList()
              closeAddMoneyInModal()
            }}
          ></MoneyInModal>

          <MoneyOutModal
            show={showAddMoneyOutModal}
            onClose={closeAddMoneyOutModal}
            onSubmit={() => {
              props.refreshList()
              closeAddMoneyOutModal()
            }}
          ></MoneyOutModal>
        </div>
        {/* end:: Actions */}
      </div>
    </>
  );
};

export { FinanceSummaryHeader };
