import React, { useState } from "react";
import { RateCard } from "../../shared/cards/RateCard";
import Button from "../../shared/elements/Button";
import { PlusSmallIcon } from "@heroicons/react/20/solid";
import { RateModal } from "./RateModal";
import { TransmissionTypeModel } from "../../../../core/models/transmission-type-model";
import { AdiRates } from "../../../../core/models/rates/adi-rates";
import { AdiRateModel } from "../../../../core/models/rates/adi-rate-model";
import Alert from "../../shared/overlays/Alert";
import { Skeleton } from "@mui/material";
import EmptyState from "../../shared/elements/EmptyState";
import { DocumentCheckIcon } from "@heroicons/react/24/outline";

export type RateListProps = {
  parentWrapper: string;
  loading: boolean;
  adiRates?: AdiRates;
  transmissionTypes: Array<TransmissionTypeModel>;
  submitting: boolean;
  refreshRates: () => void;
  setRates: (adiRates: Array<AdiRateModel>) => void;
  questionnaire: boolean;
  closeParentModal: () => void;
};

const RateList = ({
  loading,
  parentWrapper,
  adiRates,
  transmissionTypes,
  refreshRates,
  setRates,
  questionnaire,
  closeParentModal
}: RateListProps) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedRate, setSelectedRate] = useState<AdiRateModel>();

  const openRateModal = (rate?: AdiRateModel) => {
    setSelectedRate(rate);
    setOpenModal(true);
  };

  const closeModal = (adiRates?: Array<AdiRateModel>) => {
    // closeParentModal();
    setSelectedRate(undefined);
    setOpenModal(false);
    if (adiRates) {
      setRates(adiRates);
    }
  };

  return loading ? (
    <>
      <RateCard loading={loading}></RateCard>
    </>
  ) : (
    <>
      <RateModal
        closeModal={closeModal}
        open={openModal}
        transmissionTypes={transmissionTypes}
        adiRates={adiRates}
        parentWrapper={parentWrapper}
        refreshRates={refreshRates}
        rate={selectedRate}
      ></RateModal>

      {adiRates?.adiRates.length === 0 ? (


<>
          {!adiRates?.completed_car_questionnaire_step ? (
            <div className="mb-4">
              <Alert
                colour="red"
                icon="pmf-icon-info"
                title="Please add at least one car"
              />
            </div>
          ) : (
            <></>
          )}
        <div className="pointer-events-auto w-full overflow-hidden rounded-lg bg-white shadow border mb-2 mt-3 p-3">
          <div className="-mt-8">
            <EmptyState
              title="No rates yet"
              description="Please add your hourly rates"
              Icon={DocumentCheckIcon}
            />
          </div>
          <Button
            className="-mt-7 mb-2"
            colour="yellow"
            type="button"
            fullWidth
            size="large"
            disabled={!adiRates?.completed_car_questionnaire_step}
            onClick={() => openRateModal(undefined)}
          >
            <span className="flex items-center py-1">
              <PlusSmallIcon className="w-4 h-4" /> New Rates
            </span>
          </Button>
        </div>
        </>
      ) : (
        <div className={questionnaire ? "pointer-events-auto w-full overflow-hidden rounded-lg bg-white shadow border mb-2 px-3" : ""}>
            <Button
              className="mt-5 mb-6"
              colour="yellow"
              type="button"
              fullWidth
              onClick={() => openRateModal(undefined)}
            >
              <span className="flex items-center py-1">
                <PlusSmallIcon className="w-5 h-5" /> New Rates
              </span>
            </Button>
          <div className={`${questionnaire ? "" : "sm:grid-cols-2"} grid grid-cols-1 gap-3`}>
            {adiRates?.adiRates.map((rate) => (
              <div key={rate.id}>
                <RateCard
                  rate={rate}
                  openRateModal={openRateModal}
                  loading={loading}
                ></RateCard>
              </div>
            ))}
          </div>
          {adiRates && !adiRates.rateChangeAllowed && (
            <div className="mt-2">
              <Alert colour="blue">
                <div>
                  *If you wish to change your rates please
                  <a
                    className="text-blue-600 visited:text-purple-600 ps-1"
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.passmefast.co.uk/contact"
                  >
                    contact us
                  </a>
                </div>
              </Alert>
            </div>
          )}
        </div>
      )}
      {questionnaire && (
        <Button
          className="mt-6 mb-3"
          fullWidth
          onClick={() => closeParentModal()}
          colour="outline"
          size="large"
        >
          Done
        </Button>
      )}
    </>
  );
};

export { RateList };
