
import { FC } from "react";
import DisclosureItem from "../../shared/elements/DisclosureItem";
import Alert from "../../shared/overlays/Alert";

interface IProps {
  isParentQuestionnaire: boolean;
}

const StripeBenefits: FC<IProps> = ({ isParentQuestionnaire }) => {
  const stripeBenefits = [
    {
      title: 'Faster payments, on your schedule',
      desc: 'Take payments directly from your learners via credit card, debit card, Apple Pay, and Google Pay. And choose to receive your payments daily, weekly, or monthly.',
      icon: 'pmf-icon-faster-payouts'
    },
    {
      title: 'More security for you & your learners',
      desc: 'Get securely paid into an account of your choosing without carrying cash or sharing your personal details.',
      icon: 'pmf-icon-security'
    },
    {
      title: 'Stay in the loop',
      desc: 'We’ll automatically notify you when a learner pays you. Somebody missed a payment? Give them a nudge directly from your app dashboard — no need to keep checking your bank or calling around to chase payments.',
      icon: 'pmf-icon-stay-loop'
    },
    {
      title: 'No more no-shows',
      desc: 'Stay one step ahead by sending a secure payment link before lessons begin. Your learner pays when they’re ready and you confirm the payment has been received before lessons start.',
      icon: 'pmf-icon-no-more'
    }
  ]

  return (
    <>
      <Alert
        colour="gray"
        icon="pmf-icon-info"
        customDesc={
          <span className="text-sm">
            Please note that you’ll be required to complete a
            <a
              className="underline mx-1"
              href="https://support.stripe.com/questions/know-your-customer-obligations?locale=en-GB"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="">know your customer</span>
            </a>
            check with Stripe
          </span>
        }
      ></Alert>

      {stripeBenefits.map((item, index) =>
        <div key={index}>
          <DisclosureItem
            disclosureCard
            title={item.title}
            disclosureIcon={item.icon}
            disclosureCardSize="md"
            border={isParentQuestionnaire ? 'border' : ''}
          >
            <p className="px-2 py-2">
              {item.desc}
            </p>
          </DisclosureItem>
        </div>)}
    </>
  );
};

export { StripeBenefits };
