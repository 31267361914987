
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react'
import PageHeader from '../../components/shared/elements/PageHeader';
import ContactInfo from './ContactInfo';

const HelpWrapper: FC = () => {

  useEffect(() => {
    document.title = `ADI Network App | Help & Feedback | ADI Network`;
  }, []);
  
  return (
    <>
      <PageHeader title='Help & Feedback' backToURL='/ADI/settings/overview'></PageHeader>

      <ContactInfo isNotAllowed={false}></ContactInfo>
    </>
  )
}

export default HelpWrapper