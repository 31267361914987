import { FC } from 'react'
import { Assessment } from '../../../../../../core/models/assessments/assessment'
import DisclosureItem from '../../../../shared/elements/DisclosureItem'
import Skeleton from "@mui/material/Skeleton";


type Props = {
  assessment: Assessment,
  loading?: boolean
}

const ProgressBreakdown: FC<Props> = ({ assessment, loading }) => {
  return (
    <>
      {loading ?
        <>
          {[...Array(6)].map((_, index) => (
            <div key={index} className="mb-4">
              <DisclosureItem disclosureCard disclosureCardSize="md" loading={loading} />
            </div>
          ))}
        </>
        :
        <>
          {assessment?.sections?.map((section, index) => { 
            return (
              <div key={index} className="mb-4">
                <DisclosureItem 
                  disclosureCard disclosureCardSize="md" title={section?.section_name} count={section?.score} progress={true}>
                      {section.section_items.map((item, index) =>
                        <div key={index} 
                        className="mb-5 last:mb-0 px-3 last:pb-4">
                          <div className='flex justify-between text-darkBlue mb-2'>
                            <p className="text-xs">{item.item_name}</p>
                            <p className="text-xs font-bold">{item.rate}</p>
                          </div>
                          <div aria-hidden="true">
                            <div className="overflow-hidden rounded-full bg-gray-200">
                              <div className="h-3 rounded-full bg-yellow" style={{ width: (item.rate * 100 / 5) + '%' }} />
                            </div>
                          </div>
                        </div>
                      )}
                </DisclosureItem>
              </div>
            )

          })}
        </>
      }
    </>
  )
}

export { ProgressBreakdown }
