import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import * as auth from '../redux/AuthRedux'
import { getSavedSteps } from '../../../../core/services/adi-service'

export function MobileLogin() {
  const dispatch = useDispatch()
  const urlSearchParams = new URLSearchParams(window.location.search);
  let sessionFromURL = Object.fromEntries(urlSearchParams.entries())
  let url = window.location.href

  useEffect(() => {
    checkHasUnverifiedData()
  }, []);


  const checkHasUnverifiedData = () => {
    return getSavedSteps().then(res => {
      if (res.data.results.has_un_verified_imported_data) {
        dispatch(auth.actions.setHasUnVerifiedData(true))
      }
      dispatch(auth.actions.setIsMobile(true))
      dispatch(auth.actions.setIsLogin(true))
      dispatch(auth.actions.login())
      if (url.includes("mobileVersion")) {
        dispatch(auth.actions.setMobileVersion(sessionFromURL.mobileVersion))
      }
    });
  }
  return (
    <>

    </>
  )
}
