import modifiedFetch from "../../../setup/axios/ModifiedFetch";
import { BaseResponse } from "../models/base-response";

const LESSON_URL = process.env.REACT_APP_LESSONS_BASE_URL;

export function getTakenHours(dealId: any): Promise<BaseResponse<any>> {
  return modifiedFetch({
    url: LESSON_URL + "/lessons/taken-hours-details",
    method: "POST",
    data: {
      job_ref: dealId,
    },
  });
}
