import { FC, useState } from "react";
import { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import Modal from "../../shared/overlays/Modal";
import Alert from "../../shared/overlays/Alert";
import Dropdown from "../../shared/forms/Dropdown";
import Input from "../../shared/forms/Input";
import { convertToTimeFormat } from "../../../helpers/DateHelper";
import {
  addOtherEvent,
  getLessonsRecurringOptions,
  updateOtherEvent,
} from "../../../../core/services/job-service";
import { LessonRecurringOptionEnum } from "../../../../core/models/enums/lesson-recurring_option-enum";
import DateInput from "../../shared/forms/DateInput";
import Slider from "../../shared/forms/Slider";
import CheckboxInput from "../../shared/forms/CheckboxInput";
import { useSharedData } from "../../../pages/providers/SharedDataProvider";
import TimeInput from "../../shared/forms/TimeInput";
import dayjs from "dayjs";
import { EditInSeries } from "./EditInSeries";
import {
  scrollToFirstError,
  scrollToTagBySelector,
} from "../../../helpers/ScrollHelper";

type Props = {
  show: any;
  onHide: any;
  refreshParent?: any;
  event?: any;
  isEditMode: boolean;
};

interface OtherEvent {
  event_name: string;
  event_location: string;
  event_date: Date | null;
  time: string;
  event_duration: number;
  is_all_day: boolean;
  recurring_option: number;
  start_date: Date | null;
  end_date: Date | null;
}

const OtherEventModal: FC<Props> = ({
  show,
  onHide,
  refreshParent,
  event,
  isEditMode,
}) => {
  const [recurringOptions, setRecurringOptions] = useState<any>();
  const [editAllEvents, setEditAllEvents] = useState<boolean>(false);
  const [showEditInSeiresOptions, setShowEditInSeiresOptions] =
    useState<boolean>(false);
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const { data } = useSharedData();

  const maxDuration = 360;
  const step = 15;
  const minDuration = 30;
  const defaultDuration = 60;
  const warningMsgs = [
    "You have another lesson at the same time",
    "You have another event at the same time",
    "You have PT on the same time",
  ];

  const updatePersonalSchema = Yup.object().shape({
    event_name: Yup.string().required("Event name is required"),
    event_date: Yup.date().required("Date is required"),
    time: Yup.string().when("is_all_day", {
      is: (is_all_day: boolean) => is_all_day === false,
      then: () => Yup.string().required("Event time is required"),
      otherwise: () => Yup.string().nullable(),
    }),
    event_duration: Yup.number().when("is_all_day", {
      is: (is_all_day: boolean) => is_all_day === false,
      then: () => Yup.number().required("Event duration is required"),
      otherwise: () => Yup.number().nullable(),
    }),
    is_all_day: Yup.boolean().required(),
    recurring_option: Yup.number().when("is_all_day", {
      is: (is_all_day: boolean) => is_all_day === false,
      then: () => Yup.number().required("Make recurring is required"),
      otherwise: () => Yup.number().nullable(),
    }),
    start_date: Yup.date().when("recurring_option", {
      is: (recurring_option: number) =>
        recurring_option !== LessonRecurringOptionEnum.Never,
      then: () => Yup.date().required("Start date is required"),
      otherwise: () => Yup.date().nullable(),
    }),
    end_date: Yup.date().when("recurring_option", {
      is: (recurring_option: number) =>
        recurring_option !== LessonRecurringOptionEnum.Never,
      then: () => Yup.date().required("End date is required"),
      otherwise: () => Yup.date().nullable(),
    }),
  });

  const initialValues: OtherEvent = {
    event_name: "",
    event_location: "",
    event_date: data?.selectedDate ?? null,
    time: "",
    event_duration: defaultDuration,
    is_all_day: false,
    recurring_option: LessonRecurringOptionEnum.Never,
    start_date: data?.selectedDate ?? null,
    end_date: null,
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: updatePersonalSchema,
    onSubmit: (values) => {
      submitData(
        { ...values, check_only: showWarning ? false : true },
        showWarning ? false : true
      );
    },
  });

  useEffect(() => {
    if (show) {
      getRecurringOptions();
      if (event) {
        setShowEditInSeiresOptions(
          isEditMode &&
            event.recurring_option !== LessonRecurringOptionEnum.Never
        );
        formik.setValues({
          event_name: event?.event_name ?? "",
          event_location: event?.event_location ?? "",
          event_date: event?.event_date
            ? moment(event?.event_date).toDate()
            : data?.selectedDate ?? null,
          time:
            event?.event_date && !event?.is_all_day
              ? moment(event?.event_date).format("HH:mm")
              : "",
          event_duration:
            event?.event_duration && event?.event_duration != 0
              ? event?.event_duration * 60
              : defaultDuration,
          is_all_day: event?.is_all_day === 1 ?? false,
          recurring_option:
            event?.recurring_option ?? LessonRecurringOptionEnum.Never,
          start_date: event?.series_start_date
            ? moment(event?.series_start_date).toDate()
            : data?.selectedDate ?? null,
          end_date: event?.series_end_date
            ? moment(event?.series_end_date).toDate()
            : null,
        });
      }
    }
  }, [show, isEditMode]);

  useEffect(() => {
    scrollToFirstError(formik);
  }, [formik.isSubmitting]);

  const getRecurringOptions = () => {
    getLessonsRecurringOptions().then((data) => {
      const recurringOptions = data?.data?.results;
      if (recurringOptions) {
        const mappedRecurringOptions = Array.from(recurringOptions).map(
          (option: any) => {
            return { value: option?.id, label: option.name };
          }
        );
        setRecurringOptions(mappedRecurringOptions);
      }
    });
  };

  const prepareSubmitData = (values: any): any => {
    const formattedTime = values.is_all_day
      ? moment("00:00", "HH:mm")
      : moment(values.time, "HH:mm");
    let submittedData = {
      ...values,
      event_duration: values.event_duration / 60,
      event_date: moment(values.event_date)
        .set("hour", formattedTime.get("hour"))
        .set("minute", formattedTime.get("minute"))
        .format("yyyy-MM-DD HH:mm"),
      start_date: moment(values.start_date).format("yyyy-MM-DD"),
      override_overlap: !showWarning ? false : true,
    };

    if (values.recurring_option !== LessonRecurringOptionEnum.Never) {
      submittedData = {
        ...submittedData,
        end_date: moment(values.end_date).format("yyyy-MM-DD"),
      };
    }

    if (values.is_all_day) {
      submittedData = {
        ...submittedData,
        event_duration: 0,
      };
    }

    return submittedData;
  };

  const submitData = async (values: any, isInitialSubmit: boolean) => {
    formik.setSubmitting(true);
    let response;
    if (event) {
      response = await updateOtherEvent(
        event.id,
        prepareSubmitData({ ...values, edit_entire_series: editAllEvents })
      ).catch((error: any) => {
        onError(error);
      });
    } else {
      response = await addOtherEvent(prepareSubmitData(values)).catch(
        (error: any) => {
          onError(error);
        }
      );
    }
    if (!isEditMode) {
      if (response && isInitialSubmit) {
        submitData({ ...values, check_only: false }, false);
        return;
      }
    }

    if (response && (!isInitialSubmit || isEditMode)) {
      if (refreshParent) {
        refreshParent();
      }
      close();
    }
    formik.setSubmitting(false);
  };

  const onError = (error: any) => {
    if (error && error.errors && error.errors.length > 0) {
      if (warningMsgs.includes(error.errors[0].errorMessage)) {
        setShowWarning(true);
      }
      formik.setStatus(error.errors[0].errorMessage);
    } else {
      formik.setStatus("Sorry, an error has occurred");
    }
    scrollToTagBySelector("#error-alert");
  };

  const close = () => {
    formik.resetForm();
    setShowWarning(false);
    setShowEditInSeiresOptions(false);
    setEditAllEvents(false);
    onHide();
  };

  const increaseDuration = () => {
    if (formik.values.event_duration < maxDuration) {
      formik.setFieldValue(
        "event_duration",
        formik.values.event_duration + step
      );
    }
  };

  const decreaseDuration = () => {
    if (formik.values.event_duration > minDuration) {
      formik.setFieldValue(
        "event_duration",
        formik.values.event_duration - step
      );
    }
  };

  const updateStartRecurringDate = (
    recurringOption: number,
    eventDate: Date
  ) => {
    if (recurringOption !== LessonRecurringOptionEnum.Never)
      formik.setFieldValue("start_date", eventDate ?? new Date());
    else formik.setFieldValue("start_date", null);
  };

  return (
    <Modal
      open={show}
      title={isEditMode ? "Edit Event(s)" : "Add Event(s)"}
      submitText={!showWarning ? "Save" : "Confirm"}
      closeText={!showWarning ? "Cancel" : "Ignore"}
      onClose={close}
      disabled={formik.isSubmitting}
      onSubmit={() => {
        if (isEditMode && showEditInSeiresOptions)
          setShowEditInSeiresOptions(false);
        else formik.handleSubmit();
      }}
    >
      {showEditInSeiresOptions ? (
        <EditInSeries
          editAll={editAllEvents}
          setEditAll={setEditAllEvents}
          isPrivateJob
          type="other event"
        />
      ) : (
        <form onSubmit={formik.handleSubmit}>
          {formik.status && (
            <div id="error-alert">
              <Alert
                colour="orange"
                icon="pmf-icon-info"
                title={formik.status}
              />
            </div>
          )}

          {!showWarning && (
            <div>
              {((event && !editAllEvents) || !event) && (
                <>
                  <div className="-mt-2">
                    <Input
                      {...formik.getFieldProps("event_name")}
                      type="text"
                      label="Name"
                      placeholder="Event name"
                      error={
                        formik.touched.event_name && formik.errors.event_name
                          ? true
                          : false
                      }
                      errorMsg={formik.errors.event_name?.toString()}
                      required={true}
                      name="event_name"
                      disabled={formik.isSubmitting}
                      id="event_name"
                    />
                  </div>

                  <div className="-mb-6">
                    <Input
                      {...formik.getFieldProps("event_location")}
                      type="text"
                      label="Location"
                      placeholder="Event location"
                      error={
                        formik.touched.event_location &&
                        formik.errors.event_location
                          ? true
                          : false
                      }
                      errorMsg={formik.errors.event_location?.toString()}
                      name="event_location"
                      disabled={formik.isSubmitting}
                      id="event_location"
                    />
                  </div>

                  <div className="-mb-4">
                    <DateInput
                      openToDate={formik.values.event_date ?? new Date()}
                      selected={formik.values.event_date}
                      isClearable={false}
                      name="event_date"
                      placeholderText="Event date"
                      id="event_date"
                      disabled={formik.isSubmitting}
                      required={true}
                      onChange={(e) => {
                        formik.setFieldValue("event_date", e);
                        formik.setFieldValue("start_date", e ?? new Date());
                      }}
                      inputLabel="Event date"
                      inputPlaceholder="Event date"
                      inputName="event_date_input"
                      error={
                        formik.touched.event_date && formik?.errors?.event_date
                          ? true
                          : false
                      }
                      errorMsg={formik?.errors?.event_date?.toString()}
                    />
                  </div>

                  <TimeInput
                    {...formik.getFieldProps("time")}
                    label="Event Start Time"
                    error={
                      formik.touched.time && formik.errors.time ? true : false
                    }
                    errorMsg={formik.errors.time}
                    disabled={
                      formik.isSubmitting || formik.values.is_all_day
                        ? true
                        : false
                    }
                    value={dayjs(formik.values.time, "H:mm")}
                    onOpen={() => {
                      formik.setFieldTouched("time");
                    }}
                    minutesStep={5}
                    onChange={(newValue) => {
                      formik.setFieldValue(
                        "time",
                        dayjs(newValue).format("H:mm").toString()
                      );
                    }}
                  />

                  <div className="mb-4">
                    <div className="flex justify-between -mb-2">
                      <label
                        htmlFor="duration"
                        className="text-sm font-medium text-darkBlue"
                      >
                        Duration <span className="text-sm text-red-500">*</span>
                      </label>

                      {(!isEditMode ||
                        (isEditMode &&
                          event?.recurring_option ===
                            LessonRecurringOptionEnum.Never)) && (
                        <div className="flex">
                          <div className="-mt-1">
                            <CheckboxInput
                              name="is_all_day"
                              required={true}
                              label="All day"
                              isSingleCheckbox={true}
                              checked={formik.values.is_all_day === true}
                              disabled={formik.isSubmitting}
                              onChange={(e: any) => {
                                formik.setFieldValue(
                                  "is_all_day",
                                  e.target.checked
                                );
                                if (e.target.checked) {
                                  formik.setFieldValue(
                                    "recurring_option",
                                    LessonRecurringOptionEnum.Never
                                  );
                                  formik.setFieldValue("time", null);
                                  formik.setFieldValue("start_date", null);
                                  formik.setFieldValue("end_date", null);
                                }
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>

                    <Slider
                      decreaseFunction={decreaseDuration}
                      increaseFunction={increaseDuration}
                      step={step}
                      minValue={minDuration}
                      maxValue={maxDuration}
                      rangeValues={[formik.values.event_duration]}
                      onChange={(e: any) =>
                        formik.setFieldValue("event_duration", e[0])
                      }
                      showInput={false}
                      formik={formik}
                      formatFun={(index: number) =>
                        convertToTimeFormat(formik.values.event_duration)
                      }
                      minValueFormatted={`${minDuration} mins`}
                      maxValueFormatted={`${maxDuration / 60} hours`}
                      disabled={
                        formik.isSubmitting || formik.values.is_all_day
                          ? true
                          : false
                      }
                    />
                  </div>
                </>
              )}
              {((event && editAllEvents) || !event) && (
                <>
                  {isEditMode &&
                    formik.values.recurring_option ===
                      LessonRecurringOptionEnum.Never && (
                      <Alert
                        colour="orange"
                        icon="pmf-icon-info"
                        title="Warning: All other events will be deleted!"
                      />
                    )}
                  <Dropdown
                    {...formik.getFieldProps("recurring_option")}
                    options={recurringOptions}
                    label="Make Recurring"
                    placeholder="Make Recurring"
                    error={
                      formik.touched.recurring_option &&
                      formik.errors.recurring_option
                        ? true
                        : false
                    }
                    errorMsg={formik.errors.recurring_option?.toString()}
                    required={!formik.values.is_all_day}
                    name="recurring_option"
                    disabled={
                      formik.isSubmitting || formik.values.is_all_day
                        ? true
                        : false
                    }
                    id="recurring_option"
                    value={recurringOptions?.find(
                      (c: any) => c.value === formik.values.recurring_option
                    )}
                    onChange={(e) => {
                      formik.setFieldValue("recurring_option", e?.value);
                      if (formik.values.event_date && !formik.values.start_date)
                        updateStartRecurringDate(
                          e?.value,
                          formik.values.event_date
                        );
                    }}
                    onBlur={() => {
                      formik.setFieldTouched("recurring_option");
                    }}
                  />

                  {formik.values.recurring_option &&
                    formik.values.recurring_option !==
                      LessonRecurringOptionEnum.Never && (
                      <>
                        <div className="-my-4">
                          <DateInput
                            openToDate={formik.values.start_date}
                            selected={formik.values.start_date}
                            isClearable={false}
                            name="start_date"
                            placeholderText="Start date"
                            id="start_date"
                            disabled={!editAllEvents && !isEditMode}
                            required={true}
                            onChange={(e) => {
                              if (e != null) {
                                formik.setFieldValue("start_date", e);
                              } else {
                                formik.setFieldValue("start_date", null);
                              }
                            }}
                            inputLabel="Start Date"
                            inputPlaceholder="Start Date"
                            inputName="start_date"
                            error={formik?.errors?.start_date ? true : false}
                            errorMsg={formik?.errors?.start_date?.toString()}
                          />
                        </div>

                        <DateInput
                          openToDate={formik.values.event_date ?? new Date()}
                          selected={formik.values.end_date}
                          isClearable={false}
                          minDate={formik.values.event_date}
                          name="end_date"
                          id="end_date"
                          placeholderText="End date"
                          disabled={formik.isSubmitting}
                          required={true}
                          onChange={(e) => formik.setFieldValue("end_date", e)}
                          inputLabel="End Date"
                          inputPlaceholder="End Date"
                          inputName="end_date"
                          error={formik?.errors?.end_date ? true : false}
                          errorMsg={formik?.errors?.end_date?.toString()}
                        />
                      </>
                    )}
                </>
              )}
            </div>
          )}
        </form>
      )}
    </Modal>
  );
};

export { OtherEventModal };
