import { FC } from "react";
import Modal from "../../shared/overlays/Modal";
import OtherEventCard from "../../shared/cards/OtherEventCard";
import moment from "moment";
import StackedCardWithActionsMenu from "../../shared/data/StackedCardWithActionsMenu";
interface IProps {
  show: any;
  onClose: any;
  event: any;
  updateData: () => void;
}

const OtherEventDetailsModal: FC<IProps> = (props) => {
  const { show, onClose, event, updateData } = props;

  const mapData = (data: any) => {
    const event_date_formatted = moment(
      data?.event_date,
      "yyyy-MM-DD HH:mm:ss"
    );
    return {
      ...data,
      date: event_date_formatted.format("DD MMM, yyyy hh:mm a"),
      to_time_str: event_date_formatted
        .add(parseFloat(data?.event_duration) * 60, "minutes")
        .format("hh:mm a"),
      series_end_date: moment(data?.series_end_date, "yyyy-MM-DD HH:mm").format(
        "DD MMM, yyyy"
      ),
    };
  };

  return (
    <>
      <Modal
        open={show}
        onClose={onClose}
        title={"Other Event Details"}
        hideClose={false}
        hideSubmit={true}
      >
        {!event?.isIntegratedCalendar ? (
          <OtherEventCard
            event={mapData(event)}
            refreshParent={() => {
              onClose();
              updateData();
            }}
          />
        ) : (
          <div key={"otherEvent"} className="py-2">
            <div className="flex flex-col w-full rounded shadow bg-white p-1 mb-4">
              <StackedCardWithActionsMenu
                title={event?.name}
                icon="pmf-icon-calendar"
                loading={false}
                showAction={false}
                description={moment(event?.date).format("DD MMM, yyyy")}
              ></StackedCardWithActionsMenu>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export { OtherEventDetailsModal };
