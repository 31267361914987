import { FC, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { getConnectAccount, updateAccount } from '../../../../../core/services/connect-service';
import Modal from '../../../shared/overlays/Modal';
import RadioInput from '../../../shared/forms/RadioInput';
import Alert from '../../../shared/overlays/Alert';

interface IProps {
  show: any
  onHide: any
}

const AccountSettingsModal: FC<IProps> = ({ show, onHide }) => {
  const [account, setAccount] = useState<any>(null);
  const [isSubmitted, setSubmitted] = useState(false)

  const payoutFrequencyTypes = [{
    value: "daily",
    label: 'Daily',
    description: 'Your account balance will be sent to you daily'
  }, {
    value: "weekly",
    label: 'Weekly',
    description: 'Your account balance will be sent to you every Friday'
  }, {
    value: "monthly",
    label: 'Monthly',
    description: 'Your account balance will be sent to you on the 1st of each month'
  }];

  const updateSettingsSchema = Yup.object().shape({
    payout_frequency: Yup.string()
      .required('This field is required')
      .nullable()
  })

  const formik = useFormik({
    initialValues: {
      payout_frequency: account?.payout_frequency,
    },
    enableReinitialize: true,
    validationSchema: updateSettingsSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true)
      setSubmitted(true);
      updateAccount({ "payout_frequency": values.payout_frequency }).then(() => {
        setSubmitted(false);
        setStatus("")
        setSubmitting(false)
        close();
      })
        .catch(() => {
          setSubmitting(false)
          setStatus("Error occurred")
          setSubmitted(false);
        })

    },
  })

  const getAccount = () => {
    getConnectAccount()
      .then((res) => {
        setAccount(res.data);
      })
      .catch(() => {
      })
  };

  useEffect(() => {
    if (show) {
      getAccount();
    }
  }, [show]);

  const close = () => {
    formik.resetForm();
    onHide();
  }

  return (
    <>
      <Modal
        open={show}
        title="Account Settings"
        submitText='Save'
        closeText='Cancel'
        onClose={close}
        onSubmit={formik.handleSubmit}
        disabled={formik.isSubmitting}
      >
        <form>
          <h3 className='text-base font-bold '>Payment Schedule</h3>
          <div>
            <RadioInput
              label="Please select how often you'd like to get paid"
              name="payout_frequency"
              options={payoutFrequencyTypes.map(type => {
                return {
                  value: type.value,
                  label: type.label,
                  description: type.description
                }
              })}
              error={(formik.touched.payout_frequency && formik.errors.payout_frequency) ? true : false}
              errorMsg={formik.errors.payout_frequency?.toString()}
              onClick={(value) => formik.setFieldValue('payout_frequency', value)}
              value={formik.values.payout_frequency}
              disabled={formik.isSubmitting || isSubmitted}
              required={false}
            />
          </div>
          <Alert colour='blue' title='Once we’ve released the payment to you, it may take up to three working days for the funds to arrive in your account.' icon='pmf-icon-info' />
        </form>
      </Modal>
    </>
  )
}

export default AccountSettingsModal;