import { FC, useEffect, useState } from "react";
import { addLocationBid } from "../../../../core/services/job-service";
import toast, { Toaster } from "react-hot-toast";
import Notification from "../../shared/overlays/Notification";
import Button from "../../shared/elements/Button";
import PickupLocationMap from "../../shared/PickupLocationMap";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
interface IProps {
  jobId: string | undefined;
  onClose: any;
  onUpdate: any;
  studentLocation: any;
  jobUpdated: () => void;
}

const RegisterLocationInterestDialog: FC<IProps> = (props) => {
  const zoom = 11;
  const radius = 5000;
  const [loading, setLoading] = useState<boolean>(false);
  const [pickup, setPickup] = useState<{ lat: number; lng: number }>();
  useEffect(() => {}, []);

  async function submitForm() {
    if (pickup && pickup.lat && pickup.lng) {
      setLoading(true);
      toast.custom(
        () => (
          <Notification
            colour="gray"
            title={"Adding your bid..."}
            description={""}
            Icon={ExclamationCircleIcon}
          />
        ),
        {
          duration: 5000,
          position: "top-center",
        }
      );
      const res = await addLocationBid(
        {
          pickup_location_long: pickup.lng,
          pickup_location_lat: pickup.lat,
        },
        props.jobId
      ).catch(() => {
        return;
      });
      setLoading(false);
      if (!res) {
        toast.error("Failed to add bid!");
      } else {
        toast.success("Bid added successfully!");
        props.jobUpdated();
        props.onUpdate();
      }
    } else {
      toast.error("Invalid location!");
    }
  }

  function closeModal() {
    setLoading(false);
    toast.dismiss();
    props.onClose();
  }

  return (
    <>
      <Toaster />
      <form>
        <div className="flex flex-col text-darkBlue gap-y-3">
          <h3 className="text-xl font-semibold font-titlef">
            Register Interest
          </h3>
          <p>
            We&apos;ll send this request to the learner and update you when they
            respond.
          </p>
          <p>
            <strong>Note:</strong> Submitting this request does not guarantee
            you&apos;ll be allocated the course.
          </p>
          <p>
            If the learner accepts the change, we ask that you accept the course{" "}
            <strong>within 72 hours</strong>.
          </p>
        </div>

        <div className="my-3">
          <PickupLocationMap
            center={props.studentLocation}
            zoom={zoom}
            radius={radius}
            setPickup={setPickup}
            canEdit={true}
          />
        </div>
        <div className="text-darkBlue">
          Please place the car in the suggested pick up location, using the map
          above.
        </div>

        <div className="sticky w-full bottom-0 left-0 px-0 py-4 bg-white z-[11]">
          <div className="w-full flex items-center justify-between space-x-2">
            <Button
              colour="yellow"
              type="submit"
              className="w-1/2"
              size="large"
              onClick={submitForm}
              disabled={loading}
            >
              Confirm
            </Button>
            <Button
              colour="outline"
              type="button"
              className="w-1/2"
              size="large"
              onClick={closeModal}
              disabled={loading}
            >
              Back
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export { RegisterLocationInterestDialog };
