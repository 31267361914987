import { FC, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { createClient } from "../../../../../prismic";
import { filter } from "@prismicio/client";
import ArticleTile from "../_shared/ArticleTile";
import { PlusIcon } from "@heroicons/react/20/solid";
import Button from "../../../components/shared/elements/Button";
import { useQuery } from "../../../helpers/QueryParamsHelper";

type Props = {
  authorId: string;
  authorUid?: string;
};

const AuthorArticlesList: FC<Props> = ({ authorId, authorUid }) => {
  const history = useHistory();
  const [articles, setArticles] = useState<Array<any>>([]);
  const [totalResults, setTotalResults] = useState<number>(0);
  const [resultsSize, setResultsSize] = useState<number>(0);
  const query = useQuery();
  const [pageCounter, setPageCounter] = useState(+(query.get("page") ?? 20));

  const loadMoreArticles = async () => {
    if (resultsSize < totalResults) {
      setPageCounter(pageCounter + 20);
    } else {
      setPageCounter(totalResults);
    }
  };

  // Initial load of articles
  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const articlesResponse = await createClient.get({
          fetchLinks: "category.name",
          filters: [
            filter.at("document.type", "cpd_article"),
            filter.at("my.cpd_article.author_data", authorId),
          ],
          orderings: {
            field: "document.first_publication_date",
            direction: "asc",
          },
          pageSize: pageCounter,
        });
        const mappedArticles = articlesResponse?.results?.map((result) => ({
          id: result.id,
          // Map additional properties here
          thumbImage: result.data.thumb_img,
          categoryName: result.data.categories_link.data.name,
          articleTitle: result.data.title,
          authorName: result.data.author,
          releaseDate: result.data.date,
          articleShortDescription:
            result.data.blurb.length > 0
              ? result.data.blurb
                  .map((paragraph: any) => paragraph.text)
                  .join(" ")
              : result.data.content[0].text,
          uid: result.uid,
          mostPopular: result.data.set_in_most_popular,
          content: result.data.content,
        }));

        setArticles(mappedArticles);
        setTotalResults(articlesResponse.total_results_size);
        setResultsSize(articlesResponse.results_size);
      } catch (error) {
        console.error("Error while fetching articles:", error);
      }
    };

    fetchArticles();
  }, [pageCounter, createClient]);

  return (
    <>
      <>
        {/* <ArticleTile loading={true}/> */}
        <div className="grid grid-cols-1 gap-y-9 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-8 p-3">
          {articles?.map((item, index) => (
            <ArticleTile
              loading={false}
              key={index}
              imgURL={item.thumbImage}
              category={item.categoryName}
              title={item.articleTitle}
              shortDesc={item.articleShortDescription}
              author={{}}
              uid={item.uid}
              content={item.content}
              onShowArticleClick={() => {
                const category = query.get("category");
                if (query.get("category")) {
                  history.push(
                    `/ADI/cpd/article/${item?.uid}?page=${pageCounter}&authorId=${authorUid}&category=${category}`
                  );
                } else {
                  history.push(
                    `/ADI/cpd/article/${item?.uid}?page=${pageCounter}&authorId=${authorUid}`
                  );
                }
              }}
            />
          ))}
        </div>

        <div className="grid grid-cols-6 gap-4 mx-auto w-full mt-6">
          {resultsSize < totalResults && (
            //Load more button
            <Button
              className="col-span-4 col-start-2"
              colour="outline"
              onClick={loadMoreArticles}
              size="large"
            >
              <div className="flex items-center text-lg font-semibold">
                LOAD MORE <PlusIcon className="w-5 h-5 ms-2" />
              </div>
            </Button>
          )}
          <p className="col-span-6 text-[#616161] text-sm text-center mb-5">
            Showing {resultsSize} of {totalResults}
          </p>
        </div>
      </>
    </>
  );
};

export default AuthorArticlesList;
