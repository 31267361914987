import { FC, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import RadioInputGroup from "../../../../shared/forms/RadioInputGroup";
import { OTHER_ID } from "../../../../../../core/models/enums/reschedule-reasons-enum";
import {
  getRecheduleReasons,
  rescheduleJobTest,
} from "../../../../../../core/services/job-service";
import Modal from "../../../../shared/overlays/Modal";
import { formatFromDate } from "../../../../../helpers/DateHelper";
import Input from "../../../../shared/forms/Input";
import Alert from "../../../../shared/overlays/Alert";
import toast from "react-hot-toast";
import Notification from "../../../../shared/overlays/Notification";
import {
  ExclamationCircleIcon,
  CheckCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";

interface IProps {
  show: any;
  onHide: any;
  jobData: any;
  adiJobId: any;
}
const todayDate = new Date();
const requiredMsg = "This field is required";
const rescheduleReasonRequired = "A schedule Reason is required";
const validationSchema = Yup.object().shape({
  date: Yup.date().required("Date is required").nullable(),
  reschedule_reason_id: Yup.number().required(rescheduleReasonRequired),
  note: Yup.string()
    .max(255)
    .when("reschedule_reason_id", ([reschedule_reason_id], schema) => {
      return reschedule_reason_id == OTHER_ID
        ? schema.required(requiredMsg)
        : schema;
    }),
});

const RescheduleTestDialog: FC<IProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const [rescheduleReasons, setRescheduleReasons] = useState<Array<any>>([]);
  const imagesReference = [
    {
      label: "Learner's availability",
      value: "std-availability",
    },
    {
      label: "My availability",
      value: "my-availability",
    },
    {
      label: "Learner needs more lessons",
      value: "std-lessons",
    },
    {
      label: "Test clash",
      value: "test-clash",
    },
    {
      label: "Unforeseen circumstances",
      value: "unforeseen-circumstances",
    },
  ];

  useEffect(() => {
    setLoading(true);
    getRecheduleReasons()
      .then((res) => {
        setRescheduleReasons(res.data);
      })
      .finally(() => setLoading(false));
  }, []);

  const getImgRef = (reasonName: string) => {
    return (
      "/assets/media/svg/reschedule-reasons/" +
      imagesReference?.filter((ref) => ref.label === reasonName)[0]?.value
    );
  };

  let radioOption = rescheduleReasons.map((reason: any) => {
    return {
      label: reason.name,
      value: reason.id,
      img: getImgRef(reason.name).concat(".svg"),
      nonselectImg: getImgRef(reason.name).concat("-grey.svg"),
    };
  });

  const formik = useFormik({
    initialValues: {
      note: undefined,
      reschedule_reason_id: undefined,
      date: undefined,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit,
  });

  function onSubmit(
    values: { note: string | undefined; reschedule_reason_id: any; date: any },
    { setStatus, setSubmitting }: any
  ) {
    setStatus(null);
    let loadingId = toast.custom(
      () => (
        <Notification
          colour="gray"
          title={"Rescheduling test date..."}
          description={""}
          Icon={ExclamationCircleIcon}
        />
      ),
      {
        duration: 5000,
        position: "top-center",
      }
    );

    rescheduleJobTest(
      {
        requested_test_date: formatFromDate(values.date),
        reschedule_reason_id: values.reschedule_reason_id,
        note: values.note,
        reschedule_reason_name: rescheduleReasons.find(
          (a) => a.id == values.reschedule_reason_id
        ).name,
      },
      props.adiJobId
    )
      .then((data: any) => {
        props.onHide();
        setSubmitting(false);
        formik.setSubmitting(false);
        setStatus(null);
        toast.custom(
          () => (
            <Notification
              colour="green"
              title={"Saved successfully"}
              description={""}
              Icon={CheckCircleIcon}
            />
          ),
          {
            duration: 5000,
            position: "top-center",
            id: loadingId,
          }
        );
      })
      .catch((response: any) => {
        setSubmitting(false);
        formik.setSubmitting(false);
        if (response?.errors?.length) {
          formik.setStatus(response.errors[0]);
        } else {
          formik.setStatus("Sorry, an error has occurred");
        }

        toast.custom(
          () => (
            <Notification
              colour="red"
              title={"Failed to reschedule test date!"}
              description={""}
              Icon={ExclamationTriangleIcon}
            />
          ),
          {
            duration: 5000,
            position: "top-center",
            id: loadingId,
          }
        );
      });
  }

  const setDateValue = (e: any) => {
    if (e != null) {
      formik.setFieldValue("date", e);
    } else {
      formik.setFieldValue("date", null);
    }
  };

  const onReasonChanged = (reason: any) => {
    formik.setFieldValue("reschedule_reason_id", reason);
  };

  useEffect(() => {
    formik.resetForm();
  }, [props.show]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Modal
          open={props.show}
          onSubmit={formik.handleSubmit}
          submitText="Reschedule Test"
          closeText="Cancel"
          title={"Rescheduling " + (props.jobData?.student_first_name ?? props.jobData?.student_surname) + "’s test"}
          onClose={props.onHide}
          disabled={formik.isSubmitting}
        >
          <div className="d-flex flex-column align-items-start justify-content-start">
            {/* ---- */}
            {formik.status ? (
              <div className="mb-lg-15 alert alert-danger">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            ) : (
              <></>
            )}

            <h2 className="text-base font-bold leading-6 text-darkBlue mb-3">
              Why does this test need to be pushed back?
            </h2>

            <RadioInputGroup
              name="reasons"
              label={""}
              options={radioOption}
              onChange={(reason) => onReasonChanged(reason)}
              value={formik.values.reschedule_reason_id}
              disabled={formik.isSubmitting}
              error={
                formik.touched.reschedule_reason_id &&
                formik.errors.reschedule_reason_id != undefined
              }
              errorMsg={formik.errors.reschedule_reason_id}
            />

            <Input
              {...formik.getFieldProps("note")}
              type="text"
              label="Notes"
              error={formik.touched.note && formik.errors.note ? true : false}
              errorMsg={formik.errors.note}
              name="note"
              onChange={formik.handleChange}
              disabled={formik.isSubmitting}
              id="note"
            />
          </div>
          <Alert
            colour="orange"
            icon="pmf-icon-info"
            title="Upon requesting to reschedule your learner's test, the learner will receive an email to seek their permission before proceeding with finding a new date"
          />

          <div className="d-flex flex-wrap align-items-start justify-content-between flex-column mt-4">
            <h2 className="text-base font-bold leading-6 text-darkBlue mb-3">
              Preferred Test Date
            </h2>
            <div className="data-picker-parent w-100">
              <DatePicker
                name="date"
                id="date"
                autoComplete="off"
                minDate={todayDate}
                disabled={formik.isSubmitting || loading}
                selected={formik.values.date}
                className="form-group col-12"
                onChange={(update) => {
                  setDateValue(update);
                }}
                inline
                calendarClassName="w-full pt-5"
                clearButtonClassName="!h-fit !top-[42%] after:!bg-white after:ring-1 after:ring-darkBlue after:!text-darkBlue"
                popperClassName="!static !pt-0 w-full !translate-x-0"
                wrapperClassName="-mb-3 w-full"
              />
            </div>
            <div className="fv-plugins-message-container mb-2">
              {formik.touched.date && formik.errors.date && (
                <div className="fv-help-block">
                  <span role="alert" className="text-danger">
                    {formik.errors.date}
                  </span>
                </div>
              )}
            </div>
          </div>
          <Alert
            icon=""
            title="We will arrange a new test as close as possible to the selected
                date. We can’t guarantee test appointments on particular dates."
            colour="gray"
          />
        </Modal>
      </form>
    </>
  );
};

export { RescheduleTestDialog };
