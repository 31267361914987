import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import * as Yup from "yup";
import DisclosureItem from "../../../components/shared/elements/DisclosureItem";
import { useEffect, useState } from "react";

const passwordLength = 8;
const passMinLengthMsg =
  "Password must have at least " + passwordLength + " characters";
const numberRequired = "Password must have numeric characters";
const lowercaseRequired = "Password must have lowercase characters";
const uppercaseRequired = "Password must have uppercase characters";
const symbolRequired = "Password must have symbol characters";

const passwordRequirements = [
  passMinLengthMsg,
  numberRequired,
  symbolRequired,
  lowercaseRequired,
  uppercaseRequired,
];

export const passwordYup = Yup.string()
  .min(passwordLength, passMinLengthMsg)
  .matches(new RegExp(".*\\d.*"), numberRequired)
  .matches(new RegExp(".*[a-z].*"), lowercaseRequired)
  .matches(new RegExp(".*[A-Z].*"), uppercaseRequired)
  .matches(new RegExp(".*\\W.*"), symbolRequired)
  .required("Password is required");

export type PasswordRequirementsProps = {
  currentErrors: Array<string>;
  expand: boolean;
};

export function PasswordRequirements({
  currentErrors = [],
  expand
}: PasswordRequirementsProps) {
  const[showRequirements, setShowRequirements] = useState(false);

  useEffect(() => {    
    if(!showRequirements && expand) {
      setShowRequirements(expand);
    }
  }, [expand])
  
  return (
    <div className="-mt-5 -mb-1">
      <DisclosureItem
        title={<span className="text-sm">Password requirements</span>}
        disclosureCard
        disclosureCardSize="xs"
        expanded={showRequirements}
      >
        <ul>
          {passwordRequirements.map((item, index) => {
            return (
              <li key={index} className="text-sm flex items-start mt-[3px]">
                <span>
                  {currentErrors.includes(item) ? (
                    <XMarkIcon className="h-4 w-4 text-red-600 me-1 mt-[3px]" />
                  ) : (
                    <CheckIcon className="h-4 w-4 text-emerald-600 me-1 mt-[3px]" />
                  )}
                </span>
                <span
                  className={
                    currentErrors.includes(item)
                      ? "text-red-600"
                      : "text-emerald-600"
                  }
                >
                  {item}
                </span>
              </li>
            );
          })}
        </ul>
      </DisclosureItem>
    </div>
  );
}
