import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { fetchPrismicData } from "../../../../../prismic";
import {
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  IconButton,
} from "@mui/material";
import { ClearIcon } from "@mui/x-date-pickers";
import { useHistory } from "react-router-dom";
import { useQuery } from "../../../helpers/QueryParamsHelper";

interface IProps {
  setSort: any;
  setCategory: any;
  handleClearCategoryClick: () => void;
  handleClearSortClick: () => void;
}

const CPDFilter = ({
  setCategory,
  setSort,
  handleClearCategoryClick,
  handleClearSortClick,
}: IProps) => {
  const [categories, setcategories] = useState<Array<any>>([]);
  const [selectedSort, setSelectedSort] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const history = useHistory();
  const query = useQuery();
  const sortBy = [
    { value: "newest", label: "Newest" },
    { value: "oldest", label: "Oldest" },
    { value: "author", label: "Author A-Z" },
    { value: "title", label: "Title A-Z" },
  ];

  useEffect(() => {
    const categoryUid = query.get("category");
    const fetchData = async () => {
      const contentType = "category"; // Replace with your content type
      const results = await fetchPrismicData(contentType);
      setcategories(
        results?.map((category: any) => {
          return {
            uid: category.uid,
            value: category.data.name,
            id: category.id,
          };
        })
      );
      if (categoryUid) {
        const categoryId = results?.find((e) => e.uid == categoryUid)?.id;
        if (categoryId) setSelectedCategory(categoryId);
      }
    };
    fetchData();
  }, []);

  const handleChange = (event: any) => {
    setSelectedCategory(event.target.value);
    setCategory(event);
    const categoryUid = categories.find(
      (e) => e.id === event.target.value
    )?.uid;
    query.set("category", categoryUid);
    history.replace({
      search: query.toString(),
    });
  };

  const handleSortChange = (event: any) => {
    setSelectedSort(event.target.value);
    setSort(event);
  };

  return (
    <>
      <div className="grid grid-cols-2 gap-4 mb-6">
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-helper-label">Category</InputLabel>
          <Select
            id="category_filter"
            value={selectedCategory}
            label="Category"
            onChange={handleChange}
            className="bg-white cursor-pointer !leading-[44px]"
            endAdornment={
              <IconButton
                sx={{
                  display: selectedCategory ? "" : "none",
                  width: "20px",
                  height: "20px",
                }}
                onClick={() => {
                  setSelectedCategory("");
                  handleClearCategoryClick();
                }}
              >
                <ClearIcon sx={{ width: "16px", height: "16px" }} />
              </IconButton>
            }
            sx={{
              "& .MuiSelect-iconOutlined": {
                display: selectedCategory ? "none" : "",
              },
              ".MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl":
                {
                  fontSize: "14px",
                  fontWeight: 700,
                  fontFamily: "Poppins, sans-serif",
                  color: "#163A5E",
                  lineHeight: 1,
                },
              height: 44,
              border: "0px",
              borderRadius: "50px",
              "& .MuiSelect-nativeInput": {
                top: 0,
              },
              "& .MuiInputBase-input": {
                fontSize: "14px",
                fontWeight: 700,
                fontFamily: "Poppins, sans-serif",
                color: "#163A5E",
                padding: "0px 8px",
                height: 44,
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
            }}
          >
            {categories?.map((item, index) => (
              <MenuItem key={index} value={item?.id}>
                {item?.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-helper-label">Sort by</InputLabel>
          <Select
            id="sort_by"
            value={selectedSort}
            label="Sort by"
            onChange={handleSortChange}
            className="bg-white cursor-pointer !leading-[44px]"
            endAdornment={
              <IconButton
                sx={{
                  display: selectedSort ? "" : "none",
                  width: "20px",
                  height: "20px",
                }}
                onClick={() => {
                  setSelectedSort("");
                  handleClearSortClick();
                }}
              >
                <ClearIcon sx={{ width: "16px", height: "16px" }} />
              </IconButton>
            }
            sx={{
              "& .MuiSelect-iconOutlined": {
                display: selectedSort ? "none" : "",
              },
              height: 44,
              border: "0px",
              borderRadius: "50px",
              lineHeight: 1,
              ".css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
                fontSize: "14px",
                fontWeight: 700,
                fontFamily: "Poppins, sans-serif",
                color: "#163A5E",
                lineHeight: 1,
              },
              "& .MuiInputBase-input": {
                fontSize: "14px",
                fontWeight: 700,
                fontFamily: "Poppins, sans-serif",
                color: "#163A5E",
                padding: "0px 16px",
                lineHeight: "20px",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
            }}
          >
            {sortBy?.map((item, index) => (
              <MenuItem key={index} value={item?.value}>
                {item?.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </>
  );
};

export default CPDFilter;
