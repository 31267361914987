import React, { FC, useState } from "react";
import { AvailabilityModel } from "../../../../core/models/availability-modal";
import { DayModel } from "../../../../core/models/day-model";
import { getTimes } from "./../../../../core/services/date-service";
import Dropdown from "../../shared/forms/Dropdown";
import Button from "../../shared/elements/Button";
import { PlusSmallIcon, TrashIcon } from "@heroicons/react/24/outline";
import CheckboxInput from "../../shared/forms/CheckboxInput";
import { CustomDropdown } from "../../shared/forms/CustomDropdown";
import Skeleton from "@mui/material/Skeleton";

interface IProps {
  weekDays?: Array<DayModel>;
  availabilityList: Array<AvailabilityModel>;
  formik?: any;
  submitted?: boolean;
  overLap: Array<boolean>;
  loading?: boolean;
  title?: string;
}
const Availability: FC<IProps> = ({
  weekDays,
  availabilityList,
  formik,
  submitted,
  overLap,
  loading = false,
  title,
}) => {
  const [selectedDays, setSelectedDays] = useState<Array<number>>([]);

  function onDayChanged(
    e: React.ChangeEvent<HTMLInputElement>,
    dayId: number
  ): void {
    let list = formik.values.availability;
    if (!e.target.checked) {
      let dataWithoutRemoved = list.filter(function (a: AvailabilityModel) {
        return a.day_id != dayId;
      });
      formik.setFieldValue("availability", dataWithoutRemoved);
    } else {
      addAvailabilitySlot(dayId);
    }
  }

  const addAvailabilitySlot = (dayId: number) => {
    let tempSlot = formik.values.availability;
    let newSlot = new AvailabilityModel();
    newSlot.day_id = dayId;
    tempSlot.push(newSlot);
    formik.setFieldValue("availability", tempSlot);
  };

  const onFromTimeChanged = (e: any, slot: AvailabilityModel) => {
    if (formik.isSubmitting) {
      return;
    }
    if (e.value != null) {
      let availabilityData = formik.values.availability;
      let updatedSlotIndex = availabilityData.indexOf(slot);
      availabilityData[updatedSlotIndex].from_time = e.value;
      formik.setFieldValue("availability", availabilityData);
    }
  };

  const onToTimeChanged = (e: any, slot: AvailabilityModel) => {
    if (formik.isSubmitting) {
      return;
    }
    if (e.value != null) {
      let availabilityData = formik.values.availability;
      let updatedSlotIndex = availabilityData.indexOf(slot);
      availabilityData[updatedSlotIndex].to_time = e.value;
      formik.setFieldValue("availability", availabilityData);
    }
  };

  const removeAvailabilitySlot = (slot: AvailabilityModel) => {
    if (formik.isSubmitting) {
      return;
    }
    const temp = formik.values.availability;
    let deletedIndex = temp.indexOf(slot);

    temp.splice(deletedIndex, 1);
    formik.setFieldValue("availability", temp);
  };

  const copy = (dayId: number) => {
    handleCopy(selectedDays, dayId);
    setSelectedDays([]);
  };

  const handleCopy = (selectedDaysToCopy: Array<number>, dayId: number) => {
    if (formik.isSubmitting) {
      return;
    }

    let listData = formik.values.availability;

    let sourceDayData = listData.filter(function (a: AvailabilityModel) {
      return a.day_id == dayId;
    });
    selectedDaysToCopy.forEach((dayId) => {
      sourceDayData.forEach((availability: AvailabilityModel) => {
        let newCopySlot = new AvailabilityModel();
        newCopySlot.day_id = dayId;
        newCopySlot.from_time = availability.from_time;
        newCopySlot.to_time = availability.to_time;

        let exists =
          listData.findIndex(
            (b: AvailabilityModel) =>
              b.day_id == dayId &&
              (b.from_time == newCopySlot.from_time ||
                b.to_time == newCopySlot.to_time)
          ) > -1;

        if (!exists) {
          listData.push(newCopySlot);
        }
      });
    });

    formik.setFieldValue("availability", listData);
  };

  const handleSelect = (dayIndex: number) => {
    if (
      selectedDays.filter((selectedDay) => selectedDay == dayIndex).length == 0
    ) {
      setSelectedDays([...selectedDays, dayIndex]);
    } else {
      setSelectedDays(
        selectedDays.filter((selectedDay) => selectedDay != dayIndex)
      );
    }
  };

  var daysList = weekDays?.map(function (day, key) {
    return (
      <div key={key}>
        <div className="flex justify-between items-center">
          <CheckboxInput
            loading={loading}
            name={day.day_name ?? ""}
            required={false}
            label={day.day_name ?? ""}
            isSingleCheckbox={true}
            checked={availabilityList.findIndex((b) => b.day_id == day.id) > -1}
            onChange={(e: any) => onDayChanged(e, day.id)}
            disabled={formik.isSubmitting}

          // value={day.id}
          />

          {availabilityList?.filter(function (a) {
            return a.day_id == day.id;
          }).length > 0 ? (
            <div key={key + "btnCopy"} className="flex">
              {
                <Button
                  type="button"
                  onClick={() => addAvailabilitySlot(day.id)}
                  size="fit"
                  fitWidth={true}
                  colour="transparent"
                  Icon={PlusSmallIcon}
                ></Button>
              }

              <div className="ms-2">
                {loading ? (
                  <Skeleton
                    width={30}
                    height={30}
                    variant="circular"
                    className="me-2"
                  />
                ) : (
                  <CustomDropdown
                    options={(weekDays ?? []).map((item) => {
                      return {
                        title: item.day_name,
                        id: item.id,
                        isChecked:
                          item.day_name == day.day_name ||
                          selectedDays.includes(item.id),
                        isDisabled: item.day_name == day.day_name,
                      };
                    })}
                    sourceId={day.id}
                    applyCopy={copy}
                    handleSelect={handleSelect}
                    onClose={() => setSelectedDays([])}
                  />
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>

        {loading ? (
          <div className="grid grid-cols-11 space-x-2 items-center">
            <div className="col-span-5">
              <Skeleton height={40} />
            </div>
            <div className="col-span-5">
              <Skeleton height={40} />
            </div>
            <Skeleton
              width={30}
              height={30}
              variant="circular"
              className="me-2"
            />
          </div>
        ) : availabilityList.filter(function (a) {
          return a.day_id == day.id;
        }).length > 0 ? (
          <div className="mt-3 mb-2">
            {availabilityList
              .filter(function (a) {
                return a.day_id === day.id;
              })
              .map(function (slot, i) {
                return (
                  <div key={key + i + "slot"}>
                    <div className="flex items-start justify-between mb-4">
                      {loading ? (
                        <>
                          <div className="grid grid-cols-11 space-x-2 items-center">
                            <div className="col-span-5">
                              <Skeleton height={40} />
                            </div>
                            <div className="col-span-5">
                              <Skeleton height={40} />
                            </div>
                            <Skeleton
                              width={30}
                              height={30}
                              variant="circular"
                              className="me-2"
                            />
                          </div>
                        </>
                      ) : (
                        <div>
                          <div className="grid grid-cols-11 space-x-2 items-start">
                            <div className="-my-5 col-span-5">
                              <Dropdown
                                options={getTimes(5, 22.5, 30)}
                                onChange={(newValue) =>
                                  onFromTimeChanged(newValue, slot)
                                }
                                value={
                                  getTimes(5, 22.5, 30).filter(
                                    (time) => time.value === slot.from_time
                                  )[0]
                                }
                                error={!slot.from_time && submitted}
                                errorMsg="From time is required"
                                placeholder="From"
                                disabled={slot.id != ''}
                              />
                            </div>
                            <div className="-my-5 col-span-5">
                              <Dropdown
                                options={getTimes(5, 22.5, 30)}
                                onChange={(newValue) =>
                                  onToTimeChanged(newValue, slot)
                                }
                                value={
                                  getTimes(5, 22.5, 30).filter(
                                    (time) => time.value === slot.to_time
                                  )[0]
                                }
                                error={!slot.to_time && submitted}
                                errorMsg="Until time is required"
                                placeholder="To"
                                disabled={slot.id != ''}
                              />
                            </div>

                            <div className="mt-1">
                              <Button
                                type="button"
                                size="fit"
                                fitWidth={true}
                                colour="transparent"
                                Icon={TrashIcon}
                                onClick={() => removeAvailabilitySlot(slot)}
                                disabled={slot.id != ''}
                              ></Button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    {slot.to_time &&
                      slot.from_time &&
                      slot.to_time <= slot.from_time && (
                        <p
                          className="text-sm text-red-600 -mt-2 mb-3"
                          id="error"
                        >
                          From time must be less than until time
                        </p>
                      )}
                  </div>
                );
              })}
            {overLap[key] == true && (
              <p className="text-sm text-red-600 -mt-1 mb-4" id="error">
                Overlap exists
              </p>
            )}
          </div>
        ) : (
          <p className="text-sm font-medium leading-6 text-darkBlue">
            Not available
          </p>
        )}

        <hr className="my-3" />
      </div>
    );
  });

  return (
    <>
      <label
        htmlFor="day_time_available"
        className="text-sm font-medium text-darkBlue"
      >
        {loading ? (
          <Skeleton width={120} />
        ) : (
          <>
            {title ? title : 'What days/times are you available?'}{" "}
            {title ? '' : <span className="text-sm text-red-500">*</span>}
          </>
        )}
      </label>

      {formik.touched.availability && formik.errors.availability && (
        <p className="text-sm text-red-600" id="error">
          {formik.errors.availability}
        </p>
      )}

      {daysList}
    </>
  );
};

export { Availability };
