import { FC, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import {
  getAboutData,
  getCities,
  getGenders,
  updateAboutData,
  getLatestPromotionCode,
  getReferrer,
} from "../../../../core/services/adi-service";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AboutDataModel } from "../../../../core/models/about-data-model";
import { formatDate } from "../../../helpers/DateHelper";
import { IQuationnaireTabProps } from "../../../../core/models/quationnaire-tab-props";
import { scrollToFirstError } from "../../../helpers/ScrollHelper";
import { postalCodeExpression } from "../../../../core/models/enums/patterns-enum";
import {
  getAllDays,
  getMonths,
  getYears,
} from "../../../../core/services/date-service";
import { UploadProfileModal } from "./UploadProfileModal";
import { PromotionCodeModal } from "./PromotionCodeModal";
import PageHeader from "../../shared/elements/PageHeader";
import Alert from "../../shared/overlays/Alert";
import Input from "../../shared/forms/Input";
import Dropdown from "../../shared/forms/Dropdown";
import Button from "../../shared/elements/Button";
import { CameraIcon, UserCircleIcon } from "@heroicons/react/20/solid";
import Skeleton from "@mui/material/Skeleton";
import InputHint from "../../shared/forms/InputHint";
import toast from "react-hot-toast";
import Notification from "../../shared/overlays/Notification";
import {
  ExclamationTriangleIcon,
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";

const AboutUser: FC<IQuationnaireTabProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [imageSrc, setImageSrc] = useState<any>(null);
  const [show, setShow] = useState<boolean>(false);
  const [cities, setCities] = useState<Array<any>>([]);
  const [genders, setGenders] = useState<Array<any>>([]);
  const [adiPicture, setAdiPicture] = useState<any>(null);
  const [promotionCode, setPromotionCode] = useState<any>(null);
  const [submittedPromotionCode, setSubmittedPromotionCode] =
    useState<boolean>(false);
  const [referrerData, setReferrerData] = useState({
    code: "",
    full_name: "",
  });
  const [showPromotionCodeModal, setShowPromotionCodeModal] = useState(false);

  const [aboutData, setAboutData] = useState<AboutDataModel>({
    gender_id: null,
    date_of_birth: "1990/1/1",
    postal_code: "",
    city_name: "",
    street_address: "",
    address_id: "",
    day: "",
    month: "",
    year: "",
    adi_picture: null,
  });
  const years = getYears(100, 21, false);
  const months = getMonths();
  const days = getAllDays();
  const citiesBinded = cities.map((city) => city.city_name);
  const validPostalCode = new RegExp(postalCodeExpression + "$", "i");

  const updateAboutSchema = Yup.object().shape({
    street_address: Yup.string()
      .min(3, "Minimum 3 characters")
      .max(50, "Maximum 50 characters")
      .required("Address is required")
      .nullable(),
    postal_code: Yup.string()
      .matches(validPostalCode, "Invalid postal code")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Postal code is required")
      .nullable(),
    city_name: Yup.string().required("City is required"),
    year: Yup.string().nullable(),
    month: Yup.string().nullable(),
    day: Yup.string().nullable(),
    lang: Yup.array().nullable(),
  });

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const citiesPromise = getCities().then((res) => {
        setCities(res.data.results);
      });
      const genderPromise = getGenders().then((res) => {
        let gendersArr = res.data.results.map((gender: any) => {
          return {
            value: gender.id,
            label: gender.gender_name,
          };
        });
        setGenders(gendersArr);
      });
      const promotionCodePromise = getLatestPromotionCode().then((res) => {
        setPromotionCode(res.data.results?.code);
      });
      Promise.all([citiesPromise, genderPromise, promotionCodePromise]).then(
        () => {
          getAboutData().then((res) => {
            setInitialAboutData(res.data.results);
            setAdiPicture(res.data.results.adi_picture);
            setLoading(res === null);
          });
        }
      );
    };
    fetchData();
  }, []);

  useEffect(() => {
    (async () => {
      if (
        promotionCode != null &&
        promotionCode.length >= 5 &&
        promotionCode.length <= 6
      ) {
        const res = await getReferrer({
          adi_number: promotionCode,
        }).catch(() => {});

        if (
          res?.data?.results?.adi_number != null &&
          res?.data?.results?.full_name != null
        ) {
          setReferrerData({
            ...res,
            code: res?.data?.results?.adi_number,
            full_name: res?.data?.results?.full_name,
          });
        }
      } else {
        setReferrerData({
          code: "",
          full_name: "",
        });
      }
    })();
  }, [promotionCode, referrerData.full_name]);

  function setInitialAboutData(data: AboutDataModel) {
    const preparedData: AboutDataModel = {
      gender_id: data.gender_id,
      postal_code: data.postal_code === null ? "" : data.postal_code,
      city_name: data.city_name,
      street_address: data.street_address === null ? "" : data.street_address,
      address_id: data.address_id,
      date_of_birth: data.date_of_birth,
      day: "",
      month: "",
      year: "",
      adi_picture: data.adi_picture,
    };
    setAboutData(preparedData);
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const formik = useFormik({
    initialValues: {
      month: aboutData.month,
      year: aboutData.year,
      day: aboutData.day,
      street_address: aboutData.street_address,
      postal_code: aboutData.postal_code,
      city_name: aboutData.city_name,
    },
    enableReinitialize: true,
    validationSchema: updateAboutSchema,

    onSubmit: (values, { setStatus, setSubmitting }) => {
      setStatus("");
      let submitData: AboutDataModel = prepareSubmitData(values);
      setLoading(true);
      let loadingId = toast.custom(
        () => (
          <Notification
            colour="gray"
            title={"Updating your data..."}
            description={""}
            Icon={ExclamationCircleIcon}
          />
        ),
        {
          duration: 5000,
          position: "top-center",
        }
      );
      updateAboutData(submitData)
        .then((data) => {
          ReactGA.event("update_about_you");
          // dispatch(auth.actions.setPersonalData(data.data.results))
          toast.custom(
            () => (
              <Notification
                colour="green"
                title={"Saved successfully"}
                description={""}
                Icon={CheckCircleIcon}
              />
            ),
            {
              duration: 5000,
              position: "top-center",
              id: loadingId,
            }
          );
          setLoading(false);
          props.goToNextTab();
          setSubmitting(false);
          scrollToTop();
        })
        .catch((error) => {
          setLoading(false);
          setSubmitting(false);
          toast.custom(
            () => (
              <Notification
                colour="red"
                title={"Failed to update personal data!"}
                description={""}
                Icon={ExclamationTriangleIcon}
              />
            ),
            {
              duration: 5000,
              position: "top-center",
              id: loadingId,
            }
          );
          scrollToTop();
          if (error && error.errors && error.errors.length > 0) {
            setSubmitting(false);
            setStatus(error.errors[0].errorMessage);
          } else {
            setSubmitting(false);
            setStatus("Sorry, an error has occurred");
          }
        });
    },

    // onSubmit: (values, { setStatus, setSubmitting }) => {
    //   console.log(values)
    //   debugger;
    //   setStatus("");
    //   let hasError = checkHasError();
    //   if (!hasError) {
    //     let data: AboutDataModel = prepareSubmitData(values);
    //     updateAboutData(data)
    //       .then((data) => {
    //         ReactGA.event("update_about_you");
    //         props.goToNextTab();
    //         setSubmitting(false);
    //         scrollToTop();
    //       })
    //       .catch((error) => {
    //         setSubmitting(false);
    //         scrollToTop();
    //         if (error && error.errors && error.errors.length > 0) {
    //           setSubmitting(false);
    //           setStatus(error.errors[0].errorMessage);
    //         } else {
    //           setSubmitting(false);
    //           setStatus("Sorry, an error has occurred");
    //         }
    //       });
    //   } else {
    //     setSubmitting(false);
    //   }
    // },
  });

  useEffect(() => {
    scrollToFirstError(formik);
  }, [formik.errors, formik.isSubmitting, formik.isValidating]);

  useEffect(() => {
    if (aboutData.date_of_birth) {
      let date = new Date(
        aboutData.date_of_birth ? aboutData.date_of_birth : ""
      );
      setAboutData({
        ...aboutData,
        day: date.getDate().toString(),
        month: months[date.getMonth()]?.value,
        year: date.getFullYear().toString(),
      });
    }
  }, [aboutData.date_of_birth]);

  function checkHasError(): boolean {
    if (
      days.findIndex((a) => a.value === formik.values.day) < 0 &&
      formik.values.day !== ""
    ) {
      formik.setFieldValue("day", "");
      return true;
    }
    if (aboutData.city_name === null) {
      return true;
    }
    if (aboutData.gender_id === null) {
      return true;
    }
    return false;
  }

  function prepareSubmitData(values: any): AboutDataModel {
    const submittedData: AboutDataModel = {
      gender_id: aboutData.gender_id,
      postal_code: values.postal_code,
      city_name: values.city_name,
      street_address: values.street_address,
      address_id: aboutData.address_id,
      date_of_birth: formatDate(values.year, values.month, values.day),
      day: "",
      month: "",
      year: "",
      adi_picture: null,
    };
    return submittedData;
  }

  const setSubmit = () => {
    if (submitted === false) {
      setSubmitted(true);
    }
  };

  const onGenderChanged = (e: any, id: any) => {
    if (formik.isSubmitting) {
      return;
    }
    setAboutData({
      ...aboutData,
      gender_id: id,
    });
  };

  const hideCropper = () => {
    setShow(false);
    getAboutData().then((res) => {
      setAdiPicture(res.data.results.adi_picture);
    });
  };

  const onFileChange = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const imageDataUrl = URL.createObjectURL(e.target.files[0]);
      setImageSrc(imageDataUrl);
      setShow(true);
    }
  };

  return (
    //  loading ? <h5> Loading... </h5> :
    <>
      <div
        className={props.parentWrapper === "questionnaire" ? "hidden" : "mb-5"}
      >
        <PageHeader
          title="About You"
          backToURL={
            props.parentWrapper !== "questionnaire"
              ? "/ADI/settings/overview"
              : ""
          }
        />
      </div>

      <div className="bg-white py-4 px-5 rounded-lg">
        <div
          className={
            `col-12  m-auto` +
            (props.parentWrapper === "questionnaire"
              ? "col-lg-10"
              : "col-lg-12")
          }
        >
          {props.parentWrapper === "questionnaire" &&
          !props.selfRegisterDate ? (
            <Alert
              colour="orange"
              icon="pmf-icon-info"
              title={
                "Welcome to our new ADI Network app. \nAs we contacted you before, we already hold some of your data in our systems. Please review and update these records in order to access the app."
              }
            />
          ) : (
            <></>
          )}
          <form onSubmit={formik.handleSubmit} noValidate>
            <div className="card card--form-container ">
              {formik.status ? (
                <Alert
                  colour="red"
                  icon="pmf-icon-info"
                  title={formik.status}
                />
              ) : (
                <></>
              )}

              {/* start:: From Group */}

              {props.parentWrapper !== "questionnaire" ? (
                <>
                  <h4 className="text-base font-bold ">
                    {loading ? (
                      <Skeleton width={50} className="me-2" />
                    ) : (
                      <>Profile Picture</>
                    )}
                  </h4>

                  {adiPicture == null && (
                    <p className="d-block font-size-14 gray-text">
                      {loading ? (
                        <Skeleton width={100} className="me-2" />
                      ) : (
                        <span className="text-[#848484] text-xs">
                          {" "}
                          Upload a photo to help your learners recognise you
                        </span>
                      )}
                    </p>
                  )}

                  <div className="col-span-full pb-5">
                    <div className="mt-2 flex items-center gap-x-3">
                      {loading ? (
                        <Skeleton
                          width={96}
                          height={96}
                          variant="circular"
                          className="me-2"
                        />
                      ) : (
                        <>
                          {adiPicture != null ? (
                            <img
                              alt=""
                              className=" h-24 w-24 flex-shrink-0 rounded-full bg-gray-200 border-[4px] border-[#F4F5F7]"
                              src={adiPicture}
                            />
                          ) : (
                            <UserCircleIcon
                              className="h-24 w-24 text-gray-300 -mx-3"
                              aria-hidden="true"
                            />
                          )}
                        </>
                      )}
                      <Button
                        colour="outline"
                        fitWidth
                        disabled={loading}
                        rounded="sm"
                        className=" bg-white py-2"
                      >
                        <label
                          htmlFor="profile-pic"
                          className="flex items-center"
                        >
                          <CameraIcon className="h-4 w-4 -mt-[2px]" />
                          <span className="ms-1 leading-none mt-[2px]">
                            Change photo
                          </span>
                          <input
                            type="file"
                            className="hidden"
                            id="profile-pic"
                            onChange={(e) => onFileChange(e)}
                            accept="image/*"
                          />
                        </label>
                      </Button>
                    </div>
                  </div>

                  <UploadProfileModal
                    show={show}
                    hide={hideCropper}
                    imageSrc={imageSrc}
                  />
                </>
              ) : (
                <></>
              )}

              <h4
                className={`${
                  props.parentWrapper === "questionnaire" ? "md:pt-3" : ""
                } text-base font-bold`}
              >
                {loading ? (
                  <Skeleton width={50} className="me-2" />
                ) : (
                  <>Your Address</>
                )}
              </h4>

              {/* begin::Form group */}
              {loading ? (
                <Skeleton className="w-full" height={50}></Skeleton>
              ) : (
                <Input
                  {...formik.getFieldProps("street_address")}
                  type="text"
                  label="First line of address"
                  placeholder=""
                  error={
                    formik.touched.street_address &&
                    formik.errors.street_address
                      ? true
                      : false
                  }
                  errorMsg={formik.errors.street_address}
                  required={true}
                  name="street_address"
                  disabled={formik.isSubmitting}
                  id="street_address"
                />
              )}
              {/* end::Form group */}

              {/* begin::city_name Form group */}

              {loading ? (
                <Skeleton className="w-full" height={50}></Skeleton>
              ) : (
                <InputHint
                  {...formik.getFieldProps("city_name")}
                  type="text"
                  label="Town/City"
                  error={
                    formik.touched.city_name &&
                    formik.errors.city_name !== undefined
                  }
                  value={
                    formik.values.city_name != ""
                      ? citiesBinded.find(
                          (t: any) =>
                            t.toLowerCase() ===
                            formik.values?.city_name?.toLowerCase()
                        )
                      : formik.values.city_name
                  }
                  errorMsg={formik.errors.city_name}
                  required={true}
                  name="city_name"
                  disabled={formik.isSubmitting}
                  id="city_name"
                  onChange={(e) => {
                    formik.setFieldValue("city_name", e?.target.value);
                  }}
                  options={citiesBinded}
                />
              )}

              {/* end::city_name Form group */}

              {/* start:: From Group */}
              {/* <div
                  className={
                      formik.touched.city_name && formik.errors.city_name
                          ? 'form-group row is-invalid'
                          : 'form-group row'
                  }
              >
                  <div className='d-flex justify-content-between'>
                      <label htmlFor='town_city' className='control-label'>
                          Town/City<span className='required'></span>
                      </label>
                      {formik.touched.city_name && formik.errors.city_name && (
                          <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                  <span role='alert'>{formik.errors.city_name}</span>
                              </div>
                          </div>
                      )}
                  </div>
                  <div className='col-12'>
                      <div className='clearfix text-field form-control-autocomplete'>
                          <Hint options={citiesBinded} allowTabFill>
                              <input
                                  readOnly={formik.isSubmitting}
                                  disabled={formik.isSubmitting}
                                  {...formik.getFieldProps('city_name')}
                                  className='form-control col-md-12' onChange={handleChange} />
                          </Hint>
                      </div>

                  </div>

              </div> */}
              {/* end:: From Group */}

              {/* begin::postal_code Form group */}
              {loading ? (
                <Skeleton className="w-full" height={50}></Skeleton>
              ) : (
                <Input
                  {...formik.getFieldProps("postal_code")}
                  type="text"
                  label="Postcode"
                  placeholder=""
                  error={
                    formik.touched.postal_code && formik.errors.postal_code
                      ? true
                      : false
                  }
                  errorMsg={formik.errors.postal_code}
                  required={true}
                  name="postal_code"
                  disabled={formik.isSubmitting}
                  id="postal_code"
                />
              )}
              {/* end::postal_code Form group */}

              <h4 className="text-base font-bold ">
                {loading ? (
                  <Skeleton width={50} className="me-2" />
                ) : (
                  <>Gender</>
                )}
              </h4>
              {/* start:: From Group */}
              {loading ? (
                <Skeleton className="w-full" height={50}></Skeleton>
              ) : (
                <Dropdown
                  options={genders}
                  label="Gender"
                  error={
                    aboutData.gender_id === null && submitted ? true : false
                  }
                  errorMsg="Gender is required"
                  required={true}
                  name="Genders"
                  disabled={formik.isSubmitting}
                  id="Genders"
                  value={genders.find((t) => t.value === aboutData.gender_id)}
                  onChange={(e) => {
                    onGenderChanged(e, e?.value);
                  }}
                />
              )}

              {/* start:: From Group */}
              {loading ? (
                <Skeleton className="w-full" width={70}></Skeleton>
              ) : (
                <h4 className="text-base font-bold mt-6">Promotion Code</h4>
              )}
              <div className="mb-10 grid grid-cols-2 gap-2">
                {loading ? (
                  <Skeleton className="w-full" height={50}></Skeleton>
                ) : (
                  <Input
                    value={promotionCode}
                    type="text"
                    label="Code"
                    placeholder="ADI Number of Referrer"
                    name="promotion_code"
                    disabled={formik.isSubmitting}
                    id="promotion_code"
                    onChange={(e) => {
                      setPromotionCode(e?.target.value);
                    }}
                  />
                )}

                {loading ? (
                  <Skeleton className="w-full" height={50}></Skeleton>
                ) : (
                  <div className="my-6 flex space-x-2 justify-between">
                    <Button
                      colour="yellow"
                      size="large"
                      className="pl-4 pr-4"
                      fitWidth
                      disabled={
                        referrerData.full_name === null ||
                        referrerData.full_name === ""
                      }
                      onClick={() => setShowPromotionCodeModal(true)}
                    >
                      Submit Promotion Code
                    </Button>
                  </div>
                )}

                <PromotionCodeModal
                  show={showPromotionCodeModal}
                  referrerData={referrerData}
                  onClose={() => setShowPromotionCodeModal(false)}
                  onSubmitted={() => {
                    setShowPromotionCodeModal(false);
                    setSubmittedPromotionCode(true);
                  }}
                />

                {submittedPromotionCode && !loading && (
                  <p className="text-darkBlue col-span-full">
                    Promotion code has been added to your account and will be
                    taken into account if valid
                  </p>
                )}
              </div>

              {/* end:: From Group */}
            </div>

            <div
              className={`${
                props.parentWrapper === "questionnaire"
                  ? "flex-row-reverse"
                  : "flex-row"
              } my-3 flex justify-between gap-2`}
            >
              {loading ? (
                <Skeleton width={150} height={50}></Skeleton>
              ) : (
                <Button
                  disabled={formik.isSubmitting}
                  onClick={() => setSubmit()}
                  colour="yellow"
                  size="large"
                  type="submit"
                >
                  {!loading && (
                    <span className="indicator-label">
                      {props.parentWrapper === "questionnaire"
                        ? "Save & Continue"
                        : "Save"}
                    </span>
                  )}
                  {loading && (
                    <span
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      Please wait...
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </Button>
              )}
              {loading ? (
                <Skeleton width={150} height={50}></Skeleton>
              ) : (
                <>
                {props.parentWrapper != "questionnaire" ?
                  <Button
                    disabled={formik.isSubmitting}
                    onClick={() => props.goToPrevTab()}
                    colour="outline"
                    size="large"
                  >
                    Discard
                  </Button> : <></>
                }
                </>

              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export { AboutUser };
