import { FC } from "react";
import { toAbsoluteUrl } from "../../../helpers";
import Button from "../../../components/shared/elements/Button";


type Props = {
  imgURL: string;
  title: React.ReactNode;
  desc: string;
  button: {
    text: React.ReactNode;
    onClick: () => void;
    color: "yellow" | "darkBlue" | "outline";
    disabled: boolean;
  };
};

const CPDBreakCard: FC<Props> = ({ imgURL, title, desc, button }) => {
  return (
    <>
      {/* <!------- */}
      <div
        className={`group relative flex flex-col justify-center items-center overflow-hidden text-center rounded-[20px] bg-white px-5 pt-8 pb-9 mt-14  mb-6`}
      >
        <div className="aspect-h-4 aspect-w-3 sm:aspect-none sm:h-60 px-8 mb-5">
          <img
            src={toAbsoluteUrl(`${imgURL}`)}
            className="h-full w-auto object-content object-center sm:h-full sm:w-auto"
          />
        </div>

        <h3 className=" text-4xl font-bold font-titlef tracking-tight text-primary font-heading mb-2 px-4 ">
          {title}
        </h3>

        <p className="text-sm mb-6">{desc}</p>

        <Button className="px-6"
          onClick={() => button.onClick()}
          colour={button.color}
          disabled={button.disabled}
          size="large"
        >
          <span className=" text-sm">{button.text}</span>
        </Button>
      </div>
      {/* <!------- */}
    </>
  );
};

export default CPDBreakCard;
