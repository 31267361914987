import { FC } from 'react'
import Modal from '../../../shared/overlays/Modal'
import Alert from '../../../shared/overlays/Alert'

type Props = {
  show: any
  onHide: any
  continueSelected: any
}

const ExpirePaymentModal: FC<Props> = ({ continueSelected, show, onHide }) => {

  return (
    <>
      <Modal
        open={show}
        title="Expire Payment"
        submitText='Confirm'
        closeText='Cancel'
        onClose={onHide}
        onSubmit={continueSelected}
      >
        <Alert
          colour='orange'
          title='You are about to expire this payment'
          icon='pmf-icon-info'
        />
      </Modal>
    </>
  )
}

export { ExpirePaymentModal }
