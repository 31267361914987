import { FC, useEffect, useState } from "react";

import PageHeader from "../../components/shared/elements/PageHeader";
// import ArticlesVideosHeader from "./ArticlesVideosHeader";
import CPDHeader from "./_shared/CPDHeader";
import ArticlesList from "./articles/ArticlesList";
import { PrismicProvider } from "@prismicio/react";
import { createClient } from "../../../../prismic";
import { Tab } from "@headlessui/react";
import Input from "../../components/shared/forms/Input";
import { toAbsoluteUrl } from "../../helpers";
import CPDSearchResult from "./CPDSearchResult";
import VideosList from "./videos/VideosList";
import { useHistory } from "react-router-dom";
import { useQuery } from "../../helpers/QueryParamsHelper";

const CPDWrapper: FC = () => {
  const history = useHistory();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const query = useQuery();

  useEffect(() => {
    if (query.get("mainTab")?.toLocaleLowerCase() === "article") {
      setSelectedIndex(1);
    } else if (query.get("mainTab")?.toLocaleLowerCase() === "videos") {
      setSelectedIndex(0);
    } else if (query.get("tab")?.toLocaleLowerCase() === "article") {
      setSelectedIndex(1);
    }

  }, [query]);

  const tabs = [
    {
      key: "videos",
      title: "Videos",
      component: <VideosList />,
    },
    {
      key: "articles",
      title: "Articles",
      component: <ArticlesList />,
    },
  ];

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
  }

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (e: any) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    query.set("tab", selectedIndex === 0 ? "videos" : "article");
    history.replace({
      search: query.toString(),
    });
  }, [selectedIndex]);

  return (
    <>
      <div className="mb-2">
        <PageHeader title="" backToURL="/ADI/more" />
      </div>

      <CPDHeader />

      <div className="px-5 flex flex-col -mb-6 -mt-7 col-span-full">
        <Input
          type={"text"}
          placeholder="What topic are you looking for?"
          value={searchTerm}
          onChange={handleSearchChange}
          onSearchClick={() =>
            history.push(`/ADI/cpd/search-result/${searchTerm}`)
          }
          clickableSearchIcon={true}
          roundedFull
        />
      </div>

      <div className="mt-8 mb-8">
        <img
          className="w-full"
          src={toAbsoluteUrl("/assets/media/svg/vector.svg")}
          alt=""
        />
      </div>

      <div className="px-5 pb-5">
        <PrismicProvider client={createClient}>
          <Tab.Group
            selectedIndex={selectedIndex}
            onChange={(index) => {
              setSelectedIndex(index);
              history.push(`?tab=${index === 0 ? "videos" : "articles"}`);
            }}
          >
            <Tab.List className="flex space-x-1 lg:w-1/4 sm:w-1/2">
              {tabs.map((tab) => (
                <Tab
                  key={tab.key}
                  className={({ selected }) =>
                    classNames(
                      "w-full py-2.5 text-lg font-medium leading-5",
                      "ring-white ring-opacity-60 ring-offset-2 ring-offset-transparent focus:outline-none focus:ring-none",
                      selected
                        ? " border-b-4 border-darkBlue"
                        : " text-[#A8A8A8] hover:brightness-75"
                    )
                  }
                >
                  <>{tab.title}</>
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels className="">
              {tabs.map((tab) => (
                <Tab.Panel
                  key={tab.key}
                  className={`grid grid-cols-1 pt-6 gap-0 ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none`}
                >
                  {tab.component}
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>
        </PrismicProvider>
      </div>
    </>
  );
};

export default CPDWrapper;
