import { FC } from 'react'
import { StudentCard } from '../../shared/cards/StudentCard';
import { PencilSquareIcon, TrashIcon, XMarkIcon } from '@heroicons/react/24/outline';
import StackedCardWithActionsMenu from '../../shared/data/StackedCardWithActionsMenu';
import { getTestStatusColor } from '../../../helpers/lesson-status-helper';
type Props = {
  lessons: any,
  loading: boolean,
  showUpdateModal: any,
  setLesson: any,
  showDeleteModal: any,
  showCancelModal: any
}

const LessonsListTab: FC<Props> = ({ loading, lessons, showUpdateModal, setLesson, showDeleteModal, showCancelModal }) => {

  return (
    <>
      <div className="grid md:grid-cols-2 grid-cols-1 gap-4 mb-3">
        {
          loading ?
            <div className='h-fit'>
              <StackedCardWithActionsMenu loading={loading}>
                <StudentCard loading={loading} />
              </StackedCardWithActionsMenu>

            </div>
            : <>
              {lessons.map(function (lesson: any, key: number) {
                return (
                  <div key={key}>
                    <StackedCardWithActionsMenu
                      title={lesson.date_str}
                      icon='pmf-icon-wheel'
                      description={lesson.form_time_str + " to " + lesson.to_time_str}
                      pillTitle={lesson.job_ref ? "#" + lesson.job_ref : null}
                      loading={false}
                      showAction={(lesson?.abilities?.can_be_updated  )|| (lesson?.abilities?.can_be_removed)}
                      actions={[{
                        Icon: PencilSquareIcon, title: 'Edit lesson',
                        show:  lesson?.abilities?.can_be_updated,
                        onClick: () => {
                          setLesson(lesson)
                          showUpdateModal()
                        }
                      },
                      {
                        Icon: TrashIcon,
                        title: 'Delete lesson',
                        show:  lesson?.abilities?.can_be_removed,
                        onClick: () => {
                          setLesson(lesson)
                          showDeleteModal()
                        }
                      },
                      {
                        Icon: XMarkIcon, title: 'Cancel lesson',
                        show:  lesson?.is_private_student,
                        onClick: () => {
                          setLesson(lesson)
                          showCancelModal()
                        }
                      }].filter(s=> s.show)}
                      pmfIcon={!lesson.is_private_student}
                      status={
                         { title:   lesson.status ?? "", color: getTestStatusColor(lesson.status_id) }
                        }>
                      <div className='pt-3 pb-1'>
                              { lesson.lesson_type &&
                                <div className="my-3"><label className="text-sm"><strong>Lesson Type: </strong> {lesson.lesson_type}</label></div>
                              }
                        <StudentCard loading={loading}
                          title={(lesson?.student_data?.student_first_name ?? "") + ' ' + (lesson?.student_data?.student_surname ?? "")}
                          location={lesson?.student_data?.student_post_code}
                          email={lesson?.student_data?.student_email}
                          phone={lesson?.student_data?.student_phone}
                          description={`${lesson?.student_data?.student_street_address ? lesson?.student_data?.student_street_address + ', ' : ''} ${lesson?.student_data?.student_city_name ?? ''}`}
                        />
                      </div>
                    </StackedCardWithActionsMenu>
                  </div>
                )
              })}
            </>
        }
      </div>


    </>
  )
}

export { LessonsListTab };

