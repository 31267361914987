/* eslint-disable jsx-a11y/anchor-is-valid */
import { Menu, Transition } from "@headlessui/react";
import {
  EllipsisVerticalIcon
} from "@heroicons/react/20/solid";
import clsx from "clsx";
import { FC, Fragment } from "react";

interface IActionMenuProp {
    options: {
      title: string
      show?: boolean
      Icon?: any;
      onClick: () => void;
    }[];
    disabled?: boolean
}

const ActionMenu: FC<IActionMenuProp> = ({ options, disabled}) => {

  return (
    <>
          <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="-m-2 flex items-center rounded-full py-2 text-gray-400 hover:" disabled={disabled}>
                      <span className="sr-only">Open options</span>
                      <EllipsisVerticalIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none w-fit">
                      <div className="py-1 w-max">
                        {options.map((option, i) => 
                        option.show ? (<Menu.Item key={i}>
                          {({ active }) => (
                            <a
                              href="#"
                              onClick={() => option?.onClick()}
                              className={clsx(
                                active
                                  ? "bg-gray-100 "
                                  : "",
                                "flex px-4 py-2 text-sm items-center w-full"
                              )}
                            >
                              {option?.Icon && <option.Icon
                                className="mr-3 h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />}
                              <span className="text-gray-500 font-medium text-sm">{option.title}</span>
                            </a>
                          )}
                        </Menu.Item>) : (<></>))}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
    </>
  );
};

export { ActionMenu };
