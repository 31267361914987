import { CheckoutItem } from "./checkout-item";

export class Checkout {
    id: number =0;
    session_id: string | null = null;
    account_id: string | null = null;
    student_id: string | null = null;
    payment_intent_id: string | null = null;
    student_name: string | null = null;
    student_email: string | null = null;
    checkout_status: string | null = null;
    payment_status: string | null = null;
    amount_subtotal: number= 0;
    amount_total: number  = 0;
    net_amount: number  = 0;
    paid_at: string | null = null;
    fee_forwarded: string | null = null;
    fee_paid: number =0;
    url: string  = '';
    refunded_at: string | null = null;
    refund_amount: number = 0;
    items: Array<CheckoutItem> = []
    paid_at_human: string | null = null;
    refunded_at_human: string | null = null;
    adi_receives: number  = 0;
}