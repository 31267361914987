import { FC, useState, useEffect } from "react";
import { AdiJob } from "../../../../../core/models/adi-job";
import { PaymentModel } from "../../../../../core/models/payment-model";
import ReactGA from "react-ga4";
import {
  getAdiJobPaymentDetails,
  getAdiJobPayments,
  getPrivateLearnerBalance,
  getPrivateLearnerTransactions,
} from "../../../../../core/services/payment-service";
import StackedList from "../../../shared/data/StackedList";
import { GrayCard } from "../../../shared/cards/GrayCard";
import EmptyState from "../../../shared/elements/EmptyState";
import Alert from "../../../shared/overlays/Alert";
import { CurrencyPoundIcon } from "@heroicons/react/24/outline";
import { toAbsoluteUrl } from "../../../../helpers";
import { Skeleton } from "@mui/material";
import { PrivateStdBalanceModel } from "../../../../../core/models/private-std-balance-model";
import { ActivityList } from "../../../payments/ActivityList";
import { Transaction } from "../../../../../core/models/transaction-model";

interface IProps {
  jobData: AdiJob | null;
}

export const PaymentsTab: FC<IProps> = ({ jobData }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [jobPayment, setJobPayments] = useState<Array<PaymentModel>>([]);
  const [paymentDetails, setPaymentDetails] = useState<any>(null);

  const [privateStdBalance, setPrivateStdBalance] =
    useState<PrivateStdBalanceModel>();
  const [privateStdTransactions, setPrivateStdTransactions] = useState<
    Array<Transaction>
  >([]);

  const progress = () => {
    return jobData?.total_rate && jobData?.paid && jobData?.total_rate > 0
      ? jobData?.paid >= jobData?.total_rate
        ? 100
        : (jobData?.paid / jobData?.total_rate) * 100
      : 0;
  };
  const urlSearchParams = new URLSearchParams(window.location.search);
  const adiJobId = Object.fromEntries(urlSearchParams.entries()).adi_job_id;

  useEffect(() => {
    if (!jobData?.is_private_student) {
      getJobPayments();
    } else {
      getPrivateStdPayments();
    }
  }, [jobData]);

  const getJobPayments = async () => {
    if (jobData?.job_id) {
      setLoading(true);
      const payments = await getAdiJobPayments(jobData?.job_id, adiJobId).catch(
        () => {
          setLoading(false);
        }
      );

      if (payments?.data.results) setJobPayments(payments?.data.results);

      const paymentsDetails = await getAdiJobPaymentDetails(
        jobData?.job_id,
        adiJobId
      ).catch(() => {
        setLoading(false);
      });

      if (paymentsDetails?.data.results)
        setPaymentDetails(paymentsDetails?.data.results);

      setLoading(false);
    }
  };

  const getPrivateStdPayments = async () => {
    if (jobData?.job_id) {
      setLoading(true);
      const balance = await getPrivateLearnerBalance(jobData?.job_id).catch(
        () => {
          setLoading(false);
        }
      );

      if (balance?.results) setPrivateStdBalance(balance.results);

      const transactions = await getPrivateLearnerTransactions(
        jobData?.job_id
      ).catch(() => {
        setLoading(false);
      });

      if (transactions?.results)
        setPrivateStdTransactions(transactions.results);

      setLoading(false);
    }
  };

  useEffect(() => {
    ReactGA.event("open_payments_tab");
  }, []);

  return (
    <>
      {!jobData?.is_private_student ? (
        <div className="bg-white p-5">
          <GrayCard
            topIcon="pmf-icon-currency-pound"
            title={<span>You'll earn</span>}
            value={`
                  £${
                    Number.isInteger(jobData?.total_rate)
                      ? jobData?.total_rate?.toLocaleString()
                      : jobData?.total_rate?.toFixed(2)
                  }
                  `}
            info={`${jobData?.hours} hours / £${
              Number.isInteger(jobData?.rate_per_hour)
                ? jobData?.rate_per_hour?.toLocaleString()
                : jobData?.rate_per_hour?.toFixed(2)
            } per hour`}
            loading={loading}
          />

          <div className="mt-8 mb-6">
            <div className="flex justify-between flex-wrap -mt-3 mb-2">
              <span className="text-md text-darkBlue">
                <strong>
                  £
                  {loading || !paymentDetails?.paid_amout
                    ? 0
                    : Number.isInteger(Number(paymentDetails?.paid_amout))
                    ? Number(paymentDetails?.paid_amout)?.toLocaleString()
                    : parseFloat(paymentDetails?.paid_amout)?.toFixed(2)}
                </strong>{" "}
                paid
              </span>
              <span className="text-md text-darkBlue">
                <strong>
                  £
                  {jobData?.total_rate
                    ? Number.isInteger(
                        jobData?.total_rate -
                          parseFloat(paymentDetails?.paid_amout)
                      )
                      ? (
                          jobData?.total_rate -
                          parseFloat(paymentDetails?.paid_amout)
                        ).toLocaleString()
                      : (
                          jobData?.total_rate -
                          parseFloat(paymentDetails?.paid_amout)
                        )?.toFixed(2)
                    : 0}
                </strong>{" "}
                remaining
              </span>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4 ">
              <div
                className="bg-yellow h-2.5 rounded-full"
                style={{ width: progress() + "%" }}
              ></div>
            </div>
          </div>

          <div className="">
            {paymentDetails?.upcoming && (
              <Alert
                colour="white"
                icon="pmf-icon-info"
                customDesc={
                  <span className="text-sm text-darkBlue">
                    <div className="flex items-center text-sm font-medium">
                      <p className="me-1">Payment On The Way:</p>
                      <strong>
                        £
                        {Number.isInteger(
                          parseFloat(paymentDetails?.upcoming?.payment_amount)
                        )
                          ? parseFloat(paymentDetails?.upcoming?.payment_amount)
                          : parseFloat(
                              paymentDetails?.upcoming?.payment_amount
                            )?.toFixed(2)}
                      </strong>
                    </div>
                  </span>
                }
              />
            )}
            {paymentDetails?.upcoming && (
              <Alert
                colour="white"
                icon="pmf-icon-info"
                customDesc={
                  <span className="text-sm text-darkBlue">
                    <div className="flex items-center text-sm font-medium">
                      <p className="me-1">Due Date: </p>
                      <strong>
                        {paymentDetails?.upcoming?.formated_due_date}
                      </strong>
                    </div>
                  </span>
                }
              />
            )}
            <div className=""></div>
          </div>

          <div className="mt-6 pt-6 border-t">
            <h4 className="text-base font-bold ">Transactions</h4>
            {jobPayment.length > 0 ? (
              <>
                <StackedList
                  title="Transactions"
                  data={jobPayment.map((payment) => {
                    return {
                      imgURL: payment?.is_upcoming
                        ? "/assets/media/svg/money-in.svg"
                        : "/assets/media/svg/money-in.svg",
                      title:
                        "£" +
                        (Number.isInteger(parseFloat(payment.payment_amount))
                          ? parseFloat(payment?.payment_amount)
                          : parseFloat(payment?.payment_amount)?.toFixed(2)
                        ).toString(),
                      description: "",
                      status: payment?.is_upcoming ? "Pending" : "Paid",
                      statusColour: payment?.is_upcoming
                        ? "yellow"
                        : "darkGreen",
                      value:
                        "£" +
                        (Number.isInteger(parseFloat(payment.payment_amount))
                          ? parseFloat(payment?.payment_amount)
                          : parseFloat(payment?.payment_amount)?.toFixed(2)
                        ).toString(),
                      valueDesc: "",
                    };
                  })}
                />
              </>
            ) : (
              <>
                <EmptyState
                  title="No transactions yet"
                  Icon={CurrencyPoundIcon}
                />
              </>
            )}
          </div>
        </div>
      ) : (
        <>
          <div className="p-5">
            <div className="grid grid-cols-2 gap-3 mt-1">
              <div className="relative rounded-[10px] bg-yellow text-gray-700">
                <div className="py-4 px-3 flex flex-col text-center justify-center items-center">
                  <i className={`text-base iouu`}></i>
                  <p className="block text-sm font-light leading-relaxed text-inherit antialiased">
                    Paid
                  </p>
                  <h5 className="mb-2 mt-1 font-titlef text-3xl font-semibold text-blue-gray-900 antialiased">
                    {loading ? (
                      <>
                        <Skeleton width={80} />
                      </>
                    ) : (
                      <>
                        {" "}
                        £
                        {privateStdBalance?.amount
                          ? Number.isInteger(+privateStdBalance?.amount)
                            ? privateStdBalance?.amount
                            : (+privateStdBalance?.amount)?.toFixed(2)
                          : 0}
                      </>
                    )}
                  </h5>
                </div>
              </div>

              <div className="grid grid-cols-1 gap-x-2">
                <div className="bg-white rounded-lg p-[10px]">
                  <div className="flex justify-between items-center min-w-0 gap-x-3 h-full">
                    <img
                      className="flex-none rounded-full bg-gray-50 h-9 w-9"
                      src={toAbsoluteUrl("/assets/media/svg/money-in.svg")}
                      alt=""
                    />
                    <div className="min-w-0 flex-auto">
                      <p className="text-xs leading-5">On the way</p>
                      <p className="text-base font-semibold font-titlef ">
                        {loading ? (
                          <>
                            <Skeleton width={80} />
                          </>
                        ) : (
                          <>
                            £
                            {privateStdBalance?.on_the_way
                              ? Number.isInteger(+privateStdBalance?.on_the_way)
                                ? privateStdBalance?.on_the_way
                                : (+privateStdBalance?.on_the_way)?.toFixed(2)
                              : 0}
                          </>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white p-5">
            <h4 className="text-base font-bold ">Transactions</h4>
            <div className="-mb-5 -mx-5">
              <ActivityList
                transactionList={privateStdTransactions}
                loading={loading}
                refreshList={getPrivateStdPayments}
                title="Transactions"
                hidePageHeader={true}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};
