import { FC, useEffect, useState } from "react";
import { useFormik } from "formik";
import Modal from "../../shared/overlays/Modal";
import DisclosureItem from "../../shared/elements/DisclosureItem";
import Dropdown from "../../shared/forms/Dropdown";
import Slider from "../../shared/forms/Slider";
import { Course } from "../../../../core/models/enums/course-intensity-enum";
import { CustomJobReminderModel } from "../../../../core/models/custom-job-reminder-model";
import RadioInputGroup from "../../shared/forms/RadioInputGroup";
import {
  addCutomJobAlert,
  getADITestCenterAndTransmissions,
  updateCutomJobAlert,
} from "../../../../core/services/adi-service";
import DateInput from "../../shared/forms/DateInput";
import { TransmissionType } from "../../../../core/models/enums/transmission-types-enum";
import Input from "../../shared/forms/Input";

interface IProps {
  show: any;
  onHide: any;
  alertInfo: CustomJobReminderModel | null;
  refreshParent: () => void;
}

const CustomJobReminderDialog: FC<IProps> = ({
  show,
  onHide,
  alertInfo,
  refreshParent,
}) => {
  const hourSearch = [
    { value: 5, label: "5" },
    { value: 10, label: "10" },
    { value: 15, label: "15" },
    { value: 20, label: "20" },
    { value: 30, label: "30" },
    { value: 40, label: "40+" },
  ];

  const defaultDistance = 15;
  const minDistance = 1;
  const maxDistance = 40;

  const rateStep = 1;
  const [testCenters, setTestCenters] = useState<Array<any>>([]);
  const [showFilterByTransmissions, setShowFilterByTransmissions] =
    useState<boolean>(false);

  useEffect(() => {
    if (show) {
      getTestCentersAndTransmissions();
    }
  }, [show]);

  const getTestCentersAndTransmissions = async () => {
    const values = await getADITestCenterAndTransmissions();
    if (values) {
      setTestCenters(
        values?.adi_test_centers?.map((testCenter: any) => ({
          label: testCenter.test_center_name,
          value: testCenter.test_center_id,
        }))
      );
      setShowFilterByTransmissions(values?.hasManyCars);
    }
  };

  const formik = useFormik({
    initialValues: {
      from_hour: alertInfo?.from_hour,
      to_hour: alertInfo?.to_hour,
      to_rate: alertInfo?.to_rate,
      from_rate: alertInfo?.from_rate,
      distance: alertInfo?.distance ?? defaultDistance,
      test_from_date: alertInfo?.test_from_date,
      test_to_date: alertInfo?.test_to_date,
      test_center_id: alertInfo?.test_center_id,
      transmission_type_id: alertInfo?.transmission_type_id,
      course_intensity: alertInfo?.course_intensity,
    },
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      let updateData;
      if (alertInfo) {
        updateData = await updateCutomJobAlert(values, alertInfo?.id!);
      } else {
        updateData = await addCutomJobAlert(values);
      }
      setSubmitting(false);
      if (updateData?.errors?.length === 0) {
        onClose();
        refreshParent();
      }
    },
  });

  const updateDateValue = (key: string, updatedValue: any) => {
    if (updatedValue instanceof Date) {
      const utcDate = new Date(
        Date.UTC(
          updatedValue.getFullYear(),
          updatedValue.getMonth(),
          updatedValue.getDate()
        )
      );
      formik.setFieldValue(key, utcDate);
    } else {
      formik.setFieldValue(key, updatedValue);
    }
  };

  const onClose = () => {
    formik.resetForm();
    onHide();
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const isValidKey = /^\d$/.test(e.key);
    if (!isValidKey) {
      e.preventDefault();
    }
  };

  return (
    <>
      <form noValidate key={show ? "isOpend" : "notOpened"}>
        <Modal
          open={show}
          onClose={onClose}
          onSubmit={formik.submitForm}
          closeText={"Close"}
          submitText={"Save"}
          title={"New Job SMS Alert"}
        >
          {/* ADI Test centre */}
          <div className="flex flex-col">
            <DisclosureItem
              title="Test Centre"
              id="test_centres_item"
              disclosureCard
              disclosureCardSize="xs"
              expanded={!show}
            >
              <div className="-mt-4">
                <Dropdown
                  options={testCenters}
                  placeholder="Test centre"
                  isSearchable={true}
                  name="test_centres"
                  disabled={formik.isSubmitting}
                  id="test_centres"
                  value={testCenters?.find(
                    (t: any) => t.value == formik.values.test_center_id
                  )}
                  onChange={(e) =>
                    formik.setFieldValue("test_center_id", e.value)
                  }
                />
              </div>
            </DisclosureItem>
          </div>

          {/* ADI Transmission Types */}
          {showFilterByTransmissions && (
            <div className="flex flex-col">
              <DisclosureItem
                title="Transmission"
                disclosureCard
                disclosureCardSize="xs"
                id="transmission_type_id_item"
              >
                <div className="mb-3">
                  <RadioInputGroup
                    name="transmission_type_id"
                    label={""}
                    options={[
                      { label: "Automatic", value: TransmissionType.Automatic },
                      { label: "Manual", value: TransmissionType.Manual },
                    ]}
                    onChange={(e) =>
                      formik.setFieldValue("transmission_type_id", e)
                    }
                    value={formik.values.transmission_type_id}
                    disabled={formik.isSubmitting}
                    error={
                      formik.touched.transmission_type_id &&
                      formik.errors.transmission_type_id != undefined
                    }
                    errorMsg={formik.errors.transmission_type_id?.toString()}
                  />
                </div>
              </DisclosureItem>
            </div>
          )}

          {/* Number of hours */}
          <div className="flex flex-col">
            <DisclosureItem
              title="Number of Hours"
              disclosureCard
              disclosureCardSize="xs"
              id="number_of_hours_item"
            >
              <div className="grid grid-cols-2 gap-4 -mt-2">
                <Dropdown
                  {...formik.getFieldProps("from_hour")}
                  options={hourSearch}
                  label="From"
                  placeholder=""
                  required={false}
                  name="from_hour"
                  disabled={formik.isSubmitting}
                  id="from_hour"
                  value={hourSearch.find(
                    (t: any) => t.value == formik.values.from_hour
                  )}
                  onChange={(e) => {
                    formik.setFieldValue("from_hour", e?.value);
                  }}
                  onBlur={() => {
                    formik.setFieldTouched("from_hour");
                  }}
                />
                <Dropdown
                  {...formik.getFieldProps("to_hour")}
                  options={hourSearch}
                  label="To"
                  placeholder=""
                  required={false}
                  name="to_hour"
                  disabled={formik.isSubmitting}
                  id="to_hour"
                  value={hourSearch.find(
                    (t) => t.value == formik.values.to_hour
                  )}
                  onChange={(e) => {
                    formik.setFieldValue("to_hour", e?.value);
                  }}
                  onBlur={() => {
                    formik.setFieldTouched("to_hour");
                  }}
                />
              </div>
            </DisclosureItem>
          </div>

          {/* Rate */}
          <div className="flex flex-col">
            <DisclosureItem
              title="Rate"
              disclosureCard
              disclosureCardSize="xs"
              id="rate_range_item"
            >
              <div className="grid grid-cols-2 gap-4 -mt-2">
                <Input
                  {...formik.getFieldProps("from_rate")}
                  type="number"
                  label="From"
                  required={false}
                  placeholder=""
                  name="from_rate"
                  disabled={formik.isSubmitting}
                  id="from_rate"
                  onKeyPress={handleKeyPress}
                />
                <Input
                  {...formik.getFieldProps("to_rate")}
                  type="number"
                  label="To"
                  required={false}
                  placeholder=""
                  name="to_rate"
                  disabled={formik.isSubmitting}
                  id="to_rate"
                  onKeyPress={handleKeyPress}
                />
              </div>
            </DisclosureItem>
          </div>

          {/* Course Intensity */}
          <div className="flex flex-col">
            <DisclosureItem
              title="Course Intensity"
              disclosureCard
              disclosureCardSize="xs"
              id="course_intensity_item"
            >
              <div className="mb-3">
                <RadioInputGroup
                  name="course_intensity"
                  label={""}
                  options={[
                    { label: "Intensive", value: Course.Intensive },
                    { label: "Semi-Intensive", value: Course.SemiIntensive },
                    { label: "Relaxed", value: Course.Relaxed },
                  ]}
                  onChange={(e) => formik.setFieldValue("course_intensity", e)}
                  value={formik.values.course_intensity}
                  disabled={formik.isSubmitting}
                  error={
                    formik.touched.course_intensity &&
                    formik.errors.course_intensity != undefined
                  }
                  errorMsg={formik.errors.course_intensity?.toString()}
                />
              </div>
            </DisclosureItem>
          </div>

          {/* Test Date */}
          <div className="flex flex-col">
            <DisclosureItem
              title="Test Date"
              disclosureCard
              disclosureCardSize="xs"
              expanded={!show}
              id="test_date_item"
            >
              <div className="grid grid-cols-2 gap-x-4 -mt-3">
                <DateInput
                  selected={
                    formik.values.test_from_date
                      ? new Date(formik.values.test_from_date)
                      : null
                  }
                  isClearable={true}
                  name="test_from_date"
                  id="test_from_date"
                  onChange={(update: any) => {
                    updateDateValue("test_from_date", update);
                  }}
                  disabled={formik.isSubmitting}
                  inputLabel="From"
                  inputPlaceholder="From"
                  inputName="from_date_input"
                />
                <DateInput
                  selected={
                    formik.values.test_to_date
                      ? new Date(formik.values.test_to_date)
                      : null
                  }
                  isClearable={true}
                  name="test_to_date"
                  id="test_to_date"
                  onChange={(update: any) => {
                    updateDateValue("test_to_date", update);
                  }}
                  disabled={formik.isSubmitting}
                  inputLabel="To"
                  inputPlaceholder="To"
                  inputName="to_date_input"
                />
              </div>
            </DisclosureItem>
          </div>

          {/* Distance in miles from postcode */}
          <div className="flex flex-col">
            <DisclosureItem
              title="Distance in miles from postcode"
              disclosureCard
              disclosureCardSize="xs"
              expanded={!show}
              id="distance_item"
            >
              <div className="mb-1 -mt-3">
                <Slider
                  decreaseFunction={() => {}}
                  increaseFunction={() => {}}
                  step={rateStep}
                  minValue={minDistance}
                  maxValue={maxDistance}
                  rangeValues={[formik.values.distance]}
                  onChange={(e) => formik.setFieldValue("distance", e[0])}
                  showInput={false}
                  hideButtons={true}
                  formik={formik}
                  formatFun={() => formik.values.distance.toString()}
                  minValueFormatted={`${minDistance} miles`}
                  maxValueFormatted={`${maxDistance} miles`}
                />
              </div>
            </DisclosureItem>
          </div>
        </Modal>
      </form>
    </>
  );
};

export { CustomJobReminderDialog };
