import { FC } from 'react'
import { Checkout } from '../../../../core/models/stripeConnect/checkout'
import { PaymentStatus } from '../../../../core/models/enums/payment-status-enum';
import { CheckoutStatus } from '../../../../core/models/enums/checkout-status-enum';
import { fromStripeAmount } from '../../../helpers/stripeHelper';
import EmptyState from '../../shared/elements/EmptyState';
import { CreditCardIcon } from '@heroicons/react/24/outline';
import StackedList from '../../shared/data/StackedList';

interface IProps {
    checkouts: Array<Checkout>;
    redirectToDetails: any;
    setShowExpirePopup: any;
    setExpiredCheckoutId: any;
}

export const CheckoutsList: FC<IProps> = (props) => {

    const mapCheckoutDetails = (checkout: Checkout): {
        imgURL: string,
        status: string,
        statusColour: "darkRed" | "darkGreen" | "yellow",
        value: string
    } => {
        let status = "", color: "darkRed" | "darkGreen" | "yellow" = "yellow", imgURL = "/assets/media/svg/money-in.svg";
        if (checkout.payment_status === PaymentStatus.refunded) {
            status = "Refunded";
            color = "darkRed"
            imgURL = "/assets/media/svg/money-out.svg"
        } else {
            switch (checkout.checkout_status) {
                case CheckoutStatus.completed:
                    status = "Complete";
                    color = "darkGreen";
                    break;
                case CheckoutStatus.open:
                    status = "Awaiting Payment";
                    color = "yellow";
                    break;
                case CheckoutStatus.expired:
                    status = "Cancelled";
                    color = "darkRed";
                    break;
            }
        }

        return {
            imgURL: imgURL,
            status: status,
            statusColour: color,
            value: (checkout.payment_status === PaymentStatus.refunded ? "-" : "")
                + "£" + fromStripeAmount(checkout.adi_receives)
        }
    }

    return (
        <>
            {props.checkouts.length === 0 ?
                <EmptyState
                    Icon={CreditCardIcon}
                    title=''
                    description='You don’t have any direct payments yet'
                />
                :
                <StackedList data={props.checkouts.map(function (checkout: Checkout) {
                    return {
                        imgURL: mapCheckoutDetails(checkout)?.imgURL,
                        title: checkout.student_name ?? "",
                        description: "#" + checkout.id,
                        status: mapCheckoutDetails(checkout)?.status,
                        statusColour: mapCheckoutDetails(checkout)?.statusColour,
                        value: mapCheckoutDetails(checkout)?.value,
                        valueDesc: "",
                        onCardClicked: () => props.redirectToDetails(checkout.id),
                        showButton: checkout.checkout_status === CheckoutStatus.open,
                        buttonText: "Cancel Payment",
                        onButtonClicked: () => { props.setExpiredCheckoutId(checkout.id); props.setShowExpirePopup(true) }
                    }
                })} />
            }
        </>
    )
}