import { FC, useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../helpers";
import Button from "../shared/elements/Button";
import { useHistory } from "react-router-dom";
import {
  changeIsFirstLogin,
  checkIsFirstLogin,
} from "../../../core/services/adi-service";

const StaticWelcomePage: FC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  const data = [
    {
      icon: "money",
      text: "Completely free for ADIs and PDIs",
    },
    {
      icon: "diary",
      text: "Diary management for all your learners",
    },
    {
      icon: "finance",
      text: "Finance management",
    },
    {
      icon: "nation",
      text: "Free access to nationwide jobs & practical tests",
    },
    {
      icon: "wallet",
      text: "Request and take learner payments in-app",
    },
    {
      icon: "earth",
      text: "Continued professional development",
    },
  ];

  useEffect(() => {
    getIsFirstLogin();
  }, []);

  const redirectToQuestionnaire = () => {
    history.push(`/additional-details-adis${history.location.search}`);
    setNotFirstLogin();
  };

  const setNotFirstLogin = async () => {
    await changeIsFirstLogin({ isFirstLogin: false });
    getIsFirstLogin();
  };

  const getIsFirstLogin = async () => {
    const res = await checkIsFirstLogin();

    if (res.results.isFirstLogin === 0) {
      history.push(`/additional-details-adis${history.location.search}`);
    }

    setLoading(false);
  };

  return (
    <>
      {loading ? (
        <></>
      ) : (
        <div className="flex flex-col h-screen">
          <div className="bg-white py-10 sm:px-16 px-8">
            <img
              src={toAbsoluteUrl(
                "/assets/media/svg/welcome-page/ADI-glance.svg"
              )}
              alt="ADI-glance"
              className="mx-auto"
            />
          </div>

          <div className="bg-darkBlue sm:py-14 py-10 sm:px-16 px-8 xl:px-80 m-auto h-full flex flex-col">
            <div className="grid sm:grid-cols-3 grid-cols-2 sm:gap-16 gap-y-10 gap-x-4 grow">
              {data.map((item) => (
                <div>
                  <img
                    src={toAbsoluteUrl(
                      `/assets/media/svg/welcome-page/${item.icon}.svg`
                    )}
                    alt={item.icon}
                    className="sm:w-12 w-10 mx-auto"
                  />
                  <p className="sm:text-lg text-sm text-center text-white font-bold leading-4 mt-5">
                    {item.text}
                  </p>
                </div>
              ))}
            </div>
            <p className="sm:text-3xl text-xl text-yellow font-bold sm:mt-20 mt-12 text-center">
              And much more!
            </p>
          </div>
          <div className="pt-5 pb-6 mx-auto md:w-1/4 w-full m-auto">
            <div className="mx-4">
              <Button
                onClick={redirectToQuestionnaire}
                colour="yellow"
                size="large"
                fullWidth
              >
                <span className="text-base">Continue</span>
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export { StaticWelcomePage };
