import { FC } from 'react'
import Modal from '../../shared/overlays/Modal';
import { PencilSquareIcon, TrashIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { DetailsCard } from '../../shared/cards/DetailsCard';
import { StudentCard } from '../../shared/cards/StudentCard';
import { getTestStatusColor } from '../../../helpers/lesson-status-helper';

type Props = {
  show: boolean,
  onHide: any,
  showLesson: any,
  isUpcommingOnly: number,
  loading: boolean,
  showUpdateModal: any,
  setLesson: any,
  showDeleteModal: any,
  showCancelModal: any
}

const LessonDetailsModal: FC<Props> = ({ show, onHide, showLesson, isUpcommingOnly, loading, showUpdateModal, setLesson, showDeleteModal, showCancelModal }) => {

  return (
    <>
      <Modal
        open={show}
        onClose={onHide}
        title={'Lesson Details'}
        hideClose={false}
        hideSubmit={true}
      >

        <DetailsCard
          title={showLesson?.date_str}
          subTitle={showLesson?.form_time_str + " to " + showLesson?.to_time_str}
          badge={showLesson?.job_ref ? ("#" + showLesson?.job_ref) : null}
          loading={false}
          showAction={!(loading || showLesson?.disabled_at != null || (!showLesson?.action_to_midnight && !showLesson?.can_be_updated)) || !(loading || showLesson?.disabled_at != null || (!showLesson?.action_to_midnight && !showLesson?.can_be_removed))}
          actions={[{
            Icon: PencilSquareIcon, title: 'Edit lesson', show: !(loading || showLesson?.disabled_at != null || (!showLesson?.action_to_midnight && !showLesson?.can_be_updated)), onClick: () => {
              setLesson(showLesson)
              showUpdateModal()
              onHide();
            }
          },
          {
            Icon: TrashIcon, title: 'Delete lesson', show: !(loading || showLesson?.disabled_at != null || (!showLesson?.action_to_midnight && !showLesson?.can_be_removed)), onClick: () => {
              setLesson(showLesson)
              showDeleteModal()
              onHide()
            }
          },
          {
            Icon: XMarkIcon, title: 'Cancel lesson', show: !(loading || !showLesson?.is_private_student), onClick: () => {
              setLesson(showLesson)
              showCancelModal()
              onHide();
            }
          },]}
          pmfIcon={!showLesson?.is_private_student}
          status={   { title:showLesson?.status ?? "", color: getTestStatusColor(showLesson?.status_id) }}>
          { showLesson?.lesson_type && showLesson?.is_private_student &&
          <div className="-mt-3 mb-3"><label className="text-sm"><strong>Lesson Type: </strong> {showLesson.lesson_type}</label></div>
          }
          <StudentCard 
            loading={loading}
            title={(showLesson?.student_data?.student_first_name ?? "") + ' ' + (showLesson?.student_data?.student_surname ?? "")} 
            location={showLesson?.student_data?.student_post_code}
            description={`${showLesson?.student_data?.student_street_address ? showLesson?.student_data?.student_street_address + ', ' : ''} ${showLesson?.student_data?.student_city_name ?? ''}`}
          />
        </DetailsCard>

      </Modal>


    </>
  )
}

export { LessonDetailsModal };