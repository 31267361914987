import { PrivateTest } from "../models/tests/private-test";
import modifiedFetch from "../../../setup/axios/ModifiedFetch";
import { BaseResponse } from "../models/base-response";
import axios, { AxiosResponse } from "axios";

const TESTS_URL = `${process.env.REACT_APP_TESTS_BASE_URL}`;
export const CREATE_NEW_TEST_FOR_PRIVATE_STUDENT = `${TESTS_URL}/private/create`;
export const UPDATE_TEST_FOR_PRIVATE_STUDENT = `${TESTS_URL}/private/update`;
export const DELETE_TEST_FOR_PRIVATE_STUDENT = `${TESTS_URL}/private/delete`;


export const GET_ADI_STANDARDS_CHECK_INDICATORS = `${TESTS_URL}/adi-checklist`;
export const ADI_CHECK_INDICATOR_BY_TYPE = `${TESTS_URL}/adi-check-indicator-by-type`;
// export const ADI_CHECK_INDICATOR_BY_TYPE = `http://192.168.0.69/microservice-tests-v2/public/tests/adi-check-indicator-by-type`;


export async function getStandardsCheckIndicators(): Promise<AxiosResponse<any>> {
  return axios.get<BaseResponse<Array<any>>>(GET_ADI_STANDARDS_CHECK_INDICATORS);
}
// szz

export async function StandardsCheckIndicatorsByType(data: any): Promise<Response> {
  return modifiedFetch({
    url: ADI_CHECK_INDICATOR_BY_TYPE,
    method: "POST",
    data:data
  });
}

export async function UpdatePrivateStudentTest(data: any): Promise<Response> {
    return modifiedFetch({
      url: UPDATE_TEST_FOR_PRIVATE_STUDENT,
      method: "PATCH",
      data:data
    });
}
export async function deletePrivateTest(testId: any) {
  return modifiedFetch({
    url: `${DELETE_TEST_FOR_PRIVATE_STUDENT}/${testId}`,
    method: "DELETE",
  });
}
export async function CreatePrivateStudentTest(data: PrivateTest): Promise<Response> {
  return modifiedFetch({
    url: CREATE_NEW_TEST_FOR_PRIVATE_STUDENT,
    method: "POST",
    data:data
  });
}
export function SetStudentAsUbsent(testId: string, data: PrivateTest): Promise<Response> {
  return modifiedFetch({
    url: `${TESTS_URL}/${testId}/absented`,
    method: "POST",
    data:data
  });
}