import axios, { AxiosResponse } from "axios";
import { AcceptJobData } from "../models/accept-job-data";
import { BaseResponse } from "../models/base-response";
import { FilterObject } from "../models/filter-object";
import { Job } from "../models/job";
import { AdiJob } from "../models/adi-job";
import { PagedResult } from "../models/paged-result";
import { AdiJobNote } from "../models/adi-job-note";
import { Lesson } from "../models/lesson";
import { CheckListItem } from "../models/checklist-item";
import { CurrentJobResponse } from "../models/current-job-response";
import { Test } from "../models/tests/test";
import modifiedFetch from "../../../setup/axios/ModifiedFetch";
import { PrivateStudentDataModel } from "../models/private-student-data";

const JOB_URL = process.env.REACT_APP_JOBS_BASE_URL;
const TEST_URL = process.env.REACT_APP_TESTS_BASE_URL;
const LESSON_URL = process.env.REACT_APP_LESSONS_BASE_URL;

export const GET_AVAILABLE_JOB_URL = `${JOB_URL}/jobs/available-filter-list`;
export const GET_MIN_AND_MAX_HOUR_RATES = `${JOB_URL}/jobs/min-max-hour-rates`;
export const GET_CURRENT_JOB_URL = `${JOB_URL}/jobs/current-jobs`;
export const GET_COMPLETED_JOB_URL = `${JOB_URL}/jobs/completed-jobs`;
export const GET_TESTS_URL = `${JOB_URL}/get-test-list-by-adi`;

export const GET_ADI_DASHBOARD_TEST_URL = `${JOB_URL}/tests-for-adi`;
export const CONFIRM_TEST_URL = `${JOB_URL}/adi-confirm-test`;

export const GET_TEST_REJECTION_REASONS_URL = `${TEST_URL}/rejection-reasons`;
export const GET_TEST_RESCHEDULE_REASONS_URL = `${TEST_URL}/reschedule-reasons`;

export const REJECT_TEST_URL = `${JOB_URL}/adi-reject-test`;
export const RECHEDULE_TEST_URL = `/adi-reschedule-test`;
export const ADI_JOBS_PREFIX = `${JOB_URL}/adi-jobs/`;

export const ADD_BID_URL = `${JOB_URL}/ask-for-bid/`;
export const ADD_Location_BID_URL = `${JOB_URL}/request-location-bid/`;

export const JOB_PREFIX_URL = `${JOB_URL}/jobs/`;
export const JOB_NOTE_PREFIX_URL = `${JOB_URL}/jobs-notes`;
export const GET_ACCEPT_JOB_DATA_URL = "/accept-job-data";
export const GET_CURRENT_JOB_DETAILS_URL = "/current-job-details";
export const ACCEPT_JOB_URL = "/accept-job";
export const LESSON_JOB_PREFIX_URL = `${LESSON_URL}/jobs/`;
export const LESSON_REFLECTION_JOB_PREFIX_URL = `${LESSON_URL}/jobs-reflections/`;
export const GET_LESSONS = "/lessons";
export const CHECK_UPDATE_URL = "/check-edit-lesson-overlaps";
export const HIDE_JOB_URL = `${JOB_URL}/store-adi-hidden-job`;

export const CHECK_ADD_URL = "/check-add-lesson-overlaps";

export const GET_ADI_JOB_NOTES_URL = "/adi-job-notes";
export const GET_CURRENT_PAID_JOB_URL = `${JOB_URL}/adi-paid-current-jobs`;
export const GET_CHECK_HAS_PREV_JOBS = `${LESSON_URL}/get-has-prev-lessons`;
export const GET_CHECKLIST = `${JOB_URL}/intro-checklist`;
export const STORE_ADI_JOB_CHECKLIST = `${JOB_URL}/store-adi-job-checklist`;
export const UPDATE_JOB_TEST_RESULT = "/update-job-test-result";

export const LESSON_PREFIX_URL = `${LESSON_URL}/lessons`;

export const LISSON_LIST = "/filtered-lessons";
const LESSON_LIST_WITH_TESTS = "/lessons-list-with-tests";

export const GET_JOB_DEFAULT_VIEWS = `${JOB_URL}/jobs-default-views`;
export const GET_ADI_JOB_DEFAULT_VIEWS = `${JOB_URL}/adis/get-jobs-default-view`;
export const UPDATE_ADI_JOB_DEFAULT_VIEWS = `${JOB_URL}/adis/update-jobs-default-view`;
export const NEW_JOBS_DASHBOARD = `${JOB_URL}/top-new-jobs`;
export const UPGRADED_JOBS_DASHBOARD = `${JOB_URL}/all-upgraded-job`;
export const ACCEPT_JOB_UPGRADE_DASHBOARD = `/accept-upgrade`;
export const READY_FOR_FIRST_LESSON = `${JOB_URL}/ready-for-first-lesson`;
export const UNPLANNED_FUTURE_LESSONS = `${JOB_URL}/unplanned-future-lesson`;
export const FIRST_LESSON_DATE = `${LESSON_URL}/get-first-lesson-date`;
export const GET_PRIVATE_STUDENTS_LIST = `${JOB_URL}/get-students`;
export const GET_ALL_PAID_STUDENTS_LIST = `${JOB_URL}/get-adi-paid-students`;
export const GET_DEAL_TAGS = `${JOB_URL}/get-deal-tags`;
export const SET_JOB_LIMIT_EXCEEDED_VIEWED = `${JOB_URL}/job-limit-viewed`;
export const JOB_STATEMENT = `/current-job-statement`;
export const WITHDRAW_BID = `${JOB_URL}/withdraw-job-bid`;
export const DASHBOARD_LESSONS = `${JOB_URL}/dashboard-lessons`;
const API_KEY = process.env.REACT_APP_X_API_KEY;

export const ADI_APPOINTMENTS = `${LESSON_URL}/adi-next-appointments-by-date`;
export const GET_PRIVATE_STUDENTS = `${JOB_URL}/get-private-students`;
export const GET_PRIVATE_LESSONS = `${LESSON_URL}/private-job-lessons`;

const GET_CURRENT_PAID_JOB_WITH_STUDENTS_URL = `${JOB_URL}/adi-paid-current-jobs-with-students`;
const GET_PRIVATE_STUDENT_TESTS = `${JOB_URL}/get-private-student-tests`;

export const GET_JOBS_FOR_LESSON = `${JOB_URL}/get-jobs-for-lesson`;
export const DELETE_PRIVATE_STD_TEST = `${JOB_URL}/delete-private-learner-test`;

export const GET_LESSON_RECURRING_OPTIONS = `${LESSON_URL}/recurring-options`;
export const ADI_LESSON_TYPES = `${LESSON_URL}/lesson-types`;
export const CHECK_ADI_HAS_ACTIVE_PMF_JOBS = `${JOB_URL}/check-has-active-pmf-jobs`;

export const ADD_OTHER_EVENT_URL = `${LESSON_URL}/events`
export const GET_OTHER_EVENTS_IN_CALENDAR_URL = `${LESSON_URL}/filtered-events`
export const UPDATE_OTHER_EVENT_URL = `${LESSON_URL}/events/`
export const DELETE_OTHER_EVENT_URL = `${LESSON_URL}/events/delete`

export function availableJobList(
  data: FilterObject
): Promise<AxiosResponse<BaseResponse<PagedResult<Job>>>> {
  return axios.post<BaseResponse<PagedResult<Job>>>(
    GET_AVAILABLE_JOB_URL,
    data
  );
}

export function getMinAndMaxHourRates(
  data: FilterObject
): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.post<BaseResponse<any>>(GET_MIN_AND_MAX_HOUR_RATES, data);
}
export function currentJobList(
  data: FilterObject
): Promise<AxiosResponse<BaseResponse<PagedResult<AdiJob>>>> {
  return axios.post<BaseResponse<PagedResult<AdiJob>>>(
    GET_CURRENT_JOB_URL,
    data
  );
}
export function completedJobList(
  data: FilterObject
): Promise<AxiosResponse<BaseResponse<PagedResult<AdiJob>>>> {
  return axios.post<BaseResponse<PagedResult<AdiJob>>>(
    GET_COMPLETED_JOB_URL,
    data
  );
}

export function getTestsList(data: FilterObject): Promise<BaseResponse<any>> {
  return modifiedFetch({
    url: GET_TESTS_URL,
    method: "POST",
    data: data,
  });
}
export function getAdiDashboardTests(): Promise<BaseResponse<any>> {
  return modifiedFetch({
    url: GET_ADI_DASHBOARD_TEST_URL,
    method: "GET",
  });
}
// confirmJobTest
export function confirmJobTest(
  data: any
): Promise<AxiosResponse<BaseResponse<PagedResult<Job>>>> {
  return axios.post<BaseResponse<PagedResult<Job>>>(CONFIRM_TEST_URL, data);
}

export function getRejectionReasons(): Promise<AxiosResponse<Array<any>>> {
  return axios.get<Array<any>>(
    GET_TEST_REJECTION_REASONS_URL + "?is_adi_reason=1"
  );
}
export function getRecheduleReasons(): Promise<AxiosResponse<any>> {
  return axios.get(GET_TEST_RESCHEDULE_REASONS_URL + "?type=practical");
}

export function rejectJobTest(
  data: any
): Promise<AxiosResponse<BaseResponse<PagedResult<Job>>>> {
  return axios.post<BaseResponse<PagedResult<Job>>>(REJECT_TEST_URL, data);
}
export function rescheduleJobTest(
  data: any,
  adiJobId: any
): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.post<BaseResponse<any>>(
    ADI_JOBS_PREFIX + adiJobId + RECHEDULE_TEST_URL,
    data
  );
}
export function currentJobDetails(
  jobId: string,
  adiJobId: any
): Promise<AxiosResponse<BaseResponse<any>>> {
  let queryString = adiJobId ? "?adi_job_id=" + adiJobId : " ";
  return axios.get<BaseResponse<CurrentJobResponse>>(
    JOB_PREFIX_URL + jobId + GET_CURRENT_JOB_DETAILS_URL + queryString
  );
}
export function currentJobStatement(
  jobId: string
): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.get<BaseResponse<CurrentJobResponse>>(
    JOB_PREFIX_URL + jobId + JOB_STATEMENT
  );
}
export function getAdiJobNotes(
  adiJobId: string
): Promise<AxiosResponse<BaseResponse<AdiJobNote[]>>> {
  return axios.get<BaseResponse<AdiJobNote[]>>(
    JOB_NOTE_PREFIX_URL + "/" + adiJobId + GET_ADI_JOB_NOTES_URL
  );
}
export function deleteAdiJobNote(
  id: string
): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.delete<BaseResponse<any>>(JOB_NOTE_PREFIX_URL + "/" + id);
}
export function addAdiJobNote(
  note: any
): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.post<BaseResponse<any>>(JOB_NOTE_PREFIX_URL, note);
}
export function getAcceptJobData(
  jobId: string,
  isFromSMS: boolean
): Promise<AxiosResponse<BaseResponse<AcceptJobData>>> {
  return axios.post<BaseResponse<AcceptJobData>>(
    JOB_PREFIX_URL + jobId + GET_ACCEPT_JOB_DATA_URL,
    { is_from_sms: isFromSMS }
  );
}
export function checkOverlap(
  jobId: string
): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.get<BaseResponse<any>>(JOB_URL + "/check-job-overlap/" + jobId);
}
export function cancelOverlap(
  jobId: string
): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.put<BaseResponse<any>>(
    JOB_URL + "/add-cancelled-overlap/" + jobId
  );
}
export function checkTestOverlap(
  data: any
): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.post<BaseResponse<any>>(JOB_URL + "/check-test-overlap", data);
}
export function cancelTestOverlap(
  data: any
): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.post<BaseResponse<any>>(
    JOB_URL + "/add-cancelled-test-overlap",
    data
  );
}
export function acceptJob(
  jobId: string,
  data: any
): Promise<AxiosResponse<BaseResponse<AcceptJobData>>> {
  return axios.post<BaseResponse<AcceptJobData>>(
    JOB_PREFIX_URL + jobId + ACCEPT_JOB_URL,
    data
  );
}
export function hideJob(data: any): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.post<BaseResponse<any>>(HIDE_JOB_URL, data);
}
export function addJobBid(
  data: any,
  jobId: any
): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.post<BaseResponse<any>>(ADD_BID_URL + jobId, data);
}
export function addLocationBid(
  data: any,
  jobId: any
): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.post<BaseResponse<any>>(ADD_Location_BID_URL + jobId, data);
}


export function updateLessonDate(
  lessonId: any,
  lessonData: any
): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.put<BaseResponse<any>>(
    LESSON_PREFIX_URL + "/" + lessonId,
    lessonData
  );
}
export function addLesson(
  lessonData: any
): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.post<BaseResponse<any>>(LESSON_PREFIX_URL, lessonData);
}

export function removeLesson(
  data: any
): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.post<BaseResponse<any>>(LESSON_PREFIX_URL + "/remove", data);
}
export function getJobLessonsData(
  jobId: string,
  adiJobId: string
): Promise<AxiosResponse<BaseResponse<Array<Lesson>>>> {
  return axios.get<BaseResponse<Array<Lesson>>>(
    LESSON_JOB_PREFIX_URL + jobId + GET_LESSONS + '/' + adiJobId
  );
}
export function getAdiLessonsData(
  data: any,
  withCalenderEvents = false
): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.post<BaseResponse<any>>(
    LESSON_URL + LISSON_LIST + (withCalenderEvents ? "?with_calender_events=" + withCalenderEvents : ""),
    data
  );
}
export function getAdiLessonsAndTestsData(
  data: any
): Promise<BaseResponse<any>> {
  return modifiedFetch({
    url: LESSON_URL + LESSON_LIST_WITH_TESTS,
    method: "POST",
    data: data,
  });
}
export function getadiPaidJobs(): Promise<
  AxiosResponse<BaseResponse<Array<any>>>
> {
  return axios.get<BaseResponse<Array<any>>>(GET_CURRENT_PAID_JOB_URL);
}
export function checkHasPrevJobs(): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.get<BaseResponse<any>>(GET_CHECK_HAS_PREV_JOBS);
}
export function getCheckList(): Promise<
  AxiosResponse<BaseResponse<CheckListItem[]>>
> {
  return axios.get<BaseResponse<CheckListItem[]>>(GET_CHECKLIST);
}
export function storeAdiJobCheckList(
  adiJobCheckList: any
): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.post<BaseResponse<any>>(
    STORE_ADI_JOB_CHECKLIST,
    adiJobCheckList
  );
}
export function updateJobTestResult(
  jobRef: string,
  jobTestResult: any
): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.post<BaseResponse<any>>(
    JOB_PREFIX_URL + jobRef + UPDATE_JOB_TEST_RESULT,
    jobTestResult
  );
}

export function getJobDefaultViewOptions(): Promise<
  AxiosResponse<BaseResponse<Array<any>>>
> {
  return axios.get<BaseResponse<Array<any>>>(GET_JOB_DEFAULT_VIEWS);
}

export function getADIJobDefaultViewOptions(): Promise<
  AxiosResponse<BaseResponse<any>>
> {
  return axios.get<BaseResponse<any>>(GET_ADI_JOB_DEFAULT_VIEWS);
}

export function updateADIJobDefaultViewOptions(
  id: number
): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.post<BaseResponse<any>>(UPDATE_ADI_JOB_DEFAULT_VIEWS, {
    jobs_default_view_id: id,
  });
}

export function newJobsDashboard(): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.get<BaseResponse<any>>(NEW_JOBS_DASHBOARD);
}

export function upgradedJobsDashboard(): Promise<
  AxiosResponse<BaseResponse<any>>
> {
  return axios.get<BaseResponse<any>>(UPGRADED_JOBS_DASHBOARD);
}

export function acceptJobUpgrade(
  adiJobId: string
): Promise<AxiosResponse<BaseResponse<CurrentJobResponse>>> {
  return axios.put<BaseResponse<CurrentJobResponse>>(
    JOB_URL + "/adi-jobs/" + adiJobId + ACCEPT_JOB_UPGRADE_DASHBOARD
  );
}

export function readyForFirstLessonDashboard(): Promise<
  AxiosResponse<BaseResponse<any>>
> {
  return axios.get<BaseResponse<any>>(READY_FOR_FIRST_LESSON);
}

export function unplannedFutureLessonsDashboard(): Promise<
  AxiosResponse<BaseResponse<any>>
> {
  return axios.get<BaseResponse<any>>(UNPLANNED_FUTURE_LESSONS);
}

export function getJobFirstLesson(
  jobId: any
): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.get<BaseResponse<any>>(FIRST_LESSON_DATE + "/job/" + jobId);
}

export function getStudentListFilter(
  filter: any
): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.post<BaseResponse<any>>(GET_PRIVATE_STUDENTS_LIST, filter);
}

export function getDealTags(
  dealId: any
): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.get<BaseResponse<any>>(GET_DEAL_TAGS + "/" + dealId);
}

export function setJobLimitExceededViewed(
  data: any
): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.post<BaseResponse<any>>(SET_JOB_LIMIT_EXCEEDED_VIEWED, data);
}

export function deleteJobBid(
  jobId: any
): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.get<BaseResponse<any>>(WITHDRAW_BID + "/" + jobId);
}

export function getDashboardLessons() {
  return axios.get<BaseResponse<PagedResult<any>>>(DASHBOARD_LESSONS);
}
export function getAdiAppointments(
  data: any
): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.post<BaseResponse<any>>(ADI_APPOINTMENTS, data);
}

export function getAdiPrivateStudents(
  removeCompleted?: boolean
): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.get<BaseResponse<any>>(
    `${GET_PRIVATE_STUDENTS}?remove_completed=${removeCompleted}`
  );
}

export function getPrivateStudentLessons(
  jobId: string
): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.get<BaseResponse<any>>(GET_PRIVATE_LESSONS + "/" + jobId);
}

export function getAdiPaidJobsWithStudents(): Promise<
  AxiosResponse<BaseResponse<Array<any>>>
> {
  return axios.get<BaseResponse<Array<any>>>(
    GET_CURRENT_PAID_JOB_WITH_STUDENTS_URL
  );
}

export function getPrivateStudentTests(): Promise<
  AxiosResponse<BaseResponse<Array<any>>>
> {
  return axios.get<BaseResponse<Array<any>>>(GET_PRIVATE_STUDENT_TESTS);
}

export function getJobForAddingLessons(): Promise<
  AxiosResponse<BaseResponse<Array<any>>>
> {
  return axios.get<BaseResponse<Array<any>>>(GET_JOBS_FOR_LESSON);
}

export function getLessonsRecurringOptions(): Promise<
  AxiosResponse<BaseResponse<any>>
> {
  return axios.get<BaseResponse<any>>(GET_LESSON_RECURRING_OPTIONS);
}

export function deletePrivateStdTest(
  jobId: string
): Promise<BaseResponse<any>> {
  return modifiedFetch({
    url: DELETE_PRIVATE_STD_TEST + "/" + jobId,
    method: "DELETE",
  });
}

export function getCompletePrivateLearnerReasons(): Promise<
  AxiosResponse<BaseResponse<any>>
> {
  return axios.get<BaseResponse<any>>(JOB_URL + "/complete-learner-reasons");
}

export function completePrivateLearner(
  data: any
): Promise<AxiosResponse<BaseResponse<any>>> {
  return axios.post<BaseResponse<any>>(
    JOB_URL + "/complete-private-learner",
    data
  );
}

export function checkAdiHasActivePmfJobs(): Promise<BaseResponse<any>> {
  return modifiedFetch({
    url: CHECK_ADI_HAS_ACTIVE_PMF_JOBS,
    method: "GET",
  });
}

export function moveOnHoldPrivateLearner(
  data: any
): Promise<BaseResponse<any>> {
  return modifiedFetch({
    url: `${JOB_URL}/hold-private-learner`,
    method: "POST",
    data: data,
  });
}

export async function getLessonChargeabilityDetails(
  lessonId: string
): Promise<BaseResponse<any>> {
  return modifiedFetch({
    url: `${LESSON_URL}/lesson-chargeability-details/${lessonId}`,
    method: "GET",
  });
}
export function addOtherEvent(
  data: any
): Promise<BaseResponse<any>> {
  return modifiedFetch({
    url: ADD_OTHER_EVENT_URL,
    method: "POST",
    data: data,
  });
}

export function getOtherEventsInCalendar(data: any): Promise<BaseResponse<any>> {
  return modifiedFetch({
    url: GET_OTHER_EVENTS_IN_CALENDAR_URL,
    method: "POST",
    data: data,
  });
}

export function updateOtherEvent(id: string, data: any): Promise<BaseResponse<any>> {
  return modifiedFetch({
    url: UPDATE_OTHER_EVENT_URL + id,
    method: "PUT",
    data: data,
  });
}

export function removeOtherEvent(data: any): Promise<BaseResponse<any>> {
  return modifiedFetch({
    url: DELETE_OTHER_EVENT_URL,
    method: "POST",
    data: data,
  });
}

export async function cancelPrivateStudentLesson(
  lessonId: string,
  data: any
): Promise<BaseResponse<any>> {
  return modifiedFetch({
    url: `${LESSON_URL}/cancel-lesson/${lessonId}`,
    method: "PUT",
    data: data,
  });
}

export function getAdiLessonTypes(): Promise<Promise<BaseResponse<any>>> {
  return modifiedFetch({
    url: `${ADI_LESSON_TYPES}`,
    method: "GET",
  });
}

export function getAdiJobsCount(): Promise<BaseResponse<any>> {
  return modifiedFetch({
    url: `${JOB_URL}/adi-jobs-count`,
    method: "GET",
  });
}

export function getJobLessonsDataWithReflection(
  jobId: string,
  adiJobId: string
): Promise<AxiosResponse<BaseResponse<Array<any>>>> {
  return axios.get<BaseResponse<Array<any>>>(
    LESSON_REFLECTION_JOB_PREFIX_URL + jobId + GET_LESSONS +'/' +adiJobId
  );
}
export function addAvailabilityBid(data: any): Promise<BaseResponse<any>> {
  return modifiedFetch({
    url: `${JOB_URL}/request-availability-bid`,
    method: "POST",
    data: data,
  });
}


export function getBidReasons(): Promise<Array<any>> {
  return modifiedFetch({
    url: `${JOB_URL}/portal-bid-reasons`,
    method: "GET",
  });
}
