import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline";
import { Range, getTrackBackground } from 'react-range'
import Input from "./Input";

interface IProps {
    decreaseFunction: () => void
    increaseFunction: () => void
    onChange: (e: any) => void
    step: number
    minValue: number
    maxValue: number
    rangeValues: Array<any>
    showInput?: boolean
    formik?: any
    hideButtons?:boolean
    formatFun: (index: number) => string
    minValueFormatted: string
    maxValueFormatted: string
    disabled?: boolean
}

export default function Slider({
    decreaseFunction,
    increaseFunction,
    onChange,
    step,
    minValue,
    maxValue,
    rangeValues,
    hideButtons,
    showInput = false,
    formik,
    formatFun,
    minValueFormatted,
    maxValueFormatted,
    disabled = false
}: IProps) {

    return (
        <>
            <div className='flex justify-between items-center mt-3'>
            {!hideButtons&&    <button type="button" onClick={decreaseFunction} className={`bg-gray-300 p-[2px] ${disabled ? 'opacity-40' : ''}`} disabled={disabled}>
                    <MinusSmallIcon className='w-5 h-5' />
                </button>}

                <div className='flex-grow'>
                    <div className='flex justify-center'>
                        <Range
                            values={rangeValues}
                            step={step}
                            min={minValue}
                            max={maxValue}
                            disabled={disabled}
                            onChange={(e) => {
                                onChange(e)
                            }}
                            renderTrack={({ props, children }) => (
                                <div
                                    onMouseDown={props.onMouseDown}
                                    onTouchStart={props.onTouchStart}
                                    style={{
                                        ...props.style,
                                        height: '14px',
                                        marginTop: '48px',
                                        display: 'flex',
                                        width: '90%'
                                    }}
                                >
                                    <div
                                        ref={props.ref}
                                        style={{
                                            height: '5px',
                                            width: '100%',
                                            borderRadius: '4px',
                                            background: getTrackBackground({
                                                values: rangeValues,
                                                colors: ['#FFD04F', '#ccc', '#ccc'],
                                                min: minValue,
                                                max: maxValue
                                            }),
                                            opacity: disabled ? '80%' : '100%'
                                        }}
                                    >
                                        {children}
                                    </div>
                                </div>
                            )}
                            renderThumb={({ index, props, isDragged }) => (
                                <div
                                    {...props}
                                    style={{
                                        ...props.style,
                                        height: '26px',
                                        width: '26px',
                                        borderRadius: '50%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        boxShadow: 'rgb(193 193 193) 0px 0px 3px 1px',
                                        transform: 'translate(409.625px, -8.5px)',
                                        backgroundColor: '#FFD04F',
                                        border: '4px solid rgb(255, 255, 255)',
                                        opacity: disabled ? '80%' : '100%'
                                    }}
                                >
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: '-38px',
                                            color: '#fff',
                                            padding: '0 4px 0 4px',
                                            borderRadius: '4px',
                                            backgroundColor: '#FFD04F',
                                            textAlign: 'center',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        {formatFun(index)}
                                    </div>

                                </div>
                            )}
                        />
                    </div>
                    <div className='flex justify-between mx-4 mt-1'>
                        <p className=''
                            style={{
                                color: '#828287',
                                fontSize: '16px',
                                lineHeight: '1.5'
                            }}>
                            {minValueFormatted}
                        </p>
                        <p className=''
                            style={{
                                color: '#828287',
                                fontSize: '16px',
                                lineHeight: '1.5'
                            }}> {maxValueFormatted} </p>
                    </div>
                </div>

               {!hideButtons && <button type="button" onClick={increaseFunction} className={`bg-gray-300 p-[2px] ${disabled ? 'opacity-40' : ''}`} disabled={disabled}>
                    <PlusSmallIcon className='w-5 h-5' />
                </button>}
            </div>

            {showInput &&
                <Input
                    {...formik.getFieldProps('requested_rate')}
                    name='requested_rate'
                    disabled={formik.isSubmitting}
                    id='requested_rate'
                    inputGroup={true}
                    inputGroupValue="£"
                    type='number'
                    step=".01"
                    min={minValue}
                    max={maxValue}
                    onChange={(e) => onChange([e.target.value])}
                    error={(formik.touched.requested_rate && formik.errors.requested_rate) ? true : false}
                    errorMsg={formik.errors.requested_rate}
                />}
        </>
    )
}