import { FC, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ReactGA from "react-ga4";
import { getPersonalData } from "../../../../../core/services/adi-service";
import { PersonalDataModel } from "../../../../../core/models/personal-data-model";
import { AdiJob } from "../../../../../core/models/adi-job";
import { currentJobDetails } from "../../../../../core/services/job-service";
import Alert from "../../../shared/overlays/Alert";
import { GrayCard } from "../../../shared/cards/GrayCard";
import { AvailabilityCard } from "../../../shared/cards/AvailabilityCard";
import DescriptionList from "../../../shared/data/DescriptionList";
import Button from "../../../shared/elements/Button";
import { RescheduleTestDialog } from "./_modals/RescheduleTestDialog";
import { JobNotes } from "./_modals/JobNotes";
import { Lesson } from "../../../../../core/models/lesson";
import { AddTestResult } from "./_modals/AddTestResult";
import {
  CheckIcon,
  ChevronDownIcon,
  PauseCircleIcon,
  PencilSquareIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { AddPrivateStudentAvailabilityDialog } from "./_modals/AddPrivateStudentAvailabilityDialog";
import { AddPrivateStudentDialog } from "../../../students/AddPrivateStudentDialog";
import Skeleton from "@mui/material/Skeleton";
import { Course } from "../../../../../core/models/enums/course-intensity-enum";
import MedicalConditionsDialog from "./_modals/MedicalConditionsDialog";
import { DeletePrivateStudentTestModal } from "../../../test/DeletePrivateStudentTestModal";
import MarkPrivateLearnerAsCompletedDialog from "../../modals/MarkPrivateLearnerAsCompletedDialog";
import { TestStatusEnum } from "../../../../../core/models/enums/test-status-enum";
import { GrayFullCard } from "../../../shared/cards/GrayFullCard";
import { toAbsoluteUrl } from "../../../../helpers";
import { getPrivateStudentavAilability } from "../../../../../core/services/student-service";
import { AvailabilityModel } from "../../../../../core/models/availability-modal";
import { PrivateStudentTest } from "../../../students/privateStudentTest";
import { LearnerJobStatusEnum } from "../../../../../core/models/enums/learner-job-status-enum";
import JobOnHoldDialog from "../../modals/JobOnHoldDialog";
import moment from "moment";
import { LogTestStatus } from "./_modals/LogTestStatus";
import { Test } from "../../../../../core/models/tests/test";
import LearnerCheckList from "./_modals/LearnerCheckList";
import { getAdiJobTotalPaid } from "../../../../../core/services/payment-service";

interface IProps {
  refreshLessonsTab: any;
}

const GeneralTab: FC<IProps> = ({ refreshLessonsTab }) => {
  const { jobId }: any = useParams();
  const [jobDetails, setJobDetails] = useState<AdiJob>();
  const [loading, setLoading] = useState<boolean>(false);
  const [lessonloading, setLessonLoading] = useState<boolean>(false);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const [tags, setTags] = useState<Array<any>>();
  const [firstUpcommingLesson, setFirstUpcommingLesson] = useState<Lesson>();
  const [showAddTestResultModal, setShowAddTestResultModal] = useState(false);
  const [adiData, setAdiData] = useState<PersonalDataModel>();
  const [showDeleteTestCard, setShowDeleteTestCard] = useState<boolean>(false);
  const [showReschduleTest, setShowReschduleTest] = useState(false);
  const [showPrivateStudentAvailability, setShowPrivateStudentAvailability] =
    useState<boolean>(false);
  const [showMedicalConditions, setShowMedicalConditions] =
    useState<boolean>(false);
  const [showRescheduleTestDialog, setShowRescheduleTestDialog] =
    useState<boolean>(false);
  const [showMarkTestAsCompleted, setShowMarkTestAsCompleted] =
    useState<boolean>(false);
  const [showMoveOnHold, setShowMoveOnHold] = useState<boolean>(false);
  const [showPracticalTestDialog, setShowPracticalTestDialog] =
    useState<boolean>(false);
  const [testDetails, setTestDetails] = useState<any>();
  const [privateStudentAvailability, setPrivateStudentAvailibility] = useState<
    Array<AvailabilityModel>
  >([]);
  const [isOpenTestAttempt, setIsOpenTestAttempt] = useState(false);
  const [showLogTestResultModal, setShowLogTestResultModal] = useState(false);

  const [show, setShow] = useState(false);
  const [availMatch, setAvailMatch] = useState<string>();
  const history = useHistory();
  const adiJobId = Object.fromEntries(urlSearchParams.entries()).adi_job_id;
  const recruiterName = Object.fromEntries(urlSearchParams.entries()).recruiter;
  const recruiterId = Object.fromEntries(urlSearchParams.entries()).recruiterId;
  const [paymentDetails, setPaymentDetails] = useState<any>();
  const handleClose = () => {
    setShow(false);
  };
  const refresh = () => {
    getCurrentJobDetails();
  };
  const handleShow = () => {
    setShow(true);
  };
  const handlePracticalTestClose = () => {
    setShowPracticalTestDialog(false);
  };
  const urlGenerationDateTime = Object.fromEntries(
    urlSearchParams.entries()
  ).datetime;
  useEffect(() => {
    async function fetchData() {
      await getCurrentJobDetails();
    }
    fetchData();
    getPersonalData().then((res) => {
      setAdiData(res.data.results);
    });
  }, [jobId]);
  const isLessonsOnly = () => {
    return (
      (testDetails?.location?.name == null &&
        testDetails?.booked_for == null &&
        jobDetails?.is_private_student === 0) ||
      (testDetails?.jobTests?.length == 0 &&
        jobDetails?.is_private_student === 1)
    );
  };
  const progress = () => {
    return jobDetails?.total_rate &&
      jobDetails?.paid &&
      jobDetails?.total_rate > 0
      ? jobDetails?.paid >= jobDetails?.total_rate
        ? 100
        : (jobDetails?.paid / jobDetails?.total_rate) * 100
      : 0;
  };

  const getCurrentJobDetails = async () => {
    setLoading(true);

    let res = await currentJobDetails(jobId, adiJobId).catch((error) => {
      history.push("/ADI/students");
      setLoading(false);
    });
    if (res) {
      setJobDetails(res.data.results.job);
      setFirstUpcommingLesson(res.data.results.job.nextLesson);
      let tempTags = [];
      if (res.data.results.redirect_to_new_jobs === true) {
        var url = `/ADI/new-jobs/details/${jobId}?`;
        url += `isFromSMS=true&isFromNewJobsSMS=false`;

        if (recruiterName && urlGenerationDateTime) {
          url += `&recruiter=${recruiterName}&recruiterId=${recruiterId}&datetime=${urlGenerationDateTime}`;
        }
        history.push(url);
      } else if (res.data.results.redirect_to_my_jobs === true) {
        history.push("/ADI/students");
      } else {
        if (res.data.results.job.transmission_type_name != null) {
          tempTags.push({
            title: res.data.results.job.transmission_type_name,
            colour: "green",
          });
        }
        if (
          res.data.results.job.availability_match &&
          res.data.results.job.availability_match >= 20
        ) {
          let match = "Great";
          if (
            res.data.results.job.availability_match >= 20 &&
            res.data.results.job.availability_match <= 34
          ) {
            match = "Fair";
          }
          if (
            res.data.results.job.availability_match >= 35 &&
            res.data.results.job.availability_match < 50
          ) {
            match = "Good";
          }
          setAvailMatch(match);
        }

        setTags(tempTags);
        ReactGA.event("view_my_job_details", {
          user_id: adiData?.zendesk_contact_id,
        });
        setJobDetails(res.data.results.job);
        setTestDetails(res.data.results.job.testDetails);

        setShowReschduleTest(
          !res.data.results.job.has_prev_reschedule_request &&
            res.data.results.completed_at == null &&
            res.data.results.job.removed_at == null &&
            res.data.results.job.test_date != null &&
            res.data.results.job.testDetails?.abilities?.can_reschedule
        );
      }
      await getAvailibility(res.data.results.job.student_id);
      const paymentResponse = await getAdiJobTotalPaid(
        res.data.results.job.job_id,
        res.data.results.job.adi_id,
        {
          adi_job_creation_date: res.data.results.job.created_at,
          total_amount: res.data.results.job.actual_total_rate,
        }
      ).catch();
      if (paymentResponse?.results) {
        setPaymentDetails(paymentResponse.results);
      }
      setLoading(false);
    }
  };
  const getAvailibility = async (student_id: string) => {
    let availabilityRes = await getPrivateStudentavAilability(student_id);
    if (availabilityRes && availabilityRes.results.length > 0) {
      setPrivateStudentAvailibility(availabilityRes.results);
    }
  };
  const hasUpcomingTestDate = () => {
    if (testDetails?.booked_for) {
      const testDate = new Date(testDetails?.booked_for);
      const today = new Date();
      testDate.setHours(0, 0, 0, 0);
      today.setHours(0, 0, 0, 0);
      return testDate.getTime() > today.getTime();
    }
    return true;
  };

  const getCourseIntensityDesc = () => {
    if (jobDetails?.course_intensity === Course.Intensive) {
      return "This learner wants to fit their lessons into as few days as possible. One example of this is four 3-hour lessons a week, so they’d expect to complete a 10-hour course in a week and a 48-hour course in around a month.";
    } else if (jobDetails?.course_intensity === Course.SemiIntensive) {
      return "This learner doesn’t want to take things too intensively. An example of a semi-intensive course would be one or two 3-hour lessons per week.";
    } else if (jobDetails?.course_intensity === Course.Relaxed) {
      return "This learner doesn’t want an intensive course and is happy to spread their lessons out. This would likely consist of one or two 2-hour lessons per week.";
    } else {
      return "";
    }
  };

  const setupEditBtns = () => {
    let editBtns: {
      title: string;
      Icon: any;
      show: boolean;
      onClick: () => void;
    }[] = [];
    if (jobDetails?.is_private_student === 1) {
      editBtns = [
        {
          title: "Edit Learner",
          Icon: PencilSquareIcon,
          show: true,
          onClick: handleShow,
        },
        {
          title: "Edit Availability",
          Icon: PencilSquareIcon,
          show: !jobDetails?.is_completed,
          onClick: () => {
            setShowPrivateStudentAvailability(true);
          },
        },
        {
          title:
            testDetails?.id && testDetails.status !== TestStatusEnum.Failed
              ? "Edit test"
              : "Add test",
          Icon: testDetails.id ? PencilSquareIcon : PlusIcon,
          show: !jobDetails?.is_completed,
          onClick: () => setShowPracticalTestDialog(true),
        },
        {
          title: "Mark as completed",
          Icon: CheckIcon,
          show: !jobDetails?.is_completed && testDetails?.booked_for == null,
          onClick: () => setShowMarkTestAsCompleted(true),
        },
        {
          title: "Move to on hold",
          Icon: PauseCircleIcon,
          show:
            jobDetails?.job?.learner_job_status_id ==
            LearnerJobStatusEnum.Active,
          onClick: () => setShowMoveOnHold(true),
        },
      ];

      if (testDetails?.booked_for && !jobDetails?.is_completed) {
        editBtns.push({
          title: "Delete test",
          Icon: TrashIcon,
          show: true,
          onClick: () => setShowDeleteTestCard(true),
        });
      }
      return editBtns;
    }
    return undefined;
  };

  const openLogTestStatus = (test: Test) => {
    setTestDetails(test);
    setShowLogTestResultModal(true);
  };

  const practicalTestData = () => {
    let testData: {
      icon: "pmf-icon-clock" | "pmf-icon-test" | "pmf-icon-info" | undefined;
      value: string | undefined;
    }[] = [];

    if (
      ((jobDetails?.is_private_student === 0 && (!jobDetails?.job?.test_center_name || !jobDetails?.job?.test_date) &&
      jobDetails?.job?.pt_required_at == null)) || (jobDetails?.is_private_student === 1 && !testDetails?.id)
    ) {
      testData.push({
        icon: "pmf-icon-info",
        value: "Lessons only",
      });
    } else {
      testData.push(
        {
          value: testDetails?.location?.name ?? "-",
          icon: "pmf-icon-test",
        },
        {
          value: testDetails?.booked_for_human ?? "-",
          icon: "pmf-icon-clock",
        }
      );
    }

    return testData;
  };

  return (
    <>
      <div className="bg-white p-1 pb-8">
        <DescriptionList
          title={jobDetails?.hours + " Hours"}
          pmfIcon={jobDetails?.is_private_student === 0}
          jobRef={
            jobDetails?.is_private_student == 1
              ? null
              : "#" + jobDetails?.job_ref
          }
          pills={tags}
          rounded={false}
          loading={loading}
          editButtons={setupEditBtns()}
          data={practicalTestData()}
          stdData={{
            title:
              jobDetails?.awaiting_payment ||
              (!jobDetails?.adi_has_approved_badge &&
                jobDetails?.is_private_student == 0)
                ? jobDetails?.student_first_name
                : `${jobDetails?.student_first_name ?? ""}  ${
                    jobDetails?.student_surname ?? ""
                  }`,
            description:
              jobDetails?.awaiting_payment ||
              (!jobDetails?.adi_has_approved_badge &&
                jobDetails?.is_private_student == 0)
                ? jobDetails?.student_street_address ?? ""
                : `${
                    jobDetails?.student_street_address
                      ? jobDetails?.student_street_address + ", "
                      : ""
                  } ${jobDetails?.student_city_name ?? ""}`,
            location: jobDetails?.student_post_code,
            phone:
              jobDetails?.awaiting_payment ||
              (!jobDetails?.adi_has_approved_badge &&
                jobDetails?.is_private_student == 0)
                ? ""
                : jobDetails?.student_phone,
            email:
              jobDetails?.awaiting_payment ||
              (!jobDetails?.adi_has_approved_badge &&
                jobDetails?.is_private_student == 0)
                ? ""
                : jobDetails?.student_email,
            is_completed_job: jobDetails?.is_completed,
            learner_job_status_id: jobDetails?.job?.learner_job_status_id,
            medical_conditions: jobDetails?.medicalConditions,
            onViewMedical: () => setShowMedicalConditions(true),
            completeReason:
              `${jobDetails?.complete_learner_reasons_name ?? ""}` ?? "",
          }}
        />
        <AddPrivateStudentDialog
          show={show}
          onHide={handleClose}
          type={"edit"}
          jobDetails={jobDetails ?? []}
          pickupAddress={jobDetails?.student_pickup_address}
          refresh={refresh}
        />
        <PrivateStudentTest
          studentId={jobDetails?.student_id}
          show={showPracticalTestDialog}
          onHide={handlePracticalTestClose}
          testDetails={testDetails}
          refreshParent={getCurrentJobDetails}
        />

        <div className="mt-2 px-4 mb-4">
          {jobDetails?.is_private_student === 0 && (
            <GrayFullCard
              topIcon="pmf-icon-currency-pound"
              title="You will earn:"
              value={
                <div className="flex flex-wrap">
                  <span className="text-base text-darkBlue">
                    <strong>
                      £
                      {Number.isInteger(jobDetails?.total_rate)
                        ? jobDetails?.total_rate?.toLocaleString()
                        : jobDetails?.total_rate?.toFixed(2)}
                    </strong>
                  </span>
                  <span className="text-base font-normal ms-1 text-darkBlue">
                    {" "}
                    (Hourly Rate:{" "}
                    <span>
                      £
                      {Number.isInteger(jobDetails?.rate_per_hour)
                        ? jobDetails?.rate_per_hour?.toLocaleString()
                        : jobDetails?.rate_per_hour?.toFixed(2)}
                    </span>
                    )
                  </span>
                </div>
              }
              children={
                <div className="pt-5">
                  <div className="flex justify-between flex-wrap mb-1">
                    {loading ? (
                      <Skeleton width={50} className="me-2" />
                    ) : (
                      <span className="text-sm text-darkBlue">
                        £
                        {Number.isInteger(paymentDetails?.paid_amount)
                          ? paymentDetails?.paid_amount?.toLocaleString()
                          : paymentDetails?.paid_amount?.toFixed(2)}{" "}
                        paid
                      </span>
                    )}
                    {loading ? (
                      <Skeleton width={50} className="me-2" />
                    ) : (
                      <span className="text-sm text-darkBlue">
                        £
                        {Number.isInteger(paymentDetails?.remaining_amount)
                          ? paymentDetails?.remaining_amount?.toLocaleString()
                          : paymentDetails?.remaining_amount?.toFixed(2)}{" "}
                        remaining
                      </span>
                    )}
                  </div>
                  <div className="w-full bg-gray-200 rounded-full h-3">
                    <div
                      className="bg-yellow h-3 rounded-full"
                      style={{ width: progress() + "%" }}
                    ></div>
                  </div>
                </div>
              }
            />
          )}
        </div>

        {/* start:: Availability | Preferred Course Intensity | Next Lesson */}
        {((jobDetails?.student_portal_availability &&
          jobDetails?.student_portal_availability.length > 0) ||
          loading) && (
          <div className={`grid-cols-1 grid gap-4 px-4`}>
            <AvailabilityCard
              availability={jobDetails?.student_portal_availability ?? []}
              availabilityMatch={availMatch}
              loading={loading}
            />
          </div>
        )}
        {/* end:: Availability | Preferred Course Intensity | Next Lesson */}

        <div className={`grid-cols-1 grid gap-4 mt-4 px-4`}>
          {firstUpcommingLesson && (
            <GrayFullCard
              loading={loading}
              topIcon="pmf-icon-calendar"
              title="Next Lesson:"
              value={
                <div className="text-base flex flex-col">
                  <p>{firstUpcommingLesson.date_str}</p>{" "}
                  <p>
                    {firstUpcommingLesson.lesson_date &&
                      `${firstUpcommingLesson.form_time_str} to ${firstUpcommingLesson.to_time_str}`}
                  </p>
                </div>
              }
            />
          )}
          {jobDetails?.course_intensity && (
            <GrayFullCard
              loading={loading}
              topIcon="pmf-icon-intensity"
              title="Preferred Course Intensity:"
              value={jobDetails?.course_intensity}
              desc={getCourseIntensityDesc()}
            />
          )}
          {/* start: Practical Test */}
          {!isLessonsOnly() && (
            <div className="">
              <GrayFullCard
                topIcon="pmf-icon-test"
                loading={loading}
                title={
                  <div className="flex items-center justify-between">
                    <span className="text-sm leading-none">
                      Practical Test:
                    </span>

                    {testDetails?.status === TestStatusEnum.Passed ||
                    testDetails?.status === TestStatusEnum.Failed ? (
                      <div className="flex justify-end grow ">
                        <img
                          src={toAbsoluteUrl(
                            "/assets/media/svg/" +
                              (testDetails?.status === TestStatusEnum.Passed
                                ? "success.svg"
                                : "fail.svg")
                          )}
                          alt={
                            testDetails?.status === TestStatusEnum.Passed
                              ? "Passed"
                              : "Failed"
                          }
                          className=" w-[14px] block"
                        />

                        <p className=" text-sm font-bold px-2">
                          {testDetails?.status === TestStatusEnum.Passed
                            ? "Passed"
                            : "Failed"}
                        </p>
                      </div>
                    ) : (
                      <>
                        <p className=" text-sm font-bold">
                          {testDetails?.status !== "accepted"
                            ? testDetails?.status
                            : ""}
                        </p>
                      </>
                    )}
                  </div>
                }
                children={
                  <div>
                    {testDetails?.jobTests &&
                      testDetails?.jobTests?.length > 0 && (
                        <div className="mt-4">
                          {testDetails?.jobTests?.filter(
                            (c: any) => c.status !== "accepted"
                          ).length > 0 && (
                            <>
                              <p className="text-sm mb-2">Test attempts:</p>
                              {testDetails?.jobTests
                                ?.filter((c: any) => c.status !== "accepted")
                                .toSpliced(
                                  1,
                                  isOpenTestAttempt
                                    ? 0
                                    : testDetails?.jobTests?.length
                                )
                                .map((item: any, index: any) => (
                                  <div key={index} className="mb-3 last:mb-0">
                                    <div className="flex justify-between text-darkBlue mb-2">
                                      <p className="flex justify-between text-sm">
                                        Date:
                                        <h2 className="font-bold ms-1">
                                          {moment(item.booked_for).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </h2>
                                      </p>
                                      <div className="justify-center">
                                        <div className="justify-center justify-items-center">
                                          <p className="flex justify-end text-sm">
                                            Result:{" "}
                                            <h2 className="font-bold ms-1">
                                              {item.status}
                                            </h2>
                                          </p>
                                        </div>

                                        {item.status == TestStatusEnum.Passed ||
                                        item.status == TestStatusEnum.Failed ||
                                        item.status == TestStatusEnum.Unsure ? (
                                          <>
                                            <div className="flex  text-gray-400 mb-2 justify-end">
                                              <span className="flex justify-between text-xs">
                                                {item?.attended_independently
                                                  ? "Attended independently"
                                                  : ""}
                                              </span>
                                            </div>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                ))}

                              {testDetails?.jobTests?.filter(
                                (c: any) => c.status !== "accepted"
                              ).length > 1 && (
                                <div className="flex justify-center mb-2">
                                  <ChevronDownIcon
                                    onClick={() => {
                                      setIsOpenTestAttempt(!isOpenTestAttempt);
                                    }}
                                    className={`${
                                      isOpenTestAttempt
                                        ? "rotate-180 transform"
                                        : ""
                                    } h-4 w-4 text-darkBlue cursor-pointer`}
                                  />
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      )}
                    <div className="flex items-center justify-between space-x-2 pt-3 lg:items">
                      {jobDetails?.awaiting_payment == 0 ? (
                        <Button
                          colour="outline"
                          type="button"
                          fitWidth
                          disabled={
                            hasUpcomingTestDate() ||
                            jobDetails?.awaiting_payment != 0 ||
                            (jobDetails?.is_private_student === 0 && 
                            testDetails?.status !== TestStatusEnum.PendingResult) ||
                            !testDetails?.abilities?.can_fail ||
                            !testDetails?.abilities?.can_pass
                          }
                          onClick={() => openLogTestStatus(testDetails)}
                        >
                          <div className="flex space-x-1 items-center pt-1 pb-0.5">
                            <PlusIcon className="w-4 h-4" />
                            <span className="text-xs">Log test result</span>
                          </div>
                        </Button>
                      ) : (
                        <></>
                      )}
                      {showReschduleTest &&
                      jobDetails?.is_private_student == 0 ? (
                        <Button
                          colour="outline"
                          type="button"
                          fitWidth
                          onClick={() => setShowRescheduleTestDialog(true)}
                        >
                          <div className="flex space-x-1 items-center pt-1 pb-0.5">
                            <i className="pmf-icon-reschedule"></i>
                            <span className="text-xs">Reschedule Test</span>
                          </div>
                        </Button>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                }
              />
              <LogTestStatus
                show={showLogTestResultModal}
                onHide={() => {
                  getCurrentJobDetails();
                  refreshLessonsTab();
                  setShowLogTestResultModal(false);
                }}
                onDismiss={() => {
                  setShowLogTestResultModal(false);
                }}
                openAddTestResult={() => setShowAddTestResultModal(true)}
                jobId={jobId}
                jobRef={
                  testDetails?.is_private_student ? null : testDetails?.deal_id
                }
                testId={testDetails?.id}
                is_private_student={testDetails?.is_private_student}
              />
              <AddTestResult
                show={showAddTestResultModal}
                onHide={() => {
                  setShowAddTestResultModal(false);
                  getCurrentJobDetails();
                  refreshLessonsTab();
                }}
                jobDetails={jobDetails}
                setJobDetails={setJobDetails}
              />
            </div>
          )}
          {jobDetails?.id != undefined && (
            <div>
              <LearnerCheckList
                jobDetails={jobDetails}
                refreshParent={getCurrentJobDetails}
              />
            </div>
          )}
        </div>

        {!jobDetails?.student_availability &&
          (!jobDetails?.student_portal_availability ||
            jobDetails?.student_portal_availability.length < 1) &&
          !loading && (
            <div className="px-4">
              <Alert
                loading={loading}
                colour="white"
                icon="pmf-icon-info"
                customDesc={
                  <span className="text-sm">
                    {" "}
                    Learner has not shared their availability yet{" "}
                  </span>
                }
              />
            </div>
          )}

        {jobDetails?.id != undefined && (
          <div className="mt-4 px-4">
            <JobNotes adiJobId={jobDetails?.id ?? ""} />
          </div>
        )}
        {/* end: Practical Test */}
      </div>
      <RescheduleTestDialog
        show={showRescheduleTestDialog}
        jobData={jobDetails}
        adiJobId={adiJobId}
        onHide={() => {
          setShowRescheduleTestDialog(false);
          getCurrentJobDetails();
        }}
      />
      <MedicalConditionsDialog
        show={showMedicalConditions}
        medicalConditions={jobDetails?.medicalConditions}
        onClose={() => setShowMedicalConditions(false)}
      />
      <AddPrivateStudentAvailabilityDialog
        jobDetails={jobDetails?.job}
        show={showPrivateStudentAvailability}
        onHide={() => {
          setShowPrivateStudentAvailability(false);
          getAvailibility(jobDetails?.student_id ?? "");
          handleClose();
        }}
        refresh={refresh}
        availability={privateStudentAvailability}
        loading={loading}
        setPrivateStudentAvailibility={setPrivateStudentAvailibility}
      ></AddPrivateStudentAvailabilityDialog>
      <DeletePrivateStudentTestModal
        onClose={() => {
          setShowDeleteTestCard(false);
          getCurrentJobDetails();
        }}
        jobId={jobDetails?.job_id!}
        testLocation={testDetails?.location?.name!}
        testDate={testDetails?.booked_for_human!}
        open={showDeleteTestCard}
        testId={testDetails?.id}
      />

      <MarkPrivateLearnerAsCompletedDialog
        jobId={jobId}
        show={showMarkTestAsCompleted}
        onClose={() => {
          setShowMarkTestAsCompleted(false);
          refreshLessonsTab();
          getCurrentJobDetails();
        }}
      ></MarkPrivateLearnerAsCompletedDialog>

      <JobOnHoldDialog
        jobId={jobId}
        show={showMoveOnHold}
        onClose={() => {
          setShowMoveOnHold(false);
          refreshLessonsTab();
          getCurrentJobDetails();
        }}
      ></JobOnHoldDialog>
    </>
  );
};
export { GeneralTab };
