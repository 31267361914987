import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import * as Yup from "yup";
import {
  addJobBid,
  getBidReasons,
} from "../../../../core/services/job-service";
import Button from "../../shared/elements/Button";
import Slider from "../../shared/forms/Slider";
import toast from "react-hot-toast";
import Notification from "../../shared/overlays/Notification";
import {
  ExclamationCircleIcon,
  CheckCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import CheckboxInput from "../../shared/forms/CheckboxInput";

interface IProps {
  jobId: string | undefined;
  onClose: any;
  onUpdate: any;
  jobRate: any;
  requestedRate: any;
  jobBidReasons?: string[];
  adiRateBelowHourlyRate?: boolean;
  jobUpdated: () => void;
}
const RegisterInterestDialog: FC<IProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [defaultInterest, setDefaultInterest] = useState<number>(
    (props.jobRate + 0.5) % 1 != 0
      ? (props.jobRate + 0.5).toFixed(2)
      : props.jobRate + 0.5
  );
  const [minInterest, setMinInterest] = useState<number>(props.jobRate + 0.5);
  const [submitted, setSubmitted] = useState(false);
  const [rateRange, setRateRange] = useState<Array<any>>([props.jobRate + 0.5]);
  const [jobBidReasons, setJobBidReasons] = useState<any>([]);
  const maxRate =  Number(process.env.REACT_APP_MAX_BID_VALUE);
  const step = 0.5;
  useEffect(() => {
    (async () => {
      const response: Array<any> = await getBidReasons();
      let mappedReason = Array.from(response).map((option: any) => {
        return { value: option?.id, label: option.reason };
      });
      if (!props.adiRateBelowHourlyRate) {
        mappedReason = mappedReason.filter((x) => x.value != 13);
      }
      setJobBidReasons(mappedReason);
    })();
  }, []);

  const addInterestSchema = Yup.object().shape({
    requested_rate: Yup.number()
      .required("Requested rate is required")
      .min(minInterest, "Minimum rate is " + minInterest)
      .max(maxRate, "Maximum rate is " + maxRate)
      .nullable(),
    bid_reasons: Yup.array()
      .required("Reason is required")
      .min(1, "you must select al least 1 reason"),
  });

  const formik = useFormik({
    initialValues: {
      requested_rate: props.requestedRate ?? defaultInterest,
      bid_reasons: props.jobBidReasons,
    },
    enableReinitialize: true,
    validationSchema: addInterestSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      handleSubmitForm(values);
    },
  });

  useEffect(() => {
    setDefaultInterest(props.jobRate + 0.5);
    setMinInterest(props.jobRate + 0.5);
    setRateRange(
      props.requestedRate ? [props.requestedRate] : [props.jobRate + 0.5]
    );
  }, [props.jobRate]);

  function handleSubmitForm(values: any) {
    let loadingId = toast.custom(
      () => (
        <Notification
          colour="gray"
          title={"Adding your bid..."}
          description={""}
          Icon={ExclamationCircleIcon}
        />
      ),
      {
        duration: 5000,
        position: "top-center",
      }
    );

    addJobBid(
      { requested_rate: values.requested_rate, reasons: values.bid_reasons },
      props.jobId
    )
      .then((res) => {
        setSubmitted(false);
        setLoading(false);
        props.jobUpdated();
        props.onUpdate();
        toast.custom(
          () => (
            <Notification
              colour="green"
              title={"Added successfully"}
              description={""}
              Icon={CheckCircleIcon}
            />
          ),
          {
            duration: 5000,
            position: "top-center",
            id: loadingId,
          }
        );
      })
      .catch((response) => {
        var error = response.errors?.length
          ? response.errors[0].errorMessage
          : "Sorry, an error has occurred";
        setSubmitted(false);
        formik.setStatus(error);
        setLoading(false);
        formik.setSubmitting(false);
        toast.custom(
          () => (
            <Notification
              colour="red"
              title={"Failed to add bid!"}
              description={""}
              Icon={ExclamationTriangleIcon}
            />
          ),
          {
            duration: 5000,
            position: "top-center",
            id: loadingId,
          }
        );
      });
  }

  function increaseRate() {
    if (Number(formik.values.requested_rate) < maxRate) {
      onRateChanged([Number(formik.values.requested_rate) + step]);
    }
  }

  function decreaseRate() {
    if (Number(formik.values.requested_rate) > minInterest) {
      onRateChanged([Number(formik.values.requested_rate) - step]);
    }
  }

  function closeModal() {
    setLoading(false);
    props.onClose();
  }

  const onRateChanged = (e: any) => {
    setSubmitted(true);
    let val = [e[0] != "" ? Number(e[0]) : ""];
    if (e[0] >= minInterest && e[0] <= maxRate) {
      setRateRange(val);
    }

    formik.setFieldValue(
      "requested_rate",
      typeof val[0] === "number" && val[0].toString().includes(".")
        ? Number(val[0]).toFixed(2)
        : val[0]
    );
  };

  const setSubmit = () => {
    formik.submitForm();
    if (submitted === false) {
      setSubmitted(true);
    }
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <h5 className="text-sm">
          Please let us know the hourly rate you'd require for this course.
          We’ll be in touch if we're able to accommodate this rate.
        </h5>
        <Slider
          decreaseFunction={decreaseRate}
          increaseFunction={increaseRate}
          step={step}
          minValue={minInterest}
          maxValue={maxRate}
          rangeValues={rateRange}
          onChange={onRateChanged}
          showInput={true}
          formik={formik}
          formatFun={(index: number) =>
            rateRange[index] && rateRange[index] % 1 != 0
              ? rateRange[index]?.toFixed(2)
              : rateRange[index]
          }
          minValueFormatted={`£${
            minInterest % 1 != 0 ? minInterest.toFixed(2) : minInterest
          }`}
          maxValueFormatted={`£${
            maxRate % 1 != 0 ? maxRate.toFixed(2) : maxRate
          }`}
        />

        <h5 className="text-sm mb-4">
          Please select the reason(s) you are requesting a higher hourly rate
          for this course
        </h5>

        <CheckboxInput
          {...formik.getFieldProps("bid_reasons")}
          label=""
          name="bid_reasons"
          onChange={(newValue: any) => {
            console.log(newValue);
            formik.setFieldValue("bid_reasons", newValue);
          }}
          required={false}
          options={jobBidReasons}
          values={formik.values?.bid_reasons ?? []}
          error={
            formik.touched.bid_reasons && formik.errors.bid_reasons
              ? true
              : false
          }
          errorMsg={formik.errors.bid_reasons}
        />
        <div className="sticky w-full bottom-0 left-0 px-0 py-4 bg-white z-[11]">
          <div className="w-full flex items-center justify-between space-x-2">
            <Button
              colour="yellow"
              type="submit"
              className="w-1/2"
              size="large"
              onClick={setSubmit}
              disabled={formik.isSubmitting || loading || formik.status}
            >
              Confirm
            </Button>
            <Button
              colour="outline"
              type="button"
              className="w-1/2"
              size="large"
              onClick={closeModal}
              disabled={formik.isSubmitting || loading || formik.status}
            >
              Cancel
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export { RegisterInterestDialog };
