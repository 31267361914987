import { FC } from "react";
import PickupLocationMap from "../../shared/PickupLocationMap";
import Modal from "../../shared/overlays/Modal";
interface IProps {
  show: boolean;
  close: any;
  pickupLocation: any;
}

const PickupLocationPreview: FC<IProps> = (props) => {
  const zoom = 11;
  const radius = 5000;

  return (
    <>
      <Modal
        open={props.show}
        onClose={props.close}
        hideSubmit={true}
        closeText="Back"
        title="Agreed Pickup Location"
      >
        <div>
          <div className="my-3">
            <PickupLocationMap
              zoom={zoom}
              radius={radius}
              center={props.pickupLocation}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export { PickupLocationPreview };
