/* eslint-disable jsx-a11y/anchor-is-valid */
import { Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { FC, useEffect, useState } from "react";
import { MoneyInModal } from "../components/payments/_modals/MoneyInModal";
import { MoneyOutModal } from "../components/payments/_modals/MoneyOutModal";
import { AddEditLessonModel } from "../components/lessons/_modals/AddEditLessonModel";
import { AddPrivateStudentDialog } from "../components/students/AddPrivateStudentDialog";
import { AddAssessmentDialog } from "../components/jobs/job-details/tabs/_modals/AddAssessmentDialog";
import { ClickAwayListener } from "@mui/material";
import { NewPaymentModal } from "../components/account-settings/digital-payments/_modals/NewPaymentModal";
import { getConnectAccount } from "../../core/services/connect-service";
import { useHistory } from "react-router-dom";
import { PrivateStudentTest } from "../components/students/privateStudentTest";
import { OtherEventModal } from "../components/lessons/_modals/OtherEventModal";

interface IProduct {
  title: string;
  icon: string;
  linkURL: string;
  onClickFunction: any;
}

const QuickActions: FC<any> = ({customMenu, showEventsSubMenu, setShowEventsSubMenu, refreshParent}) => {

  const [showAddMoneyInModal, setShowAddMoneyInModal] = useState(false)
  const [showAddMoneyOutModal, setShowAddMoneyOutModal] = useState(false)
  const [showAddLessonCard, setShowAddLessonCard] = useState<boolean>(false)
  const [showAssessmentModal, setShowAssessmentModal] = useState<boolean>(false)
  const [showAddStudentModal, setShowAddStudentModal] = useState(false)
  const [ showDigitalPayment, setShowDigitalPayment ] = useState<boolean>(false)
  const [ showPrivateStdTestModal, setShowPrivateStdTestModal ] = useState<boolean>(false)
  const [ showOtherEventModal, setShowOtherEventModal ] = useState<boolean>(false)
  const [account, setAccount] = useState<any>(null);
  const history = useHistory();

  const getAccount = () => {
    getConnectAccount()
      .then((res) => {
        setAccount(res.data);
      })
  };

  useEffect(() => {
    getAccount();
  }, []);


  const closeModal = () => {
    if (showEventsSubMenu) setShowEventsSubMenu(false);
    else setShow(false);
  };

  const openOtherEventModal = () => {
    closeModal()
    setShowOtherEventModal(true)
  }

  const closeOtherEventModal = () => {
    setShowOtherEventModal(false)
  }
  const openAddPrivateStdTestModal = () => {
    closeModal()
    setShowPrivateStdTestModal(true)
  }

  const closeAddPrivateStdTestModal = () => {
    setShowPrivateStdTestModal(false)
  }

  const openAddMoneyInModal = () => {
    closeModal()
    setShowAddMoneyInModal(true)
  }

  const closeAddMoneyInModal = () => {
    setShowAddMoneyInModal(false)
  }

  const openAddMoneyOutModal = () => {
    closeModal()
    setShowAddMoneyOutModal(true)
  }

  const closeAddMoneyOutModal = () => {
    setShowAddMoneyOutModal(false)
  }
  const showAddLessonCardModal = () => {
    closeModal()
    setShowAddLessonCard(true)
  }

  const openAddStudentModal = () => {
    closeModal()
    setShowAddStudentModal(true)
  }

  const closeAddStudentModal = () => {
    setShowAddStudentModal(false)
  }
  
  const openAssessmentModal = () => {
    closeModal()
    setShowAssessmentModal(true)
  }

  const closeAssessmentModal = () => {
    setShowAssessmentModal(false)
  }

  const openDigitalPaymentModal = () => {
    closeModal()
    if (account != null) {
      setShowDigitalPayment(true)
    } 
    else {
      history.push("/ADI/digital-payments");
    }
  }
  
  const closeDigitalPaymentModal = () => {
    setShowDigitalPayment(false)
  }

  const initialMenu: IProduct[] = [
    {
      title: "Money in",
      icon: "pmf-icon-credit-card",
      linkURL: "",
      onClickFunction: openAddMoneyInModal
    },
    {
      title: "Money out",
      icon: "pmf-icon-expense",
      linkURL: "300",
      onClickFunction: openAddMoneyOutModal
    },
    {
      title: "Lesson",
      icon: "pmf-icon-wheel",
      linkURL: "",
      onClickFunction: showAddLessonCardModal
    },
    {
      title: "Learner",
      icon: "pmf-icon-user",
      linkURL: "300",
      onClickFunction: openAddStudentModal
    },
    {
      title: "Progress",
      icon: "pmf-icon-statistics",
      linkURL: "",
      onClickFunction: openAssessmentModal
    },
    {
      title: "Digital Payment",
      icon: "pmf-icon-currency-pound",
      linkURL: "",
      onClickFunction: openDigitalPaymentModal
    },
  ];

  const customEventsMenu: IProduct[] = [
    {
      title: "Lesson",
      icon: "pmf-icon-wheel",
      linkURL: "",
      onClickFunction: showAddLessonCardModal
    },
    {
      title: "Practical Test",
      icon: "pmf-icon-test",
      linkURL: "300",
      onClickFunction: openAddPrivateStdTestModal
    },
    {
      title: "Other Event",
      icon: "pmf-icon-calendar",
      linkURL: "",
      onClickFunction: openOtherEventModal
    }
  ];

  const initialProducts: IProduct[] = customMenu ? customEventsMenu : initialMenu;

  const [show, setShow] = useState(false);
  // const FadeIn = ({ delay, children }) => (
  //   <Transition.Child
  //     enter={`transition-all ease-in-out duration-700 ${delay}`}
  //     enterFrom="opacity-0 translate-y-6"
  //     enterTo="opacity-100 translate-y-0"
  //     leave="transition-all ease-in-out duration-300"
  //     leaveFrom="opacity-100"
  //     leaveTo="opacity-0"
  //   >
  //     {children}
  //   </Transition.Child>
  // )

  return (
    <>

      {!customMenu && <button
        className="fixed bottom-[100px] md:bottom-16 md:left-[] md:mx-0 md:start-20 mx-auto inset-x-0 p-0 w-16 h-16 bg-yellow rounded-full z-[100]
        hover:brightness-75 active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none"
        onClick={() => setShow(!show)}
      >
        <svg viewBox="0 0 20 20" className="w-6 h-6 inline-block">
          <path
            fill="#163A5E"
            d="M16,10c0,0.553-0.048,1-0.601,1H11v4.399C11,15.951,10.553,16,10,16c-0.553,0-1-0.049-1-0.601V11H4.601
                                    C4.049,11,4,10.553,4,10c0-0.553,0.049-1,0.601-1H9V4.601C9,4.048,9.447,4,10,4c0.553,0,1,0.048,1,0.601V9h4.399
                                    C15.952,9,16,9.447,16,10z"
          />
        </svg>
      </button>}
      <ClickAwayListener onClickAway={() => closeModal()}>
      <Transition.Root
        className="fixed md:mx-0 inset-x-0 bottom-0 w-full md:shadow-inner bg-darkBlue z-[100]"
        show={customMenu ? showEventsSubMenu : show}
        enter="transition-all ease-in-out duration-500 delay-[200ms]"
        enterFrom="opacity-0 translate-y-6"
        enterTo="opacity-100 -translate-y-0"
        leave="stransition-all ease-in-out duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="flex justify-between text-white px-6 pt-4 mb-5 md:w-1/2 mx-auto">
          <h2 className="font-titlef text-2xl font-bold">Add new</h2>

          <div onClick={() => closeModal()}>
            <XMarkIcon className="h-6 w-6"></XMarkIcon>
          </div>
        </div>
        {/* [&>*:nth-child(4)]:col-start-2  */}
        <div className="md:w-1/2 grid grid-cols-[repeat(auto-fit,_16.666666%)] justify-center mx-auto">
          {initialProducts.map((button, i) => (
            <div
              key={i}
              className="flex flex-col col-span-2 items-center justify-center mb-5 "
              onClick={() => button.onClickFunction()}
            >
              <div className="bg-white flex items-center justify-center rounded-full w-12 h-12 cursor-pointer">
                <i className={`${button.icon} text-2xl`}></i>
              </div>
              <h4 className="text-sm text-white mt-2 mb-0">
                {" "}
                {button.title}
              </h4>
            </div>
          ))}
        </div>
      
      </Transition.Root>
      </ClickAwayListener>
      <MoneyInModal
        show={showAddMoneyInModal}
        onClose={closeAddMoneyInModal}
        onSubmit={closeAddMoneyInModal}
        refreshParent={refreshParent}
      ></MoneyInModal>

      <MoneyOutModal
        show={showAddMoneyOutModal}
        onClose={closeAddMoneyOutModal}
        onSubmit={closeAddMoneyOutModal}
        refreshParent={refreshParent}
      ></MoneyOutModal>

      <AddEditLessonModel 
        onClose={() => { setShowAddLessonCard(false) }}
        duration={1}
        firstDate={null}
        jobId={undefined}
        id={undefined}
        lessonsUpdated={() => {}}
        show={showAddLessonCard}
        isPrivateJob={null}
        refreshParent={refreshParent}
      />
        
      <AddPrivateStudentDialog 
        show={showAddStudentModal} 
        onHide={closeAddStudentModal} 
        type={"add"} jobDetails={null}
        refreshParent={refreshParent} 
        refresh={closeAddStudentModal}
      />

      <AddAssessmentDialog
        show={showAssessmentModal}
        onHide={closeAssessmentModal} 
        refreshParent={refreshParent}
      />

      {/* start:: Digital payment modal */}
      <NewPaymentModal 
      show={showDigitalPayment} 
      onClose={closeDigitalPaymentModal} 
      />
      {/* end:: Digital payment modal */}

      <PrivateStudentTest
          isGeneralTest
          show={showPrivateStdTestModal}
          onHide={closeAddPrivateStdTestModal}
          refreshParent={refreshParent}
        />

      <OtherEventModal
          show={showOtherEventModal}
          onHide={closeOtherEventModal}
          refreshParent={refreshParent}
          isEditMode={false}
        />

    </>
  );
};

export { QuickActions };
