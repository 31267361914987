/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import Button from "../elements/Button";
import InfoList from "../data/InfoList";


export type LessonCardProps = {
  title?: string;
  loading?: boolean;
  subTitle?: string;
  data?: any;
  buttons?: {
    text: React.ReactNode;
    onClick: () => void;
    colour?: any;
  }[];
  children?: React.ReactNode;
};


export default function LessonCard({
  title,
  subTitle,
  loading = false,
  data,
  buttons,
}: LessonCardProps) {
  return (
    <>
      <div className="bg-white rounded-lg">
        <div className="flex justify-between bg-darkBlue rounded-se-lg rounded-ss-lg px-4 sm:px-6 py-2 ">
          <h2 className="text-base font-semibold text-white">{title} Hours</h2>
        </div>

        <div className=" px-4 sm:px-6 mt-3">
          <InfoList
            data={[
              {
                icon: "pmf-icon-user",
                value: ( data.student_first_name ?? "") + ' ' + (data?.student_surname ?? '') ,
                linkURL : "/ADI/students",
                pmfIcon: !data.is_private_student
              },
              {
                icon: "pmf-icon-map-pin",
                value: data.student_post_code ?? "-",
              },
              {
                icon: "pmf-icon-suitcase",
                badge:data.job_ref,
                value :`${data.job_ref ? "#"+data.job_ref  :  "-"}`
              },
            ]}
          />
        </div>
        {buttons && (
        <div className="px-4 sm:px-6 pb-4 mt-5 flex space-x-4 d-flex align-items-center justify-content-center">
          {buttons.map((button, i) => (
            <Button colour={button.colour ??""}
              onClick={() => button.onClick()}
              key={i}
              fullWidth
              size="large"
            >
              {button.text}
            </Button>
          ))}
        </div>
      )}
        
      </div>
    </>
  );
};

export { LessonCard };
