import { FC, useEffect, useRef, useState } from "react";
import { Car } from "../../../../core/models/car";
import { DocumentDataModel } from "../../../../core/models/document-data-model";
import {
  addBadge,
  addDocument,
  addInsurance,
  getAdiContactId,
  getCars,
  getFuelTypes,
  getTransmissionTypes,
  updateAdiCars,
  uploadAdiDocument,
} from "../../../../core/services/adi-service";
import { getMonths, getYears } from "../../../../core/services/date-service";
import * as Yup from "yup";
import { useFormik } from "formik";
import { formatDate } from "../../../helpers/DateHelper";
import { toAbsoluteUrl } from "../../../helpers";
import ReactGA from "react-ga4";
import { CarModal } from "../cars/CarModal";
import Modal from "../../shared/overlays/Modal";
import RadioInputGroup from "../../shared/forms/RadioInputGroup";
import Input from "../../shared/forms/Input";
import Dropdown from "../../shared/forms/Dropdown";
import Alert from "../../shared/overlays/Alert";
import { AdiPdiSettingsTemplate } from "../adiPdiSettings/AdiPdiSettingsTemplate";
import toast from "react-hot-toast";
import Notification from "../../shared/overlays/Notification";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import Button from "../../shared/elements/Button";

interface IProps {
  show: any;
  onHide: any;
  onCreatedNewDocument: any;
  isUploadBadge: boolean;
  isUploadInsurance?: boolean;
  parentWrapper?: string;
  jobId?: string;
}

const AddDocumentDialog: FC<IProps> = (props) => {
  const requiredMsg = "This field is required";
  const badgeExpiryDateYears = getYears(4, 0, true);
  const [modalCurrentCar, setModalCurrentCar] = useState(new Car());
  const badgeExpiryDateMonths = getMonths();
  const [initialValue, setInitialValue] = useState<DocumentDataModel>(
    new DocumentDataModel()
  );
  const insuranceExpiryDateYears = getYears(20, 0, true);
  const insuranceExpiryDateMonths = getMonths();
  const [show, setShow] = useState(false);
  const [loadingCars, setLoadingCars] = useState(false);

  const [fuelTypes, setFuelTypes] = useState<any>([]);
  const [selectedOption, setSelectedOption] = useState<String>(
    props.isUploadBadge ? "badge" : props.isUploadInsurance ? "insurance" : ""
  );
  const [loadingFile, setLoadingFile] = useState<boolean>(false);
  const [contactId, setContactId] = useState<any>(null);
  const [isADI, setIsADI] = useState<boolean>(false);
  const [showAddCarModal, setShowAddCarModal] = useState<boolean>(false)

  const myRef = useRef(null);
  const executeScroll = (ref: any) => {
    if (!ref.current) return;
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const [transmissionTypes, setTransmissionTypes] = useState<Array<any>>([]);
  const documentTypes = ["badge", "insurance", "other"].map((type) => {
    return {
      label: type.charAt(0).toUpperCase() + type.slice(1),
      value: type,
      img: toAbsoluteUrl(
        "/assets/media/svg/documentTypes/" + type.concat(".svg")
      ),
      nonselectImg: toAbsoluteUrl(
        "/assets/media/svg/documentTypes/" + type.concat("-grey.svg")
      ),
    };
  });

  const [cars, setCars] = useState<Array<any>>([]);
  const [adiCars, setAdiCars] = useState<Array<any>>([]);
  const [showAdiQuestion, setShowAdiQuestion] = useState(false);
  const handleClose = () => {
    setModalCurrentCar(new Car());
    setShow(false);
  };

  const setContactName = (name: any) => {
    formik.setFieldValue("badge_name", name);
  };

  const handleSubmittingCar = (car: Car) => {
    const filteredCars = [];
    filteredCars.push(car);
    return saveCars(filteredCars);
  };

  const loadingToaster = (message: string) => {
    let loadingId = toast.custom(
      () => (
        <Notification
          colour="gray"
          title={message}
          description={""}
          Icon={ExclamationCircleIcon}
        />
      ),
      {
        duration: 5000,
        position: "top-center",
      }
    );
    return loadingId;
  };

  const successToaster = (loadingId: string) => {
    toast.custom(
      () => (
        <Notification
          colour="green"
          title={"Saved successfully"}
          description={""}
          Icon={CheckCircleIcon}
        />
      ),
      {
        duration: 5000,
        position: "top-center",
        id: loadingId,
      }
    );
  };

  const errorToaster = (message: string, loadingId: string) => {
    toast.custom(
      () => (
        <Notification
          colour="red"
          title={message}
          description={""}
          Icon={ExclamationTriangleIcon}
        />
      ),
      {
        duration: 5000,
        position: "top-center",
        id: loadingId,
      }
    );
  };

  const saveCars = (adiCars: Array<Car>) => {
    setLoadingCars(true);
    let loadingId = loadingToaster("Adding car..");
    return updateAdiCars({ cars: adiCars })
      .then(() => {
        getAdiCars();
        successToaster(loadingId);
      })
      .catch((res) => {
        setLoadingCars(false);
        errorToaster("Failed to add car!", loadingId);
      });
  };

  const documentValidationSchema = Yup.object().shape({
    document_type: Yup.string().required(requiredMsg),
    insurance_provider:
      selectedOption === "insurance"
        ? Yup.string().required(requiredMsg)
        : Yup.string().nullable(),
    badge_expiry_date_day:
      selectedOption === "badge"
        ? Yup.string().nullable().required("Day is required")
        : Yup.string().nullable(),
    badge_expiry_date_month:
      selectedOption === "badge"
        ? Yup.string().nullable().required("Month is required")
        : Yup.string().nullable(),
    badge_expiry_date_year:
      selectedOption === "badge"
        ? Yup.string().nullable().required("Year is required")
        : Yup.string().nullable(),

    document_url:
      selectedOption === "badge"
        ? Yup.string().nullable().required("Badge file is required")
        : Yup.string().nullable(),

    insurance_document_url:
      selectedOption === "insurance"
        ? Yup.string().nullable()
        : Yup.string().nullable(),

    other_document_url:
      selectedOption === "other"
        ? Yup.string().nullable().required("File is required")
        : Yup.string().nullable(),

    insurance_expiry_date_month:
      selectedOption === "insurance"
        ? Yup.string().nullable().required("Month is required")
        : Yup.string().nullable(),
    insurance_expiry_date_year:
      selectedOption === "insurance"
        ? Yup.string().nullable().required("Year is required")
        : Yup.string().nullable(),
    car_id:
      selectedOption === "insurance"
        ? Yup.string().nullable().required(requiredMsg)
        : Yup.string().nullable(),
    badge_name:
      selectedOption === "badge"
        ? Yup.string().nullable().required(requiredMsg)
        : Yup.string().nullable(),
  });

  const formik = useFormik({
    initialValues: initialValue,
    enableReinitialize: true,
    validationSchema: documentValidationSchema,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      if (selectedOption === "badge") {
        createBadge(values);
      } else if (selectedOption === "insurance") {
        createInsurance(values);
      } else {
        createOtherDocument(values);
      }
    },
  });

  const createOtherDocument = (values: DocumentDataModel) => {
    formik.setStatus(null);
    let loadingId = loadingToaster("Adding document..");
    addDocument(values)
      .then((data) => {
        formik.setSubmitting(false);
        successToaster(loadingId);
        props.onCreatedNewDocument();
        hide();
      })
      .catch((res) => {
        errorToaster("Failed to add document!", loadingId);
        formik.setSubmitting(false);
        if (res?.errors?.length) {
          formik.setStatus(res.errors[0].errorMessage);
          executeScroll(myRef);
        } else {
          formik.setStatus("Sorry, an error has occured");
          executeScroll(myRef);
        }
      });
  };

  const createInsurance = (values: DocumentDataModel) => {
    formik.setStatus(null);
    values.expiry_date = formatDate(
      values.insurance_expiry_date_year,
      values.insurance_expiry_date_month,
      "15"
    );
    values.zendesk_contact_id = contactId;
    values.transmission_type_id = adiCars.find(
      (a) => a.id == values.car_id
    ).transmission_type_id;
    let loadingId = loadingToaster("Adding insurance..");
    addInsurance(values)
      .then((data) => {
        successToaster(loadingId);
        ReactGA.event("add_insurance");
        formik.setSubmitting(false);
        props.onCreatedNewDocument();
        hide();
      })
      .catch((res) => {
        errorToaster("Failed to add insurance!", loadingId);
        formik.setSubmitting(false);
        if (res?.errors?.length) {
          formik.setStatus(res.errors[0].errorMessage);
          executeScroll(myRef);
        } else {
          formik.setStatus("Sorry, an error has occured");
          executeScroll(myRef);
        }
      });
  };

  const createBadge = (values: DocumentDataModel) => {
    formik.setStatus(null);
    values.expiry_date = formatDate(
      values.badge_expiry_date_year,
      values.badge_expiry_date_month,
      values.badge_expiry_date_day
    );

    values.zendesk_contact_id = contactId;
    values.job_id=props.jobId;
    let loadingId = loadingToaster("Adding badge..");
    addBadge(values)
      .then((data) => {
        successToaster(loadingId);
        ReactGA.event("add_badge");
        formik.setSubmitting(false);
        if (isADI == false && props.isUploadBadge == false) {
          setShowAdiQuestion(true);
        } else {
          hide();
        }
        props.onCreatedNewDocument();
      })
      .catch((res) => {
        errorToaster("Failed to add badge!", loadingId);
        formik.setSubmitting(false);
        if (res?.errors?.length) {
          formik.setStatus(res.errors[0].errorMessage);
          executeScroll(myRef);
        } else {
          formik.setStatus("Sorry, an error has occured");
          executeScroll(myRef);
        }
      });
  };

  const uploadDocument = (event: any, type: string) => {
    setLoadingFile(true);
    formik.setStatus(null);
    const file = event.target.files[0];
    let loadingId = loadingToaster("uploading document..");
    uploadAdiDocument(file)
      .then((data) => {
        successToaster(loadingId);
        formik.setFieldValue("document_url", data.data.results);
        if (type == "other") {
          formik.setFieldValue("other_document_url", data.data.results);
        }
        setLoadingFile(false);
      })
      .catch((res) => {
        errorToaster("Failed to upload document!", loadingId);
        setLoadingFile(false);
        formik.setSubmitting(false);
        if (res?.errors?.length) {
          formik.setStatus(res.errors[0].errorMessage);
          executeScroll(myRef);
        } else {
          formik.setStatus("Sorry,an error occurred while uploading file");
          executeScroll(myRef);
        }
      });
  };

  const onShow = () => {
    if (contactId == null) {
      getAdiContactData();
    }
    setInitialValue(new DocumentDataModel());
  };

  useEffect(() => {
    if (formik.values.document_type != selectedOption) {
      formik.setFieldValue("document_type", selectedOption);
    }
  }, [selectedOption]);

  useEffect(() => {
    if(props.show) {
    getAdiContactData();
    if (!props.isUploadBadge) {      
      getAdiCars();
      getFuelTypes().then((response) => {
        setFuelTypes(response?.data?.results);
      });
      getTransmissionTypes().then((response) => {
        setTransmissionTypes(response?.data?.results);
      });
    }
  }
  }, [props.show]);

  function getAdiCars() {      
    getCars().then((response) => {
      const adiCars = response?.data?.results;
      if (adiCars) {
        const cars = Array.from(adiCars).map((car) => {
          return { value: car.id, label: car.make + "-" + car.model };
        });

        if(cars.length > 0)
          formik.setFieldValue("car_id", cars[0]?.value);
        
        setCars(cars);
        setAdiCars(response?.data?.results);
        setLoadingCars(false);
      }
    });
  }

  function getAdiContactData() {
    getAdiContactId().then((response) => {
      setContactId(response?.data?.results.zendesk_contact_id);
      setContactName(response?.data?.results.full_name);
      setIsADI(response?.data?.results.is_adi_trainer);
    });
  }

  const hide = () => {
    formik.resetForm();
    formik.setFieldValue("document_type", selectedOption);
    if (!props.isUploadBadge) {
      setSelectedOption("");
    }
    getAdiContactData();
    setShowAdiQuestion(false);
    props.onHide();
  };

  return (
    <>
      <Modal
        open={props.show}
        onClose={props.onHide}
        onSubmit={formik.submitForm}
        submitText="Save"
        closeText={props.parentWrapper === "questionnaire" ? "Cancel" : "Close"}
        hideSubmit={showAdiQuestion}
        hideClose={showAdiQuestion}
        reverseButtons={props.parentWrapper === "questionnaire"}
        title={
          !showAdiQuestion
            ? "Add " + (props.isUploadBadge ? "Badge" : "Document")
            : "ADI Status"
        }
        disabled={formik.isSubmitting}
        disableSubmit={cars.length === 0 && selectedOption === "insurance"}
      >
        {!showAdiQuestion && (
          <form
            noValidate
            onSubmit={formik.handleSubmit}
            className="d-flex flex-column justify-content-between flex-grow-1"
            id="add_document_form"
          >
            {formik.status && (
              <Alert colour="red" icon="pmf-icon-info" title={formik.status} />
            )}

            {(!props.isUploadBadge && !props.isUploadInsurance) && (
              <>
                {show && (
                  <CarModal
                    show={show}
                    onClose={handleClose}
                    car={modalCurrentCar}
                    fuelTypes={fuelTypes}
                    transmissionTypes={transmissionTypes}
                    handleSubmittingCar={handleSubmittingCar}
                  />
                )}
                <div className="pb-3">
                  <RadioInputGroup
                    {...formik.getFieldProps("document_type")}
                    name="document_type_id"
                    label="Document type"
                    options={documentTypes}
                    onChange={(e) => {
                      setSelectedOption(e ?? "");
                      formik.setFieldValue("document_type", e);
                    }}
                    value={formik.values.document_type}
                    disabled={formik.isSubmitting}
                    error={
                      formik.touched.document_type &&
                      formik.errors.document_type
                        ? true
                        : false
                    }
                    errorMsg={formik.errors.document_type}
                  />
                </div>
              </>
            )}

            {/* start:: Badge Form */}
            {selectedOption && selectedOption === "badge" && (
              <>
                {/* start:: Badge Form */}
                <Input
                  {...formik.getFieldProps("badge_name")}
                  type="text"
                  label="Name"
                  placeholder="Name"
                  error={
                    formik.touched.badge_name && formik.errors.badge_name
                      ? true
                      : false
                  }
                  errorMsg={formik.errors.badge_name}
                  required={true}
                  name="badge_name"
                  disabled={formik.isSubmitting}
                  id="badge_name"
                  onChange={(e) => {
                    setContactName(e?.target?.value);
                  }}
                />

                {/* start:: Expiry Date Group */}
                <h4 className="text-base font-bold ">Expiry Date</h4>
                <div className="grid grid-cols-2 gap-2">
                  <Dropdown
                    options={badgeExpiryDateMonths}
                    label="Month"
                    placeholder="Month"
                    error={
                      formik.touched.badge_expiry_date_month &&
                      formik.errors.badge_expiry_date_month
                        ? true
                        : false
                    }
                    errorMsg={formik.errors.badge_expiry_date_month}
                    required={true}
                    name="badge_expiry_date_month"
                    disabled={formik.isSubmitting}
                    id="badge_expiry_date_month"
                    value={{
                      value: formik.values.badge_expiry_date_month,
                      label:
                        formik.values.badge_expiry_date_month === ""
                          ? "Month"
                          : formik.values.badge_expiry_date_month,
                    }}
                    onChange={(e) => {
                      formik.setFieldValue("badge_expiry_date_month", e?.value);
                    }}
                  />
                  <Dropdown
                    options={badgeExpiryDateYears}
                    label="Year"
                    placeholder="Year"
                    error={
                      formik.touched.badge_expiry_date_year &&
                      formik.errors.badge_expiry_date_year
                        ? true
                        : false
                    }
                    errorMsg={formik.errors.badge_expiry_date_year}
                    required={true}
                    name="badge_expiry_date_year"
                    disabled={formik.isSubmitting}
                    id="badge_expiry_date_year"
                    value={{
                      value: formik.values.badge_expiry_date_year,
                      label:
                        formik.values.badge_expiry_date_year === ""
                          ? "Year"
                          : formik.values.badge_expiry_date_year,
                    }}
                    onChange={(e) => {
                      formik.setFieldValue("badge_expiry_date_year", e?.value);
                    }}
                  />
                </div>
                {/* end:: Expiry Date Group */}
                <h4 className="text-base font-bold">
                  {" "}
                  Upload a photo of the front of your ADI Badge to browse
                  available jobs{" "}
                </h4>
                <Input
                  type="file"
                  label=""
                  error={
                    formik.touched.document_url && formik.errors.document_url
                      ? true
                      : false
                  }
                  errorMsg={formik.errors.document_url}
                  required={true}
                  name="document_url"
                  disabled={formik.isSubmitting}
                  id="document_url"
                  onChange={(e) => {
                    uploadDocument(e, "badge");
                  }}
                />
                {loadingFile && <p>Uploading file...</p>}
              </>
            )}
            {/* end:: Badge Form */}

            {/* start:: Insurance Form */}
            {selectedOption && selectedOption === "insurance" && (
              <>
                <Input
                  {...formik.getFieldProps("insurance_provider")}
                  type="text"
                  label="Insurance Provider"
                  placeholder="Insurance Provider"
                  error={
                    formik.touched.insurance_provider &&
                    formik.errors.insurance_provider
                      ? true
                      : false
                  }
                  errorMsg={formik.errors.insurance_provider}
                  required={true}
                  name="insurance_provider"
                  disabled={formik.isSubmitting}
                  id="insurance_provider"
                />

                <div className="d-flex flex-column col p-0">
                  {!loadingCars && (
                    <>
                    <Dropdown
                      options={cars}
                      label="Car"
                      placeholder="Select car"
                      error={
                        formik.touched.car_id && formik.errors.car_id
                          ? true
                          : false
                      }
                      errorMsg={formik.errors.car_id}
                      required={true}
                      name="car_select"
                      disabled={formik.isSubmitting || cars.length === 0}
                      id="car_select"
                      value={{
                        value: formik.values.car_id,
                        label:
                          formik.values.car_id === ""
                            ? "Select car"
                            : cars.find((c) => c.value === formik.values.car_id)
                                .label,
                      }}
                      onChange={(e) => {
                        formik.setFieldValue("car_id", e?.value);
                      }}
                    />
                   {cars.length === 0 && (
                    <>
                      <div className="-mt-5 mb-4">
                        <Button
                          colour="link"
                          type="button"
                          size="fit"
                          fitWidth
                          onClick={() => {
                            setShowAddCarModal(true);
                            getAdiCars();
                          }}
                        >
                          <span className="text-sm">
                            Add new car
                          </span>
                        </Button>
                      </div>
                      <CarModal
                        show={showAddCarModal}
                        onClose={() => setShowAddCarModal(false)}
                        car={modalCurrentCar}
                        fuelTypes={fuelTypes}
                        transmissionTypes={transmissionTypes}
                        handleSubmittingCar={handleSubmittingCar}
                      ></CarModal>
                    </>
                  )}
                    </>
                  )}
                  <h4 className="text-base font-bold ">Expiry Date</h4>
                  <div className="grid grid-cols-2 gap-2">
                    <Dropdown
                      options={insuranceExpiryDateMonths}
                      label="Month"
                      placeholder="Month"
                      error={
                        formik.touched.insurance_expiry_date_month &&
                        formik.errors.insurance_expiry_date_month
                          ? true
                          : false
                      }
                      errorMsg={formik.errors.insurance_expiry_date_month}
                      required={true}
                      name="insurance_expiry_date_month"
                      disabled={formik.isSubmitting}
                      id="insurance_expiry_date_month"
                      value={{
                        value: formik.values.insurance_expiry_date_month,
                        label:
                          formik.values.insurance_expiry_date_month === ""
                            ? "Month"
                            : formik.values.insurance_expiry_date_month,
                      }}
                      onChange={(e) => {
                        formik.setFieldValue(
                          "insurance_expiry_date_month",
                          e?.value
                        );
                      }}
                    />
                    <Dropdown
                      options={insuranceExpiryDateYears}
                      label="Year"
                      placeholder="Year"
                      error={
                        formik.touched.insurance_expiry_date_year &&
                        formik.errors.insurance_expiry_date_year
                          ? true
                          : false
                      }
                      errorMsg={formik.errors.insurance_expiry_date_year}
                      required={true}
                      name="insurance_expiry_date_year"
                      disabled={formik.isSubmitting}
                      id="insurance_expiry_date_year"
                      value={{
                        value: formik.values.insurance_expiry_date_year,
                        label:
                          formik.values.insurance_expiry_date_year === ""
                            ? "Year"
                            : formik.values.insurance_expiry_date_year,
                      }}
                      onChange={(e) => {
                        formik.setFieldValue(
                          "insurance_expiry_date_year",
                          e?.value
                        );
                      }}
                    />
                  </div>
                </div>
              </>
            )}
            {/* end:: Insurance Form */}

            {/* start:: Other Form */}
            {selectedOption && selectedOption === "other" && (
              <>
                <h4 className="text-base font-bold mt-4 -mb-4">
                  Photo (upload photo){" "}
                </h4>
                <Input
                  type="file"
                  label=""
                  error={
                    formik.touched.other_document_url &&
                    formik.errors.other_document_url
                      ? true
                      : false
                  }
                  errorMsg={formik.errors.other_document_url}
                  required={true}
                  name="other-file"
                  disabled={formik.isSubmitting}
                  id="other-file"
                  onChange={(e) => {
                    uploadDocument(e, "other");
                  }}
                />
                {loadingFile && <p>Uploading file...</p>}
              </>
            )}
            {/* end:: Other Form */}
          </form>
        )}
        {showAdiQuestion && (
          <>
            <AdiPdiSettingsTemplate
              parentWrapper="document"
              hasUploadedBadge={false}
              badge={null}
              allowSkip={false}
              handleShow={null}
              goToPrevTab={() => hide()}
              goToNextTab={() => hide()}
            ></AdiPdiSettingsTemplate>
          </>
        )}
      </Modal>
    </>
  );
};

export { AddDocumentDialog };
