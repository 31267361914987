import { FC, useState } from "react";
import StackedList from "../shared/data/StackedList";
import PageHeader from "../shared/elements/PageHeader";
import { Transaction } from "../../../core/models/transaction-model";
import { TransactionTypes } from "../../../core/models/enums/transaction-type-enum";
import EmptyState from "../shared/elements/EmptyState";
import { CurrencyPoundIcon } from "@heroicons/react/24/outline";
import { MoneyInModal } from "./_modals/MoneyInModal";
import { MoneyOutModal } from "./_modals/MoneyOutModal";

interface IProps {
  transactionList: any;
  loading: boolean;
  refreshList: any;
  title: string;
  hidePageHeader: boolean;
}

const ActivityList: FC<IProps> = (props) => {
  const [showAddMoneyInModal, setShowAddMoneyInModal] = useState(false);
  const [showAddMoneyOutModal, setShowAddMoneyOutModal] = useState(false);
  const [selectedTransactionId, setSelectedTransactionId] = useState(null);

  const openAddMoneyInModal = (transactionId: any) => {
    setSelectedTransactionId(transactionId);
    setShowAddMoneyInModal(true);
  };

  const closeAddMoneyInModal = () => {
    setShowAddMoneyInModal(false);
  };

  const openAddMoneyOutModal = (transactionId: any) => {
    setSelectedTransactionId(transactionId);
    setShowAddMoneyOutModal(true);
  };

  const closeAddMoneyOutModal = () => {
    setShowAddMoneyOutModal(false);
  };

  const setColor = (status: any) => {
    if (status === "Pending Learner Payment" || status === "Pending Payout") {
      return "yellow";
    } else if (status === "Complete") {
      return "darkGreen";
    } else {
      return "darkRed";
    }
  };
  return (
    <>
      <div className="bg-white p-5 p-lg-0">
        {!props.hidePageHeader && (
          <div className=" -mt-6 mb-3">
            <PageHeader title={props.title} />
          </div>
        )}
        {props.loading === true ? (
          <>
            <StackedList data={[{ loading: props.loading }]} />
          </>
        ) : (
          <>
            {props.transactionList !== undefined &&
            props.transactionList.length === 0 ? (
              <>
                <EmptyState
                  title={"No " + props.title.toLowerCase() + " yet"}
                  Icon={CurrencyPoundIcon}
                />
              </>
            ) : (
              <StackedList
                title={props.title}
                data={props.transactionList?.map((transaction: Transaction) => {
                  return {
                    loading: props.loading,
                    imgURL:
                      transaction.transaction_type_id ===
                      TransactionTypes.MoneyIn
                        ? "/assets/media/svg/money-in.svg"
                        : "/assets/media/svg/money-out.svg",
                    title: transaction.student_name ?? transaction.expense_type,
                    description:
                      (transaction.isPmf ? "" : transaction.time + " . ") +
                      transaction.date,
                    status: transaction.status,
                    statusColour: setColor(transaction.status),
                    value:
                      transaction.transaction_type_id ===
                      TransactionTypes.MoneyIn
                        ? "+£" + transaction.amount
                        : "-£" + transaction.amount,
                    valueDesc: transaction.payment_method,
                    pmfIcon: transaction.isPmf,
                    onCardClicked: transaction.can_edit
                      ? transaction.transaction_type_id ===
                        TransactionTypes.MoneyIn
                        ? () => openAddMoneyInModal(transaction.transaction_id)
                        : () => openAddMoneyOutModal(transaction.transaction_id)
                      : () => {},
                  };
                })}
              />
            )}

            <MoneyInModal
              show={showAddMoneyInModal}
              onClose={closeAddMoneyInModal}
              transactionId={selectedTransactionId}
              onSubmit={() => {
                props.refreshList();
                closeAddMoneyInModal();
              }}
            ></MoneyInModal>

            <MoneyOutModal
              show={showAddMoneyOutModal}
              onClose={closeAddMoneyOutModal}
              transactionId={selectedTransactionId}
              onSubmit={() => {
                props.refreshList();
                closeAddMoneyOutModal();
              }}
            ></MoneyOutModal>
          </>
        )}
      </div>
    </>
  );
};

export { ActivityList };
