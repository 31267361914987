/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react'
import { DashboardHeader } from '../../components/dashboard/DashboardHeader'
import { ActionCentre } from '../../components/dashboard/action-centre/ActionCentre'

const DashboardWrapper: FC = () => {
    useEffect(() => {
      document.title = `ADI Network App | Dashboard | ADI Network`;
    }, []);
  
  return (
    <>
      <DashboardHeader />
      <div className='px-5 py-6'>
        <ActionCentre />
      </div>
    </>
  )
}

export { DashboardWrapper }
