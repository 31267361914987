import { FC } from 'react'
import { TrashIcon, PlusSmallIcon } from "@heroicons/react/24/outline";
import Button from '../../../../shared/elements/Button';
import TextArea from '../../../../shared/forms/TextArea';
import Alert from '../../../../shared/overlays/Alert';
import { A } from '@fullcalendar/core/internal-common';
import Notes from '../../../../shared/data/Notes';

interface IProps {
  notes: Array<{ note: string }>,
  disabled: boolean,
  setNotes: any,
  student_name: any,
}

const AssessmentNotes: FC<IProps> = ({ notes, setNotes, student_name, disabled }) => {

  const addNewNote = (newNote:any) => {
    setNotes([...notes, { note: newNote }]);
  }

  const updateNote = (value: string, index: number) => {
    let tempNotes = notes;
    tempNotes[index].note = value;
    setNotes([...tempNotes]);
  }

  const removeNote = (note:any,index: number) => {
    let tempNotes = notes.filter((note, i) => i !== index);
    setNotes([...tempNotes]);
  }


  return (
    <>
     <Notes
     title="Notes"
     alertTxt={  <span className='text-[10px]'>
     Feel free to leave feedback here. These notes will be shared with
     <span className="font-size-15 fw-bolder ms-1">{student_name}</span>
   </span>}
     notes={notes.map(a=> {  return {value :a.note}})}
     onCreateNote={(value)=>addNewNote(value)}
     onRemoveNote={(note,index)=>removeNote(note,index)}
     />


    </>
  )
}

export { AssessmentNotes }
