import React from "react";

export interface DayProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  dayNo?: string;
  isActive?: boolean;
  dayName: string;
  children?: React.ReactNode;
  onClick?: any;
  monthName?: string;
}

export default function Day({
  isActive= false,
  dayNo,
  dayName,
  children,
  onClick,
  monthName,
  ...props
}: DayProps) {
  // types: primary, secondary, warning, danger
  return(
  <>
    <div className="flex items-center justify-center w-[60px] mx-auto h-[85px]">
        <button type="button" 
        onClick={() => onClick()}
        className="flex flex-col items-center justify-center">
            
            {/* Default: "", Selected: "bg-gray-900 text-white", Today (Not Selected): "text-indigo-600", Today (Selected): "bg-indigo-600 text-white" */}
            <span 
            className={`
            ${isActive ? "text-white bg-darkBlue w-[60px] h-[60px] text-[24px]" : "bg-white text-[18px] text-darkBlue w-12 h-12"} 
            flex flex-col mt-1 relative items-center justify-center font-semibold rounded-full transition-all`}
            >
                {/* mt-3 flex h-8 w-8 items-center justify-center rounded-full text-base font-semibold  */}
            {dayNo}
            { dayNo === '01' ?
            <span className={`
            ${isActive ? "text-white" : "text-darkBlue"} text-[10px] leading-none pt-1 text-darkBlue font-semibold`}> {monthName} </span>
            : <></>}
            </span>
            <span className="text-[10px] mt-2 text-darkBlue font-semibold">{dayName}</span>
        </button>
    </div>

  </>
  )
}
