import clsx from "clsx";
import { Link } from "react-router-dom";
import MenuLinks from "./MenuLinks";

const Navigation = () => {
  return (
    <>
      <div className="fixed bottom-0 sm:left-0 z-50 w-full sm:h-[90px] bg-darkBlue">
        <MenuLinks />
      </div>
    </>
  );
};

export default Navigation;
