import { LessonStatusEnum } from "../../core/models/enums/lesson-status-enum"

export const getTestStatusColor = (status : any) => {
  switch(status)
  {
    case LessonStatusEnum.Confirmed:
    return 'darkGreen';
    case LessonStatusEnum.CancelledChargeable:
    return 'darkRed';
    case LessonStatusEnum.CancelledNotChargeable:
    return 'darkRed';
    case LessonStatusEnum.Disputed:
    return 'yellow';
    case LessonStatusEnum.Pending:
    return 'yellow';
    case LessonStatusEnum.Rejected:
    return 'darkRed';
    case LessonStatusEnum.Removed:
    return 'darkRed';
    default:
      return  'darkRed'

  }
    
    
  };