import { Switch } from '@headlessui/react'
import Skeleton from '@mui/material/Skeleton';

interface IProps {
    label?: string
    onChange: (e?: any) => void;
    disabled?: boolean
    checked: boolean,
    loading? : boolean,
    size?: "full" | "fit"
}

export default function Toggler({
    label,
    checked,
    disabled,
    onChange,
    loading,
    size="fit",
    ...props
}: IProps) {

    return (
        <div className={`${{
                full: "justify-between",
                fit: "justify-between md:justify-start"
            }[size]
            } flex items-center w-full`}>
            <label className='block text-sm font-medium leading-6 text-darkBlue me-3'>
                {loading ?    <Skeleton animation="wave"  width={200}/>: label }
            </label>
            {
                loading ?    <Skeleton className="w-1/4 !rounded-full" height={20}  width = {50}animation="wave" variant="rounded"></Skeleton> : 
                <Switch
                checked={checked}
                onChange={onChange}
                disabled={disabled} 
                className={`${checked ? 'bg-yellow' : 'bg-[#d2dae7]'}
                ${disabled ? 'pointer-events-none opacity-50' : 'cursor-pointer'}
          relative inline-flex h-[26px] w-[50px] shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                {...props}
            >
                <span className="sr-only">Use setting</span>
                <span
                    aria-hidden="true"
                    className={`${checked ? 'translate-x-6' : 'translate-x-0'}
            pointer-events-none inline-block h-[22px] w-[22px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                />
            </Switch>
            }
            
        </div>
    )
}