import React from "react";

export interface EmptyStateProps {
  Icon?: any;
  title?: string | React.ReactNode;
  description?: string;
  imgUrl?:string;
}

export default function EmptyState({
  title = "Nothing To See Here",
  description = "",
  Icon,
  imgUrl,
  ...props
}: EmptyStateProps) {
  return (
    <div
      className={`flex flex-col items-center justify-center text-center py-10 px-5`}
      {...props}
    >
      {Icon && <Icon className="h-10 w-10 text-darkBlue" />}
      {imgUrl && <img src={imgUrl} className="h-10 w-10 text-darkBlue" />}
      <div className="text-darkBlue text-sm font-bold mt-5 mb-2">{title}</div>
      <p className="text-darkBlue text-[10px]">{description}</p>
    </div>
  );
}
