import { useState } from "react";
import { FilterObject } from "../../../core/models/filter-object";
import Modal from "../shared/overlays/Modal";
import { TestStatusEnum } from "../../../core/models/enums/test-status-enum";
import RadioInput from "../shared/forms/RadioInput";


export type TestFilterModalProps = {
    filterObject: FilterObject,
    ApplyFilter: (searchObject: any) => void
    open: boolean
};

const TestFilterModal = ({ filterObject, open, ApplyFilter }: TestFilterModalProps) => {
    const [searchObject, setSearchObject] = useState<any>(filterObject.SearchObject)
    const clearFilter = () => {
        setSearchObject({});
        ApplyFilter({})
    }

    const mapFilter = (status: any) =>{
        switch (status) {
            case TestStatusEnum.Confirmed:
                setSearchObject({
                    is_accepted: !searchObject.is_accepted,
                    status: status
                })
                break;            
                case TestStatusEnum.Rejected:
                setSearchObject({
                    is_rejected: !searchObject.is_rejected,
                    status: status
                })
                break;
                case TestStatusEnum.Passed:
                setSearchObject({
                    is_passed: !searchObject.is_passed,
                    status: status
                })
                break;
                case TestStatusEnum.Failed:
                setSearchObject({
                    is_failed: !searchObject.is_failed,
                    status: status
                })
                break;
                case TestStatusEnum.PendingResult:
                setSearchObject({
                    is_pending_result_submission: !searchObject.is_pending_result_submission,
                    status: status
                })
                break;
                case TestStatusEnum.PendingAdiApproval:
                setSearchObject({
                    is_pending_adi_approval: !searchObject.is_pending_adi_approval,
                    status: status
                })
                break;
        }
    }

    return (
        <>
            <Modal
                title="Filter"
                closeText="Clear filter"
                submitText="Show Results"
                onSubmit={() => { ApplyFilter(searchObject) }}
                onClose={clearFilter}
                open={open}>

                <div className="flex flex-col">
                    <h2 className="font-bold text-base text-darkBlue mt-3 mb-0">Test Status</h2>

                    <RadioInput
                        name="Test status"
                        label=""
                        required={false}
                        disabled={false}
                        options={[
                            { value: TestStatusEnum.Confirmed, label: "Accepted" }, 
                            { value: TestStatusEnum.Rejected, label: "Rejected" },
                            { value: TestStatusEnum.Passed, label: "Passed" },
                            { value: TestStatusEnum.Failed, label: "Failed" },
                            { value: TestStatusEnum.PendingResult, label: "Awaiting result" },
                            { value: TestStatusEnum.PendingAdiApproval, label: "Awaiting confirmation" },
                        ]}
                        onClick={(e) => {
                            mapFilter(e)
                        }}
                        value={searchObject.status}
                        fullWidth={true}
                    />
                </div>
            </Modal >
        </>)
}


export { TestFilterModal }
