/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from "react";
import { LessonsList } from "../../components/lessons/lessonsList";
import PageHeader from "../../components/shared/elements/PageHeader";
const LessonsWrapper: FC = () => {
  useEffect(() => {
    document.title = `ADI Network App | Calendar | ADI Network`;
  }, []);

  return (
    <>
      <div className="px-5 mb-5">
        <PageHeader title="Calendar" />
      </div>
      <LessonsList />
    </>
  );
};

export { LessonsWrapper };
