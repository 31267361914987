import { FC, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import Day from "../shared/elements/Day";
import { getDaysRangeFromToday } from "../../../core/services/date-service";
import moment from "moment";

interface IProps {
  handleClick: any;
}

const ResponsiveCalendar: FC<IProps> = (props) => {
  const [days, setDays] = useState<any>([]);
  const [selectedDate, setSelectedDate] = useState<any>(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const rangeOfDays = 15;

  // {*//*}

  let firstClientX: number, clientX;

  const preventTouch = (e: any) => {
    const minValue = 5; // threshold

    clientX = e.touches[0].clientX - firstClientX;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;

      return false;
    }
  };

  const touchStart = (e: any) => {
    firstClientX = e.touches[0].clientX;
  };
  let containerRef = useRef(document.createElement("div"));

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener("touchstart", touchStart);
      containerRef.current.addEventListener("touchmove", preventTouch, {
        passive: false,
      });
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener("touchstart", touchStart);
        containerRef.current.removeEventListener("touchmove", preventTouch);
      }
    };
  });

  useEffect(() => {

    setDays(getDaysRangeFromToday(rangeOfDays));
  }, []);

  const settings = {
    className: "center",
    centerMode: true,
    infinite: false,
    centerPadding: "26px",
    slidesToShow: 5,
    speed: 500,
    arrows: false,
    initialSlide: rangeOfDays,
    swipeToSlide: true,
  };

  return (
    <div className="flex flex-col mt-[20px]">
      <div ref={containerRef}>
        <Slider {...settings}>
          {days.map(function (day: any, key: any) {
            return (
              <Day
                key={key}
                onClick={() => {
                  setSelectedDate(day[1].date);
                  props.handleClick(day[1].date);
                }}
                isActive={selectedDate == day[1].date}
                dayName={day[0].dayName}
                dayNo={day[2].dayNo}
                monthName={day[3].monthName}
              />
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export { ResponsiveCalendar };
