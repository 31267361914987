import { FC } from "react";
import Badge from "../../../../shared/elements/Badge";
import { convertToTimeFormat } from "../../../../../helpers/DateHelper";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import CircularProgress from "@mui/joy/CircularProgress";
import Skeleton from "@mui/material/Skeleton";
interface ILessonsProgressProps {
  progress: any;
  loading: any;
  takenHoursResponse: any;
}
export const LessonsProgress: FC<ILessonsProgressProps> = ({
  progress,
  loading,
  takenHoursResponse,
}) => {
  return (
    <>
      <div className="flex items-center justify-between bg-darkBlue rounded-[20px] text-white p-5">
        <div>
          <h2 className="text-base font-bold">
            {loading ? (
              <>
                {" "}
                <Skeleton width={150} className="me-2" />
              </>
            ) : (
              "Remaining hours:"
            )}
          </h2>
          <div className="flex items-center my-2">
            {loading ? (
              <div className="flex item-center">
                <Skeleton width={20} className="me-2" />

                <Skeleton width={150} className="me-2" />
              </div>
            ) : (
              <div className="flex gap-6">
                <div className="flex items-center">
                  <Badge
                    roundedPill={false}
                    title={
                      +takenHoursResponse?.adi_remaining_hours == 0
                        ? "0 Hour"
                        : convertToTimeFormat(
                            +takenHoursResponse?.adi_remaining_hours * 60
                          )
                    }
                    colour="yellow"
                  ></Badge>{" "}
                  <span className="text-xs ms-1">Left</span>
                </div>
                <div className="flex items-center">
                  <Badge
                    roundedPill={false}
                    title={
                      takenHoursResponse?.adi_scheduled_hours == 0
                        ? "0 Hour"
                        : convertToTimeFormat(
                            takenHoursResponse?.adi_scheduled_hours * 60
                          )
                    }
                    colour="blue"
                  ></Badge>{" "}
                  <span className="text-xs ms-1">Booked</span>
                </div>
              </div>
            )}
          </div>
          <p className="flex items-center">
            {loading ? (
              <div className="flex item-center">
                <Skeleton width={20} className="me-2" />

                <Skeleton width={150} className="me-2" />
              </div>
            ) : (
              <>
                <CheckCircleIcon className="w-4 h-4" />
                <span className="text-xs ms-1">
                  {convertToTimeFormat(
                    takenHoursResponse?.adi_taken_hours * 60
                  ) == ""
                    ? "0"
                    : convertToTimeFormat(
                        takenHoursResponse?.adi_taken_hours * 60
                      )}{" "}
                  out of{" "}
                  {convertToTimeFormat(+takenHoursResponse?.adi_hours * 60)}{" "}
                  Completed
                </span>
              </>
            )}
          </p>
        </div>

        <>
          {loading ? (
            <Skeleton
              width={100}
              height={100}
              className="me-2"
              variant="circular"
            />
          ) : (
            <CircularProgress
              determinate
              sx={{
                "--CircularProgress-size": "90px",
                color: "#fff",
                "--CircularProgress-linecap": "square",
                "--CircularProgress-progressColor": "#FFD04F",
                "--CircularProgress-trackColor": "#4F779E",
              }}
              value={Math.round(progress * 100)}
              thickness={10}
            >
              <span className=" font-bold text-2xl">
                {Math.round(progress * 100)}%
              </span>
            </CircularProgress>
          )}
        </>
      </div>
    </>
  );
};
