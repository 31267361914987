/* eslint-disable jsx-a11y/anchor-is-valid */

import { ChevronRightIcon } from "@heroicons/react/24/outline";
import Skeleton from "@mui/material/Skeleton";
import { Link } from "react-router-dom";

export interface PillCardProps extends React.HTMLAttributes<HTMLDivElement> {
  colour?: "yellow" | "darkBlue";
  fullWidth?: boolean;
  title?: string;
  linkURL?: string;
  typeIcon?: string;
  data?: { Icon?: string; value: string; }[];
  loading?: boolean;
}

export default function PillCard({
  colour = "yellow",
  title,
  linkURL,
  fullWidth = true,
  typeIcon,
  data,
  loading,
  ...props
}: PillCardProps) {
  return (
    <>
      <Link to={linkURL ? linkURL : {}}
        className={`${fullWidth ? "w-full" : "w-max"
          } flex items-center space-x-3 rounded-full bg-white px-[9px] pt-[10px] pb-3 mb-4 shadow-sm`}
      >
        <div
          className={`${{
              yellow: "bg-yellow",
              darkBlue: "bg-darkBlue",
            }[colour]
            } flex-shrink-0 flex content-center justify-center border-white  w-[42px] h-[42px] rounded-full drop-shadow-md border-[3px]`}
        >
          <i
            className={`${typeIcon} ${{
                darkBlue: "text-white",
                yellow: "text-darkBlue",
              }[colour]
              }
             text-[18px] flex items-center`}
          ></i>
        </div>
        <div className="min-w-0 flex-1 flex items-center justify-between me-1">
          <div >
            <p className="text-base font-bold mb-1">{loading ? <>
              <Skeleton width={80} />
            </> :
              <>   {title} </>
            }</p>
            <div className="truncate text-sm flex">
              {data?.map((item, i) => (
                <div key={i}>
                  {item.value ? <div className="flex text-xs mr-4">
                    <i className={`${item.Icon} flex items-center mr-1`}></i>
                    {item.value}
                  </div> : <></>}
                </div>
              ))}
              {
                loading &&
                <Skeleton width={200} />
              }
            </div>
          </div>

          <div>
            <ChevronRightIcon className="w-5 h-5" />{" "}
          </div>

        </div>
      </Link>
    </>
  );
}
