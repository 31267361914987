import { FC, useState } from "react";
import { deleteJobBid } from "../../../../core/services/job-service";
import Button from "../../shared/elements/Button";
import Alert from "../../shared/overlays/Alert";
import toast from "react-hot-toast";
import Notification from "../../shared/overlays/Notification";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";

interface IProps {
  jobId: string | undefined;
  onClose: any;
  jobUpdated: any;
}
const WithdrawBidDialog: FC<IProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState(null);

  function withdrawBid() {
    let loadingId = toast.custom(
      () => (
        <Notification
          colour="gray"
          title={"Removing your bid..."}
          description={""}
          Icon={ExclamationCircleIcon}
        />
      ),
      {
        duration: 5000,
        position: "top-center",
      }
    );

    deleteJobBid(props.jobId)
      .then((res) => {
        setLoading(false);
        props.jobUpdated();
        toast.custom(
          () => (
            <Notification
              colour="green"
              title={"Removed successfully"}
              description={""}
              Icon={CheckCircleIcon}
            />
          ),
          {
            duration: 5000,
            position: "top-center",
            id: loadingId,
          }
        );
      })
      .catch((response) => {
        setError(
          response.errors?.length
            ? response.errors[0].errorMessage
            : "Sorry, an error has occurred"
        );
        setLoading(false);
        toast.custom(
          () => (
            <Notification
              colour="red"
              title={"Failed to remove your bid!"}
              description={""}
              Icon={ExclamationTriangleIcon}
            />
          ),
          {
            duration: 5000,
            position: "top-center",
            id: loadingId,
          }
        );
      });
  }

  return (
    <>
      {error && <Alert colour="red" icon="pmf-icon-info" title={error} />}
      <p className="text-sm">Are you sure to withdraw this job bid?</p>

      <div className="sticky w-full bottom-0 left-0 px-0 py-4 bg-white z-[11]">
        <div className='w-full flex items-center justify-between space-x-2'>
        <Button
          colour="yellow"
          type="submit"
          className="w-1/2"
          size="large"
          onClick={withdrawBid}
          disabled={loading}
        >
          Withdraw
        </Button>
        <Button
          colour="outline"
          type="button"
          className="w-1/2"
          size="large"
          onClick={props.onClose}
          disabled={loading}
        >
          Cancel
        </Button>
        </div>
      </div>
    </>
  );
};

export { WithdrawBidDialog };
