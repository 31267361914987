import { useState, useEffect } from "react";
import {
  checkIsFirstLogin,
  getSavedSteps,
  skipJobPreferencesStep,
} from "../../../core/services/adi-service";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ArrowRightOnRectangleIcon } from "@heroicons/react/20/solid";
import { PersonalDetails } from "../account-settings/personal-details/PersonalDetails";
import { RootState } from "../../../../setup";
import { AboutUser } from "../account-settings/about-user/AboutUser";
import { AboutDrivingTuition } from "../account-settings/drivingTuition/AboutDrivingTuition";
import { signOut } from "../../pages/auth/redux/AuthCRUD";

import * as auth from "../../pages/auth/redux/AuthRedux";
import { toAbsoluteUrl } from "../../helpers";
import Button from "../shared/elements/Button";
import { LogoutModal } from "./LogoutModal";
import { GeneralQuestionnaireData } from "../account-settings/general-questionnaire-data/GeneralQuestionnaireData";
import { getConnectAccount } from "../../../core/services/connect-service";
import { useQuery } from "../../helpers/QueryParamsHelper";
import { AnimatePresence, motion } from "framer-motion";
import Stepper from "../shared/navigation/Stepper";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";

export function CompleteADIQuestionnaire() {
  const dispatch = useDispatch();
  const [showLogout, setShowLogout] = useState<boolean>(false);
  const logout = () => {
    setShowLogout(false);
    signOut();
    dispatch(auth.actions.logout());
    sessionStorage.clear();
  };
  const hasUnVerifiedData = useSelector<RootState>(
    ({ auth }) => auth.hasUnVerifiedData
  );
  const [refresh, doRefresh] = useState(0);
  const [active, setActive] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [selfRegisterDate, setSelfRegisterDate] = useState<any>(false);
  const history = useHistory();
  const query = useQuery();
  const redirectToDashboard = () => {
    dispatch(auth.actions.setIsLogin(true));
    dispatch(auth.actions.requestPersonalData());
    if (
      history.location.search.includes("category") ||
      history.location.search.includes("videos")
    ) {
      let url = query.get("returnUrl");
      if (!url) {
        const tab = query.get("tab");
        url = `/ADI/cpd?tab=${tab ?? "videos"}`;
      }
      const category = query.get("category");
      if (category) url = url + `&category=${category}`;
      const video = query.get("video");
      if (video) url = url + `&video=${video}`;
      history.push(url);
    } else {
      history.push("/ADI/dashboard");
    }
  };

  const parentSteps = ["About You", "Digital Payments", "Job Preferences"];
  const [parentStep, setParentStep] = useState<string>("");

  useEffect(() => {
    setLoading(true);
    getIsFirstLogin();
  }, []);

  const getIsFirstLogin = async () => {
    const res = await checkIsFirstLogin();

    if (res.results.isFirstLogin === 1) {
      history.push(`/welcome-page${history.location.search}`);
    } else {
      getSavedQuestionnaireSteps(true);
    }
  };

  const getSavedQuestionnaireSteps = (setInitialStep: boolean) => {
    getSavedSteps()
      .then((res) => {
        setSelfRegisterDate(res.data.results.self_register_date);
        dispatch(
          auth.actions.setHasUnVerifiedData(
            res.data.results.has_un_verified_imported_data
          )
        );

        let savedSteps = res.data.results.saved_steps;
        let completedSteps = 0;
        let tempSteps = getResetSteps();
        let currentStep = tempSteps[0].name;
        if (!res.data.results.has_un_verified_imported_data) {
          redirectToDashboard();
          setLoading(false);
        } else {
          if (savedSteps.length >= 3) {
            currentStep = "JobPreferences";
            completedSteps = 3;
          } else {
            completedSteps = savedSteps.length;
            for (let step of tempSteps) {
              let isSaved: boolean =
                savedSteps.findIndex((a) => a === step.index) > -1;
              if (!isSaved) {
                currentStep = step.name;
                break;
              }
            }
          }

          if (currentStep === "JobPreferences") {
            getAccount()
              .then((data) => {
                if (data) {
                  currentStep = "JobPreferences";
                  completedSteps++;
                }
                if (setInitialStep) setActive(currentStep);
                updateParentStepOnly(currentStep);
              })
              .catch(() => {
                if (res.data.results.stripe_skipped) {
                  currentStep = "JobPreferences";
                  completedSteps++;
                }
                if (setInitialStep) setActive(currentStep);
                updateParentStepOnly(currentStep);
              });
          } else {
            if (setInitialStep) setActive(currentStep);
            updateParentStepOnly(currentStep);
          }
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  function getResetSteps(): Array<any> {
    return [
      {
        name: "personalDetails",
        label: "Contact Details",
        index: 1,
      },
      {
        name: "aboutUser",
        label: "About You",
        index: 2,
      },
      {
        name: "aboutDrivingTuition",
        label: "Your ADI badge",
        index: 3,
      },
      {
        name: "JobPreferences",
        label: "Job preferences",
        index: 6,
      },
    ];
  }
  const updateSteps = (setInitialStep: boolean) => {
    getSavedQuestionnaireSteps(setInitialStep);
  };

  const updateParentStep = (activeStep: string) => {
    if (activeStep === "JobPreferences") {
      setParentStep(parentSteps[2]);
    } else {
      setParentStep(parentSteps[0]);
    }
  };

  const updateParentStepOnly = (activeStep: string) => {
    updateParentStep(activeStep);
    setLoading(false);
  };

  const getAccount = async () => {
    const res = await getConnectAccount();
    if (res.data) {
      return res.data;
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (active === "JobPreferences") {
      updateSteps(true);
    }
    //  doRefresh(refresh+1)
  }, []);

  const skipJobPreferences = async () => {
    await skipJobPreferencesStep();
    redirectToDashboard();
  };

  const filteredSteps = getResetSteps();
  const currentStepIndex = filteredSteps.findIndex(
    (step) => step.name === active
  );

  const goToPrevTab = () => {
    const prevStepIndex = currentStepIndex - 1;
    if (prevStepIndex >= 0) {
      const prevStep = filteredSteps[prevStepIndex].name;
      setActive(prevStep);

      if (
        prevStep === "aboutUser" ||
        prevStep === "personalDetails" ||
        prevStep === "aboutDrivingTuition"
      ) {
        setParentStep(parentSteps[0]);
      } else if (prevStep === "JobPreferences") {
        setParentStep(parentSteps[2]);
      }
    }
  };

  if (loading) {
    // return <><SplashScreen /></>
    return <></>;
  } else {
    return (
      <>
        <div className="relative flex flex-col items-center w-full justify-center py-6">
          <div className="grid grid-cols-5 items-center w-full mb-6">
            <a
              className="m-auto col-span-3 col-start-2 mx-auto"
              href="https://www.passmefast.co.uk"
              title="PassMeFast"
              rel="home"
            >
              <img
                alt="ADI Network Logo"
                src={toAbsoluteUrl(
                  "/assets/media/logos/ADI-network-logo-sm.svg"
                )}
                className="h-10 w-auto"
              />
            </a>
            <span className="relative flex justify-end pe-4">
              <Button
                size="icon"
                colour="outline"
                fitWidth
                onClick={() => setShowLogout(true)}
                Icon={ArrowRightOnRectangleIcon}
              ></Button>
            </span>
          </div>

          {!loading ? (
            <div
              className="w-full flex flex-col gap-5 justify-center items-center"
              aria-hidden="true"
            >
              <div className="grid grid-cols-5 items-center justify-between w-full lg:max-w-[750px]">
                <span className="relative flex justify-end pe-4">
                  <Button
                    colour="link"
                    size="fit"
                    fullWidth
                    onClick={goToPrevTab}
                    className="no-underline"
                  >
                    <ArrowLeftIcon className="w-4 h-4 me-[10px] -ms-2" />
                  </Button>
                </span>
                <p className="col-span-3 col-start-2 text-darkBlue font-bold text-base text-center">
                  {parentStep}
                </p>

                {currentStepIndex > 2 ? (
                  <>
                    <Button
                      colour="link"
                      size="fit"
                      fullWidth
                      onClick={skipJobPreferences}
                      className="no-underline"
                    >
                      Skip now
                    </Button>
                  </>
                ) : (
                  <></>
                )}
              </div>

              {/* Stepper component */}
              <Stepper
                steps={filteredSteps}
                currentStepIndex={currentStepIndex}
              />
            </div>
          ) : (
            <></>
          )}
        </div>

        <div
          className={`bg-white w-full min-h-[calc(100vh-130px)] flex justify-center items-top py-5`}
        >
          <AnimatePresence mode="wait">
            <motion.div
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              className="w-full lg:max-w-[750px] bg-white rounded-lg sm:px-3"
            >
              {active === "personalDetails" && (
                <PersonalDetails
                  hasUnVerifiedProfile={hasUnVerifiedData}
                  refresh={refresh}
                  selfRegisterDate={selfRegisterDate}
                  goToPrevTab={() => setActive("personalDetails")}
                  goToNextTab={() => {
                    setActive("aboutUser");
                  }}
                  parentWrapper="questionnaire"
                />
              )}
              {active === "aboutUser" && (
                <AboutUser
                  hasUnVerifiedProfile={hasUnVerifiedData}
                  refresh={refresh}
                  selfRegisterDate={selfRegisterDate}
                  goToPrevTab={() => setActive("personalDetails")}
                  goToNextTab={() => {
                    setActive("aboutDrivingTuition");
                    doRefresh(1);
                  }}
                  parentWrapper="questionnaire"
                />
              )}
              {active === "aboutDrivingTuition" && (
                <AboutDrivingTuition
                  hasUnVerifiedProfile={hasUnVerifiedData}
                  refresh={refresh}
                  selfRegisterDate={selfRegisterDate}
                  goToPrevTab={() => setActive("aboutUser")}
                  goToNextTab={() => {
                    setActive("JobPreferences");
                    updateSteps(true);
                  }}
                  parentWrapper="questionnaire"
                />
              )}
              {active === "JobPreferences" && (
                <GeneralQuestionnaireData
                  hasUnVerifiedProfile={hasUnVerifiedData}
                  refresh={refresh}
                  selfRegisterDate={selfRegisterDate}
                  goToPrevTab={() => setActive("aboutDrivingTuition")}
                  goToNextTab={() => redirectToDashboard()}
                  parentWrapper="questionnaire"
                  goToDashboard={true}
                />
              )}
            </motion.div>
          </AnimatePresence>
        </div>

        <LogoutModal
          show={showLogout}
          onClose={() => setShowLogout(false)}
          onSubmit={logout}
        />
      </>
    );
  }
}

export default CompleteADIQuestionnaire;
