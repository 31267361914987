import { FC, useEffect, useState } from "react";

/* eslint-disable jsx-a11y/anchor-is-valid */
interface IProps {
    timeInSeconds: number
    isResetting: boolean
    resetTimer: (rt: boolean) => void
    onFinish: any
    children?:React.ReactNode
}

const CountDownTimer: FC<IProps> = (props) => {

    const [remainingTime, setRemainingTime] = useState<number>(0);
    useEffect(() => {
        if (props.isResetting) {
            props.resetTimer(false);
            setRemainingTime(props.timeInSeconds);
        }
    }, [props.isResetting])

    useEffect(() => {
        setTimeout(() => {
            if (remainingTime > 0) {
                const nextTime = remainingTime - 1;
                setRemainingTime(nextTime);
                if (nextTime == 0) {
                    props.onFinish();
                }
            }
        }, 1000);
    }, [remainingTime])

    return (
        <> {remainingTime > 0 && (
            <button
                disabled={true}
                className="btn btn-link link-primary fs-6 fw-bolder pt-1 pb-0">
                {remainingTime} secs
            </button>
        )}
        </>

    )
}

export { CountDownTimer };