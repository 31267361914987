import { FC, useState } from 'react'
import Modal from '../../shared/overlays/Modal'
import Alert from '../../shared/overlays/Alert'


type Props = {
  onHide: any
  submitForDelete: any,
  show: boolean,
  hasActiveStripePayment: boolean,
  hasActivePmfCourses: boolean
}

const DeleteAdiAccountModal: FC<Props> = ({ submitForDelete, onHide, show, hasActiveStripePayment, hasActivePmfCourses }) => {
  return (
    <>
      <Modal
        open={show}
        title="Delete Account"
        submitText='Yes'
        closeText='No'
        onClose={onHide}
        onSubmit={submitForDelete}
        hideClose={hasActivePmfCourses}
        hideSubmit={hasActivePmfCourses}
      >
        {/* ---- */}
        <div className='d-flex flex-column'>
          {hasActivePmfCourses && <div className="pb-8"><p className=''>
            We are currently unable to delete your account as you have active learners
            assigned to you. Please contact the ADI Network team on 0333 1886 627 to discuss
            your request further.
            Please also be aware that if for any reason you are dissatisfied with the handling
            of your request you have the right to lodge a complaint with the Information
            Commissioner’s Office (ICO).
          </p>
            <p className="mt-2">Contact details for the ICO are available on their website
              <a
                href="https://ico.org.uk/"
                target="_blank"
                rel="noopener noreferrer"
                className="ml-1 underline cursor-pointer"
              >
                <b>Information Commissioner's Office (ICO)</b>
              </a>
            </p>
          </div>}
          {hasActiveStripePayment && !hasActivePmfCourses &&
            <span>
              <p className=''>
                Our Privacy Manager will contact you in the next few days regarding your request,
                via the email address registered to your account.
                Please be aware that you may need to verify your ID or provide more information
                before we can proceed with your request.
                By requesting to deleting your account you will lose access to private student data
                you have stored on your account.
              </p>
              <p>More information is available on our Privacy Notice
                <a
                  href="https://www.adinetwork.co.uk/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ml-1 underline cursor-pointer"
                >
                  <b>Privacy Policy | ADI Network</b>
                </a>.
              </p>
              <div className='mt-4'>
                <Alert
                  colour="blue"
                  icon="pmf-icon-info"
                  customDesc={
                    <p className="text-base text-bold">Please note, you have a pending Stripe Payment(s), you will receive this payment inline with your pay-out schedule, however, you can still contact ADI Network if you experience any issues with this payment</p>
                  }
                />
              </div>

              <p className="my-3 text-base">Please confirm you wish to continue</p>
            </span>
          }
          {!hasActiveStripePayment && !hasActivePmfCourses &&
            <span>
              <p className=''>
                Our Privacy Manager will contact you in the next few days regarding your request,
                via the email address registered to your account.
                Please be aware that you may need to verify your ID or provide more
                information before we can proceed with your request.
                By requesting to deleting your account you will lose access to private student
                data you have stored on your account. </p>
              <p>  More information is available on our Privacy Notice
                <a
                  href="https://www.adinetwork.co.uk/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ml-1 underline cursor-pointer"
                >
                  <b>Privacy Policy | ADI Network</b>
                </a>.
              </p>
              <p className="my-3 text-base">Please confirm you wish to continue</p>
            </span>
          }
        </div>
      </Modal>
    </>
  )
}

export { DeleteAdiAccountModal }
