/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from "react";
import { NewJobsSummary } from "./NewJobsSummary";
import { TestsSummary } from "./TestsSummary";
import { LessonsSummary } from "./LessonsSummary";
import { PaymentSummary } from "./PaymentSummary";
import { DashboardAlerts } from "./DashboardAlerts";
import { PrismicNotification } from "./PrismicNotification";

const ActionCentre: FC = () => {

  const [newJobsCount, setNewJobsCount] = useState<any>(null);
  const [canShowPrismicNotification, setCanShowPrismicNotification] = useState(false);
  var newJobsSummary = (
    <NewJobsSummary
      setIsDocumentMissing={false}
      setNewJobsCount={setNewJobsCount}
    />
  );

  return (
    <>
      {
        canShowPrismicNotification ? <PrismicNotification /> :
          <DashboardAlerts showPrismicNotification={() => setCanShowPrismicNotification(true)} />
      }



      <div className="w-full pt-0">
        <h3 className="text-xs text-darkBlue font-medium mb-1">Action Centre</h3>
        <div className="w-full sm:max-w-full">
          {/* ----start:: JOBS---- */}
          <> {newJobsSummary} </>
          {/* ----end:: JOBS---- */}
          {/* ----start:: TEST---- */}
          <TestsSummary ></TestsSummary>

          {/* ----end:: TEST---- */}
          {/* ----start:: PAYMENTS---- */}
          <PaymentSummary></PaymentSummary>
          {/* ----end:: PAYMENTS---- */}
          {/* ----start:: LESSONS---- */}
          <LessonsSummary></LessonsSummary>
          {/* ----end:: LESSONS---- */}

        </div>
      </div>
    </>
  );
};

export { ActionCentre };
