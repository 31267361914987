import axios, { AxiosResponse } from 'axios'
import { Assessment } from '../models/assessments/assessment'
import { Section } from '../models/assessments/section'
import { BaseResponse } from '../models/base-response'

const ASSESSMENT_URL = `${process.env.REACT_APP_ASSESSMENTS_BASE_URL}/cognito`
const ASSESSMENT_API_KEY = process.env.REACT_APP_X_API_KEY

export function getDealAssessments(dealId: any): Promise<AxiosResponse<BaseResponse<any>>> {
    return axios.get<BaseResponse<any>>(`${ASSESSMENT_URL}/history/${dealId}`, {
        headers: {
            "x-api-key": ASSESSMENT_API_KEY!
        }
    })
}

export function addAssessment(data: Assessment): Promise<AxiosResponse<BaseResponse<Assessment>>> {
    return axios.post<BaseResponse<Assessment>>(`${ASSESSMENT_URL}`, data, {
        headers: {
            "x-api-key": ASSESSMENT_API_KEY!
        }
    })
}

export function getSections(dealId:any): Promise<AxiosResponse<BaseResponse<Array<Section>>>> {
    return axios.get<BaseResponse<Array<Section>>>(`${ASSESSMENT_URL}/sections/${dealId}`, {
        headers: {
            "x-api-key": ASSESSMENT_API_KEY!
        }
    })
}

export function shareAssessment(jobId: any, studentData: any): Promise<AxiosResponse<BaseResponse<any>>> {
    return axios.post<BaseResponse<any>>(`${ASSESSMENT_URL}/share/${jobId}`, studentData, {
        headers: {
            "x-api-key": ASSESSMENT_API_KEY!
        }
    })
}
export function getReflectionQuestions(jobId: any, studentData: any): Promise<AxiosResponse<BaseResponse<any>>> {
    return axios.get<BaseResponse<any>>(`${ASSESSMENT_URL}/reflections`, {
        headers: {
            "x-api-key": ASSESSMENT_API_KEY!
        }
    })
}
export function getReflectionForLesson(lessonId: any): Promise<AxiosResponse<BaseResponse<any>>> {
    return axios.get<BaseResponse<any>>(`${ASSESSMENT_URL}/lesson/${lessonId}/reflections`, {
        headers: {
            "x-api-key": ASSESSMENT_API_KEY!
        }
    })
}
export function AddUpdateReflectionForLesson(data: any): Promise<AxiosResponse<BaseResponse<any>>> {
    return axios.put<BaseResponse<any>>(`${ASSESSMENT_URL}/reflections`,data, {
        headers: {
            "x-api-key": ASSESSMENT_API_KEY!
        }
    })
}