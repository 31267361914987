import { FC, useRef, useEffect, useState } from 'react'
import { connect, useDispatch, ConnectedProps } from 'react-redux'
import * as auth from './AuthRedux'
import { getUserByToken, addAdi, signOut } from './AuthCRUD'
import { RootState } from '../../../../../setup'
//commented
//import { LayoutSplashScreen } from '../../../layout/core'
import { Auth } from "aws-amplify";
import { useHistory } from 'react-router-dom'

const mapState = (state: RootState) => ({ auth: state.auth })
const connector = connect(mapState, auth.actions)
type PropsFromRedux = ConnectedProps<typeof connector>
interface Props  extends PropsFromRedux{
  children: React.ReactNode;
}

const AuthInit: FC<Props> = (props) => {
  const [isLoaded , setIsLoaded] = useState(false)
  const didRequest = useRef(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken before rendering the application
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const user = await getUserByToken();
          const redirectKey = 'amplify-redirected-from-hosted-ui';
          const isRedirected = localStorage.getItem(redirectKey);
          if (isRedirected) {
            await addAdi(user.username).then(data=>{
              if (data.data.results.isRegistration == false) {
                if (data.data.results.can_access_portal) {
                  dispatch(auth.actions.setIsLogin(true))
                } else {
                  localStorage.setItem("permission-denied", "true");
                  return signOut();
                }
              } else  {
                history.push('/auth')        
              }
            });
            localStorage.removeItem(redirectKey);
          }
          dispatch(props.fulfillUser(user));
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          dispatch(props.logout())
          sessionStorage.clear()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }
      Auth.currentSession().then(async () => {
     await  requestUser();
     setIsLoaded(true)
    }).catch(() => { 
      setIsLoaded(true)
      dispatch(props.logout())
      sessionStorage.clear()
      setShowSplashScreen(false)
    });
    // eslint-disable-next-line
  }, [])

 // return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>
  return  (isLoaded ? <>{props.children}</>  : <></> );

}

export default connector(AuthInit)
