/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import * as auth from '../redux/AuthRedux'
import { impersonateUser } from '../redux/AuthCRUD'
import { getSavedSteps } from '../../../../core/services/adi-service'
import PageHeader from '../../../components/shared/elements/PageHeader'
import Alert from '../../../components/shared/overlays/Alert'
import Input from '../../../components/shared/forms/Input'
import Button from '../../../components/shared/elements/Button'

const loginSchema = Yup.object().shape({
  email: Yup.string().email('Wrong email format').required('Email is required'),
  password: Yup.string().required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

export function ImpersonatedLogin() {
  // Initialize a boolean state
  const [passwordShown, setPasswordShown] = useState(false)

  // Password toggle handler
  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown)
  }

  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setStatus(null)
      setTimeout(() => {
        impersonateUser(values.email, values.password)
          .then(() => {
            return checkHasUnverifiedData()
          })
          .catch((err) => {
            if (err.errors?.length) {
              setStatus(err.errors[0].errorMessage)
            } else {
              setStatus(err.message)
            }
          }).finally(() => {
            setSubmitting(false)
            setLoading(false)
          })
      }, 1000)
    },
  })
  const checkHasUnverifiedData = () => {
    return getSavedSteps().then(res => {
      if (res.data.results.has_un_verified_imported_data) {
        dispatch(auth.actions.setHasUnVerifiedData(true))
      }
      dispatch(auth.actions.setIsLogin(true))
      dispatch(auth.actions.login())
    });
  }
  return (
    <>
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
      >
        {/* begin::Heading */}
        <PageHeader title='Log in to continue' isCenter={true} />
        {/* begin::Heading */}

        {formik.status ? (
          <Alert colour='red' description={formik.status} />
        ) : (
          <></>
        )}

        {/* begin::Form group */}
        <Input
          label='Email'
          placeholder='Email'
          {...formik.getFieldProps('email')}
          error={formik.touched.email && formik.errors.email ? true : false}
          errorMsg={formik.errors.email}
          type='email'
          name='email'
        />
        {/* end::Form group */}

        {/* begin:: Password form group */}


        <Input
          label='Password'
          type={passwordShown ? 'text' : 'password'}
          error={formik.touched.password && formik.errors.password ? true : false}
          errorMsg={formik.errors.password}
          eyeIcon={true}
          placeholder='Password'
          {...formik.getFieldProps('password')}

        />



        <div className='text-sm leading-6 mb-6'>
          {/* begin::Link */}
          <Link
            to='/auth/forgot-password'
            className="font-semibold text-darkBlue hover:text-indigo-800 hover:brightness-75 cursor-pointer"
          >
            Forgot Password?
          </Link>
          {/* end::Link */}
        </div>
        {/* end:: Password form group */}

        {/* begin::Action */}
        <Button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-3'
          size='large'
          fullWidth={true}
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </Button>
        {/* end::Action */}
      </form>
    </>
  )
}
