import CreatableSelect from 'react-select/creatable';

export interface CreatableDropdownProps {
  label?: string;
  id?: string;
  name?: string;
  className?: string;
  value?: any;
  options: Array<any>;
  onChange?: (newValue: any, actionMeta: any) => void;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error?: boolean;
  errorMsg?: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  isSearchable?: boolean;
}

export default function CreatableDropdown({
  label,
  className = "",
  options = [],
  name,
  id = "",
  value,
  onChange,
  onBlur,
  required = false,
  disabled = false,
  isSearchable = false,
  error = false,
  errorMsg = "This field is required",
  placeholder,
  ...props
}: CreatableDropdownProps) {
  
  return (
    <div className="flex flex-col items-start my-4">
      <div className="relative w-full">
        <label
          htmlFor={name}
          className="absolute -top-2 left-2 inline-block bg-white px-1 text-sm font-medium text-darkBlue z-[1]"
        >
          {label}{required && <span className='text-sm text-red-500'>*</span>}
        </label>
        <CreatableSelect
          {...props}
          name={name}
          id={id}
          placeholder={placeholder}
          isSearchable={isSearchable}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          isDisabled={disabled}
          required={required}
          options={options}
          className={`block w-full text-darkBlue placeholder:text-gray-400 mt-1 ${className}`}
            styles={{
              input: (baseStyles, state) => ({
                ...baseStyles,
                height: '38px',
                minWidth: '100px',
                borderColor: 'transparent'
              }),
              placeholder: (baseStyles, state) => ({
                ...baseStyles,
                color:'#9ca3af',
                fontSize:'14px',
              }),
              control: (baseStyles, state) => ({
                ...baseStyles,
                minHeight: '44px',
                height: '44px',
                borderRadius: '2px',
                boxShadow: 'none',
                borderColor: state.isFocused ? '#4f46e5' : (error ? "#ef4444" : "#dadde2"),
                "&:hover": {
                  borderColor: error ? "#ef4444" : (state.isFocused ? '#4f46e5' : "#dadde2")
                }
              }),
              singleValue: (baseStyles, state) => ({
                ...baseStyles,
                fontSize:'14px',
                fontWeight: 400,
                color: '#163A5E'
              }),
              option: (baseStyles, state) => ({
                ...baseStyles,
                fontSize:'15px',
                minHeight: '36px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                display: 'inline-block',
              }),
              menuList: (baseStyles, state) => ({
                ...baseStyles,
                maxHeight:'140px',
                overflowX: 'hidden',
                display: 'grid',
                width: '100%'
              }),
              menu: (baseStyles, state) => ({
                ...baseStyles,
                position: 'absolute',
                zIndex: 20,
                marginTop: '4px',
                boxShadow: 'none',
                border: '1px solid #d1d5db'
              }),
            }}
        />
        {error && (
          <p className="text-sm text-red-600" id="error">
            {errorMsg}
          </p>
        )}
      </div>
    </div>
  );
}


