import { useEffect, useState } from "react";
import { motion } from "framer-motion";

interface Step {
  name: string;
  label: string;
  index: number;
}

interface StepperProps {
  steps: Step[];
  currentStepIndex: number;
}


export default function Stepper({ steps , currentStepIndex = 0 }: StepperProps) {

  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    if (window) setInitialLoad(false);
  }, []);
  return (
    <div className="flex flex-col text-white w-full max-w-[260px]">
      {/* <div className="text-center mb-2 font-bold">{currentStep?.name}</div> */}
      <div className="flex space-x-[6px]">
        {steps.map((step, i) => (
          <div
            className={`bg-darkBlue/10 bg-opacity-50 first:rounded-l-md w-full h-[8px] last:rounded-r-md overflow-hidden`}
            key={i}
          >
            <motion.div
              className={`w-full h-full bg-darkBlue `}
              variants={{ completed: { width: "100%", opacity: 1 }, incomplete: { width: "0%", opacity: 0 } }}
              initial="incomplete"
              animate={currentStepIndex + 1 > i ? "completed" : "incomplete"}
              transition={{
                delay: initialLoad ? i * 0.1 : 0,
                duration: initialLoad ? 0.2 : 0.4,
                ease: [0.65, 0.05, 0.36, 0.95],
              }}
            ></motion.div>
          </div>
        ))}
      </div>
    </div>
  );
}
