import { NewCheckoutItem } from "./new-checkout-item";

export class NewCheckout {
    student_id: string | null = null;
    job_id: string | null = null;
    student_email: string | null = null;
    student_name: string | null = null; 
    notify: boolean= true;
    forward_fee: number= 0;
    items: Array<NewCheckoutItem> = [];
    is_private_student?: number= 0;
    product_id?: number|undefined;
    lesson_date?: string|null = null; 
    lesson_id?: string|null = null; 
}