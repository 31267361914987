import {
  ExclamationCircleIcon,
  PencilSquareIcon,
  PlusSmallIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { FC, useEffect, useState } from "react";
import { Lesson } from "../../../../../core/models/lesson";
import { AddEditLessonModel } from "../../../lessons/_modals/AddEditLessonModel";
import { RemoveLessonDialog } from "../../../lessons/_modals/RemoveLessonModal";
import { LessonsProgress } from "./lesson-tab/LessonsProgress";
import Button from "../../../shared/elements/Button";
import StackedCardWithActionsMenu from "../../../shared/data/StackedCardWithActionsMenu";
import Alert from "../../../shared/overlays/Alert";
import EmptyState from "../../../shared/elements/EmptyState";
import Skeleton from "@mui/material/Skeleton";
import { getTestStatusColor } from "../../../../helpers/lesson-status-helper";
import { useHistory } from "react-router-dom";
import { checkAdiHasBankDetails } from "../../../../../core/services/adi-service";
import { CancelLessonDialog } from "../../../lessons/_modals/CancelLessonModal";
import { getTakenHours } from "../../../../../core/services/lessons-service";

interface ILessonsTabProps {
  lessons: Array<any>;
  getLessons: any;
  jobDetails: any | null;
  loading: boolean;
  warning?: any | undefined;
  setWarning?: any;
}
export const LessonsTab: FC<ILessonsTabProps> = ({
  lessons,
  getLessons,
  jobDetails,
  loading,
  warning,
  setWarning,
}) => {
  const [currentLesson, setCurrentLesson] = useState<Lesson>(new Lesson());
  const [showAddLessonCard, setShowAddLessonCard] = useState<boolean>(false);
  const [showCancelLessonCard, setShowCancelLessonCard] =
    useState<boolean>(false);
  const [showDeleteLessonCard, setShowDeleteLessonCard] =
    useState<boolean>(false);
  const [showUpdateLessonCard, setShowUpdateLessonCard] =
    useState<boolean>(false);
  const [hasBankDetails, setHasBankDetails] = useState<boolean>(false);
  const [takenHoursResponse, setTakenHoursResponse] = useState(null);
  const history = useHistory();

  useEffect(() => {
    (async () => {
      const result = await checkAdiHasBankDetails().catch(() => {});
      setHasBankDetails(result?.data?.results?.hasBankDetails);
    })().catch(() => {});
  }, []);

  useEffect(() => {
    if (!jobDetails?.job_ref) {
      return;
    }
    loadTakenHours();
  }, [jobDetails?.job_ref]);

  const loadTakenHours = async () => {
    const response = await getTakenHours(jobDetails?.job_ref).catch(() => null);
    if (!response?.results) {
      return;
    }
    setTakenHoursResponse(response.results);
  };

  var lessonsRows = lessons?.map((lesson: any, index: number) => {
    return (
      <div key={index}>
        <StackedCardWithActionsMenu
          icon="pmf-icon-wheel"
          title={lesson.date_str}
          description={lesson.form_time_str + " to " + lesson.to_time_str}
          pmfIcon={!lesson.is_private_student}
          status={{
            title: lesson.status ?? "",
            color: getTestStatusColor(lesson.status_id),
          }}
          pillTitle={lesson.job_ref ? "#" + lesson.job_ref : null}
          showAction={
            !(loading || !lesson?.abilities?.can_be_removed) ||
            !(loading || !lesson?.abilities?.can_be_updated)
          }
          children={
            lesson.lesson_type && (
              <>
                <label className="text-sm">
                  <strong>Lesson Type: </strong> {lesson.lesson_type}
                </label>
              </>
            )
          }
          actions={[
            {
              Icon: PencilSquareIcon,
              title: "Edit lesson",
              show: !(loading || !lesson?.abilities?.can_be_updated),
              onClick: () => {
                setCurrentLesson(lesson);
                setShowUpdateLessonCard(true);
              },
            },
            {
              Icon: TrashIcon,
              title: "Delete lesson",
              show: !(loading || !lesson?.abilities?.can_be_removed),
              onClick: () => {
                setCurrentLesson(lesson);
                setShowDeleteLessonCard(true);
              },
            },
            {
              Icon: XMarkIcon,
              title: "Cancel lesson",
              show: !(loading || !lesson?.is_private_student),
              onClick: () => {
                setCurrentLesson(lesson);
                setShowCancelLessonCard(true);
              },
            },
          ].filter((s) => s.show)}
        />
      </div>
    );
  });

  return (
    <>
      <AddEditLessonModel
        onClose={() => {
          setShowUpdateLessonCard(false);
        }}
        duration={currentLesson.duration}
        firstDate={
          currentLesson?.lesson_date_str
            ? new Date(currentLesson?.lesson_date_str)
            : new Date()
        }
        jobId={currentLesson?.job_id}
        id={currentLesson?.id}
        lessonsUpdated={() => {
          getLessons();
        }}
        show={showUpdateLessonCard}
        isPrivateJob={currentLesson?.is_private_student}
        student_email={currentLesson?.student_data?.student_email}
        recurringOption={currentLesson?.recurring_option}
        canUpdateSeries={currentLesson?.can_update_series}
        seriesStartDate={currentLesson?.series_start_date}
        seriesEndDate={currentLesson?.series_end_date}
        lesson_type={currentLesson?.lesson_type}
      />
      <AddEditLessonModel
        onClose={() => {
          setShowAddLessonCard(false);
        }}
        duration={1}
        firstDate={null}
        jobId={jobDetails?.job_id}
        id={undefined}
        lessonsUpdated={() => {
          getLessons();
        }}
        show={showAddLessonCard}
        isPrivateJob={jobDetails?.is_private_student}
        student_email={jobDetails?.student_email}
      />
      <RemoveLessonDialog
        lessonId={currentLesson?.id}
        duration={currentLesson.duration}
        date={currentLesson.lesson_date_str}
        show={showDeleteLessonCard}
        dateStr={currentLesson.lesson_date_str}
        isPrivateJob={currentLesson?.is_private_student}
        onHide={() => {
          setShowDeleteLessonCard(false);
        }}
        onSubmit={() => {
          getLessons();
          setShowDeleteLessonCard(false);
        }}
        recurringOption={currentLesson?.recurring_option}
        canUpdateSeries={currentLesson?.can_update_series}
      />
      <CancelLessonDialog
        lessonId={currentLesson?.id}
        duration={currentLesson.duration}
        date={currentLesson.lesson_date_str}
        show={showCancelLessonCard}
        dateStr={currentLesson.lesson_date_str}
        isPrivateJob={currentLesson?.is_private_student}
        hasEmail={currentLesson?.student_data?.student_email ? true : false}
        onHide={() => {
          setShowCancelLessonCard(false);
        }}
        onSubmit={() => {
          getLessons();
          setShowCancelLessonCard(false);
        }}
        recurringOption={currentLesson?.recurring_option}
        canUpdateSeries={currentLesson?.can_update_series}
      />
      {hasBankDetails || jobDetails?.is_private_student ? (
        <div className=" px-5 py-4 ">
          {(jobDetails?.hours || loading) && !jobDetails?.is_private_student ? (
            <LessonsProgress
              loading={loading}
              progress={
                jobDetails?.hours > 0
                  ? jobDetails?.scheduled_hours / jobDetails?.hours >= 1
                    ? 1
                    : jobDetails?.scheduled_hours / jobDetails?.hours
                  : 0
              }
              takenHoursResponse={takenHoursResponse}
            />
          ) : (
            <></>
          )}
          {warning ? (
            <>
              <Alert title={warning} colour="orange"></Alert>
            </>
          ) : (
            <></>
          )}

          {/* start:: new lesson button */}
          {lessons.length > 0 ? (
            <div className="my-6">
              <Button
                onClick={() => setShowAddLessonCard(true)}
                disabled={
                  jobDetails?.awaiting_payment != 0 ||
                  jobDetails?.removed_at != null ||
                  jobDetails?.completed_at != null
                }
                fullWidth
                size="large"
              >
                <span className="flex items-center py-1 text-base">
                  <PlusSmallIcon className="w-5 h-5" /> New Lesson
                </span>
              </Button>
            </div>
          ) : (
            <></>
          )}

          {/* end:: new lesson button */}
          {/* */}

          <>
            <div className="flex items-center justify-between my-6">
              <h3 className="text-sm">
                {" "}
                {loading ? (
                  <Skeleton width={100} />
                ) : (
                  <>
                    <b>{lessons?.length}</b> lessons booked
                  </>
                )}
              </h3>
            </div>
            {lessons.length == 0 && !loading ? (
              <>
                <EmptyState
                  title="There is no upcoming lessons"
                  Icon={ExclamationCircleIcon}
                />
                <div className="my-6">
                  <Button
                    onClick={() => setShowAddLessonCard(true)}
                    disabled={
                      jobDetails?.awaiting_payment != 0 ||
                      jobDetails?.removed_at != null ||
                      jobDetails?.completed_at != null
                    }
                    fullWidth
                    size="large"
                  >
                    <span className="flex items-center py-1 text-base">
                      <PlusSmallIcon className="w-5 h-5" /> New Lesson
                    </span>
                  </Button>
                </div>
              </>
            ) : (
              <div className="grid grid-cols-1 gap-4 mb-3">
                {" "}
                {loading ? (
                  <>
                    <StackedCardWithActionsMenu
                      loading={loading}
                    ></StackedCardWithActionsMenu>
                  </>
                ) : (
                  lessonsRows
                )}{" "}
              </div>
            )}
          </>
        </div>
      ) : (
        <>
          <EmptyState
            title="Please provide your bank details"
            Icon={ExclamationCircleIcon}
            description="so we can pay you when lessons begin"
          />
          <div className="my-6 px-5 py-4 ">
            <Button
              onClick={() => history.push("/ADI/profile/banking-details")}
              fullWidth
              size="large"
            >
              <span className="flex items-center py-1 text-base">
                Go to bank details
              </span>
            </Button>
          </div>
        </>
      )}{" "}
    </>
  );
};
