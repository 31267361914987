import { FC, useEffect, useState } from "react";
import TestCard from "../../shared/cards/TestCard";
import LinkItem from "../../shared/elements/LinkItem";
import DisclosureItem from "../../shared/elements/DisclosureItem";
import Slider from "react-slick";
import { getAdiDashboardTests } from "../../../../core/services/job-service";
import { Test } from "../../../../core/models/tests/test";
import { mapSubTitle } from "../../../mappers/testMapper";
import { RejectTestModal } from "../../test/RejectTestModal";
import { ConfirmTestModal } from "../../test/ConfirmTestModal";
import { TestStatusEnum } from "../../../../core/models/enums/test-status-enum";
import { AddTestResult } from "../../jobs/job-details/tabs/_modals/AddTestResult";
import { PlusIcon } from "@heroicons/react/24/outline";
import { LogTestStatus } from "../../jobs/job-details/tabs/_modals/LogTestStatus";

const TestsSummary: FC<any> = () => {
  const sliderSettings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3.25,
    slidesToScroll: 1.5,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 2.5,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1.65,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1.35,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1.15,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 350,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [tests, setTests] = useState<Test[]>();
  const [jobId, setJobId] = useState<string>();
  const [testCount, setTestCount] = useState<number>(0);
  const [test, setTest] = useState<any>();
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [openRejectDialog, setOpenRejectDialog] = useState<boolean>(false);
  const [showLogTestResultModal, setShowLogTestResultModal] = useState(false);
  const [showAddTestResultModal, setShowAddTestResultModal] = useState(false);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const openTest = urlSearchParams.get('open');

  useEffect(() => {
    LoadTests();
  }, []);

  const LoadTests = () => {
    (async () => {
      setIsLoading(true)
      const unconfirmedTestRequest = await getAdiDashboardTests().catch(() => { });
      let tests: Test[] = unconfirmedTestRequest?.results?.tests ?? [];
      let count = unconfirmedTestRequest?.results?.count ?? 0;
      setTestCount(count)
      setTests(tests)
      setIsLoading(false)
    })().catch(() => {
      setTestCount(0);
      setTests([]);
      setIsLoading(false);
    });
  };

  const actionButtons = (test: Test) => {
    let buttons: {
      text: React.ReactNode;
      onClick: () => void;
      color: "yellow" | "darkBlue" | "outline";
      disabled: boolean;
      size: string;
    }[] = [];
    if ((test.is_private == true || test.status === TestStatusEnum.PendingResult) && test.abilities?.can_fail && test.abilities?.can_pass) {
      buttons.push({
        text: (
          <div className="flex space-x-1 items-center pt-1 pb-0.5">
            <PlusIcon className="w-4 h-4" />
            <span className="text-xs">Log test result</span>
          </div>
        ),
        color: "outline",
        onClick:() => {
          openLogTestStatus(test);
        },
        disabled: submitting,
        size: "medium"
      });
    }
    if (test.abilities?.can_accept) {
      buttons.push({
        text: <span>Accept</span>,
        color: "yellow",
        onClick: () => {
          openConfirmModal(test);
        },
        disabled: submitting,
        size: "medium"
      });
    }
    if (test.abilities?.can_reject) {
      buttons.push({
        text: <span>Reject</span>,
        color: "outline",
        onClick: () => {
          openRejectModal(test);
        },
        disabled: submitting,
        size: "medium"
      });
    }
    return buttons;
  };

  const openLogTestStatus = (test: Test) => {
    setTest(test);
    setJobId(test.job_id);
    setShowLogTestResultModal(true);
  };

  const openConfirmModal = (test: Test) => {
    setTest(test);
    setOpenConfirmDialog(true);
  };

  const openRejectModal = (test: Test) => {
    setTest(test);
    setOpenRejectDialog(true);
  };

  const mapData = (test: Test) => {
    const data: any = [
      {
        icon: "pmf-icon-test",
        value: test?.location?.name ?? test.test_location ?? "",
      },
      {
        icon: "pmf-icon-clock",
        value: test?.booked_for_human,
      },
    ];

    if (test.deal_id) {
      data.push({
        icon: "pmf-icon-suitcase",
        value: `#${test.deal_id?.toString()}`,
        badge: true,
      });
    }

    return data;
  };

  return (
    <>
      <RejectTestModal
        test={test}
        refreshTests={LoadTests}
        open={openRejectDialog}
        closeModal={() => setOpenRejectDialog(false)}
      ></RejectTestModal>
      <ConfirmTestModal
        test={test}
        refreshTests={LoadTests}
        open={openConfirmDialog}
        closeModal={() => setOpenConfirmDialog(false)}
      ></ConfirmTestModal>
      <LogTestStatus
        show={showLogTestResultModal}
        onHide={() => {
          LoadTests();
          setShowLogTestResultModal(false);
        }}
        onDismiss={() => {
          setShowLogTestResultModal(false);
        }}
        openAddTestResult={() => setShowAddTestResultModal(true)}
        jobId={jobId}
        jobRef={test?.is_private_student ? null : test?.deal_id}
        testId= {test?.id}
        is_private_student={test?.is_private_student}
      />
      <AddTestResult
        show={showAddTestResultModal}
        onHide={() => {
          setShowAddTestResultModal(false);
          LoadTests();
        }}
        jobDetails={{
          is_private_student: test?.is_private_student,
          job_ref: test?.is_private_student ? null : test?.deal_id,
          job: { id: jobId },
        }}
        setJobDetails={() => {}}
      />
      <DisclosureItem
        title="Tests"
        count={testCount}
        loading={isLoading}
        linkName="tests"
        linkURL="/ADI/tests"
        expanded={openTest === "test"}
      >
        <Slider {...sliderSettings}>
          {tests?.map((test, i) => (
            <div className="pr-3 h-[220px]" key={i}>
              <TestCard
                name={test.student?.full_name ?? test.student_name}
                data={mapData(test)}
                buttons={actionButtons(test)}
                pmfIcon={test.created_by_type === "passmefast"}
                subTitle={mapSubTitle(test.status)}
              />
            </div>
          ))}
        </Slider>

        <div className="flex justify-end w-full mt-[24px]">
          <LinkItem url="/ADI/tests" seeAllIcon={true}>
            <span className="text-sm">See all tests</span>
          </LinkItem>
        </div>
      </DisclosureItem>
    </>
  );
};

export { TestsSummary };
