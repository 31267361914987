import { FC, useState } from "react";
import Modal from "../../shared/overlays/Modal";
import { CustomJobReminderModel } from "../../../../core/models/custom-job-reminder-model";
import toast from "react-hot-toast";
import Notification from "../../shared/overlays/Notification";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { deleteCutomJobAlert } from "../../../../core/services/adi-service";

interface IProps {
  show: any;
  onHide: any;
  alertInfo: CustomJobReminderModel;
  refreshParent: () => void;
}

const DeleteJobReminderDialog: FC<IProps> = ({
  show,
  onHide,
  alertInfo,
  refreshParent,
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const deleteReminder = async () => {
    setLoading(true);
    const isDeleted = await deleteCutomJobAlert(alertInfo?.id!).catch(() => {
      setLoading(false);
    });

    if (isDeleted) {
      setLoading(false);
      onHide();
      refreshParent();
      toast.custom(
        () => (
          <Notification
            colour="green"
            title={"Alert is deleted successfully!"}
            description={""}
            Icon={ExclamationTriangleIcon}
          />
        ),
        {
          duration: 5000,
          position: "top-center",
        }
      );
    }
  };

  return (
    <Modal
      open={show}
      onClose={onHide}
      onSubmit={deleteReminder}
      submitText="Remove"
      closeText="Cancel"
      title="Remove alert"
      description={"Are you sure you want to remove this custom job alert?"}
      disabled={loading}
    ></Modal>
  );
};

export { DeleteJobReminderDialog };
