import { FC, useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../helpers";
import Button from "../../components/shared/elements/Button";
import PageHeader from "../../components/shared/elements/PageHeader";

const GoShorty: FC = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [imageSrc, setImageSrc] = useState<string>("default-image.jpg"); // Set the default image source

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    };

    // Attach the event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures that the effect runs only once during component mount

  useEffect(() => {
    // Check window size and update image source accordingly
    if (windowSize.width > 768) {
      setImageSrc("Extra-Earning-lg.svg");
    } else {
      setImageSrc("Extra-Earning-sm.svg");
    }
  }, [windowSize]);

  useEffect(() => {
    document.title = `ADI Network App | GoShorty | ADI Network`;
  }, []);

  return (
    <>
      <div className="mb-5">
        <PageHeader
          title="Earn commission with GoShorty Learner Insurance"
          backToURL="/ADI/more"
        />
      </div>
      <div className="bg-white py-4 px-5 text-center">
        <div className="flex justify-center w-full">
          <img
            src={toAbsoluteUrl(`/assets/media/goshorty/` + imageSrc)}
            alt="GoShorty: short-term learner driver insurance made simple."
          />
        </div>

        <div className="md:px-6 pt-3">
          <p className="py-3">
            ADI Network have teamed up with GoShorty to help you maximise your
            pass rate and top up your earnings by a potential{" "}
            <span className="font-bold primary-highlight">
              £237.60 per learner
            </span>{" "}
            , with no limits on how many people you can refer.
          </p>
          <p className="py-3">
            You'll know that private practice is key to a learner's success.
            It's important for them to get behind the wheel at different times
            and in different conditions. In fact, the DVSA recommends a sizable
            22 hours of private practice on top of professional driving lessons.
          </p>
          <p className="py-3 font-bold primary-highlight">
            So, ADI Network has partnered with GoShorty to give you the
            opportunity to boost your earnings and your pass rate.{" "}
          </p>

          <div className="flex flex-col items-center">
            <Button
              className="mt-6 mb-6 md:px-24 px-14"
              size="large"
              onClick={() =>
                window.open(
                  "https://app.hellosign.com/s/GAU7XFTe",
                  "_blank",
                  "noreferrer"
                )
              }
              fitWidth
            >
              <span className="flex items-center py-1">GET STARTED</span>
            </Button>
          </div>
        </div>

        <div className="md:px-6 pb-10 font-weight-500">
          <p className="py-3">
            GoShorty’s Learner Driver Practice Insurance is the ideal way for
            your learners to practice between lessons — safe in the knowledge
            that they’re fully insured.
          </p>
          <p className="py-3">
            Learners can get insurance from GoShorty for as little as an hour or
            as long as 6 months, and it takes just two minutes to get a quote.
          </p>
          <p className="py-3">
            Thanks to our partnership with GoShorty, you earn up to £237.60
            commission every time you refer somebody to purchase their Learner
            Driver Insurance.
          </p>
        </div>

        <div className="md:px-6 pb-10 font-weight-500">
          <p className="py-3 text-3xl font-bold primary-highlight">
            How to sign up to GoShorty’s commission scheme
          </p>
          <p className="py-3">
            Step 1: Tap the button below and enter your email address
          </p>
          <p className="py-3">
            Step 2: GoShorty will email you an agreement for you to sign
            digitally
          </p>
          <p className="py-3">
            Step 3: You’ll be sent your unique referral link to share with your
            learners
          </p>
          <p className="py-3">
            Step 4: When somebody purchases an insurance policy using your link,
            you’ll be paid up to 40% commission
          </p>

          <div className="flex flex-col items-center">
            <Button
              className="mt-6 mb-6 md:px-24 px-14"
              size="large"
              onClick={() =>
                window.open(
                  "https://app.hellosign.com/s/GAU7XFTe",
                  "_blank",
                  "noreferrer"
                )
              }
              fitWidth
            >
              <span className="flex items-center py-1">GET STARTED</span>
            </Button>
          </div>
        </div>

        <div className="md:px-6 pb-10 font-weight-500">
          <div className="flex pb-8">
            <img
              className="w-full"
              src={toAbsoluteUrl(`/assets/media/goshorty/GoShorty.png`)}
              alt="PassMeFast & GoShorty: Earn an extra £237.60 per student with GoShorty. Terms and conditions apply."
            />
          </div>
          <p className="pb-2 font-weight-400 small-print">
            By registering to GoShorty's commission scheme, you agree that:
          </p>
          <p className="pb-2 font-weight-400 small-print">
            1) ADI Network may share your data with GoShorty so that they can
            set you up in their commission scheme
          </p>
          <p className="pb-2 font-weight-400 small-print">
            2) GoShorty can contact you via telephone and/or email
          </p>
          <p className="pb-2 font-weight-400 small-print">
            3) Data may be shared between GoShorty & ADI Network so we can
            improve the partnership and our services to you
          </p>
          <p className="font-weight-400 small-print">
            4) You can unsubscribe from the commission scheme at any time by
            using the 'Unsubscribe' button on any email from GoShorty
          </p>
        </div>
      </div>
    </>
  );
};

export default GoShorty;
