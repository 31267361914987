import { NewJobsDetails } from "../../components/jobs/NewJobDetails";
import PageHeader from "../../components/shared/elements/PageHeader";
import { FC, useEffect } from "react";

const NewJobsDetailsWrapper: FC = () => {
  useEffect(() => {
    document.title = `ADI Network App | Jobs | ADI Network`;
  }, []);
    
  return (
    <>
      <div className="mb-5">
        <PageHeader
          title="Job details"
          backToURL="/ADI/new-jobs"
          storageKey="new_jobs_query"
        />
      </div>
      <NewJobsDetails />
    </>
  );
};

export { NewJobsDetailsWrapper };
