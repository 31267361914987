/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from "react";
import React from "react";
import { toAbsoluteUrl } from "../../../helpers";
import Skeleton from "@mui/material/Skeleton";
import { ClickAwayListener, Tooltip } from "@mui/material";

interface IGrayFullCardProp {
  title?: React.ReactNode;
  value?: string | null | undefined | React.ReactNode;
  topIcon?:
    | "pmf-icon-map-pin"
    | "pmf-icon-currency-pound"
    | "pmf-icon-calendar"
    | "pmf-icon-intensity"
    | "pmf-icon-test";
  smallCard?: boolean;
  loading?: boolean;
  desc?: string;
  info?: string;
  children?: React.ReactNode;
}

const GrayFullCard: FC<IGrayFullCardProp> = ({
  topIcon,
  title,
  value,
  smallCard,
  loading = false,
  desc,
  info,
  children,
}) => {
  const [openDesc, setOpenDesc] = useState(false);

  return (
    <>
      <div className="flex flex-col flex-1 rounded-[10px] bg-gray-100 h-full p-4">
        <div className="flex items-center w-full">
          {loading ? (
            <>
              <Skeleton width={20} className="me-2" />
              <Skeleton width={50} className="me-2" />
            </>
          ) : (
            <>
              <i className={` text-3xl me-3 ${topIcon}`}></i>
              <div className="grow">
                <h5 className="block text-sm tracking-normal text-darkBlue antialiased mb-0">
                  {title}
                </h5>
                {value &&
                <p
                  className={`${
                    smallCard ? "text-xs" : "text-base"
                  } block font-bold leading-relaxed text-inherit antialiased`}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (topIcon === "pmf-icon-map-pin")
                      window.open(
                        process.env.REACT_APP_GOOGLE_MAP_URL
                          ? process.env.REACT_APP_GOOGLE_MAP_URL + value
                          : "#",
                        "_blank"
                      );
                  }}
                >
                  {loading ? (
                    <Skeleton width={30} className="me-2" />
                  ) : (
                    <span className="flex flex-wrap items-center">
                      {value}
                      {desc && (
                        <ClickAwayListener
                          onClickAway={() => setOpenDesc(false)}
                        >
                          <Tooltip
                            title={desc}
                            arrow
                            placement="top"
                            open={openDesc}
                            componentsProps={{
                              tooltip: {
                                style: {
                                  fontSize: "10px",
                                  lineHeight: "18px",
                                  fontFamily: "Poppins, sans-serif",
                                  maxWidth: "250px",
                                  marginRight: "6px",
                                },
                              },
                            }}
                          >
                            <i
                              className="text-sm ms-1 pmf-icon-info cursor-pointer"
                              onClick={() => setOpenDesc(!openDesc)}
                            ></i>
                          </Tooltip>
                        </ClickAwayListener>
                      )}

                    {info && 
                        <div className="text-sm ms-2 font-normal">
                            {loading ? <Skeleton width={40} className="me-2" /> : <>{info}</>}
                        </div>
                    }

                    </span>
                  )}
                </p>
                }
            
              </div>
            </>
          )}
        </div>

        {children && <>
          { loading ? 
            <Skeleton width={70} className="me-2" />
            :
            <> {children} </>
          }
        </>
        }
      </div>
    </>
  );
};

export { GrayFullCard };
