import { FC, useState } from "react";
import {
  CreatePrivateStudentTest,
  UpdatePrivateStudentTest,
} from "../../../core/services/test-service";
import { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { useParams } from "react-router-dom";
import {
  getAllDays,
  getMonths,
  getYears,
} from "../../../core/services/date-service";
import { getTestCenters } from "../../../core/services/adi-service";
import { formatDate } from "../../helpers/DateHelper";
import Modal from "../shared/overlays/Modal";
import Input from "../shared/forms/Input";
import Dropdown from "../shared/forms/Dropdown";
import Alert from "../shared/overlays/Alert";
import { getAdiPrivateStudents } from "../../../core/services/job-service";
type Props = {
  show: any;
  onHide: any;
  testDetails?: any;
  studentId?: string;
  refreshParent?: any;
  isGeneralTest?: boolean;
};
const PrivateStudentTest: FC<Props> = ({
  show,
  onHide,
  testDetails,
  studentId,
  refreshParent,
  isGeneralTest = false,
}) => {
  const years = getYears(1, 0, true);
  const months = getMonths();
  const days = getAllDays();
  const { jobId }: any = useParams();
  const [testCenters, setTestCenters] = useState<Array<any>>([]);
  const [selectedTestCentre, setSelectedTestCentre] = useState<any>();
  const [year, setYear] = useState<any>("");
  const [month, setMonth] = useState<any>("");
  const [day, setDay] = useState<any>("");
  const [time, setTime] = useState<any>("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentJobs, setCurrentJobs] = useState<Array<any>>([]);
  const [error, setError] = useState<any>("");

  const updatePersonalSchema = Yup.object().shape(
    {
      student_id: Yup.string().required("Learner is required"),
      practical_location: Yup.string().nullable(),
      month: Yup.string().required("Month is required"),

      day: Yup.string().required("Day is required"),
      year: Yup.string().required("Year is required").nullable(),
      time: Yup.string().nullable(),
    },
    [
      ["day", "year"],
      ["time", "year"],
      ["month", "year"],
      ["day", "month"],
      ["time", "day"],
      ["time", "month"],
    ]
  );
  useEffect(() => {
    if(show)
      {
    setIsLoading(true);
    getTestCenters().then((res) => {
      let testCentersBinded = res.data.results.map((testCenter) => {
        if (testCenter.id === testDetails?.test_centre_id) {
          setSelectedTestCentre({
            label: testCenter.test_center_name,
            value: testCenter.id,
          });
          formik.setFieldValue("practical_location", testCenter.id);
        }
        return { label: testCenter.test_center_name, value: testCenter.id };
      });
      if (testDetails?.booked_for != null) {
        const monthValue =
          months[
            new Date(
              testDetails?.booked_for?.split(" ")[0]
                ? testDetails?.booked_for?.split(" ")[0]
                : ""
            ).getMonth()
          ]?.value;
        setMonth(monthValue);
        formik.setFieldValue("month", monthValue);
        const dayValue = new Date(
          testDetails?.booked_for?.split(" ")[0]
            ? testDetails?.booked_for?.split(" ")[0]
            : ""
        )
          .getDate()
          .toString();
        setDay(dayValue);
        formik.setFieldValue("day", dayValue);
        const yearValue = new Date(
          testDetails?.booked_for?.split(" ")[0]
            ? testDetails?.booked_for?.split(" ")[0]
            : ""
        )
          .getFullYear()
          .toString();
        setYear(yearValue);
        formik.setFieldValue("year", yearValue);

        const dateTime = new Date(testDetails?.booked_for);
        const timePart = moment(dateTime)
          .utc()
          .format("HH:mm")
          .toString()
          .split(" ")[0];
        formik.setFieldValue("time", timePart);
        setTime(timePart);
      } else {
        setYear("");
        setMonth("");
        setDay("");
        setTime("");
        setSelectedTestCentre(null);
      }
      setTestCenters(testCentersBinded);

      if (isGeneralTest) {
        getLearners();
      } else {
        setIsLoading(false);
      }
    });
  }
  }, [testDetails, show]);
  const formatDateTime = (values: any) => {
    if (formatDate(values.year, values.month, values.day) === null) return null;
    else if (!values.time)
      return formatDate(values.year, values.month, values.day);
    else
      return (
        formatDate(values.year, values.month, values.day) + " " + values.time
      );
  };
  function prepareSubmitData(values: any): any {
    if (testDetails?.id) {
      const submittedData: any = {
        test_centre_id: values.practical_location
          ? values.practical_location
          : null,
        test_date: formatDateTime(values),
        test_id: testDetails?.id ? testDetails?.id : null,
      };
      return submittedData;
    } else {
      const submittedData: any = {
        student_id: values?.student_id,
        test_centre_id: values.practical_location
          ? values.practical_location
          : null,
        test_date: formatDateTime(values),
        job_id: isGeneralTest
          ? currentJobs.filter(
              (job) => job.student_id === values?.student_id
            )[0].value
          : jobId,
      };
      return submittedData;
    }
  }
  const formik = useFormik({
    initialValues: {
      student_id: studentId,
      practical_location: "",
      month: month,
      day: day,
      year: year,
      time: time,
    },
    enableReinitialize: true,
    validationSchema: updatePersonalSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setStatus("");

      if (testDetails?.id) {
        await UpdatePrivateStudentTest(prepareSubmitData(values)).catch(
          (error) => {
            setSubmitting(false);
            if (error && error.errors && error.errors.length > 0) {
              setSubmitting(false);
              setStatus(error.errors[0].errorMessage);
            } else {
              setSubmitting(false);
              setStatus("Sorry, an error has occurred");
            }
          }
        );
        setSubmitting(false);
        if (refreshParent) {
          refreshParent();
        }
        close();
      } else {
        const preparedData = prepareSubmitData(values);
        const response = await CreatePrivateStudentTest(preparedData).catch(
          (err) => {
            setError(err?.message);
          }
        );
        if (response) {
          if (refreshParent) {
            refreshParent();
          }
          close();
        }
      }
    },
  });
  const close = () => {
    setSelectedTestCentre(null);
    formik.resetForm();
    onHide();
  };

  const getLearners = () => {
    getAdiPrivateStudents(true).then((res) => {
      const jobs = res?.data?.results;
      if (jobs) {
        const mappedJobs = Array.from(jobs).map((job: any) => {
          return {
            value: job.job_id,
            label: job.student_full_name,
            student_id: job.student_id,
          };
        });
        setCurrentJobs(mappedJobs);
        if (mappedJobs.length === 1) {
          formik.setFieldValue("student_id", mappedJobs[0]?.student_id);
        }
      }
      setIsLoading(false);
    });
  };

  return (
    // Your JSX elements

    <Modal
      open={show}
      title={
        isGeneralTest
          ? "Add Test(s)"
          : testDetails?.id
          ? "About practical test"
          : "Add practical test"
      }
      submitText={testDetails?.id ? "Save" : "Add"}
      onClose={close}
      closeText="Cancel"
      disableSubmit={formik.isSubmitting}
      onSubmit={formik.handleSubmit}
    >
      <form onSubmit={formik.handleSubmit}>
        <div>
          {formik.status && (
            <Alert colour="red" icon="pmf-icon-info" title={formik.status} />
          )}
          {error && <Alert colour="red" icon="pmf-icon-info" title={error} />}
          <>
            {isLoading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {isGeneralTest && (
                  <Dropdown
                    options={currentJobs}
                    label="Learner"
                    placeholder="Select learner"
                    error={
                      formik.touched.student_id && formik.errors.student_id
                        ? true
                        : false
                    }
                    errorMsg={formik.errors.student_id?.toString()}
                    required={true}
                    name="Learner_id"
                    disabled={formik.isSubmitting}
                    id="Learner_id"
                    isSearchable
                    value={{
                      value: formik.values.student_id,
                      label: currentJobs.filter(
                        (job) => job.student_id === formik.values.student_id
                      )[0]?.label,
                    }}
                    onChange={(e) => {
                      formik.setFieldValue("student_id", e?.student_id);
                    }}
                    onBlur={() => {
                      formik.setFieldTouched("student_id");
                    }}
                  />
                )}

                {/* begin::Form group */}
                <div className="grid grid-cols-3 gap-2 -mb-4">
                  <Dropdown
                    options={days}
                    label="Day"
                    placeholder="Day"
                    error={
                      formik.touched.day && formik.errors.day ? true : false
                    }
                    errorMsg={formik.errors.day?.toString()}
                    required={true}
                    name="days"
                    disabled={formik.isSubmitting}
                    id="days"
                    value={{
                      value: formik.values.day,
                      label:
                        formik.values.day === "" ? "Day" : formik.values.day,
                    }}
                    onChange={(e) => {
                      formik.setFieldValue("day", e?.value);
                    }}
                  />

                  <Dropdown
                    options={months}
                    label="Month"
                    placeholder="Month"
                    error={
                      formik.touched.month && formik.errors.month ? true : false
                    }
                    errorMsg={formik.errors.month?.toString()}
                    required={true}
                    name="months"
                    disabled={formik.isSubmitting}
                    id="months"
                    value={{
                      value: formik.values.month,
                      label:
                        formik.values.month === ""
                          ? "Month"
                          : formik.values.month,
                    }}
                    onChange={(e) => {
                      formik.setFieldValue("month", e?.value);
                    }}
                  />

                  <Dropdown
                    options={years}
                    label="Year"
                    placeholder="Year"
                    error={
                      formik.touched.year && formik.errors.year ? true : false
                    }
                    errorMsg={formik.errors.year?.toString()}
                    required={true}
                    name="years"
                    disabled={formik.isSubmitting}
                    id="years"
                    value={{
                      value: formik.values.year,
                      label:
                        formik.values.year === "" ? "Year" : formik.values.year,
                    }}
                    onChange={(e) => {
                      formik.setFieldValue("year", e?.value);
                    }}
                  />
                </div>
                {/* end:: From Group */}

                {/* begin::Form group */}
                <Input
                  {...formik.getFieldProps("time")}
                  type="time"
                  label="Time"
                  placeholder=""
                  error={
                    formik.touched.time && formik.errors.time ? true : false
                  }
                  errorMsg={formik.errors.time?.toString()}
                  required={false}
                  name="time"
                  disabled={formik.isSubmitting}
                  id="time"
                  onChange={(e) => {
                    if (e.target.valueAsDate === null)
                      formik.setFieldValue("time", "");
                    else
                      formik.setFieldValue(
                        "time",
                        moment(e.target.valueAsDate).utc().format("HH:mm:ss")
                      );
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Backspace" || e.key === "Delete")
                      formik.setFieldValue("time", "");
                  }}
                  className="w-full h-11 bg-white text-left"
                  smallSpace={true}
                />
                {/* end::Form group */}

                {/* start::Form group */}
                <Dropdown
                  options={testCenters}
                  label="Test centre"
                  placeholder=""
                  error={
                    formik.touched.practical_location &&
                    formik.errors.practical_location
                      ? true
                      : false
                  }
                  errorMsg={formik.errors.practical_location?.toString()}
                  required={false}
                  isSearchable={true}
                  name="practical_location"
                  disabled={formik.isSubmitting}
                  id="practical_location"
                  value={selectedTestCentre}
                  onChange={(e: any) => {
                    formik.setFieldValue("practical_location", e?.value);
                    setSelectedTestCentre({ label: e?.label, value: e?.value });
                  }}
                  placement="top"
                />
                {/* end::Form group */}
              </div>
            )}
          </>
        </div>
      </form>
    </Modal>
  );
};

export { PrivateStudentTest };
