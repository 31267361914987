import { useState } from "react";
import { FilterObject } from "../../../../core/models/filter-object";
import CheckboxInput from "../../shared/forms/CheckboxInput";
import Modal from "../../shared/overlays/Modal";
import { TransactionTypes } from "../../../../core/models/enums/transaction-type-enum";
import { useFormik } from "formik";
import { PaymentStatusFilter } from "../../../../core/models/enums/payment-status-filter-enum";
import { PaymentMethods } from "../../../../core/models/enums/payment-methods-enum";
import RadioInputGroup from "../../shared/forms/RadioInputGroup";
import DatePicker from 'react-datepicker';
import moment from "moment";
import RadioInput from "../../shared/forms/RadioInput";
import Input from "../../shared/forms/Input";


export type PaymentFilterModalProps = {
    filterObject: FilterObject,
    applyFilter: (searchObject: any) => void
    open: boolean,
    onHide: any
};

const PaymentFilterModal = ({ filterObject, open, applyFilter, onHide }: PaymentFilterModalProps) => {
    const [searchObject, setSearchObject] = useState<any>(filterObject.SearchObject)
    const [selectedRange, setSelectedRange] = useState<any>(2)
    const [startDate, setStartDate] = useState(
        filterObject.SearchObject.from_date
            ? new Date(filterObject.SearchObject.from_date)
            : null
    );
    const [endDate, setEndDate] = useState(
        filterObject.SearchObject.to_date
            ? new Date(filterObject.SearchObject.to_date)
            : null
    );

    const setFromDateValue = (e: any) => {
        if (e != null) {
            formik.setFieldValue(
                "from_date",
                e.getFullYear() + "-" + (e.getMonth() + 1) + "-" + e.getDate()
            );
        } else {
            formik.setFieldValue("from_date", null);
        }
        setStartDate(e);
    };

    const setToDateValue = (e: any) => {
        if (e != null) {
            formik.setFieldValue(
                "to_date",
                e.getFullYear() + "-" + (e.getMonth() + 1) + "-" + e.getDate()
            );
        } else {
            formik.setFieldValue("to_date", null);
        }
        setEndDate(e);
    };

    moment.updateLocale('en', {
        week: {
            dow: 1
        }
    });

    const dateFilter = [
        {
            label: 'Custom',
            value: 1,
        },
        {
            label: 'Set',
            value: 2,
        }];


    const dateFilterRanges = [
        { label: "Yesterday", value: [moment().subtract(1, 'day').startOf('day').format('YYYY-MM-DD'), moment().subtract(1, 'day').endOf('day').format('YYYY-MM-DD')] },
        { label: "Today", value: [moment().startOf('day').format('YYYY-MM-DD'), moment().endOf('day').format('YYYY-MM-DD')] },
        { label: "This week", value: [moment().startOf('week').format('YYYY-MM-DD'), moment().endOf('week').format('YYYY-MM-DD')] },
        { label: "Last week", value: [moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD'), moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD')] },
        { label: "This month", value: [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')] },
        { label: "Last month", value: [moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'), moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')] },
        { label: "This quarter", value: [moment().startOf('quarter').format('YYYY-MM-DD'), moment().endOf('quarter').format('YYYY-MM-DD')] },
        { label: "Last quarter", value: [moment().subtract(1, 'quarter').startOf('quarter').format('YYYY-MM-DD'), moment().subtract(1, 'quarter').endOf('quarter').format('YYYY-MM-DD')] },
        { label: "This year", value: [moment().startOf('year').format('YYYY-MM-DD'), moment().endOf('year').format('YYYY-MM-DD')] },
        { label: "Last year", value: [moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'), moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD')] },
    ]

    const clearFilter = () => {
        formik.setFieldValue("payment_method_id", searchObject.payment_method_id)
        formik.setFieldValue("status", searchObject.status)
        formik.setFieldValue("transaction_type_id", searchObject.transaction_type_id)
        formik.setFieldValue("from_date", moment().startOf('week').format('YYYY-MM-DD'))
        formik.setFieldValue("to_date", moment().endOf('week').format('YYYY-MM-DD'))
        formik.setFieldValue("custom_date", searchObject.custom_date)
        applyFilter(searchObject)
        setStartDate(new Date(moment().startOf('week').format('YYYY-MM-DD')));
        setEndDate(new Date(moment().endOf('week').format('YYYY-MM-DD')));
        
        onHide();
    }

    const formik = useFormik({
        initialValues: {
            transaction_type_id: searchObject.transaction_type_id,
            from_date: searchObject.from_date,
            to_date: searchObject.to_date,
            status: searchObject.status,
            payment_method_id: searchObject.payment_method_id,
            custom_date: searchObject.custom_date,
            filterName: searchObject.filterName
        },
        enableReinitialize: true,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            applyFilter(values);
            onHide();
        },
    });


    function setRangeDate(e: any) {
        formik.setFieldValue('from_date', dateFilterRanges[e].value[0])
        formik.setFieldValue('to_date', dateFilterRanges[e].value[1])
        setSelectedRange(e)
        formik.setFieldValue('filterName', dateFilterRanges[e].label)
    }

    function uniqueFilter(value: any, index: any, self: any) {
        return self.indexOf(value) === index;
    }

    const statusChange = (e: any, key: string, defaultValue: any, oldValue: any) => {
        if (e.includes(0)) {
            if (oldValue.includes(0)) {
                const index = e.indexOf(0, 0);
                if (index > -1) {
                    e.splice(index, 1);
                }
                e = e.filter(uniqueFilter);
                formik.setFieldValue(key, e);

            } else {
                formik.setFieldValue(key, defaultValue);
            }
        } else {
            if (oldValue.includes(0)) {
                formik.setFieldValue(key, []);
            } else {
                e = e.filter(uniqueFilter);
                formik.setFieldValue(key, e);
                if (key == "payment_method_id") {
                    if (e.includes(PaymentMethods.Paypal)) {
                        formik.setFieldValue("payment_method_id", [...e, PaymentMethods.Cheque]);
                    } else {
                        const index = e.indexOf(PaymentMethods.Cheque, 0);
                        if (index > -1) {
                            e.splice(index, 1);
                        }
                        e = e.filter(uniqueFilter);
                        formik.setFieldValue(key, e);
                    }
                }
            }
        }

    }

    return (
        <>
            <form noValidate>
                <Modal
                    title="Filter"
                    closeText="Clear filter"
                    submitText="Show Results"
                    onSubmit={() => { formik.submitForm() }}
                    onClose={clearFilter}
                    open={open}>

                    <RadioInputGroup
                        {...formik.getFieldProps('custom_date')}
                        name="dateFilter"
                        label="Date"
                        options={dateFilter}
                        disabled={false}
                        onChange={(e: any) => {
                            formik.setFieldValue('custom_date', e)
                            if (e === 1) {
                                formik.setFieldValue('filterName', 'Custom')
                            }
                        }}
                        value={formik.values.custom_date}
                    />

                    {formik.values.custom_date == 1 &&

                        <div className="flex flex-col">
                            <div className="grid grid-cols-2 gap-4 data-picker-parent w-100">
                                <DatePicker
                                    dateFormat={"dd/MM/yyyy"}
                                    isClearable={false}
                                    placeholderText="From"
                                    selected={startDate}
                                    name="from_date"
                                    id="from_date"
                                    onChange={(update) => {
                                        setFromDateValue(update);
                                    }}
                                    customInput={
                                        <Input
                                            type='text'
                                            label='From'
                                            placeholder='From'
                                            readonly={true}
                                            name='from' />
                                    }
                                    calendarClassName="w-full pt-5"
                                    clearButtonClassName="!h-fit !top-[42%] after:!bg-white after:ring-1 after:ring-darkBlue after:!text-darkBlue"
                                    popperClassName="!static !pt-0 w-full !translate-x-0"
                                    wrapperClassName="-mb-3"
                                />
                                <DatePicker
                                    dateFormat={"dd/MM/yyyy"}
                                    isClearable={false}
                                    placeholderText="To"
                                    selected={endDate}
                                    name="to_date"
                                    id="to_date"
                                    onChange={(update) => {
                                        setToDateValue(update);
                                    }}
                                    customInput={
                                        <Input
                                            type='text'
                                            label='To'
                                            placeholder='To'
                                            readonly={true}
                                            name='to' />
                                    }
                                    calendarClassName="w-full pt-5"
                                    clearButtonClassName="!h-fit !top-[42%] after:!bg-white after:ring-1 after:ring-darkBlue after:!text-darkBlue"
                                    popperClassName="!static !pt-0 w-full !translate-x-0"
                                    wrapperClassName="-mb-3"
                                />
                            </div>
                        </div>
                    }

                    {formik.values.custom_date == 2 &&
                        <div className="pt-1">
                            <RadioInput
                                name="date"
                                label=""
                                required={false}
                                disabled={false}
                                options={[
                                    { label: "Yesterday", value: 0 },
                                    { label: "Today", value: 1 },
                                    { label: "This week", value: 2 },
                                    { label: "Last week", value: 3 },
                                    { label: "This month", value: 4 },
                                    { label: "Last month", value: 5 },
                                    { label: "This quarter", value: 6 },
                                    { label: "Last quarter", value: 7 },
                                    { label: "This year", value: 8 },
                                    { label: "Last year", value: 9 },
                                ]}

                                onClick={(e) => {
                                    setRangeDate(e)
                                }}
                                value={selectedRange}
                            />
                        </div>
                    }

                    <div className="flex flex-col mb-4">
                        <CheckboxInput
                            label="Type"
                            name="transaction_type"
                            onChange={(e) => {
                                statusChange(e, "transaction_type_id", searchObject.transaction_type_id, formik.values.transaction_type_id)
                            }}
                            required={false}
                            options={[
                                { label: "All", value: 0 },
                                { label: "Money in", value: TransactionTypes.MoneyIn },
                                { label: "Money out", value: TransactionTypes.MoneyOut }
                            ]}
                            values={formik.values.transaction_type_id}
                            classNameOpt='grid grid-cols-2 justify-between'
                        />
                    </div>

                    <div className="flex flex-col mb-4">
                        <CheckboxInput
                            label="Payment Status"
                            name="payment_status"
                            onChange={(e) => {
                                statusChange(e, "status", searchObject.status, formik.values.status)
                            }}
                            required={false}
                            options={[
                                { label: "All", value: 0 },
                                { label: "Complete", value: PaymentStatusFilter.Complete },
                                { label: "Awaiting Learner Payment", value: PaymentStatusFilter.AwaitingStudent },
                                { label: "Payout Pending", value: PaymentStatusFilter.PayoutPending },
                                { label: "Cancelled", value: PaymentStatusFilter.Cancelled },
                                { label: "Refunded", value: PaymentStatusFilter.Refunded },
                            ]}
                            values={formik.values.status}
                            classNameOpt='grid grid-cols-2 justify-between'
                        />
                    </div>
                    <div className="flex flex-col mb-4">
                        <CheckboxInput
                            label="Payment Method"
                            name="payment_method"
                            onChange={(e) => {
                                statusChange(e, "payment_method_id", searchObject.payment_method_id, formik.values.payment_method_id)
                            }}
                            required={false}
                            options={[
                                { label: "All", value: 0 },
                                { label: "Card Payment", value: PaymentMethods.Card },
                                { label: "Cash Payment", value: PaymentMethods.Cash },
                                { label: "Bank Transfer", value: PaymentMethods.BankTransfer },
                                { label: "PassMeFast", value: PaymentMethods.Pmf },
                                { label: "Digital payment", value: PaymentMethods.Stripe },
                                { label: "Other", value: PaymentMethods.Paypal }
                            ]}
                            values={formik.values.payment_method_id}
                            classNameOpt='grid grid-cols-2 justify-between'
                        />
                    </div>
                </Modal >
            </form>
        </>)
}


export { PaymentFilterModal }
