import { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { DashboardWrapper } from "../content/pages/dashboard/DashboardWrapper";
import { MasterLayout } from "../content/layout/MasterLayout";
import { RootState } from "../../setup";
import { useSelector } from "react-redux";

import { LessonsWrapper } from "../content/pages/calendar/LessonsWrapper";
import { StudentsWrapper } from "../content/pages/students/StudentsWrapper";
import { CurrentJobsWrapper } from "../content/pages/jobs/CurrentJobsWrapper";
import { NewJobsWrapper } from "../content/pages/jobs/NewJobsWrapper";
import { SettingsWrapper } from "../content/pages/account-settings/SettingsWrapper";
import { TestsWrapper } from "../content/pages/tests/TestsWrapper";
import { PaymentsWrapper } from "../content/pages/finance/PaymentsWrapper";
import { JobDetailsWrapper } from "../content/pages/jobs/JobDetailsWrapper";
import { NewJobsDetailsWrapper } from "../content/pages/jobs/NewJobsDetailsWrapper";
import GoShortyWrapper from "../content/pages/networkperks/GoShortyWrapper";
import PassperksWrapper from "../content/pages/networkperks/PassperksWrapper";
import { DigitalPaymentsWrapper } from "../content/pages/digital-payments/DigitalPaymentsWrapper";
import CheckoutsWrapper from "../content/pages/digital-payments/CheckoutsWrapper";
import HelpWrapper from "../content/pages/help/HelpWrapper";
import { SharedDataProvider } from "../content/pages/providers/SharedDataProvider";
import CPDWrapper from "../content/pages/cpd/CPDWrapper";
import ScrollToTop from "../content/layout/ScrollToTop";
import ArticlePage from "../content/pages/cpd/articles/[uid]/ArticlePage";
import CPDSearchResult from "../content/pages/cpd/CPDSearchResult";
import CPDPage from "../content/pages/cpd/CPDPage";
import { MoreWrapper } from "../content/pages/more/MoreWrapper";
import { StandardsCheckWrapper } from "../content/pages/standards-check/StandardsCheckWrapper";
import { StandardsCheckDetails } from "../content/pages/standards-check/[uid]/StandardsCheckDetails";
import UpdatesWrapper from "../content/pages/help/UpdatesWrapper";
import AuthorPage from "../content/pages/cpd/authors/AuthorPage";
import CarLeasing from "../content/pages/car-leasing/CarLeasing";

export function PrivateRoutes() {
  return (
    <Suspense>
      <MasterLayoutWrapper />
    </Suspense>
  );
}

export function MasterLayoutWrapper() {
  // const AccountSettingsWrapper = lazy(() => import('../content/pages/account-settings/AccountSettingsWrapper'))
  // const JobDetailsWrapper = lazy(() => import('./../content/pages/jobs/JobDetailsWrapper'));
  // const HelpWrapper = lazy(() => import('./../content/pages/help/HelpWrapper'));
  // const SettingWrapper = lazy(() => import('./../content/pages/settings/SettingsWrapper'));
  // const CommunicationWrapper = lazy(() => import('./../content/pages/communication/CommunicationWrapper'));
  // const IntegrationWrapper = lazy(() => import('./../content/pages/integration/IntegrationWrapper'));
  // const personalData: PersonalDataModel = useSelector<RootState>(({ auth }) => auth.personalData, shallowEqual) as PersonalDataModel
  const showPayment = useSelector<RootState>(
    ({ auth }) => auth.personalData?.is_stripe_connect_view_enabled
  );
  return (
    <div className="h-[100vh]">
      <SharedDataProvider>
        <MasterLayout>
          <ScrollToTop />
          <Switch>
            <Route
              path="/ADI/new-jobs"
              exact={true}
              component={NewJobsWrapper}
            />
            <Route
              path="/ADI/new-jobs/details/:jobId"
              component={NewJobsDetailsWrapper}
            />
            <Route path="/ADI/profile" component={SettingsWrapper} />
            <Route path="/ADI/dashboard" component={DashboardWrapper} />
            <Route path="/ADI/lessons" component={LessonsWrapper} />
            <Route path="/ADI/current-jobs" component={CurrentJobsWrapper} />
            <Route path="/ADI/students" component={StudentsWrapper} />
            <Route path="/ADI/settings" component={SettingsWrapper} />
            <Route path="/ADI/tests" component={TestsWrapper} />
            <Route
              path="/ADI/payments"
              exact={true}
              component={PaymentsWrapper}
            />
            <Route path="/ADI/more" exact={true} component={MoreWrapper} />
            <Route path="/ADI/car-leasing" exact component={CarLeasing} />
            <Route
              path="/ADI/checkouts/:checkoutId"
              exact={true}
              component={CheckoutsWrapper}
            />
            <Route path="/ADI/help" component={HelpWrapper} />
            <Route path="/ADI/updates" component={UpdatesWrapper} />
            <Route
              path="/ADI/job-details/:jobId"
              component={JobDetailsWrapper}
            />
            <Route
              path="/ADI/pass-perks/discounts-and-rewards"
              component={PassperksWrapper}
            />
            <Route
              path="/ADI/pass-perks/goshorty"
              component={GoShortyWrapper}
            />
            <Route path="/ADI/cpd" exact={true} component={CPDWrapper} />
            <Route
              path="/ADI/standards-check-indicators"
              exact={true}
              component={StandardsCheckWrapper}
            />
            <Route
              path="/ADI/standards-check-indicators/:uid"
              component={StandardsCheckDetails}
            />
            <Route
              path="/ADI/cpd/search-result/:searchVal"
              exact={true}
              component={CPDSearchResult}
            />
            <Route path="/ADI/cpd/article/:uid" component={ArticlePage} />
            <Route path="/ADI/cpd/authors/:authorUid" component={AuthorPage} />
            <Route
              path="/ADI/professional-development/:uid"
              exact={true}
              component={CPDPage}
            />
            {showPayment ? (
              <Route
                path="/ADI/digital-payments"
                exact={true}
                component={DigitalPaymentsWrapper}
              />
            ) : null}
            <Redirect from="/ADI" exact={true} to="/ADI/dashboard" />
            <Redirect to="/ADI/dashboard" />
          </Switch>
        </MasterLayout>
      </SharedDataProvider>
    </div>
  );
}
