import { FC, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { createClient } from "../../../../../prismic";
import { filter } from "@prismicio/client";
import { PlusIcon } from "@heroicons/react/20/solid";
import Button from "../../../components/shared/elements/Button";
import { useQuery } from "../../../helpers/QueryParamsHelper";
import VideoTile from "../_shared/VideoTile";
import { saveAdiClickedPage } from "../../../../core/services/adi-service";
import { VideoModal } from "../videos/VideoModal";

type Props = {
  authorId: string;
};

const AuthorVideosList: FC<Props> = ({ authorId }) => {
  const history = useHistory();
  const [videos, setVideos] = useState<Array<any>>([]);
  const [totalResults, setTotalResults] = useState<number>(0);
  const [resultsSize, setResultsSize] = useState<number>(0);
  const query = useQuery();
  const [selectedVideo, setSelectedVideo] = useState<any>();
  const [show, setShow] = useState(false);
  const [pageCounter, setPageCounter] = useState(+(query.get("page") ?? 20));
  const handleClose = () => setShow(false);
  const loadMoreVideos = async () => {
    if (resultsSize < totalResults) {
      setPageCounter(pageCounter + 20);
    } else {
      setPageCounter(totalResults);
    }
  };

  const adiClickedPage = async (e: any) => {
    await saveAdiClickedPage(e).catch(() => {});
  };

  const openVideoModal = (video?: any) => {
    setSelectedVideo(video);
    query.append("video", video?.uid);
    history.replace({
      search: query.toString(),
    });
    setShow(true);
  };

  useEffect(() => {
    fetchVideos();
  }, [pageCounter, createClient, authorId]);

  const fetchVideos = async () => {
    try {
      if (authorId) {
        const videosResponse = await createClient.get({
          fetchLinks: "category.name",
          filters: [
            filter.at("document.type", "videos"),
            filter.any("my.videos.author_data", [authorId]),
          ],
          orderings: {
            field: "document.first_publication_date",
            direction: "asc",
          },
          pageSize: pageCounter,
        });

        const mappedVideos = videosResponse?.results?.map((result) => ({
          id: result.id,
          // Map additional properties here
          thumbImage: result.data.thumb_img,
          categoryName: result.data.categories_link.data.name,
          videoTitle: result.data.title,
          videoShortDescription:
            result.data.blurb.length > 0
              ? result.data.blurb
                  .map((paragraph: any) => paragraph.text)
                  .join(" ")
              : "",
          authorName: result.data.author,
          releaseDate: result.data.date,
          videoURL: result.data.vidurl.url,
          duration: result.data.duration,
          uid: result.uid,
          mostPopular: result.data.set_in_most_popular,
        }));

        setVideos(mappedVideos);
        setTotalResults(videosResponse.total_results_size);
        setResultsSize(videosResponse.results_size);
      }
    } catch (error) {
      console.error("Error while fetching articles:", error);
    }
  };

  return (
    <>
      <>
        {/* <ArticleTile loading={true}/> */}
        <div className="grid grid-cols-1 gap-y-9 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-8 p-3">
          {videos?.map((item, index) => (
            <VideoTile
              key={index}
              imgURL={item.thumbImage}
              vidDuration={item.duration}
              author={{}}
              title={item.videoTitle}
              uid={item.uid}
              category={item.categoryName}
              onShowVideoClick={() => {
                adiClickedPage({
                  page_url: window.location.pathname + "/video",
                  page_name: item.videoTitle,
                });
                openVideoModal(item);
              }}
            />
          ))}
        </div>

        <div className="grid grid-cols-6 gap-4 mx-auto w-full mt-6">
          {resultsSize < totalResults && (
            //Load more button
            <Button
              className="col-span-4 col-start-2"
              colour="outline"
              onClick={loadMoreVideos}
              size="large"
            >
              <div className="flex items-center text-lg font-semibold">
                LOAD MORE <PlusIcon className="w-5 h-5 ms-2" />
              </div>
            </Button>
          )}
          <p className="col-span-6 text-[#616161] text-sm text-center mb-5">
            Showing {resultsSize} of {totalResults}
          </p>
        </div>

        <VideoModal
          title={selectedVideo?.videoTitle}
          show={show}
          onHide={handleClose}
          url={selectedVideo?.videoURL}
        ></VideoModal>
      </>
    </>
  );
};

export default AuthorVideosList;
