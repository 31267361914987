import { FC, useEffect, useState } from "react";
import Modal from "../../components/shared/overlays/Modal";
import RadioInput from "../../components/shared/forms/RadioInput";
import { getAdiJobsCount } from "../../../core/services/job-service";

type Props = {
  show: any;
  onHide: any;
  isBank: boolean;
  completeSteps: any;
  onAcceptAnyway: any;
  setSort: any;
};

const SortJobsDialog: FC<Props> = ({
  show,
  onHide,
  isBank,
  completeSteps,
  onAcceptAnyway,
  setSort,
}) => {
  const [options, setOptions] = useState([
    {
      value: "most_relevant,DESC",
      label: "Most Relevant: Most to Least",
      tooltip: "Sort by most relevant jobs based on jobs you've taken recently",
    },
    {
      value: "total_rate,DESC",
      label: "Total Earnings: High to Low",
    },
    {
      value: "total_rate,ASC",
      label: "Total Earnings: Low to High",
    },
    {
      value: "rate_per_hour,DESC",
      label: "Hourly Rate: High to Low",
    },
    {
      value: "rate_per_hour,ASC",
      label: "Hourly Rate: Low to High",
    },
    {
      value: "displayed_hours,DESC",
      label: "Course Hours: High to Low",
    },
    {
      value: "displayed_hours,ASC",
      label: "Course Hours: Low to High",
    },
    {
      value: "test_date,ASC",
      label: "Test Date: Soonest to Furthest",
    },
    {
      value: "test_date,DESC",
      label: "Test Date: Furthest to Soonest",
    },
    {
      value: "distance,ASC",
      label: "Distance: Closest First",
    },
  ]);

  useEffect(() => {
    (async () => {
      const res = await getAdiJobsCount().catch(() => {
        return;
      });
      if (res) {
        if (res.results <= 0) {
          setOptions((options) => options.slice(1));
        }
      }
    })();
  }, []);
  const [checkedValue, setCheckedValue] = useState("");
  const clear = () => {
    setCheckedValue("");
    setSort("", "");
    onHide();
  };

  const handleCheck = (event: any) => {
    setCheckedValue(event);
  };
  const sort = () => {
    if (checkedValue != "") {
      let checkedSort = checkedValue.split(/[,]+/);
      setSort(checkedSort[0], checkedSort[1]);
    }

    onHide();
  };

  return (
    <>
      <Modal
        open={show}
        onClose={() => {
          clear();
        }}
        onSubmit={() => {
          sort();
        }}
        onDismiss={onHide}
        closeText={"Clear"}
        submitText={"Apply"}
        title={"Sort"}
        icon={"sort"}
      >
        <div className="-mt-2">
          <RadioInput
            label=""
            fullWidth
            name="has_brand_advertisement"
            options={options}
            error={false}
            onClick={(value) => handleCheck(value)}
            value={checkedValue}
            required={false}
            disabled={false}
          />
        </div>
      </Modal>
    </>
  );
};

export { SortJobsDialog };
