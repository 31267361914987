import { FC, useEffect, useState } from 'react'

import { completePrivateLearner, getCompletePrivateLearnerReasons } from '../../../../core/services/job-service'
import Modal from '../../shared/overlays/Modal'
import RadioInput from '../../shared/forms/RadioInput'
import Button from '../../shared/elements/Button';

interface IMarkPrivateLearnerAsCompletedDialogProps {
    show: any
    onClose: any
    jobId: string
}

const MarkPrivateLearnerAsCompletedDialog: FC<IMarkPrivateLearnerAsCompletedDialogProps> = (props) => {
    const [reasons, setReasons] = useState<any>()
    const [isSubmitting, setSubmitting] = useState<boolean>(false)
    const [isSubmitted, setSubmitted] = useState<boolean>(false)
    const [selectedReason, setSelectedReason] = useState<any>()
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)


    useEffect(() => {
        (async () => {
            const reasonsResponse = await getCompletePrivateLearnerReasons().catch(() => { })
            setReasons(Array.from(reasonsResponse?.data?.results).map((reason: any) => {
                return {
                    value: reason.id,
                    label: reason.name
                }
            }))
        })()
    }, []);

    function closeModal() {
        props.onClose()
        setShowConfirmModal(false);
    }

    function onSubmit() {
        (async () => {
            setSubmitting(true)
            if (selectedReason !== undefined) {
                setSubmitted(true)
                const rejectTestResponse = await completePrivateLearner({
                    reason_id: selectedReason,
                    job_id: props.jobId
                }).catch((response: any) => {
                    setSubmitting(false)
                });
                if (rejectTestResponse?.status === 200) {
                    setSubmitting(false)
                    closeModal()
                }
            }
        })()
    }

    function handleOpenConfirm(){
        setSubmitting(true)
        if (selectedReason !== undefined) {
            setShowConfirmModal(true);
        }
    }



    return (
        <>
            <Modal
                open={props.show}
                onClose={() => closeModal()}
                onSubmit={() =>handleOpenConfirm()}
                submitText='Save'
                closeText='Cancel'
                title='Mark as completed'
                disabled={isSubmitted}
            >
                {showConfirmModal === false ?
                    <div className='d-flex flex-column align-items-start justify-content-start'>
                        <RadioInput name='reasons' label='Select reason'
                            options={reasons}
                            value={selectedReason}
                            onClick={(reason) => setSelectedReason(reason)}
                            required={true}
                            errorMsg={"You should select a reason"}
                            disabled={false}
                            error={isSubmitting && selectedReason === undefined}
                            fullWidth
                        />
                    </div>
                    :
                    <div className="modal fixed bottom-0 left-0 top-0 right-0 w-100 z-[1000]">
                        <div className="w-full z-[55] fixed top-0 right-0 left-0 bottom-0 bg-black bg-opacity-25 opacity-100"></div>
                        <div
                            className={`px-5 fixed bottom-0 left-0 right-0 py-6 bg-white z-[555] max-h-[90vh] rounded-t-2xl rounded-b-none sm:rounded-b-2xl  shadow-lg`}
                        >
                            <p className="text-sm mb-2 font-bold">
                                Please confirm you wish to mark this learner as complete
                            </p>
                            <div className="mt-4 flex items-center justify-between space-x-2">
                                <Button size="large" onClick={() => onSubmit()}>Confirm</Button>
                                <Button size="large" colour="outline" onClick={() => {closeModal()}}>Cancel</Button>
                            </div>
                        </div>
                    </div>

                }
            </Modal>



        </>

    )
}

export default MarkPrivateLearnerAsCompletedDialog
