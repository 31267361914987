import { PrismicRichText } from "@prismicio/react";
import { FC, useEffect, useState } from "react";
import { useHistory } from "react-router";
import CPDHeader from "./_shared/CPDHeader";
import PageHeader from "../../components/shared/elements/PageHeader";
import CPDBreakCard from "./_shared/CPDBreakCard";
import { createClient } from "../../../../prismic";
import { useParams } from "react-router-dom";

const CPDPage: FC = () => {
  const history = useHistory();
  const [pageContent, setPageContent] = useState<any>();
  const params = useParams();
  const paramValues = Object.values(params);
  const lastParam = paramValues[paramValues.length - 1] as string;

  useEffect(() => {
    //console.log(lastParam, params, paramValues);
    // React advises to declare the async function directly inside useEffect
    async function getPage() {
      const response = await createClient.getByUID("page", lastParam);
      //console.log(response); // Log the response object
      setPageContent(response);
    }
    getPage();
  }, [lastParam]);

  return (
    <>
      <div className="mb-2">
        <PageHeader title={pageContent?.data.title} backToURL="/ADI/more" />
      </div>

      {pageContent?.data.content.length === 1 &&
      pageContent?.data.content[0].text === "" ? (
        <>
          <CPDHeader />
        </>
      ) : (
        <>
          <div className=" px-5 lg:px-6 pt-10">
            <PrismicRichText
              field={pageContent?.data.content}
              components={{
                list: ({ children }) => (
                  <ul className="list-disc ps-6 mt-3">{children}</ul>
                ),
                oList: ({ children }) => (
                  <ol className="list-decimal ps-6 mt-3">{children}</ol>
                ),
                paragraph: ({ children }) => (
                  <p className="mt-2 text-base leading-6">{children}</p>
                ),
                heading3: ({ children }) => (
                  <h3 className=" mt-12 text-2xl font-titlef font-bold">
                    {children}
                  </h3>
                ),
                heading4: ({ children }) => (
                  <h4 className="mt-12 text-xl font-titlef font-bold">
                    {children}
                  </h4>
                ),
              }}
            />
          </div>
        </>
      )}

      {/* <CPDHeader /> */}

      <div className="px-5 pb-5 -mt-5">
        {/* start:: Break Card 2 */}
        <CPDBreakCard
          imgURL={"/assets/media/CPD/course-around.svg"}
          title={<>Find available courses local to you</>}
          desc=" Lessons paid upfront & tests booked in advance"
          button={{
            text: "Get work for free",
            onClick: () => history.push(`/ADI/new-jobs`),
            color: "yellow",
            disabled: false,
          }}
        />
        {/* start:: Break Card 2 */}
      </div>
    </>
  );
};

export default CPDPage;
