import { RadioGroup } from "@headlessui/react";
import clsx from "clsx";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { toAbsoluteUrl } from "../../../helpers";

interface IProps {
  label: string;
  name: string;
  options: Array<{
    value: any;
    label: string;
    img?: string;
    nonselectImg?: string | undefined;
    show?: boolean;
  }>;
  onChange: (value: any) => void;
  value: string | number | undefined | null | any;
  disabled: boolean;
  error?: boolean;
  errorMsg?: string;
  required?: boolean;
  colour?: string;
  centraliseItems?: boolean;
}

export default function RadioInputGroup({
  label,
  name,
  value,
  options = [],
  onChange,
  disabled = false,
  error = false,
  errorMsg = "This field is required",
  required,
  colour,
  centraliseItems,
}: IProps) {
  var optionsLength = centraliseItems
    ? options.filter((option) => option.show).length
    : options.length;

  var containerGridClass =
    centraliseItems && optionsLength < 3
      ? "grid-cols-" + (optionsLength < 1 ? 1 : optionsLength) + " gap-2.5"
      : centraliseItems && optionsLength >= 3
      ? "grid-cols-3 gap-2.5"
      : optionsLength > 2
      ? "grid-cols-3 gap-2.5"
      : "grid-cols-2 gap-4";

  return (
    <>
      <RadioGroup
        value={value}
        onChange={onChange}
        name={name}
        className="mb-[2px]"
      >
        <span>
          <span className="text-sm font-medium text-darkBlue">
            {label}
            {required && <span className="text-sm text-red-500">*</span>}
          </span>
        </span>

        <div className={`${containerGridClass} mt-1 grid`}>
          {options.map((option, index) =>
            option.show === false ? (
              <></>
            ) : (
              <div
                key={index}
                className={`relative ${
                  colour == "gray"
                    ? "flex flex-col flex-1 rounded-[10px] bg-gray-100 h-full p-2"
                    : ""
                }`}
              >
                <RadioGroup.Option
                  key={option.value}
                  value={option.value}
                  disabled={disabled}
                  as="input"
                  type="radio"
                  name={name}
                  className="absolute z-[1] opacity-0 h-full w-full cursor-pointer"
                />
                <RadioGroup.Label
                  as="label"
                  htmlFor={name}
                  className={`${
                    option.img || option.nonselectImg
                      ? "sm:p-3.5 px-0 py-3.5 h-full"
                      : "px-3.5 py-2.5 h-[44px]"
                  } ${
                    !(value === option?.value) ||
                    option?.nonselectImg ||
                    option?.img
                      ? `${colour != "gray" ? "bg-white" : ""}`
                      : "bg-darkBlue"
                  } relative flex rounded-sm focus:outline-none cursor-pointer items-center`}
                >
                  <span
                    className={`${
                      option.img || option.nonselectImg
                        ? "items-center text-center"
                        : "items-start"
                    } flex flex-1 flex-col justify-center`}
                  >
                    {(option.img || option.nonselectImg) && (
                      <img
                        alt={option.label}
                        src={toAbsoluteUrl(
                          !(value === option?.value) && option?.nonselectImg
                            ? option?.nonselectImg
                            : option.img
                            ? option.img
                            : ""
                        )}
                        className="px-0 w-[30px] h-auto mb-2"
                      />
                    )}

                    <span
                      className={`${
                        !(value === option?.value) ||
                        option.img ||
                        option.nonselectImg
                          ? "text-darkBlue"
                          : "text-white"
                      } block text-xs font-medium leading-3`}
                    >
                      {option.label}
                    </span>
                  </span>
                  <CheckCircleIcon
                    className={clsx(
                      !(value === option?.value) ? "invisible" : "visible",
                      option?.nonselectImg || option?.img
                        ? "absolute right-2 top-1"
                        : "",
                      "h-4 w-4 text-darkBlue"
                    )}
                    aria-hidden="true"
                  />
                  {!(option?.nonselectImg || option?.img) && (
                    <span
                      className={clsx(
                        value === option?.value
                          ? "bg-darkBlue border-transparent ring-2 ring-offset-2 ring-darkBlue"
                          : "bg-white border-transparent ring-1 ring-offset-1 ring-gray-300",
                        "mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"
                      )}
                      aria-hidden="true"
                    >
                      <span className="rounded-full bg-white w-2 h-2" />
                    </span>
                  )}
                  <span
                    className={clsx(
                      value === option?.value
                        ? "border-darkBlue"
                        : "border-gray-300",
                      `pointer-events-none w-full h-full appearance-none absolute top-0 -bottom-px left-0 -right-px rounded-sm ${
                        colour != "gray" ? "border" : ""
                      }`
                    )}
                    aria-hidden="true"
                  />
                </RadioGroup.Label>
              </div>
            )
          )}
        </div>
      </RadioGroup>

      {error && (
        <p className="text-sm text-red-600" id="error">
          {errorMsg}
        </p>
      )}
    </>
  );
}
