import { FC, useEffect, useState } from "react";
import DisclosureItem from "../../shared/elements/DisclosureItem";
import Slider from "react-slick";
import AddNewEmptyState from "../../shared/elements/AddNewEmptyState";
import LinkItem from "../../shared/elements/LinkItem";
import { ResendStudentPaymentLink, getPendingStudentPayment } from "../../../../core/services/connect-service";
import { PaymentCard } from "../../shared/cards/PaymentCard";
import toast, { Toaster } from 'react-hot-toast'
import Notification from '../../shared/overlays/Notification'
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
const PaymentSummary: FC<any> = ({ }) => {
  const [isLoading, setIsloading] = useState<boolean>(true);
  const [paymentsList, setPaymentList] = useState<Array<any>>([]);
  const [paymentsCount, setPaymentsCount] = useState<any>();

 var settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3.25,
    slidesToScroll: 1.5,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 2.5,
        }
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1.65,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1.35,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1.15,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 350,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  };
  const getDashboardPendingStudentPayment = () => {
   // setIsloading(true)
    getPendingStudentPayment()
      .then((res) => {
        setPaymentList(res?.data?.data)
        setPaymentsCount(res?.data?.total_records)
        setIsloading(false)
      })
      .catch(() => {
        setIsloading(false)
      })
  };
  const ResendStudentPaymentLinkClicked = (id: any) => {
    setIsloading(true)
    ResendStudentPaymentLink(id)
      .then((res) => {
        getDashboardPendingStudentPayment();
        setIsloading(false);
        toast.custom(
          () => (
              <Notification
                  colour="green"
                  title={"Payment link has been sent successfully"}
                  description={""}
                  Icon={ExclamationTriangleIcon}
              />
          ),
          {
              duration: 5000,
              position: "top-center",
          }
      );
        
      })
      .catch(() => {
        toast.custom(
          () => (
              <Notification
              colour="red"
              title={"Some thing went wrong"}
                  description={""}
                  Icon={ExclamationTriangleIcon}
              />
          ),
          {
              duration: 5000,
              position: "top-center",
          }
      );
        setIsloading(false)
      })
  };
  useEffect(() => {
    getDashboardPendingStudentPayment();
  }, []); 

  

  return (
    <>
     <Toaster />
      <DisclosureItem title="Payment" loading={isLoading} count={paymentsCount} linkName="payments" linkURL="/ADI/payments">
      <Slider {...settings}>
      {paymentsList.map((item, index) => {
        return (  
          <div key={index} className="pr-3">
           <PaymentCard 
            title={"£"+(Number.isInteger(+(item?.adi_receives/100)) ?  (item?.adi_receives/100) : (+(item?.adi_receives/100))?.toFixed(2))}
            subTitle={item?.status_human} data={[
                  {
                    icon: 'pmf-icon-clock',
                    value: item?.created_at_datetime_formatted ?? '',
                  },
                  {
                    icon: 'pmf-icon-user',
                    value: item?.student_name,
                      linkURL : "/ADI/students"
                  },
                ]}
                buttons={[
                  {
                  text: (
                      <span> Chase Payment</span>
                  ),
                  colour:  'yellow',
                  onClick: () => {
                    ResendStudentPaymentLinkClicked(item.id)
                   }
                  },
                ]}
                ></PaymentCard>
          </div>
          )
        })
        }
      
      </Slider>
      <div className="flex justify-end w-full mt-[24px]">
        <LinkItem url="/ADI/payments" seeAllIcon={true}>
          <span className="text-sm">See all payments</span>
        </LinkItem>
      </div>
    </DisclosureItem>
    </>
  );
};

export { PaymentSummary };
