import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as auth from "../redux/AuthRedux";
import { CockpitImpersonate, login, signOut } from "../redux/AuthCRUD";
import { useEffect } from "react";
import PageHeader from "../../../components/shared/elements/PageHeader";
import Spinner from "../../../components/shared/elements/Spinner";
export const IMPERSONATOR_TOKEN = `impersonator-token`;

export function ImpersonateTransit() {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      signOut();
      localStorage.removeItem(IMPERSONATOR_TOKEN);
      dispatch(auth.actions.logout());
      sessionStorage.clear();
      localStorage.clear();
      setToken();
    }, 1500);
  }, []);

  const setToken = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const impersonatorToken = urlSearchParams.get("impersonatorToken");
    const userToken = urlSearchParams.get("token");
    if (impersonatorToken) {
      localStorage.setItem(IMPERSONATOR_TOKEN, impersonatorToken);
      sessionStorage.setItem("isSessionOpened", "true");
      CockpitImpersonate(userToken).then((result) => {
        const pwd = atob(result.data.results.pwd);
        setTimeout(() => {
          login(result.data.results.impersonator_email, pwd)
            .then(() => {
              dispatch(auth.actions.setIsLogin(true));
              dispatch(auth.actions.login());
              history.push("/ADI/dashboard");
            })
            .catch((err: any) => {});
        }, 1000);
      });
    }
  };

  return (
    <>
      <form className="form w-100" noValidate id="kt_login_signin_form">
        {/* begin::Heading */}
        <div className="text-center mb-6">
          <PageHeader title="Redirecting to adi's account" isCenter={true} />
          <Spinner />
        </div>
      </form>
    </>
  );
}
