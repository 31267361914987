export class Lesson {
    id:string | undefined;
    job_id:string | undefined;
    adi_id:string | undefined;
    job_ref: string|undefined;
    lesson_date:Date| null=null;
    lesson_date_str: string|undefined;
    duration:number=1;
    student_name:string|undefined;
    is_prev:boolean|undefined;
    can_be_removed: boolean|undefined;
    can_be_updated: boolean|undefined;
    action_to_midnight: boolean|undefined;
    disabled_at:Date| null=null;
    created_at: any;
    is_private_student: number | null | undefined = 0;
    date_str: string|undefined;
    form_time_str: string|undefined;
    to_time_str: string|undefined;
    parent_id?: string|undefined;
    recurring_option?: number|undefined;
    can_update_series?: boolean|undefined;
    series_start_date?: Date | null;
    series_end_date?: Date | null;
    student_data?:{student_first_name: string, student_email: string | undefined}|undefined;
    lesson_type?: string|undefined;
}