/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  ArrowRightIcon,
  ChevronRightIcon,
  EnvelopeIcon,
  MapIcon,
  PhoneIcon,
} from "@heroicons/react/24/outline";
import { toAbsoluteUrl } from "../../../helpers";
import Button from "../elements/Button";
import Skeleton from "@mui/material/Skeleton";
import { Tooltip } from "@mui/material";
import { useState } from "react";
import { PickupLocationPreview } from "../../jobs/modals/PickupLocationPreview";

interface IStudentCardProp {
  loading: boolean | undefined;
  title?: string | undefined;
  location?: number | string | undefined;
  description?: string;
  phone?: string;
  email?: string;
  agreedLocation?: any;
  pmfIcon?: boolean;
  status?: { color: string; title: string } | null;
  medicalConditions?: React.ReactNode;
  completeReason?: string;
  testResult?: string;
  hideLocation?: boolean;
}

export default function StudentCard({
  loading,
  title,
  location,
  description,
  phone,
  email,
  agreedLocation,
  pmfIcon = false,
  status,
  medicalConditions,
  completeReason,
  testResult,
  hideLocation = false
}: // navigation,
IStudentCardProp) {
  // const StudentCard: FC<IStudentCardProp> = ({student , loading , showAddress }) => {
  const [tooltipText, setTooltipText] = useState("");
  const [showPickupLocation, setShowPickupLocation] = useState(false);

  return (
    <>
      <div className=" rounded shadow border-l-8 border-l-yellow bg-white px-4 py-3 sm:px-6 sm:ps-4 h-full">
        <div className="-ml-4 flex flex-wrap items-center justify-between sm:flex-nowrap h-full">
          <div className="ml-4 w-full h-full">
            <div className="flex flex-col h-full justify-between">
              <div className="flex justify-between items-center">
                {/* {loading ? ( */}
                <div className="min-w-0 flex-1 flex items-center mb-2 min-h-[24px] justify-between">
                  <h3 className="text-base font-bold leading-6 text-darkBlue grow truncate w-full">
                    {loading ? (
                      <>
                        <Skeleton width={150} animation="wave" />
                      </>
                    ) : (
                      <> {title} </>
                    )}
                  </h3>

                  <div className="ml-4 flex flex-shrink-0">
                    {loading ? (
                      <div className="flex item-center">
                        <Skeleton
                          width={20}
                          height={20}
                          className="me-2"
                          variant="circular"
                        />
                        <Skeleton
                          width={20}
                          height={20}
                          animation="wave"
                          variant="circular"
                        />
                      </div>
                    ) : (
                      <>
                        {phone && (
                          <Tooltip
                            title={tooltipText !== "" ? tooltipText : phone}
                            arrow
                            placement="top"
                            onClose={() => setTooltipText("")}
                            componentsProps={{
                              tooltip: {
                                style: {
                                  fontSize: "10px",
                                  lineHeight: "18px",
                                  fontFamily: "Poppins, sans-serif",
                                  maxWidth: "250px",
                                  marginRight: "6px",
                                },
                              },
                            }}
                          >
                            <span>
                              <Button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  const isMobile =
                                    /Mobi/i.test(navigator.userAgent) ||
                                    "ontouchend" in document.documentElement;
                                  if (!isMobile) {
                                    setTooltipText("Copied to clipboard!");
                                    navigator.clipboard.writeText(phone);
                                  } else {
                                    window.open("tel:" + phone, "_self");
                                  }
                                }}
                                Icon={PhoneIcon}
                                size="icon"
                                colour="transparent"
                              />
                            </span>
                          </Tooltip>
                        )}

                        {email && (
                          <Button
                            onClick={(e) => {
                              e.stopPropagation();
                              window.open("mailto:" + email, "_self");
                            }}
                            Icon={EnvelopeIcon}
                            size="icon"
                            colour="transparent"
                          />
                        )}

                        {agreedLocation &&
                          agreedLocation.lat !== 0 &&
                          agreedLocation.lng !== 0 && (
                            <Tooltip
                              title={"Agreed Pickup Location"}
                              arrow
                              placement="top"
                              onClose={() => setTooltipText("")}
                              componentsProps={{
                                tooltip: {
                                  style: {
                                    fontSize: "10px",
                                    lineHeight: "18px",
                                    fontFamily: "Poppins, sans-serif",
                                    maxWidth: "250px",
                                    marginRight: "6px",
                                  },
                                },
                              }}
                            >
                              <span>
                                <Button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setShowPickupLocation(true);
                                  }}
                                  Icon={MapIcon}
                                  size="icon"
                                  colour="transparent"
                                />
                              </span>
                            </Tooltip>
                          )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="block">
                {loading ? (
                  <div className="flex item-center">
                    <Skeleton width={14} height={14} className="me-2" />
                    <Skeleton width={150} animation="wave" />
                  </div>
                ) : (
                  <>
                    {!hideLocation && location && (
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          window.open(
                            process.env.REACT_APP_GOOGLE_MAP_URL
                              ? process.env.REACT_APP_GOOGLE_MAP_URL + location
                              : "#",
                            "_blank"
                          );
                        }}
                        size="fit"
                        colour="transparent"
                        className="justify-start"
                      >
                        <p className="text-sm text-darkBlue flex items-center leading-5 mb-1">
                          <i className="pmf-icon-map-pin text-base text-darkBlue me-1 leading-none -ms-1 -mt-[1px]"></i>{" "}
                          {location}
                          <ChevronRightIcon className="w-4 h-4 ms-1 text-[#CFCFCF]" />
                        </p>
                      </Button>
                    )}
                  </>
                )}
              </div>

              {medicalConditions}
              {loading ? (
                <div className="flex item-center">
                  <Skeleton width={200} animation="wave" />
                </div>
              ) : (
                <p className="text-sm leading-4 text-darkBlue">{description}</p>
              )}
              {loading ? (
                <div className="flex item-center">
                  <Skeleton width={14} height={14} className="me-2" />
                  <Skeleton width={150} animation="wave" />
                </div>
              ) : (
                status && (
                  <div className="flex min-w-0 gap-x-4 mt-3 justify-between">
                    <div className="min-w-0 flex-auto">
                      <div className="flex items-center gap-x-1.5">
                        <div className="flex-none rounded-full">
                          <div
                            className={`
                        ${
                          {
                            darkGreen: "bg-green",
                            yellow: "bg-yellow",
                            darkRed: "bg-danger",
                            darkBlue: "bg-darkBlue",
                            darkGray: "bg-[#2f2f2f]",
                          }[status?.color]
                        } 
                        h-1.5 w-1.5 rounded-full`}
                          />
                        </div>
                        <p className="text-xs">{status?.title}</p>

                        {pmfIcon ? (
                          <img
                            src={toAbsoluteUrl("/assets/media/logos/pmf.svg")}
                            className="h-4"
                            alt="pmfIcon"
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <span className="text-xs">
                      {testResult
                        ? testResult.charAt(0).toUpperCase() +
                          testResult.slice(1)
                        : completeReason}
                    </span>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
        <PickupLocationPreview
          show={showPickupLocation}
          close={() => {
            setShowPickupLocation(false);
          }}
          pickupLocation={agreedLocation}
        />
      </div>
    </>
  );
}

export { StudentCard };
