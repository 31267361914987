import { FC, useEffect, useState } from "react";
import Skeleton from "@mui/material/Skeleton";
import {
  startOfToday,
  format,
  parse,
  eachDayOfInterval,
  startOfWeek,
  endOfWeek,
  endOfMonth,
  isSameMonth,
  isToday,
  add,
} from "date-fns";
import { capitalizeFirstLetter } from "../../../../helpers/textHelper";
import { useSharedData } from "../../../../pages/providers/SharedDataProvider";
import moment from "moment";
import { getAdiLessonsAndTestsData } from "../../../../../core/services/job-service";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ExclamationCircleIcon,
  PencilSquareIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { getTestStatusColor } from "../../../../helpers/lesson-status-helper";
import { mapStatus } from "../../../../mappers/testMapper";
import StudentCard from "../../../shared/cards/StudentCard";
import StackedCardWithActionsMenu from "../../../shared/data/StackedCardWithActionsMenu";
import { PrivateStudentTest } from "../../../students/privateStudentTest";
import { DeletePrivateStudentTestModal } from "../../../test/DeletePrivateStudentTestModal";
import EmptyState from "../../../shared/elements/EmptyState";
import OtherEventCard from "../../../shared/cards/OtherEventCard";
import {
  getGoogleCalendarEventsOnSelectedDay,
  getOutlookCalendarEventsOnSelectedDay,
} from "../../../../../core/services/adi-service";
import { TestStatusEnum } from "../../../../../core/models/enums/test-status-enum";
import { useQuery } from "../../../../helpers/QueryParamsHelper";
import { useHistory } from "react-router-dom";

type Props = {
  loading: boolean;
  highlightDates: any;
  selectedOption: any;
  showUpdateModal: any;
  setLesson: any;
  showDeleteModal: any;
  showCancelModal: any;
  initialDate: any;
  isUpcommingOnly: any;
  updateData: () => void;
  selectedDayInCalendar: Date;
  setSelectedDayInCalendar: any;
  refreshMonthView: boolean;
};

const MonthView: FC<Props> = ({
  loading,
  highlightDates,
  selectedOption,
  showUpdateModal,
  setLesson,
  showDeleteModal,
  showCancelModal,
  initialDate,
  isUpcommingOnly,
  updateData,
  selectedDayInCalendar,
  setSelectedDayInCalendar,
  refreshMonthView,
}) => {
  const [clickedDay, setClickedDay] = useState(
    selectedDayInCalendar ?? new Date()
  );
  const [loadingData, setLoadingData] = useState<boolean>();
  const [currentTest, setCurrentTest] = useState<any>();
  const [showUpdateTestModal, setShowUpdateTestModal] = useState<boolean>();
  const [showDeleteTestModal, setShowDeleteTestModal] = useState<boolean>();
  const { data, updateSharedData } = useSharedData();
  const [events, setEvents] = useState<Array<any>>([]);
  const [integrationCalendarEvents, setIntegrationCalendarEvents] = useState<
    Array<any>
  >([]);
  const query = useQuery();
  const history = useHistory();

  const getLessonsAndTests = async (selected: any) => {
    await getAdiLessonsAndTestsData({
      is_upcomming_only: false,
      selected: selected,
    }).then((res) => {
      const modifiedEvents = res?.results?.map((data: any) => {
        if (data?.is_test) {
          return {
            ...data,
            item: {
              ...data?.item,
              is_lesson: false,
              title: data?.item?.student ? data?.item?.student?.full_name : "",
              date: moment(
                data?.item?.booked_for_human,
                "DD/MM/yyyy hh:mm"
              ).toDate(),
              id: data?.item?.id,
              student_name: data?.item?.student
                ? data?.item?.student?.full_name
                : data?.item?.student_name,
              job_ref: data?.item?.deal_id,
              test_location: data?.item?.location
                ? data?.item?.location?.name
                : data?.item?.test_location,
              date_str: moment(
                data?.item?.booked_for_human,
                "DD/MM/yyyy hh:mm"
              ).format("dddd, DD MMMM"),
              form_time_str: moment(
                data?.item?.booked_for_human,
                "DD/MM/yyyy hh:mm"
              ).format("h:mm a"),
              backgroundColor: "#32c3d7",
            },
          };
        } else if (data?.is_event) {
          const event_date_formatted = moment(
            data?.item?.event_date,
            "yyyy-MM-DD HH:mm:ss"
          );
          return {
            ...data,
            item: {
              ...data?.item,
              is_lesson: false,
              is_other: true,
              date: event_date_formatted.format("DD MMM, yyyy hh:mm a"),
              date_str: event_date_formatted.format("dddd, DD MMMM"),
              form_time_str: event_date_formatted.format("h:mm a"),
              to_time_str: event_date_formatted
                .add(parseFloat(data?.item?.event_duration) * 60, "minutes")
                .format("h:mm a"),
              series_end_date: data?.item?.series_end_date
                ? moment(
                    data?.item?.series_end_date,
                    "yyyy-MM-DD HH:mm"
                  ).format("DD MMM, yyyy")
                : null,
              backgroundColor: "#32c3d7",
            },
          };
        } else if (data?.is_google) {
          return { ...data };
        } else if (data?.is_outlook) {
          return { ...data };
        } else {
          return { ...data };
        }
      });

      setEvents(modifiedEvents);
    });
  };

  const getData = async (date: Date) => {
    setLoadingData(true);
    const lessonsAndTestsPromise = getLessonsAndTests(
      moment(date).format("ddd MMM DD yyyy")
    );

    Promise.all([lessonsAndTestsPromise]).then((res) => {
      setLoadingData(false);
    });
  };

  const handleClose = () => {
    setShowUpdateTestModal(false);
  };
  const mapData = (event: any) => {
    const data: any = [
      {
        icon: "pmf-icon-test",
        value: event?.test_location ?? "",
      },
      {
        icon: "pmf-icon-clock",
        value: event?.date_str,
      },
    ];

    if (event.job_ref) {
      data.push({
        icon: "pmf-icon-suitcase",
        value: `#${event?.job_ref?.toString()}`,
        badge: true,
      });
    }

    return data;
  };

  useEffect(() => {
    highlightDates?.filter(
      (highlightedDate: any) => highlightedDate?.type === "other"
    );
  }, [highlightDates]);

  useEffect(() => {
    if (initialDate) {
      var date = moment(selectedDayInCalendar, "DD-MM-YYYY").toDate();
      setClickedDay(date);
      setSelectedDayInCalendar(date);
      updateSharedData("selectedDate", date);
      getData(date);
    } else {
      getData(new Date());
    }
  }, [initialDate]);

  useEffect(() => {
    if (refreshMonthView == true) {
      getData(clickedDay);
    }
  }, [refreshMonthView]);

  const today = selectedDayInCalendar ?? startOfToday();
  const days = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

  const [currMonth, setCurrMonth] = useState(() => format(today, "MMM-yyyy"));
  let firstDayOfMonth = parse(currMonth, "MMM-yyyy", new Date());

  const daysInMonth = eachDayOfInterval({
    start: startOfWeek(firstDayOfMonth),
    end: endOfWeek(endOfMonth(firstDayOfMonth)),
  });

  const getPrevMonth = () => {
    const firstDayOfPrevMonth = add(firstDayOfMonth, { months: -1 });
    setCurrMonth(format(firstDayOfPrevMonth, "MMM-yyyy"));
    setSelectedDayInCalendar(firstDayOfPrevMonth);
  };

  const getNextMonth = () => {
    const firstDayOfNextMonth = add(firstDayOfMonth, { months: 1 });
    setCurrMonth(format(firstDayOfNextMonth, "MMM-yyyy"));
    setSelectedDayInCalendar(firstDayOfNextMonth);
  };

  return (
    <>
      <div className="md:grid md:grid-cols-2 md:divide-x md:divide-gray-200 md:px-4">
        {
          <>
            <div className="pt-6 md:pr-8 pb-0 sm:pb-5 mb-6">
              <div className="flex items-center">
                <h2 className="flex-auto text-base font-bold">
                  {format(firstDayOfMonth, "MMMM yyyy")}
                </h2>
                <div className="relative flex rounded-md bg-white shadow-sm items-stretch">
                  <button
                    type="button"
                    onClick={() => getPrevMonth()}
                    className="flex h-9 w-12 items-center justify-center rounded-l-md border-y border-l border-gray-300 pr-1 text-gray-400 hover:text-darkBlue focus:relative md:w-9 md:pr-0 md:hover:bg-gray-50"
                  >
                    <span className="sr-only">Previous month</span>
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                  <button
                    type="button"
                    onClick={() => setCurrMonth(format(new Date(), "MMM-yyyy"))}
                    className="border-y border-gray-300 md:px-3.5 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:relative block"
                  >
                    This Month
                  </button>
                  <button
                    type="button"
                    onClick={() => getNextMonth()}
                    className="flex h-9 w-12 items-center justify-center rounded-r-md border-y border-r border-gray-300 pl-1 text-gray-400 hover:text-darkBlue focus:relative md:w-9 md:pl-0 md:hover:bg-gray-50"
                  >
                    <span className="sr-only">Next month</span>
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
              <div className="mt-10 grid grid-cols-7 text-center text-xs leading-6 text-darkBlue">
                {days.map((day, idx) => {
                  return <div key={idx}>{capitalizeFirstLetter(day)}</div>;
                })}
              </div>
              <div className="grid grid-cols-7 mt-2 content-center">
                {daysInMonth.map((day, idx) => {
                  return (
                    <div
                      key={"day" + idx}
                      className={`colStartClasses[getDay(day)]
                      ${idx > 6 ? "border-t border-gray-200" : ""} py-1 `}
                    >
                      <p
                        onClick={() => {
                          setClickedDay(day);
                          setSelectedDayInCalendar(day);
                          getData(day);
                          updateSharedData("selectedDate", day);
                          query.set(
                            "selected",
                            moment(day).format("DD-MM-YYYY")
                          );
                          history.push({
                            search: query.toString(),
                          });
                        }}
                        className={`cursor-pointer mx-auto flex flex-col items-center justify-center w-10 h-10 font-medium rounded-full ${
                          isSameMonth(day, today)
                            ? "text-darkBlue"
                            : "text-gray-400"
                        } ${!isToday(day) && " hover:bg-gray-300"} 
                        
                        ${
                          moment(clickedDay).isSame(moment(day), "day")
                            ? "bg-darkBlue text-white"
                            : ""
                        }`}
                      >
                        {format(day, "d")}

                        <div className="flex gap-x-0.5 w-full justify-center">
                          {highlightDates.map((highlightDate: any, i: number) =>
                            moment(highlightDate.date).isSame(
                              moment(day),
                              "day"
                            ) ? (
                              <div
                                key={day.toString() + i}
                                className={`${
                                  highlightDate.type === "pmf"
                                    ? "bg-[#2BCD5A]"
                                    : highlightDate.type === "private"
                                    ? "bg-[#FFBF00]"
                                    : highlightDate.type === "test"
                                    ? "bg-[#32c3d7]"
                                    : highlightDate.type === "other"
                                    ? "bg-[#9B9D9C]"
                                    : ""
                                } 
                                h-1 w-1 rounded-full`}
                              />
                            ) : (
                              <></>
                            )
                          )}
                        </div>
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="mt-4 pt-6 md:pl-8 md:border-0 border-t">
              <h2 className="text-base font-bold text-darkBlue mb-2">
                {loading ? (
                  <Skeleton width={200} />
                ) : (
                  <>{moment(clickedDay).format("dddd DD MMMM")}</>
                )}
              </h2>

              {((events.length > 0 || integrationCalendarEvents.length > 0) &&
                selectedOption === 0) ||
              loading ||
              loadingData ? (
                <>
                  {!loadingData &&
                    events?.map((item, index) => (
                      <div key={"event" + index} className="my-2">
                        {item?.is_lesson && (
                          <div className="pt-3 rounded-lg shadow border-t-[15px] border-t-yellow">
                            <StackedCardWithActionsMenu
                              title={item?.item?.date_str}
                              icon="pmf-icon-wheel"
                              description={
                                item?.item?.form_time_str +
                                " to " +
                                item?.item?.to_time_str
                              }
                              pillTitle={
                                item?.item?.job_ref
                                  ? "#" + item?.item?.job_ref
                                  : null
                              }
                              loading={false}
                              showAction={
                                item?.item?.abilities?.can_be_updated ||
                                item?.item?.abilities?.can_be_removed
                              }
                              actions={[
                                {
                                  Icon: PencilSquareIcon,
                                  title: "Edit lesson",
                                  show: item?.item?.abilities?.can_be_updated,
                                  onClick: () => {
                                    setLesson(item?.item);
                                    showUpdateModal();
                                  },
                                },
                                {
                                  Icon: TrashIcon,
                                  title: "Delete lesson",
                                  show: item?.item?.abilities?.can_be_removed,
                                  onClick: () => {
                                    setLesson(item?.item);
                                    showDeleteModal();
                                  },
                                },
                                {
                                  Icon: XMarkIcon,
                                  title: "Cancel lesson",
                                  show: item?.item?.is_private_student,
                                  onClick: () => {
                                    setLesson(item?.item);
                                    showCancelModal();
                                  },
                                },
                              ].filter((s) => s.show)}
                              pmfIcon={!item?.item?.is_private_student}
                              status={{
                                title: item?.item?.status ?? "",
                                color: getTestStatusColor(
                                  item?.item?.status_id
                                ),
                              }}
                              isCanlenderView={true}
                            >
                              <div className="pt-3 pb-1">
                                {item?.item?.lesson_type && (
                                  <div className="my-3">
                                    <label className="text-sm">
                                      <strong>Lesson Type: </strong>{" "}
                                      {item?.item?.lesson_type}
                                    </label>
                                  </div>
                                )}
                                <StudentCard
                                  loading={loading}
                                  title={
                                    (item?.item?.student_data
                                      ?.student_first_name ?? "") +
                                    " " +
                                    (item?.item?.student_data
                                      ?.student_surname ?? "")
                                  }
                                  location={
                                    item?.item?.student_data?.student_post_code
                                  }
                                  email={
                                    item?.item?.student_data?.student_email
                                  }
                                  phone={
                                    item?.item?.student_data?.student_phone
                                  }
                                  description={`${
                                    item?.item?.student_data
                                      ?.student_street_address
                                      ? item?.item?.student_data
                                          ?.student_street_address + ", "
                                      : ""
                                  } ${
                                    item?.item?.student_data
                                      ?.student_city_name ?? ""
                                  }`}
                                  hideLocation={true}
                                />
                              </div>
                            </StackedCardWithActionsMenu>
                          </div>
                        )}
                        {item?.is_test && (
                          <div className="pt-3 rounded-lg shadow border-t-[15px] border-t-darkBlue">
                            <StackedCardWithActionsMenu
                              title={item?.item?.date_str}
                              icon="pmf-icon-test"
                              description={item?.item?.form_time_str}
                              status={
                                item?.item?.is_private_student === 1 &&
                                item?.item?.status === TestStatusEnum.Confirmed
                                  ? undefined
                                  : mapStatus(item?.item?.status)
                              }
                              loading={false}
                              pmfIcon={item?.item?.is_private === 0}
                              showAction={item?.item?.is_private_student === 1}
                              actions={[
                                {
                                  Icon: PencilSquareIcon,
                                  title: "Edit test",
                                  show: item?.item?.is_private_student === 1,
                                  onClick: () => {
                                    setCurrentTest(item?.item);
                                    setShowUpdateTestModal(true);
                                  },
                                },
                                {
                                  Icon: TrashIcon,
                                  title: "Delete test",
                                  show: item?.item?.is_private_student === 1,
                                  onClick: () => {
                                    setCurrentTest(item?.item);
                                    setShowDeleteTestModal(true);
                                  },
                                },
                              ].filter((s) => s.show)}
                              isCanlenderView={true}
                            >
                              <div className="pt-3 pb-1">
                                {item?.item?.test_location && (
                                  <div className="my-3">
                                    <label className="text-sm">
                                      <strong>Test Center: </strong>{" "}
                                      {item?.item?.test_location}
                                    </label>
                                  </div>
                                )}
                                {item?.item?.student && (
                                  <StudentCard
                                    loading={loading}
                                    title={
                                      (item?.item?.student?.first_name ?? "") +
                                      " " +
                                      (item?.item?.student?.surname ?? "")
                                    }
                                    location={
                                      item?.item?.student?.student_post_code
                                    }
                                    email={item?.item?.student?.email}
                                    phone={item?.item?.student?.phone}
                                    description={`${
                                      item?.item?.student
                                        ?.student_street_address
                                        ? item?.item?.student
                                            ?.student_street_address + ", "
                                        : ""
                                    } ${
                                      item?.item?.student?.student_city_name ??
                                      ""
                                    }`}
                                    hideLocation={true}
                                  />
                                )}
                              </div>
                            </StackedCardWithActionsMenu>
                          </div>
                        )}

                        {item?.is_event && (
                          <div className="pt-3 rounded-lg shadow border-t-[15px] border-t-darkBlue">
                            <OtherEventCard
                              event={item?.item}
                              refreshParent={() => {
                                updateData();
                                getData(clickedDay);
                              }}
                              isMonthView={true}
                            />
                          </div>
                        )}
                        {(item?.is_google || item?.is_outlook) && (
                          <div className="pt-3 rounded-lg shadow border-t-[15px] border-t-darkBlue">
                            <StackedCardWithActionsMenu
                              title={moment(
                                item?.item?.date,
                                "dddd, DD MMMM"
                              ).toString()}
                              icon="pmf-icon-calendar"
                              loading={false}
                              showAction={false}
                              description={
                                (item?.item?.from_time ?? "") +
                                " to " +
                                (item?.item?.to_time ?? "")
                              }
                              isCanlenderView={true}
                            >
                              <div className="pt-3 pb-1">
                                {item?.item?.name && (
                                  <div className="my-3">
                                    <label className="text-sm">
                                      <strong>
                                        {item?.is_google
                                          ? "Google Calender"
                                          : "Outlook"}
                                        :{" "}
                                      </strong>{" "}
                                      {item?.item?.name}
                                    </label>
                                  </div>
                                )}
                              </div>
                            </StackedCardWithActionsMenu>
                          </div>
                        )}
                      </div>
                    ))}

                  {loadingData && (
                    <div className="h-fit my-2 -mx-3">
                      <StackedCardWithActionsMenu loading={loadingData}>
                        <StudentCard loading={loadingData} />
                      </StackedCardWithActionsMenu>
                    </div>
                  )}
                  <PrivateStudentTest
                    show={showUpdateTestModal}
                    onHide={handleClose}
                    testDetails={currentTest}
                    refreshParent={() => {
                      updateData();
                      getData(clickedDay);
                    }}
                  />
                  <DeletePrivateStudentTestModal
                    jobId={currentTest?.job_id}
                    testLocation={currentTest?.location?.name}
                    testDate={currentTest?.booked_for_human}
                    open={showDeleteTestModal!}
                    testId={currentTest?.id}
                    onClose={() => {
                      setShowDeleteTestModal(false);
                      updateData();
                      getData(clickedDay);
                    }}
                  />
                </>
              ) : (
                <div className=" justify-center items-center flex w-full">
                  <EmptyState
                    Icon={ExclamationCircleIcon}
                    title="No appointments on this day"
                  />
                </div>
              )}
            </div>
          </>
        }
      </div>
    </>
  );
};

export { MonthView };
