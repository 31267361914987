/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from "react";
import PageHeader from "../../components/shared/elements/PageHeader";
import { DirectPaymentDetails } from "../../components/account-settings/digital-payments/DirectPaymentDetails";

const CheckoutsWrapper: FC = () => {
  useEffect(() => {
    document.title = `ADI Network App | Checkout details | ADI Network`;
  }, []);
    
  return (
    <>
      <PageHeader title="Checkout details" backToURL="/ADI/digital-payments" />
      <DirectPaymentDetails />
    </>
  );
};

export default CheckoutsWrapper;
