import { TestStatusEnum } from "../../core/models/enums/test-status-enum"

export const mapSubTitle = (status: TestStatusEnum): { text: string, color: "red" | "yellow" } | undefined => {
    switch (status) {
        case TestStatusEnum.PendingAdiApproval:
            return {
                text: "test awaiting approval",
                color: "red"
            }
        case TestStatusEnum.PendingResult:
            return {
                text: "test result needed",
                color: "yellow"
            }
    }
}

export const mapStatus = (status: TestStatusEnum): { title: string, color: "blue" | "green" | "darkGreen" | "yellow" | "red" | "outlineRed" | "gray" | "darkRed" } | undefined => {
    switch (status) {
        case TestStatusEnum.Passed:
            return {
                title: status,
                color: "darkGreen"
            }
        case TestStatusEnum.Failed:
            return {
                title: status,
                color: "darkRed"
            }

        case TestStatusEnum.Cancelled:
            return {
                title: status,
                color: "red"
            }
        case TestStatusEnum.Confirmed:
            return {
                title: status,
                color: "green"
            }
        case TestStatusEnum.Rejected:
            return {
                title: status,
                color: "yellow"
            }
    }
} 