import { FC, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import Modal from "../../components/shared/overlays/Modal";
import RadioInputGroup from "../../components/shared/forms/RadioInputGroup";
import { hideJob } from "../../../core/services/job-service";
import Alert from "../../components/shared/overlays/Alert";
import { RegisterInterestDialog } from "../../components/jobs/modals/RegisterInterestDialog";
import { getCanAcceptJobs } from "../../../core/services/adi-service";
import { CantAcceptJobDialog } from "../../components/jobs/modals/CantAcceptJobDialog";

interface IProps {
  show: any;
  onHide: any;
  jobId: string;
  onSubmit: any;
  jobRef: any;
  jobRate: any;
  hasPrevBid: boolean;
  adiHasBadge: boolean;
  adiRateBelowHourlyRate: boolean;
}

const DeclineJobDialog: FC<IProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [isSubmited, setIsSubmited] = useState<boolean>(false);
  const [submiting, setSubmiting] = useState<boolean>(false);
  const [showRegisterInterest, setShowRegisterInterest] =
    useState<boolean>(false);
  const [showRateIncrease, setShowRateIncrease] = useState<boolean>(false);
  const [canAcceptJob, setCanAcceptJob] = useState<boolean>(false);
  const [showCantAccept, setShowCantAccept] = useState<boolean>(false);


  const reasons = [
    {
      title: "Learner availability",
      image: "/assets/media/svg/hide-reasons/st-availability",
    },
    {
      title: "I’m fully booked",
      image: "/assets/media/svg/hide-reasons/availablity",
    },
    {
      title: "Test centre",
      image: "/assets/media/svg/hide-reasons/cone",
    },
    {
      title: "Pickup distance",
      image: "/assets/media/svg/hide-reasons/distance",
    },
    {
      title: "Job rate",
      image: "/assets/media/svg/hide-reasons/rate",
    },
    {
      title: "Test clash",
      image: "/assets/media/svg/hide-reasons/tests-clash",
    },
  ].map((type) => {
    return {
      label: type.title,
      value: type.title,
      img: type.image.concat(".svg"),
      nonselectImg: type.image.concat(".svg"),
    };
  });
  const openRegisterInterest = () => {
    setShowRegisterInterest(true);
  };
  const [selectedReason, setSelectedReason] = useState<string | null>();
  const onHide = () => {
    if (showRateIncrease || showRegisterInterest) {
      props.onSubmit();
    } else if (!submiting) {
      if (isSubmited) {
        props.onSubmit();
      } else {
        props.onHide();
      }
    }
  };
  useEffect(() => {
    if (props.show) {
      onShow();
    }
    getCanAcceptJobs().then((data) => {
      if('can_accept_jobs' in data?.data?.results && data?.data?.results?.can_accept_jobs === 1){
        setCanAcceptJob(true);
      }
    })
  }, [props.show]);
  const onShow = () => {
    setLoading(false);
    setError(null);
    setIsSubmited(false);
    setSubmiting(false);
  };
  const onReasonChanged = (reason: string) => {
    setSelectedReason(reason);
  };

  useEffect(() => {
    if (selectedReason) {
      hideTheJob();
    }
  }, [selectedReason]);

  const hideTheJob = () => {
    if (!selectedReason) {
      return;
    }
    setSubmiting(true);

    hideJob({ job_id: props.jobId, reason: selectedReason })
      .then((data) => {
        ReactGA.event("hide_job");
        if (selectedReason == "Job rate" && !props.hasPrevBid && props.adiHasBadge) {
          setShowRateIncrease(true);
        } else {
          props.onSubmit();
        }
      })
      .catch((error) => {
        setSubmiting(false);
        if (error && error.errors && error.errors.length > 0) {
          setSubmiting(false);
          setError(error.errors[0].errorMessage);
        } else {
          setSubmiting(false);
          setError("Sorry, an error has occured");
        }
      });
  };

  return (
    <div>
      <Modal
        open={props.show && !showCantAccept}
        description={
          showRateIncrease
            ? "Would you be able to take this course for a higher rate?"
            : "Why are you unable to take this job?"
        }
        onClose={() => {
          showRateIncrease ? props.onSubmit() : onHide();
        }}
        onSubmit={() => {
          !canAcceptJob ? setShowCantAccept(true) : openRegisterInterest();
        }}
        closeText={"No"}
        hideClose={!showRateIncrease || showRegisterInterest}
        hideSubmit={!showRateIncrease || showRegisterInterest}
        submitText={"Yes"}
        title={"Hide job"}
      >
        {showRegisterInterest ? (
          <RegisterInterestDialog
            onClose={() => {
              props.onSubmit();
            }}
            jobRate={props.jobRate}
            jobId={props.jobId}
            jobUpdated={() => {
              props.onSubmit();
            }}
            adiRateBelowHourlyRate={props.adiRateBelowHourlyRate}
            requestedRate={undefined}
            onUpdate={undefined}
          />
        ) : (
          !showRateIncrease && (
            <>
              <RadioInputGroup
                name="reason_id"
                label={""}
                options={reasons}
                onChange={(reason) => onReasonChanged(reason)}
                value={selectedReason}
                disabled={submiting}
                error={error != null}
                errorMsg={error != null ? error : undefined}
              />

              <div className="mt-3">
                <Alert
                  title="This job will reappear if any details change. You can also view
            hidden jobs by selecting ‘Show hidden jobs’ in the filters."
                  colour="gray"
                />
              </div>
            </>
          )
        )}
      </Modal>
      <CantAcceptJobDialog
        show={showCantAccept}
        onClose={() => {
          setShowCantAccept(false);
          props.onSubmit();
        }}
      />
    </div>
  );
};

export { DeclineJobDialog };
