/* eslint-disable jsx-a11y/anchor-is-valid */
import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import { FC, useState } from "react";
import Button from "../elements/Button";
import CheckboxInput from "./CheckboxInput";
import { ClickAwayListener, Popper } from "@mui/material";

interface ICustomDropdownProp {
  options: {
    title: string | null;
    id: number;
    isChecked: boolean;
    isDisabled: boolean;
  }[];
  sourceId: number;
  handleSelect: (dayIndex: number) => void;
  applyCopy: (dayId: number) => void;
  onClose: () => void;
  disabled?: boolean;
}

const CustomDropdown: FC<ICustomDropdownProp> = ({
  options,
  sourceId,
  disabled,
  handleSelect,
  applyCopy,
  onClose,
}) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? sourceId + "dropdown-popper" : undefined;

  return (
    <>
      <ClickAwayListener
        onClickAway={(event) => {
          if (open) handleClick(event);
        }}
      >
        <div className="relative inline-block text-left">
          <Button
            aria-describedby={id}
            size="fit"
            Icon={DocumentDuplicateIcon}
            colour="transparent"
            onClick={(event) => {
              handleClick(event);
              onClose();
            }}
            disabled={disabled}
          ></Button>

          <Popper
            id={id}
            open={open}
            anchorEl={anchorEl}
            placement="auto"
            disablePortal={true}
            className="z-[1]"
          >
            <div className="mt-2 w-40 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                <div>
                  <div className="text-gray-300 font-medium text-sm px-4 py-2">
                    Copy time to:
                  </div>
                </div>

                {options.map((option, i) => (
                  <div key={i}>
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        handleSelect(option.id);
                      }}
                      className="hover:bg-gray-100 flex px-4 py-1 text-sm"
                    >
                      <CheckboxInput
                        key={
                          option.isChecked ? "checked-" + i : "notchecked-" + i
                        }
                        name={"op" + i}
                        required={false}
                        label={option.title ?? ""}
                        isSingleCheckbox={true}
                        checked={option.isChecked}
                        onChange={() => handleSelect(option.id)}
                        disabled={option.isDisabled}
                      />
                    </div>
                  </div>
                ))}

                <div className="px-4 py-2">
                  <Button
                    type="button"
                    onClick={(event) => {
                      applyCopy(sourceId);
                      handleClick(event);
                      onClose();
                    }}
                    fullWidth
                    size="medium"
                    colour="outline"
                  >
                    <span className="flex items-center text-darkBlue">
                      Apply
                    </span>
                  </Button>
                </div>
              </div>
            </div>
          </Popper>
        </div>
      </ClickAwayListener>
    </>
  );
};

export { CustomDropdown };
