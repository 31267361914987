
import { FC } from "react";
import Button from "../../shared/elements/Button";
import EmptyState from "../../shared/elements/EmptyState";
import { CreditCardIcon } from "@heroicons/react/24/outline";
import { createConnectAccount, getOnBoardLink } from "../../../../core/services/connect-service";
import { PersonalDataModel } from "../../../../core/models/personal-data-model";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../../../../setup";

interface IProps {
  createLoading: boolean;
  onCreateLoading: Function;
  setAccount?: Function;
}

const CreateAccountStripe: FC<IProps> = ({ createLoading, onCreateLoading, setAccount }) => {
  const personalData: PersonalDataModel = useSelector<RootState>(
    ({ auth }) => auth.personalData,
    shallowEqual
  ) as PersonalDataModel;

  const createAccount = async () => {
    onCreateLoading(true);
    const res = await createConnectAccount({ email: personalData?.email })
      .catch(() => { onCreateLoading(false) });

    if (res) {
      if (setAccount) setAccount(res?.data);
      generateLink();
    }
    onCreateLoading(false);
  };

  const generateLink = async () => {
    const res: any = await getOnBoardLink()
      .catch(() => { });
    if (res?.data?.url)
      window.location.href = res?.data?.url ?? "";
  };

  return (
    <>
      <EmptyState
        Icon={CreditCardIcon}
        title="Accepting Direct Payments"
        description="Create an account to accept payments from your learners"
      />
      <div className="-mt-8">
        <div className="my-3">
          <Button onClick={createAccount} fullWidth={true} size="large" disabled={createLoading}>
            Create An Account
          </Button>
        </div>
      </div>
    </>
  );
};

export { CreateAccountStripe };
