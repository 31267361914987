import { FC } from "react";
import { PrismicImage, PrismicRichText } from "@prismicio/react";
import { PlayCircleIcon } from "@heroicons/react/20/solid";
import { useHistory } from "react-router-dom";
import { useQuery } from "../../../helpers/QueryParamsHelper";

type Props = {
  imgURL: any;
  vidDuration: any;
  author: any;
  title: React.ReactNode;
  shortDesc?: any;
  category?: any;
  onShowVideoClick: () => void;
  authorData?: any;
  uid: any;
  pageCounter?: any
};

const VideoTile: FC<Props> = ({
  imgURL,
  vidDuration,
  author,
  title,
  shortDesc,
  category,
  onShowVideoClick,
  authorData,
  uid,
  pageCounter
}) => {
  const history = useHistory();
  const query = useQuery();
  return (
    <>
      {/* <!------- */}

      <div className={`group relative flex flex-col hover:cursor-pointer`} id={uid}>
        <div onClick={onShowVideoClick} className="VideoTip d-flex flex-column">
          <div className="relative group group-hover:pointer overflow-hidden rounded-[20px] h-[300px]">
            <PrismicImage
              field={imgURL}
              className="h-full w-full object-contain object-center sm:h-full sm:w-full"
            />
            <span className="absolute bottom-4 right-4 rounded-md bg-darkBlue opacity-90 px-3 py-[2px] text-xs text-white font-semibold font-titlef">
              <PrismicRichText field={vidDuration} />
            </span>

            <div className="absolute opacity-0 group-hover:opacity-100 flex justify-center right-0 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <button className="w-12 h-12 rounded-full bg-darkBlue focus:outline-none">
                <PlayCircleIcon className="text-white" />
              </button>
            </div>
          </div>
          <div className="flex flex-1 flex-col space-y-2 py-4">
            <div className="text-sm text-[#616161]">{category}</div>
            <h3 className="leading-none text-[26px] font-titlef font-bold">
              {/* <span aria-hidden="true" className="absolute inset-0"></span> */}
              {title}
            </h3>

            <p className="text-sm text-[#616161]">{shortDesc}</p>
            {Object.keys(author).length > 0 ? (
              <div className="text-sm flex">
                <p className="me-1">by</p>
                {""}
                <div onClick={(e) => {
                  e.stopPropagation();
                  history.push(
                    query.get("category") != null ?
                      `/ADI/cpd/authors/${authorData?.uid}?mainTab=videos&pageCounter=${pageCounter}&articleId=${uid}&category=${query.get("category")}` :
                      `/ADI/cpd/authors/${authorData?.uid}?mainTab=videos&pageCounter=${pageCounter}&articleId=${uid}`
                  );

                }}>
                  <PrismicRichText field={author} />
                </div>
              </div>
            ) : <></>}
          </div>
        </div>
      </div>
      {/* <!------- */}
    </>
  );
};

export default VideoTile;
