import {
  PrismicImage,
  PrismicProvider,
  PrismicRichText,
} from "@prismicio/react";
import { FC, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { createClient } from "../../../../../../prismic";
import PageHeader from "../../../../components/shared/elements/PageHeader";
import { toAbsoluteUrl } from "../../../../helpers";
import CPDBreakCard from "../../_shared/CPDBreakCard";
import ArticleTile from "../../_shared/ArticleTile";
import { useQuery } from "../../../../helpers/QueryParamsHelper";
import { calculateReadingTime } from "../../../../helpers/readingTimeHelper";

type ArticleProps = {
  uid: string;
  setRelatedArticles?: (e: any) => void;
  page: string | null;
  setLoading: any;
  searchVal: string | null;
};

const Article: FC<ArticleProps> = ({
  uid,
  setRelatedArticles = (e: any) => { },
  page,
  setLoading,
  searchVal
}) => {
  const query = useQuery();

  const [article, setArticle] = useState<any>();

  useEffect(() => {
    async function getArticle() {
      setLoading(true);
      const response = await createClient.getByUID("cpd_article", uid, {
        fetchLinks: ["category.name"],
      });

      setArticle(response);
      const relatedArticlesList = response?.data?.related_articles?.map(
        (t: any) => t.article_related
      );
      const relatedArticleIds = relatedArticlesList
        .map((i: any) => i.id)
        .filter((id: any) => id !== undefined);

      const relatedArticlesData = await createClient.getAllByIDs(
        relatedArticleIds,
        {
          fetchLinks: ["category.name"],
        }
      );

      const mappedArticles = relatedArticlesData?.map((result) => ({
        id: result.id,
        thumbImage: result.data.thumb_img,
        categoryName: result.data.categories_link.data.name,
        articleTitle: result.data.title,
        authorName: result.data.author,
        releaseDate: result.data.date,
        articleShortDescription: result.data.content[0].text,
        uid: result.uid,
        content: result.data.content,
      }));
      setRelatedArticles(mappedArticles);
      setLoading(false);
    }
    getArticle();
  }, [uid]);

  return (
    <>
      {/* {state === 'loading' ? (
        <p>Loading…</p>
      ) : ( */}
      {article && (
        <>
          <PageHeader
            title=""
            backToURL={
              searchVal ? 
                `/ADI/cpd/search-result/${searchVal}`
              : (
                query.get("authorId") ? (
                  query.get("category") ?
                    `/ADI/cpd/authors/${query.get("authorId")}?mainTab=article&pageCounter=${page}&articleId=${uid}&category=${query.get("category")}`
                    :
                    `/ADI/cpd/authors/${query.get("authorId")}?mainTab=article&pageCounter=${page}&articleId=${uid}`

                ) : (
                  query.get("category")
                    ? `/ADI/cpd?tab=article&uid=${uid}&page=${page}&category=${query.get("category")}`
                    : `/ADI/cpd?tab=article&uid=${uid}&page=${page}`
                )
              )
            }
          />
          <div className="px-5 lg:px-6 pt-5">
            <p className="text-base pb-1">
              {article.data.categories_link.data.name}
            </p>
            <h1 className="font-titlef text-[26px] font-bold leading-none my-2">
              {article.data.title}
            </h1>
            {Object.keys(article.data.author).length > 0 ? (
              <div className="text-sm flex pt-2">
                <p className="me-1">by</p>
                {""}
                <PrismicRichText field={article.data.author} />
                <span className="mx-1">•</span>
                <p className="mx-1">
                  {calculateReadingTime(article?.data.content)} min read
                </p>
              </div>
            ) : (
              <>
                <div className="text-sm pt-2">
                  <p>{calculateReadingTime(article?.data.content)} min read</p>
                </div>
              </>
            )}
          </div>

          <figure className="mt-7">
            {article?.data.banner_image ? (
              <>
                <PrismicImage
                  field={article?.data.banner_image}
                  className="aspect-video w-full md:h-[450px] object-cover object-top"
                />
              </>
            ) : (
              <>
                <img
                  src={toAbsoluteUrl(
                    `/assets/media/CPD/(colormatch)driving-school-test-handsome-young-arab-instructor.png`
                  )}
                  alt={article?.data.title}
                  className="aspect-video w-full md:h-[450px] object-cover object-top"
                />
              </>
            )}
          </figure>

          <div className=" px-5 lg:px-6 pt-10">
            <PrismicRichText
              field={article?.data.content}
              components={{
                list: ({ children }) => (
                  <ul className="list-disc ps-6 mt-3">{children}</ul>
                ),
                oList: ({ children }) => (
                  <ol className="list-decimal ps-6 mt-3">{children}</ol>
                ),
                paragraph: ({ children }) => (
                  <p className="mt-2 text-base leading-6">{children}</p>
                ),
                heading3: ({ children }) => (
                  <h3 className=" mt-12 text-2xl font-titlef font-bold">
                    {children}
                  </h3>
                ),
                heading4: ({ children }) => (
                  <h4 className="mt-12 text-xl font-titlef font-bold">
                    {children}
                  </h4>
                ),
              }}
            />
          </div>
        </>
      )}
    </>
  );
};

const ArticlePage: FC = () => {
  const { uid }: any = useParams();
  const query = useQuery();
  const history = useHistory();
  const [relatedArticles, setRelatedArticlesList] = useState<any>();
  const [loading, setLoading] = useState(true);
  return (
    <>
      <PrismicProvider client={createClient}>
        <Article
          uid={uid}
          setRelatedArticles={setRelatedArticlesList}
          page={query.get("page")}
          setLoading={setLoading}
          searchVal={query.get("searchVal")}
        />
      </PrismicProvider>
      {loading == false && (
        <div className="px-5 lg:px-6">
          {/* start:: Break Card 2 */}
          <CPDBreakCard
            imgURL={"/assets/media/CPD/course-around.svg"}
            title={<>Find available courses local to you</>}
            desc=" Lessons paid upfront & tests booked in advance"
            button={{
              text: "Get work for free",
              onClick: () => history.push(`/ADI/new-jobs`),
              color: "yellow",
              disabled: false,
            }}
          />
          {/* start:: Break Card 2 */}
          {relatedArticles?.length > 0 && (
            <>
              <p className="flex self-center justify-center text-2xl font-bold p-5">
                Related Article
              </p>
              <div className="grid grid-cols-1 gap-y-9 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-8">
                {relatedArticles?.map((item: any, index: any) => (
                  <ArticleTile
                    loading={false}
                    key={index}
                    imgURL={item.thumbImage}
                    category={item.categoryName}
                    title={item.articleTitle}
                    shortDesc={item.articleShortDescription}
                    author={item.authorName}
                    content={item.content}
                    onShowArticleClick={() =>
                      history.push(`/ADI/cpd/article/${item?.uid}`)
                    }
                    uid={item?.uid}
                  />
                ))}
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default ArticlePage;
