import { FC, useEffect, useState } from "react";
import Modal from "../../../../shared/overlays/Modal";
import HorizontalCheckboxGroup from "../../../../shared/forms/HorizontalCheckboxGroup";
import { getJobLessonsDataWithReflection } from "../../../../../../core/services/job-service";
import toast, { Toaster } from "react-hot-toast";
import Notification from "../../../../shared/overlays/Notification";
import {
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  PlusSmallIcon,
} from "@heroicons/react/24/outline";
import EmptyState from "../../../../shared/elements/EmptyState";
import Button from "../../../../shared/elements/Button";
import { AddUpdateReflectionForLesson, getReflectionForLesson } from "../../../../../../core/services/assessment-service";
import TextArea from "../../../../shared/forms/TextArea";
import Dropdown from "../../../../shared/forms/Dropdown";
import { Skeleton } from "@mui/material";

interface IProps {
  jobId: any;
  adiJobId: any;
  show: boolean;
  onClose: any;
  lessons: any;
  studentId:any;
  selected? :any;
  edit:boolean;
  setShowAddReflectionFormModel:any
}

const AddReflectionFormModel: FC<IProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [answers, setAnswers] = useState<any>([]);
  const [selected, setSelected] = useState<any>();
  const [reflectionList ,setReflectionList]= useState<Array<any>>([])
  useEffect(() => {
    if(props.show) {
    if ( props.selected  && props.selected?.value && props.edit){
       setSelected(props.selected)}
       else setSelected(props.lessons[0]);}
  }, [props.show]);
  useEffect(() => {
    if (selected?.value &&props.show ) loadLessonsWithReflection(selected?.value);
  }, [selected?.value ,props.show]);

  const loadLessonsWithReflection = async (id: any) => {
    try {
      setLoading(true);
      const res = await getReflectionForLesson(id);
      setAnswers(res.data.results);
      setReflectionList(res.data.results)
      setLoading(false);
    } catch {
      setLoading(false);
      toast.custom(
        () => (
          <Notification
            colour="red"
            title={"Error loading your jobs"}
            description={""}
            Icon={ExclamationTriangleIcon}
          />
        ),
        {
          duration: 5000,
          position: "top-center",
        }
      );
    }
  };
const submitReflection =async ()=>{
try {
  setSubmitting(true);
  let obj = {
    lesson_id:selected.value,
    student_id : props.studentId,
    job_id: props.jobId,
    reflections:reflectionList,
  }
  const res = await AddUpdateReflectionForLesson(obj);
  props.onClose(true)
  setSubmitting(false);
} catch {
  setSubmitting(false);
  toast.custom(
    () => (
      <Notification
        colour="red"
        title={"Something went wrong!"}
        description={""}
        Icon={ExclamationTriangleIcon}
      />
    ),
    {
      duration: 5000,
      position: "top-center",
    }
  );
}
}
  return (
    <>
      <Toaster />
      <form noValidate id="kt_update_rate_form">
        <Modal
          open={props.show}
          title=""
          onClose={()=>{props.onClose(false)}}
          submitText="Share"
          onSubmit={submitReflection}
          disableSubmit={submitting || loading}
        >
          {
            props.edit ? <h4 className="p-2 text-lg font-bold">
            {props?.selected?.label}
          </h4> :<Dropdown
            options={props.lessons}
            label="lessons"
            errorMsg="lesson is required"
            required={true}
            name="lessons"
            id="lessons"
            value={props.lessons?.find((t: any) => t.value === selected?.value)}
            onChange={(e) => {
              setSelected(e);
            }}
          />
          }
          
          {props.lessons.length == 0 && !loading ? (
            <>
              <EmptyState
                title="There is no planned lessons"
                Icon={ExclamationCircleIcon}
              />
            </>
          ) : (
            <>
              {loading ? (
                <>
                  {[...Array(5)].map((e, i) => (
                    <div key={i} className="py-2">
                      <Skeleton width="60%" animation="wave"></Skeleton>
                      <Skeleton
                        width="100%"
                        height={80}
                        animation="wave"
                      ></Skeleton>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  {answers.map((item: any, index: any) => {
                    return (
                      <div key={index} className="py-2">
                        <h4 className=" text-md ">{item.question}</h4>
                        <TextArea
                          name={"answer_" + index}
                          maxLength={255}
                          className="form-control"
                          value={reflectionList.find((a:any)=>a.question_id == item.question_id)?.answer}
                          placeholder=""
                          disabled = {submitting}
                          onChange={(e)=>{
                            let oldAnswer = reflectionList?.find((a:any)=>a.question_id == item.question_id);
                            if(oldAnswer)
                              {
                                
                                let newArray = reflectionList.filter((a:any)=>a.question_id != item.question_id)
                                oldAnswer.answer =e?.target?.value;
                                newArray.push(oldAnswer);
                                setReflectionList(newArray)

                              }
                            else
                            {
                              let updateAnswer = item;
                              updateAnswer.answers =e?.target?.value;
                              reflectionList?.push(updateAnswer)
                              setReflectionList(reflectionList)
                            } 
                            
                          }}
                        />
                      </div>
                    );
                  })}
                </>
              )}
            </>
          )}
        </Modal>
      </form>
    </>
  );
};

export { AddReflectionFormModel };
