import { FC, useEffect, useState } from 'react'
import { refund, softRefund } from '../../../../../core/services/connect-service'
import { Refund } from '../../../../../core/models/stripeConnect/refund'
import { Checkout } from '../../../../../core/models/stripeConnect/checkout'
import { fromStripeAmount } from '../../../../helpers/stripeHelper'
import Modal from '../../../shared/overlays/Modal'
import Alert from '../../../shared/overlays/Alert'

interface IProps {
  show: any
  onClose: any,
  checkout: Checkout
}

const RefundPaymentModal: FC<IProps> = (props) => {
  const [refundDetails, setRefundDetails] = useState<Refund>(new Refund())
  const [submitting, setSubmitting] = useState(false)

  function closeModal() {
    props.onClose()
  }

  useEffect(() => {
    if (props.checkout.id != 0) {
      softRefund(props.checkout.id)
        .then((res) => {
          setRefundDetails(res.data)
        })
        .catch(() => {
        })
    }
  }, [props.checkout.id])

  const issueRefund = () => {
    setSubmitting(true)
    refund(props.checkout.id)
      .then((res) => {
        closeModal()
        setRefundDetails(res.data)
        setSubmitting(false)
      })
      .catch(() => {
        setSubmitting(false)
      })
  };

  return (
    <Modal
      open={props.show}
      title={refundDetails && refundDetails.refund_allowed === true ? 'Refund Payment' : 'Refund Unavailable'}
      submitText={refundDetails && refundDetails.refund_allowed ? "Refund" : "Get Help"}
      closeText='Cancel'
      onClose={closeModal}
      onSubmit={refundDetails && refundDetails.refund_allowed === true ? issueRefund : () => { window.open("https://www.passmefast.co.uk/contact", "_blank"); }}
      disabled={submitting}
    >
      {refundDetails !== null && refundDetails.refund_allowed && (
        <div className='w-full'>
          <Alert icon='pmf-icon-info' colour='blue' description='You are about to refund this payment back to the learners payment method. Are you sure you wish to continue?' />
          <h4 className='text-base font-bold mb-2 mt-5'>Refund details</h4>
          <div className="pt-4 font-medium shadow rounded">
            <div className="flex justify-between border-gray-300">
              <span className=''>Learner receives</span>
              <span className="">
                &pound;{fromStripeAmount(refundDetails.refund_amount)}
              </span>
            </div>
            <div className=' border-t'>
              ADI Network retains the processing fee of
              {" "}<strong> £{fromStripeAmount(props.checkout.fee_paid)}</strong>{" "} for this transaction.
            </div>
            {refundDetails.balance_after_refund < 0 && (
              <div className="flex justify-between border-t border-gray-300">
                <span className=''>New account balance</span>
                <span className="">
                  &pound;{fromStripeAmount(refundDetails.balance_after_refund)}
                </span>
              </div>
            )}
            {refundDetails.balance_after_refund < 0 && (
              <>
                <Alert
                  icon='pmf-icon-info'
                  title='Information about negative balances'
                  description='Issuing this refund will take your Stripe Account balance into a negative amount. Stripe will attempt to offset this balance with any future payments. If this is not possible then they will attempt to collect the balance from your connected bank account within 10 working days.'
                />
                <Alert
                  icon='pmf-icon-info'
                  title='Outstanding Balances'
                  description='It is your responsibility to make sure your account balance remains in good standing. If Stripe cannot settle your outstanding balance, we will contact you to pay off your remaining debt, however we will charge a £50 admin fee for this service.'
                />
                <Alert
                  icon='pmf-icon-info'
                  title=''
                  colour='orange'
                  description='For more information about our terms and
                  conditions for connect accounts, please visit '
                  buttons={[
                    {
                      onClick: () => window.open("www.passmefast.co.uk/connect-terms", "_blanc"),
                      title: "www.passmefast.co.uk/connect-terms"
                    }
                  ]}
                />
              </>
            )}
          </div>
        </div>
      )}
      {refundDetails && refundDetails.refund_allowed === false && (
        <div className="mt-2">
          <p className="text-sm">
            Sorry we were unable to process this refund
          </p>
          {refundDetails.error_message && <p className="text-sm">
            <strong>{refundDetails.error_message}</strong>
          </p>}
        </div>)
      }
    </Modal>
  )
}

export { RefundPaymentModal }
