import { FC, useEffect, useState } from "react";
import {
  formatDisplayedDate,
  formatDisplayedTimeWithDuration,
} from "../../../../core/services/date-service";
import {
  getLessonChargeabilityDetails,
  getLessonsRecurringOptions,
  cancelPrivateStudentLesson,
} from "../../../../core/services/job-service";
import Modal from "../../shared/overlays/Modal";
import InfoList from "../../shared/data/InfoList";
import Button from "../../shared/elements/Button";
import { LessonStatusEnum } from "../../../../core/models/enums/lesson-status-enum";
import toast from "react-hot-toast";
import Notification from "../../shared/overlays/Notification";
import { CheckCircleIcon } from "@heroicons/react/24/outline";

interface IProps {
  show: any;
  onHide: any;
  onSubmit: any;
  lessonId: any;
  date: any;
  duration: any;
  dateStr: any;
  isPrivateJob: any;
  hasEmail?: boolean;
  recurringOption?: number;
  canUpdateSeries?: boolean;
}

const CancelLessonDialog: FC<IProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [isOutsidePolicy, setIsOutsidePolicy] = useState<boolean>(false);
  const [isPastLesson, setIsPastLesson] = useState<boolean>(false);
  const [isDocumentOrNoPolicy, setIsDocumentOrNoPolicy] =
    useState<boolean>(false);
  const [isWithin48hours, setIsWithin48hours] = useState<boolean>(false);
  const [recurringOptions, setRecurringOptions] = useState<any>();

  const onShow = async () => {
    setLoading(true);
    setError(null);
    const res = await getLessonChargeabilityDetails(props.lessonId).catch(
      () => {
        setLoading(false);
      }
    );
    if (!res) return;
    setIsOutsidePolicy(res.results?.is_outside_cancellation_policy ?? false);
    setIsDocumentOrNoPolicy(res.results?.is_document_or_no_policy ?? false);
    setIsWithin48hours(res.results?.is_within_48_hours ?? false);
    setIsPastLesson(res.results?.is_past_lesson ?? false);
    setLoading(false);
  };

  const loadMessage = () => {
    if (isPastLesson || (isWithin48hours && isOutsidePolicy)) {
      return "This lesson was cancelled with less notice than your cancellation policy. Are you charging the learner for this lesson?";
    } else if (isWithin48hours && isDocumentOrNoPolicy) {
      return "This lesson is being cancelled with less than 48 hours notice, will the learner be charged?";
    } else {
      return "Please confirm you wish to cancel this lesson?";
    }
  };

  const cancelLesson = async (cancellationType: number) => {
    setSubmitting(true);
    setLoading(true);
    const res = await cancelPrivateStudentLesson(props.lessonId, {
      status_id: cancellationType,
    }).catch(() => {
      setSubmitting(false);
      setLoading(false);
    });
    setSubmitting(false);
    setLoading(false);
    if (!res) {
      setError("Sorry, an error occurred while cancelling the lesson");
      return;
    }
    if ((props.isPrivateJob && props.hasEmail) || !props.isPrivateJob) {
      toast.custom(
        () => (
          <Notification
            colour="green"
            title={
              "Lesson successfully cancelled. The learner will be notified by email."
            }
            description={""}
            Icon={CheckCircleIcon}
          />
        ),
        {
          duration: 3000,
          position: "top-center",
        }
      );
    }
    props.onSubmit();
  };

  useEffect(() => {
    if (props.show) onShow();
    getLessonsRecurringOptions().then((data) => {
      setRecurringOptions(data?.data?.results);
    });
  }, [props.show]);

  return (
    <Modal
      open={props.show}
      onClose={props.onHide}
      title="Cancel lesson"
      disabled={submitting}
      hideSubmit={true}
      hideClose={true}
      showBackButton={true}
    >
      <>
        {loading ? (
          <h2 className="mb-3">Loading...</h2>
        ) : (
          <>
            <div className="w-full px-5 text-left align-middle">
              <p className="text-sm text-red-600 -mt-1 mb-2" id="error">
                {error}
              </p>
              <p className="text-1g font-semibold mb-2">{loadMessage()}</p>

              <InfoList
                data={[
                  {
                    icon: "pmf-icon-calendar",
                    value: formatDisplayedDate(props.date) ?? "-",
                  },
                  {
                    icon: "pmf-icon-clock",
                    value:
                      formatDisplayedTimeWithDuration(
                        new Date(props.dateStr),
                        props.duration * 60
                      ) ?? "-",
                  },
                  {
                    icon: "pmf-icon-reschedule",
                    value: recurringOptions?.find(
                      (key: any) => key.id === props?.recurringOption
                    )?.name,
                  },
                ]}
              />

              {isPastLesson || (isWithin48hours && (isOutsidePolicy || isDocumentOrNoPolicy)) ? (
                <div
                  className="
            flex items-center justify-between gap-2 mt-3 pb-4"
                >
                  <Button
                    colour="yellow"
                    type="submit"
                    className="w-1/2"
                    onClick={() =>
                      cancelLesson(LessonStatusEnum.CancelledChargeable)
                    }
                    size="large"
                    disabled={submitting}
                  >
                    Yes
                  </Button>

                  <Button
                    colour="outline"
                    type="button"
                    className="w-1/2"
                    onClick={() =>
                      cancelLesson(LessonStatusEnum.CancelledNotChargeable)
                    }
                    size="large"
                    disabled={submitting}
                  >
                    No
                  </Button>
                </div>
              ) : (
                <div
                  className="
            flex items-center justify-between gap-2 mt-3 pb-4"
                >
                  <Button
                    colour="yellow"
                    type="submit"
                    className="w-1/2"
                    onClick={() => {
                      isOutsidePolicy
                        ? cancelLesson(LessonStatusEnum.CancelledChargeable)
                        : cancelLesson(LessonStatusEnum.CancelledNotChargeable);
                    }}
                    size="large"
                    disabled={submitting}
                  >
                    Confirm
                  </Button>

                  <Button
                    colour="outline"
                    type="button"
                    className="w-1/2"
                    onClick={props.onHide}
                    size="large"
                    disabled={submitting}
                  >
                    Cancel
                  </Button>
                </div>
              )}
            </div>
          </>
        )}
      </>
    </Modal>
  );
};

export { CancelLessonDialog };
