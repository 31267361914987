import { FC, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { AdiJobNote } from "../../../../../../core/models/adi-job-note";
import {
  addAdiJobNote,
  deleteAdiJobNote,
  getAdiJobNotes,
} from "../../../../../../core/services/job-service";
import Notes from "../../../../shared/data/Notes";
import toast from "react-hot-toast";
import Notification from "../../../../shared/overlays/Notification";
import {
  ExclamationCircleIcon,
  CheckCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";

interface IProps {
  adiJobId: string;
}

const JobNotes: FC<IProps> = (props) => {
  const [jobNotes, setJobNotes] = useState<Array<AdiJobNote>>([]);
  const [refresh, setRefresh] = useState<Boolean>(true);

  useEffect(() => {
    if (refresh) {
      setRefresh(false);
      getAdiJobNotes(props.adiJobId)
        .then((res) => {
          setJobNotes(res.data.results);
        })
        .catch(() => {});
    }
  }, [refresh]);

  const removeNote = (note: any, index: any) => {
    const oldJobs = [...jobNotes];
    oldJobs.splice(index, 1);
    let loadingId = toast.custom(
      () => (
        <Notification
          colour="gray"
          title={"Deleting note..."}
          description={""}
          Icon={ExclamationCircleIcon}
        />
      ),
      {
        duration: 5000,
        position: "top-center",
      }
    );

    deleteAdiJobNote(jobNotes[index].id)
      .then(() => {
        toast.custom(
          () => (
            <Notification
              colour="green"
              title={"Deleted successfully"}
              description={""}
              Icon={CheckCircleIcon}
            />
          ),
          {
            duration: 5000,
            position: "top-center",
            id: loadingId,
          }
        );
      })
      .catch(() => {
        setJobNotes(oldJobs);
        toast.custom(
          () => (
            <Notification
              colour="red"
              title={"Failed to delete note!"}
              description={""}
              Icon={ExclamationTriangleIcon}
            />
          ),
          {
            duration: 5000,
            position: "top-center",
            id: loadingId,
          }
        );
      })
      .finally(() => {
        setRefresh(true);
      });
  };

  const addNote = (value: any) => {
    const note: any = {
      adi_job_id: props.adiJobId,
      body: value,
    };

    let loadingId = toast.custom(
      () => (
        <Notification
          colour="gray"
          title={"Adding note..."}
          description={""}
          Icon={ExclamationCircleIcon}
        />
      ),
      {
        duration: 5000,
        position: "top-center",
      }
    );

    addAdiJobNote(note)
      .then(() => {
        ReactGA.event("add_notes");
        const updatedNotes = jobNotes;
        updatedNotes?.push(note);
        setJobNotes(updatedNotes);
        toast.custom(
          () => (
            <Notification
              colour="green"
              title={"Saved successfully"}
              description={""}
              Icon={CheckCircleIcon}
            />
          ),
          {
            duration: 5000,
            position: "top-center",
            id: loadingId,
          }
        );
      })
      .catch(() => {
        toast.custom(
          () => (
            <Notification
              colour="red"
              title={"Failed to add note!"}
              description={""}
              Icon={ExclamationTriangleIcon}
            />
          ),
          {
            duration: 5000,
            position: "top-center",
            id: loadingId,
          }
        );
      })
      .finally(() => {
        setRefresh(true);
      });
  };

  return (
    <>
      <Notes
        title="Your Notes"
        alertTxt={
          <span className="text-sm">
            These notes are for you to log information about this learner and
            their course. ADI Network and the learner are unable to see them.
          </span>
        }
        notes={jobNotes.map((a) => {
          return { value: a.body, date: a.created_at };
        })}
        onCreateNote={(value) => addNote(value)}
        onRemoveNote={(note, index) => removeNote(note, index)}
      />
    </>
  );
};

export { JobNotes };
