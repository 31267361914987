import { ChevronRightIcon } from "@heroicons/react/24/outline";
import React from "react";
import { Link } from "react-router-dom";
import Badge from "../elements/Badge";
import { toAbsoluteUrl } from "../../../helpers";
import Skeleton from "@mui/material/Skeleton";


export interface InfoListData {
  icon:
  "pmf-icon-map-pin" |
  "pmf-icon-suitcase" |
  "pmf-icon-user" |
  "pmf-icon-clock" |
  "pmf-icon-test" |
  "pmf-icon-calendar" |
  "pmf-icon-currency-pound" |
  "pmf-icon-wheel" |
  "pmf-icon-info" |
  "pmf-icon-phone" |
  "pmf-icon-credit-card" |
  "pmf-icon-intensity" |
  "pmf-icon-statistics" |
  "pmf-icon-more" |
  "pmf-icon-home" |
  "pmf-icon-eye-slash" |
  "pmf-icon-expense" |
  "pmf-icon-envelope" |
  "pmf-icon-bubble-chat" |
  "pmf-icon-reschedule" |
  undefined;
  value: string | React.ReactNode | undefined;
  badge?: boolean;
  linkURL?: string;
  pmfIcon?: boolean;
}

export interface InfoListProps extends React.HTMLAttributes<HTMLDivElement> {
  loading?: boolean
  data?: InfoListData[];
}

export default function InfoList({
  title = "Nothing To See Here",
  loading = false,
  data,
  ...props
}: InfoListProps) {
  return (
    <>
      <div className="w-full flex flex-col mt-3" {...props}>
        <ul className="list-none">
          {
            loading ? <>
              <li className='mb-2 last:mb-0 w-full flex justify-between'>
                <span className="flex items-center mt-0">
                  <Skeleton width={14} height={14} variant="circular" className="me-3" animation="wave" />
                  <Skeleton width={150} className="me-2" animation="wave" />
                </span>
              </li>
              <li className='mb-2 last:mb-0 w-full flex justify-between'>
                <span className="flex items-center mt-0">
                  <Skeleton width={14} height={14} variant="circular" className="me-3" animation="wave" />
                  <Skeleton width={150} className="me-2" animation="wave" />
                </span>
              </li>
              <li className='mb-2 last:mb-0 w-full flex justify-between'>
                <span className="flex items-center mt-0">
                  <Skeleton width={14} height={14} variant="circular" className="me-3" animation="wave" />
                  <Skeleton width={150} className="me-2" animation="wave" />
                </span>
              </li>
            </> : (data?.map((item, i) => (
              <li key={i} className='mb-0 last:mb-0 w-full flex justify-between'>
                <span className="flex items-center mt-0 max-w-[90%]">
                  <i className={`${item.icon} mr-1 text-darkBlue text-xl`}></i>
                  {item.badge ?
                    <Badge
                      roundedPill={false}
                      title={item.value}
                      colour="gray"
                    ></Badge>
                    : (<>
                      <span className="text-sm text-darkBlue truncate">{item.value} </span>
                      {item?.pmfIcon ? <img src={toAbsoluteUrl('/assets/media/logos/pmf.svg')} className='h-4 ms-1' alt="pmfIcon" /> : <></>}
                    </>)}
                </span>
                {item.linkURL != null && (
                  <Link to={item.linkURL} className="flex items-center">
                    <ChevronRightIcon className=" w-4 h-4" />{" "}
                  </Link>
                )}
              </li>
            )))
          }
        </ul>
      </div>
    </>
  );
}
