import { FC, useEffect, useState } from "react";
import {
  formatDisplayedDate,
  formatDisplayedTimeWithDuration,
} from "../../../../core/services/date-service";
import {
  getLessonsRecurringOptions,
  removeOtherEvent,
} from "../../../../core/services/job-service";
import Modal from "../../shared/overlays/Modal";
import InfoList from "../../shared/data/InfoList";
import Alert from "../../shared/overlays/Alert";
import Button from "../../shared/elements/Button";
import { LessonRecurringOptionEnum } from "../../../../core/models/enums/lesson-recurring_option-enum";

interface IProps {
  show: any;
  onHide: any;
  refreshParent?: any;
  event?: any;
}

const RemoveOtherEventModal: FC<IProps> = ({
  show,
  onHide,
  refreshParent,
  event,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [recurringOptions, setRecurringOptions] = useState<any>();

  const deleteOtherEvent = (deleteEntireSeries: boolean) => {
    setLoading(true);
    removeOtherEvent({
      eventId: event.id,
      delete_entire_series: deleteEntireSeries,
    })
      .then((res) => {
        // ReactGA.event('remove_lesson')
        setLoading(false);
        if (refreshParent) refreshParent();
        onClose();
      })
      .catch((error) => {
        setLoading(false);
        if (error && error.errors && error.errors.length > 0) {
          setError(error.errors[0].errorMessage);
        } else {
          setError("Sorry, an error has occured");
        }
      });
  };

  const onClose = () => {
    setError(null);
    onHide();
  };

  useEffect(() => {
    if(show)
      {
        getLessonsRecurringOptions().then((data) => {
          setRecurringOptions(data?.data?.results);
        });
      }
   
  }, [show]);

  return (
    <Modal
      open={show}
      onClose={onClose}
      submitText="Remove"
      closeText="Cancel"
      title="Remove Other Event(s)"
      description="Are you sure you want to remove the other event(s)?"
      disabled={false}
      disableSubmit={loading}
      hideSubmit
      hideClose
    >
      {error ? (
        <Alert colour="red" icon="pmf-icon-info" title={error} />
      ) : (
        <>
          <InfoList
            data={[
              {
                icon: "pmf-icon-calendar",
                value: formatDisplayedDate(event?.event_date) ?? "-",
              },
              {
                icon: "pmf-icon-clock",
                value:
                  formatDisplayedTimeWithDuration(
                    new Date(event?.event_date),
                    event?.event_duration * 60
                  ) ?? "-",
              },
              {
                icon: "pmf-icon-reschedule",
                value: recurringOptions?.find(
                  (key: any) => key.id === event?.recurring_option
                )?.name,
              },
            ]}
          />
        </>
      )}

      <div className="sticky w-full bottom-0 left-0 px-0 py-4 bg-white z-[11]">
        <div className="w-full">
          <div className="w-full flex items-center justify-between space-x-2">
            <Button
              colour="yellow"
              type="submit"
              className="w-1/2"
              size="large"
              onClick={() => deleteOtherEvent(false)}
              disabled={loading}
            >
              {event?.recurring_option === LessonRecurringOptionEnum.Never
                ? "Delete"
                : "This event only"}
            </Button>
            <Button
              colour="outline"
              type="button"
              className="w-1/2"
              size="large"
              onClick={() => {
                if (event?.recurring_option === LessonRecurringOptionEnum.Never)
                  onClose();
                else deleteOtherEvent(true);
              }}
              disabled={loading}
            >
              {event?.recurring_option === LessonRecurringOptionEnum.Never
                ? "Cancel"
                : "The entire series"}
            </Button>
          </div>
          {event?.recurring_option !== LessonRecurringOptionEnum.Never && (
            <Button
              onClick={onClose}
              disabled={loading}
              colour="link"
              size="fit"
              fullWidth
            >
              Cancel
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export { RemoveOtherEventModal };
