import PageHeader from "../../components/shared/elements/PageHeader";

export default function CarLeasing() {
  return (
    <div className="h-[calc(100svh-94px-24px)] md:h-[calc(100svh-24px)]">
      <PageHeader
        title="Earn commission with GoShorty Learner Insurance"
        backToURL="/ADI/more"
      />
      <iframe
        src="https://staging.adinetwork.co.uk?iframe-mode=true"
        title="ADI Network"
        className="w-full h-full border-none"
      >
        Loading
      </iframe>
    </div>
  );
}
