/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/20/solid";
import { FC } from "react";
import { Car } from "../../../../core/models/car";
import Badge from "../elements/Badge";
import { ActionMenu } from "../forms/ActionMenu";
import Skeleton from "@mui/material/Skeleton";

interface ICarCardProp {
  car?: Car,
  transmissionTypes?: any,
  isSubmitting?: boolean,
  handleDeleteCar?: () => void,
  handleShow?: (car: Car) => void,
  loading:boolean
}

const CarCard: FC<ICarCardProp> = ({ car, transmissionTypes, isSubmitting, handleShow, handleDeleteCar, loading=false }) => {

  const getTransmissionTypeName = (car: Car) => {
    return transmissionTypes.find((t: any) => t.id === car.transmission_type_id)?.trasmission_type_name;
  }
  return (
    <>
      <div className="rounded shadow-lg border-l-8 border-l-yellow bg-white p-3.5">
        <div className="flex flex-wrap items-center justify-between sm:flex-nowrap w-full">
        
          <div className="flex flex-col w-full">
            <div className="flex justify-between items-start">
              <div>
               
                <h3 className="text-base font-bold leading-6 text-darkBlue">
                  
                {loading ? 
                        <Skeleton width={70} className="me-2" />
                
                    :<>  {car?.model}</> }
                 
                </h3>
              {car&&
              <div className="my-[5px]">{}
                  <Badge loading={loading} title={getTransmissionTypeName(car)} colour="green"></Badge>
                </div>
              }
                
              </div>
                  {
                    handleDeleteCar&&handleShow&&car&&
                    <div className="ml-4 flex flex-shrink-0">
                <ActionMenu  disabled={isSubmitting}  options={[{Icon : PencilSquareIcon,title : 'Edit car', show : true , onClick: () => handleShow(car)},
                                {Icon : TrashIcon,title : 'Delete car', show : true , onClick: () => handleDeleteCar()}]}></ActionMenu>
              </div>
                  }
              
            </div>
            <span className="text-gray-400 text-[10px]">
            {loading ? 
                        <Skeleton width={70} className="me-2" />
                
                    :<>   Reg ID: {car?.reg_number}</> }
             
            </span>
          </div>

        </div>
      </div>
    </>
  );
};

export { CarCard };
