/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import Button from "../elements/Button";
import InfoList from "../data/InfoList";

export type PaymentCardProps = {
  title?: string;
  loading?: boolean;
  subTitle?: string;
  data?: any;
  buttons?: {
    text: React.ReactNode;
    onClick: () => void;
    colour?: any;
  }[];
  children?: React.ReactNode;
};
const PaymentCard: FC<PaymentCardProps> = ({title,subTitle ,data , buttons}) => {
  return (
    <>
      <div className="bg-white px-4 py-5 sm:px-6 rounded-lg">
        <div className="flex flex-col">
          <h2 className="text-2xl font-semibold ">{title}</h2>
          <div className="flex w-full items-center">
            <span className="w-1.5 h-1.5 me-2 bg-yellow rounded-full"></span>
            {subTitle}
          </div>
        </div>

        <div className="pt-5">
        <InfoList
            data={data}
          />
        </div>
        {buttons && (
        <div className=" mt-4 flex space-x-4 d-flex align-items-center justify-content-center">
          {buttons.map((button, i) => (
            <Button colour={button.colour ??""}
              onClick={() => button.onClick()}
              key={i}
              fullWidth
              size="large"
            >
              {button.text}
            </Button>
          ))}
        </div>
      )}
        
      </div>
    </>
  );
};

export { PaymentCard };
