import { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import Modal from "../../../components/shared/overlays/Modal";
import { useHistory } from "react-router-dom";
import { createClient } from "../../../../../prismic";
import { filter } from "@prismicio/client";
import { useQuery } from "../../../helpers/QueryParamsHelper";

type Props = {
  url: string | undefined;
  title: string | undefined;
  show: boolean;
  onHide: any;
};

const VideoModal = ({ url, title, show, onHide }: Props) => {
  const history = useHistory();
  const query = useQuery();
  const [video, setVideo] = useState<any>(null);

  useEffect(() => {
    (async () => {
      const videoUid = query.get("video");
      if (!title && videoUid) {
        const response = await createClient
          .get({
            fetchLinks: "category.name",
            filters: [
              filter.at("document.type", "videos"),
              filter.at("my.videos.uid", videoUid),
            ],
          })
          .catch(() => {
            return;
          });
        if (response && response?.results?.length > 0) {
          const mappedVideo = {
            uid: response.results[0].uid,
            videoTitle: response.results[0].data.title,
            videoURL: response.results[0].data.vidurl.url,
          };
          setVideo(mappedVideo);
        }
      }
    })();
  }, [query, title]);

  const close = () => {
    onHide();
    query.delete("video");
    history.replace({
      search: query.toString(),
    });
    setVideo(null);
  };

  return (
    <>
      <Modal
        open={show || video != null}
        title={title ?? video?.videoTitle}
        onClose={close}
        hideSubmit
      >
        <ReactPlayer
          playing
          url={url ?? video?.videoURL}
          width="100%"
          height="auto"
          controls={true}
          allow="fullscreen; picture-in-picture"
        />
      </Modal>
    </>
  );
};

export { VideoModal };
