import React from "react";
import Skeleton from "@mui/material/Skeleton";

export interface BadgeProps extends React.HTMLAttributes<HTMLDivElement> {
  colour?:
    | "blue"
    | "green"
    | "darkGreen"
    | "yellow"
    | "red"
    | "outlineRed"
    | "gray"
    | "darkRed"
    | "brown"
    | "darkBlue";
  fullWidth?: boolean;
  roundedPill?: boolean;
  roundedT?: boolean;
  notificationBadge?: boolean;
  loading?: boolean;
  title?: any;
  size?: "md" | "sm" | "xs" | "smBadge" | "mdBadge";
}

export default function Badge({
  colour = "blue",
  title,
  fullWidth,
  roundedPill = true,
  roundedT = true,
  notificationBadge,
  loading = false,
  size = "sm",
  ...props
}: BadgeProps) {
  return (
    <div
      className={`${notificationBadge ? " text-white bg-danger p-0" : "px-3"}
      ${fullWidth ? "w-full" : ""} ${
        {
          green: "bg-[#CFF6DB] text-[#163A5E] border-transparent",
          darkGreen: "bg-green text-white border-transparent",
          yellow: "bg-yellow text-white border-transparent",
          blue: "bg-blue-100 text-blue-900 border-transparent",
          gray: "bg-gray-100  border-gray-400",
          red: "bg-red-100 text-danger border-transparent",
          darkRed: "bg-danger text-white border-transparent",
          outlineRed: "bg-white text-danger border-danger border",
          brown: "bg-brown text-white border-transparent py-1",
          darkBlue: "bg-darkBlue text-white border-transparent py-1",
        }[colour]
      } 
      ${
        {
          smBadge: "w-4 h-4 ml-1",
          mdBadge: "w-5 h-5 ml-2",
          xs: "px-[4px] py-[2px] leading-[15px] h-fit",
          sm: "py-0 leading-[20px]",
          md: "py-2",
        }[size]
      } 
      ${roundedPill ? "rounded-full" : "rounded-md"}
      ${roundedT ? "" : "rounded-ss-none rounded-se-none"} 
      flex relative items-center justify-center font-semibold text-xs transition-all`}
      {...props}
    >
      {loading ? <Skeleton width={50} className="me-2" /> : <>{title}</>}
    </div>
  );
}
