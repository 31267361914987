import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactGA from "react-ga4";
import { useHistory } from "react-router-dom";
import {
  addLesson,
  getJobForAddingLessons,
  updateLessonDate,
  getLessonsRecurringOptions,
  getAdiLessonTypes,
} from "../../../../core/services/job-service";
import {
  concatDateAndTime,
  convertToTimeFormat,
} from "../../../helpers/DateHelper";
import {
  checkCanAddLesson,
  checkHasBadge,
} from "../../../../core/services/adi-service";
import {
  formatDisplayedDate,
  formatDisplayedTimeWithDuration,
  getTimes,
} from "../../../../core/services/date-service";
import Modal from "../../shared/overlays/Modal";
import Dropdown from "../../shared/forms/Dropdown";
import Slider from "../../shared/forms/Slider";
import Alert from "../../shared/overlays/Alert";
import InfoList from "../../shared/data/InfoList";
import { useSharedData } from "../../../pages/providers/SharedDataProvider";
import dayjs from "dayjs";
import TimeInput from "../../shared/forms/TimeInput";
import toast from "react-hot-toast";
import Notification from "../../shared/overlays/Notification";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import Input from "../../shared/forms/Input";
import { LessonRecurringOptionEnum } from "../../../../core/models/enums/lesson-recurring_option-enum";
import moment from "moment";
import { EditInSeries } from "./EditInSeries";
import DateInput from "../../shared/forms/DateInput";
import CreatableDropdown from "../../shared/forms/CreatableDropdown";

interface IProps {
  firstDate: Date | null;
  jobId: string | undefined;
  id: string | undefined;
  onClose: any;
  duration: number;
  show: any;
  lessonsUpdated: () => void;
  isPrivateJob: any;
  refreshParent?: any;
  recurringOption?: number;
  canUpdateSeries?: boolean;
  seriesStartDate?: Date | null;
  seriesEndDate?: Date | null;
  lesson_type?: string | undefined;
  student_email?: string | undefined;
}
const AddEditLessonModel: FC<IProps> = (props) => {
  const history = useHistory();
  const defaultDuration = 60;
  const [loading, setLoading] = useState<boolean>(false);
  const [initialLoading, setInitialLoading] = useState<boolean>(false);
  const [lessonsDate, setLessonsDate] = useState<Date | null>(null);
  const [lessonsTime, setLessonsTime] = useState<string>("");
  const [warning, setWarning] = useState<string | null>(null);
  const [duration, setDuration] = useState<number>(defaultDuration);
  const [durationRange, setDurationRange] = useState([defaultDuration]);
  const [showEditLessonCard, setShowEditLessonCard] = useState<boolean>(false);
  const [msgCondition, setMsgCondition] = useState<any>();
  const [isPrivateJob, setPrivateJob] = useState<any>(null);
  const [hasExpiredBadge, setHasExpiredBadge] = useState<boolean>(false);
  const [hasUnApprovedBadge, setHasUnApprovedBadge] = useState<boolean>(false);
  const [hasBadge, setHasBadge] = useState<boolean>(false);
  const [recurringOptions, setRecurringOptions] = useState<any>();
  const [defaultRecurringOption, setDefaultRecurringOption] =
    useState<number>(0);
  const [showHoursExceedLimit, setShowHoursExceedLimit] =
    useState<boolean>(false);
  const [exceedLimit, setExceedLimit] = useState<boolean>(false);
  const [totalNubmerOfHours, setTotalNubmerOfHours] = useState<number>();
  const [exceedLimitError, setExceedLimitError] = useState<boolean>(false);
  const [showConfirmLessonInSeries, setShowConfirmLessonInSeries] =
    useState<boolean>(false);
  const [editAllLessons, setEditAllLessons] = useState<boolean>(false);
  const [hasApprovedBadge, setHasApprovedBadge] = useState<boolean>(false);
  const [lessonTypes, setLessonTypes] = useState<Array<any>>([]);
  const [showOtherLessonType, setShowOtherLessonType] =
    useState<boolean>(false);
  const [otherLessonType, setOtherLessonType] = useState<string>("");

  const updateLessonsSchema = Yup.object().shape({
    date: Yup.date().required("Date is required"),
    time: Yup.string().required("Time is required").nullable(),
    duration: Yup.number().required("Duration is required").nullable(),
    job_id: props.jobId
      ? Yup.string()
      : Yup.string().required("Learner is required").nullable(),
    recurring_option: Yup.number()
      .required("Recurring option is required")
      .nullable(),
    start_date: Yup.date().required(),
    end_date: Yup.date().required(),
    other_lesson_type: Yup.string().when(["lesson_type"], {
      is: (lesson_type: string) =>
        lesson_type != null && lesson_type.toLowerCase() == "other",
      then: () => Yup.string().required("Lesson type is required"),
      otherwise: () => Yup.string().nullable(),
    }),
  });

  const { data, updateSharedData } = useSharedData();
  const [currentJobs, setCurrentJobs] = useState<Array<any>>([]);
  const [selectedLessonType, setSelectedLessonType] = useState<any>(null);

  const maxDuration = 360;
  const step = 15;
  const minDuration = 30;
  const timeOptions = getTimes(5, 21, 5);

  useEffect(() => {
    if (props.show) {
      checkHasBadgeFunction();
      setInitialLoading(true);
      if (props.canUpdateSeries) {
        setShowConfirmLessonInSeries(true);
      }
      setDuration(props.duration * 60);
      setDurationRange([props.duration * 60]);
      getLessonsRecurringOptions().then((data) => {
        const recurringOptions = data?.data?.results;
        if (recurringOptions) {
          if (props?.recurringOption)
            setDefaultRecurringOption(props?.recurringOption);
          else
            setDefaultRecurringOption(
              recurringOptions?.find(
                (c: any) => c.order === LessonRecurringOptionEnum.Never
              )?.id
            );
          const mappedRecurringOptions = Array.from(recurringOptions).map(
            (option: any) => {
              return { value: option?.id, label: option.name };
            }
          );
          setRecurringOptions(mappedRecurringOptions);
        }
      });
      if (!props.isPrivateJob) {
        checkCanAddLesson().then((data) => {
          setHasExpiredBadge(data?.data?.results.has_expired_badge == 1);
          setHasUnApprovedBadge(data?.data?.results.has_unapproved_badge == 1);
          setHasBadge(data?.data?.results.has_badge == 1);
        });
      } else {
        fetchLessonTypes();
      }
      if (!props.jobId) {
        getJobForAddingLessons()
          .then((res) => {
            const jobs = res?.data?.results;
            if (jobs) {
              const mappedJobs = Array.from(jobs).map((job) => {
                return {
                  value: job.job_id,
                  label: job.student_full_name,
                  duration: job.total_lessons_duration,
                  isPrivateStudent: job.is_private_student,
                  student_email: job?.student_email,
                };
              });
              setCurrentJobs(mappedJobs);
              if (mappedJobs.length == 1) {
                formik.setFieldValue("job_id", mappedJobs[0]?.value);
                setPrivateJob(mappedJobs[0].isPrivateStudent);
                fetchLessonTypes();
              }
            }
            setInitialLoading(false);
          })
          .catch((error) => {
            setInitialLoading(false);
          });
      } else {
        setInitialLoading(false);
      }
    }
  }, [props.jobId, props.show]);

  const fetchLessonTypes = async function () {
    let adiLessonTypes = await getAdiLessonTypes();
    if (adiLessonTypes) {
      setLessonTypes(adiLessonTypes.results);
      if (props.lesson_type) {
        let i = adiLessonTypes.results.findIndex(
          (l: any) => l.value == props.lesson_type
        );
        setSelectedLessonType(adiLessonTypes.results[i]);
        formik.setFieldValue("lesson_type", adiLessonTypes.results[i].value);
      } else {
        setSelectedLessonType(adiLessonTypes.results[0]);
        formik.setFieldValue("lesson_type", adiLessonTypes.results[0].value);
      }
    }
  };

  const checkHasBadgeFunction = async () => {
    const hasBadgeCheck = await checkHasBadge();
    setHasApprovedBadge(
      hasBadgeCheck?.results?.has_approved_badge ? true : false
    );
  };

  const formik = useFormik({
    initialValues: {
      date: lessonsDate,
      time: lessonsTime,
      duration: duration,
      job_id: props.jobId,
      recurring_option: defaultRecurringOption,
      start_date: editAllLessons
        ? moment(props?.seriesStartDate).toDate()
        : lessonsDate,
      end_date: editAllLessons
        ? moment(props?.seriesEndDate).toDate()
        : lessonsDate,
      lesson_type: null,
      other_lesson_type: null,
    },
    enableReinitialize: true,
    validationSchema: updateLessonsSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      if (props.isPrivateJob && !values.lesson_type) {
        values.lesson_type = lessonTypes[0].value;
      }
      setLoading(true);
      if (getLessonDateTime(values.date, values.time)) {
        handleSubmitForm(values, true);
      } else {
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  function getLessonDateTime(date: any, time: any) {
    var firstLessonDate: Date | null = date;
    var firstLessonTime: string = time;
    if (firstLessonTime != "") {
      firstLessonDate?.setHours(parseInt(firstLessonTime.split(":")[0]));
      firstLessonDate?.setMinutes(parseInt(firstLessonTime.split(":")[1]));
      if (firstLessonDate) {
        return concatDateAndTime(firstLessonDate, time);
      }
    }
    return null;
  }

  useEffect(() => {
    if (!showEditLessonCard) mapFirstLessonDate();
  }, [props.firstDate, data?.selectedDate]);

  useEffect(() => {
    formik.setStatus(null);
  }, [formik.values.date, formik.values.duration, formik.values.time]);

  function sendLessonLoggedToast(isAdd: boolean) {
    toast.custom(
      () => (
        <Notification
          colour="green"
          title={`Lesson successfully ${
            isAdd ? "logged" : "changed"
          }. The learner will be notified ${
            isPrivateJob ?? props.isPrivateJob
              ? " by email."
              : " in their PassMeFast Portal."
          }`}
          description={""}
          Icon={ExclamationTriangleIcon}
        />
      ),
      {
        duration: 5000,
        position: "top-center",
      }
    );
  }

  function handleSubmitForm(values: any, checkOnly = false) {
    var firstLessonDateStr = getLessonDateTime(values.date, values.time);
    var startDateStr = getLessonDateTime(values.start_date, values.time);
    var endDateStr = getLessonDateTime(values.end_date, values.time);
    if (firstLessonDateStr) {
      var durationHours = values.duration / 60;
      if (props.id) {
        let args: any = {
          job_id: values.job_id,
          lesson_date: firstLessonDateStr,
          duration: durationHours,
          check_only: checkOnly,
          recurring_option: values.recurring_option,
          start_date: startDateStr,
          end_date: endDateStr,
          exceedLimit: exceedLimit,
          edit_entire_series: editAllLessons,
        };
        if (props.isPrivateJob || isPrivateJob) {
          args = { ...args, lesson_type: formik.values.lesson_type };
        }
        updateLessonDate(props.id, args)
          .then((res) => {
            ReactGA.event("update_lesson");
            if (checkOnly && props.isPrivateJob == 0) {
              setMsgCondition(res.data.results);
              onShow();
              setShowEditLessonCard(true);
            } else {
              props.lessonsUpdated();
              closeModal();
              if (
                (props.isPrivateJob == 1 && props?.student_email) ||
                props.isPrivateJob == 0
              )
                sendLessonLoggedToast(false);
            }
          })
          .catch((response) => {
            if (response?.results?.numberOfHoursExceedsLimit) {
              setShowHoursExceedLimit(true);
              formik.setSubmitting(false);
              setTotalNubmerOfHours(response?.results?.totalNumberOfHours);
            }
            if (response?.results?.numberOfHoursExceedsCourseLimit) {
              setExceedLimitError(true);
            }
            var error = response.errors?.length
              ? response.errors[0].errorMessage
              : "Sorry, an error has occured";
            formik.setStatus(error);
            setLoading(false);
            formik.setSubmitting(false);
            setWarning(null);
          });
      } else {
        let args: any = {
          lesson_date: firstLessonDateStr,
          duration: durationHours,
          job_id: values.job_id,
          check_only: checkOnly,
          recurring_option: values.recurring_option,
          start_date: firstLessonDateStr,
          end_date: endDateStr,
          exceedLimit: exceedLimit,
        };
        if (props.isPrivateJob || isPrivateJob) {
          args = { ...args, lesson_type: formik.values.lesson_type };
        }
        addLesson(args)
          .then((res) => {
            ReactGA.event("add_lesson");
            let isPrivate =
              props.isPrivateJob == null ? isPrivateJob : props.isPrivateJob;
            if (checkOnly && isPrivate == 0) {
              onShow();
              setMsgCondition(res.data.results);
              setShowEditLessonCard(true);
            } else {
              props.lessonsUpdated();
              closeModal();
              if (
                (isPrivate == 1 && props.jobId && props?.student_email) ||
                (isPrivate == 1 &&
                  !props.jobId &&
                  currentJobs.filter(
                    (job: any) => job.value == values.job_id
                  )[0]?.student_email) ||
                isPrivate == 0
              )
                sendLessonLoggedToast(true);
              props.refreshParent();
              setShowHoursExceedLimit(false);
              setExceedLimit(false);
            }
          })
          .catch((response) => {
            if (response?.results?.numberOfHoursExceedsLimit) {
              setShowHoursExceedLimit(true);
              formik.setSubmitting(false);
              setTotalNubmerOfHours(response?.results?.totalNumberOfHours);
            }
            if (response?.results?.numberOfHoursExceedsCourseLimit) {
              setExceedLimitError(true);
            }
            var error = response.errors?.length
              ? response.errors[0].errorMessage
              : "Sorry, an error has occured";
            formik.setStatus(error);
            setLoading(false);
            formik.setSubmitting(false);
            setWarning(null);
          });
      }
    }
  }

  function increaseDuration() {
    if (formik.values.duration < maxDuration) {
      onDurationChanged([formik.values.duration + step]);
    }
  }

  function decreaseDuration() {
    if (formik.values.duration > minDuration) {
      onDurationChanged([formik.values.duration - step]);
    }
  }

  const mapFirstLessonDate = () => {
    if (props.firstDate) {
      let date: Date | null = props.firstDate
        ? new Date(props.firstDate)
        : data?.selectedDate
        ? data?.selectedDate
        : null;
      setLessonsDate(date);
      if (date) {
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let time =
          (hours > 9 ? hours : "0" + hours) +
          ":" +
          (minutes > 9 ? minutes : "0" + minutes);
        setLessonsTime(time);
      }
    } else if (data?.selectedDate) {
      let date: Date | null = props.firstDate
        ? new Date(props.firstDate)
        : data?.selectedDate
        ? data?.selectedDate
        : null;
      setLessonsDate(date);
    }
  };

  const setDateValue = (e: any) => {
    if (e != null) {
      formik.setFieldValue("date", e);
      if (!editAllLessons) {
        formik.setFieldValue("start_date", e);
        formik.setFieldValue("end_date", e);
      }
    } else {
      formik.setFieldValue("date", null);
      formik.setFieldValue("start_date", null);
    }
  };

  function closeModal() {
    setLoading(false);
    formik.resetForm();
    setWarning(null);
    formik.setStatus(undefined);
    formik.setSubmitting(false);
    setShowEditLessonCard(false);
    setDuration(defaultDuration);
    setDurationRange([props.duration * 60]);
    setEditAllLessons(false);
    setPrivateJob(false);
    props.onClose();
  }

  const onDurationChanged = (e: any) => {
    setDurationRange(e);
    formik.setFieldValue("duration", e[0]);
  };

  const onShow = () => {
    setLoading(false);
    formik.setSubmitting(false);
  };

  const confirmEditLesson = () => {
    formik.setSubmitting(true);
    handleSubmitForm(formik.values);
  };

  const onSubmit = () => {
    if (showConfirmLessonInSeries) {
      setShowConfirmLessonInSeries(false);
    } else {
      if (hasExpiredBadge && !hasUnApprovedBadge) {
        history.push("/ADI/profile/documents");
      } else if (showEditLessonCard) {
        confirmEditLesson();
      } else if (showHoursExceedLimit) {
        setExceedLimit(true);
        formik.handleSubmit();
      } else {
        formik.handleSubmit();
      }
    }
  };

  const lessonData = () => {
    let data: {
      icon:
        | "pmf-icon-clock"
        | "pmf-icon-calendar"
        | "pmf-icon-reschedule"
        | undefined;
      value: string | React.ReactNode | undefined;
    }[] = [
      {
        icon: "pmf-icon-calendar",
        value: (
          <>
            {editAllLessons
              ? formatDisplayedDate(formik.values.start_date)
              : formatDisplayedDate(formik.values.date) ?? "-"}{" "}
            {formik.values.recurring_option !==
              LessonRecurringOptionEnum.Never && formik.values.end_date != null
              ? "to " + formatDisplayedDate(formik.values.end_date)
              : null}
          </>
        ),
      },
      {
        icon: "pmf-icon-clock",
        value:
          formatDisplayedTimeWithDuration(
            formik.values.date,
            formik.values.duration
          ) ?? "-",
      },
      {
        icon: "pmf-icon-reschedule",
        value: recurringOptions?.find(
          (c: any) => c.value === formik.values.recurring_option
        )?.label,
      },
    ];
    return data;
  };

  return (
    <>
      <Modal
        open={props.show}
        onClose={() => {
          mapFirstLessonDate();
          closeModal();
          setExceedLimitError(false);
          setShowHoursExceedLimit(false);
          setShowConfirmLessonInSeries(false);
        }}
        onSubmit={() => onSubmit()}
        submitText={
          hasExpiredBadge && !hasUnApprovedBadge
            ? "Upload Document"
            : showEditLessonCard || showHoursExceedLimit
            ? "Confirm"
            : showConfirmLessonInSeries
            ? "Ok"
            : "Save"
        }
        closeText="Cancel"
        title={
          showEditLessonCard
            ? "Confirm lesson(s)"
            : props.id
            ? editAllLessons
              ? "Edit Series"
              : "Edit lesson"
            : "Add lesson(s)"
        }
        disabled={formik.isSubmitting || loading}
        hideSubmit={hasUnApprovedBadge || exceedLimitError}
        disableSubmit={
          formik.isSubmitting ||
          loading ||
          (!hasApprovedBadge &&
            (isPrivateJob === 0 || props.isPrivateJob === 0))
        }
      >
        {showConfirmLessonInSeries ? (
          <EditInSeries
            editAll={editAllLessons}
            setEditAll={setEditAllLessons}
            isPrivateJob={props?.isPrivateJob}
            type="lesson"
          />
        ) : (
          <form noValidate autoFocus={false}>
            {initialLoading ? (
              <>
                <h5>Loading ...</h5>
              </>
            ) : (
              <>
                {!hasApprovedBadge &&
                  (isPrivateJob === 0 || props.isPrivateJob === 0) && (
                    <Alert
                      colour="red"
                      icon="pmf-icon-info"
                      title="You can't add a lesson for this learner until your badge has been approved"
                    />
                  )}
                {hasExpiredBadge && !hasUnApprovedBadge && (
                  <Alert
                    colour="gray"
                    icon="pmf-icon-info"
                    title="Your ADI badge has expired. Please upload your new badge before logging more lessons."
                  />
                )}

                {!hasExpiredBadge && hasUnApprovedBadge && (
                  <Alert
                    colour="gray"
                    icon="pmf-icon-info"
                    title="Your badge is awaiting approval. It may take a day or two. Please check in later!"
                  />
                )}

                {!hasUnApprovedBadge && !hasExpiredBadge && (
                  <>
                    {!props.jobId &&
                      !showEditLessonCard &&
                      !showHoursExceedLimit &&
                      !exceedLimitError && (
                        <Dropdown
                          {...formik.getFieldProps("job_id")}
                          options={currentJobs}
                          label="Learner"
                          placeholder="Select learner"
                          error={
                            formik.touched.job_id && formik.errors.job_id
                              ? true
                              : false
                          }
                          errorMsg={formik.errors.job_id}
                          required={true}
                          name="job_id"
                          disabled={formik.isSubmitting}
                          id="job_id"
                          isSearchable
                          value={{
                            value: formik.values.job_id,
                            label: !formik.values.job_id
                              ? currentJobs.length == 1
                                ? currentJobs[0]?.label
                                : "Select job"
                              : currentJobs.find(
                                  (c) => c.value === formik.values.job_id
                                )?.label,
                          }}
                          onChange={async (e) => {
                            formik.setFieldValue("job_id", e?.value);
                            setPrivateJob(
                              currentJobs.find((c) => c.value === e?.value)
                                ?.isPrivateStudent
                            );
                            await fetchLessonTypes();
                          }}
                          onBlur={() => {
                            formik.setFieldTouched("job_id");
                          }}
                        />
                      )}
                    {!showEditLessonCard &&
                      !showHoursExceedLimit &&
                      !exceedLimitError && (
                        <>
                          {!props?.id || (props?.id && !editAllLessons) ? (
                            <>
                              <input
                                type="text"
                                className="focus:border-0 focus:ring-0 h-0 flex -mt-3"
                              />{" "}
                              {/* Hidden input for initial focus */}
                              <div>
                                <DateInput
                                  openToDate={formik.values.date ?? new Date()}
                                  selected={formik.values.date}
                                  isClearable={false}
                                  name="date"
                                  id="date"
                                  disabled={formik.isSubmitting || loading}
                                  required={true}
                                  onChange={(update) => {
                                    setDateValue(update);
                                  }}
                                  inputLabel="Lesson Date"
                                  inputPlaceholder="Lesson Date"
                                  inputName="transaction_date_input"
                                  error={formik?.errors?.date ? true : false}
                                  errorMsg={formik?.errors?.date}
                                />
                              </div>
                              {formik.status ? (
                                <p className="text-sm text-red-600" id="error">
                                  * {formik.status}
                                </p>
                              ) : (
                                warning &&
                                formik.values.time && (
                                  <Alert
                                    colour="gray"
                                    icon="pmf-icon-info"
                                    title={warning}
                                  />
                                )
                              )}
                              <TimeInput
                                {...formik.getFieldProps("time")}
                                label="Lesson Start Time"
                                error={
                                  formik.touched.time && formik.errors.time
                                    ? true
                                    : false
                                }
                                errorMsg={formik.errors.time}
                                disabled={formik.isSubmitting || loading}
                                value={dayjs(formik.values.time, "H:mm")}
                                onOpen={() => {
                                  formik.setFieldTouched("time");
                                  formik.setFieldTouched("date");
                                }}
                                minutesStep={5}
                                onChange={(newValue) => {
                                  formik.setFieldValue(
                                    "time",
                                    dayjs(newValue).format("H:mm").toString()
                                  );
                                  // if (props.id)
                                  //   setLessonsTime(dayjs(newValue).format("H:mm").toString())
                                }}
                              />
                              <div className="flex flex-col">
                                <label
                                  htmlFor="duration"
                                  className="text-sm font-medium text-darkBlue"
                                >
                                  Duration{" "}
                                  <span className="text-sm text-red-500">
                                    *
                                  </span>
                                </label>

                                <Slider
                                  decreaseFunction={decreaseDuration}
                                  increaseFunction={increaseDuration}
                                  step={step}
                                  minValue={minDuration}
                                  maxValue={maxDuration}
                                  rangeValues={durationRange}
                                  onChange={onDurationChanged}
                                  showInput={false}
                                  formik={formik}
                                  formatFun={(index: number) =>
                                    convertToTimeFormat(durationRange[index])
                                  }
                                  minValueFormatted={`${minDuration} mins`}
                                  maxValueFormatted={`${
                                    maxDuration / 60
                                  } hours`}
                                />
                              </div>
                            </>
                          ) : (
                            <></>
                          )}

                          {props?.id &&
                            editAllLessons &&
                            formik.values.recurring_option ===
                              LessonRecurringOptionEnum.Never && (
                              <Alert
                                colour="orange"
                                icon="pmf-icon-info"
                                title="Warning: All other lessons will be deleted!"
                              />
                            )}

                          {props.isPrivateJob || isPrivateJob ? (
                            <div className="my-6">
                              <Dropdown
                                name="select_lesson_type"
                                id="select_lesson_type"
                                label="Lesson Type"
                                required={false}
                                value={selectedLessonType}
                                onChange={(e: any) => {
                                  setSelectedLessonType({
                                    label: e.label,
                                    value: e.value,
                                  });
                                  e.value.toLowerCase() == "other"
                                    ? setShowOtherLessonType(true)
                                    : setShowOtherLessonType(false);
                                  if (
                                    e.value.toLowerCase() == "other" &&
                                    otherLessonType
                                  ) {
                                    formik.setFieldValue(
                                      "lesson_type",
                                      otherLessonType
                                    );
                                  } else {
                                    formik.setFieldValue(
                                      "lesson_type",
                                      e.value
                                    );
                                  }
                                }}
                                options={lessonTypes}
                                error={
                                  formik.touched.lesson_type &&
                                  formik.errors?.lesson_type
                                    ? true
                                    : false
                                }
                              />
                              {showOtherLessonType ? (
                                <div className="my-6">
                                  <Input
                                    {...formik.getFieldProps(
                                      "other_lesson_type"
                                    )}
                                    type="text"
                                    label="Please enter lesson type"
                                    name="lesson_type"
                                    value={otherLessonType}
                                    required={false}
                                    disabled={formik.isSubmitting}
                                    onChange={(e: any) => {
                                      setOtherLessonType(e.target.value);
                                      formik.setFieldValue(
                                        "lesson_type",
                                        e.target.value
                                      );
                                    }}
                                    id="lesson_type"
                                    error={
                                      formik.touched.other_lesson_type &&
                                      formik.errors?.other_lesson_type
                                        ? true
                                        : false
                                    }
                                    errorMsg={formik.errors.other_lesson_type}
                                  />
                                </div>
                              ) : (
                                false
                              )}
                            </div>
                          ) : null}
                          {!props?.id || (props?.id && editAllLessons) ? (
                            <>
                              {editAllLessons && formik.status ? (
                                <p className="text-sm text-red-600" id="error">
                                  * {formik.status}
                                </p>
                              ) : (
                                warning &&
                                formik.values.time && (
                                  <Alert
                                    colour="gray"
                                    icon="pmf-icon-info"
                                    title={warning}
                                  />
                                )
                              )}

                              <Dropdown
                                {...formik.getFieldProps("recurring_option")}
                                options={recurringOptions}
                                label="Make Recurring"
                                placeholder=""
                                error={
                                  formik.touched.recurring_option &&
                                  formik.errors.recurring_option
                                    ? true
                                    : false
                                }
                                errorMsg={formik.errors.recurring_option?.toString()}
                                required={true}
                                name="recurring_option"
                                disabled={formik.isSubmitting}
                                id="recurring_option"
                                value={{
                                  value: formik.values.recurring_option,
                                  label: !formik.values.recurring_option
                                    ? recurringOptions?.length > 0
                                      ? recurringOptions[0]?.label
                                      : "Make Recurring"
                                    : recurringOptions?.find(
                                        (c: any) =>
                                          c.value ===
                                          formik.values.recurring_option
                                      )?.label,
                                }}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    "recurring_option",
                                    e?.value
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched("recurring_option");
                                }}
                              />

                              {formik.values.recurring_option !==
                                LessonRecurringOptionEnum.Never && (
                                <>
                                  <input
                                    type="text"
                                    className="focus:border-0 focus:ring-0 h-0 flex -mt-3"
                                  />{" "}
                                  {/* Hidden input for initial focus */}
                                  <div>
                                    <DateInput
                                      openToDate={
                                        formik.values.start_date ?? new Date()
                                      }
                                      selected={formik.values.start_date}
                                      isClearable={false}
                                      name="start_date"
                                      id="start_date"
                                      disabled={props?.id ? false : true}
                                      required={true}
                                      onChange={(e) => {
                                        if (e != null) {
                                          formik.setFieldValue("start_date", e);
                                          formik.setFieldValue("end_date", e);
                                        } else {
                                          formik.setFieldValue(
                                            "start_date",
                                            null
                                          );
                                        }
                                      }}
                                      inputLabel="Start Date"
                                      inputPlaceholder="Start Date"
                                      inputName="start_date"
                                      error={
                                        formik?.errors?.start_date
                                          ? true
                                          : false
                                      }
                                      errorMsg={formik?.errors?.start_date}
                                    />
                                  </div>
                                  <input
                                    type="text"
                                    className="focus:border-0 focus:ring-0 h-0 flex -mt-3"
                                  />{" "}
                                  {/* Hidden input for initial focus */}
                                  <div>
                                    <DateInput
                                      openToDate={
                                        formik.values.date ?? new Date()
                                      }
                                      selected={formik.values.end_date}
                                      isClearable={false}
                                      minDate={
                                        editAllLessons
                                          ? formik.values.start_date
                                          : formik.values.date
                                      }
                                      name="end_date"
                                      id="end_date"
                                      disabled={formik.isSubmitting || loading}
                                      required={
                                        formik.values.recurring_option !==
                                        LessonRecurringOptionEnum.Never
                                      }
                                      onChange={(e) => {
                                        if (e != null) {
                                          formik.setFieldValue("end_date", e);
                                        } else {
                                          formik.setFieldValue(
                                            "end_date",
                                            null
                                          );
                                        }
                                      }}
                                      inputLabel="End Date"
                                      inputPlaceholder="End Date"
                                      inputName="end_date"
                                      error={
                                        formik?.errors?.end_date ? true : false
                                      }
                                      errorMsg={formik?.errors?.end_date}
                                    />
                                  </div>
                                </>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      )}

                    {/* Start:: Confirm adding lesson & payment changes messages */}
                    {showEditLessonCard &&
                      !showHoursExceedLimit &&
                      !exceedLimitError && (
                        <div className="d-flex flex-column align-items-start justify-content-start">
                          {
                            <>
                              {loading == true && <h2>Loading...</h2>}
                              {loading != true && (
                                <>
                                  {msgCondition?.isAfterPT == 1 && (
                                    <Alert
                                      colour="red"
                                      icon="pmf-icon-info"
                                      title="Something doesn’t look right"
                                      description="This lesson is after the test date. Please double-check what you have entered or let us know if the learner’s test appointment has been pushed back."
                                    />
                                  )}

                                  <InfoList data={lessonData()} />

                                  {msgCondition != null && (
                                    <>
                                      {!hasBadge && (
                                        <Alert
                                          colour="gray"
                                          icon="pmf-icon-info"
                                          title="Payments cannot be made until your ADI Badge has been uploaded and approved"
                                        />
                                      )}
                                      {hasBadge &&
                                        msgCondition?.isRemoved == 1 && (
                                          <Alert
                                            colour="gray"
                                            icon="pmf-icon-info"
                                            title={`Your payment scheduled for ${msgCondition?.paymentDate} will be cancelled`}
                                          />
                                        )}
                                      {hasBadge &&
                                        msgCondition?.isLatePayment == 1 && (
                                          <Alert
                                            colour="gray"
                                            icon="pmf-icon-info"
                                            title="To be paid in advance, lessons should be scheduled with 5 working days' notice"
                                          />
                                        )}
                                      {((hasBadge &&
                                        msgCondition?.multipleChanges == 1) ||
                                        (hasBadge &&
                                          msgCondition?.isUpdated == 1)) && (
                                        <Alert
                                          colour="gray"
                                          icon="pmf-icon-info"
                                          title="Your payment schedule will be affected by this action, you can check the payment dates and amounts from the payment page."
                                        />
                                      )}
                                    </>
                                  )}
                                  {hasBadge &&
                                    msgCondition?.needPayment == 1 &&
                                    msgCondition?.multipleChanges == 0 && (
                                      <Alert
                                        colour="gray"
                                        icon="pmf-icon-info"
                                        title={`Your next payment will be made on ${msgCondition?.paymentDate}`}
                                      />
                                    )}
                                </>
                              )}
                            </>
                          }
                        </div>
                      )}

                    {/* Start:: Confirm adding lesson after exceeding limit */}
                    {showHoursExceedLimit && (
                      <div className="d-flex flex-column align-items-start justify-content-start">
                        {
                          <>
                            {loading == true && <h2>Loading...</h2>}
                            {loading != true && (
                              <>
                                <p className="font-semibold mb-2">
                                  {" "}
                                  You are about to schedule {
                                    totalNubmerOfHours
                                  }{" "}
                                  hours of lessons, are you sure you would like
                                  to continue?{" "}
                                </p>
                              </>
                            )}
                          </>
                        }
                      </div>
                    )}
                    {exceedLimitError && (
                      <div className="d-flex flex-column align-items-start justify-content-start">
                        {
                          <>
                            {loading == true && <h2>Loading...</h2>}
                            {loading != true && (
                              <>
                                <p className="font-semibold mb-2">
                                  The number of hours you attempted to log
                                  exceeds the course length. If the learner
                                  needs more hours, please ask them to contact
                                  PassMeFast.{" "}
                                </p>
                              </>
                            )}
                          </>
                        }
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </form>
        )}
      </Modal>
    </>
  );
};

export { AddEditLessonModel };
