import { Auth } from 'aws-amplify'

export const setHeader: any = async (xApiKey?: string) => {
    const authResponse = await Auth.currentSession()
    var token = authResponse.getIdToken().getJwtToken()

    const headers: any = {
        accept: 'application/json',
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
    }

    const impersonatorToken = localStorage.getItem(`impersonator-token`)
    if (impersonatorToken) {
        headers['Impersonator-Token'] = impersonatorToken
    }

    if (xApiKey) {
        headers['x-api-key'] = xApiKey
    }

    return headers
}