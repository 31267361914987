import { FC, useEffect, useState } from 'react'
import Button from '../../shared/elements/Button'
import InfoList from '../../shared/data/InfoList'
import Alert from '../../shared/overlays/Alert'

interface IProps {
  data: any | undefined
  show: boolean
  onClose: any
  accept: any
  isTest: boolean
  isSubmitting: boolean

}
const ClashDetailsDialog: FC<IProps> = ({ isTest, isSubmitting, data, show, onClose, accept }) => {
  const [mappingData, setMappingData] = useState<any>();

  useEffect(() => {
    if (show && data) {
      onShow();
    }
  }, [show, data])


  const onShow = () => {
    if (data.clashed.test_date) {
      setMappingData({
        type: 'Test',
        std_name: data.clashed.student_surname,
        job_ref: data.clashed.job_ref,
        date: data.clashed.test_date_str,
      })
    } else if (data.clashed.event_date) {
      setMappingData({
        type: 'Event',
        std_name: data.clashed.event_name,
        job_ref: data.clashed.event_location,
        date: data.clashed.event_date_str,
      })
    } else {
      setMappingData({
        type: 'Lesson',
        std_name: data.clashed.student_name,
        job_ref: data.clashed.job_ref,
        date: data.clashed.lesson_date_str,
      })
    }
  }

  return (
    <>
      <Alert description={`You already have the following appointment(s) on the day of this learner’s ${mappingData?.type.toLowerCase()}`} colour='orange'></Alert>

      <InfoList
            data={[
              {
                icon: "pmf-icon-user",
                value: mappingData?.std_name ?? '-',
              },
              {
                icon: "pmf-icon-calendar",
                value: mappingData?.date ?? '-',
              },
              {
                icon: "pmf-icon-suitcase",
                value: mappingData?.job_ref ?? "-",
              }
            ]}
          />

      <p className="text-sm mt-5">
        Are you sure you want to accept this {isTest ? 'test' : 'job'}?
      </p>

      <div className="sticky w-full bottom-0 left-0 px-0 py-4 bg-white z-[11]">
        <div className='w-full flex items-center justify-between space-x-2'>
        <Button
          colour="yellow"
          type="submit"
          className="w-1/2"
          size="large"
          disabled={isSubmitting}
          onClick={accept}
        >
          Yes
        </Button>
        <Button
          colour="outline"
          type="button"
          className="w-1/2"
          size="large"
          disabled={isSubmitting}
          onClick={onClose}
        >
          Cancel
        </Button>
        </div>
      </div>
    </>
  )
}

export { ClashDetailsDialog }


