import { FC } from 'react'
import GoShorty from './GoShorty';

const GoShortyWrapper: FC = () => {
  return (
    <>
        <GoShorty></GoShorty>
    </>
  )

}

export default GoShortyWrapper