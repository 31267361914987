import { FC, useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  CreditCardIcon,
  CurrencyPoundIcon,
  ExclamationTriangleIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";
import AccountSettingsModal from "./_modals/AccountSettingsModal";
import { ExpirePaymentModal } from "./_modals/ExpirePaymentModal";
import { NewPaymentModal } from "./_modals/NewPaymentModal";
import { CheckoutsList } from "./CheckoutsList";
import { RootState } from "../../../../../setup";
import { PersonalDataModel } from "../../../../core/models/personal-data-model";
import { Checkout } from "../../../../core/models/stripeConnect/checkout";
import {
  listCheckouts,
  getConnectBalance,
  getConnectAccount,
  createConnectAccount,
  setOnBoarded,
  getOnBoardLink,
  expire,
  generateDashboardLink,
} from "../../../../core/services/connect-service";
import EmptyState from "../../shared/elements/EmptyState";
import Notification from "../../shared/overlays/Notification";
import Button from "../../shared/elements/Button";
import { Skeleton } from "@mui/material";
import StackedList from "../../shared/data/StackedList";
import { toAbsoluteUrl } from "../../../helpers";
import { CreateAccountStripe } from "../stripe/CreateAccountStripe";
import { StripeBenefits } from "../stripe/StripeBenefits";

export const DigitalPaymentsComponent: FC<any> = () => {
  const personalData: PersonalDataModel = useSelector<RootState>(
    ({ auth }) => auth.personalData,
    shallowEqual
  ) as PersonalDataModel;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [showExpirePopup, setShowExpirePopup] = useState(false);
  const [balance, setBalance] = useState<any>({ amount: 0, negative: false });
  const [newPayment, setNewPayment] = useState(false);
  const [expiredCheckoutId, setExpiredCheckoutId] = useState<any>(null);
  const [account, setAccount] = useState<any>(null);
  const [checkouts, setCheckouts] = useState<Array<Checkout>>([new Checkout()]);
  const [viewDashboardClicked, setViewDashboardClicked] = useState(false);
  const [showUpdateAccount, setShowUpdateAccount] = useState(false);
  let onboard = urlSearchParams.get("onboard");

  const getCheckouts = () => {
    listCheckouts()
      .then((res) => {
        setCheckouts(res.data);
      })
      .catch(() => {});
  };

  const getBalance = () => {
    getConnectBalance()
      .then((res) => {
        setBalance(res.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getAccount = () => {
    setLoading(true);
    getConnectAccount()
      .then((res) => {
        setAccount(res.data);
        getBalance();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAccount();
    getCheckouts();
  }, []);

  useEffect(() => {
    if (onboard) {
      setOnBoardedLink();
    }
  }, [onboard]);

  const createAccount = () => {
    createConnectAccount({ email: personalData?.email })
      .then((res) => {
        setAccount(res.data);
        generateLink();
      })
      .catch(() => {});
  };

  const submit = () => {
    if (account?.onboarded === 0) {
      generateLink();
    } else if (account == null) {
      createAccount();
    }
  };

  const setOnBoardedLink = async () => {
    setOnBoarded()
      .then(() => {
        getAccount();
        getCheckouts();
      })
      .catch(() => {});
  };

  const generateLink = async () => {
    getOnBoardLink()
      .then((res: any) => {
        window.location.href = res.data.url ?? "";
      })
      .catch(() => {});
  };

  const openNewPayment = () => {
    setNewPayment(true);
  };

  const closeNewPayment = () => {
    setNewPayment(false);
    getCheckouts();
  };

  const expireCheckout = (checkoutId: any) => {
    setShowExpirePopup(false);
    expire(checkoutId)
      .then(() => {
        getCheckouts();
      })
      .catch(() => {
        toast.custom(
          () => (
            <Notification
              colour="red"
              title={"error expiring checkout"}
              description={""}
              Icon={ExclamationTriangleIcon}
            />
          ),
          {
            duration: 5000,
            position: "top-center",
          }
        );
      });
  };

  const viewDashboard = () => {
    setViewDashboardClicked(true);
    generateDashboardLink()
      .then((res) => {
        setViewDashboardClicked(false);
        // window.open(res.data.url ?? "", "_blank");
        window.location.href = res.data.url ?? "";
      })
      .catch(() => {
        setViewDashboardClicked(false);
        toast.custom(
          () => (
            <Notification
              colour="red"
              title={"Error getting dashboard link"}
              description={""}
              Icon={ExclamationTriangleIcon}
            />
          ),
          {
            duration: 5000,
            position: "top-center",
          }
        );
      });
  };

  const redirectToDetails = (checkoutId: number) => {
    history.push("/ADI/checkouts/" + checkoutId);
  };

  return (
    <>
      <Toaster />
      {account == null && !loading && (
        <div className="px-5">
          <CreateAccountStripe createLoading={loading} onCreateLoading={setLoading} setAccount={setAccount}/>

            <StripeBenefits isParentQuestionnaire={false} />
        </div>
      )}

      {account != null && account?.onboarded === 0 && !loading && (
        <div className="py-4 px-5">
          <EmptyState
            Icon={CreditCardIcon}
            title="Finish Account Setup"
            description="Your payment account setup is not yet completed. Please complete the onboarding process to start accepting direct payments from your learners."
          />
        </div>
      )}

      {loading ? (
        <div className="flex px-5">
          <Skeleton className="w-full" variant="rounded" height={100} />
        </div>
      ) : (
        <>
          {account != null && account?.onboarded === 1 && (
            <div className="px-5">
              <div className="grid grid-cols-2 gap-3 mt-1">
                <div className="relative rounded-[10px] bg-yellow text-gray-700">
                  <div className="py-4 px-3 flex flex-col text-center justify-center items-center">
                    <i className={`text-base iouu`}></i>
                    <p className="block text-sm font-light leading-relaxed text-inherit antialiased">
                      Balance
                    </p>
                    <h5 className="mb-2 mt-1 font-titlef text-3xl font-semibold text-blue-gray-900 antialiased">
                      £{Number.isInteger(+(balance.amount / 100)) ?  (balance.amount / 100) : (+(balance.amount / 100))?.toFixed(2)}
                    </h5>
                  </div>
                </div>

                <div className="grid grid-cols-1 gap-x-2">
                  <div className="bg-white rounded-lg p-[10px]">
                    <div className="flex justify-between items-center min-w-0 gap-x-3 h-full">
                      <img
                        className="flex-none rounded-full bg-gray-50 h-9 w-9"
                        src={toAbsoluteUrl("/assets/media/svg/money-in.svg")}
                        alt=""
                      />
                      <div className="min-w-0 flex-auto">
                        <p className="text-xs leading-5">
                          On the way{" "}
                        </p>
                        <p className="text-base font-semibold font-titlef ">
                          {loading ? (
                            <>
                              <Skeleton width={80} />
                            </>
                          ) : (
                            <> £{Number.isInteger(+(balance.on_the_way / 100)) ?  (balance.on_the_way / 100) : (+(balance.on_the_way / 100))?.toFixed(2)} </>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}

      {(account != null || loading) && (
        <div className="py-4 px-5">
          <div className="my-3 flex space-x-2 justify-between px-3 sm:px-5">
            {loading ? (
              <Skeleton className="w-1/2" variant="rounded" height={30} />
            ) : (
              <Button
                onClick={account?.onboarded === 1 ? openNewPayment : submit}
                colour="darkBlue"
                size="large"
                halfWidth={true}
                type="submit"
              >
                <span className="indicator-label">
                  {account?.onboarded === 1
                    ? "New Payment"
                    : "Continue Account Setup"}
                </span>
              </Button>
            )}
            {loading ? (
              <Skeleton className="w-1/2" variant="rounded" height={30} />
            ) : (
              <Button
                disabled={viewDashboardClicked}
                onClick={viewDashboard}
                colour="outline"
                size="large"
                halfWidth={true}
                Icon={CurrencyPoundIcon}
              >
                View Dashboard
              </Button>
            )}
          </div>

          <div className="flex">
            {loading ? (
              <Skeleton
                className="w-full mx-10"
                variant="rounded"
                height={30}
              />
            ) : (
              <Button
                onClick={() => setShowUpdateAccount(true)}
                colour="link"
                size="large"
                fullWidth
                Icon={PencilSquareIcon}
              >
                Update Account Settings
              </Button>
            )}
          </div>
        </div>
      )}

      {((account != null && account?.onboarded === 1) || loading) && (
        <>
          {/* START:: checkoutRows Area */}
          <div className="bg-white px-5">
            <h3 className="text-base font-bold pt-8 pb-4">Payment history</h3>
            {loading ? (
              <StackedList data={[{ loading: loading }]} />
            ) : (
              <CheckoutsList
                checkouts={checkouts}
                redirectToDetails={redirectToDetails}
                setExpiredCheckoutId={setExpiredCheckoutId}
                setShowExpirePopup={setShowExpirePopup}
              />
            )}
          </div>
          {/* END:: checkoutRows Area */}
        </>
      )}

      <ExpirePaymentModal
        continueSelected={() => expireCheckout(expiredCheckoutId)}
        show={showExpirePopup}
        onHide={() => {
          setShowExpirePopup(false);
        }}
      />
      <NewPaymentModal show={newPayment} onClose={closeNewPayment} />
      <AccountSettingsModal
        show={showUpdateAccount}
        onHide={() => setShowUpdateAccount(false)}
      />
    </>
  );
};
