import { Section } from "./section";

export class Assessment {
    id: string = "";
    date: string = "";
    score: number = 0;
    student_id: string | null = null;
    job_id: string | null = null;
    zendesk_deal_id: string | null = null;
    student_contact_id: string | null = null;
    adi_contact_id: string | null = null;
    sections: Array<Section> = []
    notes: Array<{note: string}> = []
    is_private_student: boolean = false
    student_email?: string
    student_name?: string
}