/* eslint-disable jsx-a11y/anchor-is-valid */
import { Dispatch, FC, SetStateAction, useState } from "react";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { getNextAuthAction, requestPassword } from "../redux/AuthCRUD";
import { toAbsoluteUrl } from "../../../helpers";
import Input from "../../../components/shared/forms/Input";
import PageHeader from "../../../components/shared/elements/PageHeader";
import Button from "../../../components/shared/elements/Button";

const loginSchema = Yup.object().shape({
  email: Yup.string().email("Wrong email format").required("Email is required"),
});

const initialValues = {
  email: "",
};

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/
interface IProps {
  setAuthAction: Dispatch<SetStateAction<string>>;
  setEmail: Dispatch<SetStateAction<string>>;
  children?: React.ReactNode;
}
const DispatchEmail: FC<IProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, { setStatus }) => {
      setLoading(true);
      setStatus(null);
      props.setEmail(values.email);
      goToNextAuthPage(values.email);
    },
  });

  const goToNextAuthPage = (email: string) => {
    getNextAuthAction(email)
      .then((res) => {
        const action = res.data.results;
        if (action == "forgot_password") {
          return requestPassword(email).then(() =>
            history.push("/auth/reset-password/" + email)
          );
        }
        props.setAuthAction(action);
      })
      .catch((err) => {
        if (err.errors?.length) {
          formik.setStatus(err.errors[0].errorMessage);
        } else {
          formik.setStatus(err.message);
        }
      })
      .finally(() => {
        formik.setSubmitting(false);
        setLoading(false);
      });
  };

  return (
    <>
      <form className="space-y-6" onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Heading */}
        <div className="text-center mb-8">
          {/* begin::Title */}
          <PageHeader title="Welcome to ADI Network" isCenter={true} />
          {/* end::Title */}
        </div>
        {/* end::Heading */}

        <div className="sm:mx-auto sm:w-full sm:max-w-md mb-8 flex">
          <img
            src={toAbsoluteUrl(
              `/assets/media/logos/intelligent-award-bronze-professional-support-provider-of-the-year.png`
            )}
            alt="Intelligent Instructor Awards 2024 Bronze - Professional Support Provider of the Year"
            className="mx-auto w-28 sm:w-52 flex-1"
          />
          <img
            src={toAbsoluteUrl(
              `/assets/media/logos/intelligent-award-bronze-product-of-the-year.png`
            )}
            alt="Intelligent Instructor Awards 2024 Bronze - Product of the Year"
            className="mx-auto w-28 sm:w-52 flex-1"
          />
        </div>
        {formik.status ? (
          <div className="mb-lg-4 alert alert-danger">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : (
          <></>
        )}
        {/* begin::Form group */}
        <p className="block text-base font-bold leading-5 text-darkBlue mt-4 -mb-2">
          Enter your email to register or login
        </p>
        <Input
          {...formik.getFieldProps("email")}
          type="email"
          label="Email"
          placeholder="you@example.com"
          error={formik.touched.email && formik.errors.email ? true : false}
          errorMsg={formik.errors.email}
          required={true}
          name="email"
          disabled={formik.isSubmitting}
          id="email"
        />
        {/* end::Form group */}
        {/* begin::Action */}
        <Button
          colour="yellow"
          type="submit"
          size="large"
          className="w-full"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className="indicator-label">Continue</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </Button>
        {/* end::Action */}
      </form>
    </>
  );
};

export { DispatchEmail };
