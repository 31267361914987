export class DocumentDataModel {
  id: string = '';
  document_title: string | null = '';
  document_url?: string = '';
  insurance_document_url?: string;
  other_document_url: string | null = '';
  full_url?: string;
  adi_id: string | null = '';
  created_at: string | null = '';
  updated_at: string | null = '';
  deleted_at: string | null = '';
  badges_id?: string;
  insurance_id?: string;
  expiry_date?: string | null = '';
  insurance_expiry_date?: string | null = '';
  badge_name?: string;
  car_id?: string = '';
  adi_number?: string = '';
  document_id: string | null = '';
  car_reg_number: string | null = null;
  car_make?: string;
  car_model?: string;
  car_color: string | null = null;
  insurance_provider?: string;
  badge_expiry_date_day: string = '1';
  badge_expiry_date_month: string = '';
  badge_expiry_date_year: string = '';
  insurance_expiry_date_year: string = '';
  insurance_expiry_date_month: string = '';
  insurance_expiry_date_day: string = '';
  document_type: string = '';
  transmission_type_id: any = null;
  zendesk_contact_id: any = null;
  rejection_reason_name?: string;
  job_id?: string;
  expiry_date_formatted?: string;
}
