import { FC } from "react";
import { Assessment } from "../../../../../../core/models/assessments/assessment";
import { PencilSquareIcon, ShareIcon } from "@heroicons/react/24/outline";
import Button from "../../../../shared/elements/Button";
import { OverallProgress } from "./OverallProgress";
import { ProgressBreakdown } from "./ProgressBreakdown";

type Props = {
  assessments: Array<Assessment>;
  setEditedAssessment: any;
  loading: boolean;
  allowShare: boolean,
  shareAssessment: () => void
};
const ProgressHistory: FC<Props> = ({ assessments, setEditedAssessment, loading, allowShare, shareAssessment }) => {

  return (
    <>
      {((assessments?.length > 0) || loading) && (
        <div>
          <OverallProgress assessment={assessments[0]} loading={loading} />
          {/* start:: update Progress button */}
          <div className="flex items-center justify-between mt-6 mb-5">
            <h2 className='text-sm'>
              Progress breakdown
            </h2>
            <div className="flex">
              {allowShare ?
              <>
              <Button
                      type='button'
                    onClick={shareAssessment}
                      size='icon'
                      fitWidth={true}
                      colour='outline'
                    className="mx-2 px-2"
                    >
                    <span className="flex items-center" title="Share progress with student">
                      <ShareIcon className="w-4 h-4" />{" "}
                      <span className="ms-1 leading-5 mt-1 hidden md:flex">Share</span>{" "}
                    </span>
                    </Button>
              </>
                 : null}
              <Button
                colour="outline"
                onClick={() => setEditedAssessment(assessments[0])}
                disabled={loading}
              >
                <div className="flex items-center">
                  <PencilSquareIcon className="w-4 h-4" />
                  <span className="ms-1 leading-none mt-1">Update Progress</span>
                </div>
              </Button>
            </div>
          </div>
          {/* end:: update Progress button */}

          <ProgressBreakdown assessment={assessments[0]} loading={loading} />
        </div>
      )}
    </>
  );
};
export { ProgressHistory };
