/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getSavedSteps } from "../../../core/services/adi-service";
import { AvailableJobList } from "./AvailableJobList";
import * as auth from "../auth/redux/AuthRedux";
import Alert from "../../components/shared/overlays/Alert";
import PageHeader from "../../components/shared/elements/PageHeader";
import Badge from "../../components/shared/elements/Badge";
import { QuestionnaireStepsEnum } from "../../../core/models/enums/questionnaire-steps-enum";
import { StarIcon } from "@heroicons/react/20/solid";

const NewJobsWrapper: FC = () => {
  const [showSortDialog, setShowSortDialog] = useState(false);
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const [isSorted, setIsSorted] = useState(false);
  const [isSmartSort, setIsSmartSort] = useState(false);
  const [filterCount, setFilterCount] = useState(0);
  const [showFilters, setShowFilters] = useState<boolean>(false);

  const [finishedBankingDetailsStep, setFinishedBankingDetailsStep] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [unsavedSteps, setUnsavedSteps] = useState<Array<number>>([
    1, 2, 3, 4, 5, 6, 7,
  ]);
  const [completeStep, setCompleteStep] = useState<boolean>(false);
  const [hasUnRatedCars, setHasUnRatedCars] = useState<boolean>(false);
  const [uploadedRequiredDocs, setUploadedRequiredDocs] =
    useState<boolean>(false);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    checkUnsavedCount();
  }, []);

  const handleSetUploadedRequiredDocs = (uploadedReqDocs: boolean) => {
    setUploadedRequiredDocs(uploadedReqDocs);
  };
  function checkUnsavedCount() {
    getSavedSteps()
      .then((res) => {
        dispatch(
          auth.actions.setHasUnVerifiedData(
            res.data.results.has_un_verified_imported_data
          )
        );
        if (res.data.results.has_un_verified_imported_data) {
          history.push("/additional-details-adis");
        }
        let temp = [...unsavedSteps];
        setHasUnRatedCars(res.data.results.has_cars_with_no_rate);
        let savedSteps = res.data.results.saved_steps;
        if (savedSteps?.includes(QuestionnaireStepsEnum.bankDetails)) {
          setFinishedBankingDetailsStep(true);
        }
        setUnsavedSteps(temp);
        setLoading(false);
      })
      .catch((error) => {});
  }

  useEffect(() => {
    document.title = `ADI Network App | Jobs | ADI Network`;
  }, []);
  
  return (
    <div className="px-5">
      <div className="mb-5">
        <PageHeader
          title="New Jobs"
          buttons={
            !showFilters
              ? []
              : [
                  {
                    text: "Sort",
                    notification: (
                      <div className="flex-none ms-1">
                        {isSorted ? (
                          isSmartSort ? (
                            <div className="text-yellow">
                              <StarIcon className="h-4 w-4" />
                            </div>
                          ) : (
                            <div className="bg-danger h-1.5 w-1.5 rounded-full" />
                          )
                        ) : null}
                      </div>
                    ),
                    Icon: "sort",
                    onClick: () => setShowSortDialog(true),
                  },
                  {
                    text: "Filter",
                    notification: filterCount > 0 && (
                      <Badge
                        notificationBadge={true}
                        size="smBadge"
                        title={filterCount}
                      />
                    ),
                    Icon: "filter",
                    onClick: () => setShowFilterDialog(true),
                  },
                ]
          }
        />
      </div>

      {loading != true &&
        !finishedBankingDetailsStep &&
        uploadedRequiredDocs == true && (
          <Alert
            title="You can now view and accept jobs. Please provide your bank details so we can pay you when lessons begin."
            colour="gray"
          />
        )}
      <AvailableJobList
        key="jobList"
        completeSteps={() => {
          setCompleteStep(true);
        }}
        unsavedSteps={unsavedSteps}
        setUploadedReqDocs={handleSetUploadedRequiredDocs}
        showSortDialog={showSortDialog}
        hideSortDialog={() => setShowSortDialog(false)}
        setIsSorted={setIsSorted}
        isSmartSorted={isSorted && isSmartSort}
        setIsSmartSort={setIsSmartSort}
        showFilterDialog={showFilterDialog}
        hideFilterDialog={() => setShowFilterDialog(false)}
        setFilterCount={setFilterCount}
        isShowFilter={showFilters}
        showFilters={() => setShowFilters(true)}
        hideFilters={() => setShowFilters(false)}
      />
      {/* <CurrentJobCard /> */}
    </div>
  );
};

export { NewJobsWrapper };
