/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { ResetPassword } from "./components/ResetPassword";
// import { toAbsoluteUrl } from '../../helpers'
import { ImpersonatedLogin } from "./components/ImpersonatedLogin";
import { Authentication } from "./components/Authentication";
import { ImpersonateTransit } from "./components/ImpersonateTransit";
// import { Footer } from '../../layout/components/Footer'
import { MobileLogin } from "./components/MobileLogin";
import { ConfirmPayment } from "./components/ConfirmPayment";
import { toAbsoluteUrl } from "../../helpers";

export function AuthPage() {
  useEffect(() => {
    document.title = `ADI Network App | Auth | ADI Network`;
  }, []);
  let location = useLocation();
  let returnUrl = location.search;
  if (location.search.indexOf("_gl=") != -1) {
    location.search = "";
  }
  if (returnUrl.indexOf("returnUrl") == -1) {
    returnUrl = "returnUrl=" + location.pathname + location.search;
  }
  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          {/* begin::Logo */}
          <img
            alt="ADI Network Logo"
            src={toAbsoluteUrl("/assets/media/logos/ADI-network-logo-sm.svg")}
            className="mx-auto h-10 w-auto"
          />
          {/* end::Logo */}
        </div>
        <div className="mt-10 mx-4 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-10 shadow sm:rounded-lg sm:px-12">
            {/* begin::Wrapper */}
            <Switch>
              <Route path="/auth/login" component={Authentication} />
              <Route path="/auth/mobile-login" component={MobileLogin} />
              <Route path="/auth/stripe-payment" component={ConfirmPayment} />
              <Route path="/auth/admin-login" component={ImpersonatedLogin} />
              <Route
                path="/auth/reset-password/:email"
                component={ResetPassword}
              />
              <Route path="/auth/transition" component={ImpersonateTransit} />
              <Redirect from="/auth" exact={true} to="/auth/login" />
              <Redirect to={"/auth/login?" + returnUrl} />
            </Switch>
            {/* end::Wrapper */}
          </div>
        </div>
      </div>
    </>
  );
}
