export class Car {
    public id: string|undefined;
    public frontendId: string|undefined;
    public car_year: number|undefined;
    public reg_number: string|undefined;
    public make: string|undefined;
    public model: string|undefined;
    public color: string|undefined;
    public has_brand_advertisement: number|undefined;
    public transmission_type_id: number|undefined;
    public fuel_type_id: number|undefined;
}