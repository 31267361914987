import { useFormik } from "formik";
import { FC } from "react";
import * as Yup from "yup";
import Modal from "../../../../shared/overlays/Modal";
import Alert from "../../../../shared/overlays/Alert";
import TextArea from "../../../../shared/forms/TextArea";
import RadioInputGroup from "../../../../shared/forms/RadioInputGroup";
import { SetStudentAsUbsent } from "../../../../../../core/services/test-service";

type Props = {
  show: any;
  onHide: any;
  onDismiss: any;
  jobId: any;
  jobRef: any;
  testId: any;
  is_private_student: any;
  openAddTestResult: any;
};

const LogTestStatus: FC<Props> = ({
  show,
  onHide,
  onDismiss,
  jobId,
  jobRef,
  testId,
  is_private_student,
  openAddTestResult,
}) => {
  const validationSchema = Yup.object().shape({
    is_learner_absented: Yup.number().required("This field is required"),
    is_continue: Yup.boolean()
      .when("is_learner_absented", ([is_learner_absented], schema) => {
        return is_learner_absented == 1 && is_private_student
          ? schema.required("This field is required")
          : schema;
      })
      .nullable(),
  });
  const formik = useFormik({
    initialValues: {
      is_learner_absented: null,
      failing_test_reasons: "",
      is_continue: null,
      job_id: jobId,
      job_ref: jobRef,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      if (values.is_learner_absented == 0) {
        openAddTestResult();
        handleClose();
      } else {
        const response = await SetStudentAsUbsent(testId, values);
        if (response) {
          formik.resetForm();
          onHide();
        } else {
          setStatus("Sorry, an error has occurred");
        }
      }
      setSubmitting(false);
    },
  });

  const handleClose = () => {
    onDismiss();
    formik.resetForm();
  };

  return (
    <>
      <form
        onSubmit={formik.handleSubmit}
        className="flex flex-col justify-content-between h-100"
      >
        <Modal
          open={show}
          onSubmit={formik.handleSubmit}
          submitText="Confirm"
          closeText="Cancel"
          title={"Log Test Result"}
          onClose={handleClose}
          disabled={formik.isSubmitting}
        >
          {formik.status ? (
            <Alert colour="orange" icon="pmf-icon-info" title={formik.status} />
          ) : (
            <></>
          )}

          <div className="flex flex-col mb-3">
            <RadioInputGroup
              name="is_learner_absented"
              label={"Did the learner attend the test?"}
              options={[
                {
                  label: "Yes",
                  value: 0,
                },
                {
                  label: "No",
                  value: 1,
                },
              ]}
              onChange={(is_absented) => {
                formik.setFieldValue("is_learner_absented", is_absented);
                if (is_absented == 1) {
                  if (is_private_student == 0) {
                    formik.setFieldValue("failing_test_reasons", "");
                    formik.setFieldValue("is_continue", false);
                  }
                }
              }}
              value={formik.values.is_learner_absented}
              disabled={formik.isSubmitting}
              error={
                formik.touched.is_learner_absented &&
                formik.errors.is_learner_absented != undefined
              }
              errorMsg={formik.errors.is_learner_absented?.toString()}
            />
          </div>

          {formik.values.is_learner_absented == 1 && (
            <div className="flex flex-col">
              <div className="my-3">
                <TextArea
                  name="failing_test_reasons"
                  disabled={formik.isSubmitting}
                  maxLength={255}
                  label=" Comments:"
                  className="form-control"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.failing_test_reasons}
                  placeholder="Add a Comment"
                />
              </div>
              {is_private_student === 1 && (
                <>
                  <RadioInputGroup
                    name="is_continue"
                    label={"Are you continuing with this learner?"}
                    options={[
                      { label: "Yes", value: true },
                      { label: "No", value: false },
                    ]}
                    onChange={(reason) => {
                      formik.setFieldValue("is_continue", reason);
                      formik.setFieldValue("failing_test_reasons", "");
                    }}
                    value={formik.values.is_continue}
                    disabled={formik.isSubmitting}
                    error={
                      formik.touched.is_continue &&
                      formik.errors.is_continue != undefined
                    }
                    errorMsg={formik.errors.is_continue?.toString()}
                  />
                </>
              )}
            </div>
          )}
        </Modal>
      </form>
    </>
  );
};

export { LogTestStatus };
