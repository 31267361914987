// import { useNavigate } from "react-router-dom";

import { useEffect } from "react";

const Page404 = () => {
  useEffect(() => {
    document.title = `ADI Network App | ADI Network`;
  }, []);
  // const navigate = useNavigate();

  // const handleBackToHome = () => navigate("/");

  return <></>;
};

export default Page404;
