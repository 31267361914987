import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import {UserModel} from '../models/UserModel'
import {getUserByToken, getUserPersonalData} from './AuthCRUD'
import { PersonalDataModel } from '../../../../core/models/personal-data-model';

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  Register: '[Register] Action',
  Confirm: '[Confirm] Action',
  EmailConfirmed: '[EmailConfirmed] Action',
  ForgotPassword: '[ForgotPassword] Action',
  ResetPassword: '[ResetPassword] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
  SetUser: '[Set User] Action',
  SetIsLogin: '[Set IsLogin] Action',
  SetIsMobile:'[Set IsMobile] Action',
  PersonalDataRequested: '[PersonalDataRequested] Action',
  SetPersonalData:'[Set personal] Action',
  SetHasUnVerifiedData: '[Set HasUnVerifiedData] Action',
  SetMobileVersion:'[Set MobileVersion] Action'
  
}

const initialAuthState: IAuthState = {
  user: undefined,
  personalData: undefined,
  email: undefined,
  password: undefined,
  isLogin:false,
  isMobile:false,
  hasUnVerifiedData:false,
  mobileVersion:undefined,
}

export interface IAuthState {
  user?: UserModel
  personalData?: PersonalDataModel
  email?: string
  password?: string
  isLogin?:boolean
  isMobile?:boolean
  hasUnVerifiedData?:boolean,
  mobileVersion?: string
}

export const reducer = persistReducer(
  {storage, key: 'v100-demo1-auth', whitelist: ['user', 'email','password','isLogin','isMobile','personalData','hasUnVerifiedData','mobileVersion']},
  (state: IAuthState = initialAuthState, action: ActionWithPayload<IAuthState>) => {
    switch (action.type) {
      case actionTypes.Login: {
        return {...state, user: undefined}
      }

      case actionTypes.Confirm: {
        const email = action.payload?.email
        const password = action.payload?.password
        return {...state, email, password}
      }

      case actionTypes.EmailConfirmed: {
        return {...state, email: undefined, password: undefined}
      }

      case actionTypes.ForgotPassword: {
        const email = action.payload?.email
        return {...state, email}
      }

      case actionTypes.ResetPassword: {
        return {...state, email: undefined}
      }

      case actionTypes.Register: {
        return {...state, user: undefined}
      }

      case actionTypes.Logout: {
        return {...state,email:undefined,password:undefined, user: undefined,isLogin:false, personalData:undefined,hasUnVerifiedData:false}
      }

      case actionTypes.UserRequested: {
        return {...state, user: undefined}
      }

      case actionTypes.UserLoaded: {
        const user = action.payload?.user
        return {...state, user:user}
      }

      case actionTypes.SetUser: {
        const user = action.payload?.user
        return {...state, user:user}
      }
      

      case actionTypes.SetPersonalData: {
        const personalData = action.payload?.personalData
        return {...state, personalData:personalData}
      }
      case actionTypes.SetIsLogin: {
        const isLogin = action.payload?.isLogin
        return {...state, isLogin:isLogin}
      }
      case actionTypes.SetIsMobile: {
        const isMobile = action.payload?.isMobile
        return {...state, isMobile:isMobile}
      }
      case actionTypes.SetHasUnVerifiedData: {
        const hasUnVerified = action.payload?.hasUnVerifiedData
        return {...state, hasUnVerifiedData:hasUnVerified}
      }
      case actionTypes.SetMobileVersion: {
        const mobileVersion = action.payload?.mobileVersion
        return {...state, mobileVersion:mobileVersion}
      }
      
      default:
        return state
    }
  }
)

export const actions = {
  login: () => ({type: actionTypes.Login}),
  setHasUnVerifiedData:(hasUnVerifiedData:boolean)=>({type: actionTypes.SetHasUnVerifiedData, payload: {hasUnVerifiedData}}),
  setIsLogin:(isLogin:boolean)=>({type: actionTypes.SetIsLogin, payload: {isLogin}}),
  setIsMobile:(isMobile:boolean)=>({type: actionTypes.SetIsMobile, payload: {isMobile}}),
  setMobileVersion:(mobileVersion:string)=>({type: actionTypes.SetMobileVersion, payload: {mobileVersion}}),

  confirm: (email: string, password: string) => ({
    type: actionTypes.Confirm, payload: {email, password}
  }),
  emailConfirmed: () => ({
    type: actionTypes.EmailConfirmed
  }),
  forgotPassword: (email: string) => ({
    type: actionTypes.ForgotPassword, payload: {email}
  }),
  resetPassword: () => ({
    type: actionTypes.ResetPassword
  }),
  register: () => ({type: actionTypes.Register}),
  logout: () => ({type: actionTypes.Logout}),
  requestUser: () => ({
    type: actionTypes.UserRequested,
  }),
  fulfillUser: (user: UserModel) => ({type: actionTypes.UserLoaded, payload: {user}}),
  setUser: (user: UserModel) => ({type: actionTypes.SetUser, payload: {user}}),
  requestPersonalData: () => ({
    type: actionTypes.PersonalDataRequested,
  }),  
  setPersonalData: (personalData: PersonalDataModel) => ({type: actionTypes.SetPersonalData, payload: {personalData}}),
}

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.EmailConfirmed, function* emailConfirmedSaga() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.ResetPassword, function* resetPasswordSaga() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    yield put(actions.fulfillUser(yield getUserByToken()))
  })
  
  yield takeLatest(actionTypes.UserLoaded, function* personalDataRequested() {
    yield put(actions.setPersonalData(yield getUserPersonalData()))
  })
  
  yield takeLatest(actionTypes.UserRequested, function* personalDataRequested() {
    yield put(actions.setPersonalData(yield getUserPersonalData()))
  })
  yield takeLatest(actionTypes.PersonalDataRequested, function* personalDataRequested() {
    yield put(actions.setPersonalData(yield getUserPersonalData()))
  })
}
