import { FC, useEffect, useState } from 'react'
import { RefundPaymentModal } from './_modals/RefundPaymentModal'
import { getCheckout } from '../../../../core/services/connect-service'
import { Checkout } from '../../../../core/models/stripeConnect/checkout'
import { CheckoutStatus } from '../../../../core/models/enums/checkout-status-enum'
import { useParams } from 'react-router-dom'
import { PaymentStatus } from '../../../../core/models/enums/payment-status-enum'
import Badge from '../../shared/elements/Badge'
import Button from '../../shared/elements/Button'
import { StudentCard } from '../../shared/cards/StudentCard'
import Alert from '../../shared/overlays/Alert'

export const DirectPaymentDetails: FC = () => {
    const [checkoutDetails, setCheckoutDetails] = useState(new Checkout())
    const [showRefund, setShowRefund] = useState(false);
    const { checkoutId }: any = useParams()
    const [loading, setLoading] = useState<boolean>(false)

    const getCheckoutDetails = () => {
        setLoading(true)
        getCheckout(checkoutId)
            .then((res) => {
                setCheckoutDetails(res.data)
                setLoading(false)
            })
            .catch(() => {
                setLoading(false)
            })
    };

    useEffect(() => {
        getCheckoutDetails();
    }, []);


    const openShowRefund = () => {
        setShowRefund(true);
    }

    const closeShowRefund = () => {
        setShowRefund(false);
        getCheckoutDetails();
    }

    const invoicesRows = checkoutDetails.items?.map(item => {
        return (
            <div className="flex justify-between">
                <dt>{item.name}</dt>
                <dd className="">&pound;
                    {(item.amount_total / 100).toFixed(2)}</dd>
            </div>
        )
    })

    const mapStatus = (): { title: string, colour: "blue" | "green" | "darkGreen" | "yellow" | "red" | "outlineRed" | "gray" | "darkRed" } | undefined => {
        if (checkoutDetails.payment_status === PaymentStatus.paid) {
            return {
                title: 'Paid',
                colour: "green"
            }
        }
        if (checkoutDetails.payment_status === PaymentStatus.unpaid && checkoutDetails.checkout_status !== CheckoutStatus.expired) {
            return {
                title: 'Unpaid',
                colour: "darkRed"
            }
        }
        if (checkoutDetails.checkout_status === CheckoutStatus.expired) {
            return {
                title: 'Expired',
                colour: "gray"
            }
        }
        if (checkoutDetails.payment_status === PaymentStatus.refunded) {
            return {
                title: 'Refunded',
                colour: "yellow"
            }
        }
    }

    return (
        <>
            {loading && (
                <h3> Loading... </h3>
            )}

            {!loading && (
                <div className='bg-white p-5'>
                    <RefundPaymentModal show={showRefund} onClose={closeShowRefund} checkout={checkoutDetails} />

                    <div className="lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 xl:gap-x-24">
                        <div className="lg:col-start-2">
                            <div className="flex justify-between">
                                <dl className="text-sm font-medium">

                                    <h2 className="text-2xl font-extrabold ">Invoice</h2>
                                    <dd className="mt-1 mb-2">#{checkoutDetails.id}</dd>
                                </dl>
                            </div>

                            <div className="flex justify-start">
                                <Badge
                                    roundedPill={false}
                                    title={mapStatus()?.title}
                                    colour={mapStatus()?.colour}
                                />
                            </div>

                            <div className="w-full mt-5">

                                <StudentCard
                                    title={checkoutDetails.student_name?.toString()}
                                    email={checkoutDetails?.student_email?.toString()}
                                    phone=""
                                    description=''
                                    pmfIcon={false}
                                    location={""}
                                    loading={loading}
                                />
                            </div>

                            <h4 className='text-base font-bold mb-2 mt-5'>Summary</h4>

                            <dl className="space-y-4 p-4 text-sm font-medium shadow rounded">
                                {checkoutDetails.items.length > 0 && <>
                                    {invoicesRows}
                                </>}
                                <div className="flex justify-between border-t border-gray-300 pt-4">
                                    <dt>Subtotal</dt>
                                    <dd className="">
                                        &pound;{(checkoutDetails.amount_subtotal / 100).toFixed(2)}
                                    </dd>
                                </div>

                                {checkoutDetails.payment_status === PaymentStatus.paid &&
                                    <div className="flex justify-between border-t border-gray-300 pt-4">
                                        <dt>Processing Fee</dt>
                                        <dd className="">
                                            &pound;{(checkoutDetails.fee_paid / 100).toFixed(2)}
                                        </dd>
                                    </div>
                                }
                                {checkoutDetails.checkout_status === CheckoutStatus.completed &&
                                    <div className="flex justify-between border-t border-gray-300 pt-4">
                                        <dt>Amount Received</dt>
                                        <dd className="">
                                            &pound;{(checkoutDetails.net_amount / 100).toFixed(2)}
                                        </dd>
                                    </div>
                                }
                                {checkoutDetails.payment_status === PaymentStatus.refunded &&
                                    <div className="flex justify-between border-t border-gray-300 pt-4">
                                        <dt>Initial Amount Received</dt>
                                        <dd className="">&pound;
                                            {((checkoutDetails.amount_total - checkoutDetails.fee_paid) / 100).toFixed(2)}
                                        </dd>
                                    </div>
                                }
                                {checkoutDetails.payment_status === PaymentStatus.refunded &&
                                    <div className="flex justify-between border-t border-gray-300 pt-4">
                                        <dt>Amount Refunded</dt>
                                        <dd className="">&pound;
                                            {((checkoutDetails.amount_total) / 100).toFixed(2)}
                                        </dd>
                                    </div>
                                }
                            </dl>

                            {(checkoutDetails.payment_status === PaymentStatus.refunded && checkoutDetails.net_amount < 0) && (
                                <div className="my-4">
                                    <Alert

                                        title="Why is my net amount negative?"
                                        description="Because you have refunded this payment, the full
                                            amount paid was sent back to the learner, however we
                                            still had to process this payment so we have kept
                                            the initial processing fee."
                                        colour="gray" icon='pmf-icon-info'
                                    />
                                </div>
                            )}
                            {checkoutDetails.payment_status === PaymentStatus.paid && (
                                <Button
                                    colour='yellow'
                                    onClick={openShowRefund}
                                    fullWidth
                                    size='large'
                                >
                                    Refund
                                </Button>
                            )}
                        </div>
                    </div>

                </div>)
            }
        </>
    )
}