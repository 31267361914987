import { FC, useEffect } from "react";
import useScript from "../../helpers/components/useScript";
import ReactGA from "react-ga4";
import PageHeader from "../../components/shared/elements/PageHeader";

const DiscountPage: FC = () => {
  useScript("https://tyviso.com/rewards-page/loader.js");
  ReactGA.event("open_pass_perks_tab");
  
  useEffect(() => {
    document.title = `ADI Network App | Discount & Rewards | ADI Network`;
  }, []);
  
  return (
    <>
      <div className="mb-5">
        <PageHeader title="Discount & Rewards" backToURL="/ADI/more" />
      </div>
      <tyviso-rewards partnerID="63906288c2883c645ff0f28a"></tyviso-rewards>
    </>
  );
};

export default DiscountPage;
