import { FC, useState, useEffect } from 'react';
import { JobDefaultView } from '../../../../core/models/job-default-view';
import { getADIJobDefaultViewOptions, getJobDefaultViewOptions, updateADIJobDefaultViewOptions } from '../../../../core/services/job-service';
import { useFormik } from 'formik';
import * as Yup from 'yup'
import PageHeader from '../../shared/elements/PageHeader';
import Alert from '../../shared/overlays/Alert';
import Button from '../../shared/elements/Button';
import RadioInput from '../../shared/forms/RadioInput';
import { useHistory } from 'react-router-dom';
import { Skeleton } from '@mui/material';

export const DefaultJobSettings: FC<any> = () => {

    const [loading, setLoading] = useState(false)
    const [jobDefaultViews, setJobDefaultViews] = useState<Array<JobDefaultView>>([])
    const [selected, setSelected] = useState<number>()
    const [submitted, setSubmitted] = useState(false)
    const [saved, setSaved] = useState(false)
    const history = useHistory();


    useEffect(() => {
        setLoading(true)
        setSaved(false)
        const fetchData = async () => {
            const jobDefaultViewsPromise = getJobDefaultViewOptions()
                .then((res) => {
                    setJobDefaultViews(res.data.results)
                })
            Promise.all([jobDefaultViewsPromise]).then(() => {
                getADIJobDefaultViewOptions().then((res) => {
                    setSelected(Number(res.data?.results?.setting_value))
                    setLoading(res === null)
                })
            })

        }
        fetchData()
    }, [])

    const updateDefaultViewSchema = Yup.object().shape({
        jobs_default_view_id: Yup.number().required()
    })

    const formik = useFormik({
        initialValues: {
            jobs_default_view_id: selected,
        },
        enableReinitialize: true,
        validationSchema: updateDefaultViewSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            setStatus('')
            if (selected) {
                updateADIJobDefaultViewOptions(selected)
                    .then((data) => {
                        setSubmitting(false)
                        scrollToTop()
                        setSaved(true)
                    })
                    .catch((error) => {
                        setSubmitting(false)
                        scrollToTop()
                        if (
                            error &&
                            error.errors &&
                            error.errors.length > 0
                        ) {
                            setSubmitting(false)
                            setStatus(error.errors[0].errorMessage)
                        } else {
                            setSubmitting(false)
                            setStatus('Sorry, an error has occured')
                        }
                    })
            } else {
                setSubmitting(false)
            }
        },
    })

    const setSubmit = () => {
        if (submitted === false) {
            setSubmitted(true)
        }
    }

    var jobDefaultViewsRows = (
        <RadioInput
            loading={loading}
            name="jobs_default_view_id"
            options={jobDefaultViews.map(option => {
                return {
                    value: option.id,
                    label: option.jobs_default_view_name
                }
            })}
            error={(formik.touched.jobs_default_view_id && formik.errors.jobs_default_view_id) ? true : false}
            errorMsg={formik.errors.jobs_default_view_id}
            onClick={(value) => { formik.setFieldValue('jobs_default_view_id', value); onSelectedViewChanged(value) }}
            value={formik.values.jobs_default_view_id}
            disabled={formik.isSubmitting}
            required={false}
        />
    )

    const onSelectedViewChanged = (id: any) => {
        if (formik.isSubmitting)
            return;
        setSelected(id);
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }

    return (<>
        <div className='mb-5'>
            <PageHeader title='Default Job Settings' backToURL='/ADI/settings/overview' />
        </div>
            <div className='bg-white py-4 px-5'>
                {saved ? (
                    <Alert colour='blue' title='Successfully saved' icon='pmf-icon-info' />
                ) : (
                    <></>
                )}
                <div className='col-12 m-auto col-lg-12'>
                    <form onSubmit={formik.handleSubmit}>
                        <div className='card card--form-container'>
                            {formik.status ? (
                                <Alert description={formik.status} colour="red"></Alert>
                            ) : (
                                <></>
                            )}
                            <div
                                className={
                                    selected === null && submitted
                                        ? 'form-group row is-invalid'
                                        : 'form-group row'
                                }
                            >
                                <div className='d-flex justify-content-between'>
                                    {selected === null && submitted && (
                                        <Alert colour='red' title='Job matching option is required' icon='pmf-icon-info' />
                                    )}
                                </div>

                                <div className='col-12'>
                                {loading ? <Skeleton height={30} className="w-full" /> :
                                    <h4 className='text-base font-bold '>Show me jobs…</h4>}
                                    <div className='clearfix'>{jobDefaultViewsRows}</div>
                                </div>
                            </div>
                            <div className="my-3 grid grid-cols-2 gap-2">
                            {loading ? <Skeleton width={150} height={50} animation="wave"></Skeleton> :
                                <Button
                                    type='submit'
                                    title='Save'
                                    disabled={formik.isSubmitting}
                                    onClick={() => setSubmit()}
                                    colour="yellow"
                                    id='kt_sign_up_submit'
                                >
                                    <span className='indicator-label'> Save</span>
                                </Button>}
                                
                            {loading ? <Skeleton width={150} height={50} animation="wave"></Skeleton> :
                                <Button
                                    disabled={formik.isSubmitting}
                                    onClick={() => history.push("/ADI/settings/overview")}
                                    colour="outline"
                                    size="large"
                                >
                                    <span className='indicator-label'> Cancel</span>
                                </Button>}
                            </div>
                        </div>
                    </form>
                </div>
            </div >
    </>)
}