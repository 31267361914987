import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import * as Yup from "yup";
import { scrollToFirstError } from "../../../helpers/ScrollHelper";
import Modal from "../../shared/overlays/Modal";
import Input from "../../shared/forms/Input";
import DatePicker from "react-datepicker";
import Dropdown from "../../shared/forms/Dropdown";
import {
  addAdiProducts,
  addMoneyIn,
  getAdiProducts,
  getPaymentMethods,
  getPaymentMethodsMoneyIn,
  getTransaction,
  updateMoneyIn,
} from "../../../../core/services/payment-service";
import {
  getAdiPrivateStudents,
  getPrivateStudentLessons,
} from "../../../../core/services/job-service";
import { PaymentMethods as paymentMethodsEnum } from "../../../../core/models/enums/payment-methods-enum";
import RadioInputGroup from "../../shared/forms/RadioInputGroup";
import { MoneyIn } from "../../../../core/models/money-in-model";
import CreatableDropdown from "../../shared/forms/CreatableDropdown";
import { NewPaymentForm } from "../../account-settings/digital-payments/NewPaymentForm";
import { getConnectAccount } from "../../../../core/services/connect-service";
import Button from "../../shared/elements/Button";
import { useHistory } from "react-router-dom";
import EmptyState from "../../shared/elements/EmptyState";
import { CreditCardIcon } from "@heroicons/react/24/outline";
import { toAbsoluteUrl } from "../../../helpers";
import { DeleteMoneyModal } from "./DeleteMoneyModal";

interface IProps {
  show: any;
  onClose: any;
  onSubmit: any;
  transactionId?: any;
  refreshParent?: any;
}

const MoneyInModal: FC<IProps> = (props) => {
  const defaultProd = [
    {
      label: "Lesson",
      value: 1,
    },
    {
      label: "Test",
      value: 2,
    },
    {
      label: "Other Product",
      value: 3,
    },
  ];

  const paymentMethodOpt = [
    {
      label: "Digital Payment",
      value: "stripe",
    },
    {
      label: "Other",
      value: "other",
    },
  ];
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [privateStudents, setPrivateStudents] = useState<Array<any>>([]);
  const [privateLessons, setPrivateLessons] = useState<Array<any>>([]);
  const [adiProducts, setAdiProducts] = useState<Array<any>>([]);
  const [addedProducts, setAdiAddedProducts] = useState<any>();
  const [hasStripe, setHasStripe] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("stripe");
  const [showLessonsSelect, setShowLessonsSelect] = useState<boolean>(false);
  const requiredMsg = "This field is required";
  const history = useHistory();

  const validationSchema = Yup.object().shape({
    student_name: Yup.string().required(requiredMsg).nullable(),
    payment_method_id: Yup.number().required(requiredMsg),
    product_id: Yup.number().required(requiredMsg),
    amount: Yup.number().required(requiredMsg).nullable(),
    transaction_date: Yup.string().nullable(),
    product_type: Yup.string().required(requiredMsg),
    student_id: Yup.string().required(requiredMsg).nullable(),
    transaction_date_formatted: Yup.string().nullable(),
    job_id: Yup.string().required(requiredMsg).nullable(),
  });

  useEffect(() => {
    if(props.show)
      {
        getAccount();
        getAdiPrivateStudentList(null);
        getAdiProductList();
        getPaymentMethodList();
      }
  
  }, [props.show]);

  const formik = useFormik({
    initialValues: new MoneyIn(),
    validationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      if (values.transaction_date_formatted) {
        let date = new Date(values.transaction_date_formatted);
        values.transaction_date =
          date?.getFullYear() +
          "-" +
          (date?.getMonth() + 1) +
          "-" +
          date?.getDate();
      } else {
        let date = new Date();
        values.transaction_date =
          date?.getFullYear() +
          "-" +
          (date?.getMonth() + 1) +
          "-" +
          date?.getDate();
      }
      if (props.transactionId) {
        updateMoneyIn(props.transactionId, values).finally(() => {
          if (values.product_id == 3 && addedProducts != null) {
            saveAdiProduct(values.product_type);
          }
          setAdiAddedProducts(null);
          setSubmitting(false);
          props.onSubmit();
        });
      } else {
        addMoneyIn(values).finally(() => {
          if (values.product_id == 3 && addedProducts != null) {
            saveAdiProduct(values.product_type);
          }
          setAdiAddedProducts(null);
          setSubmitting(false);
          props.onSubmit();
          if (props.refreshParent) props.refreshParent();
        });
      }
    },
  });

  const getAdiPrivateStudentList = (jobId: null) => {
    getAdiPrivateStudents(false).then((res) => {
      setPrivateStudents(
        res?.data?.results.map((job: any) => {
          return {
            value: job.student_full_name,
            label: job.student_full_name,
            id: job.job_id,
            student_id: job.student_id,
          };
        })
      );
      let job = res?.data?.results.filter((t: any) => t.job_id === jobId);
      if (job.length > 0) {
        formik.setFieldValue("job_id", job[0]?.job_id);
        formik.setFieldValue("student_id", job[0]?.student_id);
        getStudentLessons(job[0]?.job_id);
      }
    });
  };

  const getPaymentMethodList = () => {
    getPaymentMethodsMoneyIn().then((res) => {
      setPaymentMethods(
        res.data.results.map((type: any) => {
          return {
            value: type.id,
            label: type.name,
          };
        })
      );
    });
  };

  useEffect(() => {
    if (props.transactionId) {
      getTransaction(props.transactionId).then((res) => {
        formik.setValues(res.data.results);
        if (res.data?.results?.product_type === "Lesson") {
          formik.setFieldValue("product_id", 1);
          formik.setFieldValue("product_type", "Lesson");
        } else if (res.data?.results?.product_type === "Test") {
          formik.setFieldValue("product_id", 2);
          formik.setFieldValue("product_type", "Test");
        } else {
          formik.setFieldValue("product_id", 3);
        }
        if (res.data?.results?.job_id) {
          getAdiPrivateStudentList(res.data.results.job_id);
        }

        if (
          res.data?.results?.payment_method_id !== paymentMethodsEnum.Stripe
        ) {
          setPaymentMethod("other");
        }

        formik.setFieldValue(
          "transaction_date_formatted",
          res.data?.results.formated_transaction_date
        );
      });
    }
  }, [props.transactionId]);

  const getStudentLessons = (jobId: string) => {
    getPrivateStudentLessons(jobId)
      .then((res) => {
        setPrivateLessons(
          res.data.results.map((type: any) => {
            return {
              value: type.lesson_date,
              label: type.format_lesson_date,
            };
          })
        );
      })
      .catch((response) => {});
  };

  const getAdiProductList = () => {
    getAdiProducts()
      .then((res) => {
        setAdiProducts(
          res.data.results.map((type: any) => {
            return {
              value: type.name,
              label: type.name,
            };
          })
        );
      })
      .catch((response) => {});
  };

  const saveAdiProduct = (productName: string) => {
    addAdiProducts({ name: productName })
      .then((res) => {
        getAdiProductList();
      })
      .catch((response) => {});
  };

  const getAccount = () => {
    getConnectAccount()
      .then((res) => {
        setHasStripe(true);
      })
      .catch(() => {
        setHasStripe(false);
      });
  };

  useEffect(() => {
    if (!props.transactionId) {
      formik.resetForm();
      setPaymentMethod("stripe");
    }
  }, [props.show]);

  useEffect(() => {
    scrollToFirstError(formik);
  }, [formik.errors, formik.isSubmitting, formik.isValidating]);

  return (
    <>
      <form noValidate>
        <Modal
          open={props.show}
          onClose={props.onClose}
          onSubmit={formik.submitForm}
          submitText={props.transactionId ? "Save changes" : "Save"}
          title="Money in"
          titleIcon={toAbsoluteUrl("/assets/media/svg/money-in.svg")}
          disabled={formik.isSubmitting}
          hideClose={
            formik.values?.payment_method_id === paymentMethodsEnum.Stripe
          }
          hideSubmit={
            formik.values?.payment_method_id === paymentMethodsEnum.Stripe
          }
          onActionLink={() => {
            props.onClose();
            setShowDeleteModal(true);
          }}
          actionLinkText="Delete activity"
          actionLinkColor="dangerLink"
          showActionLink={props.transactionId ? true : false}
        >
          <div>
            <h4 className="text-base font-bold">
              Payment method<span className="text-sm text-red-500">*</span>
            </h4>
          </div>

          <div className="">
            <RadioInputGroup
              name="payment_method"
              label=""
              options={paymentMethodOpt}
              onChange={(e) => {
                setPaymentMethod(e);
                if (e === "stripe") {
                  formik.setFieldValue(
                    "payment_method_id",
                    paymentMethodsEnum.Stripe
                  );
                } else {
                  formik.setFieldValue("payment_method_id", null);
                }
                formik.setFieldValue("product_id", null);
              }}
              value={paymentMethod}
              disabled={props.transactionId || formik.isSubmitting}
            />
          </div>

          {paymentMethod === "other" && (
            <Dropdown
              options={paymentMethods}
              label="Payment method"
              placeholder="Payment method"
              error={
                formik.touched.payment_method_id &&
                formik.errors?.payment_method_id
                  ? true
                  : false
              }
              errorMsg={formik.errors?.payment_method_id}
              required={true}
              name="payment_method_id"
              disabled={formik.isSubmitting}
              id="payment_method"
              value={paymentMethods.find(
                (t: any) => t.value === formik.values?.payment_method_id
              )}
              onChange={(e) => {
                formik.setFieldValue("payment_method_id", e?.value);
              }}
            />
          )}

          {paymentMethod !== "stripe" && (
            <>
              <Dropdown
                {...formik.getFieldProps("job_id")}
                options={privateStudents}
                label="Learner"
                placeholder="Learner"
                error={
                  formik.touched.job_id && formik.errors?.job_id ? true : false
                }
                errorMsg={formik.errors?.job_id}
                required={true}
                name="job_id"
                disabled={formik.isSubmitting}
                id="job_private"
                isSearchable
                value={privateStudents.find(
                  (t: any) => t.id === formik.values?.job_id
                )}
                onChange={(e: any) => {
                  formik.setFieldValue("student_name", e?.value);
                  formik.setFieldValue("job_id", e?.id);
                  formik.setFieldValue("student_id", e?.student_id);
                  getStudentLessons(e?.id);
                }}
                onBlur={() => {
                  formik.setFieldTouched("job_id");
                }}
              />
              <div className="-my-1">
                <h4 className="text-base font-bold">
                  Products<span className="text-sm text-red-500">*</span>
                </h4>
              </div>
              <div className="">
                <RadioInputGroup
                  {...formik.getFieldProps("product_id")}
                  name="Product"
                  label=""
                  options={defaultProd}
                  onChange={(e) => {
                    formik.setFieldValue("product_id", e);
                    if (e == 1) {
                      formik.setFieldValue("product_type", "Lesson");
                    } else if (e == 2) {
                      formik.setFieldValue("product_type", "Test");
                      setShowLessonsSelect(false);
                    } else {
                      formik.setFieldValue("product_type", null);
                      setShowLessonsSelect(false);
                    }
                    formik.setFieldValue("lesson_date", null);
                  }}
                  value={formik.values?.product_id}
                  disabled={formik.isSubmitting}
                  error={
                    formik.touched.product_id && formik.errors?.product_id
                      ? true
                      : false
                  }
                  errorMsg={formik.errors?.product_id}
                />
              </div>

              {formik.values?.product_id === 1 && (
                <div className="">
                  <Button
                    colour="link"
                    type="button"
                    size="fit"
                    fitWidth
                    onClick={() => setShowLessonsSelect(!showLessonsSelect)}
                  >
                    <span className="text-sm">
                      Assign this payment to a lesson
                    </span>
                  </Button>
                </div>
              )}

              {formik.values?.product_id === 1 && showLessonsSelect && (
                <div className="mt-5 -mb-4">
                  <Dropdown
                    {...formik.getFieldProps("lesson_date")}
                    options={privateLessons}
                    label="Lessons"
                    placeholder="Lessons"
                    error={
                      formik.touched.lesson_date && formik.errors?.lesson_date
                        ? true
                        : false
                    }
                    errorMsg={formik.errors?.lesson_date}
                    required={false}
                    name="lesson_date"
                    disabled={formik.isSubmitting}
                    id="lesson_date"
                    value={privateLessons.find(
                      (t: any) => t.value === formik.values?.lesson_date
                    )}
                    onChange={(e) => {
                      formik.setFieldValue("lesson_date", e?.value);
                    }}
                    onBlur={() => {
                      formik.setFieldTouched("lesson_date");
                    }}
                  />
                </div>
              )}

              {formik.values?.product_id === 3 && (
                <div className="mt-5 -mb-4">
                  <CreatableDropdown
                    name="products"
                    id="products"
                    placeholder="Product"
                    label="Product"
                    required={true}
                    value={adiProducts.find(
                      (t: any) => t.value === formik.values?.product_type
                    )}
                    isSearchable={true}
                    onChange={(e: any) => {
                      let selected = addedProducts;
                      if (e.__isNew__) {
                        selected = {
                          label: e.label,
                          value: e.value,
                        };
                        setAdiAddedProducts(selected);
                      } else {
                        selected = e;
                      }

                      formik.setFieldValue("product_type", e?.value);
                    }}
                    options={adiProducts}
                    error={
                      formik.touched.product_type && formik.errors?.product_type
                        ? true
                        : false
                    }
                  />
                </div>
              )}
              <div>
                <DatePicker
                  selected={
                    formik.values?.transaction_date_formatted != undefined
                      ? new Date(formik.values.transaction_date_formatted)
                      : new Date()
                  }
                  dateFormat={"dd/MM/yyyy"}
                  isClearable={false}
                  placeholderText="Payment date"
                  name="transaction_date_formatted"
                  id="transaction_date_formatted"
                  required={true}
                  onChange={(e) => {
                    if (e != null) {
                      formik.setFieldValue("transaction_date_formatted", e);
                    }
                  }}
                  customInput={
                    <Input
                      type="text"
                      label="Payment date"
                      placeholder="Payment date"
                      readonly={true}
                      name="transaction_date_input"
                    />
                  }
                  calendarClassName="w-full pt-5"
                  clearButtonClassName="!h-fit !top-[42%] after:!bg-white after:ring-1 after:ring-darkBlue after:!text-darkBlue"
                  popperClassName="!static !pt-0 w-full !translate-x-0"
                  wrapperClassName="-mb-3 w-full"
                />
              </div>

              <div className="-mt-4 -mb-4">
                <Input
                  {...formik.getFieldProps("amount")}
                  type="number"
                  label="Amount"
                  placeholder="Amount"
                  error={
                    formik.touched.amount && formik.errors?.amount
                      ? true
                      : false
                  }
                  errorMsg={formik.errors.amount}
                  required={true}
                  name="amount"
                  disabled={formik.isSubmitting}
                  id="amount"
                />
              </div>
            </>
          )}

          {formik.values?.payment_method_id === paymentMethodsEnum.Stripe &&
            (hasStripe === true ? (
              <>
                <NewPaymentForm
                  show={true}
                  onClose={props.onClose}
                  onSubmit={props.onSubmit}
                  refreshParent={props.refreshParent}
                />
              </>
            ) : (
              <>
                <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow border mb-5 mt-3 p-3">
                  <div className="-mt-8">
                    <EmptyState
                      Icon={CreditCardIcon}
                      title="Accepting Direct Payments"
                      description="Create an account to accept payments from your learners"
                    />
                  </div>

                  <Button
                    className="-mt-7 mb-2"
                    colour="yellow"
                    type="button"
                    fullWidth
                    size="large"
                    onClick={() => {
                      props.onClose();
                      history.push("/ADI/digital-payments");
                    }}
                  >
                    <span className="flex items-center leading-none">
                      Connect to stripe
                    </span>
                  </Button>
                </div>
              </>
            ))}
        </Modal>
      </form>

      <DeleteMoneyModal
        show={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onSubmit={props.onSubmit}
        transactionId={props.transactionId}
      />
    </>
  );
};

export { MoneyInModal };
