/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, Key, useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../helpers";
import { createClient } from "../../../../prismic";
import { RichTextField, filter } from "@prismicio/client";
import { PrismicLink, PrismicRichText } from "@prismicio/react";
import Button from "../../components/shared/elements/Button";
import { RootState } from "../../../../setup";
import { useSelector } from "react-redux";

const NewRelease: FC = () => {
  
  const isMobile = useSelector<RootState>(({ auth }) => auth.isMobile)

  const [isLoading, setIsLoading] = useState(false);
  const [disableNewRelease, setDisableNewRelease] = useState(false);
  const [pages, setPages] = useState<any>();

  // Initial load of articles
  useEffect(() => {
    //console.log("*** Initial load of articles ***");
    const fetchPages = async () => {
      setIsLoading(true);
      try {
        const response = await createClient.get({
          filters: [filter.at("document.type", "new-version-released")],
        });
        //console.log(response); // Log the response object
        // const mappedArticles = response?.results?.map((result) => ({
        //   name: result.data.title,
        //   linkURL: "/ADI/professional-development/" + result.uid,
        // }));
        //console.log(mappedArticles); // Log the response object
        setPages(response?.results);
        //console.log(pages); // Log the response object
      } catch (error) {
        console.error("Error while fetching articles:", error);
      }
      setIsLoading(false);
    };
    fetchPages();
  }, [createClient]);

  const hideNewRelease = () => {
    setDisableNewRelease(true)
  };

  return (
    <>
    {(isMobile && !disableNewRelease) ? <>
      {pages?.map((item: any, index: any) => (
        <div className="" key={index}>
          {item.data.show_on_portal ? (
            <div className="fixed top-0 right-0 left-0 bottom-0 bg-gray-100 z-[999]">
              <div className="overflow-hidden h-[100vh] adi-network-wrapper flex justify-between flex-col">
                <>
                  <div className="overflow-hidden pt-20 sm:py-20 lg:pb-32 xl:pb-36 relative grow">
                    <div className="mx-auto max-w-7xl px-12 sm:px-6 lg:px-8">
                      <div className="absolute bottom-0 left-0 flex justify-center w-full -z-10">
                        <img
                          alt=""
                          width="1600"
                          height="340"
                          decoding="async"
                          data-nimg="1"
                          src={toAbsoluteUrl("/assets/media/adin-launch.svg")}
                        />
                      </div>
                      <div className="grid grid-cols-1 gap-10">
                        <div className="w-full h-max text-center md:text-left">
                          <img
                            className="mb-6"
                            alt=""
                            width="1280"
                            height="340"
                            decoding="async"
                            data-nimg="1"
                            src={toAbsoluteUrl(
                              "/assets/media/logos/ADI-network-logo.svg"
                            )}
                          />
                          <div className=" text-lg">
                            <PrismicRichText field={item.data.content} />
                          </div>
                        </div>

                        <div className="flex flex-col px-4 sm:px-6 lg:px-8 justify-center items-center text-center py-6">
                          <h5 className="text-sm text-center"> Download the ADI Network app to your mobile device by selecting the appropriate option below.</h5>
                        <div className="flex space-x-4 items-center mt-5 justify-center md:justify-start">
                          
                          <a href="https://apps.apple.com/gb/app/adi-network-instructor-app/id6450789318" target="_blank">
                            <img alt="Apple App Store" loading="lazy"  decoding="async" data-nimg="1" 
                            src={toAbsoluteUrl(
                              "/assets/media/appstore.png"
                            )}
                            />
                          </a>
                          <a href="https://play.google.com/store/apps/details?id=com.passmefast.portal.adi" target="_blank">
                            <img alt="Google Play Store" loading="lazy" decoding="async" data-nimg="1"
                            src={toAbsoluteUrl(
                              "/assets/media/googleplay.png"
                            )}
                            />
                          </a>
                        </div>

                        <div className="mt-2">
                          <Button colour="link"
                          onClick={() => hideNewRelease()}>
                          Maybe later
                          </Button>
                        </div>
                        </div>

                        {/* <div className="flex w-full md:w-75 ps-0 ps-lg-5 ms-0 ms-lg-5 text-base">

                          {item.data.cta.map((item:any, index:any) => (
                            <Button colour={item.ctaType} size="large" 
                            onClick={(e) => {
                              e.stopPropagation();
                              window.open(item.ctaLink);
                            }}>
                              <PrismicLink field={item.ctaLink}>
                                {item.ctatext}
                                {item.ctaLink}
                              </PrismicLink>
                            </Button>
                          ))} 
                        </div> */}
                      </div>
                    </div>
                  </div>
                </>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      ))}
    </> 
    : null}
    
    </>
  );
};

export { NewRelease };
