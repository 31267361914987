import { FC, useState } from "react";
import Modal from "../../shared/overlays/Modal";

interface IProps {
  show: any;
  onClose: any;
  submit: any;
  acceptJobData: any;
}

const CourseProviderTermsAndConditionDialog: FC<IProps> = ({
  show,
  onClose,
  submit,
  acceptJobData,
}) => {
  const [disabled, setDisabled] = useState<boolean>(false);

  const totalPayment = Number.isInteger(acceptJobData?.total_rate)
    ? acceptJobData?.total_rate?.toLocaleString()
    : acceptJobData?.total_rate != null
    ? acceptJobData?.total_rate?.toFixed(2)
    : "";

  const courseHour = acceptJobData?.hours;

  return (
    <Modal
      open={show}
      onClose={onClose}
      submitText="Accept"
      title="Terms and conditions"
      onSubmit={() => {
        setDisabled(true);
        submit();
      }}
      closeText="Cancel"
      disabled={disabled}
    >
      <p className="font-semibold mb-2">
        {" "}
        This course is provided by PassMeFast. Before accepting, please note the
        following terms:{" "}
      </p>
      <p className="mb-1">
        {" "}
        1. You confirm that the student’s availability for lessons, course
        intensity, and stated pick-up location are suitable.{" "}
      </p>
      <p className="mb-1">
        {" "}
        2. If you accept this course and need to hand it back, you’ll be
        expected to refund any unused lesson hours within 5 working days.{" "}
      </p>
      <p className="mb-1">
        {" "}
        3. You’ll be paid £{totalPayment} for this {courseHour}-hour course.
        You’ll be paid in instalments of 10 hours before lessons begin. Please
        update the learner’s schedule at least 5 working days before the lesson
        so we can ensure you’re paid on time.{" "}
      </p>
      <p className="mb-1">
        {" "}
        4. The learner’s practical test is included within the hours of their
        course. It is your responsibility to communicate how many hours you
        reserve for test day with the learner. No additional fees should be
        requested for using your car during the test.{" "}
      </p>
      <p className="text-sm mt-2">
        {" "}
        If you’re unsure about anything, please contact ADI Network on 0333 188
        6627 so we can assist. Please see PassMeFast's full Terms and Conditions
        <a
          href="https://www.passmefast.co.uk/adi-terms"
          target="_blank"
          rel="noopener noreferrer"
          className="ml-1 underline cursor-pointer"
        >
          {" "}
          here
        </a>
      </p>
    </Modal>
  );
};

export { CourseProviderTermsAndConditionDialog };
