/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { DocumentList } from "../../components/account-settings/documents/DocumentList";
import { AccountSettings } from "../../components/account-settings/AccountSettings";
import { CarsList } from "../../components/account-settings/cars/CarsList";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "../../../../setup";
import { PersonalDataModel } from "../../../core/models/personal-data-model";
import { JobPreferences } from "../../components/account-settings/job-preferences/JobPreferences";
import { DefaultJobSettings } from "../../components/preferences/default-job-settings/DefaultJobSettings";
import { PersonalDetails } from "../../components/account-settings/personal-details/PersonalDetails";
import { AboutUser } from "../../components/account-settings/about-user/AboutUser";
import { BankingDetails } from "../../components/account-settings/bank-details/BankDetails";
import { AboutDrivingTuition } from "../../components/account-settings/drivingTuition/AboutDrivingTuition";
import { Communication } from "../../components/preferences/adi-communication/Communication";
import { RatesWrapper } from "../../components/account-settings/rates/RatesWrapper";
import { AppNotification } from "../../components/account-settings/app-notification/AppNotification";
import { CancellationPolicy } from "../../components/account-settings/cancellation-policy/CancellationPolicy";
import { CalendarIntegration } from "../../components/account-settings/calendar-integration/CalendarIntegration";

const SettingsWrapper: FC = () => {
  const history = useHistory();
  const redirectToAccountSettings = () => {
    history.push("/ADI/settings/overview");
  };
  const redirectToDashboard = () => {
    history.push("/ADI/dashboard");
  };
  const isMobile = useSelector<RootState>(({ auth }) => auth.isMobile);

  const personalData: PersonalDataModel = useSelector<RootState>(
    ({ auth }) => auth.personalData,
    shallowEqual
  ) as PersonalDataModel;

  useEffect(() => {
    document.title = `ADI Network App | Settings | ADI Network`;
  }, []);

  return (
    <>
      <Switch>
        <Route path="/ADI/settings/overview">
          <AccountSettings personalData={personalData} />
        </Route>
        <Route path="/ADI/settings/integration">
          <CalendarIntegration />
        </Route>
        <Route path="/ADI/profile/documents">
          <DocumentList />
        </Route>
        <Route path="/ADI/profile/about-u">
          <AboutUser
            parentWrapper="accountsettings"
            refresh={0}
            hasUnVerifiedProfile={false}
            selfRegisterDate={null}
            goToPrevTab={() => redirectToAccountSettings()}
            goToNextTab={() => redirectToAccountSettings()}
          />
        </Route>
        <Route path="/ADI/profile/car-details">
          <CarsList
            parentWrapper="accountsettings"
            refresh={0}
            hasUnVerifiedProfile={false}
            selfRegisterDate={null}
            goToPrevTab={() => redirectToAccountSettings()}
            goToNextTab={() => redirectToAccountSettings()}
          />
        </Route>
        <Route path="/ADI/profile/rates-cancellation-policy">
          <RatesWrapper parentWrapper="accountsettings" />
        </Route>
        <Route path="/ADI/profile/cancellation-policy">
          <CancellationPolicy parentWrapper="accountsettings" />
        </Route>
        <Route path="/ADI/profile/banking-details">
          <BankingDetails
            parentWrapper="accountsettings"
            refresh={0}
            hasUnVerifiedProfile={false}
            selfRegisterDate={null}
            goToPrevTab={() => redirectToAccountSettings()}
            goToNextTab={() => redirectToAccountSettings()}
          />
        </Route>

        {isMobile ? (
          <Route path="/ADI/settings/notification">
            <AppNotification />
          </Route>
        ) : null}

        <Route path="/ADI/settings/communication">
          <Communication />
        </Route>
        <Route path="/ADI/settings">
          <DefaultJobSettings />
        </Route>

        <Route path="/ADI/profile/job-preferences">
          <JobPreferences
            parentWrapper="accountsettings"
            refresh={0}
            hasUnVerifiedProfile={false}
            selfRegisterDate={null}
            goToPrevTab={() => redirectToAccountSettings()}
            goToNextTab={() => redirectToAccountSettings()}
          />
        </Route>
        <Route path="/ADI/profile/driving-tuition">
          <AboutDrivingTuition
            parentWrapper="accountsettings"
            refresh={0}
            hasUnVerifiedProfile={false}
            selfRegisterDate={null}
            goToPrevTab={() => redirectToAccountSettings()}
            goToNextTab={() => redirectToAccountSettings()}
          />
        </Route>

        <Route path="/ADI/profile/overview">
          <PersonalDetails
            parentWrapper="accountsettings"
            refresh={0}
            hasUnVerifiedProfile={false}
            selfRegisterDate={null}
            goToPrevTab={() => redirectToDashboard()}
            goToNextTab={() => redirectToDashboard()}
          />
        </Route>
        {/* */}
        {/* 
        <Redirect from='/ADI/settings' exact={true} to='/ADI/settings/overview' />
        <Redirect to='/ADI/settings/overview' /> */}
      </Switch>
    </>
  );
};

export { SettingsWrapper };
