import { useFormik } from 'formik'
import { FC, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { scrollToFirstError } from '../../../helpers/ScrollHelper';
import Modal from '../../shared/overlays/Modal'
import Input from '../../shared/forms/Input'
import Dropdown from '../../shared/forms/Dropdown'
import DatePicker from 'react-datepicker';
import { addMoneyOut, getExpenseTypes, getPaymentMethods, addExpenseTypes, getTransaction, updateMoneyOut } from '../../../../core/services/payment-service'
import { MoneyOut } from '../../../../core/models/money-out-model';
import CreatableDropdown from '../../shared/forms/CreatableDropdown';
import Button from '../../shared/elements/Button';
import { PlusSmallIcon, TrashIcon } from '@heroicons/react/24/outline';
import { toAbsoluteUrl } from '../../../helpers';
import { DeleteMoneyModal } from './DeleteMoneyModal';
interface IProps {
    show: any
    onClose: any,
    onSubmit: any,
    transactionId?: string | null
    refreshParent?: any
}

const MoneyOutModal: FC<IProps> = (props) => {
    const [expenseTypes, setExpenseTypes] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [addedExpense, setAdiAddedExpenses] = useState<any>();
    const [showCustomExpenseType, setShowCustomExpenseType] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false)

    const requiredMsg = "This field is required";


    const validationSchema = Yup.object().shape({
        expense_type: Yup.string().required(requiredMsg).nullable(),
        payment_method_id: Yup.number().nullable(),
        amount: Yup.number().required(requiredMsg).nullable(),
        transaction_date: Yup.string().nullable(),
        transaction_date_formatted: Yup.string().nullable(),
    })

    const formik = useFormik({
        initialValues: new MoneyOut(),
        enableReinitialize: true,
        validationSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            setSubmitting(true)
            if (values.transaction_date_formatted) {
                let date = new Date(values.transaction_date_formatted)
                values.transaction_date = date?.getFullYear() + '-' + (date?.getMonth() + 1) + '-' + date?.getDate();
            } else {
                let date = new Date()
                values.transaction_date = date?.getFullYear() + '-' + (date?.getMonth() + 1) + '-' + date?.getDate();
            }
            if (props.transactionId) {
                    updateMoneyOut(props.transactionId, values).finally(() => {
                        if (expenseTypes.filter((e: any) => e.value === values.expense_type).length === 0) {
                            saveAdiExpense(values.expense_type)
                        }
                        setAdiAddedExpenses(null)
                        setSubmitting(false);
                        props.onSubmit();
                    });

            } else {
                addMoneyOut(values).finally(() => {
                    if (expenseTypes.filter((e: any) => e.value === values.expense_type).length === 0) {
                        saveAdiExpense(values.expense_type)
                    }
                    setAdiAddedExpenses(null)
                    setSubmitting(false);
                    props.onSubmit();
                    props.refreshParent();
                });
            }
        },
    })

    useEffect(() => {
        if(props.show)
            {
        getExpenseTypes().then((res) => {
            setExpenseTypes(res.data.results.map((type: any) => {
                return {
                    value: type.name,
                    label: type.name
                }
            }));
        })
            .catch((response) => {

            });
        getPaymentMethods().then((res) => {
            setPaymentMethods(res.data.results.map((type: any) => {
                return {
                    value: type.id,
                    label: type.name
                }
            }));
        })
            .catch((response) => {

            })}
    }, [props.show])

    useEffect(() => {
        if (props.transactionId) {
            getTransaction(props.transactionId).then((res) => {
                formik.setValues(res.data.results)
            });
        }
    }, [props.transactionId])



    const saveAdiExpense = (expenseName: string) => {
        addExpenseTypes({ "name": expenseName }).then((res) => {
            getAllExpenseTypes();
        })
            .catch((response) => {

            })
    }


    const getAllExpenseTypes = () => {
        getExpenseTypes().then((res) => {
            setExpenseTypes(res.data.results.map((type: any) => {
                return {
                    value: type.name,
                    label: type.name
                }
            }));
        })
            .catch((response) => {

            })
    }

    useEffect(() => {
        if (!props.transactionId) {
            formik.resetForm();
            setShowCustomExpenseType(false)
        }
    }, [props.show])


    useEffect(() => {
        scrollToFirstError(formik);
    }, [formik.errors, formik.isSubmitting, formik.isValidating]);

    const addCustomExpenseType = () => {
        setShowCustomExpenseType(true);
    }

    return (
        <>
            <form noValidate id='kt_out_money_form'>
                <Modal
                    open={props.show}
                    onClose={props.onClose}
                    onSubmit={formik.submitForm}
                    submitText={props.transactionId ? 'Save changes' : 'Save'}
                    title="Money out"
                    titleIcon={toAbsoluteUrl("/assets/media/svg/money-out.svg")}
                    disabled={formik.isSubmitting}
                    onActionLink={() => {
                        props.onClose();
                        setShowDeleteModal(true);
                    }}
                    actionLinkText='Delete activity'
                    actionLinkColor="dangerLink"
                    showActionLink={props.transactionId ? true : false}
                >

                    <CreatableDropdown
                        name='expense_type'
                        id='expense_type'
                        placeholder='Expense type...'
                        label='Expense type'
                        isSearchable={true}
                        required={true}
                        value={expenseTypes.find((t: any) => t.value === formik.values?.expense_type)}
                        disabled={showCustomExpenseType}
                        onChange={(e: any) => {
                            let selected = addedExpense
                            if (e.__isNew__) {
                                selected = {
                                    label: e.label,
                                    value: e.value
                                }
                                setAdiAddedExpenses(selected)
                            } else {
                                selected = e
                            }

                            formik.setFieldValue("expense_type", e?.value)
                        }}
                        options={expenseTypes}
                        error={(formik.touched.expense_type && formik.errors?.expense_type && !showCustomExpenseType) ? true : false}
                    />

                    <div className='-mt-3 mb-5'>
                        <Button
                            colour="link"
                            type="button"
                            size='fit'
                            fitWidth
                            disabled={showCustomExpenseType}
                            onClick={addCustomExpenseType}
                        >
                            <span className='flex items-center py-1'><PlusSmallIcon className='w-5 h-5' /> Add Expense type</span>
                        </Button>
                    </div>

                    {showCustomExpenseType && <div className='-mt-9 flex flex-nowrap items-center justify-between'>
                        <div className='me-3 flex-1'>
                            <Input
                                {...formik.getFieldProps('expense_type')}
                                type='text'
                                placeholder='Expense type'
                                error={(formik.touched.expense_type && formik.errors?.expense_type) ? true : false}
                                errorMsg={requiredMsg}
                                name='expense_type'
                                disabled={formik.isSubmitting}
                                id='expense_type' />
                        </div>

                        <Button
                            disabled={formik.isSubmitting}
                            type='button'
                            size='fit'
                            fitWidth={true}
                            colour='transparent'
                            Icon={TrashIcon}
                            onClick={() => setShowCustomExpenseType(false)}
                        ></Button>
                    </div>}

                    <div className="-mt-6">
                        <DatePicker
                            selected={formik.values?.transaction_date_formatted != undefined ? new Date(formik.values?.transaction_date_formatted) : new Date()}
                            dateFormat={'dd/MM/yyyy'}
                            isClearable={false}
                            placeholderText="Payment date"
                            name='transaction_date_formatted'
                            id='transaction_date_formatted'
                            required={true}
                            onChange={(e) => {
                                if (e != null) {
                                    formik.setFieldValue('transaction_date_formatted', e)
                                }
                            }}
                            customInput={
                                <Input
                                    type='text'
                                    label='Payment date'
                                    readonly={true}
                                    placeholder='Payment date'
                                    name='transaction_date_input' />
                            }
                            calendarClassName="w-full pt-5"
                            clearButtonClassName="!h-fit !top-[42%] after:!bg-white after:ring-1 after:ring-darkBlue after:!text-darkBlue"
                            popperClassName="!static !pt-0 w-full !translate-x-0"
                            wrapperClassName="-mb-3 w-full"
                        />
                    </div>

                    <div className='-mt-4'>
                        <Input
                            {...formik.getFieldProps('amount')}
                            type='number'
                            label='Amount'
                            placeholder='Amount'
                            error={(formik.touched.amount && formik.errors?.amount) ? true : false}
                            errorMsg={formik.errors.amount}
                            required={true}
                            name='amount'
                            disabled={formik.isSubmitting}
                            id='amount' />
                    </div>

                    <Dropdown
                        {...formik.getFieldProps('payment_method_id')}
                        options={paymentMethods}
                        label='Payment method'
                        placeholder='Payment method'
                        required={false}
                        name='payment_method_id'
                        disabled={formik.isSubmitting}
                        id='payment_method'
                        value={paymentMethods.find((t: any) => t.value === formik.values?.payment_method_id)}
                        onChange={(e) => {
                            formik.setFieldValue("payment_method_id", e?.value)
                        }}
                        onBlur={() => {
                            formik.setFieldTouched("payment_method_id")
                        }} />

                </Modal>
            </form>

            <DeleteMoneyModal
                show={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                onSubmit={props.onSubmit}
                transactionId={props.transactionId}
            />
        </>
    )
}

export { MoneyOutModal }
