/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as auth from "../redux/AuthRedux";
import { login, requestPassword } from "../redux/AuthCRUD";
import { getSavedSteps } from "../../../../core/services/adi-service";

import ReactGA from "react-ga4";
import ContactInfo from "../../help/ContactInfo";
import Input from "../../../components/shared/forms/Input";
import Button from "../../../components/shared/elements/Button";
import PageHeader from "../../../components/shared/elements/PageHeader";

const loginSchema = Yup.object().shape({
  email: Yup.string().email("Wrong email format").required("Email is required"),
  password: Yup.string().required("Password is required"),
});

interface IProps {
  email: string;
  cancelLogin: () => void;
  returnUrl: string;
  children?: React.ReactNode;
}
const Login: FC<IProps> = (props) => {
  // Initialize a boolean state
  const [passwordShown, setPasswordShown] = useState(false);
  // Password toggle handler
  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };

  const [loading, setLoading] = useState(false);
  const [permissionDenied, setPermissionDenied] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const initialValues = {
    email: props.email,
    password: "",
    isLogin: false,
  };

  useEffect(() => {
    showPermissionDenied();
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setStatus(null);
      setPermissionDenied(false);
      setTimeout(() => {
        login(values.email, values.password)
          .then(() => {
            ReactGA.event("potal_login");
            checkHasUnverifiedData();
          })
          .catch((err: any) => {
            if (err.errors?.length) {
              setStatus(err.errors[0].errorMessage);
            } else {
              setStatus(err.message);
            }
          })
          .finally(() => {
            setSubmitting(false);
            setLoading(false);
          });
      }, 1000);
    },
  });

  const checkHasUnverifiedData = () => {
    return getSavedSteps()
      .then((res) => {
        dispatch(
          auth.actions.setHasUnVerifiedData(
            res.data.results.has_un_verified_imported_data
          )
        );
        dispatch(auth.actions.setIsLogin(true));
        dispatch(auth.actions.login());
        if (props.returnUrl) {
          history.push(props.returnUrl);
        }
        setLoading(false);
      })
      .catch((err) => {
        if (err.errors[0].errorMessage === "Can't access portal") {
          showPermissionDenied();
        }
        setLoading(false);
      });
  };

  const showPermissionDenied = () => {
    const hasValue = localStorage.getItem("permission-denied");
    if (hasValue) {
      setPermissionDenied(true);
      localStorage.removeItem("permission-denied");
    }
  };

  return (
    <>
      <form className="space-y-6" onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Heading */}
        <div className="text-center mb-0">
          {/* begin::Title */}
          <PageHeader
            title={
              formik.values.isLogin
                ? "Log in to continue"
                : "Welcome to ADI Network"
            }
            isCenter={true}
          />
          {/* end::Title */}
        </div>
        {/* end::Heading */}

        {formik.status ? (
          <div className="rounded-md flex items-center px-2.5 py-2 text-sm font-medium bg-red-100">
            <div className="text-danger font-semibold">{formik.status}</div>
          </div>
        ) : (
          <></>
        )}
        {permissionDenied && (
          <div className="mb-lg-6 alert alert-warning not-allowed-alert p-6 pb-4">
            <ContactInfo isNotAllowed={true}></ContactInfo>
          </div>
        )}
        {/* begin::Form group */}
        <Input
          {...formik.getFieldProps("email")}
          type="email"
          label="Email address"
          placeholder="you@example.com"
          error={formik.touched.email && formik.errors.email ? true : false}
          errorMsg={formik.errors.email}
          required={true}
          name="email"
          disabled
          id="email"
        />
        {/* end::Form group */}
        {/* begin:: Password form group */}
        <Input
          {...formik.getFieldProps("password")}
          type={passwordShown ? "text" : "password"}
          label="Password"
          placeholder="********"
          error={
            formik.touched.password && formik.errors.password ? true : false
          }
          errorMsg={formik.errors.password}
          required={true}
          name="password"
          disabled={formik.isSubmitting}
          id="password"
        />
        {/* end:: Password form group */}
        <div className="flex items-center justify-between">
          {/* <div className="flex items-center">
            <span
              className={clsx("text-sm", {
                "border-danger":
                  formik.touched.password && formik.errors.password,
              })}
              role="button"
              onClick={togglePassword}
            >
              {passwordShown ? "Hide" : "Show"} password
            </span>
          </div> */}

          <div className="text-sm leading-6">
            <a
              onClick={(e) => {
                e.preventDefault();
                requestPassword(props.email).then(() =>
                  history.push("/auth/reset-password/" + props.email)
                );
              }}
              className="font-semibold text-darkBlue hover:text-indigo-800 hover:brightness-75 cursor-pointer"
            >
              Forgot password?
            </a>
          </div>
        </div>
        {/* begin::Action */}
      <div className='flex justify-between space-x-2'>

        <Button
          colour="yellow"
          type="submit"
          size="large"
          disabled={formik.isSubmitting || !formik.isValid || !formik.values.password || loading}
        >
          {!loading && <span className="indicator-label">Sign in</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </Button>
          <Button
          colour="gray"
            type="button"
            id="kt_login_password_reset_form_cancel_button"
            disabled={formik.isSubmitting || loading}
            onClick={(e) => {
              e.preventDefault();
              props.cancelLogin();
            }}
          >
            Cancel
          </Button>
        </div>
        {/* end::Action */}
        {/* begin:: Password form group */}
        {/* <div
          className={
            formik.touched.password && formik.errors.password
              ? "form-group mb-6 is-invalid"
              : "form-group mb-6"
          }
        >
          <div className="d-flex flex-wrap justify-content-between mb-1">
      
            <label className="form-label fw-bolder text-dark fs-3 mb-0">
              Password
            </label>
            {formik.touched.password && formik.errors.password && (
              <div className="fv-plugins-message-container mb-0">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>

          <div className="input-group mb-0">
            <input
              type={passwordShown ? "text" : "password"}
              autoComplete="off"
              placeholder="Password"
              {...formik.getFieldProps("password")}
              className={clsx(
                "form-control form-control-lg",
                {
                  "is-invalid":
                    formik.touched.password && formik.errors.password,
                },
                {
                  "is-valid":
                    formik.touched.password && !formik.errors.password,
                }
              )}
            />

            <span
              className={clsx("input-group-text rounded-0", {
                "border-danger":
                  formik.touched.password && formik.errors.password,
              })}
              role="button"
              onClick={togglePassword}
            >
              <i
                className={`fs-3 far ${
                  passwordShown ? "fa-eye-slash" : "fa-eye"
                }`}
              ></i>
            </span>
          </div>

         
        </div> */}
        {/* end:: Password form group */}
        {/* begin::Action */}
        {/* end::Action */}
        {/* begin::Action */}
        {/* <SocialLogin></SocialLogin> */}
        {/* end::Action */}
        {/* begin::Action */}
        
        {/* end::Action */}
      </form>
    </>
  );
};

export { Login };
