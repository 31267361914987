import { FC, useEffect, useState } from 'react'
import { PlusSmallIcon, TrashIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { NewCheckout } from '../../../../core/models/stripeConnect/new-checkout'
import { getPrivateStudentLessons, getadiPaidJobs } from '../../../../core/services/job-service'
import { fromStripeAmount, toStripeAmount } from '../../../helpers/stripeHelper'
import { addCheckout, addProduct, calculateCheckout, getProducts } from '../../../../core/services/connect-service'
import Modal from '../../shared/overlays/Modal';
import Alert from '../../shared/overlays/Alert';
import Input from '../../shared/forms/Input';
import Button from '../../shared/elements/Button';
import Dropdown from '../../shared/forms/Dropdown';
import CreatableDropdown from '../../shared/forms/CreatableDropdown';
import Toggler from '../../shared/forms/Toggler'
import RadioInputGroup from '../../shared/forms/RadioInputGroup';
import { updatePrivateStdEmail } from '../../../../core/services/student-service';

interface IProps {
    show: any
    onClose: any
    onSubmit: any
    refreshParent?: any
}

const NewPaymentForm: FC<IProps> = (props) => {
    const productsOpt = [
        {
            label: 'Lesson',
            value: 1,
        },
        {
            label: 'Test',
            value: 2,
        },
        {
            label: 'Other Product',
            value: 3,
        }];

    const lessonData = {
        label: "One Lesson",
        title: "One Lesson",
        price: 2500,
        value: 1,
        name: "One Lesson"
    }

    const testData = {
        label: "Practical Test",
        title: "Practical Test",
        price: 7500,
        value: 2,
        name: "Practical Test"
    }

    const [students, setStudents] = useState<Array<any>>([])
    const [jobs, setJobs] = useState<Array<any>>([])
    const [productsSubtotal, setProductsSubtotal] = useState<number>(0)
    const [submitted, setSubmitted] = useState<boolean>(false)
    const [processingFee, setProcessingFee] = useState<any>(0)
    const [products, setProducts] = useState<Array<any>>([])
    const [selectedProducts, setSelectedProducts] = useState<Array<any>>([{}])
    const [checkOut, setCheckout] = useState<NewCheckout>(new NewCheckout())
    const [showStdEmail, setShowStdEmail] = useState<boolean>(false)
    const [selectedStd, setSelectedStd] = useState<any>(null)
    const [privateLessons, setPrivateLessons] = useState<Array<any>>([])
    const [showLessonsSelect, setShowLessonsSelect] = useState<boolean>(false)
    const [formattedPrice, setFormattedPrice] = useState<string>('')
    const [isInputActive, setIsInputActive] = useState<boolean>(false)
    
    const addCheckoutSchema = Yup.object().shape({
        job_id: Yup.string().required('Learner is required'),
        student_email: Yup.string().email('Wrong email format').required('Learner email is required').nullable(),
        product_id: Yup.number().required('This field is required'),
    })

    useEffect(() => {
        setShowLessonsSelect(false);
        if (props.show) {
            getProducts().then((res) => {
                const prods = res?.data
                let temp = []
                for (let i = 0; i < prods?.length; i++) {
                    temp.push({
                        label: prods[i].name,
                        title: prods[i].name,
                        price: prods[i].price,
                        value: 3 + i
                    })
                }
                setProducts(temp)
            })
        }
    }, [props.show])

    useEffect(() => {
        getadiPaidJobs().then((res) => {
            const jobs = res?.data?.results
            if (jobs) {
                const mappedJobs = Array.from(jobs).map((job) => {
                    return { value: job.job_id, label: job.student_full_name  }
                })
                setJobs(jobs)
                setStudents(mappedJobs)
            }
        })
    }, [])

    useEffect(() => {
        let sum = 0
        selectedProducts.forEach((a) => {
            if (a.price != null) {
                sum = sum + a.price
            }
        })
        setProductsSubtotal(sum)
        if (sum > 0) {
            getFee(sum)
        }
    }, [selectedProducts])

    const getFee = (subtotal: any) => {
        calculateCheckout({ amount: toStripeAmount(subtotal) }).then((res) => {
            setProcessingFee(fromStripeAmount(res?.data?.fee))
        }).catch(() => { })
    }

    const addNewProduct = () => {
        setSelectedProducts([
            ...selectedProducts,
            { price: null, title: null },
        ])
    }
    function closeModal() {
        formik.resetForm();
        props.onClose()
    }

    const formik = useFormik({
        initialValues: checkOut,
        enableReinitialize: true,
        validationSchema: addCheckoutSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            setSubmitted(true)

            if(productsSubtotal !== 0) {
            if (selectedProducts.length === 0 && formik.values?.product_id === 3) {
                setSubmitting(false)
                return
            }
            if(formik.values?.product_id === 3) {
            if (selectedProducts.findIndex(product =>
                !product.title || product.title == null || product.title.length === 0 ||
                !product.price ||
                product.price == null || product.price.length === 0 ||
                product.price <= 0

            ) !== -1) {
                setSubmitting(false)
                return
            }
            }
            if (
              values.is_private_student &&
              values.student_email &&
              values.student_id
            ) {
              updatePrivateStdEmail(
                values.student_email,
                values.student_id
              ).catch((err: any) => {
                setSubmitting(false);
                if (err.errors?.length) {
                  setStatus(err.errors[0].errorMessage);
                } else {
                  setStatus(err.message);
                }
              });
              submitCheckOut(values, setStatus, setSubmitting);
            } else {
              submitCheckOut(values, setStatus, setSubmitting);
            }
        } else {
            formik.setSubmitting(false)
        }
        },
    })

    var selectedProductRows = selectedProducts.map(function (product: any, index: any) {
        return (
            <div key={index}>
                <div className='flex items-center justify-between -my-5'>
                    <div className='flex w-[90%] space-x-2'>
                        <div className='w-1/2'>
                            <CreatableDropdown
                                name='products'
                                id='products'
                                onChange={(e: any) => {
                                    let selected = [...selectedProducts]
                                    if (e.__isNew__) {
                                        selected[index] = {
                                            label: e.label,
                                            title: e.label,
                                            price: null,
                                        }
                                    } else {
                                        selected[index] = e
                                    }
                                    setSelectedProducts(selected)
                                }}
                                options={products}
                                isSearchable={true}
                                label='Product'
                                placeholder=''
                                error={(submitted && formik.values?.product_id === 3 && (!product.title || product.title == null || product.title.length === 0)) ? true : false}
                                errorMsg="Product required"
                                required={formik.values?.product_id === 3}
                                disabled={formik.isSubmitting}
                            />
                        </div>

                        <div className='-my-2 w-1/2'>
                            <Input
                                value={product.price}
                                onChange={(e) => {
                                    let selected = [...selectedProducts]
                                    selected[index].price = parseFloat(e.target.value) < 0 ? parseFloat(e.target.value) * -1 : parseFloat(e.target.value)
                                    setSelectedProducts(selected)
                                }}
                                type="number"
                                label="Price"
                                placeholder=""
                                error={((!product.price ||
                                    product.price == null || product.price.length === 0 ||
                                    product.price <= 0) && submitted) ? true : false}
                                errorMsg={"Price required"}
                                required={true}
                                name="price"
                                disabled={formik.isSubmitting || submitted}
                                id="price"
                                inputGroup={true}
                                inputGroupValue='£'
                            />
                        </div>
                    </div>

                    <div className={((!product.price ||
                        product.price == null || product.price.length === 0 ||
                        product.price <= 0 || !product.title || product.title == null || product.title.length === 0)
                        && submitted) ? 'mb-4' : ''}>
                        {selectedProducts.length > 1 && <Button
                            type='button'
                            size='fit'
                            fitWidth
                            colour='transparent'
                            Icon={TrashIcon}
                            onClick={(e) => {
                                const temp = [...selectedProducts]
                                temp.splice(index, 1)
                                setSelectedProducts(temp)
                                if (temp.length === 0) {
                                    setProcessingFee(0)
                                    setProductsSubtotal(0)
                                }
                            }}
                        />}
                    </div>
                </div>
            </div>
        )
    })

    function submitCheckOut(values: NewCheckout, setStatus: Function, setSubmitting: Function) {
        let selectedJob = jobs.find((c) => c.job_id === values.job_id)
        let created: NewCheckout = {
            job_id: values.job_id,
            student_id: selectedJob.student_id,
            student_email: values.student_email,
            student_name: selectedJob.student_first_name ?? selectedJob.student_surname,
            items: [],
            forward_fee: values.forward_fee,
            notify: true,
            lesson_date:values.lesson_date,
            lesson_id:values.lesson_id
        }

        if(formik.values?.product_id === 1) {
            if(toStripeAmount(productsSubtotal) !== lessonData.price) {
                created.items = [{...lessonData, price: toStripeAmount(productsSubtotal)}]
            } else {
                created.items = [lessonData]
            }
        } else if(formik.values?.product_id === 2) {
            if(toStripeAmount(productsSubtotal) !== testData.price) {
                created.items = [{...testData, price: toStripeAmount(productsSubtotal)}]
            } else {
                created.items = [testData]
            }
        } else {
            created.items = selectedProducts.map((p) => ({
                ...p,
                name: p.title,
                price: toStripeAmount(p.price),
            }));
        }
        
        if(formik.values?.product_id === 3) {
        selectedProducts.forEach(a => {
            if (a.title !== "One Lesson" && a.title !== "Practical Test")
                addProduct({ name: a.title, price: a.price })
        })
        }

        addCheckout(created)
            .then(() => {
                props.onSubmit()
                if (props.refreshParent)
                    props.refreshParent();
            })
            .catch((err: any) => {
                if (err.errors?.length) {
                    setStatus(err.errors[0].errorMessage)
                } else {
                    setStatus(err.message)
                }
            })
            .finally(() => {
                setSubmitting(false)
                closeModal()
            })
    }

    const getStudentLessons = (jobId: string) => {
        getPrivateStudentLessons(jobId).then((res) => {
            setPrivateLessons(res.data.results.map((type: any) => {
                return {
                    value: type.lesson_date,
                    label: type.format_lesson_date,
                    id: type.lessonId
                }
            }));
        })
            .catch((response) => {

            })
    }

    useEffect(() => {      
        if(!isInputActive && (productsSubtotal === parseInt(productsSubtotal.toString(), 10))) 
            setFormattedPrice(productsSubtotal.toFixed(2))
    }, [productsSubtotal, isInputActive])
    

    return (
        <>

            <form
                noValidate
                className='flex justify-between'
                onSubmit={formik.handleSubmit}
            >
                <div>
                    {formik.status && (
                        <Alert description={formik.status} colour="red" />
                    )}
                    <div>
                        {/* start:: From Group */}
                        <div className='flex w-full -mb-2'>
                            <div className='w-full'>
                                <Dropdown
                                    options={students}
                                    label='Learner'
                                    name='job_id'
                                    id='job_id'
                                    isSearchable
                                    value={{
                                        value: formik.values.job_id,
                                        label: students.find((c) => c.value === formik.values.job_id)?.label
                                    }}
                                    placeholder="Select..."
                                    error={formik.touched.job_id && formik.errors.job_id ? true : false}
                                    errorMsg={formik.errors.job_id}
                                    required={true}
                                    disabled={formik.isSubmitting}
                                    onChange={(e) => {
                                        formik.setFieldValue('job_id', e?.value)
                                        let studentTemp = jobs.find((c) => c.job_id === e?.value);
                                        setSelectedStd(studentTemp);
                                        formik.setFieldValue('student_email', studentTemp?.student_email ?? '')
                                        formik.setFieldValue('student_id', studentTemp?.student_id ?? '')
                                        formik.setFieldValue('is_private_student', studentTemp?.is_private_student)
                                        getStudentLessons(e?.value)
                                        if(studentTemp.is_private_student === 1 && !studentTemp?.student_email) {
                                            setShowStdEmail(true);
                                        } else {
                                            setShowStdEmail(false);
                                        }
                                    }}
                                />
                            </div>
                            <div className='mt-7'>
                                <Button
                                    onClick={() => {
                                        setShowStdEmail(!showStdEmail)
                                        formik.setFieldValue('student_email', selectedStd?.student_email ?? '')
                                    }}
                                    colour="link"
                                    size="icon"
                                    fitWidth
                                    Icon={PencilSquareIcon}
                                    disabled={formik.values.is_private_student === 0}
                                ></Button>
                            </div>
                        </div>
                        {/* end:: From Group */}

                        {/* begin::Form group */}
                        {(showStdEmail && formik.values.is_private_student !== 0) && 
                        <div className='-mt-5 -my-3'>
                        <Input
                            {...formik.getFieldProps('student_email')}
                            type="email"
                            label="Learner Email"
                            placeholder=""
                            error={formik.values.is_private_student === 1 && formik.touched.student_email && formik.errors.student_email ? true : false}
                            errorMsg={formik.errors.student_email}
                            required={true}
                            name="student_email"
                            disabled={formik.values.is_private_student === 0}
                            id="student_email"
                            onChange={(e) => {
                                formik.setFieldValue('student_email', e?.target.value)
                            }}
                        />
                        </div>}
                        {/* end::Form group */}

                        {/* start:: From Group */}
                        <div>
                            <h4
                                className="text-base font-bold"
                            >
                                Products<span className='text-sm text-red-500'>*</span>
                            </h4>
                        </div>

                        <div className=''>
                                <RadioInputGroup
                                    {...formik.getFieldProps('product_id')}
                                    name="Product"
                                    label=""
                                    options={productsOpt}
                                    onChange={(e) => {
                                        formik.setFieldValue('product_id', e)
                                        if(e == 1) {
                                            setProductsSubtotal(25)
                                            getFee(25)
                                        } else if(e == 2) {
                                            setProductsSubtotal(75)
                                            getFee(75)
                                            setShowLessonsSelect(false);
                                        } else {
                                            setProductsSubtotal(0)
                                            setShowLessonsSelect(false);
                                            setSelectedProducts([{}]);
                                        }
                                        setProcessingFee(0)
                                        formik.setFieldValue("lesson_date", null)
                                    }}
                                    value={formik.values?.product_id}
                                    disabled={formik.isSubmitting}
                                    error={(formik.touched.product_id && formik.errors?.product_id) ? true : false}
                                    errorMsg={formik.errors?.product_id}  />
                        </div>

                        {(formik.values?.product_id === 1 && formik.values.is_private_student == 1) &&
                            <div className='-mb-2'>
                                <Button
                                    colour="link"
                                    type="button"
                                    size='fit'
                                    fitWidth
                                    onClick={() => setShowLessonsSelect(!showLessonsSelect)}
                                ><span className='text-sm'>Assign this payment to a lesson</span></Button>
                            </div>}

                            {(formik.values?.product_id === 1 && showLessonsSelect) &&
                                <div className='mt-6 -mb-2'>
                                    <Dropdown
                                        {...formik.getFieldProps('lesson_date')}
                                        options={privateLessons}
                                        label='Lessons'
                                        placeholder='Lessons'
                                        error={(formik.touched.lesson_date && formik.errors?.lesson_date) ? true : false}
                                        errorMsg={formik.errors?.lesson_date}
                                        required={false}
                                        name='lesson_date'
                                        disabled={formik.isSubmitting}
                                        id='lesson_date'
                                        value={privateLessons.find((t: any) => t.value === formik.values?.lesson_date)}
                                        onChange={(e) => {
                                            formik.setFieldValue("lesson_date", e?.value)
                                            formik.setFieldValue("lesson_id", e?.id)
                                        }}
                                        onBlur={() => {
                                            formik.setFieldTouched("lesson_date")
                                        }} />
                                </div>
                            }


            {(formik.values?.product_id === 1 ||
                formik.values?.product_id === 2) && (
                    <div className='-mt-1 -mb-3'>
                <Input
                  value={formattedPrice}
                  type="number"
                  inputMode={"numeric"}
                  label="Price"
                  name="learner-paied-amount"
                  id="learner-paied-amount"
                  min="1"
                  onChange={(e) => {
                    setIsInputActive(true);
                    let value = e.target.value !== "" ? e.target.value : "0";
                    setProductsSubtotal(parseFloat(value));
                    setFormattedPrice(value)

                    if (e.target.value !== "" && e.target.value !== "0")
                      getFee(parseFloat(e.target.value));
                    else setProcessingFee(0);
                  }}
                  onBlur={() => setIsInputActive(false)}
                  error={submitted && productsSubtotal === 0}
                  errorMsg="Price not valid"
                  disabled={formik.isSubmitting}
                  inputGroup={true}
                  inputGroupValue="£"
                />
                </div>
              )}

                        {(formik.values?.product_id === 3) && <div className='mt-6'>
                            {selectedProductRows}
                            <div className='my-2'>
                                <Button
                                    colour="link"
                                    type="button"
                                    size='fit'
                                    fitWidth
                                    onClick={() => addNewProduct()}
                                >
                                    <span className='flex items-center py-1'><PlusSmallIcon className='w-5 h-5' />
                                        Add New Product
                                    </span>
                                </Button>
                            </div>

                            <div className='pt-2 mt-2 mb-0 mx-0 border-t flex justify-between w-full'>
                                <span> Total</span>
                                <span> £ {Number.isInteger(+(productsSubtotal)) ?  (productsSubtotal) : (+(productsSubtotal))?.toFixed(2)}</span>
                            </div>
                        </div>}

                        {/* START:: Your Revenue */}
                        <div className='w-full mb-6 mt-0'>
                            <div className='flex flex-wrap justify-between mt-5'>
                                <label> Your Revenue </label>
                            </div>
                            <div className='bg-gray-100 rounded  p-4'>
                                <div className='flex justify-between items-center'>
                                    <div>Learner Pays</div>
                                    <div> £{formik.values.forward_fee ? (productsSubtotal + parseFloat(processingFee)).toFixed(2) : productsSubtotal.toFixed(2)} </div>
                                </div>

                                <div className='flex justify-between mt-1 pt-1'>
                                    <div>Processing Fee</div>
                                    <div>- £{parseFloat(processingFee).toFixed(2)}</div>
                                </div>

                                <div className='flex justify-between mt-1 pt-1 font-bold'>
                                    <div>You Receive</div>
                                    <div> £{formik.values.forward_fee ? productsSubtotal.toFixed(2) : (productsSubtotal - parseFloat(processingFee)).toFixed(2)} </div>
                                </div>
                            </div>

                            {formik.values.forward_fee ? (
                                <p className='text-left mt-4 bg-blue-100 text-blue-900 p-4 rounded'>
                                    The learner’s payment has been increased by {' '}
                                    <strong>£{parseFloat(processingFee).toFixed(2)}</strong> to cover the processing fee.
                                </p>
                            ) : (
                                <p className='text-left mt-4 bg-orange-100 text-orange-900 p-4 rounded'>
                                        <strong>£{parseFloat(processingFee).toFixed(2)}</strong> will be deducted from the final payment to cover the processing fee. You may forward this fee onto the Learner if you prefer.
                                </p>
                            )}
                        </div>
                        {/* END:: Your Revenue */}

                        <div className='flex p-0 mt-2 justify-between'>
                            <label
                                className='ms-0 pe-4'
                                htmlFor='forwardFeesCheck'
                            >
                                <h4 className="text-base font-bold"> Forward fees to learner </h4>
                                <span className='block text-xs text-[#A8A8A8]'>
                                    {formik.values.job_id ?
                                        students.find((c) => c.value === formik.values.job_id)?.label : "Learner"}
                                    {" "} will cover the processing fee.
                                </span>
                            </label>
                            <div className='flex-shrink'>
                                <Toggler
                                    // NOTE: Don't use === here please
                                    checked={formik.values.forward_fee == 1}
                                    onChange={() => formik.setFieldValue('forward_fee', !!!formik.values.forward_fee)}
                                    disabled={formik.isSubmitting}
                                />
                            </div>
                        </div>
                        <div className='md:mt-0 mt-[65px]'></div>
                        <div className="fixed md:sticky w-full bottom-0 left-0 px-5 md:px-0 py-4 bg-white z-[11]">
                            <div className='w-full flex items-center justify-between space-x-2'>
                            <Button
                                colour="yellow"
                                type="submit"
                                halfWidth size="large"
                                onClick={formik.handleSubmit}
                                disabled={formik.isSubmitting}>
                                Save
                            </Button>
                            <Button
                                colour="outline"
                                type="button"
                                halfWidth size="large"
                                onClick={closeModal}>
                                Close
                            </Button>
                            </div>
                        </div>
                    </div>
                </div>

              
            </form>
        </>
    )
}

export { NewPaymentForm }
