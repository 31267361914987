import axios, { AxiosResponse } from 'axios'

import { BaseResponse } from '../models/base-response'
import { PaymentModel } from '../models/payment-model'
import modifiedFetch from '../../../setup/axios/ModifiedFetch'

const PAYMENT_URL = process.env.REACT_APP_PAYMENT_BASE_URL

export const PAYMENT_AUTH_URL = `${PAYMENT_URL}/cognito`
export const GET_PAYMENT_LIST_URL = `${PAYMENT_AUTH_URL}/adi-payments`
export const GET_ADI_JOB_PAYMENT_LIST_URL = `${PAYMENT_AUTH_URL}/job-payments`
export const GET_ADI_JOB_PAYMENT_DETAILS_URL = `${PAYMENT_AUTH_URL}/job-payment-details`
export const CONFIRM_PAYMENT_URL = `${PAYMENT_AUTH_URL}/confirm-payment`
export const ADI_TRANSACTIONS=  `${PAYMENT_AUTH_URL}/adi-transactions`
export const ADD_MONEY_IN_PAYMENT_URL = `${PAYMENT_AUTH_URL}/money-in`
export const ADD_MONEY_OUT_PAYMENT_URL = `${PAYMENT_AUTH_URL}/money-out`
export const GET_PAYMENT_METHODS_URL = `${PAYMENT_AUTH_URL}/payment-methods`
export const GET_PAYMENT_METHODS_MONEY_IN_URL = `${PAYMENT_AUTH_URL}/payment-methods-money-in`
export const GET_EXPENSES_URL = `${PAYMENT_AUTH_URL}/adi-expense-types`
export const GET_ADI_PRODUCTS_URL = `${PAYMENT_AUTH_URL}/adi-product-types`
export const ADD_ADI_PRODUCTS_URL = `${PAYMENT_AUTH_URL}/adi-product-types`
export const ADD_EXPENSES_URL = `${PAYMENT_AUTH_URL}/adi-expense-types`

export const TRANSACTION_URL = `${PAYMENT_AUTH_URL}/transactions`

export const GET_PRIVATE_LEARNER_BALANCE_URL = `${PAYMENT_AUTH_URL}/private-learner-balance`
export const GET_PRIVATE_LEARNER_TRANSACTIONS_URL = `${PAYMENT_AUTH_URL}/transactions/private-learner`
export const GET_ADI_TOTAL_PAID = `${PAYMENT_AUTH_URL}/total-paid-amount`

export function getPayments(): Promise<AxiosResponse<BaseResponse<any>>> {
    return axios.get<BaseResponse<PaymentModel>>(GET_PAYMENT_LIST_URL)
}

export function getAdiJobPayments(jobId: string,adiJobId : any): Promise<AxiosResponse<BaseResponse<any>>> {
    return axios.get<BaseResponse<PaymentModel>>(GET_ADI_JOB_PAYMENT_LIST_URL + '/' + jobId+"/"+adiJobId)
}

export function confirmPayment(paymentId: string): Promise<AxiosResponse<BaseResponse<any>>> {
    return axios.put<BaseResponse<PaymentModel>>(CONFIRM_PAYMENT_URL + '/' + paymentId, null)
}

export function getAdiJobPaymentDetails(jobId: string,adiJobId : any): Promise<AxiosResponse<BaseResponse<any>>> {
    return axios.get<BaseResponse<any>>(GET_ADI_JOB_PAYMENT_DETAILS_URL + '/' + jobId +"/"+adiJobId)
}


export function adiTransactions(data : any): Promise<AxiosResponse<BaseResponse<any>>> {
    return axios.post<BaseResponse<PaymentModel>>(ADI_TRANSACTIONS , data)
}

export function addMoneyIn(data: any): Promise<AxiosResponse<BaseResponse<any>>> {
    return axios.post<BaseResponse<any>>(ADD_MONEY_IN_PAYMENT_URL, data)
}

export function addMoneyOut(data: any): Promise<AxiosResponse<BaseResponse<any>>> {
    return axios.post<BaseResponse<any>>(ADD_MONEY_OUT_PAYMENT_URL, data)
}

export function getPaymentMethods(): Promise<AxiosResponse<BaseResponse<any>>> {
    return axios.get<BaseResponse<any>>(GET_PAYMENT_METHODS_URL)
}

export function getPaymentMethodsMoneyIn(): Promise<AxiosResponse<BaseResponse<any>>> {
    return axios.get<BaseResponse<any>>(GET_PAYMENT_METHODS_MONEY_IN_URL)
}


export function getExpenseTypes(): Promise<AxiosResponse<BaseResponse<any>>> {
    return axios.get<BaseResponse<any>>(GET_EXPENSES_URL)
}

export function getAdiProducts(): Promise<AxiosResponse<BaseResponse<any>>> {
    return axios.get<BaseResponse<any>>(GET_ADI_PRODUCTS_URL)
}

export function addAdiProducts(data: any): Promise<AxiosResponse<BaseResponse<any>>> {
    return axios.post<BaseResponse<any>>(ADD_ADI_PRODUCTS_URL,data)
}

export function addExpenseTypes(data: any): Promise<AxiosResponse<BaseResponse<any>>> {
    return axios.post<BaseResponse<any>>(ADD_EXPENSES_URL,data)
}

export function getTransaction(id:string): Promise<AxiosResponse<BaseResponse<any>>> {
    return axios.get<BaseResponse<any>>(TRANSACTION_URL +'/'+id)
}

export function deleteTransaction(id:any): Promise<AxiosResponse<BaseResponse<any>>> {
    return axios.delete<BaseResponse<any>>(TRANSACTION_URL +'/'+id)
}

export function updateMoneyIn(id:any,data: any): Promise<AxiosResponse<BaseResponse<any>>> {
    return axios.put<BaseResponse<any>>(TRANSACTION_URL +'/money-in/'+id, data)
}

export function updateMoneyOut(id:any,data: any): Promise<AxiosResponse<BaseResponse<any>>> {
    return axios.put<BaseResponse<any>>(TRANSACTION_URL +'/money-out/'+id, data)
}

export function getPrivateLearnerBalance(jobId: string): Promise<BaseResponse<any>> {
    return modifiedFetch({
        url: GET_PRIVATE_LEARNER_BALANCE_URL + '/' + jobId,
        method: 'GET'
    })
}

export function getPrivateLearnerTransactions(jobId: string): Promise<BaseResponse<any>> {
    return modifiedFetch({
        url: GET_PRIVATE_LEARNER_TRANSACTIONS_URL + '/' + jobId,
        method: 'GET'
    })
}


export function getAdiJobTotalPaid(jobId: string, adiId: string, data: any): Promise<BaseResponse<any>> {
  return modifiedFetch({
    url: GET_ADI_TOTAL_PAID + "/" + jobId + "/" + adiId,
    method: "POST",
    data: data,
  });
}

