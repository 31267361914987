export enum JobBIDStatusEnum {
  PendingReview = 1,
  UnderConsideration = 2,
  Rejected = 3,
  JobNoLongerAvailable = 4,
  AcceptedPendingADIConfirmation = 5,
  AcceptedConnected = 6,
  Withdrawn = 7
}


export const JobBIDStatusList = [
  {
    id: JobBIDStatusEnum.PendingReview,
    img: "/assets/media/svg/bids/pending_review.svg",
    colour: "#8E8E8E",
    status: "Pending Review"
  },
  {
    id: JobBIDStatusEnum.UnderConsideration,
    img: "/assets/media/svg/bids/pending_review.svg",
    colour: "#8E8E8E",
    status: "Under Consideration"
  },
  {
    id: JobBIDStatusEnum.Rejected,
    img: "",
    colour: "#8E8E8E",
    status: "Rejected"
  },
  {
    id: JobBIDStatusEnum.JobNoLongerAvailable,
    img: "/assets/media/svg/bids/no_longer.svg",
    colour: "#8E8E8E",
    status: "No Longer Available"
  },
  {
    id: JobBIDStatusEnum.AcceptedPendingADIConfirmation,
    img: "/assets/media/svg/bids/accepted.svg",
    colour: "#163A5E",
    status: "Accepted"
  },
  {
    id: JobBIDStatusEnum.AcceptedConnected,
    img: "/assets/media/svg/bids/accepted.svg",
    colour: "#163A5E",
    status: "Accepted"
  },
  {
    id: JobBIDStatusEnum.Withdrawn,
    img: "/assets/media/svg/bids/no_longer.svg",
    colour: "#8E8E8E",
    status: "Accepted"
  }
]