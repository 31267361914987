
import { FC } from 'react'
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Assessment } from '../../../../../../core/models/assessments/assessment';
import CircularProgress from '@mui/joy/CircularProgress';
import Skeleton from '@mui/material/Skeleton';
import { CalendarIcon } from '@heroicons/react/24/outline';

type Props = {
    assessment: Assessment,
    loading?: boolean
}
const OverallProgress: FC<Props> = ({ assessment, loading }) => {
    return (
        <>
            <div className="flex items-center justify-between bg-darkBlue rounded-[20px] text-white p-5">
                <div>
                    <h2 className='text-base font-bold'>
                        {loading ? <>
                            <Skeleton width={150} />
                        </> : <>
                            Overall progress:
                        </>}
                    </h2>
                    <p className="flex items-center my-2">
                         {loading ? <>
                            <Skeleton width={170} />
                        </> : <>
                        <CalendarIcon className="w-4 h-4" /> 
                        <span className="text-xs ms-1"> Created on {assessment?.date}</span>
                        </>}
                        
                    </p>
                    
                    <div className="flex items-center text-sm my-2">
                        <p>
                            {loading ? <>
                                <Skeleton width={150} />
                            </> : <>
                                    Progress is calculated based on the scores you provide to the learner across all of the below categories
                            </>}
                        </p>
                    </div>
                </div>

                <>
                    {loading ? <Skeleton width={100} height={100} className="me-2" variant="circular"/>  :
                    <CircularProgress
                        determinate
                        sx={{"--CircularProgress-size": "90px",
                        color:'#fff',
                        "--CircularProgress-linecap": "square",
                        "--CircularProgress-progressColor": "#FFD04F",
                        "--CircularProgress-trackColor": "#4F779E",
                        }}
                        value={assessment == undefined ? 0 : assessment?.score}
                        thickness={10}
                    >
                        <span className=" font-bold text-2xl">
                        {`${assessment == undefined ? 0 : assessment?.score}%`}
                        </span>
                    </CircularProgress>}
                </>
            </div>
        </>
    )
}

export { OverallProgress }
