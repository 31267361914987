import axios, { AxiosResponse } from 'axios'

import { Checkout } from '../models/stripeConnect/checkout'
import { BaseResponse } from '../models/base-response'
import { NewCheckout } from '../models/stripeConnect/new-checkout'
import { Refund } from '../models/stripeConnect/refund'

const CONNECT_URL = `${process.env.REACT_APP_STRIPE_CONNECT_BASE_URL}`
const STRIPE_CONNECT_API_KEY = process.env.REACT_APP_X_API_KEY
export const GET_CONNECT_ACOUNT_URL = `${CONNECT_URL}/account`
export const GET_CONNECT_BALANCE_URL = `${CONNECT_URL}/account/balance`

export const ON_BOARD_URL = `${CONNECT_URL}/account/onboard`
export const PRODUCT_URL = `${CONNECT_URL}/products`


export function getConnectAccount(): Promise<AxiosResponse<BaseResponse<any>>> {
    return axios.get<BaseResponse<any>>(GET_CONNECT_ACOUNT_URL, {
        headers: {
            "x-api-key": STRIPE_CONNECT_API_KEY!
        }
    })
}
export function getProducts(): Promise<AxiosResponse<Array<any>>> {
    return axios.get<Array<any>>(PRODUCT_URL, {
        headers: {
            "x-api-key": STRIPE_CONNECT_API_KEY!
        }
    })
}
export function addProduct(product:any): Promise<AxiosResponse<BaseResponse<any>>> {
    return axios.post<BaseResponse<any>>(PRODUCT_URL,product, {
        headers: {
            "x-api-key": STRIPE_CONNECT_API_KEY!
        }
    })
}

export function getConnectBalance(): Promise<AxiosResponse<BaseResponse<any>>> {
    return axios.get<BaseResponse<any>>(GET_CONNECT_BALANCE_URL, {
        headers: {
            "x-api-key": STRIPE_CONNECT_API_KEY!
        }
    })
}

export function createConnectAccount(data: any): Promise<AxiosResponse<BaseResponse<any>>> {
    return axios.post<BaseResponse<any>>(GET_CONNECT_ACOUNT_URL, data, {
        headers: {
            "x-api-key": STRIPE_CONNECT_API_KEY!
        }
    })
}

export function getOnBoardLink(): Promise<AxiosResponse<BaseResponse<any>>> {
    return axios.post<BaseResponse<any>>(ON_BOARD_URL, null, {
        headers: {
            "x-api-key": STRIPE_CONNECT_API_KEY!
        }
    })
}
export function setOnBoarded(): Promise<AxiosResponse<any>> {
    return axios.patch<any>(ON_BOARD_URL, null, {
        headers: {
            "x-api-key": STRIPE_CONNECT_API_KEY!
        }
    })
}

export function listCheckouts(): Promise<AxiosResponse<Array<Checkout>>> {
    return axios.get<Array<Checkout>>(`${CONNECT_URL}/checkouts`, {
        headers: {
            "x-api-key": STRIPE_CONNECT_API_KEY!
        }
    })
}

export function refund(checkoutId: any): Promise<AxiosResponse<Refund>> {
    return axios.post<Refund>(CONNECT_URL + '/checkouts/' + checkoutId + '/refund', null,{
        headers: {
            "x-api-key": STRIPE_CONNECT_API_KEY!
        }
    })
}

export function softRefund(checkoutId: any): Promise<AxiosResponse<Refund>> {
    return axios.post<Refund>(CONNECT_URL + '/checkouts/' + checkoutId + '/refund/details',null, {
        headers: {
            "x-api-key": STRIPE_CONNECT_API_KEY!
        }
    })
}

export function getCheckout(checkoutId: number): Promise<AxiosResponse<Checkout>> {
    return axios.get<Checkout>(CONNECT_URL + '/checkouts/' + checkoutId, {
        headers: {
            "x-api-key": STRIPE_CONNECT_API_KEY!
        }
    })
}
export function addCheckout(checkOut:NewCheckout): Promise<AxiosResponse<any>> {
    return axios.post<any>(`${CONNECT_URL}/checkouts`, checkOut,{
        headers: {
            "x-api-key": STRIPE_CONNECT_API_KEY!
        }
    })
}
export function calculateCheckout(value:any): Promise<AxiosResponse<any>> {
    return axios.post<any>(`${CONNECT_URL}/fees/calculate`,value, {
        headers: {
            "x-api-key": STRIPE_CONNECT_API_KEY!
        }
    })
}
export function expire(checkoutId: any): Promise<AxiosResponse<any>> {
    return axios.post(CONNECT_URL + '/checkouts/' + checkoutId + '/expire',null, {
        headers: {
            "x-api-key": STRIPE_CONNECT_API_KEY!
        }
    })
}

export function generateDashboardLink(): Promise<AxiosResponse<any>> {
    return axios.post(CONNECT_URL + '/account/login',null, {
        headers: {
            "x-api-key": STRIPE_CONNECT_API_KEY!
        }
    })
}

export function updateAccount(data: any): Promise<AxiosResponse<BaseResponse<any>>> {
    return axios.patch<BaseResponse<any>>(GET_CONNECT_ACOUNT_URL, data, {
        headers: {
            "x-api-key": STRIPE_CONNECT_API_KEY!
        }
    })
}

export function completePayment(data: any): Promise<AxiosResponse<any>> {
    return axios.post(CONNECT_URL + '/checkouts/complete',data, {
        headers: {
            "x-api-key": STRIPE_CONNECT_API_KEY!
        }
    })
}
export function getPendingStudentPayment(): Promise<AxiosResponse<any>> {
    return axios.get(CONNECT_URL + '/checkouts/pending-student-payment',{
        headers: {
            "x-api-key": STRIPE_CONNECT_API_KEY!
        }
    })
}
export function ResendStudentPaymentLink(id:any): Promise<AxiosResponse<any>> {
    return axios.post(CONNECT_URL + `/checkouts/${id}/chase-payment`, {},{
        headers: {
            "x-api-key": STRIPE_CONNECT_API_KEY!
        }
    })
}


