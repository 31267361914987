import { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { createClient, fetchPrismicData } from "../../../../../prismic";
import { filter } from "@prismicio/client";
import ArticleTile from "../_shared/ArticleTile";
import CPDFilter from "../_shared/CPDFilter";
import { PlusIcon } from "@heroicons/react/20/solid";
import Button from "../../../components/shared/elements/Button";
import CPDBreakCard from "../_shared/CPDBreakCard";
import { useQuery } from "../../../helpers/QueryParamsHelper";
import { scrollInstantToTagBySelector } from "../../../helpers/ScrollHelper";

const ArticlesList: FC = () => {
  const history = useHistory();
  // const [articles, { state }] = useAllPrismicDocumentsByType("cpd_article");
  const [articles, setArticles] = useState<Array<any>>([]);
  const [mostPopularArticles, setMostPopularArticles] = useState<Array<any>>(
    []
  );
  const [category, setCategory] = useState<Array<any>>([]);
  const [totalResults, setTotalResults] = useState<number>(0);
  const [resultsSize, setResultsSize] = useState<number>(0);
  // const [hasMore, setHasMore] = useState(true);

  // const [sortVal, setSortVal] = useState("");
  const [orderingBy, setOrderingBy] = useState(
    "my.cpd_article.last_publication_date"
  );
  const [orderDir, setOrderDir] = useState<any>("asc");
  const [selectedCategoryID, setSelectedCategoryID] = useState<Array<any>>([]);
  const query = useQuery();
  const [pageCounter, setPageCounter] = useState(+(query.get("page") ?? 20));

  const loadMoreArticles = async () => {
    //console.log("Loading more articles");
    if (resultsSize < totalResults) {
      setPageCounter(pageCounter + 20);
    } else {
      setPageCounter(totalResults);
    }
    // setIsLoading(true);
    // setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const contentType = "category"; // Replace with your content type
    const results = await fetchPrismicData(contentType);
    const idValues = results.map((item: any) => item.id); // Adjust this according to your API response structure
    const categoryUid = query.get("category");
    setSelectedCategoryID(
      categoryUid ? [results?.find((e) => e.uid == categoryUid)?.id] : idValues
    );
  };

  // Initial load of articles
  useEffect(() => {
    //console.log("*** Initial load of articles ***");
    const fetchArticles = async () => {
      try {
        const response = await createClient.get({
          fetchLinks: "category.name",
          filters: [
            filter.at("document.type", "cpd_article"),
            filter.any("my.cpd_article.categories_link", selectedCategoryID),
          ],
          orderings: {
            field: orderingBy,
            direction: orderDir,
          },
          pageSize: pageCounter,
        });
        //console.log(response); // Log the response object
        const mappedArticles = response?.results?.map((result) => ({
          id: result.id,
          // Map additional properties here
          thumbImage: result.data.thumb_img,
          categoryName: result.data.categories_link.data.name,
          // readTime: result.data.timetoread,
          articleTitle: result.data.title,
          //authorAvatar: result.data.author.data.avatar.url,
          authorName: result.data.author,
          author: result.data.author_data,
          releaseDate: result.data.date,
          articleShortDescription:
            result.data.blurb.length > 0
              ? result.data.blurb
                .map((paragraph: any) => paragraph.text)
                .join(" ")
              : result.data.content[0].text,
          uid: result.uid,
          mostPopular: result.data.set_in_most_popular,
          content: result.data.content,
        }));
        //console.log(mappedArticles); // Log the response object
        const popularArticles = mappedArticles.filter((article) => {
          if (article.mostPopular === true) {
            return article;
          }
        });
        const notPopularArticles = mappedArticles.filter((article) => {
          if (!article.mostPopular || article.mostPopular === false) {
            return article;
          }
        });
        //console.log(mappedArticles); // Log the response object
        setArticles(notPopularArticles);
        setMostPopularArticles(popularArticles);

        setArticles(mappedArticles);
        setTotalResults(response.total_results_size);
        setResultsSize(response.results_size);
      } catch (error) {
        console.error("Error while fetching articles:", error);
      }
    };

    fetchArticles();
  }, [pageCounter, selectedCategoryID, orderingBy, createClient]);

  // start:: Initial sort
  const handleSortChange = (newOrder: any) => {
    if (newOrder) {
      const values = getSort(newOrder.target.value);
      getSort(newOrder.target.value);
      setOrderDir(values.dir);
      setOrderingBy(values.orderField);
    }
  };
  const getSort = (value: string): { orderField: string; dir: string } => {
    switch (value) {
      case "title":
        return { orderField: "my.cpd_article.title", dir: "asc" };
      case "author":
        return { orderField: "my.cpd_article.author", dir: "asc" };
      case "newest":
        return {
          orderField: "my.cpd_article.first_publication_date",
          dir: "asc",
        };
      case "oldest":
        return {
          orderField: "my.cpd_article.first_publication_date",
          dir: "desc",
        };
      default:
        return { orderField: "my.cpd_article.date", dir: "asc" };
    }
  };

  // Handle filter by category
  const handleCategoryChange = (selectedCategory: any) => {
    if (selectedCategory) {
      setSelectedCategoryID([selectedCategory.target.value]);
    }
  };

  useEffect(() => {
    if (articles.length > 0)
      scrollInstantToTagBySelector(`[id='${query.get("uid")}']`);
  }, [articles]);

  return (
    <>
      {/* start:: Category & Sort filter */}
      <CPDFilter
        setSort={(sortBy: any) => handleSortChange(sortBy)}
        setCategory={(selectedCategory: any) =>
          handleCategoryChange(selectedCategory)
        }
        handleClearCategoryClick={() => {
          query.delete("category");
          history.replace({
            search: query.toString(),
          });
          fetchData();
        }}
        handleClearSortClick={() => handleSortChange(null)}
      />
      {/* end:: Category & Sort filter */}

      {mostPopularArticles.length > 0 && (
        <div className="bg-white rounded-[20px] px-5 pt-2 pb-9 mt-5 mb-10 ">
          <p className="py-4 text-2xl">Most popular</p>
          <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 xl:grid-cols-3 lg:gap-x-8">
            {mostPopularArticles?.map((item, index) => (
              <ArticleTile
                loading={false}
                key={index}
                imgURL={item.thumbImage}
                category={item.categoryName}
                title={item.articleTitle}
                shortDesc={item.articleShortDescription}
                author={item.authorName}
                authorData={item.author}
                uid={item.uid}
                content={item.content}
                pageCounter={pageCounter}
                onShowArticleClick={() => {
                  const category = query.get("category");
                  if (query.get("category")) {
                    history.push(
                      `/ADI/cpd/article/${item?.uid}?page=${pageCounter}&category=${category}`
                    );
                  } else {
                    history.push(
                      `/ADI/cpd/article/${item?.uid}?page=${pageCounter}`
                    );
                  }
                }}
              />
            ))}
          </div>
        </div>
      )}
      <>
        {/* <ArticleTile loading={true}/> */}
        <div className="grid grid-cols-1 gap-y-9 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-8">
          {articles?.slice(0, 2).map((item, index) => (
            <ArticleTile
              loading={false}
              key={index}
              imgURL={item.thumbImage}
              category={item.categoryName}
              title={item.articleTitle}
              shortDesc={item.articleShortDescription}
              author={item.authorName}
              authorData={item.author}
              uid={item.uid}
              content={item.content}
              pageCounter={pageCounter}
              onShowArticleClick={() => {
                const category = query.get("category");
                if (query.get("category")) {
                  history.push(
                    `/ADI/cpd/article/${item?.uid}?page=${pageCounter}&category=${category}`
                  );
                } else {
                  history.push(
                    `/ADI/cpd/article/${item?.uid}?page=${pageCounter}`
                  );
                }
              }}
            />
          ))}
        </div>

        {/* start:: Break Card 1 */}
        <CPDBreakCard
          imgURL={"/assets/media/CPD/getting-paid.svg"}
          title={
            <>
              Getting paid has never been{" "}
              <span className="bg-free-circle bg-bottom bg-no-repeat bg-contain">
                easier
              </span>
            </>
          }
          desc="Take digital payments by credit card, debit card, Apple Pay & Google Pay"
          button={{
            text: "Find out more",
            onClick: () => history.push(`/ADI/digital-payments`),
            color: "darkBlue",
            disabled: false,
          }}
        />
        {/* end:: Break Card 1 */}

        {/* <ArticleTile loading={true}/> */}
        <div className="grid grid-cols-1 gap-y-9 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-8">
          {articles?.slice(2, pageCounter).map((item, index) => (
            <ArticleTile
              loading={false}
              key={index}
              imgURL={item.thumbImage}
              category={item.categoryName}
              title={item.articleTitle}
              shortDesc={item.articleShortDescription}
              author={item.authorName}
              authorData={item.author}
              uid={item.uid}
              content={item.content}
              onShowArticleClick={() => {
                const category = query.get("category");
                if (query.get("category")) {
                  history.push(
                    `/ADI/cpd/article/${item?.uid}?page=${pageCounter}&category=${category}`
                  );
                } else {
                  history.push(
                    `/ADI/cpd/article/${item?.uid}?page=${pageCounter}`
                  );
                }
              }}
            />
          ))}
        </div>

        <div className="grid grid-cols-6 gap-4 mx-auto w-full mt-6">
          {resultsSize < totalResults && (
            //Load more button
            <Button
              className="col-span-4 col-start-2"
              colour="outline"
              onClick={loadMoreArticles}
              size="large"
            >
              <div className="flex items-center text-lg font-semibold">
                LOAD MORE <PlusIcon className="w-5 h-5 ms-2" />
              </div>
            </Button>
          )}
          <p className="col-span-6 text-[#616161] text-sm text-center mb-5">
            Showing {resultsSize} of {totalResults}
          </p>
        </div>

        {/* start:: Break Card 2 */}
        <CPDBreakCard
          imgURL={"/assets/media/CPD/course-around.svg"}
          title={<>Find available courses local to you</>}
          desc=" Lessons paid upfront & tests booked in advance"
          button={{
            text: "Get work for free",
            onClick: () => history.push(`/ADI/new-jobs`),
            color: "yellow",
            disabled: false,
          }}
        />
        {/* start:: Break Card 2 */}
      </>
    </>
  );
};

export default ArticlesList;
