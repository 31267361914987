import { FC, useEffect } from "react";
import PageHeader from "../../components/shared/elements/PageHeader";
import { DigitalPaymentsComponent } from "../../components/account-settings/digital-payments/DigitalPaymentsComponent";

export const DigitalPaymentsWrapper: FC<any> = () => {
  useEffect(() => {
    document.title = `ADI Network App | Digital Payment Account | ADI Network`;
  }, []);

  return (
    <div>
      <div className="mb-5">
        <PageHeader
          title="Digital Payment Account"
          backToURL="/ADI/settings/overview"
        />
      </div>
      <DigitalPaymentsComponent />
    </div>
  );
};
