import { FC } from "react";
import Modal from "../../shared/overlays/Modal";
import { BankingDetails } from "../bank-details/BankDetails";
import { QuestionnaireStepsEnum } from "../../../../core/models/enums/questionnaire-steps-enum";
import { JobPreferences } from "../job-preferences/JobPreferences";
import { RatesWrapper } from "../rates/RatesWrapper";
import { CancellationPolicy } from "../cancellation-policy/CancellationPolicy";
import { AddDocumentDialog } from "../documents/AddDocumentDialog";
import { CarAndInsurance } from "../cars-and-insurance/CarAndInsurance";
import { QuestionnaireStepModel } from "../../../../core/models/questionnaire-step-model";

interface QuestionnaireStepModalProps {
  show: boolean;
  getQuestionnareProgressData?: () => void;
  onClose: () => void;
  onSubmit: () => void;
  selectedShownModal: number;
  jobId?: string;
  steps: Array<QuestionnaireStepModel>;
}

const QuestionnaireStepModal: FC<QuestionnaireStepModalProps> = ({
  show,
  getQuestionnareProgressData,
  onClose,
  onSubmit,
  selectedShownModal,
  jobId,
  steps
}) => {
  const getModalTitle = () => {
    switch (selectedShownModal) {
      case QuestionnaireStepsEnum.bankDetails:
        return "Banking Details";
      case QuestionnaireStepsEnum.availability:
        return "Job Preferences";
      case QuestionnaireStepsEnum.carAndInsurance:
        return "Car + Insurance";
      case QuestionnaireStepsEnum.rates:
        return "Rates";
      case QuestionnaireStepsEnum.cancellationPolicy:
        return "Cancellation Policy";
      default:
        return "";
    }
  };

  return (
    <>
      {selectedShownModal === QuestionnaireStepsEnum.badge ? (
        <AddDocumentDialog
          show={show}
          onHide={onClose}
          isUploadBadge={true}
          onCreatedNewDocument={onSubmit}
          parentWrapper="questionnaire"
          jobId={jobId}
        />
      ) : (
        <Modal
          open={show}
          onClose={onClose}
          hideClose={true}
          hideSubmit={true}
          title={getModalTitle()}
        >
          {selectedShownModal === QuestionnaireStepsEnum.bankDetails ? (
            <BankingDetails
              parentWrapper="questionnaire"
              refresh={0}
              hasUnVerifiedProfile={false}
              selfRegisterDate={null}
              goToPrevTab={onClose}
              goToNextTab={onSubmit}
            />
          ) : selectedShownModal === QuestionnaireStepsEnum.rates ? (
            <RatesWrapper
              parentWrapper="questionnaire"
              refresh={0}
              hasUnVerifiedProfile={false}
              selfRegisterDate={null}
              goToPrevTab={onClose}
              goToNextTab={onSubmit}
            />
          ) : selectedShownModal ===
            QuestionnaireStepsEnum.cancellationPolicy ? (
            <CancellationPolicy
              parentWrapper="questionnaire"
              refresh={0}
              hasUnVerifiedProfile={false}
              selfRegisterDate={null}
              goToPrevTab={onClose}
              goToNextTab={onSubmit}
            />
          ) : selectedShownModal === QuestionnaireStepsEnum.availability ? (
            <JobPreferences
              parentWrapper="questionnaire"
              refresh={0}
              hasUnVerifiedProfile={false}
              selfRegisterDate={null}
              goToPrevTab={onClose}
              goToNextTab={onSubmit}
            />
          ) : selectedShownModal === QuestionnaireStepsEnum.carAndInsurance ? (
            <CarAndInsurance
              parentWrapper="questionnaire"
              refresh={0}
              hasUnVerifiedProfile={false}
              selfRegisterDate={null}
              goToPrevTab={onClose}
              goToNextTab={onSubmit}
              onUpdate={getQuestionnareProgressData}
              currentStep={steps.filter(step => step.step_number === QuestionnaireStepsEnum.carAndInsurance)[0]}
            />
          ) : (
            <></>
          )}
        </Modal>
      )}
    </>
  );
};

export { QuestionnaireStepModal };
