import { FC, useState } from "react";
import {
  startOfToday,
  format,
  parse,
  eachDayOfInterval,
  startOfWeek,
  endOfWeek,
  setHours,
  setMinutes,
  addWeeks,
  subWeeks,
} from "date-fns";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import { LessonDetailsModal } from "../../_modals/LessonDetailsModal";
import { TestDetailsModal } from "../../_modals/TestDetailsModal";
import { useSharedData } from "../../../../pages/providers/SharedDataProvider";
import { OtherEventDetailsModal } from "../../_modals/OtherEventDetailsModal";

type Props = {
  loading: boolean;
  highlightDates: any;
  selectedOption: any;
  showUpdateModal: any;
  setLesson: any;
  showDeleteModal: any;
  showCancelModal: any;
  initialDate: any;
  isUpcommingOnly: any;
  updateData: () => void;
  selectedDayInCalendar: Date;
  setSelectedDayInCalendar: any;
};

const WeeklyView: FC<Props> = ({
  loading,
  highlightDates,
  selectedOption,
  showUpdateModal,
  setLesson,
  showDeleteModal,
  initialDate,
  isUpcommingOnly,
  updateData,
  selectedDayInCalendar,
  setSelectedDayInCalendar,
  showCancelModal
}) => {
  const { data, updateSharedData } = useSharedData();

  const [currentWeekStart, setCurrentWeekStart] = useState(
    startOfWeek(selectedDayInCalendar ?? new Date(), { weekStartsOn: 1 })
  );

  const generateWeeklyDates = () => {
    // Generate a date range for the current week
    const weekStartDate = startOfWeek(currentWeekStart, { weekStartsOn: 1 });
    const weekEndDate = endOfWeek(currentWeekStart, { weekStartsOn: 1 });
    return eachDayOfInterval({ start: weekStartDate, end: weekEndDate });
  };

  const getMonthName = () => {
    // Extract the month name from the first day of the week
    return format(currentWeekStart, "MMMM yyyy");
  };

  const handlePrevWeek = () => {
    setCurrentWeekStart((prevWeekStart) => subWeeks(prevWeekStart, 1));
    setSelectedDayInCalendar(
      startOfWeek(subWeeks(currentWeekStart, 1), { weekStartsOn: 1 })
    );
    updateSharedData(
      "selectedDate",
      startOfWeek(subWeeks(currentWeekStart, 1), { weekStartsOn: 1 })
    );
  };

  const handleNextWeek = () => {
    setCurrentWeekStart((prevWeekStart) => addWeeks(prevWeekStart, 1));
    setSelectedDayInCalendar(
      startOfWeek(addWeeks(currentWeekStart, 1), { weekStartsOn: 1 })
    );
    updateSharedData(
      "selectedDate",
      startOfWeek(addWeeks(currentWeekStart, 1), { weekStartsOn: 1 })
    );
  };

  // Create an array of hours from 0 to 23
  const hoursArray = Array.from({ length: 24 }, (_, index) => index);

  // Get the current date to set the minutes and seconds to 0
  const now = new Date();
  const currentDay = setMinutes(setHours(now, 0), 0);

  const [showLessonDetails, setShowLessonDetails] = useState<boolean>(false);
  // const [selectedLesson, setSelectedLesson] = useState<Lesson>(new Lesson())
  const [selectedLesson, setSelectedLesson] = useState<any>(null);

  const [showTestDetailsModal, setShowTestDetailsModal] =
    useState<boolean>(false);

  const [showOtherEventDetailsModal, setShowOtherEventDetailsModal] =
    useState<boolean>(false);
  const [selectedEvent, setSelectedEvent] = useState<any>(null);

  const eventClickFun = (event: any) => {
    if (event.type === "pmf" || event.type === "private") {
      setShowLessonDetails(true);
      // setSelectedLesson(hi.filter((lesson: any) => lesson.id === id)[0])
      setSelectedLesson(event);
    } else if (event.type === 'other') {
      setShowOtherEventDetailsModal(true);
      setSelectedEvent(event);
    } else {
      setShowTestDetailsModal(true);
      // setSelectedEvent(modifiedEvent)
      setSelectedEvent(event);
    }
  };

  return (
    <>
      <div className="flex h-full flex-col sm:px-4">
        <header className="flex flex-none items-center justify-between border-b border-gray-200 py-6">
          <div>
            <h2 className="flex-auto text-base font-bold">{getMonthName()}</h2>
          </div>

          <div className="flex items-center">
            <div className="relative flex rounded-md bg-white shadow-sm items-stretch">
              <button
                type="button"
                onClick={() => handlePrevWeek()}
                className="flex h-9 w-12 items-center justify-center rounded-l-md border-y border-l border-gray-300 pr-1 text-gray-400 hover:text-darkBlue focus:relative md:w-9 md:pr-0 md:hover:bg-gray-50"
              >
                <span className="sr-only">Previous day</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </button>
              <button
                type="button"
                onClick={() => {
                  setCurrentWeekStart(
                    startOfWeek(new Date(), { weekStartsOn: 1 })
                  );
                  setSelectedDayInCalendar(new Date());
                }}
                className="border-y border-gray-300 px-3.5 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:relative block"
              >
                This Week
              </button>
              {/* <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" /> */}
              <button
                type="button"
                onClick={() => handleNextWeek()}
                className="flex h-9 w-12 items-center justify-center rounded-r-md border-y border-r border-gray-300 pl-1 text-gray-400 hover:text-darkBlue focus:relative md:w-9 md:pl-0 md:hover:bg-gray-50"
              >
                <span className="sr-only">Next day</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </header>

        <div className="isolate flex flex-auto flex-col bg-white overflow-x-scroll">
          <div
            className="flex max-w-full flex-none flex-col sm:max-w-none md:max-w-full min-w-[600px] md:max-h-[69vh] max-h-[50vh] overflow-y-scroll overflow-x-hidden"
          >
            <div className="sticky top-0 z-30 flex-none bg-white shadow ring-1 ring-black ring-opacity-5 sm:pr-8 w-full">
              <div className="-mr-px grid-cols-7 divide-x divide-gray-100 border-r border-gray-100 text-sm leading-6 text-gray-500 grid">
                <div className="col-end-1 w-12" />
                {generateWeeklyDates().map((day, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-center py-3"
                  >
                    <span className="flex flex-col sm:flex-row items-center justify-center py-0">
                      <span className="inline">
                        {format(day, "E")}{" "}
                      </span>

                      <span
                        className={`${
                          moment(selectedDayInCalendar).isSame(
                            moment(day),
                            "day"
                          )
                            ? "text-center ms-0 sm:ms-2 rounded-full bg-darkBlue text-white"
                            : ""
                        }  w-8 h-8 inline-flex items-center justify-center font-semibold`}
                      >
                        {format(day, "d")}
                      </span>
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-auto">
              <div className="sticky left-0 z-10 w-12 flex-none bg-white ring-1 ring-gray-100" />
              <div className="grid flex-auto grid-cols-1 grid-rows-1">
                {/* Horizontal lines */}
                <div
                  className="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100"
                  style={{
                    gridTemplateRows: "repeat(24, minmax(4.5rem, 1fr))",
                  }}
                >
                  <div className="row-end-1 h-7"></div>
                  {hoursArray.map((hour) => {
                    const hourOfDay = setHours(currentDay, hour);
                    return (
                      <div key={hour}>
                        <div>
                          <div className="sticky left-0 z-20 -ml-12 -mt-2.5 w-12 pr-2 text-right text-xs leading-5 text-gray-400">
                            {format(hourOfDay, "h a")}
                          </div>
                        </div>
                        <div />
                      </div>
                    );
                  })}
                </div>

                {/* Vertical lines */}
                <div className="col-start-1 col-end-2 row-start-1 grid-cols-7 grid-rows-1 divide-x divide-gray-100 grid">
                  <div className="col-start-1 row-span-full" />
                  <div className="col-start-2 row-span-full" />
                  <div className="col-start-3 row-span-full" />
                  <div className="col-start-4 row-span-full" />
                  <div className="col-start-5 row-span-full" />
                  <div className="col-start-6 row-span-full" />
                  <div className="col-start-7 row-span-full" />
                  <div className="col-start-8 row-span-full w-8 hidden sm:inline" />
                </div>

                {/* Events */}
                <ol
                  className="col-start-1 col-end-2 row-start-1 grid grid-cols-7 sm:pr-8"
                  style={{
                    gridTemplateRows:
                      "1.75rem repeat(288, minmax(0, 1fr)) auto",
                  }}
                >
                  {highlightDates
                    .filter((elem: any) =>
                      moment(elem.date).isBetween(
                        moment(
                          startOfWeek(currentWeekStart, { weekStartsOn: 1 })
                        ),
                        moment(
                          endOfWeek(currentWeekStart, { weekStartsOn: 1 })
                        ),
                        "day",
                        "[]"
                      )
                    )
                    .map((item: any, i: number) => (
                      <>
                        <li
                          className={`relative mt-px flex`}
                          style={{
                            gridRow: `${
                              2 +
                              moment(item.date).get("hour") * 12 +
                              Math.round(moment(item.date).get("minute") / 5)
                            } / span ${12 * item.duration}`,
                            gridColumnStart:
                              moment(item.date).diff(
                                moment(currentWeekStart),
                                "days"
                              ) + 1,
                          }}
                        >
                          <div className={`group absolute inset-1`}>
                            <div
                              onClick={() => eventClickFun(item)}
                              className={`min-h-full h-max flex rounded-lg py-1 md:py-2 px-2 border border-white ${
                                item.type === "pmf"
                                  ? "bg-[#2BCD5A]"
                                  : item.type === "private"
                                  ? "bg-[#FFBF00]"
                                  : item.type === "test"
                                  ? "bg-[#32c3d7] h-max"
                                  : item.type === "other"
                                  ? "bg-[#9B9D9C]"
                                  : ""
                              }`}
                            >
                              <p className="flex md:flex-row flex-col text-xs h-fit w-full">
                                <span className="text-white inline whitespace-nowrap mb-0 md:leading-3 leading-none">
                                  <time
                                    className=" leading-none text-xs"
                                    dateTime={moment(item.date).toISOString()}
                                  >
                                    {moment(item.date).format("hh:mm a")}{" "}
                                    <span className="hidden md:inline me-1">
                                      -
                                    </span>
                                  </time>
                                </span>
                                <span className="order-1 md:mt-0 mt-[2px] truncate font-semibold text-white text-xs leading-none">
                                  {item.title}
                                </span>
                              </p>
                            </div>
                          </div>
                        </li>
                      </>
                    ))}
                </ol>

                <LessonDetailsModal
                  show={showLessonDetails}
                  onHide={() => setShowLessonDetails(false)}
                  showLesson={selectedLesson}
                  isUpcommingOnly={isUpcommingOnly}
                  loading={loading}
                  showUpdateModal={showUpdateModal}
                  setLesson={setLesson}
                  showDeleteModal={showDeleteModal}
                  showCancelModal={showCancelModal}
                />

                <TestDetailsModal
                  show={showTestDetailsModal}
                  onClose={() => setShowTestDetailsModal(false)}
                  event={selectedEvent}
                  testsUpdated={() => {
                    updateData();
                  }}
                />

                <OtherEventDetailsModal
                  show={showOtherEventDetailsModal}
                  onClose={() => setShowOtherEventDetailsModal(false)}
                  event={selectedEvent}
                  updateData={updateData}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { WeeklyView };
