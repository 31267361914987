/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import { Login } from './Login'
import { Registration } from './Registration'
import { DispatchEmail } from './DispatchEmail'
import { useLocation } from 'react-router-dom';

export function Authentication() {
  const defaultAction = 'dispatch-email';
  const [authAction, setAuthAction] = useState<string>(defaultAction);
  const [email, setEmail] = useState<string>('');
  let location = useLocation()
  const [returnUrl, setReturnUrl] = useState<string>('')
  
  useEffect(()=>{
    if (location.search && location.search.length > 1) {
      setReturnUrl(location.search.substring(location.search.indexOf("=") + 1))
    }
  }, [])
  if (authAction == "login") {
    return <Login email={email}
      cancelLogin={() => setAuthAction(defaultAction)}
      returnUrl= {returnUrl}
      >
    </Login>;
  } else if (authAction == "registration") {
    return <Registration email={email}
      cancelRegistration={() => setAuthAction(defaultAction)}>
    </Registration>;
  } else {
    return <DispatchEmail
      setAuthAction={setAuthAction}
      setEmail={setEmail}
    >
    </DispatchEmail>
  }
}
