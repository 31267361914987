/* eslint-disable jsx-a11y/anchor-is-valid */
import {
    PencilSquareIcon
} from "@heroicons/react/24/outline";
import { FC } from "react";
import { AdiRateModel } from "../../../../core/models/rates/adi-rate-model";
import Button from "../elements/Button";
import { Skeleton } from "@mui/material";

interface ICarCardProp {
    loading: boolean,
    loadingFile?: boolean,
    rate?: AdiRateModel,
    openRateModal?: (rate?: AdiRateModel) => void
}

const RateCard: FC<ICarCardProp> = ({ rate, openRateModal, loading, loadingFile }) => {
    return (
        <>
            <div className=" shadow rounded-[10px] border-l-8 border-l-yellow bg-white p-3 mb-5">
                <div className="flex-wrap items-center justify-between lg:flex-nowrap w-full">
                    <div className="flex flex-col w-full">
                        <div className="flex justify-between items-center">
                            {loading ? <div className="flex item-center">
                                <Skeleton width={140} />
                            </div> :
                                <h3 className="text-base font-bold leading-6 text-darkBlue">
                                    {rate?.trasmission_type_name}
                                </h3>
                            }
                            <div className="ml-4 flex flex-shrink-0">
                                {loading ? <div className="flex item-center">
                                    <Skeleton width={30} height={30} className="me-2" variant="circular"/>
                                </div> :
                                    <Button
                                        onClick={() => { openRateModal?.(rate) }}
                                        disabled={loadingFile}
                                        Icon={PencilSquareIcon}
                                        size="icon"
                                        colour="transparent"
                                    />
                                }
                            </div>
                        </div>
                        <div className="w-full h-px my-3 bg-gray-200 border-0 "></div>
                    </div>

                    <div className='flex flex-col w-full pb-0'>
                        {loading ? <div className="">
                            <Skeleton width={150} />
                        </div> :
                            <h4 className='text-sm font-titlef font-bold mb-2'>Booking rate:</h4>}
                        {loading ?
                            <div className="flex justify-between">
                                <Skeleton width={180} />
                                <Skeleton width={80} />
                            </div> :
                            <div className='flex justify-between'>
                                <p className='text-xs'>1 – 9h block (per h)</p>
                                <p className='text-xs font-semibold'>
                                    £{Number(rate?.first_range_hour_price).toFixed(2)}
                                </p>
                            </div>
                        }
                        {loading ?
                            <div className="flex justify-between my-2">
                                <Skeleton width={160} />
                                <Skeleton width={80} />
                            </div> :
                            <div className='flex justify-between my-2'>
                                <p className='text-xs'>10 – 29h block (per h)</p>
                                <p className='text-xs font-semibold'>
                                    £{Number(rate?.second_range_hour_price).toFixed(2)}
                                </p>
                            </div>
                        }
                        {loading ?
                            <div className="flex justify-between">
                                <Skeleton width={170} />
                                <Skeleton width={80} />
                            </div> :
                            <div className='flex justify-between'>
                                <p className='text-xs'>30+ hours (per h)</p>
                                <p className='text-xs font-semibold'>
                                    £{Number(rate?.third_range_hour_price).toFixed(2)}
                                </p>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export { RateCard };
