/* eslint-disable jsx-a11y/anchor-is-valid */
import {
    PencilSquareIcon,
    TrashIcon,
} from "@heroicons/react/20/solid";
import { FC } from "react";
import Badge from "../elements/Badge";
import { ActionMenu } from "../forms/ActionMenu";
import Skeleton from "@mui/material/Skeleton";
import InfoList from "../data/InfoList";
import { toAbsoluteUrl } from "../../../helpers";
import { DocumentCheckIcon, EyeIcon } from "@heroicons/react/24/outline";
import Button from "../elements/Button";

interface IDocumentCardProp {
    loading: boolean,
    url?: string,
    documentType: "Insurance" | "Badge" | "Other",
    badgeName?: string,
    adiNumber?: string,
    expiryDate?: string | null
    insuranceProvider?: string,
    car?: string
    rejectionReason?: string,
    fileType?: "image" | "pdf" | "doc"
}

const DocumentCard: FC<IDocumentCardProp> = ({ loading = false, url, documentType, badgeName, adiNumber, expiryDate, insuranceProvider, car, rejectionReason, fileType }) => {

    const viewFile = (url: string) => {
        Object.assign(document.createElement('a'), {
            target: '_blank',
            href: url,
        }).click();
    }

    const isExpired = () => {
        if (expiryDate)
            return new Date(expiryDate) < new Date()
    }

    return (
        <>
            <div className="bg-white border border-gray-200 rounded-lg shadow">
                <div className="grid grid-cols-4">
                    
                    <div className="flex flex-col justify-between p-4 leading-normal col-span-3">
                        <div className={`flex justify-start space-x-1 items-start`}>
                            <div className="flex items-center mr-2">
                                <Badge colour='green' title={documentType} />
                            </div>
                            {isExpired() ?
                                <div className="flex items-center mr-2">
                                    <Badge colour='darkRed' title='expired' />
                                </div> : null
                            }
                        </div>
                        <div className={`my-3 relative w-full flex justify-between`}>
                            {badgeName ?
                                <div>
                                    <p className="text-[10px] text-slate-500 font-light">
                                        Name on badge
                                    </p>
                                    <p className="text-sm text-ellipsis overflow-hidden pr-10">
                                        {badgeName}
                                    </p>
                                </div> : null
                            }
                            {adiNumber ?
                                <div>
                                    <p className="text-[10px] text-slate-500 font-light">
                                        ADI number
                                    </p>
                                    <p className="text-sm text-ellipsis overflow-hidden pr-10">
                                        {adiNumber}
                                    </p>
                                </div> : null
                            }
                            {insuranceProvider ?
                                <div>
                                    <p className="text-[10px] text-slate-500 font-light">
                                        Insurance provider
                                    </p>
                                    <p className=" text-ellipsis overflow-hidden pr-10">
                                        {insuranceProvider}
                                    </p>
                                </div> : null
                            }
                            {car ?
                                <div>
                                    <p className="text-[10px] text-slate-500 font-light">
                                        Car
                                    </p>
                                    <p className=" text-ellipsis overflow-hidden pr-10">
                                        {car}
                                    </p>
                                </div> : null
                            }
                            
                        </div>

                        <div className="">
                        {rejectionReason ?
                        <div className="-mt-3">
                            <InfoList data={[
                                {
                                    icon: "pmf-icon-info",
                                    value: <span className='text-danger leading-1'> Badge rejected: 
                                    <span className="font-bold ps-1"> {rejectionReason}</span></span>,
                                }]
                            } />
                        </div>
                        : null
                        }
                         
                        {expiryDate ?
                                <InfoList data={[
                                    {
                                        icon: "pmf-icon-calendar",
                                        value: <span className={`${isExpired() ? 'text-danger' : ''} leading-1`}>Expiry date: 
                                        <span className="font-bold ps-1">{expiryDate}</span></span>,
                                    }]
                                } /> : null
                            }
                        </div>
                    </div>

                    {
                        <>
                            {fileType === "image" ? 
                            <img className="object-cover h-16 w-16 my-4 flex-shrink-0 rounded bg-gray-200 border-[4px] border-[#F4F5F7]" alt="" src={url} /> : null}
                            {fileType === "pdf" || fileType === "doc"  ? 
                            <div className="bg-darkBlue object-cover h-16 w-16 my-4 flex-shrink-0 rounded border-[4px] border-[#F4F5F7] flex items-center justify-center">
                                <DocumentCheckIcon className="w-10 h-10 text-white"></DocumentCheckIcon>
                            </div>
                            : null}
                           
                        </>
                    }
                </div>
                    {url &&
                        <div className='border-t w-full py-2 text-center'>
                            <Button colour="link" onClick={() => viewFile(url)} fullWidth>
                                <span className="flex items-center justify-center no-underline">
                                View file 
                                </span>
                            </Button>
                        </div>
                    }
                
            </div>
        </>
    );
};

export { DocumentCard };
