/* eslint-disable jsx-a11y/anchor-is-valid */
import { Menu, Transition } from "@headlessui/react";
import { useState, Fragment } from "react";
import { MoneyInModal } from "../components/payments/_modals/MoneyInModal";
import { MoneyOutModal } from "../components/payments/_modals/MoneyOutModal";
import { AddEditLessonModel } from "../components/lessons/_modals/AddEditLessonModel";
import { AddPrivateStudentDialog } from "../components/students/AddPrivateStudentDialog";
import { AddAssessmentDialog } from "../components/jobs/job-details/tabs/_modals/AddAssessmentDialog";
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import { PlusSmallIcon } from "@heroicons/react/24/outline";

interface IProduct {
    title: string;
    icon: string;
    linkURL: string;
    onClickFunction: any;
}

export interface IQuickAddButton {
    show: "Learner" | "Events";
    refreshParent?: any
    setShowEventsSubMenu?: any
}

export default function QuickAddButton({
    show,
    refreshParent,
    setShowEventsSubMenu
}: IQuickAddButton) {

    const [showAddMoneyInModal, setShowAddMoneyInModal] = useState(false)
    const [showAddMoneyOutModal, setShowAddMoneyOutModal] = useState(false)
    const [showAddLessonCard, setShowAddLessonCard] = useState<boolean>(false)
    const [showAssessmentModal, setShowAssessmentModal] = useState<boolean>(false)
    const [showAddStudentModal, setShowAddStudentModal] = useState(false)

    const openAddMoneyInModal = () => {
        setShowAddMoneyInModal(true)
    }

    const closeAddMoneyInModal = () => {
        setShowAddMoneyInModal(false)
    }

    const openAddMoneyOutModal = () => {
        setShowAddMoneyOutModal(true)
    }

    const closeAddMoneyOutModal = () => {
        setShowAddMoneyOutModal(false)
    }
    const showAddLessonCardModal = () => {
        setShowAddLessonCard(true)
    }

    const openAddStudentModal = () => {
        setShowAddStudentModal(true)
    }

    const closeAddStudentModal = () => {
        setShowAddStudentModal(false)
    }

    const openAssessmentModal = () => {
        setShowAssessmentModal(true)
    }

    const closeAssessmentModal = () => {
        setShowAssessmentModal(false)
    }

    const initialProducts: IProduct[] = [
        {
            title: "Add Lesson",
            icon: "pmf-icon-wheel",
            linkURL: "",
            onClickFunction: showAddLessonCardModal
        },
        {
            title: "Add Learner",
            icon: "pmf-icon-user",
            linkURL: "300",
            onClickFunction: openAddStudentModal
        },
        {
            title: "Add Progress",
            icon: "pmf-icon-statistics",
            linkURL: "",
            onClickFunction: openAssessmentModal
        },
        {
            title: "Add Money in",
            icon: "pmf-icon-credit-card",
            linkURL: "",
            onClickFunction: openAddMoneyInModal
        },
        {
            title: "Add Money out",
            icon: "pmf-icon-expense",
            linkURL: "300",
            onClickFunction: openAddMoneyOutModal
        },
    ];


    return (
        <>
            <div className="fixed bottom-28 md:mx-0 md:end-4 right-2 p-0 z-[100] mouse  transition ease-in duration-200 focus:outline-none inline-flex">
                <button
                    type="button"
                    className="relative w-[180px] inline-flex items-center rounded-l-full bg-yellow px-3 py-2 text-xs font-semibold text-darkBlue hover:brightness-75 focus:z-10 border-r-2"
                    onClick={() => {
                        if (show === "Events") setShowEventsSubMenu(true);
                        else
                          initialProducts
                            .find((x) => x.title.includes(show))
                            ?.onClickFunction();
                      }}
                >
                    <span className='flex items-center justify-center w-full py-1 text-sm'>
                        <PlusSmallIcon className='w-5 h-5' />
                    {show === "Events" ? "Add" : initialProducts.find(x => x.title.includes(show))?.title}
                    </span>
                </button>
                <Menu as="div" className="relative -ml-px block" >
                    <Menu.Button className="relative inline-flex items-center rounded-r-full bg-yellow px-2 py-2 text-darkBlue hover:brightness-75  focus:z-10">
                        {({ open }) => (
                            <span className='flex items-center py-1'>
                                {open ? <ChevronUpIcon className="h-5 w-5" aria-hidden="true" /> : <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />}
                            </span>
                        )}
                    </Menu.Button>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items className="top-5 transform -translate-y-full absolute right-0 w-[215px] origin-top-right bg-white rounded-t-md focus:outline-none" style={{ zIndex: -1 }}>
                            <div className="py-1">
                                {initialProducts.map((item, i) => (
                                    item.title.includes(show) ? null :
                                        <Menu.Item key={item.title}>
                                            {({ active }) => (
                                                <div
                                                    key={i}
                                                    onClick={() => item.onClickFunction()}
                                                    className={`text-darkBlue block px-2 py-2 text-sm flex items-center font-semibold cursor-pointer 
                                                    ${i === (initialProducts.length -1) && " pb-6"} `}
                                                >
                                                    <i className={`${item.icon}`}></i>
                                                    <span className="ml-2" >{item.title}</span>
                                                </div>
                                            )}
                                        </Menu.Item>
                                ))}
                            </div>
                        </Menu.Items>
                    </Transition>
                </Menu>
            </div>
            <MoneyInModal
                show={showAddMoneyInModal}
                onClose={closeAddMoneyInModal}
                onSubmit={closeAddMoneyInModal}
                refreshParent={refreshParent}
            />

            <MoneyOutModal
                show={showAddMoneyOutModal}
                onClose={closeAddMoneyOutModal}
                onSubmit={closeAddMoneyOutModal}
                refreshParent={refreshParent}
            />

            <AddEditLessonModel 
                onClose={() => { setShowAddLessonCard(false) }}
                duration={1}
                firstDate={null}
                jobId={undefined}
                id={undefined}
                lessonsUpdated={() => {}}
                show={showAddLessonCard}
                isPrivateJob={null}
                refreshParent={refreshParent}
            />

            <AddPrivateStudentDialog 
                show={showAddStudentModal} 
                onHide={closeAddStudentModal} 
                type={"add"} jobDetails={null} 
                refreshParent={refreshParent}
                refresh={closeAddStudentModal}
            />

            <AddAssessmentDialog
                show={showAssessmentModal}
                onHide={closeAssessmentModal}
                refreshParent={refreshParent}
            />
        </>
    );
};

