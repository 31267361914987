import * as Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

export function getYears(yearsCount: number, yearDiff: number, isIncrementing: boolean): Array<any> {
    var years: Array<any> = [];
    const currentYear: number = Number(new Date().getFullYear());
    var startYear: number = currentYear - yearDiff;
    if (isIncrementing) {
        var endYear: number = startYear + yearsCount;
        for (let i = startYear; i <= endYear; i++) {
            years.push({ label: i.toString(), value: i.toString() })
        }
    }
    else {
        var endYear: number = startYear - yearsCount;
        for (let i = startYear; i >= endYear; i--) {
            years.push({ label: i.toString(), value: i.toString() })
        }
    }
    return years;
}
export function getMonths(): Array<any> {
    return moment.months().map(month => {
        return {
            label: month, value: month
        }
    });
}
export function getDays(yearStr: string, month: number): Array<string> {
    const year = parseInt(yearStr);
    var days: Array<string> = [];
    var firstDayInMonth: number = new Date(year, month, 1).getDate();
    var lastDayInMonth: number = new Date(year, month + 1, 0).getDate();
    for (let i = firstDayInMonth; i <= lastDayInMonth; i++) {
        days.push(i.toString())
    }
    return days;
}

export function getAllDays(): Array<any> {
    var days: Array<string> = [];
    for (let i = 1; i <= 31; i++) {
        days.push(i.toString())
    }
    return days.map(day => {
        return {
            label: day, value: day
        }
    });
}

export function getTimes(fromHour: number, toHours: number, span: number): Array<any> {
    var times = [];
    var start = fromHour * 60;
    var end = toHours * 60;
    for (var i = 0; start <= end; i++) {
        var hh = Math.floor(start / 60);
        var mm = (start % 60);
        times[i] = {
            value: ("0" + hh).slice(-2) + ':' + ("0" + mm).slice(-2),
            label: ("0" + hh).slice(-2) + ':' + ("0" + mm).slice(-2)
        };
        start = start + span;
    }

    return times;
}

export function formatDisplayedDate(date: Date |null): string {
    return moment(date).format('dddd, D  MMMM')
}

export function formatDisplayedTime(date: Date |null): string {
    return moment(date).format('LT')
}

export function formatDisplayedTimeWithDuration(date: Date |null, duration: number): string {    
    return moment(date).format('h:mm a') + ' to ' + moment(date).add(duration, 'minutes').format('h:mm a')
}

export function getDaysRangeFromToday(days: number) {
    var start = moment().subtract(days, 'days');
    var end = moment().add(days, 'days');
    const duration = Array.from(moment.range(start,end).by('days')).map((m,i) =>  {return  [ {"dayName":m.format('ddd')},{"date":m.format('YYYY-MM-DD')},{"dayNo":m.format('DD')},{"monthName":m.format('MMM')}]});

    return duration;
}
