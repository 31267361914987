import { FC } from "react";
import Modal from "../../shared/overlays/Modal";
import { GeneralQuestionnaireData } from "./GeneralQuestionnaireData";

interface CompleteQuestionnaireDataModalProps {
  show: boolean;
  onClose: () => void;
  jobId?: string;
  goToDashboard?: boolean;
}

const CompleteQuestionnaireDataModal: FC<
  CompleteQuestionnaireDataModalProps
> = ({ show, onClose, jobId, goToDashboard = true }) => {
  return (
    <Modal
      open={show}
      hideSubmit={true}
      title="Questionnaire"
      closeText={"Close"}
      onClose={onClose}
    >
      <GeneralQuestionnaireData
        goToPrevTab={() => {}}
        goToNextTab={() => {}}
        parentWrapper={""}
        refresh={0}
        hasUnVerifiedProfile={undefined}
        jobId={jobId}
        selfRegisterDate={undefined}
        onCloseParentModal={onClose}
        goToDashboard={goToDashboard}
      />
    </Modal>
  );
};

export { CompleteQuestionnaireDataModal };
