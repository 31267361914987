import { FC, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import ReactGA from "react-ga4";
import {
  acceptJob,
  cancelOverlap,
  checkOverlap,
  getAcceptJobData,
} from "../../../core/services/job-service";
import { AcceptJobData } from "../../../core/models/accept-job-data";
import DescriptionList from "../shared/data/DescriptionList";
import Button from "../shared/elements/Button";
import Alert from "../shared/overlays/Alert";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { JobBidCard } from "../shared/cards/JobBidCard";
import { AvailabilityCard } from "../shared/cards/AvailabilityCard";
import { NewJobModalParent } from "./modals/NewJobModalParent";
import Skeleton from "@mui/material/Skeleton";
import { Course } from "../../../core/models/enums/course-intensity-enum";
import toast from "react-hot-toast";
import Notification from "../shared/overlays/Notification";
import { CourseProviderTermsAndConditionDialog } from "./modals/CourseProviderTermsAndConditionDialog";
import { GrayFullCard } from "../shared/cards/GrayFullCard";
import { CantAcceptJobDialog } from "./modals/CantAcceptJobDialog";
import { getCanAcceptJobs } from "../../../core/services/adi-service";
import { CompleteQuestionnaireDataModal } from "../account-settings/general-questionnaire-data/CompleteQuestionnaireDataModal";
import { BidTypesModal } from "./modals/BidTypesModal";
import Badge from "../shared/elements/Badge";

const NewJobsDetails: FC = () => {
  const { jobId }: any = useParams();
  const history = useHistory();
  const urlSearchParams = new URLSearchParams(window.location.search);
  let recruiter = urlSearchParams.get("recruiter");
  let recruiterId = urlSearchParams.get("recruiterId");
  let urlGenerationDateTime = urlSearchParams.get("datetime");
  let isFromSMS = urlSearchParams.get("isFromSMS") == "true" ? true : false;
  let isFromNewJobsSMS =
    urlSearchParams.get("new_jobs_sms") == "true" ? true : false;
  let isFromNewJobsCustomizedSMS =
    urlSearchParams.get("new_jobs_customized_sms") === "1" ? true : false;

  const [acceptJobData, setAcceptJobData] = useState<AcceptJobData>();
  const [tags, setTags] = useState<Array<any>>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [showRegisterInterest, setShowRegisterInterest] =
    useState<boolean>(false);
  const [showWithdrawDialog, setShowWithdrawDialog] = useState<boolean>(false);
  const [showClashDialog, setShowClashDialog] = useState<boolean>(false);
  const [showTermsAndConditionDialog, setShowTermsAndConditionDialog] =
    useState<boolean>(false);
  const [clashedData, setClashedData] = useState<any>();
  const [canAcceptJob, setCanAcceptJob] = useState<boolean>(false);
  const [availMatch, setAvailMatch] = useState<string>();
  const [showCantAccept, setShowCantAccept] = useState<boolean>(false);
  const [showQuestionnaire, setShowQuestionnaire] = useState<boolean>(false);
  const [showRegisterLocationInterest, setShowRegisterLocationInterest] =
    useState<boolean>(false);
  const [showBidOptionsModal, setShowBidOptionsModal] =
    useState<boolean>(false);

  const [
    showRegisterAvailabilityInterest,
    setShowRegisterAvailabilityInterest,
  ] = useState<boolean>(false);

  useEffect(() => {
    if (recruiter != null && recruiter != undefined) {
      ReactGA.event("open_job_from_sms");
    } else {
      ReactGA.event("view_new_job_details");
    }
    getJobData();
  }, []);

  const onHide = (isUpdated: boolean) => {
    setShowRegisterInterest(false);
    setShowWithdrawDialog(false);
    setShowRegisterLocationInterest(false);
    setShowRegisterAvailabilityInterest(false);
    setShowClashDialog(false);
    if (isUpdated) {
      getJobData();
    }
  };

  const loadingToaster = (message: string) => {
    let loadingId = toast.custom(
      () => (
        <Notification
          colour="gray"
          title={message}
          description={""}
          Icon={ExclamationCircleIcon}
        />
      ),
      {
        duration: 5000,
        position: "top-center",
      }
    );
    return loadingId;
  };

  const successToaster = (
    message = "Saved successfully",
    loadingId: string
  ) => {
    toast.custom(
      () => (
        <Notification
          colour="green"
          title={message}
          description={""}
          Icon={CheckCircleIcon}
        />
      ),
      {
        duration: 5000,
        position: "top-center",
        id: loadingId,
      }
    );
  };

  const errorToaster = (message: string, loadingId: string) => {
    toast.custom(
      () => (
        <Notification
          colour="red"
          title={message}
          description={""}
          Icon={ExclamationTriangleIcon}
        />
      ),
      {
        duration: 5000,
        position: "top-center",
        id: loadingId,
      }
    );
  };

  const getJobData = () => {
    setLoading(true);
    setError(null);
    getAcceptJobData(jobId, isFromSMS)
      .then((data) => {
        let tempJobData = data.data.results;
        setAcceptJobData(data.data.results);
        let tempTags = [];
        if (data.data.results.show_transmission === 1)
          tempTags.push({
            title: tempJobData?.is_manual ? "Manual" : "Automatic",
            colour: "green",
          });

        if (tempJobData?.is_new_job == 1 && tempJobData?.is_updated_job != 1)
          tempTags.push({ title: "New", colour: "darkRed" });

        if (tempJobData?.is_updated_job == 1)
          tempTags.push({ title: "Updated", colour: "darkRed" });
        setTags(tempTags);

        if (data.data && data.data.errors && data.data.errors.length > 0) {
          //setCanAcceptJob(false);
          if (tempJobData?.adi_has_badge === 1) {
            //setError(data.data.errors[0].errorMessage);
          }
        }
        // getAvailabilityMatch();
        if (
          data.data.results.availability_match &&
          data.data.results.availability_match >= 20
        ) {
          let match = "Great";
          if (
            data.data.results.availability_match >= 20 &&
            data.data.results.availability_match <= 34
          ) {
            match = "Fair";
          }
          if (
            data.data.results.availability_match >= 35 &&
            data.data.results.availability_match < 50
          ) {
            match = "Good";
          }
          setAvailMatch(match);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error && error.errors && error.errors.length > 0) {
          setLoading(false);
          setError(error.errors[0].errorMessage);
        } else {
          setLoading(false);
          setError("Sorry, an error has occured");
        }
      });
    getCanAcceptJobs().then((data) => {
      if (
        "can_accept_jobs" in data?.data?.results &&
        data?.data?.results?.can_accept_jobs === 1
      ) {
        setCanAcceptJob(true);
      }
    });
  };

  const registerInterest = () => {
    ReactGA.event("click_register_interest");
  };

  function cancel() {
    history.push({
      pathname: "/ADI/new-jobs",
      search: sessionStorage.getItem("new_jobs_query") ?? "",
    });
  }

  const checkClash = () => {
    setSubmitting(true);
    checkOverlap(jobId)
      .then((res) => {
        if (res.data.results.has_clash) {
          setSubmitting(false);
          setClashedData(res.data.results);
          setShowClashDialog(true);
        } else {
          setShowTermsAndConditionDialog(true);
        }
      })
      .catch((error) => {
        setSubmitting(false);
      });
  };

  const cancelClash = () => {
    setLoading(true);
    setSubmitting(true);
    let loadingId = loadingToaster("Cancelling overlap...");
    cancelOverlap(jobId)
      .then((res) => {
        setLoading(false);
        setShowClashDialog(false);
        successToaster("Done", loadingId);
        history.push({
          pathname: "/ADI/new-jobs",
          search: sessionStorage.getItem("new_jobs_query") ?? "",
        });
      })
      .catch((error) => {
        setLoading(false);
        errorToaster("Failed to cancel overlap!", loadingId);
      });
  };

  const acceptTheJob = () => {
    setShowClashDialog(false);
    setSubmitting(true);
    var data = {
      recruiter: recruiter,
      recruiterId: recruiterId,
      date: urlGenerationDateTime,
      isFromNewJobsSMS: isFromNewJobsSMS,
      isFromNewJobsCustomizedSMS: isFromNewJobsCustomizedSMS,
    };
    setSubmitting(true);
    let loadingId = loadingToaster("Accepting job...");
    acceptJob(jobId, data)
      .then((data) => {
        ReactGA.event("accept_job");
        setSubmitting(false);
        successToaster("Saved successfully", loadingId);
        history.push({
          pathname: "/ADI/new-jobs",
          search: sessionStorage.getItem("new_jobs_query") ?? "",
        });
      })
      .catch((error) => {
        setSubmitting(false);
        if (error && error.errors && error.errors.length > 0) {
          setSubmitting(false);
          setError(error.errors[0].errorMessage);
        } else {
          setSubmitting(false);
          setError("Sorry, an error has occured");
        }
        errorToaster("Failed to accept job!", loadingId);
      });
  };

  const getModalTitle = () => {
    if (showRegisterInterest) return "Register Interest";
    else if (showWithdrawDialog) return "Withdraw Job Bid";
    else if (showClashDialog) return "Existing Appointments";
    else if (showRegisterAvailabilityInterest) return "Register Interest";
    else return "Job #" + acceptJobData?.job_ref;
  };

  const getAvailabilityMatch = () => {
    let match = "";
    if (
      acceptJobData?.availability_match &&
      acceptJobData?.availability_match >= 20
    ) {
      match = "Great";
      if (
        acceptJobData?.availability_match >= 20 &&
        acceptJobData?.availability_match <= 34
      ) {
        match = "Fair";
      }
      if (
        acceptJobData?.availability_match >= 35 &&
        acceptJobData?.availability_match < 50
      ) {
        match = "Good";
      }
    }
    setAvailMatch(match);
    return match;
  };

  const mapTextAvailability = () => {
    let tempAvailability = acceptJobData?.student_availability
      ?.replace("Specific Dates ", "")
      ?.split("\n")
      ?.filter(function (e) {
        return e.replace(/(\n)/, "");
      })
      ?.map((day, i) => (day.replace(" ", "").length > 0 ? day : ""));

    return tempAvailability?.map((day) => {
      return {
        day_short_name: day?.split(" ")[1],
        availability: [
          {
            from_time: day?.split(" ")[4]?.split("-")[0],
            to_time: day?.split(" ")[4]?.split("-")[1],
          },
        ],
      };
    });
  };

  const getCourseIntensityDesc = () => {
    if (acceptJobData?.course_intensity === Course.Intensive) {
      return "This learner wants to fit their lessons into as few days as possible. One example of this is four 3-hour lessons a week, so they’d expect to complete a 10-hour course in a week and a 48-hour course in around a month.";
    } else if (acceptJobData?.course_intensity === Course.SemiIntensive) {
      return "This learner doesn’t want to take things too intensively. An example of a semi-intensive course would be one or two 3-hour lessons per week.";
    } else if (acceptJobData?.course_intensity === Course.Relaxed) {
      return "This learner doesn’t want an intensive course and is happy to spread their lessons out. This would likely consist of one or two 2-hour lessons per week.";
    } else {
      return "";
    }
  };

  const openQuestionnaire = () => {
    setShowQuestionnaire(true);
  };

  const practicalTestData = () => {
    let testData: {
      icon: "pmf-icon-clock" | "pmf-icon-test" | "pmf-icon-info" | undefined;
      value: string | undefined;
    }[] = [];

    if (
      (!acceptJobData?.test_center_name || !acceptJobData?.test_date) &&
      acceptJobData?.pt_required_at == null
    ) {
      testData.push({
        icon: "pmf-icon-info",
        value: "Lessons only",
      });
    } else {
      if (
        acceptJobData?.test_center_name ||
        acceptJobData?.test_preferred_test_centers
      ) {
        testData.push({
          icon: "pmf-icon-test",
          value:
            acceptJobData?.test_center_name ??
            acceptJobData?.test_preferred_test_centers,
        });
      }

      if (
        acceptJobData?.test_date?.toString() ||
        acceptJobData?.test_preferred_dates_str
      ) {
        testData.push({
          icon: "pmf-icon-clock",
          value:
            acceptJobData?.test_date?.toString() ??
            acceptJobData?.test_preferred_dates_str,
        });
      }
    }

    return testData;
  };
  return (
    <>
      {error && <Alert colour="red" icon="pmf-icon-info" title={error} />}

      <DescriptionList
        loading={loading}
        title={acceptJobData?.hours + " Hours"}
        pmfIcon={true}
        jobRef={"#" + acceptJobData?.job_ref}
        pills={tags}
        rounded={false}
        data={practicalTestData()}
      />

      <div className="bg-white px-4 pb-12 md:pb-4">
        {/*START:: You will earn */}
        <div className=" mb-4 -mt-4">
          {loading ? (
            <Skeleton height={50} width={200} />
          ) : (
            <GrayFullCard
              topIcon="pmf-icon-currency-pound"
              title={
                acceptJobData?.total_rate != null &&
                acceptJobData?.show_app_rate == false
                  ? "You will earn:"
                  : ""
              }
              value={
                acceptJobData?.show_app_rate == false ? (
                  <div className="flex flex-wrap">
                    <span className="text-base text-darkBlue">
                      <strong>
                        {acceptJobData?.total_rate != null ? "£" : ""}
                        {Number.isInteger(acceptJobData?.total_rate)
                          ? acceptJobData?.total_rate?.toLocaleString()
                          : acceptJobData?.total_rate != null
                          ? acceptJobData?.total_rate?.toFixed(2)
                          : ""}
                      </strong>
                    </span>
                    <span className="text-base font-normal ms-1 text-darkBlue">
                      {" "}
                      {Number.isInteger(acceptJobData?.rate_per_hour)
                        ? "("
                        : ""}
                      Hourly Rate:{" "}
                      <span>
                        {Number(acceptJobData?.rate_per_hour) ? "£" : ""}
                        {Number.isInteger(acceptJobData?.rate_per_hour)
                          ? acceptJobData?.rate_per_hour?.toLocaleString()
                          : Number(acceptJobData?.rate_per_hour)
                          ? acceptJobData?.rate_per_hour?.toFixed(2)
                          : acceptJobData?.rate_per_hour}
                      </span>
                      {Number.isInteger(acceptJobData?.rate_per_hour)
                        ? ")"
                        : ""}
                    </span>
                  </div>
                ) : (
                  <div>
                    <div className="text-[15px] font-normal text-darkBlue mb-1">
                      Accept via the app and you will earn:
                    </div>
                    <div className="flex text-brown justify-between gap-x-2 items-baseline -mb-1">
                      <div>
                        <span className="text-base">
                          <strong>
                            {acceptJobData?.total_rate != null ? "£" : ""}
                            {Number.isInteger(acceptJobData?.total_rate)
                              ? acceptJobData?.total_rate?.toLocaleString()
                              : acceptJobData?.total_rate != null
                              ? acceptJobData?.total_rate?.toFixed(2)
                              : ""}
                          </strong>
                        </span>
                        <span className="text-base  font-normal">
                          {" "}
                          &nbsp;
                          {!Number.isNaN(acceptJobData?.rate_per_hour)
                            ? "("
                            : ""}
                          Hourly Rate:{" "}
                          <span>
                            {Number(acceptJobData?.rate_per_hour) ? "£" : ""}
                            {Number.isInteger(acceptJobData?.rate_per_hour)
                              ? acceptJobData?.rate_per_hour?.toLocaleString()
                              : Number(acceptJobData?.rate_per_hour)
                              ? acceptJobData?.rate_per_hour?.toFixed(2)
                              : acceptJobData?.rate_per_hour}
                          </span>
                          {!Number.isNaN(acceptJobData?.rate_per_hour)
                            ? ")"
                            : ""}
                        </span>
                      </div>
                      <span className="py-1 rounded-full whitespace-nowrap">
                        <Badge colour={"brown"} title={"App Rate"} />
                      </span>
                    </div>
                    <div className="flex justify-between  gap-x-2 items-baseline">
                      <div className="line-through">
                        <span className="text-base text-gray-400">
                          <strong>
                            {acceptJobData?.original_total_rate != null
                              ? "£"
                              : ""}
                            {Number.isInteger(
                              acceptJobData?.original_total_rate
                            )
                              ? acceptJobData?.original_total_rate?.toLocaleString()
                              : acceptJobData?.original_total_rate != null
                              ? acceptJobData?.original_total_rate?.toFixed(2)
                              : ""}
                          </strong>
                        </span>
                        <span className="text-base font-normal text-gray-400">
                          {" "}
                          &nbsp;
                          {!Number.isNaN(acceptJobData?.original_rate_per_hour)
                            ? "("
                            : ""}
                          Hourly Rate:{" "}
                          <span>
                            {Number(acceptJobData?.original_rate_per_hour)
                              ? "£"
                              : ""}
                            {Number.isInteger(
                              acceptJobData?.original_rate_per_hour
                            )
                              ? acceptJobData?.original_rate_per_hour?.toLocaleString()
                              : Number(acceptJobData?.original_rate_per_hour)
                              ? acceptJobData?.original_rate_per_hour?.toFixed(
                                  2
                                )
                              : acceptJobData?.original_rate_per_hour}
                          </span>
                          {!Number.isNaN(acceptJobData?.original_rate_per_hour)
                            ? ")"
                            : ""}
                        </span>
                      </div>
                      <div className="px-0 py-1 rounded-full ">
                        <Badge colour={"darkBlue"} title={"Standard"} />
                      </div>
                    </div>
                  </div>
                )
              }
            />
          )}
        </div>
        {acceptJobData?.student_post_code ? (
          <div className="mt-4 mb-4">
            <GrayFullCard
              topIcon="pmf-icon-map-pin"
              title="Postcode:"
              value={acceptJobData?.student_post_code}
              info={
                acceptJobData?.distance
                  ? "(" + acceptJobData?.distance?.toFixed(1) + "mi away)"
                  : undefined
              }
            />
          </div>
        ) : (
          <></>
        )}

        <div className={`grid-cols-1 grid gap-4`}>
          {acceptJobData?.student_portal_availability &&
          acceptJobData?.student_portal_availability.length > 0 ? (
            <AvailabilityCard
              availability={acceptJobData?.student_portal_availability}
              availabilityMatch={availMatch}
              loading={loading}
            />
          ) : (
            <>
              {acceptJobData?.student_availability ? (
                <AvailabilityCard
                  availability={mapTextAvailability() ?? []}
                  availabilityMatch={availMatch}
                  loading={loading}
                />
              ) : (
                <></>
              )}
            </>
          )}

          {!acceptJobData?.student_availability &&
            (!acceptJobData?.student_portal_availability ||
              acceptJobData?.student_portal_availability.length < 1) && (
              <div className="mt-3">
                {loading ? (
                  <Skeleton height={50} className="w-full" animation="wave" />
                ) : (
                  <AvailabilityCard
                    customDesc={
                      <span className="text-sm font-bold">
                        <i className="pmf-icon-info"></i> Learner has not shared
                        their availability yet{" "}
                      </span>
                    }
                    availability={[]}
                    availabilityMatch={undefined}
                    loading={loading}
                  />
                )}
              </div>
            )}

          <div className="flex flex-col">
            {acceptJobData?.course_intensity ? (
              <GrayFullCard
                topIcon="pmf-icon-intensity"
                title="Preferred Course Intensity:"
                value={acceptJobData?.course_intensity}
                desc={getCourseIntensityDesc()}
              />
            ) : null}
          </div>

          {acceptJobData?.has_bid == 1 &&
            acceptJobData?.job_bid_status_name && (
              <JobBidCard
                title="Job bid"
                value={acceptJobData?.job_bid_status_name}
                showActionMenu={
                  !!acceptJobData?.can_edit_bid ||
                  !!acceptJobData?.can_delete_bid
                }
                options={[
                  {
                    Icon: PencilSquareIcon,
                    title: "Edit bid",
                    show: !!acceptJobData?.can_edit_bid,
                    onClick: () => setShowRegisterInterest(true),
                  },
                  {
                    Icon: TrashIcon,
                    title: "Delete bid",
                    show: !!acceptJobData?.can_delete_bid,
                    onClick: () => setShowWithdrawDialog(true),
                  },
                ]}
              />
            )}
        </div>

        {acceptJobData?.has_bid == 1 && (
          <div className="mt-3">
            {loading ? (
              <Skeleton height={20} className="w-full" animation="wave" />
            ) : (
              <Alert
                colour="white"
                icon="pmf-icon-info"
                customDesc={
                  <span className="text-sm leading-none">
                    {" "}
                    You have registered your interest in this job{" "}
                  </span>
                }
              />
            )}
          </div>
        )}

        <div
          className={`${
            !acceptJobData?.has_bid && acceptJobData?.adi_has_badge === 1
              ? "grid-cols-2"
              : "grid-cols-1"
          } my-3 grid gap-2 mt-4 inset-x-0 px-4 py-6 md:p-0 bg-gray-100 md:bg-transparent fixed md:relative bottom-20 z-50 md:bottom-0`}
        >
          {loading ? (
            <>
              <Skeleton
                className="!rounded-full"
                height={35}
                animation="wave"
                variant="rounded"
              ></Skeleton>
              <Skeleton
                className="!rounded-full"
                height={35}
                animation="wave"
                variant="rounded"
              ></Skeleton>
            </>
          ) : (
            <>
              <Button
                onClick={() =>
                  canAcceptJob
                    ? acceptJobData?.completed_required_data == 1
                      ? checkClash()
                      : openQuestionnaire()
                    : setShowCantAccept(true)
                }
                disabled={
                  submitting ||
                  (error !== null &&
                    acceptJobData?.completed_required_data == 1)
                }
                colour="yellow"
                size="large"
              >
                {acceptJobData?.adi_has_badge === 1
                  ? "Accept Job"
                  : "Upload Badge"}
              </Button>
              {!acceptJobData?.has_bid && acceptJobData?.adi_has_badge === 1 ? (
                <Button
                  onClick={() => {
                    if (canAcceptJob) {
                      if (
                        !acceptJobData?.can_add_location_bid &&
                        !acceptJobData?.can_add_availability_bid
                      ) {
                        registerInterest();
                        setShowRegisterInterest(true);
                      } else {
                        setShowBidOptionsModal(true);
                      }
                    } else {
                      setShowCantAccept(true);
                    }
                  }}
                  disabled={
                    submitting ||
                    (error !== null &&
                      acceptJobData?.completed_required_data == 1)
                  }
                  colour="outline"
                  size="large"
                >
                  Register Interest
                </Button>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
        {loading ? (
          <Skeleton height={20} width={100} className="w-full mx-auto" />
        ) : null}

        {(error === null || canAcceptJob) && (
          <div className="mt-5">
            {loading ? (
              <Skeleton height={50} className="w-full" animation="wave" />
            ) : (
              <Alert
                colour="white"
                icon="pmf-icon-info"
                customDesc={
                  <span className="text-[10px] text-darkBlue">
                    By accepting this job you agree to complete the tuition at
                    the stated rate and in line with{" "}
                    <strong>
                      <a
                        href="https://www.passmefast.co.uk/adi-terms"
                        rel="noreferrer"
                        target="_blank"
                      >
                        PassMeFast&apos;s Terms and Conditions.
                      </a>
                    </strong>
                  </span>
                }
              />
            )}
          </div>
        )}

        <NewJobModalParent
          show={
            showRegisterInterest ||
            showRegisterLocationInterest ||
            showWithdrawDialog ||
            showClashDialog ||
            showRegisterAvailabilityInterest
          }
          onClose={() => onHide(false)}
          onUpdate={() => onHide(true)}
          showRegisterInterest={showRegisterInterest}
          showWithdrawDialog={showWithdrawDialog}
          showClashDialog={showClashDialog}
          showRegisterLocationInterest={showRegisterLocationInterest}
          showRegisterAvailabilityInterest={showRegisterAvailabilityInterest}
          title={getModalTitle()}
          clashedData={clashedData}
          acceptJobData={acceptJobData}
          acceptTheJob={() => setShowTermsAndConditionDialog(true)}
          cancelClash={cancelClash}
          isSubmitting={submitting}
        />

        <CourseProviderTermsAndConditionDialog
          show={showTermsAndConditionDialog}
          onClose={() => {
            setShowTermsAndConditionDialog(false);
            cancel();
          }}
          submit={() => acceptTheJob()}
          acceptJobData={acceptJobData}
        />
        <CantAcceptJobDialog
          show={showCantAccept}
          onClose={() => {
            setShowCantAccept(false);
            cancel();
          }}
        />

        <CompleteQuestionnaireDataModal
          show={showQuestionnaire}
          onClose={() => {
            setShowQuestionnaire(false);
            getJobData();
          }}
          jobId={jobId}
        />

        <BidTypesModal
          show={showBidOptionsModal}
          hide={() => {
            setShowBidOptionsModal(false);
          }}
          setShowRegisterInterest={setShowRegisterInterest}
          setShowRegisterLocationInterest={setShowRegisterLocationInterest}
          setShowRegisterAvailabilityInterest={
            setShowRegisterAvailabilityInterest
          }
          setShowCantAccept={setShowCantAccept}
          registerInterest={registerInterest}
          canAcceptJob={canAcceptJob}
          acceptJobData={acceptJobData}
        />
      </div>
    </>
  );
};

export { NewJobsDetails };
