import React, { Fragment, useEffect, useState, useRef } from "react";
import Button from "../elements/Button";
import { Dialog, Transition } from "@headlessui/react";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { ArrowsUpDownIcon, ArrowLeftIcon } from "@heroicons/react/20/solid";

export type ModalProps = {
  open?: boolean;
  title?: string;
  className?: string;
  titleIcon?: string;
  icon?: "sort" | "money" | null;
  description?: string;
  onSubmit?: () => void;
  onClose?: () => void;
  onDismiss?: () => void;
  submitText?: string;
  closeText?: string;
  children?: React.ReactNode;
  hideClose?: boolean;
  hideSubmit?: boolean;
  disabled?: boolean;
  disableSubmit?: boolean;
  showActionLink?: boolean;
  actionLinkText?: string;
  actionLinkColor?: "dangerLink" | "link";
  onActionLink?: () => void;
  setMinHeight?: boolean;
  reverseButtons?: boolean;
  showBackButton?: boolean;
};

export default function Modal({
  open = false,
  title = "Modal Title",
  className = "",
  titleIcon,
  icon,
  description,
  onClose = () => {},
  onSubmit = () => {},
  onDismiss,
  submitText = "Submit",
  closeText = "Close",
  children,
  hideClose = false,
  hideSubmit = false,
  disabled = false,
  disableSubmit = false,
  showActionLink = false,
  actionLinkText,
  actionLinkColor = "link",
  onActionLink,
  setMinHeight = false,
  reverseButtons = false,
  showBackButton = false,
}: ModalProps) {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const initialFocusRef = useRef(null);

  useEffect(() => {
    if (open) {
      window.addEventListener("resize", (event) =>
        setWindowSize(window.innerWidth)
      );
      document.body.style.overflow = "hidden";
    } else {
      window.removeEventListener("resize", (event) => {});
      document.body.style.overflow = "auto";
    }
  }, [open]);

  const Header: React.ReactNode = (
    <h3 className="text-2xl font-semibold font-titlef pt-3 pb-2 px-1 text-darkBlue flex">
      {showBackButton && (
        <div onClick={onClose} className="flex items-center">
          <ArrowLeftIcon className="w-5 h-5 me-[10px]" />
        </div>
      )}
      <div className="flex items-center min-w-0 gap-x-1">
        {icon === "sort" && (
          <ArrowsUpDownIcon className=" h-7 w-7 -ms-2 mt-1" />
        )}
        {icon === "money" && (
          <i className={`pmf-icon-currency-pound text-[28px] -ms-2`}></i>
        )}
        {titleIcon && (
          <img
            className="flex-none rounded-full h-7 w-7"
            src={titleIcon}
            alt=""
          />
        )}

        {title}
      </div>
    </h3>
  );

  const Footer: React.ReactNode = (
    <>
      {(!hideSubmit || !hideClose) && (
        <div className="py-4 bg-white">
          <div
            className={`${
              reverseButtons ? "flex-row-reverse" : "flex-row"
            } flex items-center justify-between gap-2`}
          >
            {!hideSubmit && (
              <Button
                colour="yellow"
                type="submit"
                className={hideClose ? "w-full" : "w-1/2"}
                onClick={onSubmit}
                size="large"
                disabled={disabled || disableSubmit}
              >
                {submitText}
              </Button>
            )}
            {!hideClose && (
              <Button
                colour="outline"
                type="button"
                className={hideSubmit ? "w-full" : "w-1/2"}
                onClick={onClose}
                size="large"
                disabled={disabled}
              >
                {closeText}
              </Button>
            )}
          </div>

          {showActionLink && (
            <Button
              colour={actionLinkColor}
              type="button"
              className="w-full mt-2"
              onClick={onActionLink}
              size="small"
              disabled={disabled}
            >
              {actionLinkText}
            </Button>
          )}
        </div>
      )}
    </>
  );

  return (
    <>
      <div className="md:none block">
        <BottomSheet
          open={windowSize < 768 && open}
          onDismiss={onDismiss ?? onClose}
          header={Header}
          footer={Footer}
          data-body-scroll-lock-ignore={true}
          scrollLocking={false}
          blocking={true}
          initialFocusRef={initialFocusRef}
          className={setMinHeight ? "min-height-16" : ""}
        >
          <div className={`${className} w-full px-5 text-left align-middle`}>
            <div ref={initialFocusRef}>
              {description ? (
                <p className="text-sm mb-2">{description}</p>
              ) : (
                <></>
              )}
            </div>
            {children}
          </div>
        </BottomSheet>
      </div>
      <div className="md:block none">
        <Transition appear show={windowSize >= 768 && open} as={Fragment}>
          <Dialog as="div" className="relative z-[100]" onClose={() => {}}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="w-full fixed top-0 right-0 left-0 bottom-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed  top-0 right-0 left-0 bottom-0">
              <div className="flex min-h-full items-end sm:items-center justify-center text-center">
                <Transition.Child
                  as={Fragment}
                  enter="transition-all ease-in-out duration-500"
                  enterFrom="opacity-0 translate-y-[10%]"
                  enterTo="opacity-100"
                  leave="transition-all ease-in-out duration-500"
                  leaveFrom="opacity-100 "
                  leaveTo="opacity-0 translate-y-[10%]"
                >
                  <Dialog.Panel
                    className={`${className} w-full max-w-md transform overflow-y-auto rounded-t-2xl rounded-b-none sm:rounded-b-2xl bg-white px-5 !pt-0 !pb-0 text-left align-middle shadow-xl transition-all max-h-[88vh]`}
                  >
                    <div className="pt-1 -mx-1 bg-white sticky top-0 z-[11]">
                      {Header}
                    </div>

                    <div>
                      <p className="text-sm mb-2">{description}</p>
                    </div>

                    {children}

                    {(!hideClose || !hideSubmit) && (
                      <div className="sticky bottom-0 z-[11]">{Footer}</div>
                    )}
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </div>
    </>
  );
}
