
import { FC } from "react";
import * as auth from '../../../pages/auth/redux/AuthRedux'
import Button from "../../shared/elements/Button";
import { signOut } from "../../../pages/auth/redux/AuthCRUD";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const SignOutButton: FC = () => {

const history = useHistory();
const dispatch = useDispatch()
const logout = () => {
  signOut()
  dispatch(auth.actions.logout())
  sessionStorage.clear()
  history.push('/');
}

  return (
    <>
      <div className="d-flex flex-wrap px-5 pb-6 p-lg-0">
       {/* begin::Sign out */}
       <Button
          colour="outline"
          type="submit"
          fullWidth
          onClick={logout}
          size="large"
        >
          Sign out
        </Button>
        {/* end::Sign out */}

      </div>
      

    </>
  );
};

export { SignOutButton };
