import { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  Cell,
  ResponsiveContainer,
  ReferenceLine,
  Label,
} from "recharts";

interface IProps {
  chartGraphData: any;
  hitNumber: any;
  loading: boolean;
}

const getPath = (x: number, y: number, width: number, height: number) => {
  const radius = 0; // Adjust the radius as needed
  return `M${x},${y + height}
          L${x},${y + radius}
          Q${x},${y} ${x + radius},${y}
          L${x + width - radius},${y}
          Q${x + width},${y} ${x + width},${y + radius}
          L${x + width},${y + height}
          Z`;
};

const RoundedBar = (props: any) => {
  const { fill, x, y, width, height } = props;
  const barWidth = 11; // Set the desired width of the bars

  // Calculate the x-coordinate to center the bar within the category
  const centerX = x - (barWidth - width) / 2;

  return (
    <path d={getPath(centerX, y, barWidth, height)} stroke="none" fill={fill} />
  );
};

const DetailsBarChart: FC<IProps> = (props) => {
  const [minValue, setMinValue] = useState(0);
  const [average, setAverage] = useState(0);
  const [maxValue, setMaxValue] = useState(0);

  useEffect(() => {
    if (props.chartGraphData.length > 0) {
      // Find the minimum value
      const values = props.chartGraphData.map((item: any) => item.value);
      const min = Math.min(...values);
      setMinValue(min);

      // Calculate the average
      const sum = values.reduce((acc: number, val: number) => acc + val, 0);
      const avg = sum / values.length;
      setAverage(avg);

      // Calculate the maximum value with a buffer higher than hitNumber
      const buffer = 10; // Adjust this buffer as needed
      const maxValueWithBuffer = Math.max(...values, props.hitNumber) + buffer;
      const maxValueFromData = Math.max(...values);
      setMaxValue(
        maxValueWithBuffer > maxValueFromData
          ? maxValueWithBuffer
          : maxValueFromData
      );
    }
  }, [props.chartGraphData, props.hitNumber]);

  return (
    <>
      <div className="relative [&>*]:!font-normalf [&>*]:text-sm">
        {/* <div className="absolute">
          <span className="bg-darkBlue rounded-full py-1 px-2 text-white">
            {props.hitNumber}
          </span>
        </div> */}

        {/* */}
        <ResponsiveContainer width="100%" height={320}>
          <BarChart
            data={props.chartGraphData}
            margin={{
              top: 20,
              right: 0,
              left: 0,
              bottom: 5,
            }}
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#163A5E" stopOpacity={1} />
                <stop offset="200%" stopColor="#8987AC" stopOpacity={1} />
              </linearGradient>
            </defs>

            <CartesianGrid
              horizontal={true}
              vertical={true}
              stroke="#EFEFEF"
            />
            <XAxis dataKey="month" axisLine={false} tickLine={false} />
            <YAxis
              type="number"
              axisLine={false}
              tickLine={false}
              tick={false} // Hide the tick marks
              domain={[minValue, maxValue]} // Set the calculated domain
              width={35} // Adjust the width as needed
            />
            <Bar dataKey="value" fill="url(#colorUv)" shape={<RoundedBar />} />
            {/* Add reference line for the average */}
            <ReferenceLine
              y={average >= 0 ? average : 0}
              stroke="#2BCD5A"
              strokeWidth={1.5}
              strokeDasharray="4 4"
            />
            {/* Add reference line for hitNumber */}
            <defs>
              <filter id="rounded-corners" x="-5%" width="110%" y="0%" height="100%">
              <feFlood flood-color="#163A5E"/>
              <feGaussianBlur stdDeviation="2"/>
              <feComponentTransfer>
                <feFuncA type="table"tableValues="0 0 0 1"/>
              </feComponentTransfer>
              <feComponentTransfer>
                <feFuncA type="table"tableValues="0 1 1 1 1 1 1 1"/>
              </feComponentTransfer>
                <feComposite operator="over" in="SourceGraphic"/>
              </filter>
            </defs>
            <ReferenceLine y={props.hitNumber} stroke="#163A5E" strokeWidth={1.5} strokeDasharray="4 4"
             label={{
              value: props.hitNumber,
              position: "insideBottomLeft",
              fill: "#fff",
              filter: "url(#rounded-corners)",
              dx: -30, // Adjust as needed to move the label outside the CartesianGrid
              dy: 10, // Adjust as needed to position the label
            }}
            >

            </ReferenceLine>
            {/* <ReferenceLine
              y={props.hitNumber}
              stroke="#163A5E"
              strokeWidth={1.5}
              strokeDasharray="4 4"
            /> */}
          </BarChart>
        </ResponsiveContainer>
        {/* */}
      </div>
    </>
  );
};

export { DetailsBarChart };
