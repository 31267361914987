import { FC, useState, useEffect } from 'react'
import Modal from '../../shared/overlays/Modal'
import TestCard from '../../shared/cards/TestCard'
import { mapStatus, mapSubTitle } from '../../../mappers/testMapper'
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import { PrivateStudentTest } from '../../students/privateStudentTest'
import { DeletePrivateStudentTestModal } from '../../test/DeletePrivateStudentTestModal'
import { TestStatusEnum } from '../../../../core/models/enums/test-status-enum'
interface IProps {
    show: any
    onClose: any
    event: any
    testsUpdated: ()=>void,
}

const TestDetailsModal: FC<IProps> = (props) => {
    const { show, onClose, event, testsUpdated } = props;
    const [currentTest, setCurrentTest] = useState<any>()
    const [showUpdateTestModal, setShowUpdateTestModal] = useState<boolean>()
    const [showDeleteTestModal, setShowDeleteTestModal] = useState<boolean>()
    const mapData = (event: any) => {
        const data: any = [
            {
                icon: 'pmf-icon-test',
                value: event?.test_location ?? '',
            },
            {
                icon: 'pmf-icon-clock',
                value: event?.booked_for_human,
            },
        ]

        if (event?.deal_id) {
            data.push({
                icon: 'pmf-icon-suitcase',
                value: `#${event?.deal_id?.toString()}`,
                badge: true
            })
        }

        return data
    }
    const handleClose = ()=>{
        setShowUpdateTestModal(false)
      }
    return (
        <>
        <Modal
            open={show}
            onClose={onClose}
            title={'Test Details'}
            hideClose={false}
            hideSubmit={true}>
            <TestCard
                name={event?.student_name}
                status={(event?.is_private_student===1 && event?.status=== TestStatusEnum.Confirmed) ? undefined : mapStatus(event?.status)}
                data={mapData(event)}
                pmfIcon={event?.created_by_type === 'passmefast'}
                subTitle={mapSubTitle(event?.status)}
                showAction={event?.is_private_student===1}
                    actions={[{
                      Icon: PencilSquareIcon, title: 'Edit test',
                      show:  event?.is_private_student===1,
                      onClick: () => {
                        setCurrentTest(event)
                        setShowUpdateTestModal(true)
                      }
                    },
                    {
                      Icon: TrashIcon,
                      title: 'Delete test',
                      show:  event?.is_private_student===1,
                      onClick: () => {
                        setCurrentTest(event)
                        setShowDeleteTestModal(true)
                      }
                    }].filter(s=> s.show)}
            >
            </TestCard>

        </Modal>
        <PrivateStudentTest
            show={showUpdateTestModal}
            onHide={handleClose}
            testDetails={currentTest}   
            refreshParent={()=>{
                testsUpdated()
              }}              
            />
            <DeletePrivateStudentTestModal
            jobId={currentTest?.job_id}
            testLocation={currentTest?.location?.name}
            testDate={currentTest?.booked_for_human}
            open={showDeleteTestModal!}
            testId={currentTest?.id}
            onClose={()=>{
              testsUpdated()
              setShowDeleteTestModal(false)
            }}
            />
        </>
    )
}

export { TestDetailsModal }
