import { FC, useEffect, useState } from 'react'
import Modal from '../../../shared/overlays/Modal';

import {NewPaymentForm}  from '../NewPaymentForm';

interface IProps {
  show: any
  onClose: any
}

const NewPaymentModal: FC<IProps> = (props) => {
 

  function closeModal() {
    props.onClose()
  }

  return (
    <Modal
      open={props.show}
      title="New Payment"
      icon={"money"}
      submitText='Confirm'
      closeText='Cancel'
      onClose={closeModal}
      hideSubmit={true}
      hideClose={true}
    >
     <NewPaymentForm  show={props.show}  onClose={closeModal}  onSubmit={closeModal}/>
    </Modal>
  )
}

export { NewPaymentModal }
