import { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { DocumentDataModel } from '../../../../core/models/document-data-model'
import { getAdiDocuments } from '../../../../core/services/adi-service'
import { toAbsoluteUrl } from '../../../helpers'
import * as auth from '../../../pages/auth/redux/AuthRedux'
import { AddDocumentDialog } from './AddDocumentDialog'
import Button from '../../shared/elements/Button'
import { PlusSmallIcon } from '@heroicons/react/20/solid'
import PageHeader from '../../shared/elements/PageHeader'
import { DocumentCard } from '../../shared/cards/DocumentCard'
import EmptyState from '../../shared/elements/EmptyState'
import { DocumentCheckIcon } from '@heroicons/react/24/outline'
import Alert from '../../shared/overlays/Alert'
// import { AddDocumentDialog } from './AddDocumentDialog'

const DocumentList: FC = () => {

  const [documents, setDocuments] = useState<Array<DocumentDataModel>>([])
  const [loading, setLoading] = useState(false)
  const [hasExpiredDoc, setHasExpiredDoc] = useState(false)
  const dispatch = useDispatch()
  const [show, setShow] = useState(false)
  const [onCreatedNewDocument, setOnCreatedNewDocument] = useState<any>(false)

  const handleShow = () => {
    setShow(true)
  }
  const handleClose = () => {
    setShow(false)
  }
  const handleOnCreatedNewDocument = () => {
    setOnCreatedNewDocument(!onCreatedNewDocument)
  }

  useEffect(() => {
    getDocuments()
  }, [onCreatedNewDocument]);

  function getDocuments() {
    setLoading(true)
    getAdiDocuments()
      .then((res) => {
        setDocuments(res.data.results)
        setLoading(false)
      }).catch((res) => {
        setLoading(false)
      })
  }
  useEffect(() => {
    checkExpiredDocuments();
  }, [documents]);
  function checkExpiredDocuments() {
    let badgesCount = documents.filter(document => document.badges_id && document.badges_id.length > 0).length
    let hasExpiredBadge = badgesCount != 0 && documents.filter(document => document.badges_id && document.badges_id.length > 0
      && document.expiry_date && new Date(document.expiry_date) < new Date()).length ==
      badgesCount
    if (hasExpiredBadge) {
      setHasExpiredDoc(true)
      return
    }
    let insuranceCount = documents.filter(document => document.car_id && document.car_id.length > 0).length
    let hasExpiredInsurance = insuranceCount != 0 && documents.filter(document => document.car_id && document.car_id.length > 0
      && document.insurance_expiry_date && new Date(document.insurance_expiry_date) < new Date()).length == insuranceCount
    if (hasExpiredInsurance) {
      setHasExpiredDoc(true)
      return
    }
    updatePersonalData()
    setHasExpiredDoc(false)
  }
  function updatePersonalData() {
    dispatch(auth.actions.requestPersonalData())
  }
  // var documentsItems = documents?.map(function (document, index) {


  //   return (
  //     <div key={index}>
  //       {document.car_id && document.car_id.length > 0 &&
  //         <div key={document.id + '1'} className='col-12 px-0 mb-4'>
  //           {/* */}
  //           <div key={document.id + '2'} className='card h-100'>

  //             <div key={document.id + '3'} className='card-body flex justify-start text-center flex-col p-0'>
  //               {document.document_url && document.document_url.length > 0 && document.document_url.match(/.(jpg|jpeg|png|pjpeg)$/i) ?
  //                 <div key={document.id + '4'} className='symbol symbol-200px mb-2 p-6 pb-0'>
  //                   <img key={document.id + '5'}
  //                     className='h-auto w-100'
  //                     src={document.full_url}
  //                     alt=''
  //                   />
  //                 </div> :
  //                 document.document_url && document.document_url.length > 0 && document.document_url.match(/.(pdf)$/i) ?
  //                   <div key={document.id + '8'} className='bg-g-150 rounded mb-0 m-5 mt-4 px-10 py-8'>
  //                     <img key={document.id + '9'}
  //                       className='h-auto w-50px'
  //                       src={toAbsoluteUrl('/media/svg/pdf.svg')}
  //                       alt=''
  //                     />

  //                   </div> :
  //                   <div key={document.id + '6'} className='bg-g-150 rounded m-5 mt-4 mb-0 px-10 py-8'>
  //                     <img key={document.id + '7'}
  //                       className='h-auto w-30px'
  //                       src={toAbsoluteUrl('/media/svg/no-uploded-doc.svg')}
  //                       alt=''
  //                     />
  //                   </div>
  //               }

  //               <ul key={document.id + '8'} className='list-group list-group-flush w-100 p-6 p-lg-3 pt-0'>
  //                 <li className='list-group-item'>
  //                   <div className='flex justify-between'>
  //                     <span className='fs-6 fw-black'>Document type:</span>
  //                     <span className=' fw-bolder fs-6'>Insurance</span>
  //                   </div>
  //                 </li>
  //                 {document.insurance_provider && document.insurance_provider.length > 0 &&
  //                   <li className='list-group-item'>
  //                     <div className='flex justify-between'>
  //                       <span className='fs-6 fw-black text-nowrap'>Insurance provider:</span>
  //                       <span className=' fw-bolder fs-6 text-break'>{document.insurance_provider}</span>
  //                     </div>
  //                   </li>
  //                 }
  //                 {document.insurance_expiry_date && document.insurance_expiry_date.length > 0 &&
  //                   <li key={document.id + '10'} className='list-group-item'>
  //                     <div className='flex justify-between'>
  //                       <span className='fs-6 fw-black'>Expiry date:</span>
  //                       <span className={new Date(document.insurance_expiry_date) < new Date() ? 'text-danger fw-bolder fs-6' : 'fw-bolder fs-6'}>{document.insurance_expiry_date}</span>
  //                     </div>
  //                   </li>
  //                 }
  //                 {document.car_make && document.car_make.length > 0 &&
  //                   <li key={document.id + '11'} className='list-group-item'>
  //                     <div key={document.id + '12'} className='flex justify-between'>
  //                       <span key={document.id + '132'} className='fs-6 fw-black'>Car:</span>
  //                       <span key={document.id + '99'} className='brand fw-bolder fs-6'>{document.car_make + ' ' + document.car_model}</span>
  //                     </div>
  //                   </li>
  //                 }
  //                 {document.rejection_reason_name &&
  //                   <li key={document.id + '10'} className='list-group-item'>
  //                     <div className='flex justify-between'>
  //                       <span className='fs-6 fw-black'>Badge rejected:</span>
  //                       <span className='text-danger fw-bolder fs-6' >{document.rejection_reason_name}</span>
  //                     </div>
  //                   </li>}
  //               </ul>
  //             </div>


  //           </div>

  //           {/* */}
  //         </div>
  //       }
  //       {document.badges_id && document.badges_id.length > 0 &&
  //         <div key={document.id + '9'} className='col-12 px-0 mb-4'>
  //           {/* */}
  //           <div key={document.id + '13'} className='card h-100'>

  //             <div key={document.id + '65'} className='card-body flex justify-start text-center flex-col p-1'>
  //               {document.document_url && document.document_url.length > 0 && document.document_url.match(/.(jpg|jpeg|png|pjpeg)$/i) ?
  //                 <div key={document.id + '86'} className='symbol symbol-200px mb-2 p-6 pb-0'>
  //                   <img key={document.id + '962'}
  //                     className='h-auto w-100'
  //                     src={document.full_url}
  //                     alt=''
  //                   />
  //                 </div> :

  //                 document.document_url && document.document_url.length > 0 && document.document_url.match(/.(pdf)$/i) ?
  //                   <div key={document.id + '8'} className='bg-g-150 rounded m-5 mt-4 mb-0 px-10 py-8'>
  //                     <img key={document.id + '9'}
  //                       className='h-auto w-50px'
  //                       src={toAbsoluteUrl('/media/svg/pdf.svg')}
  //                       alt=''
  //                     />

  //                   </div> :
  //                   <div
  //                     key={document.id + '32'}
  //                     className='bg-g-150 rounded m-5 mt-4 mb-0 px-10 py-8'>
  //                     <img
  //                       key={document.id + '233'}
  //                       className='h-auto w-30px'
  //                       src={toAbsoluteUrl('/media/svg/no-uploded-doc.svg')}
  //                       alt=''
  //                     />
  //                   </div>
  //               }

  //               <ul key={document.id + '233'} className='list-group list-group-flush w-100 p-6 p-lg-3 pt-0'>
  //                 <li className='list-group-item'>
  //                   <div className='flex justify-between'>
  //                     <span className='fs-6 fw-black'>Document type:</span>
  //                     <span className=' fw-bolder fs-6'>Badge</span>
  //                   </div>
  //                 </li>
  //                 {document.badge_name && document.badge_name.length > 0 &&
  //                   <li key={document.id + '332'} className='list-group-item'>
  //                     <div key={document.id + '22'} className='flex justify-between'>
  //                       <span key={document.id + '212'} className='fs-6 fw-black'>Name on badge:</span>
  //                       <span key={document.id + '772'} className=' fw-bolder fs-6'>{document.badge_name}</span>
  //                     </div>
  //                   </li>
  //                 }
  //                 {document.adi_number && document.adi_number.length > 0 &&

  //                   <li key={document.id + '212'} className='list-group-item'>
  //                     <div key={document.id + '2f'} className='flex justify-between'>
  //                       <span key={document.id + 'f2'} className='fs-6 fw-black'>ADI number:</span>
  //                       <span key={document.id + 'sz2'} className=' fw-bolder fs-6'>{document.adi_number}</span>
  //                     </div>
  //                   </li>
  //                 }

  //                 {document.expiry_date && document.expiry_date.length > 0 &&
  //                   <li key={document.id + '2dss'} className='list-group-item'>
  //                     <div key={document.id + 'dsc2'} className='flex justify-between'>
  //                       <span key={document.id + '2dsd'} className='fs-6 fw-black'>Expiry date:</span>
  //                       <span key={document.id + 'sddd2'} className={new Date(document.expiry_date) < new Date() ? 'text-danger fw-bolder fs-6' : 'fw-bolder fs-6'}>{document.expiry_date}</span>
  //                     </div>
  //                   </li>
  //                 }
  //                 {document.rejection_reason_name &&
  //                   <li key={document.id + '10'} className='list-group-item'>
  //                     <div className='flex justify-between'>
  //                       <span className='fs-6 fw-black'>Badge rejected:</span>
  //                       <span className='text-danger fw-bolder fs-6' >{document.rejection_reason_name}</span>
  //                     </div>
  //                   </li>}
  //               </ul>
  //             </div>


  //           </div>
  //           {/* */}
  //         </div>
  //       }
  //       {((!document.car_id || document.car_id.length == 0) && (!document.badges_id || document.badges_id.length == 0)) &&
  //         <div key={document.id + '1'} className='col-12 px-0 mb-4'>
  //           {/* */}
  //           <div key={document.id + '2'} className='card h-100'>

  //             <div key={document.id + '3'} className='card-body flex justify-start text-center flex-col p-0'>
  //               {document.document_url && document.document_url.length > 0 && document.document_url.match(/.(jpg|jpeg|png|pjpeg)$/i) ?
  //                 <div key={document.id + '4'} className='symbol symbol-200px mb-2 p-6 pb-0'>
  //                   <img key={document.id + '5'}
  //                     className='h-auto w-100'
  //                     src={document.full_url}
  //                     alt=''
  //                   />
  //                 </div> :
  //                 document.document_url && document.document_url.length > 0 && document.document_url.match(/.(pdf)$/i) ?
  //                   <div key={document.id + '8'} className='bg-g-150 rounded mb-0 m-5 mt-4 px-10 py-8'>
  //                     <img key={document.id + '9'}
  //                       className='h-auto w-50px'
  //                       src={toAbsoluteUrl('/media/svg/pdf.svg')}
  //                       alt=''
  //                     />

  //                   </div> :
  //                   <div key={document.id + '6'} className='bg-g-150 rounded m-5 mt-4 mb-0 px-10 py-8'>
  //                     <img key={document.id + '7'}
  //                       className='h-auto w-30px'
  //                       src={toAbsoluteUrl('/media/svg/no-uploded-doc.svg')}
  //                       alt=''
  //                     />
  //                   </div>
  //               }
  //               <ul key={document.id + '233'} className='list-group list-group-flush w-100 p-6 p-lg-3 pt-0'>
  //                 <li className='list-group-item'>
  //                   <div className='flex justify-between'>
  //                     <span className='fs-6 fw-black'>Document type:</span>
  //                     <span className=' fw-bolder fs-6'>other</span>
  //                   </div>
  //                 </li>
  //                 {document.rejection_reason_name &&
  //                   <li key={document.id + '10'} className='list-group-item'>
  //                     <div className='flex justify-between'>
  //                       <span className='fs-6 fw-black'>Badge rejected:</span>
  //                       <span className='text-danger fw-bolder fs-6' >{document.rejection_reason_name}</span>
  //                     </div>
  //                   </li>}
  //               </ul>
  //             </div>


  //           </div>
  //           {/* */}
  //         </div>
  //       }
  //     </div>
  //   )
  // })

  const documentType = (item: DocumentDataModel) => {
    if (item.car_id && item.car_id.length > 0)
      return "Insurance"
    if (item.badges_id && item.badges_id.length > 0)
      return "Badge"
    return "Other"
  }
  const fileType = (document_url?: string) => {
    if (document_url) {
      if (document_url.match(/.(jpg|jpeg|png|pjpeg)$/i))
        return "image"
      if (document_url.match(/.(pdf)$/i))
        return "pdf"
      return "doc"
    }

  }
  return (
    <>


        {/* {loading &&
          <div className='row'>
            <div className='flex flex-col items-center justify-center text-center px-20 pt-8'>
              <i className="fas fs-1 fa-spinner fa-spin"></i>
            </div>
          </div>} */}
        <div className='mb-5'>
          <PageHeader title='Documents' backToURL='/ADI/settings/overview' />
        </div>
        <div className='px-5'>
        {(documents?.length === 0 && !loading) &&
          <div className='-mb-6'>
            <EmptyState
              Icon={DocumentCheckIcon}
              title="Please upload your documents"
            />
          </div>
          }
          {/* ----- start:: Add Document Modal ----- */}
          <Button
            colour="yellow"
            type="button"
            className='my-6'
            fullWidth
            onClick={handleShow}
          >
            <span className='flex items-center py-1'><PlusSmallIcon className='w-5 h-5' /> New Documents</span>
          </Button>
          {/* ----- end:: Add Document Modal ----- */}

          {/* ----- start:: Document Modal ----- */}
          <AddDocumentDialog
            show={show}
            isUploadBadge={false}
            onHide={handleClose}
            onCreatedNewDocument={() => handleOnCreatedNewDocument()}
          />
          {/* ----- end:: Document Modal ----- */}
        {!(documents?.length === 0 && !loading) &&
          <>
            {hasExpiredDoc && <Alert title='You have some expired documents' colour='red'/>}

            <div className='grid sm:grid-cols-2 grid-cols-1 gap-4 sm:pb-0 pb-8'>
            {documents?.map(item =>
            (<DocumentCard loading={loading} url={item.full_url}
              key={item.id}
              documentType={documentType(item)}
              adiNumber={item.adi_number}
              badgeName={item.badge_name}
              insuranceProvider={item.insurance_provider}
              car={item.car_make ? `${item.car_make} ${item.car_model}` : undefined}
              rejectionReason={item.rejection_reason_name}
              fileType={fileType(item.document_url)}
              expiryDate={item.expiry_date ?? item.insurance_expiry_date}
            ></DocumentCard>)
            )}
            </div>
          </>}
          </div>
    </>
  )
}
export { DocumentList }
