export const calculateReadingTime = (
  content: any,
) => {
  let time = 0;
  const avgWPM = 265;
  const words = content
    .map((item: any) => {
      return item.text;
    })
    .join(" ")
    .split(" ").length;
  time = Math.ceil(words / avgWPM);

  return time > 1 ? time : 1;
};
