import React from "react";
import { CheckCircleIcon } from "@heroicons/react/24/outline";

export type NotificationProps = {
  title?: string;
  description?: string;
  colour?: "red" | "orange" | "green" | "blue" | "gray";
  Icon?: React.ComponentType<React.ComponentProps<"svg">> | any;
  loading?: boolean;
};

export default function Notification({
  title,
  description,
  colour = "gray",
  Icon,
  loading,
}: NotificationProps) {
  return (
    <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
      <div className="p-4">
        {!loading ? (
          <div className="flex items-start">
            <div className="flex-shrink-0">
              {Icon ? (
                <Icon
                  className={`h-6 w-6 ${
                    {
                      red: "text-red-400",
                      orange: "text-orange-400",
                      green: "text-green",
                      blue: "text-blue-400",
                      gray: "text-gray-400",
                    }[colour]
                  }`}
                  aria-hidden="true"
                />
              ) : (
                <CheckCircleIcon
                  className={`h-6 w-6 ${
                    {
                      red: "text-red-400",
                      orange: "text-orange-400",
                      green: "text-green-400",
                      blue: "text-blue-400",
                      gray: "text-gray-400",
                    }[colour]
                  }`}
                  aria-hidden="true"
                />
              )}
            </div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
              <p className="text-sm font-medium">
                {title}
              </p>
              {description && (
                <p className="mt-1 text-sm">
                  {description}
                </p>
              )}
            </div>
          </div>
        ) : (
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <svg
                className={`animate-spin h-5 w-5 ${
                  {
                    red: "text-red-400",
                    orange: "text-orange-400",
                    green: "text-green-400",
                    blue: "text-blue-400",
                    gray: " dark:text-gray-200",
                  }[colour]
                }`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
              <p className="text-sm font-medium">
                {title}
              </p>
              {description && (
                <p className="mt-1 text-sm">
                  {description}
                </p>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
