import Badge from "../elements/Badge";
import Button from "../elements/Button";
import { toAbsoluteUrl } from "../../../helpers";
import { capitalizeFirstLetter } from "../../../helpers/textHelper";
import { Skeleton } from "@mui/material";
import InfoList, { InfoListData } from "../data/InfoList";
import { ActionMenu } from "../forms/ActionMenu";

export type TestCardProps = {
  name?: string;
  pmfIcon?: boolean;
  loading?: boolean;
  actions?: any,
  showAction?: boolean,
  subTitle?: {
    text: string,
    color: "yellow" | "red"
  };
  data?: InfoListData[];
  buttons?: {
    text: React.ReactNode;
    onClick: () => void;
    color: "yellow" | "darkBlue" | "outline";
    disabled: boolean
    size: string
  }[];
  status?: {
    title: string;
    color?: "blue" | "green" | "darkGreen" | "yellow" | "red" | "outlineRed" | "gray" | "darkRed";
  };
  children?: React.ReactNode;
};

export default function TestCard({
  name,
  pmfIcon = false,
  subTitle,
  loading = false,
  data,
  buttons,
  status,
  actions,
  showAction=false,
}: TestCardProps) {
  return (
    <div className="h-full">
      <div className="bg-white rounded-lg shadow mb-3 h-full flex flex-col justify-between">
        <div>
        <div className="flex pt-4 px-4 flex-col">
          <div className="min-w-0 flex-1 flex items-center">
          <div className="flex flex-col max-w-[93%]">
            {
              loading ? <Skeleton width={150} animation="wave"></Skeleton> : <p className="text-lg font-bold text-darkBlue truncate">{name}</p>
            }
            </div>
            {pmfIcon && <img src={toAbsoluteUrl('/assets/media/logos/pmf.svg')} className='h-4 ms-2' />}
            <div className="flex flex-1 justify-end">
            {  showAction ?
              (<div className="ml-4 flex flex-shrink-0">
                <ActionMenu options={actions} ></ActionMenu>
              </div>) : <></>
            }
          </div>
          </div>
          {subTitle ? <div className="flex w-full items-center">
            <span className={`w-1.5 h-1.5 me-2 rounded-full ${{
              red: "bg-danger",
              yellow: "bg-yellow"
            }[subTitle.color]
              }`}></span>
            {capitalizeFirstLetter(subTitle.text)}
          </div> : null}
          
        </div>

        <div className={`grid w-full px-4 mt-1 ${status ? 'pb-2' : 'pb-4'}`}>
          {
            <InfoList data={data} loading={loading}></InfoList>
          }
        </div>
        </div>


        {buttons && buttons.length > 0 && (
          <div className="pb-4 flex px-4 space-x-1">
            {buttons.map((button, i) => (
              <Button
                onClick={() => button.onClick()}
                key={i}
                colour={button.color}
                disabled={button.disabled}
                size={button.size}
              >
                {button.text}
              </Button>
            ))}
          </div>
        )}

        {loading ?
          <div className=" bg-gray-100 w-full rounded-ss-none rounded-se-none rounded-md py-0 leading-[20px] flex relative items-center justify-center font-semibold text-[10px] transition-all">
            <Skeleton className="!my-2" width={150} animation="wave"></Skeleton>
          </div>
          : status && (
            <div>
              <Badge roundedT={false} roundedPill={false} size='md' fullWidth colour={status.color} title={capitalizeFirstLetter(status.title)} />
            </div>
          )
        }
      </div>
    </div>
  );
}
