import React from 'react';
import ReactDOM from 'react-dom/client';
import './global.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {PersistGate} from 'redux-persist/integration/react'
import {Provider} from 'react-redux'
import * as _redux from './setup'
 import store, {persistor} from './setup/redux/Store'
// Axios
import axios from 'axios'
_redux.setupAxios(axios, store)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
  {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
  <PersistGate persistor={persistor} loading={<div>Loading...</div>}>
    <App basename={''} />
  </PersistGate>
</Provider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
