import { FC, Fragment, useEffect, useState } from "react";
import { Lesson } from "../../../core/models/lesson";
import {
  checkHasPrevJobs,
  getAdiLessonsData,
  getOtherEventsInCalendar,
  getTestsList,
} from "../../../core/services/job-service";
import { RemoveLessonDialog } from "./_modals/RemoveLessonModal";
import ReactGA from "react-ga4";
import moment from "moment";
import { LessonsListTab } from "./tabs/LessonsListTab";
import { AddEditLessonModel } from "./_modals/AddEditLessonModel";
import { Tab } from "@headlessui/react";
import Button from "../shared/elements/Button";
import {
  ExclamationCircleIcon,
  PlusSmallIcon,
} from "@heroicons/react/24/outline";
import EmptyState from "../shared/elements/EmptyState";
import Toggler from "../shared/forms/Toggler";
import Skeleton from "@mui/material/Skeleton";
import { useLocation } from "react-router-dom";
import React from "react";
import { useSharedData } from "../../pages/providers/SharedDataProvider";
import { MonthView } from "./tabs/calender/MonthView";
import { WeeklyView } from "./tabs/calender/WeeklyView";
import { DailyView } from "./tabs/calender/DailyView";
import { CancelLessonDialog } from "./_modals/CancelLessonModal";
function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const LessonsList: FC = () => {
  let query = useQuery();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [lessons, setLessons] = useState<Array<Lesson>>([]);
  const [calendarLessons, setCalendarLessons] = useState<any>();
  const [isUpcommingOnly, setIsUpcommingOnly] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [currentLesson, setCurrentLesson] = useState<Lesson>(new Lesson());
  const [showAddLessonCard, setShowAddLessonCard] = useState<boolean>(false);
  const [showDeleteLessonCard, setShowDeleteLessonCard] =
    useState<boolean>(false);
  const [showUpdateLessonCard, setShowUpdateLessonCard] =
    useState<boolean>(false);
  const [showCancelLessonCard, setShowCancelLessonCard] =
    useState<boolean>(false);
  const [hasPrevLessons, setHasPrevLessons] = useState<boolean>(false);
  const [tests, setTests] = useState<Array<any>>([]);
  const [events, setEvents] = useState<Array<any>>([]);
  const [highlightDates, setHighlightDates] = useState<Array<any>>([]);

  const [monthEvents, setMonthEvents] = useState<Array<any>>([]);
  const [otherCalendarEvents, setOtherCalendarEvents] = useState<Array<any>>(
    []
  );
  const [selectedDate, setSelectedDate] = useState<any>();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const { data, updateSharedData } = useSharedData();
  const [refreshMonthView, setRefreshMonthView] = useState<boolean>(false);
  const [selectedDayInCalendar, setSelectedDayInCalendar] = useState(
    new Date()
  );
  const [activeView, setActiveView] = useState("monthly");

  const switchView = (view: any) => {
    setActiveView(view);
    updateSharedData("selectedDate", selectedDayInCalendar);
  };

  const filterObject = {
    SearchObject: Object.fromEntries(urlSearchParams.entries()),
    PageNumber: 0,
    PageSize: 10000000,
    SortDirection: "desc",
    SortBy: "created_at",
  };
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  useEffect(() => {
    if (query.get("selected")) {
      setSelectedDate(query.get("selected"));
      setSelectedDayInCalendar(
        moment(query.get("selected"), "DD-MM-YYYY").toDate()
      );
      updateSharedData(
        "selectedDate",
        moment(query.get("selected"), "DD-MM-YYYY").toDate()
      );
    } else {
      setSelectedDate(new Date());
      updateSharedData("selectedDate", new Date());
    }

    ReactGA.event("open_lessons_tab");
  }, []);

  function getLessons(isUpcommingOnly: any) {
    getHasPrevLessons();
    getAdiLessonsData({ is_upcomming_only: isUpcommingOnly }).then((res) => {
      setLessons(res.data.results);
    });
  }

  function getHasPrevLessons() {
    checkHasPrevJobs().then((res) => {
      setHasPrevLessons(res.data.results == 1);
    });
  }

  const updateData = async () => {
    setLoading(true);
    setEvents([]);
    setHighlightDates([]);
    setMonthEvents([]);
    // setEvent(moment(today).format('dddd DD MMMM'))
    filterObject.SearchObject.without_appends = "true";
    filterObject.SearchObject.request_main_object = "true";
    const testsPromise = getTestsList(filterObject).then((res) => {
      setTests(res.results.filter((test: any) => test.accepted));
    });

    const OtherCalendarEventsPromise = getOtherEventsInCalendar({
      is_upcomming_only: false,
      is_calender: true,
    }).then((res) => {
      setOtherCalendarEvents(res.results);
    });

    const lessonsPromise = getLessons(isUpcommingOnly);

    const calendarLessons = getAdiLessonsData(
      {
        is_upcomming_only: false,
        is_calender: true,
      },
      true
    ).then((res) => {
      setCalendarLessons(res.data.results);
    });

    Promise.all([
      testsPromise,
      lessonsPromise,
      calendarLessons,
      OtherCalendarEventsPromise,
    ])
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  function addLesson() {
    setShowAddLessonCard(true);
  }

  useEffect(() => {
    updateData();
  }, []);

  function onIncludePrevChange() {
    if (isUpcommingOnly) {
      setIsUpcommingOnly(0);

      getLessons(false);
    } else {
      setIsUpcommingOnly(1);
      getLessons(true);
    }
  }

  const setOtherEvents = (otherEvents: any, isIntegratedCalendar: boolean) => {
    if (otherEvents.length > 0) {
      let tempEvents: Array<any> = [];
      let tempHighlightDates: Array<any> = [];
      let tempMonthEvents: Array<any> = [];

      otherEvents.forEach((otherEvent: any) => {
        let eventDateFormatted: any = null;
        if (isIntegratedCalendar)
          eventDateFormatted = moment(otherEvent.date, "yyyy-MM-DD");
        else
          eventDateFormatted = moment(
            otherEvent.event_date,
            "yyyy-MM-DD HH:mm:ss"
          );

        const filtered = tempMonthEvents.filter((tempDate) =>
          moment(tempDate.date).isSame(eventDateFormatted, "date")
        );

        const filtered_other_events = events
          .filter((event) => event.type === "other")
          .filter((tempData) =>
            moment(tempData.date).isSame(eventDateFormatted, "date")
          );

        if (filtered.length === 0 && filtered_other_events.length === 0) {
          tempMonthEvents.push({
            date: eventDateFormatted.toDate(),
            type: "other",
          });
        }

        tempEvents.push({
          ...otherEvent,
          date: eventDateFormatted.toDate(),
          type: "other",
          isIntegratedCalendar: isIntegratedCalendar,
          title: isIntegratedCalendar ? otherEvent.name : otherEvent.event_name,
          name: isIntegratedCalendar ? otherEvent.name : otherEvent.event_name,
          duration: isIntegratedCalendar
            ? 1
            : otherEvent?.is_all_day
            ? 24
            : parseFloat(otherEvent.event_duration),
          is_lesson: false,
          is_other: true,
        });
      });
      console.log(...events);
      setEvents([...events, ...tempEvents]);
      setHighlightDates([...highlightDates, ...tempHighlightDates]);
      setMonthEvents([...monthEvents, ...tempMonthEvents]);
    }
  };

  useEffect(() => {
    setOtherEvents(otherCalendarEvents, false);
  }, [otherCalendarEvents]);

  useEffect(() => {
    if (tests.length > 0) {
      let tempEvents: Array<any> = [];
      let tempHighlightDates: Array<any> = [];
      let tempMonthEvents: Array<any> = [];
      tests.forEach((test) => {
        const filtered = tempMonthEvents.filter((tempDate) =>
          moment(tempDate.date).isSame(
            moment(test.booked_for_human, "DD/MM/yyyy hh:mm"),
            "date"
          )
        );

        if (filtered.length === 0) {
          tempMonthEvents.push({
            date: moment(test.booked_for_human, "DD/MM/yyyy hh:mm").toDate(),
            type: "test",
          });
        }

        tempEvents.push({
          ...test,
          date: moment(test.booked_for_human, "DD/MM/yyyy hh:mm").toDate(),
          type: "test",
          title: test.student ? test.student?.full_name : "",
          duration: 1,
          is_lesson: false,
          id: test.id,
          student_name: test.student ? test.student?.full_name : "",
          job_ref: test.deal_id,
          test_location: test.location ? test.location?.name : "",
          date_str: moment(test.booked_for_human, "DD/MM/yyyy hh:mm").format(
            "DD MMM, YYYY hh:mm"
          ),
        });
      });
      setEvents([...events, ...tempEvents]);
      setHighlightDates([...highlightDates, ...tempHighlightDates]);
      setMonthEvents([...monthEvents, ...tempMonthEvents]);
    }
  }, [tests]);

  useEffect(() => {
    let tempEvents: Array<any> = [];
    let tempHighlightDates: Array<any> = [];
    let tempMonthEvents: Array<any> = [];
    if (calendarLessons?.lessons?.length > 0) {
      calendarLessons?.lessons?.forEach((lesson: Lesson) => {
        if (lesson.lesson_date_str != undefined) {
          const filtered = tempMonthEvents.filter(
            (tempDate) =>
              moment(tempDate.date).isSame(
                moment(lesson.lesson_date_str, "DD MMM, yyyy hh:mm"),
                "date"
              ) &&
              ((lesson.is_private_student && tempDate.type === "private") ||
                (!lesson.is_private_student && tempDate.type === "pmf"))
          );

          if (filtered.length === 0) {
            tempMonthEvents.push({
              date: new Date(lesson.lesson_date_str),
              type: lesson.is_private_student ? "private" : "pmf",
            });
          }

          tempEvents.push({
            ...lesson,
            date: new Date(lesson.lesson_date_str),
            type: lesson.is_private_student ? "private" : "pmf",
            title:
              lesson?.student_data?.student_first_name ?? lesson.student_name,
            duration: parseFloat(lesson.duration.toString()),
            is_lesson: true,
          });
        }
      });

      setEvents([...events, ...tempEvents]);
      setHighlightDates([...highlightDates, ...tempHighlightDates]);
      setMonthEvents([...monthEvents, ...tempMonthEvents]);
    }
    if (calendarLessons?.events?.google?.length > 0) {
      calendarLessons?.events?.google.forEach((otherEvent: any) => {
        let eventDateFormatted: any = null;
        eventDateFormatted = moment(otherEvent.date, "yyyy-MM-DD");

        const filtered = tempMonthEvents.filter((tempDate) =>
          moment(tempDate.date).isSame(eventDateFormatted, "date")
        );

        const filtered_other_events = events
          .filter((event) => event.type === "other")
          .filter((tempData) =>
            moment(tempData.date).isSame(eventDateFormatted, "date")
          );

        if (filtered.length === 0 && filtered_other_events.length === 0) {
          tempMonthEvents.push({
            date: eventDateFormatted.toDate(),
            type: "other",
          });
        }

        tempEvents.push({
          ...otherEvent,
          date: eventDateFormatted.toDate(),
          type: "other",
          isIntegratedCalendar: true,
          title: true ? otherEvent.name : otherEvent.event_name,
          name: true ? otherEvent.name : otherEvent.event_name,
          duration: true
            ? 1
            : otherEvent?.is_all_day
            ? 24
            : parseFloat(otherEvent.event_duration),
          is_lesson: false,
          is_other: true,
        });
      });

      setEvents([...events, ...tempEvents]);
      setHighlightDates([...highlightDates, ...tempHighlightDates]);
      setMonthEvents([...monthEvents, ...tempMonthEvents]);
    }
    if (calendarLessons?.events?.outlook?.length > 0) {
      calendarLessons?.events?.outlook.forEach((otherEvent: any) => {
        let eventDateFormatted: any = null;
        eventDateFormatted = moment(otherEvent.date, "yyyy-MM-DD");

        const filtered = tempMonthEvents.filter((tempDate) =>
          moment(tempDate.date).isSame(eventDateFormatted, "date")
        );

        const filtered_other_events = events
          .filter((event) => event.type === "other")
          .filter((tempData) =>
            moment(tempData.date).isSame(eventDateFormatted, "date")
          );

        if (filtered.length === 0 && filtered_other_events.length === 0) {
          tempMonthEvents.push({
            date: eventDateFormatted.toDate(),
            type: "other",
          });
        }

        tempEvents.push({
          ...otherEvent,
          date: eventDateFormatted.toDate(),
          type: "other",
          isIntegratedCalendar: true,
          title: true ? otherEvent.name : otherEvent.event_name,
          name: true ? otherEvent.name : otherEvent.event_name,
          duration: true
            ? 1
            : otherEvent?.is_all_day
            ? 24
            : parseFloat(otherEvent.event_duration),
          is_lesson: false,
          is_other: true,
        });
      });
    }
  }, [calendarLessons]);

  const CalendarView = () => {
    return (
      <>
        {/* <div className="hidden w-full md:block bg-white p-4">
          <FullCalendarEvents
            events={events}
            onChange={(e: any) => {
              setShowAddLessonCard(true);
            }}
            isUpcommingOnly={isUpcommingOnly}
            loading={loading}
            showUpdateModal={() => setShowUpdateLessonCard(true)}
            setLesson={(e: Lesson) => setCurrentLesson(e)}
            showDeleteModal={() => setShowDeleteLessonCard(true)}
            showCancelModal={() => setShowCancelLessonCard(true)}
            testsUpdated={updateData}
          ></FullCalendarEvents>
        </div> */}

        <div className="w-full flex flex-col bg-white p-4">
          <span className="isolate inline-flex rounded-md shadow-sm sm:mx-4 w-fit">
            <button
              type="button"
              onClick={() => switchView("monthly")}
              className={`"relative rounded-l-md -ml-px inline-flex items-center px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus:z-10"
            ${
              activeView === "monthly"
                ? "bg-darkBlue text-white"
                : "bg-white hover:bg-gray-50 "
            }`}
            >
              Monthly
            </button>
            <button
              type="button"
              onClick={() => switchView("weekly")}
              className={`"relative -ml-px inline-flex items-center px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus:z-10"
            ${
              activeView === "weekly"
                ? "bg-darkBlue text-white"
                : "bg-white hover:bg-gray-50 "
            }`}
            >
              Weekly
            </button>
            <button
              type="button"
              onClick={() => switchView("daily")}
              className={`"relative rounded-r-md -ml-px inline-flex items-center px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus:z-10"
            ${
              activeView === "daily"
                ? "bg-darkBlue text-white"
                : "bg-white hover:bg-gray-50 "
            }`}
            >
              Daily
            </button>
          </span>

          {activeView === "monthly" && selectedDate && (
            <MonthView
              loading={loading}
              highlightDates={monthEvents}
              selectedOption={selectedIndex}
              showUpdateModal={() => setShowUpdateLessonCard(true)}
              setLesson={(e: Lesson) => setCurrentLesson(e)}
              showDeleteModal={() => setShowDeleteLessonCard(true)}
              showCancelModal={() => setShowCancelLessonCard(true)}
              initialDate={selectedDate}
              isUpcommingOnly={isUpcommingOnly}
              updateData={updateData}
              selectedDayInCalendar={selectedDayInCalendar}
              setSelectedDayInCalendar={setSelectedDayInCalendar}
              refreshMonthView={refreshMonthView}
            />
          )}
          {activeView === "weekly" && (
            <WeeklyView
              loading={loading}
              highlightDates={events}
              selectedOption={selectedIndex}
              showUpdateModal={() => setShowUpdateLessonCard(true)}
              setLesson={(e: Lesson) => setCurrentLesson(e)}
              showDeleteModal={() => setShowDeleteLessonCard(true)}
              showCancelModal={() => setShowCancelLessonCard(true)}
              initialDate={selectedDate}
              isUpcommingOnly={isUpcommingOnly}
              updateData={updateData}
              selectedDayInCalendar={selectedDayInCalendar}
              setSelectedDayInCalendar={setSelectedDayInCalendar}
            />
          )}
          {activeView === "daily" && (
            <DailyView
              loading={loading}
              highlightDates={events}
              selectedOption={selectedIndex}
              showUpdateModal={() => setShowUpdateLessonCard(true)}
              setLesson={(e: Lesson) => setCurrentLesson(e)}
              showDeleteModal={() => setShowDeleteLessonCard(true)}
              showCancelModal={() => setShowCancelLessonCard(true)}
              initialDate={selectedDate}
              isUpcommingOnly={isUpcommingOnly}
              updateData={updateData}
              selectedDayInCalendar={selectedDayInCalendar}
              setSelectedDayInCalendar={setSelectedDayInCalendar}
            />
          )}
        </div>
        {/* <div className="md:hidden w-full block">
          <MobileCalendarEvents
            loading={loading}
            highlightDates={highlightDates}
            selectedOption={selectedIndex}
            showUpdateModal={() => setShowUpdateLessonCard(true)}
            setLesson={(e: Lesson) => setCurrentLesson(e)}
            showDeleteModal={() => setShowDeleteLessonCard(true)}
            initialDate={selectedDate}
            isUpcommingOnly={isUpcommingOnly}
            testsUpdated={updateData}
          ></MobileCalendarEvents>
        </div> */}
      </>
    );
  };

  const ListView = () => {
    return (
      <div className=" px-5 py-4">
        <div className="my-4">
          {loading ? (
            <Skeleton
              className=" !rounded-full"
              height={35}
              animation="wave"
              variant="rounded"
            ></Skeleton>
          ) : lessons.length > 0 ? (
            <Button
              colour="yellow"
              type="button"
              fullWidth
              size="large"
              disabled={loading}
              onClick={addLesson}
            >
              <span className="flex items-center py-1">
                <PlusSmallIcon className="w-4 h-4" /> New Lesson
              </span>
            </Button>
          ) : (
            <></>
          )}
        </div>

        {hasPrevLessons && selectedIndex != 0 && lessons.length > 0 && (
          <div className="bg-white p-4 mb-4">
            <Toggler
              label="Include previous lessons"
              checked={isUpcommingOnly ? false : true}
              onChange={onIncludePrevChange}
              disabled={loading}
              loading={loading}
            />
          </div>
        )}

        {lessons.length == 0 && !loading ? (
          <>
            <EmptyState
              title="There is no upcoming lessons"
              Icon={ExclamationCircleIcon}
            />
            <Button
              colour="yellow"
              type="button"
              fullWidth
              size="large"
              disabled={loading}
              onClick={addLesson}
            >
              <span className="flex items-center py-1">
                <PlusSmallIcon className="w-4 h-4" /> New Lesson
              </span>
            </Button>
          </>
        ) : (
          <LessonsListTab
            lessons={lessons}
            loading={loading}
            showUpdateModal={() => setShowUpdateLessonCard(true)}
            setLesson={(e: Lesson) => setCurrentLesson(e)}
            showDeleteModal={() => setShowDeleteLessonCard(true)}
            showCancelModal={() => setShowCancelLessonCard(true)}
          ></LessonsListTab>
        )}
      </div>
    );
  };

  const tabs = [
    {
      key: "calendar",
      title: "Calendar View",
      component: CalendarView(),
    },
    {
      key: "list",
      title: "Lessons",
      component: ListView(),
    },
  ];

  return (
    <>
      <AddEditLessonModel
        onClose={() => {
          setShowUpdateLessonCard(false);
        }}
        duration={currentLesson.duration}
        firstDate={
          currentLesson?.lesson_date_str
            ? new Date(currentLesson?.lesson_date_str)
            : new Date()
        }
        jobId={currentLesson?.job_id}
        id={currentLesson?.id}
        lessonsUpdated={() => {
          getLessons(isUpcommingOnly);
          updateData();
        }}
        show={showUpdateLessonCard}
        isPrivateJob={currentLesson?.is_private_student}
        student_email={currentLesson?.student_data?.student_email}
        recurringOption={currentLesson?.recurring_option}
        canUpdateSeries={currentLesson?.can_update_series}
        seriesStartDate={currentLesson?.series_start_date}
        seriesEndDate={currentLesson?.series_end_date}
        lesson_type={currentLesson?.lesson_type}
      />

      <AddEditLessonModel
        onClose={() => {
          setShowAddLessonCard(false);
        }}
        duration={1}
        firstDate={null}
        jobId={undefined}
        id={undefined}
        lessonsUpdated={() => {
          getLessons(isUpcommingOnly);
          updateData();
        }}
        show={showAddLessonCard}
        isPrivateJob={null}
      />

      <RemoveLessonDialog
        lessonId={currentLesson.id}
        duration={currentLesson.duration}
        date={currentLesson.lesson_date_str}
        show={showDeleteLessonCard}
        dateStr={currentLesson.lesson_date_str}
        isPrivateJob={currentLesson?.is_private_student}
        onHide={() => {
          setShowDeleteLessonCard(false);
        }}
        onSubmit={() => {
          getLessons(isUpcommingOnly);
          updateData();
          setShowDeleteLessonCard(false);
          setRefreshMonthView(true);
        }}
        recurringOption={currentLesson?.recurring_option}
        canUpdateSeries={currentLesson?.can_update_series}
      />

      <CancelLessonDialog
        lessonId={currentLesson.id}
        duration={currentLesson.duration}
        date={currentLesson.lesson_date_str}
        show={showCancelLessonCard}
        dateStr={currentLesson.lesson_date_str}
        isPrivateJob={currentLesson?.is_private_student}
        hasEmail={currentLesson?.student_data?.student_email ? true : false}
        onHide={() => {
          setShowCancelLessonCard(false);
        }}
        onSubmit={() => {
          getLessons(isUpcommingOnly);
          updateData();
          setShowCancelLessonCard(false);
          setRefreshMonthView(true);
        }}
        recurringOption={currentLesson?.recurring_option}
        canUpdateSeries={currentLesson?.can_update_series}
      />

      {
        <>
          {
            <Tab.Group
              selectedIndex={selectedIndex}
              onChange={(index) => {
                setSelectedIndex(index);
              }}
            >
              <Tab.List className="flex space-x-1 lg:w-1/4 sm:w-1/2">
                {tabs.map((tab) => (
                  <Tab
                    key={tab.key}
                    className={({ selected }) =>
                      classNames(
                        "w-full py-2.5 text-base font-medium leading-5",
                        "ring-white ring-opacity-60 ring-offset-2 ring-offset-transparent focus:outline-none focus:ring-none",
                        selected
                          ? " border-b-4 border-darkBlue"
                          : " text-[#A8A8A8] hover:brightness-75"
                      )
                    }
                  >
                    <>{tab.title}</>
                  </Tab>
                ))}
              </Tab.List>
              <Tab.Panels className="">
                {tabs.map((tab) => (
                  <Tab.Panel
                    key={tab.key}
                    className={classNames(
                      "grid grid-cols-1 gap-0  overflow-y-hidden ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none"
                    )}
                  >
                    {tab.component}
                  </Tab.Panel>
                ))}
              </Tab.Panels>
            </Tab.Group>
          }
        </>
      }
    </>
  );
};

export { LessonsList };
