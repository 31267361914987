/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useCallback, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import * as auth from '../redux/AuthRedux'
import { login, register, verifyRecaptch } from '../redux/AuthCRUD'
import { PasswordRequirements, passwordYup } from './PasswordRequirements'
import ReCAPTCHA from "react-google-recaptcha";
import ReactGA from "react-ga4";
import PageHeader from '../../../components/shared/elements/PageHeader'
import Input from '../../../components/shared/forms/Input'
import CheckboxInput from '../../../components/shared/forms/CheckboxInput'
import Button from '../../../components/shared/elements/Button'
import Alert from '../../../components/shared/overlays/Alert'

const registrationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
    changeEmail: Yup.string()
    .test('case-insensitive-match', "Email must match the previous added email", function (value) {
      const originalEmail = this.parent.email || '';
      return value?.toLowerCase() === originalEmail.toLowerCase();
    }).required("Confirm Email is required"),
  password: passwordYup,
  changepassword: Yup.string()
    .required('Confirm password is required')
    .oneOf([Yup.ref('password')], "Passwords do not match"),

  acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
})
interface IProps {
  email: string
  cancelRegistration: () => void
  children?: React.ReactNode
}
const Registration: FC<IProps> = (props) => {

  // Initialize a boolean state
  const [passwordShown, setPasswordShown] = useState(false)
  const initialValues = {
    email: props.email,
    password: '',
    changepassword: '',
    changeEmail: '',
    acceptTerms: false,
  }
  const onAcceptTerms=(e:any)=>{
    if (e.target.checked) {
      formik.setFieldValue("acceptTerms",true);
    }else{
      formik.setFieldValue("acceptTerms",false);
    }
  }
  
  // Initialize a boolean state
  const [confirmPassShown, setConfirmPassShown] = useState(false)
  
  const recaptchaKey: string = process.env.REACT_APP_RECAPTCHA_KEY ?? '';
  const recaptchaRef = useRef<any>(null)
  const [recaptchaChecked, setRecaptchaChecked] = useState(false)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true)
      setTimeout(() => {
        const captchaToken = recaptchaRef.current.getValue();
        // recaptchaRef.current.reset();
        verifyRecaptch(captchaToken)
          .then(() => register(values.email, values.password))
          .then(() => login(values.email, values.password))
          .then(() => {
            ReactGA.event('potal_login');
            dispatch(auth.actions.login())
          })
          .catch((err: any) => {
            if (err.errors?.length) {
              formik.setStatus(err.errors[0].errorMessage)
            } else {
              formik.setStatus(err.message)
            }
          }).finally(() => {
            setLoading(false)
            setRecaptchaChecked(false)
            setSubmitting(false)
          })
      }, 1000)
    },
  })

  const getFieldErrors = (fieldName: string, values: any) => {
    try {
      registrationSchema.validateSyncAt(fieldName, values, { abortEarly: false });
      return []; // No errors for the field
    } catch (validationError: any) {
      if (validationError.inner) {
        return validationError.inner
          .filter((error: any) => error.path === fieldName)
          .map((error: any) => error.message);
      }
      return [validationError.message];
    }
  };

  const passwordErrors = getFieldErrors('password', formik.values);

  return (
    <form
      className="space-y-6"
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >

      {/* begin::Heading */}
      <div className="text-center -mt-8">
        {/* begin::Title */}
        <PageHeader title="Welcome to ADI Network" isCenter={true} />
        {/* end::Title */}
      </div>
      {/* end::Heading */}


      {formik.status && (
        <Alert colour='red' description={formik.status} />
      )}

      {/* begin::Form group Confirm Email */}

      <Input
        placeholder='Email'
        type='email'
        label='Email'
        error={formik.touched.changeEmail && formik.errors.changeEmail ? true : false}
        errorMsg={formik.errors.changeEmail}
        {...formik.getFieldProps('changeEmail')}

      />
      {/* end::Form group Confirm email*/}

      {/* begin::Form group Password */}

      <div>
        <Input
          type={passwordShown ? "text" : "password"}
          placeholder="Password"
          label="Password"
          eyeIcon={true}
          error={
            formik.touched.password && formik.errors.password ? true : false
          }
          errorMsg={""}
          {...formik.getFieldProps("password")}
        />
        
        <PasswordRequirements currentErrors={passwordErrors} expand={(formik.touched.password || formik.values.password) ? true : false}/>       
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}

      <Input
        label='Confirm Password'
        type={confirmPassShown ? 'text' : 'password'}
        placeholder='Password confirmation'
        {...formik.getFieldProps('changepassword')}
        error={formik.touched.changepassword && formik.errors.changepassword ? true : false}
        errorMsg={formik.errors.changepassword}
        eyeIcon={true}
        required={true}
      />

      {/* end::Form group */}

      {/* begin::Form group */}
      <ReCAPTCHA
        sitekey={recaptchaKey}
        ref={recaptchaRef}
        onChange={useCallback(() => setRecaptchaChecked(true), [recaptchaRef])}
      />
      {/* end::Form group */}

      {/* begin::Form group */}

      <CheckboxInput
        name="acceptTerms"
        error={formik.touched.acceptTerms && formik.errors.acceptTerms?true:false}
        errorMsg={formik.errors.acceptTerms}

        label={
          <>
            I agree to the{' '}
            <a href="https://www.passmefast.co.uk/adi-terms" target="_blank" rel="noopener noreferrer" className='text-blue-900 ml-1 underline'>
              terms and conditions
            </a>
          </>
        }
        isSingleCheckbox={true}
        onChange={(e)=>{onAcceptTerms(e)}}
        required={true}
        checked={formik.values.acceptTerms}

      />


      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='flex justify-between space-x-2'>
        <Button
          type='submit'
          colour="yellow"
          size='large'
          id='kt_sign_up_submit'
          className='  '
          disabled={formik.isSubmitting || !(formik.isValid && formik.values.acceptTerms && recaptchaChecked)}
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </Button>
        {/* end::Form group */}

        {/* begin::Action */}
        {/* <SocialLogin></SocialLogin> */}
        {/* end::Action */}

        <Button
          colour='gray'
          size='large'
          type='button'
          id='kt_login_password_reset_form_cancel_button'
          disabled={formik.isSubmitting}
          onClick={(e) => {
            e.preventDefault()
            props.cancelRegistration()
          }}
        >
          Cancel
        </Button>
      </div>
    </form>
  )
}

export { Registration }
