import React, { FC } from 'react'
import { TestCenterDataModel } from '../../../../core/models/test-center-data-model'
import Dropdown from '../../shared/forms/Dropdown'
import Button from '../../shared/elements/Button'
import { PlusSmallIcon, TrashIcon } from '@heroicons/react/24/outline'
import Skeleton from '@mui/material/Skeleton';

interface IProps {
    values: Array<TestCenterDataModel>
    label: string
    isDisabled: boolean
    isSubmitted?: boolean
    errorMsg: any
    options: Array<any>
    onChange: (e: any, key: number) => void
    deleteTestCentre: (key: number) => void
    addTestCentre: () => void
    loading?:boolean;
}

const TestCentres: FC<IProps> = React.memo(({ values, label, errorMsg, isDisabled, isSubmitted, options, onChange, deleteTestCentre, addTestCentre,loading=false }) => {

    const getOptions = (value: any) => {
        if(value?.test_center_id != '') {
            return options.concat({ value: value.test_center_id, label: value.test_center_name }).sort((a,b) => b.label > a.label ? -1 : 0)
        }
        return options
    }
    return (
        <>
            <label className='text-sm font-medium text-darkBlue'>
            {loading ?
                      <Skeleton width={100} className="me-2" />

                      : <>
                
                {label}
                
                 <span className='text-sm text-red-500'>*</span></>
            }
            </label>

            {values.map(function (testCentre: TestCenterDataModel, key: number) {
                return (
                    <div key={key} className='flex justify-between items-center'>
                        <div className='md:w-[90%] sm:w-[92%] w-[88%] -my-2'>
                            <Dropdown
                                // {...formik.getFieldProps('test_centres')}
                                options={getOptions(testCentre)}
                                placeholder='Test centre'
                                error={(!testCentre.test_center_id && isSubmitted) ? true : false}
                                errorMsg={errorMsg}
                                isSearchable={true}
                                name='test_centres'
                                disabled={isDisabled}
                                id='test_centres'
                                value={getOptions(testCentre).filter((opt: any) => opt.value == testCentre.test_center_id)[0]}
                                onChange={(e) => onChange(e, key)} />
                        </div>
                        <div className={`w-fit ${(!testCentre.test_center_id && isSubmitted) ? 'mb-4' : ''}`}>
                            {(key !== 0 || values.length > 1) && (
                                <Button
                                    disabled={isDisabled}
                                    type='button'
                                    size='fit'
                                    fitWidth={true}
                                    colour='transparent'
                                    Icon={TrashIcon}
                                    onClick={() => deleteTestCentre(key)}
                                ></Button>
                            )}
                        </div>
                    </div>
                )
            })}

            <div className='my-2'>
                {
                    loading? <Skeleton width={80}/>: <Button
                    colour="link"
                    type="button"
                    size='fit'
                    fitWidth
                    onClick={addTestCentre}
                >
                    <span className='flex items-center py-1'><PlusSmallIcon className='w-5 h-5' /> Add Test Centre</span>
                </Button>
                }
               
            </div>
        </>
    )
})

export { TestCentres }
