import { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getAdiLatestBadge } from "../../../../core/services/adi-service";
import { IQuationnaireTabProps } from "../../../../core/models/quationnaire-tab-props";
import Alert from "../../shared/overlays/Alert";
import { AdiPdiSettingsTemplate } from "../adiPdiSettings/AdiPdiSettingsTemplate";
import PageHeader from "../../shared/elements/PageHeader";
import { AddDocumentDialog } from "../documents/AddDocumentDialog";
import { DocumentDataModel } from "../../../../core/models/document-data-model";
const AboutDrivingTuition: FC<IQuationnaireTabProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [hasUploadedBadge, setHasUploadedBadge] = useState(false);
  const [badge, setBadge] = useState<DocumentDataModel | null>(null);

  const history = useHistory();
  const redirectToSettings = () => {
    history.push("/ADI/settings/overview");
  };

  useEffect(() => {
    getLatestBadge();
  }, []);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  function getLatestBadge() {
    getAdiLatestBadge()
      .then((res) => {
        setBadge(res.data.results);
        if (res.data.results != null) {
          setHasUploadedBadge(true);
        }
      })
      .catch((res) => {});
  }

  const onCreateBadge = () => {
    setHasUploadedBadge(true);
    getLatestBadge();
  };

  return (
    <>
      <div>
        {props.parentWrapper !== "questionnaire" ? (
          <div className="mb-5">
            <PageHeader
              title="Driving Tuition Details"
              backToURL={
                props.parentWrapper !== "questionnaire"
                  ? "/ADI/settings/overview"
                  : ""
              }
            />
          </div>
        ) : (
          <></>
        )}
        <div>
          <div className="bg-white py-4 px-5">
            <div
              className={
                `col-12  m-auto` +
                (props.parentWrapper === "questionnaire"
                  ? "grid grid-cols-10 "
                  : "grid grid-cols-12")
              }
            >
              {props.parentWrapper === "questionnaire" &&
              props.selfRegisterDate ? (
                <Alert
                  colour="orange"
                  icon="pmf-icon-info"
                  title={
                    "We ask for your badge number so that we can validate your profile as part of our safeguarding commitments to the course providers that use ADI Network."
                  }
                />
              ) : (
                <></>
              )}
              {props.parentWrapper === "questionnaire" &&
              !props.selfRegisterDate ? (
                <Alert
                  colour="orange"
                  icon="pmf-icon-info"
                  title={
                    "Welcome to our new ADI Network app. \nAs we contacted you before, we already hold some of your data in our systems. Please review and update these records in order to access the app."
                  }
                />
              ) : (
                <></>
              )}
              {props.parentWrapper === "questionnaire" ? (
                <h4 className="mb-2 text-base font-bold">Your Badge Number</h4>
              ) : (
                <></>
              )}
              {props.parentWrapper === "questionnaire" && (
                <AddDocumentDialog
                  show={show}
                  onHide={handleClose}
                  isUploadBadge={
                    props.parentWrapper == "questionnaire" ? true : false
                  }
                  onCreatedNewDocument={onCreateBadge}
                />
              )}

              {
                <AdiPdiSettingsTemplate
                  parentWrapper={props.parentWrapper}
                  allowSkip={false}
                  handleShow={handleShow}
                  hasUploadedBadge={hasUploadedBadge}
                  badge={badge}
                  goToPrevTab={() =>
                    props.parentWrapper == "questionnaire"
                      ? props.goToPrevTab()
                      : redirectToSettings()
                  }
                  goToNextTab={() =>
                    props.parentWrapper == "questionnaire"
                      ? props.goToNextTab()
                      : redirectToSettings()
                  }
                ></AdiPdiSettingsTemplate>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { AboutDrivingTuition };
