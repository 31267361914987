


export default function TabItem({ label, activeTab, handleTabChange } : any) {

    return (
        <li className="w-full focus-within:z-10 my-2 mx-2 "  onClick={() => handleTabChange(label)}>
            <a
                href="#"
                className={
                    activeTab !== label
                        ? "inline-block  rounded-full  px-4 py-2 text-darkBlue bg-gray-200 focus:outline-none  "
                        : "inline-block  w-full active rounded-full  px-4 py-2 bg-darkBlue  text-white  focus:outline-none "
                }
                aria-current={activeTab === label ? "page" : undefined}
            >
                <div className="text-sm">
                {label}
                </div>
               
            </a>
        </li>
    );
}