import { FC, useState } from "react";
import Modal from "../../shared/overlays/Modal";
import { addPromotionCode } from "../../../../core/services/adi-service";
import toast from "react-hot-toast";
import Notification from "../../shared/overlays/Notification";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";

interface IProps {
  show: any;
  onClose: any;
  onSubmitted: any;
  referrerData?: any;
}

const PromotionCodeModal: FC<IProps> = (props) => {
  const [hasError, setHasError] = useState(false);

  const submitRefferer = async () => {
    let loadingCodeId = toast.custom(
      () => (
        <Notification
          colour="gray"
          title={"Submitting your promotion code..."}
          description={""}
          Icon={ExclamationCircleIcon}
        />
      ),
      {
        duration: 5000,
        position: "top-center",
      }
    );

    const res = await addPromotionCode({ code: props.referrerData.code }).catch(
      () => {
        setHasError(true);
      }
    );

    if (res?.status === 200) {
      toast.custom(
        () => (
          <Notification
            colour="green"
            title={"Submitted Promotion code"}
            description={""}
            Icon={CheckCircleIcon}
          />
        ),
        {
          duration: 5000,
          position: "top-center",
          id: loadingCodeId,
        }
      );
      props.onSubmitted();
      return;
    } else {
      toast.custom(
        () => (
          <Notification
            colour="red"
            title={"Failed to submit promotion code!"}
            description={""}
            Icon={ExclamationTriangleIcon}
          />
        ),
        {
          duration: 5000,
          position: "top-center",
          id: loadingCodeId,
        }
      );
      setHasError(true);
    }
  };

  return (
    <>
      <form noValidate>
        <Modal
          open={props.show}
          onClose={props.onClose}
          onSubmit={submitRefferer}
          submitText="Yes"
          closeText="No"
          title="Confirmation"
        >
          <div>
            <h4 className="text-base font-bold">
              Please confirm you have been referred by{" "}
              {props.referrerData.full_name}
            </h4>
            {hasError && (
              <div className="text-red-500">
                Error when submitting promotion code. Please try again later.
              </div>
            )}
          </div>
        </Modal>
      </form>
    </>
  );
};

export { PromotionCodeModal };
