import {FC} from 'react'
import { toAbsoluteUrl } from '../../../../helpers'
import Modal from '../../../shared/overlays/Modal'

type Props = {
  show: any
  onHide: any
  continueSelected:any

}

const VerifyAccountModal: FC<Props> = ({continueSelected,show, onHide}) => {
  return (
    <>
     <Modal
          open={show}
          onClose={()=>{onHide()}}
          onSubmit={()=>{continueSelected()}}
          submitText='Complete'
          closeText='Cancel'
          title='Verify Account'
          description='By completing this form you confirm that all details are correct.'
        >
      </Modal> 
    </>
  )
}

export {VerifyAccountModal}
