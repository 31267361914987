import { FC, ReactNode } from "react";
import { toAbsoluteUrl } from "../../../helpers";
import Skeleton from "@mui/material/Skeleton";

interface IAvailabilityCardProp {
  availability: Array<any>;
  availabilityMatch: string | undefined;
  title?: string;
  customDesc?: ReactNode;
  loading: boolean;
}

const AvailabilityCard: FC<IAvailabilityCardProp> = ({
  title="Availability Match:",
  availability,
  availabilityMatch,
  customDesc,
  loading
}) => {
  return (
    <>
    {
      loading ? 
      <div className="flex flex-col flex-1 rounded-[10px] bg-gray-100 pt-5 pb-4 px-5">
        <div className="flex items-center mb-5">
          <i className=" text-[26px] me-4"><Skeleton width={20} className="me-2" /></i>
          <div className="flex items-center text-sm tracking-normal text-darkBlue antialiased content-center grow">
            <span className=" leading-none"><Skeleton width={70} className="me-2" /></span>
            <>
            </>   
          </div>
        </div>

        <ul className="rounded-2 normal-list">
            <li className="mb-2">
              <div className="sflex flex-col">
              <div className="grid row content-center">
                <span className="text-darkBlue text-sm font-semibold ">
                  <Skeleton height={50} className="me-2" />
                </span>
              </div>
              <div className="grid row content-center">
                <span className="text-darkBlue text-sm font-semibold">
                  <Skeleton height={50} className="me-2" />
                </span>
              </div>
              </div>
            </li>
        </ul>
        {customDesc}
      </div>
      :<div className="flex flex-col flex-1 rounded-[10px] bg-gray-100 pt-5 pb-4 px-5">
      <div className="flex items-center mb-5">
        <i className=" text-[26px] pmf-icon-calendar me-4"></i>
        <div className="flex items-center text-sm tracking-normal text-darkBlue antialiased content-center grow">
          <span className=" leading-none">{availabilityMatch? <>{title}</> : <>Availability</>}</span>
          {availabilityMatch ? 
          <>
            <div className="flex justify-center grow items-center ">
              <img
                  src={toAbsoluteUrl(
                  "/assets/media/svg/availability/availability-" +
                      availabilityMatch +
                      ".svg"
                  )}
                  alt={availabilityMatch}
                  className=" w-[14px] block"
              />

              <p className=" text-base font-bold px-2">{availabilityMatch}</p>
            </div>
          </> 
          :<></>}
          
        </div>
      </div>

      <ul className="rounded-2 normal-list">
        {availability?.map((day, i) => (
          <li className="mb-2" key={i}>
            <div className="grid grid-cols-6 content-center">
              <span className="text-darkBlue text-sm font-semibold">
                {day.day_short_name}
              </span>
              <div className="flex flex-col col-span-5 text-center">
                {day.availability &&
                  day.availability.map((slot: any, i: any) => (
                    <div
                      className="flex items-center bg-white rounded-full px-4 py-1 w-full justify-center"
                      key={i}
                    >
                      <span className="text-sm leading-0 mt-[2px] text-center">
                        {/* {i > 0 && i < day.availability.length && <span>, </span>} */}
                        {slot?.from_time?.slice(0, 5)}<span className="mx-[2px]">-</span>
                        {slot?.to_time?.slice(0, 5)}
                      </span>
                    </div>
                  ))}
              </div>
            </div>
          </li>
        ))}
      </ul>
      {customDesc}
    </div>
    }
      
    </>
  );
};

export { AvailabilityCard };
