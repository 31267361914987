import DatePicker from 'react-datepicker';
import { useState, useEffect } from 'react';
import Input from './Input';
import { scrollToTagBySelector } from '../../../helpers/ScrollHelper';

export interface DateInputProps {
  label?: string;
  id: string;
  name?: string;
  placeholderText?: string;
  inputLabel?: string;
  inputPlaceholder?: string;
  inputName?: string;
  selected?: any;
  openToDate?: any;
  minDate?: any;
  onChange: (newValue: any) => void;
  error?: boolean;
  errorMsg?: string;
  required?: boolean;
  isClearable?: boolean;
  disabled?: boolean;
}

export default function DateInput({
  label,
  name,
  id = "",
  placeholderText,
  inputLabel,
  inputPlaceholder,
  inputName,
  selected,
  openToDate,
  minDate,
  onChange,
  required = false,
  isClearable = false,
  disabled = false,
  error = false,
  errorMsg = "This field is required",
  ...props
}: DateInputProps) {

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open)
      scrollToTagBySelector('.datepicker_' + id)
  }, [open])

  return (
    <>
      <DatePicker
        selected={selected}
        openToDate={openToDate}
        minDate={minDate}
        dateFormat={'dd/MM/yyyy'}
        isClearable={isClearable}
        disabled={disabled}
        placeholderText={placeholderText}
        name={name}
        id={id}
        required={required}
        onChange={onChange}
        onInputClick={() => setOpen(!open)}
        onClickOutside={() => setOpen(false)}
        onSelect={() => setOpen(false)}
        open={open}
        customInput={
          <Input
            type='text'
            label={inputLabel}
            placeholder={inputPlaceholder}
            readonly={true}
            autoFocus={false}
            error={error}
            errorMsg={errorMsg}
            name={inputName} />
        }
        calendarClassName="w-full pt-5"
        clearButtonClassName="!h-fit !top-[42%] after:!bg-white after:ring-1 after:ring-darkBlue after:!text-darkBlue"
        popperClassName={`${'datepicker_' + id} !static !pt-0 w-full !translate-x-0`}
        wrapperClassName="-mb-3 w-full"
      />
    </>
  );
}


