import { FC, useEffect, useState } from 'react'
import PageHeader from '../../components/shared/elements/PageHeader'
import { TestList } from '../../components/test/TestList'
import { FilterObject } from '../../../core/models/filter-object'
import { getTestsList } from '../../../core/services/job-service'
import ReactGA from 'react-ga4'
import { Test } from '../../../core/models/tests/test'
import { TestFilterModal } from '../../components/test/TestFilterModal'
import Badge from '../../components/shared/elements/Badge'

const TestsWrapper: FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [openFilterModal, setOpenFilterModal] = useState<boolean>(false)
    const [tests, setTests] = useState<Array<Test>>([])
    const [filterCount, setFilterCount] = useState(0)
    const [filterObject, setFilterObject] = useState<FilterObject>({
        SearchObject: {},
        PageNumber: 0,
        PageSize: 10000000,
        SortDirection: 'desc',
        SortBy: 'created_at',
    })

    function LoadTests() {
        (async () => {
            setIsLoading(true)
            const testsRequest = await getTestsList(filterObject).catch(() => setIsLoading(false));
            setTests(testsRequest?.results ?? [])
            setIsLoading(false)
        })()
    }

    useEffect(() => {
        ReactGA.event('open_tests_tab')
    })
    useEffect(() => {
        LoadTests()
    }, [filterObject])

    const applyFilter = (searchObject: any) => {
        setFilterObject({
            ...filterObject,
            SearchObject: searchObject
        })
        setOpenFilterModal(false)
        setFilterCount(Object.values(searchObject).filter((x) => x === true).length)
    }

    useEffect(() => {
        document.title = `ADI Network App | Tests | ADI Network`;
    }, []);

    return (
        <>
            <div className='px-5'>
                <TestFilterModal filterObject={filterObject} open={openFilterModal} ApplyFilter={applyFilter}></TestFilterModal>
                <div className='mb-5'>
                <PageHeader title="Test details"  buttons={[
                        {
                            text: 'Filter',
                            notification: filterCount > 0 && <Badge notificationBadge={true} size="smBadge" title={filterCount} />,
                            Icon: "filter",
                            onClick: () => { setOpenFilterModal(true); }
                        }
                    ]}/>
                </div>
                <TestList tests={tests} loading={isLoading} refreshTests={LoadTests}></TestList>
            </div>
        </>
    )
}

export { TestsWrapper }
