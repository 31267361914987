import { FC, useEffect, useState } from "react";
import { AdiJob } from "../../../../../../core/models/adi-job";
import { CheckListItem } from "../../../../../../core/models/checklist-item";
import {
  getCheckList,
  storeAdiJobCheckList,
} from "../../../../../../core/services/job-service";
import CheckboxInput from "../../../../shared/forms/CheckboxInput";
import toast from "react-hot-toast";
import Notification from "../../../../shared/overlays/Notification";
import {
  ExclamationCircleIcon,
  CheckCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import DisclosureItem from "../../../../shared/elements/DisclosureItem";
import { toAbsoluteUrl } from "../../../../../helpers";
import Button from "../../../../shared/elements/Button";

interface ILearnerCheckList {
  jobDetails: AdiJob | undefined;
  refreshParent: () => void;
}

const LearnerCheckList: FC<ILearnerCheckList> = ({
  jobDetails,
  refreshParent,
}) => {
  const [checkListInput, setCheckListInput] = useState<string[]>([]);
  const [checkList, setCheckList] = useState<CheckListItem[]>([]);
  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  useEffect(() => {
    getCheckList().then((result) => {
      if (result?.data?.results) {
        setCheckList(result.data.results);
      }
    });
  }, []);

  useEffect(() => {
    if (jobDetails) {
      const adiCheckList = jobDetails?.job?.adi_check_list ?? [];
      const adiCheckListIds = adiCheckList.map(
        (item: any) => item.intro_checklist_item_id
      );
      setCheckListInput(adiCheckListIds);
    }
  }, [jobDetails?.job?.adi_check_list]);

  function submitCheckListInput() {
    setSubmitting(true);
    var jobCheckList = {
      adi_job_id: jobDetails?.id,
      intro_checkList: checkListInput,
    };
    let loadingId = toast.custom(
      () => (
        <Notification
          colour="gray"
          title={"Updating lesson checklist..."}
          description={""}
          Icon={ExclamationCircleIcon}
        />
      ),
      {
        duration: 5000,
        position: "top-center",
      }
    );

    refreshParent();
    storeAdiJobCheckList(jobCheckList)
      .then((result) => {
        toast.custom(
          () => (
            <Notification
              colour="green"
              title={"Saved successfully"}
              description={""}
              Icon={CheckCircleIcon}
            />
          ),
          {
            duration: 5000,
            position: "top-center",
            id: loadingId,
          }
        );
      })
      .catch((res) => {
        toast.custom(
          () => (
            <Notification
              colour="red"
              title={"Failed to update lesson checklist!"}
              description={""}
              Icon={ExclamationTriangleIcon}
            />
          ),
          {
            duration: 5000,
            position: "top-center",
            id: loadingId,
          }
        );
      })
      .finally(() => setSubmitting(false));
  }

  function updateCheckListInput(e: any) {
    setCheckListInput(e);
  }

  return (
    <>
      <DisclosureItem
        disclosureCard
        title=""
        customDescription={
          <div className="flex items-center px-1 pb-1">
            <img
              src={toAbsoluteUrl("/assets/media/svg/puppil-checklist.svg")}
              alt=""
              className="w-[16px] me-4"
            />
            <p className="text-sm font-normal text-start">Learner Checklist</p>
          </div>
        }
        subTitle=""
        background="bg-gray-100"
      >
        <div className="px-5 pb-5 -mt-1">
          <CheckboxInput
            name={"Learner Checklist"}
            label={""}
            options={checkList.map((item) => {
              return { value: item.id, label: item.label };
            })}
            onChange={(e) => updateCheckListInput(e)}
            values={checkListInput}
            required={false}
            disabled={isSubmitting}
          />

          <Button
            colour="outline"
            type="button"
            className="w-full mt-3"
            onClick={submitCheckListInput}
            size="large"
            disabled={isSubmitting}
          >
            Save checklist
          </Button>
        </div>
      </DisclosureItem>
    </>
  );
};

export default LearnerCheckList;
