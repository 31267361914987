import { FC, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import PageHeader from "../../../components/shared/elements/PageHeader";
import {
  BarChart,
  LineChart,
  axisClasses,
  useDrawingArea,
  useYScale,
} from "@mui/x-charts";
import { StandardsCheckIndicatorsByType } from "../../../../core/services/test-service";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";
import React from "react";
import { DetailsBarChart } from "../../../components/standards-check/DetailsBarChart";
import { DetailsAreaChart } from "../../../components/standards-check/DetailsAreaChart";
interface ResponseItem {
  graph: { month: string; value: number }[];
  title: string;
  hitNumber: number;
}
const StandardsCheckDetails: FC = () => {
  const [checkDetails, setCheckDetails] = useState<ResponseItem | any>();
  const [indicatorsNote, setindicatorsNote] = useState<React.ReactNode>(null);
  const [pageTitle, setPageTitle] = useState("");
  const params = useParams();
  const paramValues = Object.values(params);
  const lastParam = paramValues[paramValues.length - 1] as string;

  useEffect(() => {
    const fetchData = async () => {
      if (lastParam) {
        try {
          const data = await StandardsCheckIndicatorsByType({
            type: lastParam,
          });
          // Assuming data is in the format of ResponseItem directly
          if (data) {
            setCheckDetails(data);
          } else {
            console.error("Empty or invalid response received.");
          }
        } catch (error) {
          console.error("Error fetching data:", error);
          // Handle error - set checkDetails to null or show error message
        }
      }
    };
    fetchData();
  }, [lastParam]);

  useEffect(() => {
    console.log("checkDetails:", checkDetails); // Log checkDetails
    // Switch content based on the value of myPropValue
    switch (lastParam) {
      case "number_of_minors_faults":
        setindicatorsNote(
          <>
            {" "}
            This indicator is triggered if your learners commit an{" "}
            <span className="font-bold">average of 6 or more</span> minor faults
            per test
          </>
        );
        setPageTitle("Minor driving faults");
        break;
      case "number_of_serious_faults":
        setindicatorsNote(
          <>
            {" "}
            This indicator is triggered if your learners commit an{" "}
            <span className="font-bold">average of 0.55</span> serious faults
            per test
          </>
        );
        setPageTitle("Serious driving faults");
        break;
      case "examiner_intervene":
        setindicatorsNote(
          <>
            {" "}
            This indicator is triggered if an examiner takes action in{" "}
            <span className="font-bold">10% or more</span> of your learners’
            tests
          </>
        );
        setPageTitle("Examiner Intervention");
        break;
      default:
        setindicatorsNote(
          <>
            {" "}
            This indicator is triggered if your driving test pass rate drops{" "}
            <span className="font-bold">below 55%</span>
          </>
        );
        setPageTitle("Pass rate");
    }
  }, [checkDetails, lastParam]);

  useEffect(() => {
    document.title = `ADI Network App | More | ADI Network`;
  }, []);

  return (
    <>
      <PageHeader
        title={pageTitle}
        backToURL="/ADI/standards-check-indicators"
      />
      <div className="px-5 lg:px-6 pt-9 mt-5 bg-white">
        {checkDetails ? (
          <>
            {lastParam === "number_of_minors_faults" ||
            lastParam === "number_of_serious_faults" ? (
              <>
                <DetailsBarChart
                  chartGraphData={checkDetails[0].graph}
                  hitNumber={checkDetails[0].hitNumber}
                  loading={false}
                />
              </>
            ) : (
              <>
                <DetailsAreaChart
                  chartGraphData={checkDetails[0].graph}
                  hitNumber={checkDetails[0].hitNumber}
                  loading={false}
                />
              </>
            )}

            <p className="text-sm mt-8 pb-8">{indicatorsNote}</p>
          </>
        ) : (
          <>loading</>
        )}
      </div>
    </>
  );
};

export { StandardsCheckDetails };
