import React, { useState } from "react";
import { Hint } from "react-autocomplete-hint";

export interface InputHintProps extends React.HTMLAttributes<HTMLInputElement> {
  label?: string;
  id?: string;
  name?: string;
  className?: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error?: boolean;
  errorMsg?: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  options: Array<any>;
  type: "text";
}
export default function InputHint({
  label,
  className = "",
  type = "text",
  name,
  id = "",
  value,
  onChange,
  required = false,
  disabled = false,
  error = false,
  errorMsg = "This field is required",
  placeholder,
  options = [],
  ...props
}: InputHintProps) {
  return (
    <div className="flex flex-col items-start my-6">
      <div className="relative w-full">
        <label
          htmlFor={name}
          className="absolute -top-2 z-[3] left-2 inline-block bg-white px-1 text-sm font-medium text-darkBlue"
        >
          {label}
          {required && <span className="text-sm text-red-500">*</span>}
        </label>

        <div className="relative -mt-0.5 rounded-md shadow-sm">
          <Hint options={options} allowTabFill>
            <input
              {...props}
              // type={type}
              name={name}
              id={id}
              value={value}
              className={`${error ? "border-red-500" : "border-gray-300"}
              px-2 block w-full rounded-sm mt-1 py-2.5 text-darkBlue border placeholder:text-gray-400 outline-none focus:border focus:border-inset focus:border-indigo-600 text-sm leading-6 ${className}`}
              placeholder={placeholder}
              onChange={onChange}
              disabled={disabled}
              required={required}
              readOnly={disabled}
            />
          </Hint>
        </div>
        {error && (
          <p className="text-sm text-red-600" id="error">
            {errorMsg}
          </p>
        )}
      </div>
    </div>
  );
}
