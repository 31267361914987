import { FC } from 'react'
import DiscountPage from './DiscountPage';

const PassperksWrapper: FC = () => {
  return (
    <>
        <DiscountPage></DiscountPage>
    </>
  )

}

export default PassperksWrapper