import { ChevronRightIcon } from "@heroicons/react/24/outline";
import React from "react";
import { Link } from "react-router-dom";
import Badge from "../elements/Badge";
export interface EmptyStateProps extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
  data: {
    icon?: string;
    name: string;
    linkURL: string;
    isRedirectLink?: boolean;
    notificationCount?: number;
    onClick?: any;
  }[];
}

export default function ListGroup({ title, data, ...props }: EmptyStateProps) {
  return (
    <>
      <div className="w-full flex flex-col" {...props}>
        {title && (
          <h2 className="text-2xl font-titlef font-bold mb-3">{title}</h2>
        )}

        {data?.map((item, i) => (
          <div
            key={i}
            className="inline-flex items-center py-3 text-sm font-medium border-b 
             border-[#E6E6E6] last:border-b-0
            "
          >
            {item.linkURL !== "" && !item?.isRedirectLink && (
              <Link
                to={`${item.linkURL}`}
                className="flex justify-between items-center w-full text-base"
                onClick={item.onClick ? () => item.onClick() : undefined}
              >
                {item.name}
                {item?.notificationCount ? (
                  <div className="">
                    <Badge
                      notificationBadge={true}
                      title={item?.notificationCount}
                      size="mdBadge"
                    />
                  </div>
                ) : null}
                {/* <ChevronRightIcon className="w-4 h-4" /> */}
              </Link>
            )}
            {item.linkURL !== "" && item?.isRedirectLink && (
              <a
                href={`${item.linkURL}`}
                target="_blank"
                rel="noopener noreferrer"
                className="flex justify-between items-center w-full text-base"
                onClick={item.onClick ? () => item.onClick() : undefined}
              >
                {item.name}
                {item?.notificationCount ? (
                  <div className="">
                    <Badge
                      notificationBadge={true}
                      title={item?.notificationCount}
                      size="mdBadge"
                    />
                  </div>
                ) : null}
              </a>
            )}
          </div>
        ))}
      </div>
    </>
  );
}
